import React from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react'
import Select from 'react-select'
import store from '../../store'
import { CORPORATE_COLUMN_HEADERS, API, ALLOWED_QUERY_OPERATOR, TAXONOMIES, SELECT_STYLES, SELECT_THEME_PRIMARY, DELETE_OPTIONS } from '../../constant'
import { getLocations, getTaxonomyData } from '../../utility/commonApis'
import { prepareSelectParam } from '../../utility/queryParams'
import SideNav from '../SideNav'
import Parent from '../Parent'
import ActionRenderer from '../reusable/ActionRenderer'
import Modal from '../reusable/Modal'
import Sort from '../reusable/Sort'
import Pagination from '../reusable/Pagination'
import Home from '../Home'

class Corporates extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: CORPORATE_COLUMN_HEADERS,
            context: { componentParent: this },
            frameworkComponents: { actionRenderer: ActionRenderer },
            defaultColDef: { suppressMovable: true },

            keyword: '',
            showkeyword: false,
            keyword: [],
            type_id: '',
            owned_by: '',
            is_deleted: '',
            hq_id: '',
            countries: [],
            type_ids: [],
            type_idStore: [],
            owned_bys: [],
            owned_byStore: [],
            stage: '',
            stages: [],
            stageStore: [],
            industry: '',
            industrys: [],
            industryStore: [],
            showtype_id: false,
            showstage: false,
            showindustry: false,
            showowned_by: false,
            filters: {
                keyword: [],
                owned_by: [],
                type_id: [],
                stage: [],
                industry: [],
                is_deleted: [],
                hq_id: []
            },
            filterFields: [
                { 'key': 'keyword', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'owned_by', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'type_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'stage', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'industry', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'is_deleted', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'hq_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
            ]
        }
        store.sortType = this.props.location.pathname.split('/')[1]
    }

    onGridReady(params) {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    componentDidMount() {
        document.addEventListener('mousedown', (e) => {
            if (this.type) { if (!this.type.contains(e.target)) this.setState({ showtype_id: false }) }
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showkeyword: false }) }
            if (this.stage) { if (!this.stage.contains(e.target)) this.setState({ showstage: false }) }
            if (this.industry) { if (!this.industry.contains(e.target)) this.setState({ showindustry: false }) }
            if (this.owned_by) { if (!this.owned_by.contains(e.target)) this.setState({ showowned_by: false }) }
        })

        const paramFields = ['id', 'name', 'hq_data', 'type_data', 'industry_data', 'owned_by_data', 'created_at', 'updated_at'];
        store.fields = prepareSelectParam(paramFields)

        //TODO: perform token validator
        this.fetchListData('corporate', store.fields)
        this.setOwnerIds()
        fetch(`${API}/industry?fields=id^,name`, {
            method: 'GET',
            headers: {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            },
        })
            .then(response => { return response.json() })
            .then(data => {
                if (data.status === 200) {
                    let arr = []
                    data.data.map(item => {
                        arr.push({ value: item.id, label: item.name })
                    })
                    this.setState({ industrys: arr, industryStore: arr })
                }
            })

        getTaxonomyData(TAXONOMIES.devStage).then(res => {
            this.setState({ stages: res, stageStore: res })
        })

        getTaxonomyData(TAXONOMIES.corporateType).then(res => {
            this.setState({ type_ids: res, type_idStore: res })
        });

        getLocations().then(response => {
            this.setState({
                countries: response
            });
        });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => {
            if (this.type) { if (!this.type.contains(e.target)) this.setState({ showtype_id: false }) }
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showkeyword: false }) }
            if (this.stage) { if (!this.stage.contains(e.target)) this.setState({ showstage: false }) }
            if (this.industry) { if (!this.industry.contains(e.target)) this.setState({ showindustry: false }) }
            if (this.owned_by) { if (!this.owned_by.contains(e.target)) this.setState({ showowned_by: false }) }
        })
    }

    clearFilters = () => {
        this.setState({
            is_deleted: '',
            hq_id: '',
        }, () => {
            this.handleClearAllFilters('corporate');
        });
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>CORPORATES</h3>
                            <div className='filter-container'>

                                <div className='filter-row'>
                                    <div ref={node => this.word = node} className="col-md-12 col-sm-12 col-xs-12 filter-firstline filter-spacing" >
                                        <div style={{ width: '100%' }}>
                                            <input ref={node => this.input = node}
                                                className='filter-input input-border'
                                                placeholder="Type and Enter to Search"
                                                value={this.state.keyword}
                                                onFocus={() => this.setState({ showkeyword: true })}
                                                onKeyUp={this.handleKeywordEnter}
                                                onChange={e => this.setState({ keyword: e.target.value })} />
                                            {this.state.showkeyword && this.state.filters.keyword.length > 0 ?
                                                <div className='filter-dropdown' style={{ width: '100%' }}>
                                                    <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                        {this.state.filters.keyword.map((word, i) => {
                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'keyword')}>
                                                                {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div> : null}
                                        </div>
                                        {this.state.keyword.length > 0 &&
                                            <i className="fa fa-times "
                                                style={{ paddingRight: 10 }}
                                                onClick={() => {
                                                    this.input.focus()
                                                    this.setState({ keyword: '' })
                                                }}>
                                            </i>
                                        }
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className="col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing" style={{ paddingLeft: 0 }}>
                                        <div ref={node => this.owned_by = node} className='filter-firstline' style={{ width: '100%' }}>
                                            <div>
                                                <input className='filter-input'
                                                    style={{ paddingLeft: 15 }}
                                                    placeholder="Search by owner name"
                                                    onChange={this.setOwnerIds}
                                                    onFocus={() => { this.setState({ showowned_by: true }) }} />
                                                {this.state.showowned_by && this.state.owned_bys ?
                                                    <div className='filter-dropdown' >
                                                        <div >
                                                            {this.state.filters.owned_by.length > 0 ?
                                                                <div className='filter-dropdown-wrapper' >
                                                                    {this.state.filters.owned_by.map((owner, i) => {
                                                                        return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'owned_by')}>
                                                                            {owner}&nbsp;<i className="fa fa-times-circle"></i>
                                                                        </div>
                                                                    })}
                                                                </div> : null}
                                                        </div>
                                                        <ul className='filter-list-container'>
                                                            <div className='filter-list-wrapper' >
                                                                <div>
                                                                    {this.state.owned_bys.map((item, j) =>
                                                                        <div key={j}>
                                                                            <li style={{ fontSize: 12, }} onClick={() => this.handleSelectFilter(item, 'owned_by')}>
                                                                                {item.label}
                                                                            </li><br />
                                                                        </div>)}
                                                                </div>
                                                                <div className='filter-stickybtn ' >
                                                                    <div style={{ width: 140 }}>
                                                                        <a tabIndex='0' className='filter-canclebtn' onClick={() => this.handleClearFilter('owned_by')}>Clear</a> &nbsp; | &nbsp;
                                                                        <a tabIndex='0' className='filter-applybtn' onClick={() => { this.setState({ showowned_by: false }); this.handleSearchFilters('corporate') }}>Apply</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div> : null}
                                            </div>
                                            
                                            {this.state.showowned_by ? <i className="fa fa-chevron-up " style={{ paddingRight: 10 }} onClick={() => this.setState({ showowned_by: false })} ></i> :
                                                <i className="fa fa-chevron-down " style={{ paddingRight: 10 }} onClick={() => this.setState({ showowned_by: true })} ></i>}
                                        </div>
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing'  >

                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                            <div ref={node => this.type = node} className='filter-firstline' >
                                                <div style={{ width: '100%' }}>
                                                    <input className='filter-input'
                                                        style={{ paddingLeft: 15 }}
                                                        placeholder="By Corporate Types"
                                                        value={this.state.type_id}
                                                        onChange={(e) => this.handleInputChange(e.target.value, 'type_id')}
                                                        onFocus={() => { this.setState({ showstage: false, showtype_id: true, showindustry: false }) }} />
                                                    {this.state.showtype_id && this.state.type_ids ?
                                                        <div className='filter-dropdown' >
                                                            <div >
                                                                {this.state.filters.type_id.length > 0 ?
                                                                    <div className='filter-dropdown-wrapper' >
                                                                        {this.state.filters.type_id.map((loc, i) => {
                                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'type_id')}>
                                                                                {loc}&nbsp;<i className="fa fa-times-circle"></i>
                                                                            </div>
                                                                        })}
                                                                    </div> : null}
                                                            </div>
                                                            <ul className='filter-list-container'>
                                                                <div className='filter-list-wrapper' >
                                                                    <div>
                                                                        {this.state.type_ids.map((item, j) =>
                                                                            <div key={j}>
                                                                                <li style={{ fontSize: 12, }} onClick={() => this.handleSelectFilter(item, 'type_id')}>
                                                                                    {item.label}
                                                                                </li><br />
                                                                            </div>)}
                                                                    </div>
                                                                    <div className='filter-stickybtn ' >
                                                                        <div style={{ width: 140 }}>
                                                                            <a tabIndex='0' className='filter-canclebtn' onClick={() => this.handleClearFilter('type_id')}>Clear</a> &nbsp; | &nbsp;
                                                                            <a tabIndex='0' className='filter-applybtn' onClick={() => { this.setState({ showtype_id: false }); this.handleSearchFilters('corporate') }}>Apply</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ul>
                                                        </div> : null}
                                                </div>
                                                {this.state.showtype_id ? <i className="fa fa-chevron-up " style={{ paddingRight: 10 }} onClick={() => this.setState({ showtype_id: false })} ></i> :
                                                    <i className="fa fa-chevron-down " style={{ paddingRight: 10 }} onClick={() => this.setState({ showtype_id: true })} ></i>}
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ padding: 0 }}>
                                            <div ref={node => this.stage = node} className='filter-firstline' >
                                                <div style={{ width: '100%' }}>
                                                    <input className='filter-input'
                                                        style={{ paddingLeft: 15 }}
                                                        placeholder="By Stage"
                                                        value={this.state.stage}
                                                        onChange={(e) => this.handleInputChange(e.target.value, 'industry')}
                                                        onFocus={() => { this.setState({ showtype_id: false, showstage: true, showindustry: false }) }} />
                                                    {this.state.showstage && this.state.stages ?
                                                        <div className='filter-dropdown' >
                                                            <div >
                                                                {this.state.filters.stage.length > 0 ?
                                                                    <div className='filter-dropdown-wrapper' >
                                                                        {this.state.filters.stage.map((item, i) => {
                                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'stage')}>
                                                                                {item}&nbsp;<i className="fa fa-times-circle"></i>
                                                                            </div>
                                                                        })}
                                                                    </div> : null}
                                                            </div>
                                                            <ul className='filter-list-container'>
                                                                <div className='filter-list-wrapper' >
                                                                    <div>
                                                                        {this.state.stages.map((item, j) =>
                                                                            <div key={j}>
                                                                                <li style={{ fontSize: 12, }} onClick={() => this.handleSelectFilter(item, 'stage')}>
                                                                                    {item.label}
                                                                                </li><br />
                                                                            </div>)}
                                                                    </div>
                                                                    <div className='filter-stickybtn ' >
                                                                        <div style={{ width: 140 }}>
                                                                            <a tabIndex='0' className='filter-canclebtn' onClick={() => this.handleClearFilter('stage')}>Clear</a> &nbsp; | &nbsp;
                                                                            <a tabIndex='0' className='filter-applybtn' onClick={() => { this.setState({ showstage: false }) }}>Apply</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ul>
                                                        </div> : null}
                                                </div>
                                                {this.state.showindustry ? <i className="fa fa-chevron-up " style={{ paddingRight: 10 }} onClick={() => this.setState({ showindustry: false })} ></i> :
                                                    <i className="fa fa-chevron-down " style={{ paddingRight: 10 }} onClick={() => this.setState({ showindustry: true })} ></i>}
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing'  >

                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                            <div ref={node => this.industry = node} className='filter-firstline' >
                                                <div style={{ width: '100%' }}>
                                                    <input className='filter-input'
                                                        style={{ paddingLeft: 15 }}
                                                        placeholder="By Industry"
                                                        value={this.state.industry}
                                                        onChange={(e) => this.handleInputChange(e.target.value, 'industry')}
                                                        onFocus={() => { this.setState({ showtype_id: false, showindustry: true }) }} />
                                                    {this.state.showindustry && this.state.industrys ?
                                                        <div className='filter-dropdown' >
                                                            <div >
                                                                {this.state.filters.industry.length > 0 ?
                                                                    <div className='filter-dropdown-wrapper' >
                                                                        {this.state.filters.industry.map((item, i) => {
                                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'industry')}>
                                                                                {item}&nbsp;<i className="fa fa-times-circle"></i>
                                                                            </div>
                                                                        })}
                                                                    </div> : null}
                                                            </div>
                                                            <ul className='filter-list-container'>
                                                                <div className='filter-list-wrapper' >
                                                                    <div>
                                                                        {this.state.industrys.map((item, j) =>
                                                                            <div key={j}>
                                                                                <li style={{ fontSize: 12, }} onClick={() => this.handleSelectFilter(item, 'industry')}>
                                                                                    {item.label}
                                                                                </li><br />
                                                                            </div>)}
                                                                    </div>
                                                                    <div className='filter-stickybtn ' >
                                                                        <div style={{ width: 140 }}>
                                                                            <a tabIndex='0' className='filter-canclebtn' onClick={() => this.handleClearFilter('industry')}>Clear</a> &nbsp; | &nbsp;
                                                                            <a tabIndex='0' className='filter-applybtn' onClick={() => { this.setState({ showindustry: false }); this.handleSearchFilters('corporate') }}>Apply</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ul>
                                                        </div> : null}
                                                </div>
                                                {this.state.showindustry ? <i className="fa fa-chevron-up " style={{ paddingRight: 10 }} onClick={() => this.setState({ showindustry: false })} ></i> :
                                                    <i className="fa fa-chevron-down " style={{ paddingRight: 10 }} onClick={() => this.setState({ showindustry: true })} ></i>}
                                            </div>
                                        </div>
        

                                        <div className="col-md-6 col-sm-6 col-xs-12 " style={{ padding: 0 }}>
                                            <div className='filter-firstline'>
                                                <div style={{ width: '100%' }}>
                                                    <Select
                                                        placeholder='Filter by is deleted'
                                                        styles={SELECT_STYLES}
                                                        theme={theme => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary: SELECT_THEME_PRIMARY
                                                            }
                                                        })}
                                                        value={this.state.is_deleted}
                                                        onChange={(option) => this.handleSingleSelectFilter(option, 'is_deleted')}
                                                        options={DELETE_OPTIONS}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing'  >
                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                            <div className='filter-firstline'>
                                                <div style={{ width: '100%' }}>
                                                    <Select
                                                        placeholder='Filter by country'
                                                        styles={SELECT_STYLES}
                                                        theme={theme => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary: SELECT_THEME_PRIMARY
                                                            }
                                                        })}
                                                        value={this.state.hq_id}
                                                        onChange={(option) => this.handleSingleSelectFilter(option, 'hq_id')}
                                                        options={this.state.countries}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-6 filter-searchbtn" onClick={this.clearFilters}>Clear All</a>
                                    </div>
                                </div>

                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    defaultColDef={this.state.defaultColDef}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    frameworkComponents={this.state.frameworkComponents}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                >
                                </AgGridReact>
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}


export default observer(Corporates)
