import {ALLOWED_QUERY_OPERATOR, API} from '../constant'
import store from '../store'
import {prepareFilterParam, prepareSelectParam} from "./queryParams";

export const getTaxonomyData = async (id) => {
    let response = await fetch(`${API}/taxonomy-type/${id}/taxonomy/?fields=id^,name&limit=100`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        },
    })
    let data = await response.json()
    let arr = []
    await data.data.map(item => {
        arr.push({ value: item.id, label: item.name })
    })
    return arr
}

export const getSubIndustries = async (parentId) => {
    const taxoFields = prepareSelectParam(['name', 'id']);
    const filters = prepareFilterParam([
        {key: 'is_deleted', value: [0], op: ALLOWED_QUERY_OPERATOR.equal},
        {key: 'parent_id', value: [parentId], op: ALLOWED_QUERY_OPERATOR.equal},
    ]);
    let response = await fetch(`${API}/industry?fields=${taxoFields}&filters=${filters}&limit=200`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }
    })
    let data = await response.json()
    let arr = []
    data.data.map(item => {
        arr.push({ value: item.id, label: item.name })
    })
    return arr
}
