import { ALLOWED_QUERY_OPERATOR } from "../../../constant"

export const FILTERS_OPERATORS = [
    { 
        label: 'Equal to', 
        value: ALLOWED_QUERY_OPERATOR.equal
    },
    { 
        label: 'Not equal to', 
        value: ALLOWED_QUERY_OPERATOR.notEq
    },
    { 
        label: 'Greater than or equal', 
        value: ALLOWED_QUERY_OPERATOR.gte
    },
    { 
        label: 'Greater than', 
        value: ALLOWED_QUERY_OPERATOR.gt
    },
    { 
        label: 'Less than or equal', 
        value: ALLOWED_QUERY_OPERATOR.lte
    },
    { 
        label: 'Less than', 
        value: ALLOWED_QUERY_OPERATOR.lt
    },
    { 
        label: 'Between', 
        value: ALLOWED_QUERY_OPERATOR.btw
    },
    { 
        label: 'Substring', 
        value: ALLOWED_QUERY_OPERATOR.substring
    }
]

export const FILTERS_UI_TYPES = {
    TEXT: 'TEXT',
    DROP_DOWN: 'DROP_DOWN',
    AUTO_COMPLETE: 'AUTO_COMPLETE',
    CHECKBOX: 'CHECKBOX',
    DATE: 'DATE',
    NUMBER: 'NUMBER'
}

export const FILTERS_STORES = {
    REGION: 'REGION',
    COUNTRY: 'COUNTRY',
    INDUSTRY: 'INDUSTRY',
    PRIMARY_INDUSTRY: 'PRIMARY_INDUSTRY',
    SECONDARY_INDUSTRY: 'SECONDARY_INDUSTRY',
    SUB_INDUSTRY: 'SUB_INDUSTRY',
    INVESTOR_TYPE: 'INVESTOR_TYPE',
    FUNDING_STAGE: 'FUNDING_STAGE',
    DEVELOPMENT_STAGE: 'DEVELOPMENT_STAGE',
    BUSINESS_TYPE: 'BUSINESS_TYPE',
    INVESTMENT_VEHICLE: 'INVESTMENT_VEHICLE',
    GENDER: 'GENDER',
    DEGREE: 'DEGREE',
    ENTITY: 'ENTITY'
}

export const DATA_EXPORT_FILTERS = {
    STARTUP: [
        { 
            label: 'Keyword', 
            value: 'keyword', 
            ui_type: FILTERS_UI_TYPES.TEXT
        },
        { 
            label: 'Startup HQ', 
            value: 'hq_id', 
            ui_type: FILTERS_UI_TYPES.DROP_DOWN,
            store: FILTERS_STORES.COUNTRY
        },
        { 
            label: 'Region', 
            value: 'region', 
            ui_type: FILTERS_UI_TYPES.DROP_DOWN,
            store: FILTERS_STORES.REGION
        },
        {
            label: 'Primary-Industry',
            value: 'primary_industry_filter',
            ui_type: FILTERS_UI_TYPES.DROP_DOWN,
            store: FILTERS_STORES.PRIMARY_INDUSTRY
        },
        {
            label: 'Sub-Industry',
            value: 'sub_industry_filter',
            ui_type: FILTERS_UI_TYPES.DROP_DOWN,
            store: FILTERS_STORES.SUB_INDUSTRY
        },
        {
            label: 'Secondary-Industry',
            value: 'sec_industry_filter',
            ui_type: FILTERS_UI_TYPES.DROP_DOWN,
            store: FILTERS_STORES.SECONDARY_INDUSTRY
        },
        { 
            label: 'Development Stage', 
            value: 'development_stage', 
            ui_type: FILTERS_UI_TYPES.DROP_DOWN,
            store: FILTERS_STORES.DEVELOPMENT_STAGE
        },
        { 
            label: 'Business Type', 
            value: 'business_type', 
            ui_type: FILTERS_UI_TYPES.DROP_DOWN,
            store: FILTERS_STORES.BUSINESS_TYPE
        },
        { 
            label: 'Founder Gender', 
            value: 'gender_filter', 
            ui_type: FILTERS_UI_TYPES.DROP_DOWN,
            store: FILTERS_STORES.GENDER
        },
        { 
            label: 'Active', 
            value: 'is_active', 
            ui_type: FILTERS_UI_TYPES.CHECKBOX
        },
        { 
            label: 'Fundraising', 
            value: 'fundraising', 
            ui_type: FILTERS_UI_TYPES.CHECKBOX
        },
        { 
            label: 'Revenue Generating', 
            value: 'revenue_generating', 
            ui_type: FILTERS_UI_TYPES.CHECKBOX
        },
        { 
            label: 'Max Number of Employees', 
            value: 'max_num_emp', 
            ui_type: FILTERS_UI_TYPES.NUMBER 
        },
        { 
            label: 'Min Number of Employees', 
            value: 'min_num_emp', 
            ui_type: FILTERS_UI_TYPES.NUMBER 
        },
        { 
            label: 'Max Funding Amount', 
            value: 'max_fund_amount', 
            ui_type: FILTERS_UI_TYPES.NUMBER 
        },
        { 
            label: 'Min Funding Amount', 
            value: 'min_fund_amount', 
            ui_type: FILTERS_UI_TYPES.NUMBER 
        },
        { 
            label: 'Date Founded', 
            value: 'date_founded', 
            ui_type: FILTERS_UI_TYPES.DATE 
        },
        { 
            label: 'Total Funding', 
            value: 'total_fund', 
            ui_type: FILTERS_UI_TYPES.NUMBER 
        },
        { 
            label: 'Claimed', 
            value: 'owned_by', 
            ui_type: FILTERS_UI_TYPES.CHECKBOX
        }
    ],
    ECOSYSTEM: [
        { 
            label: 'Keyword', 
            value: 'keyword', 
            ui_type: FILTERS_UI_TYPES.TEXT
        },
        { 
            label: 'Investor HQ', 
            value: 'hq_id', 
            ui_type: FILTERS_UI_TYPES.DROP_DOWN,
            store: FILTERS_STORES.COUNTRY
        },
        { 
            label: 'Industry', 
            value: 'industry', 
            ui_type: FILTERS_UI_TYPES.DROP_DOWN,
            store: FILTERS_STORES.INDUSTRY
        },
        { 
            label: 'Development Stage', 
            value: 'development_stage', 
            ui_type: FILTERS_UI_TYPES.DROP_DOWN,
            store: FILTERS_STORES.DEVELOPMENT_STAGE
        },
        { 
            label: 'Investor Type', 
            value: 'type_id', 
            ui_type: FILTERS_UI_TYPES.DROP_DOWN,
            store: FILTERS_STORES.INVESTOR_TYPE
        },
        { 
            label: 'Startups Funded', 
            value: 'rel_startup_cnt', 
            ui_type: FILTERS_UI_TYPES.NUMBER 
        },
        { 
            label: 'Claimed', 
            value: 'owned_by', 
            ui_type: FILTERS_UI_TYPES.CHECKBOX
        },
        { 
            label: 'Geo Focus', 
            value: 'geo_focus', 
            ui_type: FILTERS_UI_TYPES.DROP_DOWN,
            store: FILTERS_STORES.COUNTRY
        },
        { 
            label: 'Region', 
            value: 'region', 
            ui_type: FILTERS_UI_TYPES.DROP_DOWN,
            store: FILTERS_STORES.REGION
        },
    ],
    FUNDING: [
        { 
            label: 'Startup Keyword', 
            value: 'keyword', 
            ui_type: FILTERS_UI_TYPES.TEXT
        },
        { 
            label: 'Investor Keyword', 
            value: 'investor_keyword', 
            ui_type: FILTERS_UI_TYPES.TEXT
        },
        { 
            label: 'Region', 
            value: 'region', 
            ui_type: FILTERS_UI_TYPES.DROP_DOWN,
            store: FILTERS_STORES.REGION
        },
        { 
            label: 'Startup HQ', 
            value: 'location_id', 
            ui_type: FILTERS_UI_TYPES.DROP_DOWN,
            store: FILTERS_STORES.COUNTRY
        },
        { 
            label: 'Investor Type', 
            value: 'investor_type_id', 
            ui_type: FILTERS_UI_TYPES.DROP_DOWN,
            store: FILTERS_STORES.INVESTOR_TYPE
        },
        {
            label: 'Primary-Industry',
            value: 'primary_industry_filter',
            ui_type: FILTERS_UI_TYPES.DROP_DOWN,
            store: FILTERS_STORES.PRIMARY_INDUSTRY
        },
        {
            label: 'Sub-Industry',
            value: 'sub_industry_filter',
            ui_type: FILTERS_UI_TYPES.DROP_DOWN,
            store: FILTERS_STORES.SUB_INDUSTRY
        },
        {
            label: 'Secondary-Industry',
            value: 'sec_industry_filter',
            ui_type: FILTERS_UI_TYPES.DROP_DOWN,
            store: FILTERS_STORES.SECONDARY_INDUSTRY
        },
        { 
            label: 'Funding Stage', 
            value: 'funding_stage', 
            ui_type: FILTERS_UI_TYPES.DROP_DOWN,
            store: FILTERS_STORES.FUNDING_STAGE
        },
        { 
            label: 'Investment Vehicle', 
            value: 'investment_vehicle_id', 
            ui_type: FILTERS_UI_TYPES.DROP_DOWN,
            store: FILTERS_STORES.INVESTMENT_VEHICLE
        },
        { 
            label: 'No. of Rounds Raised', 
            value: 'investedin_funding_count', 
            ui_type: FILTERS_UI_TYPES.NUMBER
        },
        { 
            label: 'Date Raised', 
            value: 'date_raised', 
            ui_type: FILTERS_UI_TYPES.DATE
        },
        { 
            label: 'Last Date Raised', 
            value: 'last_date_raised', 
            ui_type: FILTERS_UI_TYPES.DATE
        },
        { 
            label: 'Amount Raised', 
            value: 'amount_raised', 
            ui_type: FILTERS_UI_TYPES.NUMBER 
        },
        { 
            label: 'Total Funding', 
            value: 'total_amount_raised', 
            ui_type: FILTERS_UI_TYPES.NUMBER 
        },
        { 
            label: 'Verification Status', 
            value: 'verification_status', 
            ui_type: FILTERS_UI_TYPES.CHECKBOX
        }
    ],
    PEOPLE: [
        {
            label: 'Name',
            value: 'keyword',
            ui_type: FILTERS_UI_TYPES.TEXT
        },
        {
            label: 'Employer Name',
            value: ['entity_filter','entity_type_filter'],
            ui_type: FILTERS_UI_TYPES.AUTO_COMPLETE,
            store: FILTERS_STORES.ENTITY
        },
        {
            label: 'Location',
            value: 'country_id',
            ui_type: FILTERS_UI_TYPES.DROP_DOWN,
            store: FILTERS_STORES.COUNTRY
        },
        {
            label: 'Gender',
            value: 'gender',
            ui_type: FILTERS_UI_TYPES.DROP_DOWN,
            store: FILTERS_STORES.GENDER

        },
        {
            label: 'Current Job Title',
            value: 'current_job_keyword',
            ui_type: FILTERS_UI_TYPES.TEXT
        },
        {
            label: 'Nationality',
            value: 'nationality_id',
            ui_type: FILTERS_UI_TYPES.DROP_DOWN,
            store: FILTERS_STORES.COUNTRY
        },
        {
            label: 'Founder',
            value: 'founder_filter',
            ui_type: FILTERS_UI_TYPES.CHECKBOX
        },
        {
            label: 'Investor',
            value: 'investor_filter',
            ui_type: FILTERS_UI_TYPES.CHECKBOX
        },
        {
            label: 'Degree',
            value: 'degree_filter',
            ui_type: FILTERS_UI_TYPES.AUTO_COMPLETE,
            store: FILTERS_STORES.DEGREE
        }
    ]
}
