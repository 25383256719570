import React from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';

// constants
import { API_PATH } from '../../utility/constants/apiPath';
import { SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY, OPTIONS } from '../../constant';

// helpers
import store from '../../store';

// apis
import subscriptionAddonApi from '../../apis/subscriptionAddonApi';

// components
import FormParent from '../FormParent';
import Home from '../Home';
import SideNav from '../SideNav';
import Modal from '../reusable/Modal';
import ErrorPopup from '../reusable/ErrorModal';
import SubscriptionAddonRoles from './subscriptionAddonRoles/SubscriptionAddonRoles';

class SubscriptionAddonForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                name: '',
                price: '',
                active: ''
            },
            postCreation: {
                addon_roles: []
            },
            editParams: [],
            validationFields: ['name', 'active'],
            error: false
        }
        this.parameters = {
            name: '',
            price: '',
            active: ''
        }
    }

    async componentDidMount() {
        store.subscriptionAddonRolesData = [];
        store.subscriptionAddonRoles = [];

        if (store.isEdit && store.currentId) {
            subscriptionAddonApi.getSubscriptionAddonDetails(store.currentId).then(
                response => {
                    const objTemp = {};

                    Object.keys(this.state.formFields).map(key => {
                        if (key === 'active') {
                            objTemp[key] = response.active == 0 ? { value: 0, label: 'No' } : response.active == 1 ? { value: 1, label: 'Yes' } : '';
                        } else {
                            objTemp[key] = response[key] !== null ? response[key] : '';
                        }
                    });

                    this.setState({ formFields: objTemp });
                }
            );
        }
    }

    handleAddAddon = () => {
        // prepare the addon roles to be added post creating the addon
        const tempObj = { ...this.state.postCreation };

        tempObj.addon_roles = store.subscriptionAddonRoles;

        this.setState({
            postCreation: tempObj
        }, () => this.handleAdd(API_PATH.subscriptionAddon));
    }

    handleUpdateAddon = () => {
        // prepare the addon roles api calls
        if (store.subscriptionAddonRoles.length > 0) {
            const subscriptionAddonId = store.currentId;

            let payload = [];
            store.subscriptionAddonRoles.map(role => {
                payload.push({ subscription_addon_id: subscriptionAddonId, role_id: role });
            });

            store.apis.push({ path: API_PATH.subscriptionAddonRole, method: 'POST', data: payload });
        }

        this.handleUpdate(API_PATH.subscriptionAddon);
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>Edit Subscription Addon</h3> :
                                <h3 style={{ textAlign: 'center' }}>Add Subscription Addon</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Name <span className='required'>*</span></label>
                                            <input type="text"
                                                className={this.state.nameEmpty ? "form-control input-error" : "form-control"}
                                                value={this.state.formFields.name}
                                                name='name'
                                                onChange={this.handleInputChange} />
                                            {this.state.nameEmpty && <div className='error'>Name cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Price</label>
                                            <input type="text"
                                                className="form-control"
                                                value={this.state.formFields.price}
                                                name='price'
                                                onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Active<span className='required'>*</span></label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={this.state.activeEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.active}
                                                onChange={(option) => this.handleSelectChange(option, 'active')}
                                                options={OPTIONS}
                                            />
                                            {this.state.activeEmpty && <div className='error'>Active cannot be empty</div>}
                                        </div>
                                    </div>

                                    <SubscriptionAddonRoles />

                                </div>
                            </div>

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ? <button className="btn-primary bottom-btn" disabled={this.state.editParams.length > 0 || store.apis.length > 0 || store.subscriptionAddonRoles.length > 0 ? false : true} onClick={this.handleUpdateAddon}>Save</button> :
                                    <div>
                                        <button className="btn-primary bottom-btn" onClick={this.handleAddAddon}>Add Subscription Addon</button>
                                        <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                                    </div>
                                }
                            </div>

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <ErrorPopup errorMessage={this.state.errorMessage} />
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}
export default observer(SubscriptionAddonForm);
