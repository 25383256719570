import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

// constants
import {
    SELECT_STYLES,
    SELECT_STYLES_ERROR,
    SELECT_THEME_PRIMARY
} from '../../../constant';

function APIProductSection(props) {
    return (
        <div className='form-wrapper'>
            <h5>API Product Details</h5>
            <div className='row'>
                <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                    <label>Organization <span className='required'>*</span></label>
                    <AsyncSelect
                        name='organization_id'
                        placeholder='Select an organization'
                        styles={props.isOrganizationEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                        theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: SELECT_THEME_PRIMARY
                            }
                        })}
                        value={props.formFields.organization_id}
                        defaultOptions={props.organizations}
                        loadOptions={(value, callback) => props.getOrganizations(value, callback)}
                        onChange={(option) => props.handleSelectChange(option, 'organization_id')}
                        onFocus={() => { props.getOrganizations }}
                    />
                    {props.isOrganizationEmpty && <div className='error'>Organization cannot be empty</div>}
                </div>
            </div>

            {
                props.isApiProductAddonSelected &&
                <div className='row'>
                    <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                        <label >
                            API Product Type <span className='required'>*</span>
                        </label>
                        <Select
                            name='api_product_id'
                            placeholder='Select an API product type'
                            styles={props.isApiProductTypeEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: SELECT_THEME_PRIMARY
                                }
                            })}
                            value={props.formFields.api_product_id}
                            onChange={(option) => props.handleSelectChange(option, 'api_product_id')}
                            options={props.apiProducts}
                            formatOptionLabel={props.formatOptionLabel}
                        />
                        {props.isApiProductTypeEmpty && <div className='error'>API product type cannot be empty</div>}
                    </div>
                </div>
            }
            <hr />
        </div>
    )
}

export default APIProductSection;