import React, { Component } from 'react';
import store from '../../../store';

class RoleActionRenderer extends Component {
    handleDelete = () => {
        const index = this.props.rowIndex;
        const subRoleId = this.props.data.id;
        const roleId = this.props.data.role_id ? this.props.data.role_id : '';

        if (subRoleId !== 'NEW') {
            store.apis.push({ path: `subscription-type-role/${subRoleId}`, method: 'DELETE' });
        }
        
        store.rolesData.splice(index, 1);
        store.subscriptionRoles = store.subscriptionRoles.filter(role => role != roleId);

        this.props.api.setRowData(store.rolesData);
    }

    render() {
        return (
            <div>
                <a tabIndex='2' className="btn-delete" onClick={this.handleDelete} ><i className='fas fa-trash-alt'></i></a>
            </div>
        )
    }
}

export default RoleActionRenderer

