import axios from 'axios';

import { ALLOWED_QUERY_OPERATOR, API } from '../constant';
import store from '../store';
import { prepareFilterParam, prepareSelectParam } from '../utility/queryParams';

const locationApi = {

    getLocation: (locationId, fieldsParams) => {
        const fields = prepareSelectParam(fieldsParams);

        const url = `${API}/location/${locationId}?fields=${fields}`;
        const headers = {
            Authorization: store.token
        };
        
        return new Promise((resolve, reject) => {
            axios.get(url, {headers})
                .then(response => {
                    resolve(response.data.data);
                }).catch(err => reject(err))
        })
    },

    getRegions: () => {
        const fields = prepareSelectParam(['id', 'name']);
        const filters = prepareFilterParam([{ key: 'is_region', value: [1], op: ALLOWED_QUERY_OPERATOR.equal }]);

        const url = `${API}/location?fields=${fields}&filters=${filters}`;
        const headers = {
            Authorization: store.token
        };
        
        return new Promise((resolve, reject) => {
            axios.get(url, {headers})
                .then(response => {
                    resolve(response.data.data)
                }).catch(err => reject(err))
        })
    },

    getCountries: () => {
        const fields = prepareSelectParam(['id', 'name']);
        const filters = prepareFilterParam([{ key: 'country', value: [0], op: ALLOWED_QUERY_OPERATOR.equal }]);

        const url = `${API}/location?fields=${fields}&filters=${filters}`;
        const headers = {
            Authorization: store.token
        };
        
        return new Promise((resolve, reject) => {
            axios.get(url, {headers})
                .then(response => {
                    resolve(response.data.data);
                }).catch(err => reject(err))
        })
    },

    addLocation: (payload) => {
        const url = `${API}/location`
        const headers = {
            Authorization: store.token
        }
        
        return new Promise((resolve, reject) => {
            axios.post(url, payload, {headers})
                .then(response => {
                    resolve(response.data.data);
                }).catch(err => reject(err))
        })
    },

    updateLocation: (payload) => {
        const url = `${API}/location`
        const headers = {
            Authorization: store.token
        }
        
        return new Promise((resolve, reject) => {
            axios.patch(url, payload, {headers})
                .then(response => {
                    resolve(response.data.data);
                }).catch(err => reject(err))
        })
    }

}

export default locationApi;