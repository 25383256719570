import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import store from '../../store'
import { SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY, OPTIONS, API, LOCATIONS, TAXONOMIES, BANNER_TYPES } from '../../constant'
import { API_PATH } from '../../utility/constants/apiPath';
import { getTaxonomyData, getPageList } from '../../utility/commonApis'
import { prepareSelectParam } from '../../utility/queryParams'
import { isObjectEmpty } from '../../utility/helpers'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import SideNav from '../SideNav'
import Modal from '../reusable/Modal'
import FormParent from '../FormParent'
import Home from '../Home'
import ErrorPopup from '../reusable/ErrorModal';

class MagnittDepartmentForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                name: '',
                description: '',
                goal: '',
                background_image_id: ''
            },
            background_image_idError: false,
            background_image_idSuccess: false,
            background_image_idUpload: false,
            background_image_idMsg: '',
            background_image_idFile: '',
            editParams: [],
            fileFields: ['background_image_id'],
            validationFields: ['name', 'description', 'goal'],
        }
        this.parameters = {
            name: '',
            description: '',
            goal: '',
            background_image_id: '',
        }

    }

    componentDidMount() {
        if (store.isEdit && store.currentId) {
            let paramFields = ['id', 'name', 'description', 'goal', 'background_image_id', 'background_image_data'];
            let fields = prepareSelectParam(paramFields);

            fetch(`${API}/${API_PATH.magnittDepartment}/${store.currentId}/?fields=${fields}`, {
                method: 'GET',
                headers: {
                    'Authorization': store.token,
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    let obj = {}
                    Object.keys(this.state.formFields).map(key => {
                        if (key === 'background_image_id') {
                            obj[key] = !isObjectEmpty(data.data.background_image_data) ? data.data.background_image_data.name : '';
                        }
                        else {
                            obj[key] = data.data[key] !== null ? data.data[key] : '';
                        }
                    })
                    this.state.formFields = obj;
                    this.setState(this.state);
                })
                .catch(err => {
                    console.log(err)
                })
        }

    }

    renderErrors = () => {
        let { nameEmpty,
            descriptionEmpty,
            goalEmpty,
            background_image_idMsg,
            background_image_idError,
            background_image_idUpload} = this.state
        let errors = []

        if (nameEmpty)
            errors.push(<div className='error'>MAGNiTT's department name cannot be empty</div>)
        if (descriptionEmpty)
            errors.push(<div className='error'>MAGNiTT's department description cannot be empty</div>)
        if (goalEmpty)
            errors.push(<div className='error'>MAGNiTT's department goal cannot be empty</div>)
        if (background_image_idMsg)
            errors.push(<div className='error'>{this.state.background_image_idMsg}</div>)
        if (background_image_idError)
            errors.push(<div className='error'>Upload failed!.</div>)
        if (background_image_idUpload)
            errors.push(<div className='error'>File is chosen but not uploaded.</div>)

        return errors
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>
                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT MAGNiTT DEPARTMENT</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD MAGNiTT DEPARTMENT</h3>
                            }
                            <div className='form-container'>
                                <div className='form-wrapper'>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Department Name <span className='required'>*</span></label>
                                            <input type="text" className={this.state.nameEmpty ? "form-control input-error" : "form-control"} name='name' value={this.state.formFields.name} onChange={this.handleInputChange} />
                                            {this.state.nameEmpty && <div className='error'>MAGNiTT's department name cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Description <span className='required'>*</span></label>
                                            <textarea rows='8'
                                                className={this.state.descriptionEmpty ? "form-control input-error" : "form-control"}
                                                name='description'
                                                value={this.state.formFields.description}
                                                onChange={this.handleInputChange} />
                                            {this.state.descriptionEmpty && <div className='error'>MAGNiTT's department description cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Goal <span className='required'>*</span></label>
                                            <textarea rows='8'
                                                className={this.state.goalEmpty ? "form-control input-error" : "form-control"}
                                                name='goal'
                                                value={this.state.formFields.goal}
                                                onChange={this.handleInputChange} />
                                            {this.state.goalEmpty && <div className='error'>MAGNiTT's department goal cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Background Image </label>
                                            <div className="input-group mb-3">
                                                <div className="custom-file">
                                                    <div className="form-filename-holder">{this.state.formFields.background_image_id === '' ? 'Choose a File' : this.state.formFields.background_image_id}</div>
                                                    <input type='file' className="custom-file-input" name="background_image_id" onChange={this.handleImageUpload} />
                                                </div>
                                                <div className="input-group-append">
                                                    <span className="input-group-text" onClick={() => this.addFileUpload('background-image', 'background_image_id', this.state.background_image_idFile)}>Upload</span>
                                                </div>
                                            </div>
                                            {this.state.background_image_idMsg && <div className='error'>{this.state.background_image_idMsg}</div>}
                                            {this.state.background_image_idError && <div className='error'>Upload failed!.</div>}
                                            {this.state.background_image_idSuccess && <div className='success'>Upload successful!</div>}
                                            {this.state.background_image_idUpload && <div className='error'>File is chosen but not uploaded</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {this.renderErrors()}

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ?
                                    <button className="btn-primary bottom-btn" disabled={this.state.editParams.length > 0 || store.apis.length > 0 ? false : true} onClick={() => this.handleUpdate(API_PATH.magnittDepartment)}>Save</button> :
                                    <div>
                                        <button className="btn-primary bottom-btn" onClick={() => this.handleAdd(API_PATH.magnittDepartment)}>Add Department</button>
                                        <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                                    </div>
                                }
                            </div>
                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <ErrorPopup errorMessage={this.state.errorMessage} />
                        <Modal />
                    </div>
                }
            </div>
        )
    }
}
export default observer(MagnittDepartmentForm)
