import React, { Component } from 'react';
import { observer } from 'mobx-react';

// helpers
import store from '../../../store';
import { isObjectEmpty, displayAmount } from '../../../utility/helpers';

// constants
import { API_PATH } from '../../../utility/constants/apiPath';

class InvestorsViewModal extends Component {

    handleCloseModal = () => {
        $('#investorsViewModal').modal('hide');
    }

    handleEdit = (investorData = {}, index) => {
        store.investorFundingDetails = investorData;
        store.investorFundingRowIndex = index;
        store.currentInvestorFundingId = investorData.investor_funding_id;
        store.isInvestorFundingEdit = true;

        this.props.handleEditInvestorFunding();
    }

    handleDelete = (investorData = {}, index) => {
        if (store.isEdit && investorData.investor_funding_id) {
            store.apis.push({ path: `${API_PATH.investorFunding}/${investorData.investor_funding_id}`, method: 'DELETE' })
        }

        store.investorFundingData.splice(index, 1);

        this.forceUpdate();
    }

    renderData = () => {
        let tableRows = [];
        let content = <div style={{ textAlign: 'center' }}>No investors to show</div>;

        if (!isObjectEmpty(store.investorFundingData)) {
            store.investorFundingData.map((item, index) => {
                let amount = "-";

                if (item.amount) {
                    amount = displayAmount(item.amount);
                } else if (item.invested_amount) {
                    amount = displayAmount(item.invested_amount);
                }

                tableRows.push(
                    <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{item.name}</td>
                        <td>{amount}</td>
                        {(store.isEdit || store.isAdd) &&
                            <td>
                                <a tabIndex='1' className="btn-edit" onClick={() => this.handleEdit(item, index)}><i className='fas fa-pencil-alt'></i></a>&nbsp;&nbsp;|&nbsp;&nbsp;
                                <a tabIndex='2' className="btn-delete" onClick={() => this.handleDelete(item, index)} ><i className='fas fa-trash-alt'></i></a>
                            </td>
                        }
                    </tr>
                );
            });
        }

        if (tableRows.length > 0) {
            content = <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Amount</th>
                        {store.isEdit || store.isAdd &&
                            <th scope="col">Actions</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </table>;
        }

        return content;
    }

    render() {
        return (
            <div className="modal fade" id="investorsViewModal">
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">Investors</h4>
                            <button type="button" className="close" onClick={this.handleCloseModal}>&times;</button>
                        </div>

                        <div className="modal-body">
                            {this.renderData()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default observer(InvestorsViewModal);
