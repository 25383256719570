import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react';
import Select from 'react-select';

// constants
import { ACTIVE_STATUS, ALLOWED_QUERY_OPERATOR, SELECT_STYLES, SELECT_THEME_PRIMARY } from '../../constant';
import { ORGANIZATION_COLUMN_HEADERS } from '../../utility/constants/tableHeaders';
import { API_PATH } from '../../utility/constants/apiPath';

// helpers
import store from '../../store';
import { prepareSelectParam } from '../../utility/queryParams';

// components
import Parent from '../Parent';
import SideNav from '../SideNav';
import Home from '../Home';
import ActionRenderer from '../reusable/ActionRenderer';
import Modal from '../reusable/Modal';
import Sort from '../reusable/Sort';
import Pagination from '../reusable/Pagination';

class Organization extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: ORGANIZATION_COLUMN_HEADERS,
            context: { componentParent: this },
            frameworkComponents: {
                actionRenderer: ActionRenderer
            },
            defaultColDef: { suppressMovable: true },

            // filters
            name: '',
            active: '',
            filters: {
                name: [],
                active: []
            },
            filterFields: [
                { 'key': 'name', 'op': ALLOWED_QUERY_OPERATOR.substring, 'value': [] },
                { 'key': 'active', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
            ],
        }
        store.sortType = this.props.location.pathname.split('/')[1];
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit()
    }

    async componentDidMount() {

        const paramFields = ['id', 'name', 'country_data', 'active'];
        store.fields = prepareSelectParam(paramFields);

        this.fetchListData(API_PATH.organization, store.fields);
    }

    handleClearFilters = () => {
        this.setState({
            active: '',
            name: ''
        }, () => {
            this.handleClearAllFilters(API_PATH.organization);
        });
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>Organizations</h3>
                            <div className='filter-container'>

                                <div className='filter-row' style={{ paddingTop: 10 }}>
                                    <div ref={node => this.word = node} className="col-md-6 col-sm-6 col-xs-12 filter-firstline filter-spacing">
                                        <div>
                                            <input ref={node => this.input = node}
                                                className='filter-input input-border'
                                                placeholder="Filter by name"
                                                value={this.state.name}
                                                onFocus={() => this.setState({ showname: true })}
                                                onKeyUp={this.handleNameEnter}
                                                onChange={e => this.setState({ name: e.target.value })} />
                                            {
                                                this.state.showname && this.state.filters.name.length > 0 ?
                                                    <div className='filter-dropdown' style={{ width: '100%' }}>
                                                        <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                            {this.state.filters.name.map((word, i) => {
                                                                return <div key={i} className='filter-name' onClick={() => this.handleRemoveFilter(i, 'name')}>
                                                                    {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                                </div>
                                                            })}
                                                        </div>
                                                    </div> : null
                                            }
                                        </div>

                                        {
                                            this.state.name.length > 0 &&
                                            <i className="fa fa-times "
                                                style={{ paddingRight: 10 }}
                                                onClick={() => {
                                                    this.input.focus()
                                                    this.setState({ name: '' })
                                                }}>
                                            </i>
                                        }
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                        <Select
                                            placeholder='Filter by status'
                                            styles={SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            value={this.state.active}
                                            onChange={(option) => this.handleSingleSelectFilter(option, 'active')}
                                            options={ACTIVE_STATUS}
                                        />
                                    </div>

                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-6 filter-searchbtn" onClick={this.handleClearFilters}>Clear All</a>
                                    </div>
                                </div>

                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    defaultColDef={this.state.defaultColDef}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    frameworkComponents={this.state.frameworkComponents}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                />
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />
                    </div>
                }
            </div>
        )
    }
}


export default observer(Organization)
