import React from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react'
import store from '../../store'
import {
    API,
    USER_SUBSCRIPTION_HEADERS,
    ALLOWED_QUERY_OPERATOR
} from '../../constant'
import { prepareSelectParam } from '../../utility/queryParams'
import SideNav from '../SideNav'
import ActionRenderer from '../reusable/ActionRenderer'
import Parent from '../Parent'
import Modal from '../reusable/Modal'
import Home from '../Home'
import UserSubModal from '../reusable/UserSubModal'
import EndDateModal from '../reusable/EndDateModal'
import ErrorPopup from '../reusable/ErrorModal'

class UserSubList extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: USER_SUBSCRIPTION_HEADERS,
            context: { componentParent: this },
            frameworkComponents: { actionRenderer: ActionRenderer },
            defaultColDef: { suppressMovable: true },

            keyword: '',
            showKeywords: false,
            filters: {
                keyword: [],
            },
            filterFields: [
                { 'key': 'keyword', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
            ],
            name: 'User Subscription'
        }

        store.isSubAdded = false;
        store.route = this.props.location.pathname.replace(/\//, "");
    }

    onGridReady = (params) => {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    showModal() {
        $('#userSubModal').modal({
            backdrop: 'static',
            keyboard: true,
            show: true
        })
    }

    async componentDidMount() {
        store.showUserSub = false

        document.addEventListener('mousedown', (e) => {
            if (this.word) {
                if (!this.word.contains(e.target)) this.setState({
                    showKeywords: false
                })
            }
        })

        await this.fetchData()
    }

    fetchData = async () => {
        try {
            let paramFields = ['id', 'user_id', 'status', 'start_date', 'end_date', 'user_data']
            store.fields    = prepareSelectParam(paramFields)
            let data        = await fetch(`${API}/order/${store.currentId}/user-subscription?fields=${store.fields}`, {
                method: 'GET',
                headers: {
                    'Authorization': store.token,
                    'Content-Type': 'application/json'
                },
            })
            data = await data.json()

            if (data.status == 200) {
                this.handleResult(data)
                this.assignValue('order', data.data)
                this.getUserSubscriptionData(data.data);
            }
            else {
                console.error('API Error ', data.errors);
            }
        } catch (error) {
            console.error('Error ', error)
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => {
            if (this.word) {
                if (!this.word.contains(e.target)) this.setState({
                    showKeywords: false
                })
            }
        })
    }

    getUserSubscriptionData = (arrData) => {
        if (arrData.length === 0) {
            return;
        }

        const objTemp = arrData.filter(item => item.status == 1)[0];

        store.user_subscription_id = objTemp.id;
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>
                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>SUBSCRIPTION LIST</h3>
                            <div className='filter-container'>
                            </div>

                            {store.orderType === 'offline' && <button className="btn-secondary bottom-btn"
                                data-toggle="modal"
                                data-target='#userSubModal'
                                data-backdrop="static"
                                data-keyboard="false"
                                onClick={this.showModal}>ADD USER</button>}

                            <div style={{width: '50%', margin: '0px auto', textAlign: 'center'}}>
                                {store.isSubAdded && <div className="alert alert-success">
                                    <strong>Success!</strong> {'User Subscription Added Successfully'}
                                </div>}
                            </div>

                            <div id='grid' className='ag-theme-balham' style={{ width: '100%', color: '#5d656d', marginTop: 20 }}>
                                <AgGridReact
                                    reactNext={true}
                                    defaultColDef={this.state.defaultColDef}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    frameworkComponents={this.state.frameworkComponents}
                                    onGridReady={this.onGridReady}
                                    enableCellTextSelection={true}
                                >
                                </AgGridReact>
                            </div>

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />
                        {<UserSubModal gridApi={this.gridApi} fetchData={this.fetchData}/>}
                        <EndDateModal fetchData={this.fetchData}/>
                        <ErrorPopup/>
                    </div>
                }

            </div>
        )
    }
}
export default observer(UserSubList)
