import axios from 'axios';

import { API } from '../constant';
import store from '../store';
import { prepareSelectParam } from '../utility/queryParams';

const fundingApi = {

    getFundingDetails: (fundingId) => {
        const paramFields = ['id', 'entity_type', 'date_raised', 'amount_raised', 'pre_money_valuation', 'post_money_valuation', 'funding_stage_data', 'funding_breakdowns_data', 'investors_data', 'investedin_data',];
        const fields = prepareSelectParam(paramFields);

        const url = `${API}/funding/${fundingId}?fields=${fields}`;
        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(
                    response => {
                        if (response.data.status === 200) {
                            resolve(response.data.data);
                        } else {
                            resolve({});
                        }
                    }
                )
                .catch((err) => reject(err));
        });
    },

    getFundingBreakdownDetails: (fundingBreakdownId) => {
        const paramFields = ['id', 'funding_id', 'date_raised', 'amount', 'verification_status', 'comment', 'source_url', 'investment_vehicle_data', 'location_data', 'source_type_data', 'investors_data'];
        const fields = prepareSelectParam(paramFields);

        const url = `${API}/funding-breakdown/${fundingBreakdownId}?fields=${fields}`;
        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(
                    response => {
                        if (response.data.status === 200) {
                            resolve(response.data.data);
                        } else {
                            resolve({});
                        }
                    }
                )
                .catch((err) => reject(err));
        });
    },

    getInvestorFundingDetails: (investorFundingId) => {
        const paramFields = ['id', 'funding_breakdown_id', 'deal_term_data', 'entity_data', 'status', 'is_lead', 'invested_amount', 'investment_date', 'fund_data'];
        const fields = prepareSelectParam(paramFields);

        const url = `${API}/investor-funding/${investorFundingId}?fields=${fields}`;
        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(
                    response => {
                        if (response.data.status === 200) {
                            resolve(response.data.data);
                        } else {
                            resolve({});
                        }
                    }
                )
                .catch((err) => reject(err));
        });
    },

    updateFundingStatus: (fundingBreakdownId, payload) => {
        const url = `${API}/funding-breakdown/${fundingBreakdownId}/verify`;
        const headers = {
            Authorization: store.token
        };

        return new Promise((resolve, reject) => {
            axios.post(url, payload, { headers })
                .then(response => {
                    if (response.data.status === 200) {
                        resolve(response.data.data)
                    } else {
                        reject({})
                    }
                }).catch(err => reject(err))
        })
    },

}

export default fundingApi;