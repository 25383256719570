
import React, { PureComponent } from 'react';
import store from '../../../store';

class DefaultFilterModalActionRenderer extends PureComponent {
    showModal = () => {
        $('#defaultFilterModal').modal({
            backdrop: 'static',
            keyboard: true,
            show: true
        })
        // pass the modal data
        store.defaultFilterModalData = this.props?.data?.default_filter;
    }

    render() {
        return (
            <a tabIndex="1" className="btn-edit" onClick={this.showModal}>View Default Filters</a>
        )
    }
}

export default DefaultFilterModalActionRenderer;
