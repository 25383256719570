import React, { Component } from 'react';
import moment from 'moment';

// constants
import { API, DATE_FORMAT, ALLOWED_QUERY_OPERATOR, POLICY_PERIODS, MIN_SEARCH_LENGTH, EXPORT_REQUEST_STATUS_VALUES, DEMO_REQUEST_STATUS_VALUES, FUNDING_STATUS_LABELS, ORDER_TYPES_VALUE, PRODUCT_API } from '../constant';
import { API_PATH } from '../utility/constants/apiPath';

// helpers
import store from '../store'
import { prepareFilterParam } from '../utility/queryParams';
import { displayAmount, isObjectEmpty } from '../utility/helpers';

// apis
import { getUsers } from '../utility/commonApis';
import { VALUATION_CALCULATION_STATUS_OPTIONS, UNDISCLOSED_PROXY_LOGS_STATUS_OPTIONS } from '../utility/constants/selectOptions';

export default class Parent extends Component {

    assignValue = (entity, data) => {
        let arr = []

        switch (entity) {
        case 'startup':
            data.map(item => {
                let industries = []
                if (item.industry_data.length > 0) {
                    item.industry_data.map(item => {
                        industries.push(item.name)
                    })
                }
                arr.push({
                    "id": item.id,
                    "name": item.name,
                    "industry": !isObjectEmpty(item.industry_data) ? industries.join(',') : '',
                    "country": !isObjectEmpty(item.hq_data) ? item.hq_data.name : '',
                    "owner": !isObjectEmpty(item.owned_by_data) ? `${item.owned_by_data.first_name} ${item.owned_by_data.last_name}` : '',
                    "date_founded": item.date_founded !== null ? moment(item.date_founded).format(DATE_FORMAT) : item.date_founded,
                    "total_funding": !isObjectEmpty(item.fund_raised_data) ? displayAmount(item.fund_raised_data.amount) : '',
                    "created_on": item.created_at !== null ? moment(item.created_at).format(DATE_FORMAT) : item.created_at,
                    "updated_on": item.updated_at !== null ? moment(item.updated_at).format(DATE_FORMAT) : item.updated_at
                })
            })
            break;
        case 'investor':
            data.map(item => {
                let industries = []
                if (item.industry_data.length > 0) {
                    item.industry_data.map(item => {
                        industries.push(item.name)
                    })
                }
                arr.push({
                    "id": item.id,
                    "name": item.name,
                    "investor_type": !isObjectEmpty(item.type_data) ? item.type_data.name : '-',
                    "country": !isObjectEmpty(item.hq_data) ? item.hq_data.name : '-',
                    "industry": !isObjectEmpty(item.industry_data) ? industries.join(',') : '-',
                    "owner": !isObjectEmpty(item.owned_by_data) ? `${item.owned_by_data.first_name} ${item.owned_by_data.last_name}` : '-',
                    "created_on": item.created_at !== null ? moment(item.created_at).format(DATE_FORMAT) : item.created_at,
                    "updated_on": item.updated_at !== null ? moment(item.updated_at).format(DATE_FORMAT) : item.updated_at
                })
            })
            break;
        case 'fund':
            data.map(item => {
                arr.push({
                    "id": item.id,
                    "name": item.name,
                    "fund_manager": !isObjectEmpty(item.entity_data) ? item.entity_data.name : '-',
                    "entity_id": item.entity_id,
                    "entity_type": item.entity_type,
                    "active": item.status === 1 ? 'YES' : 'NO',
                    "created_at": item.created_at !== null ? moment(item.created_at).format(DATE_FORMAT) : item.created_at,
                    "updated_at": item.updated_at !== null ? moment(item.updated_at).format(DATE_FORMAT) : item.updated_at
                })
            })
            break;
        case 'enabler':
            data.map(item => {
                let industries = []
                if (item.industry_data.length > 0) {
                    item.industry_data.map(item => {
                        industries.push(item.name)
                    })
                }
                arr.push({
                    "id": item.id,
                    "name": item.name,
                    "enabler_type": !isObjectEmpty(item.type_data) ? item.type_data.name : '-',
                    "country": !isObjectEmpty(item.hq_data) ? item.hq_data.name : '-',
                    "industry": !isObjectEmpty(item.industry_data) ? industries.join(',') : '-',
                    "owner": !isObjectEmpty(item.owned_by_data) ? `${item.owned_by_data.first_name} ${item.owned_by_data.last_name}` : '-',
                    "created_on": item.created_at !== null ? moment(item.created_at).format(DATE_FORMAT) : item.created_at,
                    "updated_on": item.updated_at !== null ? moment(item.updated_at).format(DATE_FORMAT) : item.updated_at
                })
            })
            break;
        case 'corporate':
            data.map(item => {
                let industries = []
                if (item.industry_data.length > 0) {
                    item.industry_data.map(item => {
                        industries.push(item.name)
                    })
                }
                arr.push({
                    "id": item.id,
                    "name": item.name,
                    "corporate_type": !isObjectEmpty(item.type_data) ? item.type_data.name : '-',
                    "country": !isObjectEmpty(item.hq_data) ? item.hq_data.name : '-',
                    "industry": !isObjectEmpty(item.industry_data) ? industries.join(',') : '-',
                    "owner": !isObjectEmpty(item.owned_by_data) ? `${item.owned_by_data.first_name} ${item.owned_by_data.last_name}` : '-',
                    "created_on": item.created_at !== null ? moment(item.created_at).format(DATE_FORMAT) : item.created_at,
                    "updated_on": item.updated_at !== null ? moment(item.updated_at).format(DATE_FORMAT) : item.updated_at
                })
            })
            break;
        case 'user':
            data.map(item => {
                let email = []
                if (item && item.email_data && item.email_data.length > 0) {
                    item.email_data.map(item => {
                        email.push(item.email)
                    })
                }
                arr.push({
                    "id": item.id,
                    "first_name": item.first_name !== null ? item.first_name : '-',
                    "last_name": item.last_name !== null ? item.last_name : '-',
                    "username": item.username !== null ? item.username : '-',
                    "status": item.status == 0 ? "Invited" : item.status == 1 ? 'Active' : 'Suspended',
                    "email": email.length > 0 ? email.join(',') : '-',
                    "last_login": item.last_login ? moment(item.last_login).format(DATE_FORMAT) : "-",
                    "created_on": item.created_at ? moment(item.created_at).format(DATE_FORMAT) : "-"
                })
            })
            break;
        case 'job':
            data.map(item => {
                arr.push({
                    "id": item.id,
                    "title": item.title,
                    "entity_id": item.entity_data !== null ? item.entity_data.name : '',
                    "type_id": item.type_data !== null ? item.type_data.name : '',
                    "primary_role_id": item.primary_role_data !== null ? item.primary_role_data.name : '',
                    "deadline": item.deadline !== null ? moment(item.deadline).format(DATE_FORMAT) : ''
                })
            })
            break;
        case 'news':
            let newsStatus
            data.map(item => {
                if (item.is_published !== null) newsStatus = item.is_published === 0 ? 'Draft' : item.is_published === 1 ? 'Published' : 'UnPublished'

                let categoryData = {};
                if (item.news_category_data && item.news_category_data.length > 0) {
                    categoryData = item.news_category_data[0];
                }

                arr.push({
                    "id": item.id,
                    "title": item.title !== null ? item.title : '',
                    "category": !isObjectEmpty(categoryData) ? categoryData.name : '',
                    "author": item.author !== null ? item.author : '',
                    "status": newsStatus,
                    "featured": item.is_featured !== null ? item.is_featured === 1 ? 'Yes' : 'No' : '',
                    "post_date": item.published_at !== null ? moment(item.published_at).format(DATE_FORMAT) : '',
                })
            })
            break;
        case 'research':
            let status
            data.map(item => {
                let industries = [];
                if (item.industry_data.length > 0) {
                    item.industry_data.map((item) => {
                        industries.push(item.name);
                    });
                }

                if (item.is_published !== null) status = item.is_published === 0 ? 'Draft' : item.is_published === 1 ? 'Published' : 'UnPublished'
                arr.push({
                    "id": item.id,
                    "title": item.name !== null ? item.name : '',
                    "category": !isObjectEmpty(item.category_data) ? item.category_data.name : '',
                    "image": !isObjectEmpty(item.image_data) ? item.image_data.path : '',
                    "price": item.price !== null ? item.price : '',
                    "industry": !isObjectEmpty(item.industry_data) ? industries.join(",") : "",
                    "status": status,
                    "total_download": item.total_download !== null ? item.total_download : '',
                    "published_date": item.published_at !== null ? moment(item.published_at).format(DATE_FORMAT) : '',
                })
            })
            break;
        case 'user-role':
            data.map(item => {
                let email = []
                if (item.email_data.length > 0) {
                    item.email_data.map(item => {
                        email.push(item.email)
                    })
                }
                arr.push({
                    "id": item.id,
                    "email": item.email_data.length > 0 ? email.join(',') : '',
                    "role_id": !isObjectEmpty(item.role_data) ? item.role_data.id : '',
                    "role_name": !isObjectEmpty(item.role_data) ? item.role_data.name : '',
                    "access_type": !isObjectEmpty(item.role_data) ? item.type : '',
                })
            })
            break;
        case 'banner':
            data.map(item => {
                arr.push({
                    "id": item.id,
                    "name": item.name !== null ? item.name : '',
                    "start_date": item.start_date !== null ? moment(item.start_date).format(DATE_FORMAT) : '',
                    "end_date": item.end_date !== null ? moment(item.end_date).format(DATE_FORMAT) : '',
                    "desk_image": !isObjectEmpty(item.desktop_img_data) ? item.desktop_img_data.path : '',
                    "mobile_image": !isObjectEmpty(item.responsive_img_data) ? item.responsive_img_data.path : ''
                })
            })
            break;
        case 'funding-verification':
            data.map(item => {
                let created_by_type = "-";
                if (item.created_by_admin) {
                    created_by_type = "ADMIN";
                } else if (!isObjectEmpty(item.investing_entity_data)) {
                    created_by_type = item.investing_entity_data.entity_type;
                } else {
                    created_by_type = item.entity_type ? item.entity_type : "-";
                }

                arr.push({
                    "id": item.id,
                    "funding_id": item.funding_id,
                    "entity_id": !isObjectEmpty(item.investedin_data) ? item.investedin_data.id : '-',
                    "entity_name": !isObjectEmpty(item.investedin_data) ? item.investedin_data.name : '-',
                    "stage": !isObjectEmpty(item.funding_stage_data) ? item.funding_stage_data.name : '-',
                    "investment_vehicle": !isObjectEmpty(item.investment_vehicle_data) ? item.investment_vehicle_data.name : '-',
                    "date": item.date_raised ? moment(item.date_raised).format(DATE_FORMAT) : '-',
                    "funding_amount": item.amount !== null ? displayAmount(item.amount) : '-',
                    "verified_at": item.verified_at ? moment(item.verified_at).format(DATE_FORMAT) : '-',
                    "verified_by": !isObjectEmpty(item.verified_by_data) ? `${item.verified_by_data.first_name} ${item.verified_by_data.last_name}` : '-',
                    "verification_status": item.verification_status !== null ? FUNDING_STATUS_LABELS[item.verification_status] : '-',
                    "funding_status": item.verification_status,
                    "source_url": item.source_url,
                    "comment": item.comment,
                    "created_by": !isObjectEmpty(item.created_by_data) ? `${item.created_by_data.first_name} ${item.created_by_data.last_name}` : '-',
                    "created_by_type": created_by_type,
                    "investors": item.investors_data,
                })
            })

            store.data = data;
            break;
        case API_PATH.fundingRequests:
        case 'funding-round-requests':
            data.map(item => {
                // extract funding_breakdown_id & investors data
                let fundingBreakdownId = '-';
                let investorsData = '-';
                if (item.funding_breakdowns_data && item.funding_breakdowns_data.length > 0) {
                    fundingBreakdownId = item.funding_breakdowns_data[0].funding_breakdown_id;
                    investorsData = item.funding_breakdowns_data[0].investors_data;
                }

                arr.push({
                    "id": item.id,
                    "startup_id": !isObjectEmpty(item.investedin_data) ? item.investedin_data.id : '-',
                    "funding_id": item.funding_id ? item.funding_id : '-',
                    "funding_breakdown_id": fundingBreakdownId,
                    "entity_id": item.entity_id ? item.entity_id : '-',
                    "entity_type": item.entity_type ? item.entity_type : '-',
                    "startup_name": !isObjectEmpty(item.investedin_data && item.investedin_data.name) ? item.investedin_data.name : '-',
                    "stage": !isObjectEmpty(item.funding_stage_data && item.funding_stage_data.name) ? item.funding_stage_data.name : '-',
                    "requestor": !isObjectEmpty(item.entity_data) ? item.entity_data.name : '-',
                    'amount_invested': item.invested_amount ? displayAmount(item.invested_amount) : '-',
                    "investors_data": investorsData
                })
            })

            store.data = data
            break
        case 'subscription':
            data.map(item => {
                const currency = item.currency ? item.currency : '';
                arr.push({
                    "id": item.id,
                    "name": item.name !== null ? item.name : '',
                    "type": item.type_data !== null ? item.type_data.name : '',
                    "duration": item.duration !== null ? item.duration : '',
                    "price": item.price !== null ? `${item.price} ${currency}` : '',
                    "status": item.active !== null ? item.active : ''
                })
            })
            break;
        case 'rd-policy':
            data.map(item => {

                let period = 'N/A';
                if (item.period) {
                    const filteredPeriods = POLICY_PERIODS.filter(periodItem => periodItem.value === item.period);
                    if (filteredPeriods.length > 0) {
                        period = filteredPeriods[0].label;
                    }
                }

                arr.push({
                    "id": item.id,
                    "role": !isObjectEmpty(item.role_data) ? item.role_data.name : 'N/A',
                    "download_limit": item.download_limit !== null ? item.download_limit : 'N/A',
                    "period": period,
                    "weight": item.weight !== null ? item.weight : 'N/A',
                    "status": item.active == 0 ? 'No'  : 'Yes',
                    "policy": (item.policy && item.policy.length > 0) ? item.policy : 'N/A'
                })
            })
            break;
        case 'export-request':
            data.map(item => {
                let subscribed = 'N/A'
                let subscriptionType = 'N/A'
                let status = 'N/A'

                if (!isObjectEmpty(item.subscription_data)) {
                    subscriptionType = item.subscription_data.subscription_name
                    subscribed = 'Yes'
                } else {
                    subscribed = 'No'
                }

                if (item.status !== null) {
                    if (item.status == EXPORT_REQUEST_STATUS_VALUES.pending) {
                        status = 'Pending'
                    } else if (item.status == EXPORT_REQUEST_STATUS_VALUES.contacted) {
                        status = 'Contacted'
                    } else if (item.status == EXPORT_REQUEST_STATUS_VALUES.converted) {
                        status = 'Converted'
                    }
                }

                arr.push({
                    "id": item.id,
                    "name": !isObjectEmpty(item.user_data) ? `${item.user_data.first_name} ${item.user_data.last_name}` : 'N/A',
                    "email": !isObjectEmpty(item.user_email) ?  item.user_email.email : 'N/A',
                    "status": status,
                    "comments": item.comment !== null ? item.comment : '',
                    "subscribed": subscribed,
                    "subscriptionType": subscriptionType,
                })
            })
            break;
        case 'demo-request':
            data.map(item => {
                let subscribed = 'N/A'
                let status = 'N/A'

                if (item.is_subscribed !== null) {
                    subscribed = item.is_subscribed == 1 ? 'Yes' : 'No'
                }

                if (item.status !== null) {
                    if (item.status == DEMO_REQUEST_STATUS_VALUES.pending) {
                        status = 'Pending'
                    } else if (item.status == DEMO_REQUEST_STATUS_VALUES.contacted) {
                        status = 'Contacted'
                    } else if (item.status == DEMO_REQUEST_STATUS_VALUES.converted) {
                        status = 'Converted'
                    }
                }

                arr.push({
                    "id": item.id,
                    "requestDate": item.request_date !== null ? item.request_date : 'N/A',
                    "email": item.email !== null ?  item.email : 'N/A',
                    "status": status,
                    "comments": item.comments !== null ? item.comments : '',
                    "subscribed": subscribed,
                })
            })
            break;
        case 'order':
            if (store.isOrderList) {
                data.map(item => {
                    const monthsString = item.subscription_type_data.duration ? `for ${item.subscription_type_data.duration} month(s)` : "";
                    let amountPaid;
                    let totalAmountPaid;

                    if (item.duration == 0.25) {
                        amountPaid = `${item.transaction_data.currency} 0`
                        totalAmountPaid = `${item.transaction_data.currency} 0`
                    } else {
                        amountPaid = !isObjectEmpty(item.transaction_data) ? `${item.transaction_data.currency} ${item.transaction_data.amount.toLocaleString()}` : 'N/A';
                        totalAmountPaid = !isObjectEmpty(item.transaction_data) ? `${item.transaction_data.currency} ${item.transaction_data.total_amount.toLocaleString()}` : 'N/A';
                    }

                    let type = '-';
                    if (!isObjectEmpty(item.subscription_type_data)) {
                        type = `${item.subscription_type_data.name} ${monthsString}`;
                    } else if (item.type == ORDER_TYPES_VALUE.dataExport) {
                        type = 'Data Export';
                    } else if (item.type == ORDER_TYPES_VALUE.apiProduct) {
                        type = 'API Product';
                    }

                    let endDate = '-';
                    if (!isObjectEmpty(item.organization_subscription_data)) {
                        endDate = moment(item.organization_subscription_data.end_date).format(DATE_FORMAT);
                    } else if (!isObjectEmpty(item.user_subscription_data)) {
                        endDate = moment(item.user_subscription_data.end_date).format(DATE_FORMAT);
                    }

                    let user = '-';
                    if (!isObjectEmpty(item.organization_subscription_data) && !isObjectEmpty(item.user_data)) {
                        user = `${item.organization_subscription_data.name} - ${item.user_data.first_name} ${item.user_data.last_name} - ${item.user_data.email}`;
                    } else if (!isObjectEmpty(item.organization_subscription_data)) {
                        user = item.organization_subscription_data.name;
                    } else if (!isObjectEmpty(item.user_data)) {
                        user = `${item.user_data.first_name} ${item.user_data.last_name} - ${item.user_data.email}`;
                    }

                    arr.push({
                        "id": item.id,
                        "user_sub_type_id": !isObjectEmpty(item.user_subscription_data) ? item.user_subscription_data.id : '',
                        "user": user,
                        "type": type,
                        "type_id": item.type,
                        "sub_type_id": !isObjectEmpty(item.subscription_type_data) ? item.subscription_type_data.id : '',
                        "amount_paid": amountPaid,
                        "first_amount_paid": amountPaid,
                        "total_amount_paid": totalAmountPaid,
                        "promo_amount": (!isObjectEmpty(item.transaction_data) && item.transaction_data.promo_amount) ? `${item.transaction_data.currency} ${item.transaction_data.promo_amount.toLocaleString()}` : '-',
                        "promo_code": !isObjectEmpty(item.promo_data) ? item.promo_data.code : '-',
                        "start_date": item.created_at !== null ? moment(item.created_at).format(DATE_FORMAT) : '-',
                        "end_date": endDate,
                        "status": item.active !== null ? item.active : '-',
                        "total_users": item.number_of_users !== null ? item.number_of_users : '-',
                        "created_on": item.created_at !== null ? moment(item.created_at).format(DATE_FORMAT) : item.created_at,
                    })
                })
            }
            else {
                if (data && data.length > 0) {
                    data.map(item => {
                        let note = `Expires in`;
                        let unit;
                        if (item.end_date) {
                            const startDate = new Date().valueOf();
                            const endDate = new Date(item.end_date).valueOf();
                            const tempDays = ((endDate - startDate) / (1000 * 60 * 60 * 24));
                            const tempMonths = ((endDate - startDate) / (1000 * 60 * 60 * 24 * 30));
                            const intMonths = Math.ceil(tempMonths);
                            const diff = (intMonths - tempMonths);
                            const months = (intMonths - 1);
                            const days = Math.ceil(30 - (diff * 30));

                            if (months > 0) {
                                unit = months == 1 ? 'month' : 'months';
                                note = `${note} ${months} ${unit}`;
                            }
                            if (months >= 0 && days > 0) {
                                unit = days == 1 ? 'day' : 'days';
                                note = `${note} ${days} ${unit}`;
                            }

                            if (months < 0 || tempDays < 0) {
                                note = 'Expired';
                            }
                        }
                        else {
                            note = 'No expiry'
                        }

                        if (item.status == 2) {
                            note = 'Deleted'
                        }

                        // @todo: Will look into it later
                        arr.push({
                            "id": item.id,
                            "name": !isObjectEmpty(item.user_data) ? `${item.user_data.first_name} ${item.user_data.last_name}` : 'N/A',
                            "user_id": item.user_id,
                            "user": item.user_data ? `${item.user_data.first_name} ${item.user_data.last_name} - ${item.user_data.email}` : '',
                            "status": item.status,
                            "start_date": item.start_date ? moment(item.start_date).format(DATE_FORMAT) : '',
                            "end_date": item.end_date ? moment(item.end_date).format(DATE_FORMAT) : '',
                            "note": note
                        })
                    })
                }
            }

            break;
        case 'taxonomy-type':
            data.map(item => {
                arr.push({
                    "id": item.id,
                    "name": item.name
                })
            })
            break;
        case 'location':
            data.map(item => {
                arr.push({
                    "id": item.id,
                    "name": item.name
                })
            })
            break;
        case 'industry':
            data.map(item => {
                arr.push({
                    "id": item.id,
                    "name": item.name,
                    "parent_id": item.parent_id
                })
            })
            break;
        case 'research-taxonomy':
        case 'team-role':
            data.map(item => {
                arr.push({
                    "id": item.id,
                    "name": item.name
                })
            })
            break;
        case 'role':
            data.map(item => {
                arr.push({
                    "id": item.id,
                    "name": item.name,
                    "type": item.type,
                    "created_on": item.created_at !== null ? moment(item.created_at).format('DD/MM/YYYY') : item.created_at,
                    "updated_on": item.updated_at !== null ? moment(item.updated_at).format('DD/MM/YYYY') : item.updated_at
                })
            });
            break;
        case 'resource':
            data.resources.map(item => {
                arr.push({
                    "id": item.id,
                    "code": item.code,
                    "name": item.name,
                    "method": item.method,
                    "operation": item.operation,
                    "authenticate": item.authenticate ? "Yes" : "No",
                    "authorize": item.authorize ? "Yes" : "No",
                    "active": item.active,
                })
            });
            break;
        case 'promotion':
            data.map(item => {
                arr.push({
                    "id": item.id,
                    "code": item.code,
                    "description": item.description ? item.description : "N/A",
                    "start_date": item.start_date ? moment(item.start_date).format('DD/MM/YYYY') : 'N/A',
                    "end_date": item.end_date ? moment(item.end_date).format('DD/MM/YYYY') : 'N/A',
                    "created_on": item.created_at !== null ? moment(item.created_at).format('DD/MM/YYYY') : item.created_at,
                    "updated_on": item.updated_at !== null ? moment(item.updated_at).format('DD/MM/YYYY') : item.updated_at
                })
            });
            break;
        case 'permission':
            data.map(item => {
                arr.push({
                    "id": item.id,
                    "resource": !isObjectEmpty(item.resource_data) ? item.resource_data.name : '',
                    "code": !isObjectEmpty(item.resource_data) ? item.resource_data.code : '',
                    "role": !isObjectEmpty(item.role_data) ? item.role_data.name : '',
                    "created_by": !isObjectEmpty(item.created_by_data) ? `${item.created_by_data.first_name} ${item.created_by_data.last_name}` : '',
                    "created_on": item.created_at !== null ? moment(item.created_at).format('DD/MM/YYYY') : '',
                    "updated_on": item.updated_at !== null ? moment(item.updated_at).format('DD/MM/YYYY') : ''
                })
            });
            break;
        case 'ecosystem':
            data.map(item => {

                let verified = '';
                if (item.is_verified == 1) {
                    verified = 'Verified';
                } else if (item.is_verified == 0 && item.verified_at) {
                    verified = 'Un-verified';
                } else {
                    verified = '-';
                }

                arr.push({
                    "id": item.id,
                    "entity_type": item.ecosystem_type.charAt(0).toUpperCase() + item.ecosystem_type.slice(1).toLowerCase(),
                    "name": item.name,
                    "type": !isObjectEmpty(item.type_data) ? item.type_data.name : '-',
                    "owner": !isObjectEmpty(item.owned_by_data) ? `${item.owned_by_data.first_name} ${item.owned_by_data.last_name}` : '-',
                    "created_at": moment(item.created_at).format('DD/MM/YYYY'),
                    "is_verified": verified,
                    "verify_status": item.is_verified,
                    "verified_at": item.verified_at ? moment(item.verified_at).format('DD/MM/YYYY') : '-',
                    "verified_by": !isObjectEmpty(item.verified_by_data) ? `${item.verified_by_data.first_name} ${item.verified_by_data.last_name}` : '-',
                })
            });
            break;
        case 'undisclosed-proxy':
            data.map(item => {

                arr.push({
                    "id": item.id,
                    "year": item.year ? item.year : '-',
                    "value": item.value ? item.value : '-',
                    "funding_stage": !isObjectEmpty(item.funding_stage_data) ? item.funding_stage_data.name : '-',
                    "created_by": !isObjectEmpty(item.created_by_data) ? `${item.created_by_data.first_name} ${item.created_by_data.last_name}` : '-',
                    "created_at": item.created_at ? moment(item.created_at).format('DD/MM/YYYY') : '-',
                    "updated_at": item.updated_at ? moment(item.updated_at).format('DD/MM/YYYY') : '-'
                })
            })
            break
        case API_PATH.dataExportPolicy:
            data.map(item => {

                let period = '-';
                if (item.period) {
                    const filteredPeriods = POLICY_PERIODS.filter(periodItem => periodItem.value === item.period);

                    if (filteredPeriods.length > 0) {
                        period = filteredPeriods[0].label;
                    }
                }

                arr.push({
                    "id": item.id,
                    "role": !isObjectEmpty(item.role_data) ? item.role_data.name : '-',
                    "period": period,
                    "policy": (item.policy && item.policy.length > 0) ? item.policy : '-',
                    "active": item.active ? 'Yes' : 'No',
                    "records_limit": item.records_limit ? item.records_limit : '-',
                })
            })
            break
        case API_PATH.dataExportLog:
            data.map(item => {

                const details = {
                    fields: item.fields ? item.fields.join(', ') : '-',
                    filters: item.filters ? item.filters : '-',
                    sort: item.sort ? item.sort : '-'
                }

                arr.push({
                    "id": item.id,
                    "user_id": !isObjectEmpty(item.user_data) ? item.user_data.id : '-',
                    "username": !isObjectEmpty(item.user_data) ? item.user_data.name : '-',
                    "order_id": item.order_id ? item.order_id : '-',
                    "subscription": !isObjectEmpty(item.order_data) ? item.order_data.subscription_name : '-',
                    "number_of_records": item.number_of_records ? item.number_of_records : '-',
                    "data_set_type": item.data_set_type ? item.data_set_type : '-',
                    "is_custom": item.is_custom ? 'Yes' : '-',
                    "details": details,
                    "request_date": item.request_date ? moment(item.request_date).format('DD/MM/YYYY') : '-',
                    "product_data": !isObjectEmpty(item.product_data) ? item.product_data : {},
                })
            })
            break
        case API_PATH.dataExportProduct:
            data.map(item => {
                arr.push({
                    "id": item.id,
                    "data_set_type": item.data_set_type ? item.data_set_type : '-',
                    "description": item.description ? item.description : '-',
                    "active": item.active ? 'Yes' : 'No',
                })
            })
            break
        case API_PATH.funding:
            data.map(item => {
                arr.push({
                    "id": item.id,
                    "startup_id": !isObjectEmpty(item.investedin_data) ? item.investedin_data.id : '-',
                    "startup": !isObjectEmpty(item.investedin_data) ? item.investedin_data.name : '-',
                    "amount": item.amount_raised !== null ? displayAmount(item.amount_raised) : '-',
                    "post_money_valuation": item.post_money_valuation !== null ? displayAmount(item.post_money_valuation) : '-',
                    "stage": !isObjectEmpty(item.funding_stage_data) ? item.funding_stage_data.name : '-',
                    "created_by": !isObjectEmpty(item.created_by_data) ? `${item.created_by_data.first_name} ${item.created_by_data.last_name}` : '-',
                    "created_at": item.created_at !== null ? moment(item.created_at).format(DATE_FORMAT) : '-',
                    "funding_breakdowns_count": item.funding_breakdowns_count,
                    "investors": item.investors_data,
                })
            });

            store.data = data;

            break;
        case API_PATH.newsUTM:
            data.map(item => {
                arr.push({
                    "id": item.id,
                    "description": item.description ? item.description : '-',
                    "category": !isObjectEmpty(item.category_data) ? item.category_data.name : '-',
                    "utm_link": item.utm_link ? item.utm_link : '-'
                });
            });
            break;
        case API_PATH.magnittDepartment:
            data.map(item => {
                arr.push({
                    "id": item.id,
                    "name": item.name !== null ? item.name : '-',
                    "description": item.description ? item.description : '-',
                    "goal": item.goal ? item.goal : '-',
                    "background_image": !isObjectEmpty(item.background_image_data) ? item.background_image_data.path : ''
                })
            })
            break;
        case API_PATH.organization:
            data.map(item => {
                arr.push({
                    "id": item.id,
                    "name": item.name !== null ? item.name : '-',
                    "status": item.active === 1 ? 'Active' : 'In-active',
                    "location": !isObjectEmpty(item.country_data) ? item.country_data.name : '-'
                });
            });
            break;
        case API_PATH.customDashboard:
            data.map(item => {
                arr.push({
                    "id": item.id,
                    "name": item.name !== null ? item.name : '-',
                    "organization_id": item.organization_id !== null ? item.organization_id : '-',
                    "description": item.description ? item.description : '-',
                    "tableau_workbook_name": item.tableau_workbook_name  ? item.tableau_workbook_name : '-',
                    "tableau_view_name": item.tableau_view_name  ? item.tableau_view_name : '-',
                    "tableau_site_id": item.tableau_site_id  ? item.tableau_site_id : '-',
                    "iframe_width": item.iframe_width  ? item.iframe_width : '-',
                    "iframe_height": item.iframe_height  ? item.iframe_height : '-',
                    "organization_name": !isObjectEmpty(item.organization_data) ? item.organization_data.name : '-',
                    "status": item.status == 1 ? 'Active' : 'In-active'
                });
            });
            break;
        case API_PATH.organizationRole:
            data.map(item => {
                arr.push({
                    "id": item.id,
                    "name": !isObjectEmpty(item.organization_data) ? item.organization_data.name : '-',
                    "role": !isObjectEmpty(item.role_data) ? item.role_data.name : '-',
                    "roleId": !isObjectEmpty(item.role_data) ? item.role_data.id : '-',
                    "subscriptionId": item.subscription_id !== null ? item.subscription_id : '-'
                });
            });
            break;
        case API_PATH.apiProduct:
            data.map(item => {
                let price = '-';

                if (item.price !== null) {
                    const currency = item.currency !== null ? item.currency : 'USD';

                    price = `${item.price} ${currency}`;
                }

                let status = 'Not active';
                if (item.active !== null) {
                    status = item.active ? 'Active' : 'Not active';
                }

                arr.push({
                    "id": item.id,
                    "name": item.name !== null ? item.name : '-',
                    "duration": item.duration !== null ? item.duration : '-',
                    "status": status,
                    "policy": !isObjectEmpty(item.policy) ? item.policy : null,
                    "price": price
                });
            });
            break;
        case API_PATH.apiClient:
            data.map(item => {
                arr.push({
                    "id": item._id,
                    "name": item.name !== null ? item.name : '-',
                    "clientId": item.client_id !== null ? item.client_id : '-',
                    "user": !isObjectEmpty(item.user_data) ? item.user_data.name : '-',
                    "organization": !isObjectEmpty(item.organization_data) ? item.organization_data.name : '-',
                });
            });
            break;
        case API_PATH.subscriptionAddon:
            data.map(item => {
                arr.push({
                    "id": item.id,
                    "name": item.name !== null ? item.name : '-',
                    "active": item.active ? 'Yes' : 'No',
                    "price": item.price ? item.price : '-'
                });
            });
            break;
        case API_PATH.valuationCalculation:
            data.map(item => {
                arr.push({
                    "id": item.id,
                    "stage": !isObjectEmpty(item.funding_stage_data) ? item.funding_stage_data.name : '-',
                    "lower_share_percentage": item.lower_share_percentage !== null ? item.lower_share_percentage : '-',
                    "upper_share_percentage": item.upper_share_percentage !== null ? item.upper_share_percentage : '-',
                    "equity_percentage": item.equity_percentage !== null ? item.equity_percentage : '-',
                    "description": item.description !== null ? item.description : '-',
                    "active": item.active ? 'Yes' : 'No',
                });
            });

            store.data = data;

            break;
        case API_PATH.clientDirectory:
            data.map(item => {
                arr.push({
                    "id": item.id,
                    "title": item.title !== null ? item.title : '-',
                    "logo_id": item.logo_id !== null ? item.logo_id : '-',
                    "type": item.type !== null ? item.type : '-',
                    "organization_id": item.organization_id !== null ? item.organization_id : '-',
                    "organization_name": item.organization_name !== null ? item.organization_name : '-',
                    "description": item.description !== null ? item.description : '-',
                    "status": item.status ? 'Active' : 'Inactive',
                    'default_filter': item.default_filter  !== null  ? item.default_filter : '-',
                    'brand_theme': item.brand_theme  !== null  ? item.brand_theme : '-',
                });
            });

            store.data = data;

            break;
        case API_PATH.salesAutomationEmail:
            data.map(item => {
                arr.push({
                    "id": item.id,
                    "taxonomy_id": item.taxonomy_id !== null ? item.taxonomy_id : '-',
                    "taxonomy_name": item.taxonomy_name !== null ? item.taxonomy_name : '-',
                    "taxonomy_type": item.taxonomy_type !== null ? item.taxonomy_type : '-',
                    "taxonomy_type_name": item.taxonomy_type_name !== null ? item.taxonomy_type_name : '-',
                    "email_template_id": item.email_template_id !== null ? item.email_template_id : '-',
                    "email_template_name": item.email_template_name !== null ? item.email_template_name : '-',
                    "email_template_subject": item.email_template_subject !== null ? item.email_template_subject : '-',
                    "active": item.active ? 'Yes' : 'No',
                });
            });
            break;
        case API_PATH.valuationCalculationLog:
            data.map(item => {
                let strName = '-';
                if (!isObjectEmpty(item.created_by_data)) {
                    strName = `${item.created_by_data.first_name} ${item.created_by_data.last_name}`;
                }

                let strStatus = '-';
                const arrTemp = VALUATION_CALCULATION_STATUS_OPTIONS.filter(obj => obj.value == item.status);
                if (arrTemp.length > 0) {
                    strStatus = arrTemp[0].label;
                }

                let strStartTime = "-";
                if (item.start_time) {
                    const arrTemp = item.start_time.split('T');
                    strStartTime = `${arrTemp[0]} || ${arrTemp[1].slice(0, 8)}`;
                }

                let strEndTime = "-";
                if (item.end_time) {
                    const arrTemp = item.end_time.split('T');
                    strEndTime = `${arrTemp[0]} || ${arrTemp[1].slice(0, 8)}`;
                }

                arr.push({
                    id: item.id,
                    created_by: strName,
                    start_time: strStartTime,
                    end_time: strEndTime,
                    status: strStatus,
                    error: item.error ? item.error : '-',
                    calculation_data: item.calculation_data
                });
            });
            break;
        case API_PATH.undisclosedProxyLog:
            data.map(item => {
                let strName = '-';
                if (!isObjectEmpty(item.created_by_data)) {
                    strName = `${item.created_by_data.first_name} ${item.created_by_data.last_name}`;
                }

                let strStatus = '-';
                const arrTemp = UNDISCLOSED_PROXY_LOGS_STATUS_OPTIONS.filter(obj => obj.value == item.status);
                if (arrTemp.length > 0) {
                    strStatus = arrTemp[0].label;
                }

                let strStartTime = "-";
                if (item.start_time) {
                    const arrTemp = item.start_time.split('T');
                    strStartTime = `${arrTemp[0]} || ${arrTemp[1].slice(0, 8)}`;
                }

                let strEndTime = "-";
                if (item.end_time) {
                    const arrTemp = item.end_time.split('T');
                    strEndTime = `${arrTemp[0]} || ${arrTemp[1].slice(0, 8)}`;
                }

                arr.push({
                    id: item.id,
                    created_by: strName,
                    start_time: strStartTime,
                    end_time: strEndTime,
                    status: strStatus,
                    error: item.error ? item.error : '-',
                    calculation_data: item.calculation_data
                });
            });
            break;
        default:
            arr = data;
            break;

        }

        store.rowData = arr
    }

    /**
     * @param {String} apiPath      API path
     * @param {String} str          Fields which to be fetched
     * @param {String} type         This param will only be passed when the apiPath is different than the store type
     */
    fetchListData = (apiPath, fields = store.fields, type) => {
        store.rowData = [];
        let filtersStr = "";

        let sortStr = "id^:desc";
        if (apiPath == 'application' || apiPath == API_PATH.apiClient) {
            sortStr = "_id^:desc"
        }

        if (store.currentFilters) {
            filtersStr = store.currentFilters;
        }

        if (store.currentSort) {
            sortStr = store.currentSort;
        }

        const query = `fields=${fields}&filters=${filtersStr}&limit=20&sort=${sortStr}`;

        let tempUrlField = apiPath

        if (apiPath === 'funding-verification') {
            tempUrlField = API_PATH.fundingBreakdown;
        }

        let endpoint = API;
        if (apiPath === API_PATH.apiClient) {
            endpoint = PRODUCT_API;
        }

        fetch(`${endpoint}/${tempUrlField}/?${query}`, {
            method: 'GET',
            headers: {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (data.status === 200) {
                    const entity = type ? type : apiPath;  //=== 'banner' ? 'advert' : type
                    this.assignValue(entity, data.data);
                    this.handleResult(data);
                }
            });
    }

    handleResult = (data) => {
        store.page = data.navigation.page
        store.lastPage = Number.isInteger(data.navigation.total / 20) ? Math.trunc(data.navigation.total / 20) : Math.trunc(data.navigation.total / 20) + 1
        if (store.page === 1) {
            store.totalCount = data.navigation.total
            store.fromCount = 1
            store.toCount = 20
        }
        if (store.page === store.lastPage) {
            store.totalCount = data.navigation.total
            store.fromCount = ((data.navigation.page - 1) * 20) + 1
            store.toCount = data.navigation.total
        }
        else {
            store.totalCount = data.navigation.total
            store.fromCount = ((data.navigation.page - 1) * 20) + 1
            store.toCount = data.navigation.page * 20
        }
    }

    handleKeywordEnter = (event, key = 'keyword', storeType = null) => {
        if (event.keyCode === 13 && this.state[key] !== '') {
            if (this.state.filters[key].indexOf(this.state[key]) === -1) {
                this.state.filters[key].push(this.state[key])
                this.state.filterFields.map(item => {
                    if (item.name === key || item.key === key) {
                        item.value.push(this.state[key])
                    }
                })
            }
            this.setState(this.state.filters[key])
            this.handleSearchFilters(store.type, null, storeType)
            this.setState({ [key]: '' })
        }
    }

    handleInputEnter = (event, str) => {
        let { filters, filterFields } = this.state
        let tempObj = {}

        if (event.keyCode === 13 && this.state[str] !== '') {
            if (filters[str].indexOf(this.state[str]) === -1) {
                filters[str].push(this.state[str])

                filterFields.map(item => {
                    if (item.key === str || item.name === str) {
                        item.value.push(this.state[str])
                    }
                })
            }

            tempObj['filters'] = filters
            tempObj['filterFields'] = filterFields
            tempObj[str] = ''
            // this.setState(this.state.filters.keyword)
            this.handleSearchFilters(store.type, str)
            this.setState(tempObj)
        }
    }

    handleNameEnter = (event) => {
        if (event.keyCode === 13 && this.state.name !== '') {
            if (this.state.filters.name.indexOf(this.state.name) === -1) {
                this.state.filters.name.push(this.state.name)
                this.state.filterFields.map(item => {
                    if (item.key === 'name') {
                        item.value.push(this.state.name)
                    }
                })
            }
            this.setState(this.state.filters.name)
            this.handleSearchFilters(store.type)
            this.setState({ name: '' })
        }
    }

    handleInputChange(value, str) {
        this.state[str] = value
        this.state[`${str}s`] = this.state[`${str}Store`].filter(item => {
            return item.label.toLowerCase().indexOf(value.toLowerCase()) >= 0
        })
        this.setState(this.state)
    }

    handleDateChange(date, str, field, op = ALLOWED_QUERY_OPERATOR.equal) {
        this.state[str] = date
        this.state.filterFields.map(item => {
            if (item.key === field && item.op === op) {
                item.value = []
                if (date !== '' && date !== null) {
                    item.value.push(date.toISOString())
                }
            }
        })
        this.handleSearchFilters(store.type)
        this.setState(this.state)
    }

    handleSelectFilter(obj, str) {
        if (this.state.filters[str].indexOf(obj.label) === -1) {
            this.state.filters[str].push(obj.label)
            this.state.filterFields.map(item => {
                if (item.key === str || item.name === str) {
                    item.value.push(obj.value)
                }
            })
            this.state[str] = ''
            this.state[`${str}s`] = this.state[`${str}Store`]
            this.setState(this.state.filters[str])
        }
    }

    handleSingleSelectFilter(obj, str, storeType = null) {
        let { filters, filterFields } = this.state
        let tempObj = {}
        let assignValueStoreType = null

        filters[str].push(obj.label)
        filterFields.map(item => {
            if (item.key === str || item.name === str) {

                // For filters with multiple values to filter e.g. All startups (deleted and non-deleted)
                if (obj.isMulti) {
                    item.value = obj.value.split(',')
                } else {
                    item.value = (obj.value || obj.value == 0) ? [obj.value] : ""
                }
            }
        });

        tempObj['filters'] = filters
        tempObj['filterFields'] = filterFields
        tempObj[str] = obj

        // If params has storeType, it means default api path is not same as store.type
        // So, send store.type as a separate param to the assignValue() function
        if (storeType) {
            assignValueStoreType = store.type
        }

        this.setState(tempObj, () => {
            this.handleSearchFilters(storeType || store.type, str, assignValueStoreType)
        })
    }

    handleRemoveFilter(index, str) {
        this.state.filters[`${str}`].splice(index, 1)
        this.state.filterFields.map(item => {
            if (item.key === str || item.name === str) {
                item.value.splice(index, 1)
            }
        })
        this.setState(this.state.filters[`${str}`])
        if (str === 'keyword' || str === 'name' || str === 'email' || str === 'email_filter' || str === 'id' || str === 'first_name' || str === 'last_name') {
            this.handleSearchFilters(store.type)
        }
    }

    handleClearFilter(str, storeType = null) {
        this.state.filters[str] = []
        this.state[`show${str}`] = false
        let index = this.state.filterFields.findIndex(item => {
            if (item.name) {
                return item.name === str
            } else {
                return item.key === str
            }
        })
        this.state.filterFields[index].value = []

        let assignValueStoreType = null

        // If params has storeType, it means default api path is not same as store.type
        // So, send store.type as a separate param to the assignValue() function
        if (storeType) {
            assignValueStoreType = store.type
        }

        this.handleSearchFilters(storeType || store.type, null, assignValueStoreType)
    }

    handleClearAllFilters(str) {
        let filters = {}
        Object.keys(this.state.filters).map(key => {
            filters[key] = []
        })
        this.state.filters = filters

        this.state.filterFields.map(item => {
            item.value = []
        })

        this.setState(this.state)
        store.currentFilters = ''
        this.fetchListData(str, store.fields)
    }

    /**
     *
     * @param {String} apiPath      API path
     * @param {String} str          Name of the filter
     * @param {String} storeType    It is for function assignValue(), which has switch cases, based on which it assigns value
     * These params are workaround to let helper functions not use the default store type for everything
     * Because some times store type can be different than the API path
     */
    handleSearchFilters(apiPath, str = null, storeType = null) {
        let path = apiPath
        Object.keys(this.state.filters).map(item => {
            if (item !== str) {
                this.state[`show${item}`] = false
            }
        })
        if (apiPath === 'parent_id') {
            path = 'industry'
        }
        this.callFilterAPI(path, storeType)
        this.setState(this.state)
    }

    callFilterAPI = (apiPath, storeType) => {
        store.currentFilters = prepareFilterParam(this.state.filterFields, apiPath)
        let filtersStr  = "";
        let limit = 20;

        let sortStr = "id^:desc"
        if (apiPath == 'application') {
            sortStr = "_id^:desc"
        }

        if (store.currentFilters) {
            filtersStr = store.currentFilters;
        }

        if (store.currentSort) {
            sortStr = store.currentSort;
        }

        const query = `fields=${store.fields}&filters=${filtersStr}&limit=${limit}&sort=${sortStr}`;

        let tempUrl = apiPath;

        if (apiPath === "funding-verification") {
            tempUrl = API_PATH.fundingBreakdown;
        }
        else if (apiPath === "subscription-types") {
            tempUrl = 'subscription-type';
        }

        let endpoint = API
        if (/^api-clients/g.test(apiPath)) {
            endpoint = PRODUCT_API;
        }

        fetch(`${endpoint}/${tempUrl}/?${query}`, {
            method: 'GET',
            headers: {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.status === 200) {
                    this.assignValue(storeType || apiPath, data.data)
                    this.handleResult(data)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    prepareResult = () => {
        let apiUrl = API
        let query
        if (store.type === API_PATH.apiClient) {
            query = `${store.type}/?fields=${store.fields}&sort=id^:desc`;
            apiUrl = PRODUCT_API;
        }
        else if (store.type === 'taxonomy-type') {
            query = store.isTaxonomyList ? `${store.type}/?fields=${store.fields}&page=${store.page}&sort=id^:desc` : `${store.type}/${store.taxonomyId}/taxonomy`
        }
        else if (store.type === 'order') {
            if (store.route === '')
                /* As of now (30th dec 2019), there is only one action is happending in order type So query is prepared accordingly */
                query = `${store.type}/${store.orderId}/user-subscription?fields=${store.fields}`
        }
        else {
            query = `${store.type}/?fields=${store.fields}&sort=id^:desc`
        }

        fetch(`${apiUrl}/${query}`, {
            method: 'GET',
            headers: {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.status === 200) {
                    let arr = []
                    if (store.type === 'application') {
                        if (store.isAppList) {
                            this.assignValue(store.type, data.data)
                            this.handleResult(data)
                        }
                        else {
                            data.data.map(item => {
                                arr.push({
                                    "id": item.question_id,
                                    "question": !isObjectEmpty(item.question_data) ? item.question_data.name : '',
                                    "type": !isObjectEmpty(item.question_data) ? item.question_data.type : '',
                                    "required": item.required !== null ? item.required === 1 ? 'Yes' : 'No' : '',
                                    "sort": item.sort !== null ? item.sort : ''
                                })
                            })
                            store.rowData = arr
                        }
                    }
                    else if (store.type === 'taxonomy-type') {
                        data.data.map(item => {
                            arr.push({
                                id: item.id,
                                name: item.name,
                                value: item.value
                            })
                        })
                        store.rowData = arr
                    }
                    else {
                        this.assignValue(store.type, data.data)
                        this.handleResult(data)
                    }
                }
            })
    }

    handleSortChange(obj) {
        this.setState({ sort: obj.name, showSortList: false })
        store.currentSort = `${obj.field}^:${obj.order}`
        store.page = 1
        let path

        switch (store.sortType) {
        case 'view-research-downloads':
            path = `research/${store.currentId}/download`
            break;
        case 'funding-verification':
            path = API_PATH.fundingBreakdown;
            break;
        case 'funding-round-requests':
            path = API_PATH.fundingRequests;
            break;
        case 'subscription-types':
            path = 'subscription-type'
            break;
        default:
            path = store.type
            break;
        }

        let query = store.currentFilters !== '' ? `fields=${store.fields}&sort=${obj.field}^:${obj.order}&filters=${store.currentFilters}` :
            `fields=${store.fields}&sort=${obj.field}^:${obj.order}`

        let endpoint = API
        if (path == API_PATH.apiClient) {
            endpoint = PRODUCT_API;
        }

        fetch(`${endpoint}/${path}?${query}`, {
            method: 'GET',
            headers: {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.status === 200) {
                    if (store.sortType === 'view-research-downloads') {
                        let arr = []
                        data.data.map(item => {
                            let email
                            if (item.email_data.length > 0) {
                                let temp = []
                                item.email_data.map(item1 => {
                                    temp.push(item1.email)
                                })
                                email = temp.join()
                            }
                            arr.push({
                                email: item.email !== null ? item.email : '',
                                uid: !isObjectEmpty(item.user_data) ? item.user_data.id : '',
                                username: !isObjectEmpty(item.user_data) ? `${item.user_data.first_name} ${item.user_data.last_name}` : '',
                                useremail: email,
                                download_date: item.download_date !== null ? moment(item.download_date).format(DATE_FORMAT) : item.download_date
                            })
                        })
                        store.rowData = arr
                    }
                    else {
                        this.assignValue(store.type, data.data)
                    }
                    this.handleResult(data)
                }
            })
    }

    setOwnerIds = async (e) => {
        let value = ""

        if (e && e.target.value.length >= MIN_SEARCH_LENGTH) {
            value = e.target.value;
        }

        const response = await getUsers(value)

        this.setState({
            owned_bys: response,
            owned_byStore: response
        })
    }
}
