import React from 'react'
import { observer } from 'mobx-react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import DatePicker from 'react-datepicker'
import store from '../../store'
import {
    API,
    SELECT_STYLES_ERROR,
    SELECT_STYLES,
    SELECT_THEME_PRIMARY
} from '../../constant'
import { prepareSelectParam } from '../../utility/queryParams'
import FormParent from '../FormParent'
import { extPrimaryEmail } from '../../utility/helpers'
import subscriptionApi from '../../apis/subscriptionApi'
import { SUBSCRIPTIONS_IDS } from '../../utility/constants/subscriptions'
import orderApi from '../../apis/orderApi'

class UserSubModal extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                user_id: '',
                start_date: '',
                duration: '',
                subscription_id: ''
            },
            users: [],
            validationFields: ['user_id', 'duration'],
            types: [],
        }

        this.parameters = {
            user_id: '',
            start_date: '',
            duration: '',
            subscription_id: ''
        }
    }

    async componentDidMount() {
        // Subscription taxonomy type
        const type = store.type_id
        const subscriptionsTaxo = []
        const subscriptionTaxonomy = await subscriptionApi.getSubscriptionTaxonomy(type)
        subscriptionTaxonomy.map(item => subscriptionsTaxo.push({ value: item.id, label: item.name }));
        this.setState({types: subscriptionsTaxo});

        const arSelectParams    = ['id', 'first_name', 'last_name', 'email_data']
        const strSelectParams   = prepareSelectParam(arSelectParams)
        let data                = await fetch(`${API}/user?fields=${strSelectParams}`, {
            method: 'GET',
            headers: {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            },
        })

        data = await data.json()

        if (data.status == 200) {
            let arr = []
            data.data.map(item => {
                const userEmail = extPrimaryEmail(item.email_data)
                arr.push({
                    value: item.id,
                    label: `${item.first_name} ${item.last_name} | ${userEmail}`
                })
            })

            this.setState({ users: arr })
        }
        else {
            console.error('API Error', data.errors);
        }
    }

    handleCloseModal = () => {
        $('#userSubModal').modal('hide')
        store.userSubTitle = ''
    }

    /**
     * Method to add the user subscription
     */
    addUserSubscription = async () => {
        // case 1: custom subscription
        // case 2: standard subscription
        if (store.sub_type_id === SUBSCRIPTIONS_IDS.custom_enterprise) {
            await this.addCustomSubscription();
        } else {
            await this.addStandardSubscription();
        }
    }

    /**
     * Method to add a user to a standard subscription
     */
    addStandardSubscription = async () => {
        try {
            let check
            store.currentAddName = this.state.formFields.name
            check = this.handleEmptyFieldValidation()

            if (check) {
                this.prepParameters(this.parameters, 'parameters')
                let reqData                 = this.parameters;
                reqData.subscription_id   = reqData.subscription_id ? reqData.subscription_id : store.sub_type_id
                const response = await fetch(`${API}/order/${store.currentId}/user-subscription`, {
                    method: 'POST',
                    headers: {
                        'Authorization': store.token,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(reqData)
                });

                const data = await response.json();

                if (data.status === 201) {
                    this.ResetFields()
                    this.handleCloseModal()
                    await this.props.fetchData()
                    store.isSubAdded = true;
                    setTimeout(() => {
                        store.isSubAdded = false;
                    }, 4000);
                } else {
                    $('#errorModal').modal({
                        backdrop: 'static',
                        keyboard: true,
                        show: true
                    })
                }
            }
        } catch (error) {
            console.error('Error ', error)
        }
    }

    /**
     * Method to add a user to a custom subscription
     */
    addCustomSubscription = async () => {
        try {
            if (!this.handleEmptyFieldValidation()) {
                return;
            }

            const objTemp = {
                ...this.parameters,
                subscription_id: store.sub_type_id,
                user_subscription_id: store.user_subscription_id,
            };

            await orderApi.addUserCustomSubscription(store.currentId, objTemp);

            this.ResetFields();
            this.handleCloseModal();
            await this.props.fetchData();

            store.isSubAdded = true;
            setTimeout(() => {
                store.isSubAdded = false;
            }, 4000);
        } catch (error) {
            this.handleCloseModal();

            $('#errorModal').modal({
                backdrop: 'static',
                keyboard: true,
                show: true
            });
        }
    }

    render() {
        return (
            <div>
                <div className="modal fade" id="userSubModal">
                    <div className="modal-dialog">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h4 className="modal-title">Add User</h4>
                                <button type="button" className="close" onClick={this.handleCloseModal}>&times;</button>
                            </div>

                            <div style={{paddingTop: '10px', margin: '0px auto', textAlign: 'center', fontSize: '10px', }}>
                                <div className="alert alert-warning" role="alert">
                                    There are no business validations, so you will be responsible for adding an user!
                                </div>
                            </div>

                            <div className="modal-body">
                                {/* User name */}
                                <div className='row'>
                                    <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                        <label>User Name<span className='required'>*</span></label>
                                        <AsyncSelect
                                            placeholder='Enter User Name'
                                            styles={this.state.user_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            cacheOptions
                                            value={this.state.formFields.user_id}
                                            defaultOptions={this.state.users}
                                            loadOptions={(value, callback) => this.handleLoadOptions(value, callback, 'user')}
                                            onChange={(option) => this.handleSelectChange(option, 'user_id')}
                                        />
                                    </div>
                                </div>
                                {/* Start date */}
                                <div className='row'>
                                    <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                        <label>Subscription Start Date</label>
                                        <DatePicker
                                            placeholderText="Select Start Date"
                                            className="datepicker-form-control"
                                            dateFormat="yyyy/MM/dd"
                                            maxDate={new Date()}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            selected={this.state.formFields.start_date}
                                            onChange={(date) => this.handleDateChange(date, 'start_date')} />
                                    </div>
                                </div>
                                {/* Subscription Duration */}
                                <div className='row'>
                                    <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                        <label >Subscription Duration (months) <span className='required'>*</span></label>
                                        <input title='float'
                                            placeholder='Enter Subscription Duration'
                                            className={this.state.durationEmpty || this.state.durationNumber ? "form-control input-error " : "form-control"}
                                            name='duration'
                                            value={this.state.formFields.duration}
                                            onChange={this.handleInputChange} />
                                    </div>
                                </div>
                                {/* Subscription */}
                                <div className='row'>
                                    <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                        <label>Subscription</label>
                                        <Select
                                            placeholder='Select Subscription'
                                            isDisabled={true}
                                            styles={this.state.subscription_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            value={this.state.types.filter(option => option.value === store.sub_type_id)}
                                            onChange={(option) => this.handleSelectChange(option, 'subscription_id')}
                                            options={this.state.types}
                                        />
                                    </div>
                                </div>

                                <div className='bottom-btn-wrapper'>
                                    <div style={{margin: "0px auto"}}>
                                        <div style={{float: "left"}}>
                                            <button className="btn-primary bottom-btn" onClick={async () => { await this.addUserSubscription() }}>Add</button>
                                        </div>
                                        <div style={{float: "right"}}>
                                            <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default observer(UserSubModal)
