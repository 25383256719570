import React from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';

// constants
import { SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY, OPTIONS, TAXONOMIES } from '../../constant';
import { API_PATH } from '../../utility/constants/apiPath';

// helpers
import store from '../../store';
import { prepareSelectParam } from '../../utility/queryParams';
import { isObjectEmpty } from '../../utility/helpers';

// apis
import valuationCalculation from '../../apis/valuationCalculationApi';
import { getTaxonomyData } from '../../utility/commonApis';


// components
import FormParent from '../FormParent';
import Home from '../Home';
import SideNav from '../SideNav';
import Modal from '../reusable/Modal';

class ValuationCalculationForm extends FormParent {
    constructor(props) {
        super(props);
        this.state = {
            formFields: {
                lower_share_percentage: '',
                upper_share_percentage: '',
                equity_percentage: '',
                funding_stage_id: '',
                description: '',
                active: '',
                run_valuation_calculation: ''
            },
            validationFields: ['lower_share_percentage', 'upper_share_percentage', 'funding_stage_id', 'equity_percentage'],
            editParams: [],
            //options
            error: false
        };
        this.parameters = {
            lower_share_percentage: '',
            upper_share_percentage: '',
            equity_percentage: '',
            funding_stage_id: '',
            description: '',
            active: '',
            run_valuation_calculation: 0
        };
    }


    async componentDidMount() {
        if (store.isEdit && store.currentId) {
            const paramFields = ['lower_share_percentage', 'upper_share_percentage', 'equity_percentage', 'description', 'funding_stage_id', 'active', 'funding_stage_data'];
            const fields = prepareSelectParam(paramFields);

            valuationCalculation.getValuationCalculationDetails(store.currentId, fields).then(
                response => {
                    let objTemp = {};

                    Object.keys(this.state.formFields).map(key => {
                        if (key === 'active') {
                            objTemp[key] = response.active == 0 ? { value: 0, label: 'No' } : response.active == 1 ? { value: 1, label: 'Yes' } : '-';
                        } else if (key === 'funding_stage_id') {
                            objTemp[key] = !isObjectEmpty(response.funding_stage_data) ? { 'value': response.funding_stage_data.id, 'label': response.funding_stage_data.name } : '';
                        }
                        else {
                            objTemp[key] = response[key] !== null ? response[key] : '';
                        }
                    });
                    this.setState({ formFields: objTemp });
                }
            );
        }

        getTaxonomyData(TAXONOMIES.fundingStage).then(res => {
            this.setState({ fundingStages: res })
        })
    }

    handleAddValuationCalculation = () => {
        this.handleAdd(API_PATH.valuationCalculation);
    };

    handleUpdateValuationCalculation = () => {
        this.handleUpdate(API_PATH.valuationCalculation);
    };

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT VALUATION CALCULATION</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD VALUATION CALCULATION</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>

                                    <div className='row'>
                                        <div className="col-12 form-group">
                                            <label >Funding Stage <span className='required'>*</span></label>
                                            <Select
                                                name='funding_stage'
                                                placeholder='Please select a stage'
                                                styles={this.state.funding_stage_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.funding_stage_id}
                                                onChange={(option) => this.handleSelectChange(option, 'funding_stage_id')}
                                                options={this.state.fundingStages}
                                            />
                                            {this.state.funding_stage_idEmpty && <div className='error'>Funding stage cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >LOWER SHARE PERCENTAGE <span className='required'>*</span></label>
                                            <input
                                                className={this.state.lower_share_percentageEmpty ? "form-control input-error" : "form-control"}
                                                type="number"
                                                name='lower_share_percentage'
                                                placeholder="lower share value"
                                                value={this.state.formFields.lower_share_percentage}
                                                onChange={this.handleInputChange} />
                                            {this.state.lower_share_percentageEmpty && <div className='error'>Lower Share Percentage cannot be empty</div>}
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >UPPER SHARE PERCENTAGE <span className='required'>*</span></label>
                                            <input
                                                className={this.state.upper_share_percentageEmpty ? "form-control input-error" : "form-control"}
                                                type="number"
                                                name='upper_share_percentage'
                                                placeholder="upper share value"
                                                value={this.state.formFields.upper_share_percentage}
                                                onChange={this.handleInputChange} />
                                            {this.state.upper_share_percentageEmpty && <div className='error'>Upper Share Percentage cannot be empty</div>}
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >EQUITY PERCENTAGE <span className='required'>*</span></label>
                                            <input
                                                className={this.state.equity_percentageEmpty ? "form-control input-error" : "form-control"}
                                                type="number"
                                                name='equity_percentage'
                                                placeholder="equity value"
                                                value={this.state.formFields.equity_percentage}
                                                onChange={this.handleInputChange} />
                                            {this.state.equity_percentageEmpty && <div className='error'>Equity Percentage cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Description <span className='required'>*</span></label>
                                            <input
                                                className={this.state.descriptionEmpty ? "form-control input-error" : "form-control"}
                                                type="text"
                                                name='description'
                                                placeholder="description"
                                                value={this.state.formFields.description}
                                                onChange={this.handleInputChange} />
                                            {this.state.descriptionEmpty && <div className='error'>Equity Percentage cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Active</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={this.state.activeEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.active}
                                                onChange={(option) => this.handleSelectChange(option, 'active')}
                                                options={OPTIONS}
                                            />
                                            {this.state.activeEmpty && <div className='error'>Active cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Calculate the funding proxy valuation for this funding stage's percentages?</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.run_valuation_calculation}
                                                onChange={(option) => this.handleSelectChange(option, 'run_valuation_calculation')}
                                                options={OPTIONS}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ? <button className="btn-primary bottom-btn" disabled={this.state.editParams.length > 0 || store.apis.length > 0 ? false : true} onClick={this.handleUpdateValuationCalculation}>Save</button> :
                                    <div>
                                        <button className="btn-primary bottom-btn" onClick={this.handleAddValuationCalculation}>Add</button>
                                        <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                                    </div>
                                }
                            </div>

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}

                        <Modal />

                    </div>
                }
            </div>
        );
    }
}
export default observer(ValuationCalculationForm);
