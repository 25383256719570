import axios from 'axios';

import store from '../store';
import { API } from '../constant';
import { prepareSelectParam } from '../utility/queryParams';

const newsApi = {

    getNewsCategories: (newsId) => {
        const fields = prepareSelectParam(['id', 'category_data']);

        const url = `${API}/news/${newsId}/news-category?fields=${fields}`;
        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        return new Promise((resolve, reject) => {

            axios.get(url, { headers })
                .then(
                    response => {
                        if (response.data.status === 200) {
                            resolve(response.data.data);
                        } else {
                            resolve([]);
                        }
                    }
                )
                .catch((err) => reject(err));
        });
    },

    getNewsUTMDetails: (recordId = "") => {
        const fields = prepareSelectParam(['id', 'description', 'category_data', 'utm_link']);

        const url = `${API}/news-utm/${recordId}?fields=${fields}`;
        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        return new Promise((resolve, reject) => {

            axios.get(url, { headers })
                .then(
                    response => {
                        resolve(response.data.data);
                    }
                )
                .catch((err) => reject(err));
        });
    }

}

export default newsApi;