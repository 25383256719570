import React from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import NumberFormat from "react-number-format";
import moment from 'moment';

// helpers
import store from '../../../store';
import { displayAmount, isObjectEmpty } from '../../../utility/helpers';

// constants
import { SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY, FUNDING_STATUS_OPTIONS, TAXONOMIES, FUNDING_STATUS_LABELS, DATE_FORMAT, FUNDING_STATUS_VALUES } from '../../../constant';
import { API_PATH } from '../../../utility/constants/apiPath';

// apis
import fundingApi from '../../../apis/fundingApi';
import { getLocations, getTaxonomyData } from '../../../utility/commonApis';

// components
import FormParent from '../../FormParent';

class FundingBreakdownModal extends FormParent {
    constructor() {
        super()
        this.state = {
            formFields: {
                funding_id: '',
                investment_vehicle_id: '',
                date_raised: '',
                amount: '',
                location_id: '',
                verification_status: '',
                comment: '',
                source_type_id: '',
                source_url: '',
                investors: []
            },
            validationFields: ['investment_vehicle_id', 'date_raised', 'location_id', 'verification_status', 'source_type_id', 'source_url'],
            editParams: [],
            investorError: false,
            isApiCalled: false
        }

        this.parameters = {
            investment_vehicle_id: '',
            date_raised: '',
            amount: 0,
            location_id: '',
            verification_status: '',
            comment: '',
            source_type_id: '',
            source_url: '',
            investors: []
        }
    }

    componentDidMount() {
        store.fundingBreakdownDetails = {};

        getTaxonomyData(TAXONOMIES.investmentVehicles).then(res => {
            this.setState({ investmentVehicles: res })
        })

        getTaxonomyData(TAXONOMIES.fundingSourceType).then(res => {
            this.setState({ fundingSourceTypes: res })
        })

        getLocations().then(res => {
            this.setState({ locations: res })
        })
    }

    async componentWillReceiveProps() {
        if (!isObjectEmpty(store.fundingBreakdownDetails) && store.fundingBreakdownDetails.id && !this.state.isApiCalled) {
            await this.getFundingBreakdownData(store.fundingBreakdownDetails.id);
        } else if (!isObjectEmpty(store.fundingBreakdownDetails)) {
            let objData = {};
            Object.keys(this.state.formFields).map(key => {
                if (key === 'investment_vehicle_id') {
                    const index = this.state.investmentVehicles.findIndex(item => item.value === store.fundingBreakdownDetails.investment_vehicle_id);
                    objData[key] = store.fundingBreakdownDetails.investment_vehicle_id ? this.state.investmentVehicles[index] : '';
                }
                else if (key === 'date_raised') {
                    objData[key] = store.fundingBreakdownDetails.date_raised ? new Date(store.fundingBreakdownDetails.date_raised) : '';
                }
                else if (key === 'location_id') {
                    const index = this.state.locations.findIndex(item => item.value === store.fundingBreakdownDetails.location_id);
                    objData[key] = store.fundingBreakdownDetails.location_id ? this.state.locations[index] : '';
                }
                else if (key === 'verification_status') {
                    objData[key] = store.fundingBreakdownDetails.verification_status !== null ? { value: FUNDING_STATUS_VALUES[store.fundingBreakdownDetails.verification_status], label: FUNDING_STATUS_LABELS[store.fundingBreakdownDetails.verification_status] } : '';
                }
                else if (key === 'source_type_id') {
                    const index = this.state.fundingSourceTypes.findIndex(item => item.value === store.fundingBreakdownDetails.source_type_id);
                    objData[key] = store.fundingBreakdownDetails.source_type_id ? this.state.fundingSourceTypes[index] : '';
                }
                else {
                    objData[key] = store.fundingBreakdownDetails[key] !== null ? store.fundingBreakdownDetails[key] : '';
                }
            });

            this.setState({
                isApiCalled: true,
                formFields: objData
            })

            if (this.props.location_id) {
                this.state[`location_idEmpty`] = false
                this.parameters.location_id = this.props.location_id.value
                this.state.formFields.location_id = this.props.location_id;
                this.setState(this.state)
            }
        }

        return true;
    }

    getFundingBreakdownData = async (fundingBreakdownId) => {
        fundingApi.getFundingBreakdownDetails(fundingBreakdownId).then(
            response => {
                let objData = {};

                Object.keys(this.state.formFields).map(key => {
                    if (key === 'investment_vehicle_id') {
                        objData[key] = !isObjectEmpty(response.investment_vehicle_data) ? { value: response.investment_vehicle_data.id, label: response.investment_vehicle_data.name } : '';
                    }
                    else if (key === 'date_raised') {
                        objData[key] = response.date_raised !== null ? new Date(response.date_raised) : '';
                    }
                    else if (key === 'location_id') {
                        objData[key] = !isObjectEmpty(response.location_data) ? { value: response.location_data.id, label: response.location_data.name } : '';
                    }
                    else if (key === 'verification_status') {
                        objData[key] = response.verification_status !== null ? { value: response.verification_status, label: FUNDING_STATUS_LABELS[response.verification_status] } : '';
                    }
                    else if (key === 'source_type_id') {
                        objData[key] = !isObjectEmpty(response.source_type_data) ? { value: response.source_type_data.id, label: response.source_type_data.name } : '';
                    }
                    else {
                        objData[key] = response[key] !== null ? response[key] : '';
                    }
                });

                this.setState({
                    isApiCalled: true,
                    formFields: objData
                })
            }
        )
    }

    handleAmountChange = (event) => {
        let targetVal = event.target.value;

        if (targetVal.includes(',')) {
            targetVal = targetVal.replaceAll(',', '')
        }

        const isAmountNumber = /^\d*$/.test(targetVal) || targetVal === '' ? false : true;

        this.parameters.amount = /^\d*$/.test(targetVal) && targetVal !== '' ? targetVal : '';

        this.setState((prevState) => ({
            isAmountNumber,
            formFields: {
                ...prevState.formFields,
                amount: targetVal
            }
        }));
    }

    handleAddFundingBreakdown = () => {
        if (this.handleEmptyFieldValidation()) {
            const uniqueId = `temp-id-${this.state.formFields.investment_vehicle_id.value}-${Math.random() * 100}`;
            let objData = Object.fromEntries(Object.entries(this.parameters).filter(([_, item]) => (item != "" || item != [])));

            if (store.isFundingBreakdownEdit) {
                store.fundingBreakdownsData[store.fundingBreakdownRowIndex].amount = displayAmount(this.state.formFields.amount);
                store.fundingBreakdownsData[store.fundingBreakdownRowIndex].date_raised = moment(this.state.formFields.date_raised).format(DATE_FORMAT);
                store.fundingBreakdownsData[store.fundingBreakdownRowIndex].comment = this.state.formFields.comment;
                store.fundingBreakdownsData[store.fundingBreakdownRowIndex].source_url = this.state.formFields.source_url;
                store.fundingBreakdownsData[store.fundingBreakdownRowIndex].investors = this.state.formFields.investors || [];
                store.fundingBreakdownsData[store.fundingBreakdownRowIndex].investment_vehicle_id = this.state.formFields.investment_vehicle_id.label;
                store.fundingBreakdownsData[store.fundingBreakdownRowIndex].location_id = this.state.formFields.location_id.label;
                store.fundingBreakdownsData[store.fundingBreakdownRowIndex].verification_status = this.state.formFields.verification_status.label;
                store.fundingBreakdownsData[store.fundingBreakdownRowIndex].source_type_id = this.state.formFields.source_type_id.label;

                if (store.isEdit) {
                    if (store.currentFundingBreakdownId !== '') {
                        store.apis.push({ path: `${API_PATH.fundingBreakdown}/${store.currentFundingBreakdownId}`, method: 'PATCH', data: objData });
                    }
                    else {
                        store.apis.push({ 
                            path: `${API_PATH.fundingBreakdown}`, 
                            method: 'POST', 
                            data: { funding_id: store.currentId, breakdown: [objData] },
                            tempId: uniqueId, 
                            type: 'funding-breakdown' 
                        });
                    }
                } else {
                    store.fundingBreakdowns[store.fundingBreakdownRowIndex] = objData;
                }
            }
            else {
                store.fundingBreakdowns.push(this.parameters);

                store.fundingBreakdownsData.push(
                    {
                        id: uniqueId,
                        amount: displayAmount(this.state.formFields.amount),
                        date_raised: moment(this.state.formFields.date_raised).format(DATE_FORMAT),
                        comment: this.state.formFields.comment,
                        source_url: this.state.formFields.source_url,
                        investors: this.state.formFields.investors || [],
                        investment_vehicle_name: this.state.formFields.investment_vehicle_id.label,
                        location_id: this.state.formFields.location_id.label,
                        verification_status: this.state.formFields.verification_status.label,
                        source_type_id: this.state.formFields.source_type_id.label
                    }
                )

                if (store.isEdit) {
                    store.apis.push({ 
                        path: `${API_PATH.fundingBreakdown}`, 
                        method: 'POST', 
                        data: { funding_id: store.currentId, breakdown: [objData] }, 
                        tempId: uniqueId, 
                        type: 'funding-breakdown' 
                    });
                }
            }

            this.props.gridApi.setRowData(store.fundingBreakdownsData);
            this.handleClose();
        }

    }

    handleClose = () => {
        store.fundingBreakdownDetails = {};
        store.isFundingBreakdownEdit = false;

        this.setState({
            formFields: {
                funding_id: '',
                investment_vehicle_id: '',
                date_raised: '',
                amount: '',
                location_id: '',
                verification_status: '',
                comment: '',
                source_type_id: '',
                source_url: '',
                investors: []
            },
            isApiCalled: false
        })

        $('#fundingBreakdownModal').modal('hide');
    }

    render() {
        return (
            <div className="modal fade" id="fundingBreakdownModal" >
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">{store.isFundingBreakdownEdit ? 'EDIT FUNDING BREAKDOWN' : 'ADD FUNDING BREAKDOWN'}</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.handleClose}>&times;</button>
                        </div>

                        <div className="modal-body">

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Investment Vehicle <span className='required'>*</span></label>
                                    <Select
                                        placeholder='Select an investment vehicle'
                                        styles={this.state.investment_vehicle_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        value={this.state.formFields.investment_vehicle_id}
                                        onChange={(option) => this.handleSelectChange(option, 'investment_vehicle_id')}
                                        options={this.state.investmentVehicles}
                                    />
                                    {this.state.investment_vehicle_idEmpty && <div className='error'>Investment vehicle cannot be empty</div>}
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Date Raised <span className='required'>*</span></label>
                                    <DatePicker
                                        placeholderText="Select a date"
                                        className={this.state.date_raisedEmpty ? 'datepicker-form-control input-error' : 'datepicker-form-control'}
                                        dateFormat="yyyy/MM/dd"
                                        maxDate={new Date()}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        selected={this.state.formFields?.date_raised ? new Date(this.state.formFields.date_raised) : null}
                                        onChange={(date) => this.handleDateChange(date, 'date_raised')} />
                                    {this.state.date_raisedEmpty && <div className='error'>Date raised cannot be empty</div>}
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Amount Raised</label>
                                    <NumberFormat
                                        title='number'
                                        className={this.state.isAmountNumber ? "form-control input-error" : "form-control"}
                                        name='amount'
                                        value={this.state.formFields.amount}
                                        onChange={this.handleAmountChange}
                                        thousandSeparator={true}
                                    />
                                    {this.state.isAmountNumber && <div className='error'>Amount raised should be a number</div>}
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Location <span className='required'>*</span></label>
                                    <Select
                                        placeholder='Select a location'
                                        styles={this.state.location_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        value={this.state.formFields.location_id}
                                        onChange={(option) => this.handleSelectChange(option, 'location_id')}
                                        options={this.state.locations}
                                    />
                                    {this.state.location_idEmpty && <div className='error'>Location cannot be empty</div>}
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Verification Status <span className='required'>*</span></label>
                                    <Select
                                        placeholder='Select a verification status'
                                        styles={this.state.verification_statusEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        value={this.state.formFields.verification_status}
                                        onChange={(option) => this.handleSelectChange(option, 'verification_status')}
                                        options={FUNDING_STATUS_OPTIONS}
                                    />
                                    {this.state.verification_statusEmpty && <div className='error'>Verification status cannot be empty</div>}
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Source Type <span className='required'>*</span></label>
                                    <Select
                                        placeholder='Select a source type'
                                        styles={this.state.source_type_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        value={this.state.formFields.source_type_id}
                                        onChange={(option) => this.handleSelectChange(option, 'source_type_id')}
                                        options={this.state.fundingSourceTypes}
                                    />
                                    {this.state.source_type_idEmpty && <div className='error'>Source type cannot be empty</div>}
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Source URL <span className='required'>*</span></label>
                                    <input title='text'
                                        className={this.state.source_urlEmpty ? "form-control input-error" : "form-control"}
                                        name='source_url'
                                        placeholder='Add a source URL'
                                        value={this.state.formFields.source_url}
                                        onChange={this.handleInputChange} />
                                    {this.state.source_urlEmpty && <div className='error'>Source URL cannot be empty</div>}
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Comment</label>
                                    <textarea title='text'
                                        className="form-control"
                                        name='comment'
                                        placeholder='Add a comment'
                                        value={this.state.formFields.comment}
                                        onChange={this.handleInputChange} />
                                </div>
                            </div>

                            {this.state.investorError && <div className='error'>No details to add</div>}
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleClose}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={this.handleAddFundingBreakdown}> {store.isFundingBreakdownEdit ? 'Update' : 'Add'}</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default observer(FundingBreakdownModal);