import React from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

// constants
import { SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY, OPTIONS } from '../../constant';
import { API_PATH } from '../../utility/constants/apiPath';

// helpers
import store from '../../store';
import { prepareSelectParam } from '../../utility/queryParams';

// apis
import dataExportApi from '../../apis/dataExportApi';

// components
import FormParent from '../FormParent';
import Home from '../Home';
import SideNav from '../SideNav';
import Modal from '../reusable/Modal';

class DataExportProductForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                data_set_type: '',
                description: '',
                active: '',
                resource: ''
            },
            validationFields: ['data_set_type', 'resource', 'description'],
            editParams: [],
            //stores
            dataSetTypes: [],
            resources: [],
            //options
            error: false
        }
        this.parameters = {
            data_set_type: '',
            description: '',
            active: '',
            resource: ''
        }
    }


    async componentDidMount() {
        if (store.isEdit && store.currentId) {
            const paramFields = ['data_set_type', 'description', 'active', 'resource'];
            const fields = prepareSelectParam(paramFields);

            dataExportApi.getProductDetails(store.currentId, fields).then(
                response => {
                    let objTemp = {};

                    Object.keys(this.state.formFields).map(key => {
                        if (key === 'active') {
                            objTemp[key] = response.active == 0 ? { value: 0, label: 'No' } : response.active == 1 ? { value: 1, label: 'Yes' } : '-';
                        } else if (key === 'resource') {
                            objTemp[key] = response.resource ? { value: response.resource, label: response.resource } : '-';
                        }
                        else {
                            objTemp[key] = response[key] !== null ? response[key] : ''
                        }
                    })

                    this.setState({ formFields: objTemp });
                }
            );
        }
    }

    handleAddDataExportProduct = () => {
        this.handleAdd(API_PATH.dataExportProduct);
    }

    handleUpdateDataExportProduct = () => {
        this.handleUpdate(API_PATH.dataExportProduct);
    }

    handleResourceChange = (option) => {
        const tempEditParams = [...this.state.editParams];
        const tempFormFields = { ...this.state.formFields };

        if (store.isEdit && !tempEditParams.includes('resource')) {
            tempEditParams.push('resource');
        }

        tempFormFields.resource = option;
        this.parameters.resource = option.code;

        this.setState({
            resourceEmpty: false,
            formFields: tempFormFields,
            editParams: tempEditParams
        });
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT DATA EXPORT PRODUCT</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD DATA EXPORT PRODUCT</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Data Set Type <span className='required'>*</span></label>
                                            <input 
                                                className={this.state.data_set_typeEmpty ? "form-control input-error" : "form-control"}
                                                type="text"
                                                name='data_set_type'
                                                placeholder="STARTUP"
                                                value={this.state.formFields.data_set_type}
                                                onChange={this.handleInputChange} />
                                            {this.state.data_set_typeEmpty && <div className='error'>Data Set Type cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Resource Code <span className='required'>*</span></label>
                                            <AsyncSelect
                                                name='resource_id'
                                                placeholder='Please Select or Type to search'
                                                cacheOptions
                                                styles={this.state.resourceEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                defaultOptions={this.state.resources}
                                                loadOptions={(value, callback) => this.handleLoadOptions(value, callback, 'resource')}
                                                value={this.state.formFields.resource}
                                                onChange={this.handleResourceChange}
                                            />
                                            {this.state.resourceEmpty && <div className='error'>Resource cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Description <span className='required'>*</span></label>
                                            <input type="text"
                                                className={this.state.descriptionEmpty ? "form-control input-error" : "form-control"}
                                                value={this.state.formFields.description}
                                                name='description'
                                                onChange={this.handleInputChange} />
                                            {this.state.descriptionEmpty && <div className='error'>Description cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Active</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={this.state.activeEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.active}
                                                onChange={(option) => this.handleSelectChange(option, 'active')}
                                                options={OPTIONS}
                                            />
                                            {this.state.activeEmpty && <div className='error'>Active cannot be empty</div>}
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ? <button className="btn-primary bottom-btn" disabled={this.state.editParams.length > 0 || store.apis.length > 0 ? false : true} onClick={this.handleUpdateDataExportProduct}>Save</button> :
                                    <div>
                                        <button className="btn-primary bottom-btn" onClick={this.handleAddDataExportProduct}>Add</button>
                                        <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                                    </div>
                                }
                            </div>

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}

                        <Modal />

                    </div>
                }
            </div>
        )
    }
}
export default observer(DataExportProductForm)
