import store from '../store'
import { API } from '../constant'
import Localstorage from './localstorage'

export default class Auth {
    /**
     * Method to get token.
     * 
     * @param {String} token    Auth token value.
     * @returns {Mixed}          Object or false.
     */
    static getTokenData(token = null) {
        if (!token) {
            return
        }

        const arTokenParts = String(token).split('.')

        if (arTokenParts.length != 3) {
            return
        }

        return JSON.parse(window.atob(arTokenParts[1]))
    }

    /**
     * Method to get token expiry seconds.
     * 
     * @param {String} token    Auth token value.
     * @returns {Number}        Expiry timestamp in seconds.
     */
    static getTokenExpiry(token = null) {
        if (!token) {
            return
        }

        const arTokendData = this.getTokenData(token);
        return arTokendData.exp
    }

    /**
     * Method to get token expiry (in) seconds.
     * 
     * @param {String} token    Auth token value.
     * @returns {Number}        Expiry timestamp in seconds.
     */
    static getTokenExpiryIn(token = null) {
        if (!token) {
            return
        }

        const arTokendData = this.getTokenData(token);
        return (arTokendData.exp - (new Date().getTime() / 1000));
    }

    /**
     * Method to check token expired or not.
     * 
     * @param {String}      token       Auth token value.
     * @returns {Boolean}               Boolean vakue.
     */
    static isTokenExpired(token = null) {
        if (!token) {
            return
        }

        const tokenExpiry   = (this.getTokenExpiry(token)) * 1000
        const dateNow       = new Date().getTime()
        return tokenExpiry < dateNow
    }

    /**
     * Method to get new access token.
     * 
     * @param {String}      token    Auth token value.
     * @returns {Object}             Expiry timestamp in seconds.
     */
    static async getAccessToken(refreshToken = null) {
        if (!refreshToken) {
            return
        }

        let data = await fetch(`${API}/auth/refresh`, {
            method: 'GET',
            headers: {
                'x-refresh-token': refreshToken
            },
        })

        data        = await data.json()
        let token   = null

        if (data.status == 200) {
            token = data.data.access_token
        }

        return token
    }

    static async setToken(token = null) {
        if (!token) {
            return;
        }

        const data = Localstorage.get('state')
        if (data && data.token) {
            data.token = token
            store.token = token
            Localstorage.set('state', data)
        }
    }

    static getToken() {
        const data = Localstorage.get('state')
        let token
        if (data && data.token) {
            token = data.token
        }

        return token
    }
}