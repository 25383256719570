export default class LocalStorage {
    /**
     * Method to set localstorage
     * 
     * @param {String}  key  Key to store data.
     * @param {Mixed}   data  Value to be stored in locastorage.
     */
    static set(key = null, data) {
        if (!key) {
            return;
        }

        if (typeof data == 'object') {
            data = JSON.stringify(data);
        }

        return localStorage.setItem(key, data);
    }

    static get(key = null) {
        if (!key) {
            return;
        }

        let data
        try {
            data = localStorage.getItem(key);
            return JSON.parse(data);
        } catch (error) {
            return data;
        }
    }

    static remove(key = null) {
        if (!key) {
            return;
        }

        return localStorage.removeItem(key);
    }
}