import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';

// helpers
import store from '../../../store';
import { isObjectEmpty, isURL } from '../../../utility/helpers';

// constants
import { FUNDING_STATUS_OPTIONS, FUNDING_STATUS_VALUES } from '../../../constant';

// apis
import fundingApi from '../../../apis/fundingApi';

class FundingStatusPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: '',
            isLoading: false
        };
    }

    handleVerifyFunding = () => {
        this.setState({
            isLoading: true
        });

        const payload = {};
        const fundingBreakdownId    = store.fundingBreakdownId;
        const fundingStatus         = store.fundingStatus.value;
        const fundingAnnouncement   = store.fundingAnnouncement;
        const fundingComment        = store.fundingComment;

        if ([FUNDING_STATUS_VALUES.verified, FUNDING_STATUS_VALUES.draft].includes(fundingStatus)) {
            if (!fundingAnnouncement) {
                this.setState({
                    errorMessage: "Announcement field cannot be empty",
                    isLoading: false
                });
                return;
            }

            if (!isURL(fundingAnnouncement)) {
                this.setState({
                    errorMessage: "Announcement URL is not valid",
                    isLoading: false
                });
                return;
            }

            payload.verification_status = fundingStatus;
            payload.source_url          = fundingAnnouncement;
            payload.comment             = fundingComment;

        } else {
            payload.verification_status = fundingStatus;
        }

        fundingApi.updateFundingStatus(fundingBreakdownId, payload).then(
            response => {
                if (!isObjectEmpty(response)) {
                    this.handleCloseModal();
                    this.props.getInitialData();
                }
            }
        );
    }

    handleSelect = (option) => {
        store.fundingStatus = option;
    }

    handleAnnouncementChange = (event) => {
        store.fundingAnnouncement = event.target.value;
    }

    handleCommentChange = (event) => {
        store.fundingComment = event.target.value;
    }

    handleCloseModal = () => {
        $('#fundingStatusPopup').modal('hide');

        this.setState({
            errorMessage: '',
            isLoading: false
        });

        store.fundingId             = '';
        store.fundingStatus         = '';
        store.fundingAnnouncement   = '';
        store.fundingComment        = '';
    }

    render() {

        let showInputField = false;
        if (store.fundingStatus && [FUNDING_STATUS_VALUES.verified, FUNDING_STATUS_VALUES.draft].includes(store.fundingStatus.value)) {
            showInputField = true;
        }

        return (
            <div className="modal fade" id="fundingStatusPopup">
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">Update Funding Status</h4>
                            <button type="button" className="close" onClick={this.handleCloseModal}>&times;</button>
                        </div>

                        <div className="modal-body">
                            <label>Funding Status <span className='required'>*</span></label>
                            <Select
                                value={store.fundingStatus}
                                options={FUNDING_STATUS_OPTIONS}
                                onChange={this.handleSelect}
                            />

                            {/* Show the announcement field when the user is verifying the round*/}
                            {showInputField &&
                                <>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 pt-2 form-group">
                                            <label>Announcement URL link <span className='required'>*</span></label>
                                            <input
                                                className={this.state.errorMessage ? "form-control input-error" : "form-control"}
                                                title='text'
                                                name='announcement'
                                                value={store.fundingAnnouncement}
                                                onChange={this.handleAnnouncementChange} />
                                            {this.state.errorMessage && <div className='error'>{this.state.errorMessage}</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 pt-2 form-group">
                                            <label>Comments</label>
                                            <textarea
                                                className="form-control"
                                                title='text'
                                                name='comments'
                                                value={store.fundingComment}
                                                onChange={this.handleCommentChange} />
                                        </div>
                                    </div>
                                </>
                            }
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleCloseModal}>Close</button>
                            <button type="button" className="btn btn-primary" disabled={this.state.isLoading} onClick={this.handleVerifyFunding}>
                                {this.state.isLoading && <i className="fas fa-circle-notch fa-spin mr-2"></i>}
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default observer(FundingStatusPopup)
