import axios from 'axios';

import { API } from '../constant';
import { prepareSelectParam, prepareFilterParam } from '../utility/queryParams';

const researchApi = {

    getResearchData: (getFields, getFilters = [], limit = 20) => {
        const fields = prepareSelectParam(getFields)
        const filters = prepareFilterParam(getFilters)

        const url = `${API}/research?fields=${fields}&filters=${filters}&limit=${limit}&sort=published_at^:DESC`;

        return new Promise((resolve, reject) => {

            axios.get(url).then((response) => {
                if (response.data.status === 200) {
                    resolve({
                        records: response.data.data,
                    });
                } else {
                    resolve({});
                }
            }).catch((err) => reject(err));
        });
    }

}

export default researchApi;