import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react';

// constants
import { ORGANIZATION_USERS_COLUMN_HEADERS } from '../../../utility/constants/tableHeaders';

// helpers
import store from '../../../store';

// components
import FormParent from '../../FormParent';
import OrganizationUserModal from './OrganizationUserModal';
import OrganizationUserActionRenderer from './OrganizationUserActionRenderer';

class OrganizationUsers extends FormParent {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: ORGANIZATION_USERS_COLUMN_HEADERS,
            context: { componentParent: this },
            frameworkComponents: { actionRenderer: OrganizationUserActionRenderer },
            defaultColDef: { suppressMovable: true },
            isInvitePopupOpened: false
        }
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
    }

    handleShowAddUserModal = () => {
        store.isTeamEdit = false;
        this.setState({ teamError: false });

        store.organizationUserDetails = {
            user_id: '',
            organization_id: ''
        };

        $('#addOrganizationUserModal').modal({
            backdrop: 'static',
            keyboard: true,
            show: true
        });
    }

    render() {
        return (
            <div>
                <div className='form-container' style={{ marginTop: 10 }}>
                    <div className='form-wrapper'>
                        <h5>Organization Users</h5>
                        <div className='funding-addinvestor-btn'>
                            <button className="btn-primary bottom-btn fas fa-plus-circle"
                                data-backdrop="static"
                                data-keyboard="false"
                                onClick={this.handleShowAddUserModal}>
                                Add User
                            </button>
                        </div>

                        <div id='grid' className='ag-theme-balham' style={{ width: '100%', color: '#5d656d' }}>
                            <AgGridReact
                                reactNext={true}
                                defaultColDef={this.state.defaultColDef}
                                columnDefs={this.state.columnDefs}
                                rowData={store.organizationUsersData}
                                domLayout='autoHeight'
                                context={this.state.context}
                                frameworkComponents={this.state.frameworkComponents}
                                onGridReady={this.onGridReady}
                                enableCellTextSelection={true}
                            />
                        </div>

                    </div>
                </div>

                <OrganizationUserModal gridApi={this.gridApi} />
            </div>
        )
    }
}

export default observer(OrganizationUsers);