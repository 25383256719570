import store from '../store';
// Apis
import taxonomyApi from '../apis/taxonomyApi';

export const displayAmount = (value, currency = '$') => {
    let amount;

    if (value >= 1000000000) {
        amount = currency + (Math.round(value / 100000000, -1) / 10) + 'B';
    } else if (value >= 1000000) {
        amount = currency + (Math.round(value / 100000, -1) / 10) + 'M';
    } else if (value >= 1000) {
        amount = currency + (Math.round(value / 100, 1) / 10) + 'K';
    } else {
        amount = currency + value;
    }

    return amount;
}

export const isObjectEmpty = (obj) => {
    if (!obj) return true;

    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false
    }
    return true
}

export const isValidEmail = (email) => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/
    return regex.test(String(email))
}

export const isValidFile = (file) => {
    if (file.indexOf('.pdf') === -1 &&
        file.indexOf('.xls') === -1 &&
        file.indexOf('.xlsx') === -1) {
        return false
    }
    else {
        return true
    }
}

export const isValidImageFile = (file) => {
    if (file.indexOf('.jpg') === -1 &&
        file.indexOf('.gif') === -1 &&
        file.indexOf('.png') === -1 &&
        file.indexOf('.jpeg') === -1) {
        return false
    }
    else {
        return true
    }
}

/**
 * Extract primary email from the list of emails
 * @param {Array} emailData
 * @returns {String} user primay email
 */
export const extPrimaryEmail = (emailData) => {
    let primaryEmail = '';
    let tempArr = [];

    if (emailData && emailData.length > 0) {
        tempArr = emailData.filter(v => v.is_primary);

        if (tempArr.length > 0) {
            primaryEmail = tempArr[0].email;
        }
    }

    return primaryEmail;
}



/**
 * Validate string is a URL -> taken from https://www.w3resource.com/javascript-exercises/javascript-regexp-exercise-9.php
 * @param {String} str
 * @returns {Bool} is Url
 */
export const isURL = (str) => {
    let pattern = new RegExp(/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/)
    return pattern.test(str);
}

export const getTaxonomyData = async () => {

    let path
    if (store.type === 'taxonomy-type') {
        path = store.isTaxonomyList ? `${store.type}/${store.currentId}` : `${store.type}/${store.taxonomyId}/taxonomy/${store.currentId}`;
    }
    else {
        path = `${store.type}/${store.currentId}`;
    }

    let paramFields
    if (store.type === 'taxonomy-type') {
        paramFields = store.isTaxonomyList ? ['name', 'description'] : ['name', 'value', 'description']
    }
    if (store.type === 'location') {
        paramFields = ['name', 'country', 'iso_code', 'region']
    }
    if (store.type === 'industry') {
        paramFields = ['name', 'description', 'parent_id']
    }
    if (store.type === 'research-taxonomy') {
        paramFields = ['name', 'value', 'description']
    }
    if (store.type === 'team-role') {
        paramFields = ['name', 'value']
    }

    const taxonomyDetails = await taxonomyApi.getTaxonomyDetails(path, paramFields);
    let obj = {};
    if (store.type === 'industry') {
        // Parent industries
        const parentIndustries = store.parent_industries
        // Set Select Parent Industry Option
        const selectedIndustry = parentIndustries.find(industry => industry.value === taxonomyDetails.parent_id)
        if (selectedIndustry) {
            store.parentIndustry = selectedIndustry;
        }
    }

    Object.keys(store.formFieldsTaxonomy).map(key => {
        obj[key] = taxonomyDetails[key] !== null ? taxonomyDetails[key] : '';
    })

    store.formFieldsTaxonomy = obj;

    $('#taxonomyModal').modal({
        backdrop: 'static',
        keyboard: true,
        show: true
    });
}

export const updateGeoFocusData = (response, geoLoc) => {
    geoLoc.forEach((location) => {
        response.forEach((item) => {
            if (location.label == item.name) {
                location.isDisabled = true;
            }
        })
    });

    response.sort((a, b) => {
        let loca = a.name.toLowerCase(), locb = b.name.toLowerCase();

        if (loca < locb) { return -1 }
        if (loca > locb) { return 1 }
        return 0;
    });

    return { response, geoLoc }
}