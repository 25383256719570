import React, { Component } from 'react'
import { observer } from 'mobx-react'
import cloneDeep from 'lodash/cloneDeep'
import store from '../../store'
import { SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY, API, TAXONOMIES } from '../../constant'
import { getTaxonomyData, getEntities, getIndustries, getLocations, getMultiBranch, getMultiIndustry, getMultiGeoFocus, getEntityRelations, getDevelopmentStage, getProductFiles, getTeams, getPeopleTeams, getCities } from '../../utility/commonApis'
import { prepareSelectParam } from '../../utility/queryParams'
import { isObjectEmpty, updateGeoFocusData } from '../../utility/helpers'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import SideNav from '../SideNav'
import Modal from '../reusable/Modal'
import FormParent from '../FormParent'
import Home from '../Home'
import Team from '../reusable/Team'
import PeopleTeam from '../reusable/PeopleTeam'
import ErrorPopup from '../reusable/ErrorModal';

class EnablerForm extends FormParent {
    constructor(props) {
        super(props)
        this.isEdit = this.props.match.path.indexOf("/edit") > -1;
        this.entityType = "ENABLER";

        this.state = {
            formFields: {
                name: '',
                logo: '',
                cover_image: '',
                description: '',
                body: '',
                type_id: '',
                video_link: '',
                hq_id: '',
                city_id: '',
                linkedin: '',
                facebook: '',
                twitter: '',
                website: '',
                email_relations: '',
                min_ticket_size: '',
                max_ticket_size: '',
                owned_by: ''
            },
            users: [{ 'value': 0, 'label': 'none' }],
            logoMsg: '',
            logoEmpty: false,
            logoError: false,
            logoSuccess: false,
            logoUpload: false,
            cover_imageMsg: '',
            cover_imageEmpty: false,
            cover_imageError: false,
            cover_imageSuccess: false,
            cover_imageUpload: false,
            enablerTypes: [],
            locations: [],
            industries: [],
            geoLocations: [],
            stages: [],
            startups: [],
            cities: [],
            logo: '',
            cover_image: '',
            logoFile: '',
            cover_imageFile: '',
            editlogo: '',
            editcover_image: '',
            editParams: [],
            'startup-relValue': '',
            'dev-stageValue': '',
            'geo-focusValue': '',
            'product-fileValue': '',
            branchValue: '',
            industryValue: '',
            multiFields: {
                userInvite: [],
                team: [],
                branch: [],
                industry: [],
                'geo-focus': [],
                'product-file': [],
                'startup-rel': [],
                'dev-stage': []
            },
            branch: [],
            'geo-focus': [],
            industry: [],
            'product-file': [],
            'startup-rel': [],
            'dev-stage': [],
            fileFields: ['logo', 'cover_image'],
            socialFields: ['linkedin', 'facebook', 'twitter'],
            validationFields: ['name', 'logo', 'description', 'type_id', 'hq_id'],
            errorMessage: ''
        }
        this.parameters = {
            name: '',
            logo: '',
            cover_image: '',
            description: '',
            body: '',
            type_id: '',
            video_link: '',
            hq_id: '',
            city_id: '',
            linkedin: '',
            facebook: '',
            twitter: '',
            website: '',
            email_relations: '',
            min_ticket_size: '',
            max_ticket_size: '',
            owned_by: '',
        }
    }

    componentDidMount() {
        getLocations().then(res => {
            this.setState({ locations: res, geoLocations: cloneDeep(res) })
        })

        if (store.isEdit && store.currentId) {
            let paramFields = ['id', 'name', 'logo_data', 'cover_image_data', 'description', 'body', 'video_link', 'type_data', 'city_data', 'development_stage_data',
                'hq_data', 'max_ticket_size', 'min_ticket_size', 'linkedin', 'facebook', 'twitter', 'website', 'owned_by', 'owned_by_data', 'email_relations']
            let fields = prepareSelectParam(paramFields)

            fetch(`${API}/enabler/${store.currentId}/?fields=${fields}`, {
                method: 'GET',
                headers: {
                    'Authorization': store.token,
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(async data => {
                    let obj = {}
                    Object.keys(this.state.formFields).map(key => {
                        if (key === 'logo') {
                            obj[key] = !isObjectEmpty(data.data.logo_data) ? data.data.logo_data.name : ''
                        }
                        else if (key === 'cover_image') {
                            obj[key] = !isObjectEmpty(data.data.cover_image_data) ? data.data.cover_image_data.name : ''
                        }
                        else if (key === 'hq_id') {
                            obj[key] = !isObjectEmpty(data.data.hq_data) ? { 'value': data.data.hq_data.id, 'label': data.data.hq_data.name } : ''
                        }
                        else if (key === 'city_id') {
                            obj[key] = !isObjectEmpty(data.data.city_data) ? { 'value': data.data.city_data.id, 'label': data.data.city_data.name } : ''
                        }
                        else if (key === 'type_id') {
                            obj[key] = !isObjectEmpty(data.data.type_data) ? { 'value': data.data.type_data.id, 'label': data.data.type_data.name } : ''
                        }
                        else if (key === 'owned_by') {
                            if (data.data.owned_by !== 0 || data.data.owned_by !== null) {
                                obj[key] = !isObjectEmpty(data.data.owned_by_data) ? { 'value': data.data.owned_by_data.id, 'label': `${data.data.owned_by_data.first_name} ${data.data.owned_by_data.last_name}` } : ''
                            }
                            else {
                                obj[key] = { 'value': 0, 'label': 'none' }
                            }
                        }
                        else {
                            obj[key] = data.data[key] !== null ? data.data[key] : ''
                        }
                    })
                    this.state.formFields = obj
                    this.setState(this.state)

                    if (!isObjectEmpty(data.data.hq_data) && data.data.hq_data.id) {
                        const cities = await getCities(data.data.hq_data.id)
                        this.setState({ cities })
                    }
                })

            getMultiBranch(`enabler/${store.currentId}`).then(res => {
                if (res.length > 0) {
                    this.setState({ 'branch': res })
                }
            })

            getMultiIndustry(`enabler/${store.currentId}`).then(res => {
                if (res.length > 0) {
                    this.setState({ 'industry': res })
                }
            })

            getMultiGeoFocus(`enabler/${store.currentId}`).then(res => {
                if (res.length > 0) {
                    let { geoLocations } = this.state;
                    let { response, geoLoc } = updateGeoFocusData(res, geoLocations);
                    this.setState({ 'geo-focus': response, geoLocations: geoLoc })
                }
            })

            getEntityRelations(`enabler/${store.currentId}`).then(res => {
                if (res.length > 0) {
                    this.setState({ 'startup-rel': res })
                }
            })

            getDevelopmentStage(`enabler/${store.currentId}`).then(res => {
                if (res.length > 0) {
                    this.setState({ 'dev-stage': res })
                }
            })

            getTeams(`enabler/${store.currentId}`)

            getPeopleTeams(`enabler/${store.currentId}`)

            getProductFiles(`enabler/${store.currentId}`).then(res => {
                if (res.length > 0) {
                    this.setState({ 'product-file': res })
                }
            })
        }

        getTaxonomyData(TAXONOMIES.enablerType).then(res => {
            this.setState({ enablerTypes: res })
        })

        getTaxonomyData(TAXONOMIES.devStage).then(res => {
            this.setState({ stages: res })
        })

        getIndustries().then(res => {
            this.setState({ industries: res })
        })

        getEntities('startup').then(res => {
            this.setState({ startups: res })
        })

        fetch(`${API}/user?fields=id^,first_name^,last_name`, {
            method: 'GET',
            headers: {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            },
        })
            .then(response => { return response.json() })
            .then(data => {
                if (data.status === 200) {
                    let arr = this.state.users
                    data.data.map(item => {
                        arr.push({ value: item.id, label: `${item.first_name} ${item.last_name}` })
                    })
                    this.setState({ users: arr })
                }
            })
    }

    renderErrors = () => {
        let {
            nameEmpty,
            logoMsg,
            logoEmpty,
            logoError,
            logoUpload,
            cover_imageMsg,
            cover_imageError,
            cover_imageUpload,
            descriptionEmpty,
            type_idEmpty,
            min_ticket_sizeNumber,
            max_ticket_sizeNumber,
            max_ticket_sizeMax,
            linkedinSocial,
            facebookSocial,
            twitterSocial,
            email_relationsInvalid,
            hq_idEmpty
        } = this.state

        let errors = []

        if (nameEmpty)
            errors.push(<div className='error'>Corporate name cannot be empty</div>)
        if (descriptionEmpty)
            errors.push(<div className='error'>Description cannot be empty</div>)
        if (logoMsg && logoMsg.length > 0)
            errors.push(<div className='error'>{logoMsg}</div>)
        if (logoEmpty)
            errors.push(<div className='error'>Logo cannot be empty</div>)
        if (logoError || cover_imageError)
            errors.push(<div className='error'>Upload failed!.</div>)
        if (logoUpload || cover_imageUpload)
            errors.push(<div className='error'>File is chosen but not uploaded.</div>)
        if (cover_imageMsg && cover_imageMsg.length > 0)
            errors.push(<div className='error'>{cover_imageMsg}</div>)
        if (type_idEmpty)
            errors.push(<div className='error'>Type cannot be empty.</div>)
        if (min_ticket_sizeNumber)
            errors.push(<div className='error'>Minimum Ticket Size should be number</div>)
        if (max_ticket_sizeNumber)
            errors.push(<div className='error'>Maximum Ticket Size should be number</div>)
        if (max_ticket_sizeMax)
            errors.push(<div className='error'>Maximum Ticket Size should be greater than Minimum Ticket Size</div>)
        if (linkedinSocial || facebookSocial || twitterSocial || email_relationsInvalid)
            errors.push(<div className='error'>Invalid Social URL</div>)
        if (hq_idEmpty) {
            errors.push(<div className='error'>Head Quarter cannot be empty.</div>)
        }

        return errors
    }


    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT ENABLER</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD ENABLER</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Company Name <span className='required'>*</span></label>
                                            <input type="text" className={this.state.nameEmpty ? "form-control input-error" : "form-control"} name="name" value={this.state.formFields.name} onChange={this.handleInputChange} />
                                            {this.state.nameEmpty && <div className='error'>Corporate name cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Company Logo <span className='required'>*</span></label>
                                            <div className="input-group mb-3">
                                                <div className={this.state.logoEmpty ? "custom-file input-error" : "custom-file"}>
                                                    <div className="form-filename-holder">{this.state.formFields.logo === '' ? 'Choose a File' : this.state.formFields.logo}</div>
                                                    <input type='file' className="custom-file-input" name="logo" onChange={this.handleImageUpload} />
                                                </div>
                                                <div className="input-group-append">
                                                    <span className="input-group-text" onClick={() => this.addFileUpload('logo', 'logo', this.state.logoFile)}>Upload</span>
                                                </div>
                                            </div>
                                            {this.state.logoMsg.length > 0 && <div className='error'>{this.state.logoMsg}</div>}
                                            {this.state.logoEmpty && <div className='error'>Logo cannot be empty</div>}
                                            {this.state.logoError && <div className='error'>Upload failed!.</div>}
                                            {this.state.logoSuccess && <div className='success'>Upload successful!</div>}
                                            {this.state.logoUpload && <div className='error'>File is chosen but not uploaded</div>}
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Company Cover</label>
                                            <div className="input-group mb-3">
                                                <div className="custom-file">
                                                    <div className="form-filename-holder">{this.state.formFields.cover_image === '' ? 'Choose a File' : this.state.formFields.cover_image}</div>
                                                    <input type='file' className="custom-file-input" name="cover_image" onChange={this.handleImageUpload} />
                                                </div>
                                                <div className="input-group-append">
                                                    <span className="input-group-text" onClick={() => this.addFileUpload('cover-image', 'cover_image', this.state.cover_imageFile)}>Upload</span>
                                                </div>
                                            </div>
                                            {this.state.cover_imageMsg.length > 0 && <div className='error'>{this.state.cover_imageMsg}</div>}
                                            {this.state.cover_imageError && <div className='error'>Upload failed!.</div>}
                                            {this.state.cover_imageSuccess && <div className='success'>Upload successful!</div>}
                                            {this.state.cover_imageUpload && <div className='error'>File is chosen but not uploaded</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Short Description <span className='required'>*</span></label>
                                            <textarea rows='8'
                                                className={this.state.descriptionEmpty ? "form-control input-error" : "form-control"}
                                                name='description'
                                                value={this.state.formFields.description}
                                                onChange={this.handleInputChange} />
                                            {this.state.descriptionEmpty && <div className='error'>Description cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Body</label>
                                            <textarea rows='8'
                                                className={"form-control"}
                                                name='body'
                                                value={this.state.formFields.body}
                                                onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Video Link:</label>
                                            <input className="form-control" value={this.state.formFields.video_link} name="video_link" onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-12 form-group">
                                            <label >Product Files</label>
                                            <div className="custom-file" style={{ borderRadius: 5 }}>
                                                <div className="form-filename-holder">{this.state['product-fileValue']}</div>
                                                <input type='file' className="custom-file-input" name='product_files' onChange={(ev) => this.handleMultiFileUpload(ev, 'product-file')} multiple />
                                            </div>
                                            {this.state['product-file'].length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state['product-file'].map((item, i) => {
                                                        return <li key={i}>{item.name} <i className='fa fa-times-circle' onClick={() => this.handleMultiRemove(i, item.id, 'product-file')}></i></li>
                                                    })}
                                                </div>
                                            }
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Type <span className='required'>*</span></label>
                                            <Select
                                                name="type_id"
                                                placeholder='Please Select'
                                                styles={this.state.type_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.type_id}
                                                onChange={(option) => this.handleSelectChange(option, 'type_id')}
                                                options={this.state.enablerTypes}
                                            />
                                            {this.state.type_idEmpty && <div className='error'>Type cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Head Quarter <span className='required'>*</span></label>
                                            <Select
                                                name="hq_id"
                                                placeholder='Please Select'
                                                styles={this.state.type_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.hq_id}
                                                onChange={this.handleHQChange}
                                                options={this.state.locations}
                                            />
                                            {this.state.hq_idEmpty && <div className='error'>Head Quarter cannot be empty</div>}
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-12 form-group">
                                            <label >City</label>
                                            <AsyncSelect
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                cacheOptions
                                                value={this.state.formFields.city_id}
                                                defaultOptions={this.state.cities}
                                                loadOptions={this.loadCities}
                                                onChange={(option) => this.handleSelectChange(option, 'city_id')}
                                                isDisabled={!this.state.formFields.hq_id}
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        minHeight: 28,
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Branch</label>
                                            <Select
                                                placeholder='Select branch'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.branchValue}
                                                onChange={option => this.handleMultiSelectChange(option, 'location_id', 'branch')}
                                                options={this.state.locations}
                                            />
                                            {this.state.branch.length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state.branch.map((item, i) => {
                                                        return <li key={i}>{item.name} <i className='fa fa-times-circle' onClick={() => this.handleMultiRemove(i, item.id, 'branch')}></i></li>
                                                    })}
                                                </div>
                                            }
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Geo Focus</label>
                                            <Select
                                                placeholder='Select geo-focus'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state['geo-focusValue']}
                                                onChange={option => this.handleAddGeoFocus(option, 'location_id', 'geo-focus')}
                                                options={this.state.geoLocations}
                                                isOptionDisabled={(option) => option.isDisabled}
                                            />
                                            {this.state['geo-focus'].length > 0 &&
                                                <div className='permission-policy-list-wrapper geo-focus-list'>
                                                    {this.state['geo-focus'].map((item, i) => {
                                                        return <li key={i}>{item.name} <i className='fa fa-times-circle' onClick={() => this.handleMultiRemoveGeoFocus(i, item.id, 'geo-focus')}></i></li>
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className='row'>

                                        <div className="col-md-6 col-sm-6 col-12 form-group">
                                            <label >Industry</label>
                                            <Select
                                                placeholder='Select industry'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.industryValue}
                                                onChange={option => this.handleMultiSelectChange(option, 'industry_id', 'industry')}
                                                options={this.state.industries}
                                            />
                                            {this.state.industry.length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state.industry.map((item, i) => {
                                                        return <li key={i}>{item.name} <i className='fa fa-times-circle' onClick={() => this.handleMultiRemove(i, item.id, 'industry')}></i></li>
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Minimum Ticket Size</label>
                                            <input title='number'
                                                className={this.state.min_ticket_sizeNumber ? "form-control input-error" : "form-control"}
                                                value={this.state.formFields.min_ticket_size}
                                                name="min_ticket_size"
                                                onChange={this.handleInputChange} />
                                            {this.state.min_ticket_sizeNumber && <div className='error'>Minimum Ticket Size should be number</div>}
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Maximum Ticket Size:</label>
                                            <input title='number'
                                                className={this.state.max_ticket_sizeNumber || this.state.max_ticket_sizeMax ? "form-control input-error" : "form-control"}
                                                value={this.state.formFields.max_ticket_size}
                                                name="max_ticket_size"
                                                onChange={this.handleInputChange} />
                                            {this.state.max_ticket_sizeNumber && <div className='error'>Maximum Ticket Size should be number</div>}
                                            {this.state.max_ticket_sizeMax && <div className='error'>Maximum Ticket Size should be greater than Minimum Ticket Size</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Linkedin URL</label>
                                            <input className={this.state.linkedinSocial ? "form-control input-error " : "form-control"}
                                                name='linkedin'
                                                value={this.state.formFields.linkedin}
                                                onChange={this.handleInputChange} />
                                            {this.state.linkedinSocial && <div className='error'>Invalid url</div>}
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Facebook URL</label>
                                            <input className={this.state.facebookSocial ? "form-control input-error " : "form-control"}
                                                name='facebook'
                                                value={this.state.formFields.facebook}
                                                onChange={this.handleInputChange} />
                                            {this.state.facebookSocial && <div className='error'>Invalid url</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Twitter URL</label>
                                            <input className={this.state.twitterSocial ? "form-control input-error " : "form-control"}
                                                name='twitter'
                                                value={this.state.formFields.twitter}
                                                onChange={this.handleInputChange} />
                                            {this.state.twitterSocial && <div className='error'>Invalid url</div>}
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Website URL</label>
                                            <input className="form-control" value={this.state.formFields.website} name="website" onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Email Relations</label>
                                            <input className={this.state.email_relationsInvalid ? "form-control input-error " : "form-control"}
                                                name='email_relations'
                                                value={this.state.formFields.email_relations}
                                                onChange={this.handleInputChange} />
                                            {this.state.email_relationsInvalid && <div className='error'>Invalid url</div>}
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Entity Relations</label>
                                            <AsyncSelect
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                cacheOptions
                                                value={this.state['startup-relValue']}
                                                defaultOptions={this.state.startups}
                                                loadOptions={(value, callback) => this.handleLoadOptions(value, callback, 'startup')}
                                                onChange={option => this.handleMultiSelectChange(option, 'startup_id', 'startup-rel')}
                                            />
                                            {this.state['startup-rel'].length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state['startup-rel'].map((item, i) => {
                                                        return <li key={i}>{item.name} <i className='fa fa-times-circle' onClick={() => this.handleMultiRemove(i, item.id, 'startup-rel')}></i></li>
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Development Stage</label>
                                            <Select
                                                placeholder='Select development stage'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state['dev-stageValue']}
                                                onChange={option => this.handleMultiSelectChange(option, 'development_stage_id', 'dev-stage')}
                                                options={this.state.stages}
                                            />
                                            {this.state['dev-stage'].length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state['dev-stage'].map((item, i) => {
                                                        return <li key={i}>{item.name} <i className='fa fa-times-circle' onClick={() => this.handleMultiRemove(i, item.id, 'dev-stage')}></i></li>
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    {store.isEdit &&
                                        <div className='row'>
                                            <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                                <label >Owner</label>
                                                <AsyncSelect
                                                    placeholder='Please Select'
                                                    styles={SELECT_STYLES}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: SELECT_THEME_PRIMARY
                                                        }
                                                    })}
                                                    cacheOptions
                                                    value={this.state.formFields.owned_by}
                                                    defaultOptions={this.state.users}
                                                    loadOptions={(value, callback) => this.handleLoadOptions(value, callback, 'user')}
                                                    onChange={(option) => this.handleSelectChange(option, 'owned_by')}
                                                />
                                            </div>
                                        </div>
                                    }

                                    <Team isEdit={this.isEdit} entityType={this.entityType} />

                                    <PeopleTeam isEdit={this.isEdit} entityType={this.entityType} />

                                </div>
                            </div>

                            {this.renderErrors()}

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ? <button className="btn-primary bottom-btn" disabled={this.state.editParams.length > 0 || store.apis.length > 0 ? false : true} onClick={() => this.handleUpdate('enabler')}>Save</button> :
                                    <div>
                                        <button className="btn-primary bottom-btn" onClick={() => this.handleAdd('enabler')}>Add Enabler</button>
                                        <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                                    </div>
                                }
                            </div>

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <ErrorPopup errorMessage={this.state.errorMessage} />
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}
export default observer(EnablerForm)
