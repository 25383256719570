import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react';
import Select from 'react-select';

// constants
import { VALUATION_CALCULATION_COLUMN_HEADERS, ALLOWED_QUERY_OPERATOR, SELECT_STYLES, SELECT_THEME_PRIMARY, TAXONOMIES } from '../../constant';
import { API_PATH } from '../../utility/constants/apiPath';

// helpers
import store from '../../store';
import { prepareSelectParam } from '../../utility/queryParams';

// apis
import { getTaxonomyData } from '../../utility/commonApis';

// components
import Parent from '../Parent';
import Home from '../Home';
import SideNav from '../SideNav';
import ActionRenderer from '../reusable/ActionRenderer';
import Sort from '../reusable/Sort';
import Pagination from '../reusable/Pagination';
import Modal from '../reusable/Modal';

class ValuationCalculation extends Parent {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: VALUATION_CALCULATION_COLUMN_HEADERS,
            context: { componentParent: this },
            frameworkComponents: { actionRenderer: ActionRenderer },
            showfunding_stage: false,

            funding_stage_id: '',
            funding_stages: [],
            funding_stageStore: [],
            filters: {
                funding_stage_id: [],
            },
            filterFields: [
                { key: 'funding_stage_id', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },
            ]
        };

        store.sortType = this.props.location.pathname.split('/')[1];
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
    }

    componentDidMount() {
        document.addEventListener('mousedown', (e) => {
            if (this.stage) { if (!this.stage.contains(e.target)) this.setState({ showfunding_stage: false }); }
        });

        // valuation calculation request fields
        const paramFields = ['id', 'funding_stage_data', 'lower_share_percentage', 'upper_share_percentage', 'active', 'equity_percentage', 'description'];
        store.fields = prepareSelectParam(paramFields);

        this.fetchListData(API_PATH.valuationCalculation, store.fields);

        getTaxonomyData(TAXONOMIES.fundingStage).then(res => {
            this.setState({ funding_stages: res, funding_stageStore: res });
        });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => {
            if (this.stage) { if (!this.stage.contains(e.target)) this.setState({ showfunding_stage: false }); }
        });
    }

    clearFilters = () => {
        this.setState({
            funding_stage_id: ''
        }, () => this.handleClearAllFilters(API_PATH.valuationCalculation));
    };

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>VALUATION CALCULATION</h3>
                            <div className='filter-container'>
                                <div className="filter-row" >
                                    <div className="col-12" style={{ padding: 0 }}>
                                        <div ref={node => this.stage = node} className='filter-firstline' >
                                            <div style={{ width: '100%' }}>
                                                <input className='filter-input'
                                                    style={{ paddingLeft: 15 }}
                                                    placeholder="By Funding Stage"
                                                    value={this.state.funding_stage_id}
                                                    onChange={(e) => this.handleInputChange(e.target.value, 'industry')}
                                                    onFocus={() => { this.setState({ showfunding_stage: true }); }} />
                                                {this.state.showfunding_stage && this.state.funding_stages ?
                                                    <div className='filter-dropdown w-100' >
                                                        <div >
                                                            {this.state.filters.funding_stage_id.length > 0 ?
                                                                <div className='filter-dropdown-wrapper' >
                                                                    {this.state.filters.funding_stage_id.map((item, i) => {
                                                                        return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'funding_stage_id')}>
                                                                            {item}&nbsp;<i className="fa fa-times-circle"></i>
                                                                        </div>;
                                                                    })}
                                                                </div> : null}
                                                        </div>
                                                        <ul className='filter-list-container'>
                                                            <div className='filter-list-wrapper' >
                                                                <div>
                                                                    {this.state.funding_stages.map((item, j) =>
                                                                        <div key={j}>
                                                                            <li style={{ fontSize: 12, }} onClick={() => this.handleSelectFilter(item, 'funding_stage_id')}>
                                                                                {item.label}
                                                                            </li><br />
                                                                        </div>)}
                                                                </div>
                                                                <div className='filter-stickybtn ' >
                                                                    <div style={{ width: 140 }}>
                                                                        <a tabIndex='0' className='filter-canclebtn' onClick={() => this.handleClearFilter('funding_stage_id')}>Clear</a> &nbsp; | &nbsp;
                                                                        <a tabIndex='0' className='filter-applybtn' onClick={() => { this.setState({ showfunding_stage: false }); this.handleSearchFilters(API_PATH.valuationCalculation); }}>Apply</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div> : null}
                                            </div>
                                            {this.state.showfunding_stage ? <i className="fa fa-chevron-up " style={{ paddingRight: 10 }} onClick={() => this.setState({ showfunding_stage: false })} ></i> :
                                                <i className="fa fa-chevron-down " style={{ paddingRight: 10 }} onClick={() => this.setState({ showfunding_stage: true })} ></i>}
                                        </div>
                                    </div>
                                </div>
                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-12 filter-searchbtn mr-0" onClick={this.clearFilters}>Clear All</a>
                                    </div>
                                </div>
                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    frameworkComponents={this.state.frameworkComponents}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                />
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }
            </div>
        );
    }
}

export default observer(ValuationCalculation);
