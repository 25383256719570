import React from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

// helpers, constants
import store from '../../store'
import { API, SELECT_STYLES, TAXONOMIES, SELECT_THEME_PRIMARY, POLICY_PERIODS, OPTIONS, SELECT_STYLES_ERROR } from '../../constant';
import { prepareSelectParam } from '../../utility/queryParams';
import { isObjectEmpty } from '../../utility/helpers';

// apis
import { getRolesTaxonomy, getTaxonomyData } from '../../utility/commonApis';

// components
import SideNav from '../SideNav';
import Modal from '../reusable/Modal';
import FormParent from '../FormParent';
import Home from '../Home';
import PolicyTable from './PolicyTable';

/**
 * @todo: Edit route has been temporarily commented because the API to "get data by id" is not ready
 * Basic functionality for edit has been finished but testing needs to be done
 */
class RDPolicyForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                download_limit: '',
                period: '',
                role_id: '',
                weight: '',
                active: '',
                policy: []
            },

            editParams: [],
            validationFields: ['download_limit', 'period', 'role_id'],
            roles: [],
            researchCategories: []
        }
        this.parameters = {
            download_limit: '',
            period: '',
            role_id: '',
            weight: '',
            active: '',
            policy: []
        }
    }

    async componentDidMount() {

        await getRolesTaxonomy().then(res => this.setState({ roles: res }))
        await getTaxonomyData(TAXONOMIES.researchCategory).then(res => this.setState({ researchCategories: res }))

        if (store.isEdit && store.currentId) {
            const paramFields = ['id', 'download_limit', 'period', 'policy', 'weight', 'active', 'role_id', 'role_data']
            const fields = prepareSelectParam(paramFields)

            fetch(`${API}/rd-policy/${store.currentId}/?fields=${fields}&filters=id^:${store.currentId}`, {
                method: 'GET',
                headers: {
                    'Authorization': store.token,
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    let obj = {}
                    Object.keys(this.state.formFields).map(key => {
                        if (key === 'period') {
                            const filteredPeriods = POLICY_PERIODS.filter(item => item.value == data.data.period)
                            if (filteredPeriods.length > 0) {
                                obj[key] = filteredPeriods[0]
                            }
                        }
                        else if (key === 'role_id') {
                            obj[key] = !isObjectEmpty(data.data.role_data) ? { 'value': data.data.role_data.id, 'label': data.data.role_data.name } : ''
                        }
                        else if (key === 'active') {
                            obj[key] = data.data.active == 0 ? { 'value': data.data.active, 'label': 'No' } : { 'value': data.data.active, 'label': 'Yes' }
                        } else if (key === 'policy') {
                            obj[key] = this.extractPolicyData(data.data.policy)
                        }
                        else {
                            obj[key] = data.data[key] !== null ? data.data[key] : ''
                        }
                    })
                    
                    this.setState({ formFields: obj })
                })
        }
    }


    /**
     * This method will raw policy array from the API
     * And will make appropriate change e.g. adding labels to category and periods
     * and will return final array
     */
    extractPolicyData = (data) => {
        const returnAr = [];

        if (data && data.length > 0) {
            data.map(item => {
                const tempObj = item;
                if (tempObj.period) {
                    const filteredPeriods = POLICY_PERIODS.filter(item => item.value == tempObj.period);
                    if (filteredPeriods.length > 0) {
                        tempObj.periodLabel = filteredPeriods[0].label;
                    }
                }

                if (tempObj.category_id) {
                    const filteredCategories = this.state.researchCategories.filter(item => item.value == tempObj.category_id);
                    if (filteredCategories.length > 0) {
                        tempObj.categoryLabel = filteredCategories[0].label;
                    }
                }

                returnAr.push(tempObj);
            });
        }

        return returnAr;
    }

    /**
     * This method will get called from PolicyModal
     * when fields for policy has been added succesfully
     */
    onPolicySuccess = (data) => {
        if (store.isEdit) {
            if (this.state.editParams.indexOf('policy') === -1) {
                this.state.editParams.push('policy');
            }
        }

        if (!isObjectEmpty(data)) {
            const { formFields }  = { ...this.state };
            const policy = formFields.policy;

            policy.push(data);
            formFields.policy       = policy;
            this.parameters.policy  = policy;
            this.setState({ formFields });
        }
    }
    
    /**
     * This method will get called from PolicyTable Component
     * when policy gets removed
     */
    onPolicyRemove = (data) => {
        if (store.isEdit) {
            if (this.state.editParams.indexOf('policy') === -1) {
                this.state.editParams.push('policy');
            }
        }

        const { formFields } = { ...this.state };
        formFields.policy       = data;
        this.parameters.policy  = data;
        this.setState({ formFields });
    }

    /**
     * This method is used to make appropriate changes in the data
     * before making an API call
     */
    beforeAdd = () => {
        const policyParamsClone = [...this.parameters.policy];

        policyParamsClone.map(item => {
            delete item.categoryLabel;
            delete item.periodLabel;
            delete item.uniqueId;
        });
        
        this.parameters.policy = policyParamsClone;
        this.handleAdd('rd-policy');
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT RD POLICY</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD RD POLICY</h3>
                            }   

                            <div className='form-container'>
                                <div className='form-wrapper'>
                                    <div className="row">
                                        <div className="col-md-6 col-12 form-group">
                                            <label >Download Limit <span className="required">*</span></label>
                                            <input 
                                                type="number"
                                                className={this.state.download_limitEmpty ? "form-control input-error" : "form-control"}
                                                name='download_limit'
                                                value={this.state.formFields.download_limit}
                                                onChange={this.handleInputChange} />
                                        </div>

                                        <div className="col-md-6 col-12 form-group">
                                            <label >Period <span className="required">*</span></label>
                                            <Select
                                                name='period'
                                                placeholder='Please Select'
                                                styles={this.state.periodEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.period}
                                                onChange={(option) => this.handleSelectChange(option, 'period')}
                                                options={POLICY_PERIODS}
                                            />
                                        </div>

                                        <div className="col-md-6 col-12 form-group">
                                            <label >Role <span className="required">*</span></label>
                                            <AsyncSelect
                                                name='role_id'
                                                placeholder='Please select a role'
                                                styles={this.state.role_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                cacheOptions
                                                value={this.state.formFields.role_id}
                                                defaultOptions={this.state.roles}
                                                loadOptions={(value, callback) => this.handleLoadOptions(value, callback, 'role')}
                                                onChange={(option) => this.handleSelectChange(option, 'role_id')}
                                            />
                                        </div>

                                        <div className="col-md-6 col-12 form-group">
                                            <label >Weight</label>
                                            <input 
                                                type="number"
                                                className={"form-control"}
                                                name='weight'
                                                value={this.state.formFields.weight}
                                                onChange={this.handleInputChange} />
                                        </div>

                                        <div className="col-md-6 col-12 form-group">
                                            <label >Active</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.active}
                                                onChange={(option) => this.handleSelectChange(option, 'active')}
                                                options={OPTIONS}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <PolicyTable
                                data={this.state.formFields.policy}
                                researchCategories={this.state.researchCategories}
                                onSuccess={this.onPolicySuccess}
                                onPolicyRemove={this.onPolicyRemove}
                            />

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ? <button className="btn-primary bottom-btn" disabled={this.state.editParams.length > 0 || store.apis.length > 0 ? false : true} onClick={() => this.handleUpdate('rd-policy')}>Save</button> :
                                    <button className="btn-primary bottom-btn" onClick={this.beforeAdd}>Add RD RD Policy</button>}
                                <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                            </div>

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}

export default observer(RDPolicyForm)
