import { API, ALLOWED_QUERY_OPERATOR, MIN_SEARCH_LENGTH } from '../constant'
import { prepareSelectParam, prepareFilterParam } from '../utility/queryParams';
import store from '../store'
import { isObjectEmpty, extPrimaryEmail } from './helpers'
import { USER_STATUS_OBJ } from '../constant'

const getHeaderConfig = {
    method: 'GET',
    headers: {
        'Authorization': store.token,
        'Content-Type': 'application/json'
    }
};

/**
 * Call get API and return fetched data or rejected error
 * @param {String} path
 * @param {Array} fields Array of strings
 * @param {Array} filters Array of object with filter name and OP
 * @param {Number} limit
 * @param {Number} page
 * @returns {Object, Array} Resolves with data or rejects with an error object
 */
export const getAPI = (path, fields = null, filters = null, limit = 300, page = 0) => {
    return new Promise(async (resolve, reject) => {
        try {
            let returnData      = [];
            let fieldParams     = "";
            let filterParams    = "";

            if (fields && fields.length > 0) {
                fieldParams = prepareSelectParam(fields);
            }

            if (filters && filters.length > 0) {
                filterParams = prepareFilterParam(filters);
            }

            const config = {
                method: 'GET',
                headers: {
                    'Authorization': store.token,
                    'Content-Type': 'application/json'
                }
            };

            const response  = await fetch(`${API}/${path}?fields=${fieldParams}&filters=${filterParams}&limit=${limit}&page=${page}`, config);
            const data      = await response.json();

            if (data && data.data) {
                returnData = data.data;
            }

            resolve(returnData);
        } catch (error) {
            reject(error)
        }
    });
}

export const getTaxonomyData = async (id) => {
    let response = await fetch(`${API}/taxonomy-type/${id}/taxonomy/?fields=id^,name&limit=100`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        },
    })
    let data = await response.json()
    let arr = []
    await data.data.map(item => {
        arr.push({ value: item.id, label: item.name })
    })
    return arr
}

export const getLocations = async () => {
    const filters = prepareFilterParam([
        { key: 'is_region', value: [0], op: ALLOWED_QUERY_OPERATOR.equal }, // exclude regions
        { key: 'country', value: [0], op: ALLOWED_QUERY_OPERATOR.equal },   // exclude cities
    ]);
    let response = await fetch(`${API}/location/?fields=id^,name&filters=${filters}&limit=300`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }
    })
    let data = await response.json()
    let arr = []
    await data.data.map(item => {
        arr.push({ value: item.id, label: item.name })
    })
    return arr
}

export const getDepartments = async () => {
    let response = await fetch(`${API}/magnitt-department/?fields=id^,name&limit=300`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }
    })
    let data = await response.json()
    let arr = []
    await data.data.map(item => {
        arr.push({ value: item.id, label: item.name })
    })
    return arr
}

export const getCities = async (countryId, filters = "", maxLimit = 20) => {
    const fields = ['id', 'name'];
    const fieldParams = prepareSelectParam(fields);

    let response = await fetch(`${API}/location/${countryId}/city?fields=${fieldParams}&filters=${filters}&limit=${maxLimit}`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }
    })
    let data = await response.json()
    let arr = []
    await data.data.map(item => {
        arr.push({ value: item.id, label: item.name })
    })
    return arr
}


export const getIndustries = async (filters) => {
    let response = await fetch(`${API}/industry?fields=id^,name${filters && `&filters=${filters}` || ''}&limit=200`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }
    })
    let data = await response.json()
    let arr = []
    await data.data.map(item => {
        arr.push({ value: item.id, label: item.name })
    })
    return arr
}

export const getTechnology = async (filters) => {
    const response = await fetch(`${API}/technology?fields=*${filters && `&filters=${filters}` || ''}&limit=200`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }
    })
    const res = await response.json()
    return res.data
}

export const getEntities = async (path, filterValue, params) => {
    let filterParams    = ""
    let userParamFields = ['id', 'name']

    if (filterValue && filterValue.length >= MIN_SEARCH_LENGTH) {
        const filterFields = [{ 'key': 'name', 'op': ALLOWED_QUERY_OPERATOR.substring, 'value': [filterValue] }]
        filterParams = prepareFilterParam(filterFields)
    }

    if (params && params.length > 0) {
        userParamFields = [...new Set([...userParamFields, ...params])]
    }

    const startupParams = prepareSelectParam(userParamFields)

    const response = await fetch(`${API}/${path}/?fields=${startupParams}&filters=${filterParams}`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }
    })

    const data = await response.json()
    let arr = []
    await data.data.map(item => {
        arr.push({ value: item.id, label: `[${item.id}] ${item.name}` })
    })
    return arr
}

export const getNewsTagsList = async () => {
    let response = await fetch(`${API}/news-tag?fields=id^,name`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }
    })
    let data = await response.json()
    let arr = []
    await data.data.map(item => {
        arr.push({ value: item.id, label: item.name })
    })
    return arr
}

export const getResearchTagsList = async () => {
    let response = await fetch(`${API}/research-taxonomy?fields=id^,name`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }
    })
    let data = await response.json()
    let arr = []
    await data.data.map(item => {
        arr.push({ value: item.id, label: item.name })
    })
    return arr
}

export const getMultiBusinessType = async (path) => {
    let response = await fetch(`${API}/${path}/business-type?fields=*`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }
    })
    let data = await response.json()
    let arr = []

    await data.data.map(item => {
        if (!isObjectEmpty(item.business_type_data))
            arr.push({ id: item.id, name: item.business_type_data.name })
    })

    return arr
}
export const getStartupTechnologies = async (path) => {
    let response = await fetch(`${API}/${path}/technology?fields=id^,technology_data`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }
    })
    let data = await response.json()
    let arr = []

    await data.data.map(item => {
        if (!isObjectEmpty(item.technology_data))
            arr.push({ id: item.id, name: item.technology_data.name, tech_id: item.technology_data.id })
    })

    return arr
}
export const getMultiBranch = async (path) => {
    let response = await fetch(`${API}/${path}/branch?fields=*`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }
    })
    let data = await response.json()
    let arr = []

    await data.data.map(item => {
        if (!isObjectEmpty(item.location_data))
            arr.push({ id: item.id, name: item.location_data.name })
    })

    return arr
}

export const getMultiIndustry = async (path, fields = ['id', 'industry_data']) => {
    const fieldParams = prepareSelectParam(fields)

    let response = await fetch(`${API}/${path}/industry?fields=${fieldParams}&limit=200`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }
    })
    let data = await response.json()
    let arr = []

    await data.data.map(item => {
        if (!isObjectEmpty(item.industry_data))
            arr.push({ id: item.id, name: item.industry_data.name, is_primary: item.is_primary, industry_data: item.industry_data, is_sub_industry: item.is_sub_industry })
    })
    return arr
}

export const getMultiGeoFocus = async (path) => {
    let response = await fetch(`${API}/${path}/geo-focus?fields=id^,location_data&limit=300`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }
    })
    let data = await response.json()
    let arr = []

    await data.data.map(item => {
        if (!isObjectEmpty(item.location_data))
            arr.push({ id: item.id, name: item.location_data.name })
    })

    return arr
}

export const getEntityRelations = async (path) => {
    const maxLimit = 200;

    let response = await fetch(`${API}/${path}/startup-rel?fields=id^,startup_data&limit=${maxLimit}`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }
    })
    let data = await response.json()
    let arr = []

    await data.data.map(item => {
        if (!isObjectEmpty(item.startup_data))
            arr.push({ id: item.id, name: item.startup_data.name })
    })

    return arr
}

export const getDevelopmentStage = async (path) => {
    let response = await fetch(`${API}/${path}/dev-stage?fields=id^,development_stage_data`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }
    })
    let data = await response.json()
    let arr = []

    await data.data.map(item => {
        arr.push({ id: item.id, name: item.development_stage_data.name })
    })

    return arr
}

export const getNewsMention = async (path) => {
    let response = await fetch(`${API}/${path}/news-mention?fields=id^,entity_id^,entity_type^,news_data^,entity_data`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }
    })
    let data = await response.json()
    let arr = []

    await data.data.map(item => {
        arr.push({
            id: item.id,
            cid: item.entity_id,
            tid: item.entity_type,
            entity_id: item.entity_data.name,
            entity_type: item.entity_data.type,
        })
    })
    store.mentionData = arr
}

export const getPageList = async (path) => {
    let response = await fetch(`${API}/${path}/page`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }
    })
    let data = await response.json()
    let arr = []

    await data.data.map(item => {
        arr.push({ id: item.id, name: item.page })
    })

    return arr
}

export const getTagsNews = async (path) => {
    let response = await fetch(`${API}/${path}/news-tag?fields=*`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }
    })
    let data = await response.json()
    let arr = []

    await data.data.map(item => {
        let name = !isObjectEmpty(item.tag_data) || item.tag_data !== null ? item.tag_data.name : ''
        arr.push({ id: item.id, name: name })
    })

    return arr
}

export const getLocationsNews = async (path) => {
    let response = await fetch(`${API}/${path}/location?fields=*`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }
    })
    let data = await response.json()
    let arr = []

    await data.data.map(item => {
        arr.push({
            id: item.id,
            name: item.location_data.name
        })
    })

    return arr
}

export const getTagsResearch = async (path) => {
    let response = await fetch(`${API}/${path}/tag?fields=*`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }
    })
    let data = await response.json()
    let arr = []

    await data.data.map(item => {

        if (!isObjectEmpty(item.tag))
            arr.push({ id: item.id, name: item.tag.name })
    })

    return arr
}

export const getResearchFiles = async (path) => {
    let response = await fetch(`${API}/${path}/file?fields=research_file_data`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }
    })
    let data = await response.json()
    let arr = []

    await data.data.map(item => {
        if (item.research_file_data && !isObjectEmpty(item.research_file_data)) {
            arr.push({
                id: item.research_file_data.id,
                name: item.research_file_data.name
            })
        }
    })
    return arr
}

export const getProductFiles = async (path) => {
    let response = await fetch(`${API}/${path}/product-file?fields=product_file_data`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }
    })
    let data = await response.json()
    let arr = []

    await data.data.map(item => {
        arr.push({
            id: item.product_file_data.id,
            name: item.product_file_data.name
        })
    })
    return arr
}

export const getRolesTaxonomy = async (strName = '') => {
    const fieldParams = prepareSelectParam(['id', 'name']);

    let filters = "";
    if (strName !== '') {
        filters = prepareFilterParam([
            { key: 'name', value: [strName], op: ALLOWED_QUERY_OPERATOR.substring }
        ]);
    }

    const response = await fetch(`${API}/role?fields=${fieldParams}&filters=${filters}`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }
    })
    const data = await response.json()
    const arr = []

    await data.data.map(item => {
        arr.push({
            value: item.id,
            label: item.name
        })
    })
    return arr
}


export const getTeams = async (path) => {
    const arrFields = ['id', 'user_data', 'email_data', 'role_data', 'team_role_data','is_primary'];
    const fields = prepareSelectParam(arrFields);

    const response = await fetch(`${API}/${path}/team?fields=${fields}`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }
    });

    const data = await response.json();
    let arr = [];
    await data.data.map(item => {
        arr.push({
            id: item.id,
            uid: item.user_data.id,
            rid: item.role_data.id,
            trid: item.team_role_data.id,
            user_id: `${item.user_data.first_name} ${item.user_data.last_name}`,
            role_id: item.role_data.name,
            team_role_id: item.team_role_data.name,
            email: !isObjectEmpty(item.email_data) ? item.email_data.email : '-',
            is_primary: item.is_primary
        });
    });

    store.teamData = arr;
}

export const getPeopleTeams = async (path) => {
    const arrFields = ['id', 'entity_id', 'entity_type', 'entity_data', 'people_id', 'people_data', 'team_role_id', 'team_role_data','is_primary_contact','is_founder'];
    const fields = prepareSelectParam(arrFields);

    const response = await fetch(`${API}/${path}/people-team?fields=${fields}`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }
    });

    const data = await response.json();
    let arr = [];
    await data.data.map(item => {
        arr.push({
            id: item.id,
            pid: item.people_data.id,
            trid: item.team_role_data.id,
            people_id: `${item.people_data.first_name} ${item.people_data.last_name}`,
            team_role_id: item.team_role_data.name,
            email: !isObjectEmpty(item.people_data) ? item.people_data.email : '-',
            is_primary_contact: item.is_primary_contact,
            is_founder: item.is_founder
        });
    });

    store.peopleTeamData = arr;
}

export const getPeopleData = async (path) => {
    let paramFields = ['id', 'first_name', 'last_name', 'code', 'email', 'type', 'profile_pic', 'profile_pic_data', 'linkedin', 'facebook', 'twitter', 'skype', 'phone', 'occupation_description', 'experience', 'current_job_title', 'sort', 'country_id', 'country_data', 'nationality_id', 'nationality_data', 'gender', 'status']
    let fields = prepareSelectParam(paramFields)

    const response = await fetch(`${API}/${path}?fields=${fields}`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        },
    })

    return response
}

export const getUsers = async (value, params) => {
    let filterParams    = ""
    let userParamFields = ['id', 'first_name', 'last_name', 'email_data']

    if (value && value.length >= MIN_SEARCH_LENGTH) {
        const filterFields = [
            { 'key': 'keyword', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [value] },
            { 'key': 'status', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [USER_STATUS_OBJ.invited, USER_STATUS_OBJ.active, USER_STATUS_OBJ.unVerified, USER_STATUS_OBJ.suspended] },
        ]
        filterParams = prepareFilterParam(filterFields)
    }

    if (params && params.length > 0) {
        userParamFields = [...new Set([...userParamFields ,...params])]
    }

    const userParams = prepareSelectParam(userParamFields)

    const response = await fetch(`${API}/user/?fields=${userParams}&filters=${filterParams}`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }
    })

    const data  = await response.json()
    const arr   = []

    await data.data.map(item => {
        const userEmail = extPrimaryEmail(item.email_data);

        arr.push({
            value: item.id,
            label: `${item.first_name} ${item.last_name} | ${userEmail}`
        });
    });

    return arr
}

/**
 * This method will search users based on email fitlers
 * @todo: This can be merged with users API where fitlers can be dynamic
 * @param {String} filterValue  Email value for which users will be searched
 * @returns {Array}             List of users bases of given filters
 */
export const getUserEmails = async (filterValue = '') => {
    const fields        = ['id', 'first_name', 'last_name', 'email_data'];
    const filters       = [{ 'key': 'email', 'op': ALLOWED_QUERY_OPERATOR.substring, 'value': [filterValue] }];
    const fieldParams   = prepareSelectParam(fields);
    const filterParams  = prepareFilterParam(filters);

    const response  = await fetch(`${API}/user?fields=${fieldParams}&filters=${filterParams}`, getHeaderConfig);
    const data      = await response.json();
    const arr       = [];

    await data.data.map(item => {
        const emailArr = [];
        item.email_data.map(item => emailArr.push(item.email));
        const emailString = emailArr.join(', ');

        arr.push({ value: item.id, label: `${item.first_name} ${item.last_name} | ${emailString}` })
    });

    return arr;
}

/**
 * This method will search entities based on their name
 * @param {String} entityName   Name value for entity
 * @returns {Array}             List of entities bases of given filters
 */
export const getEntityNamesSuggestions = async (entityName = '') => {
    const response = await fetch(`${API}/search/entity-names?keyword=${entityName}`, {
        method: 'GET',
        headers: {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        },
    });

    const data = await response.json();
    let arr = []

    await data.data.map(item => {
        arr.push({ value: item.name, label: `${item.name} | ${item.type}` })
    })

    return arr
}
