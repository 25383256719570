import React from 'react'
import { hydrate, render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { CKEDITOR_API_PATH, CKEDITOR_API } from './constant'
import App from './components/App'

import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/styles/index.scss'
import 'bootstrap/js/dist/modal'
import 'bootstrap/js/dist/tab'
import 'bootstrap/js/dist/util'
import CKEditor from 'ckeditor4-react'

window.CKEDITOR_BASEPATH = CKEDITOR_API_PATH;
CKEditor.editorUrl = CKEDITOR_API;

const rootElement = document && document.getElementById("root");

if (rootElement !== null) {
    if (rootElement.hasChildNodes()) {
        hydrate(
            <BrowserRouter>
                <App />
            </BrowserRouter>,
            document.getElementById('app')
        )
    }
} else {
    render(
        <BrowserRouter>
            <App />
        </BrowserRouter>,
        document.getElementById('app')
    )
}
