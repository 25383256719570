
import React, { PureComponent } from 'react';
import resourceApi from '../../apis/resourceApi';
import store from '../../store';

class LogModalActionRenderer extends PureComponent {
    showModal = async () => {
        $('#requestLogModal').modal({
            backdrop: 'static',
            keyboard: true,
            show: true
        });

        const productData = this.props.data.product_data;

        // pass the request data to the popup
        store.attributes = await this.getAttributes(productData.resource);
        store.requestLogModalData = this.props.data;
    }

    getAttributes = async (resource = '') => {
        let resourceCode = resource.toLowerCase();

        const resourceName = `${resourceCode}-get`;

        let attributes = [];
        await resourceApi.getResourceIdByCode(resourceCode, resourceName).then(
            response => {
                attributes = response.attributes[resourceCode];
            }
        );

        return attributes;
    }

    render() {
        return (
            <a tabIndex="1" className="btn-edit" onClick={this.showModal}>View details</a>
        )
    }
}

export default LogModalActionRenderer;
