import axios from 'axios';

import { API } from '../constant';
import store from '../store';

const exportRequestApi = {

    patchRequestData: (requestId, payload) => {
        let url = `${API}/export-request/${requestId}`
        let headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }
        
        return new Promise((resolve, reject) => {
            axios.patch(url, payload, {headers})
                .then(response => {
                    if (response.data.status === 200) {

                        resolve({
                            record: response.data,
                        })
                    } else {
                        reject({})
                    }
                }).catch(err => reject(err))
        })
    },

}

export default exportRequestApi;