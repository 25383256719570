import axios from 'axios';

import store from '../store';
import { API } from '../constant';
import { prepareSelectParam } from '../utility/queryParams';

const dataExportApi = {

    getPolicyDetails: (policyId) => {
        const paramFields = ['id', 'period', 'policy', 'active', 'role_data', 'records_limit'];
        const fields = prepareSelectParam(paramFields);

        const url = `${API}/data-export-policy/${policyId}/?fields=${fields}`;
        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(
                    response => {
                        if (response.data.status === 200) {
                            resolve(response.data.data);
                        } else {
                            resolve({});
                        }
                    }
                )
                .catch((err) => reject(err));
        });
    },

    // get the data set types products
    getProducts: () => {
        const paramFields = ['id', 'data_set_type', 'resource'];
        const fields = prepareSelectParam(paramFields);

        const url = `${API}/data-export-product?fields=${fields}`;
        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(
                    response => {
                        if (response.data.status === 200) {
                            resolve(response.data.data);
                        } else {
                            resolve([]);
                        }
                    }
                )
                .catch((err) => reject(err));
        });
    },

    getProductDetails: (productId = 0, fields = []) => {
        const url = `${API}/data-export-product/${productId}?fields=${fields}`;
        const headers = {
            Authorization: store.token
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(response => {
                    if (response.data.status === 200) {
                        resolve(response.data.data);
                    } else {
                        reject({});
                    }
                }).catch(err => reject(err))
        })
    },

}

export default dataExportApi;