import axios from 'axios';
import store from '../store';
import { API } from '../constant';

const UndisclosedProxyApi = {

    getUndisclosedProxyDetails: (proxyId, fields) => {
        const url = `${API}/undisclosed-proxy/${proxyId}?fields=${fields}`

        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };
        
        return new Promise((resolve, reject) => {
            axios.get(url, {headers})
                .then(response => {
                    if (response.data.status === 200) {
                        resolve(response.data.data)
                    } else {
                        reject({})
                    }
                }).catch(err => reject(err))
        })
    },

    getUndisclosedProxyCalculation: (filters) => {
        const url = `${API}/undisclosed-proxy/generate?filters=${filters}`

        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };
        
        return new Promise((resolve, reject) => {
            axios.get(url, {headers})
                .then(response => {
                    if (response.data.status === 200) {
                        resolve(response.data.data)
                    } else {
                        reject({})
                    }
                }).catch(err => reject(err))
        })
    },

}

export default UndisclosedProxyApi;