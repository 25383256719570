import axios from 'axios';

import { API } from '../constant';
import store from '../store';

const investorApi = {

    patchInvestorFundingData: (fundingId, fundingBreakdownId, InvFundingId, entityId, entityType, payload) => {
        const url = `${API}/${entityType}/${entityId}/invested/${fundingId}/funding-breakdown/${fundingBreakdownId}/investor-funding/${InvFundingId}`;

        const headers = {
            Authorization: store.token
        };

        return new Promise((resolve, reject) => {
            axios.patch(url, payload, { headers })
                .then(response => {
                    if (response.data.status === 200) {
                        resolve(true);
                    } else {
                        reject({})
                    }
                }).catch(err => reject(err))
        })
    },

}

export default investorApi;