import React from 'react';
import Select from 'react-select';

// constants
import {
    SELECT_STYLES,
    SELECT_STYLES_ERROR,
    SELECT_THEME_PRIMARY,
    PURCHASING_AS_TYPES
} from '../../../constant';

// helpers
import { isObjectEmpty } from '../../../utility/helpers';

function AddressSection(props) {
    return (
        <div className='form-wrapper'>
            <h5>User Address Details</h5>
            <div className='row'>
                <div className="col-md-6 col-sm-6 col-12 form-group">
                    <label >Billing Address<span className='required'>*</span></label>
                    <input type="text"
                        className={props.isFirstAddressLineEmpty ? "form-control input-error" : "form-control"}
                        value={props.address.address_line_1}
                        name='address_line_1'
                        onChange={props.handleAddressChange} />
                    {props.isFirstAddressLineEmpty && <div className='error'>Billing address cannot be empty</div>}
                </div>

                <div className="col-md-6 col-sm-6 col-12 form-group">
                    <label >State</label>
                    <input type="text"
                        className="form-control"
                        value={props.address.state}
                        name='state'
                        onChange={props.handleAddressChange} />
                </div>
            </div>

            <div className='row'>
                <div className="col-md-6 col-sm-6 col-12 form-group">
                    <label >City</label>
                    <input type="text"
                        className="form-control"
                        value={props.address.city}
                        name='city'
                        onChange={props.handleAddressChange} />
                </div>

                <div className="col-md-6 col-sm-6 col-12 form-group">
                    <label >Country<span className='required'>*</span></label>
                    <Select
                        name='country_id'
                        placeholder='Please select a country'
                        styles={props.isCountryEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                        theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: SELECT_THEME_PRIMARY
                            }
                        })}
                        value={props.country_id}
                        onChange={(option) => props.handleAddressSelectChange(option, 'country_id')}
                        options={props.countries}
                    />
                    {props.isCountryEmpty && <div className='error'>Country cannot be empty</div>}
                </div>
            </div>

            <div className='row'>
                <div className="col-md-6 col-sm-6 col-12 form-group">
                    <label >Purchasing As<span className='required'>*</span></label>
                    <Select
                        name='type'
                        placeholder='Please select a type'
                        styles={props.isTypeEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                        theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: SELECT_THEME_PRIMARY
                            }
                        })}
                        value={props.type}
                        onChange={(option) => props.handleAddressSelectChange(option, 'type')}
                        options={PURCHASING_AS_TYPES}
                    />
                    {props.isTypeEmpty && <div className='error'>Type cannot be empty</div>}
                </div>
            </div>

            {!isObjectEmpty(props.type) && props.type.value === 'office' &&
                <>
                    <div className='row'>
                        <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                            <label >Company Name<span className='required'>*</span></label>
                            <input type="text"
                                className={props.isCompanyNameEmpty ? "form-control input-error" : "form-control"}
                                value={props.address.company_name}
                                name='company_name'
                                onChange={props.handleAddressChange} />
                            {props.isCompanyNameEmpty && <div className='error'>Company name cannot be empty</div>}
                        </div>

                        <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                            <label >Job Title<span className='required'>*</span></label>
                            <input type="text"
                                className={props.isJobTitleEmpty ? "form-control input-error" : "form-control"}
                                value={props.address.job_title}
                                name='job_title'
                                onChange={props.handleAddressChange} />
                            {props.isJobTitleEmpty && <div className='error'>Job title name cannot be empty</div>}
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                            <label >Tax Code<span className='required'>*</span></label>
                            <input type="text"
                                className={props.isTaxCodeEmpty ? "form-control input-error" : "form-control"}
                                value={props.address.tax_code}
                                name='tax_code'
                                onChange={props.handleAddressChange} />
                            {props.isTaxCodeEmpty && <div className='error'>Tax code cannot be empty</div>}
                        </div>

                        <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                            <label >Phone Number</label>
                            <input type="text"
                                className="form-control"
                                value={props.address.phone_number}
                                name='phone_number'
                                onChange={props.handleAddressChange} />
                        </div>
                    </div>
                </>
            }

        </div>
    )
}

export default AddressSection;