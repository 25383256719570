
import React, { PureComponent } from 'react';
import store from '../../../store';

class BrandThemeModalActionRenderer extends PureComponent {
    showModal = () => {
        $('#brandThemeModal').modal({
            backdrop: 'static',
            keyboard: true,
            show: true
        })

        // pass the modal data
        store.brandThemeModalData = this.props?.data?.brand_theme;
    }

    render() {
        return (
            <a tabIndex="1" className="btn-edit" onClick={this.showModal}>View Brand Theme</a>
        )
    }
}

export default BrandThemeModalActionRenderer;
