import React from 'react'
import { observer } from 'mobx-react'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import store from '../../store'
import {
    SELECT_STYLES,
    SELECT_STYLES_ERROR,
    SELECT_THEME_PRIMARY,
    API,
    ORDER_TYPES,
    OPTIONS,
    ALLOWED_QUERY_OPERATOR,
    RESEARCH_TYPES
} from '../../constant'
import { prepareSelectParam, prepareFilterParam } from '../../utility/queryParams'
import { getLocations } from '../../utility/commonApis'
import SideNav from '../SideNav'
import Modal from '../reusable/Modal'
import FormParent from '../FormParent'
import Home from '../Home'
import { isObjectEmpty } from '../../utility/helpers'

class PromotionForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                // Static name just for popup
                name: 'Promotion',
                code: '',
                user_max_uses_count: '',
                max_uses_count: '',
                active: '',
                description: '',
                order_type: '',
                promo_type: '',
                country_id: '',
                value: '',
                start_date: '',
                end_date: '',
                rule: {
                    allowed_items: [],
                    not_allowed_items: []
                }
            },
            validationFields: ['code', 'start_date', 'promo_type', 'value'],
            editParams: [],
            locations: [],
            subscriptionsTaxo: [],
            researchTaxo: []
        }

        this.parameters = {
            code: '',
            user_max_uses_count: '',
            max_uses_count: '',
            active: '',
            description: '',
            order_type: '',
            promo_type: '',
            country_id: '',
            value: '',
            start_date: '',
            end_date: '',
            rule: {
                allowed_items: [],
                not_allowed_items: []
            }
        }

        this.promo_types = [
            { value: 1, label: 'Amount' },
            { value: 2, label: 'Percentage' }
        ];
    }

    componentDidMount() {
        if (store.isEdit && store.currentId) {
            const paramFields = ['id', 'code', 'active', 'description', 'order_type', 'rule', 'value', 'promo_type', 'country_data', 'country_id', 'user_max_uses_count', 'max_uses_count', 'start_date', 'end_date']
            const fields = prepareSelectParam(paramFields)

            fetch(`${API}/promotion/${store.currentId}/?fields=${fields}`, {
                method: 'GET',
                headers: {
                    'Authorization': store.token,
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(async data => {
                    let obj = {};

                    await this.getSubscriptionTaxo(data.data.order_type);

                    Object.keys(this.state.formFields).map(async key => {
                        if (key === 'start_date') {
                            obj[key] = data.data.start_date ? new Date(data.data.start_date) : ''
                        } else if (key === 'order_type') {
                            obj[key] = data.data[key] == 1 ? { value: 1, label: 'Subscribe' } : { value: 2, label: 'Reports' };
                        } else if (key === 'promo_type') {
                            obj[key] = data.data[key] == 1 ? { value: 1, label: 'Amount' } : { value: 2, label: 'Percentage' };
                        } else if (key === 'active') {
                            obj[key] = data.data[key] == 1 ? { value: 1, label: 'Yes' } : { value: 0, label: 'No' };
                        } else if (key === 'rule') {
                            obj[key] = this.assignRule(data.data.rule, data.data.order_type);
                        } else if (key === 'country_id') {
                            obj[key] = data.data.country_data !== null ? { 'value': data.data.country_id, 'label': data.data.country_data.name } : ''
                        } else if (key === 'end_date') {
                            obj[key] = data.data.end_date ? new Date(data.data.end_date) : ''
                        } else {
                            obj[key] = data.data[key] ? data.data[key] : ''
                        }
                    });

                    // Static name just for popup message
                    obj.name = "Promotion";
                    this.setState({ formFields: obj });
                })
                .catch(err => {
                    console.log(err)
                });
        }

        getLocations().then(res => {
            this.setState({ locations: res })
        });
    }

    assignRule = (rule, orderType) => {
        const { researchTaxo, subscriptionsTaxo } = { ...this.state };
        const allowed_items = [];
        const not_allowed_items = [];
        let taxonomy;

        if (orderType == 1) {
            taxonomy = subscriptionsTaxo;
        } else if (orderType == 2) {
            taxonomy = researchTaxo;
        }

        if (rule.allowed_items && rule.allowed_items.length > 0) {
            rule.allowed_items.map(item => {
                const index = taxonomy.findIndex(researchItem => researchItem.value == item);
                if (index > -1) {
                    allowed_items.push(taxonomy[index]);
                }
            });
        }

        if (rule.not_allowed_items && rule.not_allowed_items.length > 0) {
            rule.not_allowed_items.map(item => {
                const index = taxonomy.findIndex(researchItem => researchItem.value == item);
                if (index > -1) {
                    not_allowed_items.push(taxonomy[index]);
                }
            });
        }

        return { allowed_items, not_allowed_items };
    }

    getSubscriptionTaxo = async (type) => {
        return new Promise(async (resolve, reject) => {
            try {
                const subscriptionsTaxo = [];
                const researchTaxo = [];
                const stateObj = {};

                const subsFields = prepareSelectParam(['id', 'name', 'duration', 'price']);
                const researchFields = prepareSelectParam(['id', 'name', 'price']);
                const researchFitlers = prepareFilterParam([{ 'key': 'type_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [RESEARCH_TYPES.paid] }]);

                if (type == 1) {
                    let subscriptions = await fetch(`${API}/subscription/?fields=${subsFields}&limit=300`);
                    subscriptions = await subscriptions.json();
                    subscriptions.data.map(item => subscriptionsTaxo.push({ value: item.id, label: item.name, duration: item.duration, price: item.price }));
                    stateObj['subscriptionsTaxo'] = subscriptionsTaxo;
                }

                if (type == 2) {
                    let ressearch = await fetch(`${API}/research/?fields=${researchFields}&filters=${researchFitlers}&limit=300`)
                    ressearch = await ressearch.json();
                    ressearch.data.map(item => researchTaxo.push({ value: item.id, label: item.name, price: item.price }));
                    stateObj['researchTaxo'] = researchTaxo;
                }

                this.setState(stateObj, () => { resolve() });
            } catch (err) {
                console.log(err);
                reject();
            }
        });
    }

    addRule = (option, type) => {
        const { formFields, editParams } = { ...this.state };
        const stateObj = {};

        if (type === "allowedItem") {
            formFields.rule.allowed_items.push(option);

        } else if (type === "notAllowedItem") {
            formFields.rule.not_allowed_items.push(option);
        }

        editParams.push('rule');
        stateObj['formFields'] = formFields;
        stateObj['editParams'] = editParams;
        this.setState(stateObj);
    }

    removeRule = (index, type) => {
        const { formFields, editParams } = { ...this.state };
        const stateObj = {};

        if (type === "allowedItem") {
            formFields.rule.allowed_items.splice(index, 1);

        } else if (type === "notAllowedItem") {
            formFields.rule.not_allowed_items.splice(index, 1);
        }

        editParams.push('rule');
        stateObj['editParams'] = editParams;
        stateObj['formFields'] = formFields;
        this.setState(stateObj);
    }

    handleOrderType = (option) => {
        this.handleSelectChange(option, 'order_type');
        this.getSubscriptionTaxo(option.value);
        this.resetRule();
    }

    resetRule = () => {
        const { formFields } = this.state;
        const stateObj = {};

        const rule = {
            allowed_items: [],
            not_allowed_items: []
        };

        formFields.rule = rule;
        this.parameters.rule = rule;
        stateObj['formFields'] = formFields;

        this.setState(stateObj);
    }

    beforeSave = () => {
        if (this.handleEmptyFieldValidation()) {
            let { formFields } = this.state;
            const stateObj = {};

            // Remove spaces from code
            let code = formFields.code.split(" ").join("");
            code = code.toUpperCase();
            this.parameters.code = code;
            formFields.code = code;

            const allowedItems = [];
            const notAllowedItems = [];

            if (formFields.rule.allowed_items.length > 0) {

                formFields.rule.allowed_items.map(item => {
                    allowedItems.push(item.value)
                });

                this.parameters.rule.allowed_items = allowedItems;
            }

            if (formFields.rule.not_allowed_items.length > 0) {

                formFields.rule.not_allowed_items.map(item => {
                    notAllowedItems.push(item.value)
                });

                this.parameters.rule.not_allowed_items = notAllowedItems;
            }

            stateObj['formFields'] = formFields;

            if (store.isEdit && store.currentId) {
                this.handleUpdate('promotion')
            } else {
                this.handleAdd('promotion', true)
            }
        }
    }

    formatOptionLabel = ({ value, label, price, duration }) => (
        <div>
            <div>{label}</div>
            {(price || duration) && <div style={{ color: "#798289" }}>
                Price: ${price} {duration && `- Duration: ${duration} Month(s)`}
            </div>}
        </div>
    );

    renderErrors = () => {
        let {
            codeEmpty,
            descriptionEmpty,
            start_dateEmpty,
            promo_typeEmpty,
            valueEmpty
        } = this.state

        let errors = []

        if (codeEmpty)
            errors.push(<div className='error'>Promotion code cannot be empty</div>)
        if (descriptionEmpty)
            errors.push(<div className='error'>Description cannot be empty</div>)
        if (start_dateEmpty)
            errors.push(<div className='error'>Start date cannot be empty</div>)
        if (promo_typeEmpty)
            errors.push(<div className='error'>Promo type cannot be empty</div>)
        if (valueEmpty)
            errors.push(<div className='error'>Value cannot be empty</div>)


        return errors
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className="content">
                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className="content-right">
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT PROMOTION</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD PROMOTION</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Promo Code (Must be in CAPS without spaces)<span className='required'>*</span></label>
                                            <input type="text" className={this.state.codeEmpty ? "form-control input-error" : "form-control"} name='code' placeholder='Promo Code' value={this.state.formFields.code} onChange={this.handleInputChange} />
                                            {this.state.codeEmpty && <div className='error'>Promotion code cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Short Description</label>
                                            <textarea rows='8'
                                                className={"form-control"}
                                                name='description'
                                                value={this.state.formFields.description}
                                                onChange={this.handleInputChange} />
                                            {this.state.descriptionEmpty && <div className='error'>Description cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-6 form-group">
                                            <label >Order Type</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.order_type}
                                                onChange={(option) => this.handleOrderType(option)}
                                                options={ORDER_TYPES}
                                            />
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-6 form-group">
                                            <label>Allowed Products</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                onChange={(option) => this.addRule(option, 'allowedItem')}
                                                isDisabled={!this.state.formFields.order_type || isObjectEmpty(this.state.formFields.order_type)}
                                                options={(this.state.formFields.order_type.value == 1) ? this.state.subscriptionsTaxo : this.state.researchTaxo}
                                                formatOptionLabel={this.formatOptionLabel}
                                                controlShouldRenderValue={false}
                                            />
                                            {(this.state.formFields.rule && this.state.formFields.rule.allowed_items.length) > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state.formFields.rule.allowed_items.map((item, i) => {
                                                        return <li key={item.value}>{item.label} <i className='fa fa-times-circle' onClick={() => this.removeRule(i, 'allowedItem')}></i></li>
                                                    })}
                                                </div>
                                            }
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-6 form-group">
                                            <label>Not Allowed Products</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                onChange={(option) => this.addRule(option, 'notAllowedItem')}
                                                isDisabled={!this.state.formFields.order_type || isObjectEmpty(this.state.formFields.order_type)}
                                                options={(this.state.formFields.order_type.value == 1) ? this.state.subscriptionsTaxo : this.state.researchTaxo}
                                                formatOptionLabel={this.formatOptionLabel}
                                                controlShouldRenderValue={false}
                                            />
                                            {(this.state.formFields.rule && this.state.formFields.rule.not_allowed_items.length > 0) &&

                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state.formFields.rule.not_allowed_items.map((item, i) => {
                                                        return <li key={item.value}>{item.label} <i className='fa fa-times-circle' onClick={() => this.removeRule(i, 'notAllowedItem')}></i></li>
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 col-xs-6 form-group">
                                            <label>Per User Uses Allowed</label>
                                            <input type="text" className="form-control" name='user_max_uses_count' placeholder='Max. Per User Count' value={this.state.formFields.user_max_uses_count} onChange={this.handleInputChange} />
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-6 form-group">
                                            <label>Total No. of Uses Allowed</label>
                                            <input type="text" className="form-control" name='max_uses_count' placeholder='Max. Total No. of Uses' value={this.state.formFields.max_uses_count} onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Start date<span className='required'>*</span> </label>
                                            <DatePicker
                                                name='start_date'
                                                placeholderText="Click to select a date"
                                                className='datepicker-form-control'
                                                dateFormat="yyyy/MM/dd"
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                selected={this.state.formFields.start_date}
                                                onChange={(date) => this.handleDateChange(date, 'start_date')} />
                                            {this.state.start_dateEmpty && <div className='error'>Start date cannot be empty</div>}
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >End date</label>
                                            <DatePicker
                                                placeholderText="Click to select a date"
                                                className='datepicker-form-control'
                                                dateFormat="yyyy/MM/dd"
                                                minDate={this.state.formFields.start_date}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                selected={this.state.formFields.end_date}
                                                onChange={(date) => this.handleDateChange(date, 'end_date')} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-6 form-group">
                                            <label >Type of the next field<span className='required'>*</span></label>
                                            <Select
                                                name='promo_type'
                                                placeholder='Please Select'
                                                styles={this.state.promo_typeEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.promo_type}
                                                onChange={(option) => this.handleSelectChange(option, 'promo_type')}
                                                options={this.promo_types}
                                            />
                                            {this.state.promo_typeEmpty && <div className='error'>Promo type cannot be empty</div>}
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-6 form-group">
                                            <label >Amount/Percentage Number<span className='required'>*</span></label>
                                            <input type="text" className={this.state.nameEmpty ? "form-control input-error" : "form-control"} name='value' placeholder="Number of percentage/amount" value={this.state.formFields.value} onChange={this.handleInputChange} />
                                            {this.state.valueEmpty && <div className='error'>Value cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Country</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.country_id}
                                                onChange={(option) => this.handleSelectChange(option, 'country_id')}
                                                options={this.state.locations}
                                            />
                                        </div>





                                        <div className="col-md-6 col-sm-6 col-xs-6 form-group">
                                            <label >Active</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.active}
                                                onChange={(option) => this.handleSelectChange(option, 'active')}
                                                options={OPTIONS}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {this.renderErrors()}

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ? <button className="btn-primary bottom-btn" disabled={this.state.editParams.length > 0 ? false : true} onClick={this.beforeSave}>Save</button> :
                                    <div>
                                        <button className="btn-primary bottom-btn" onClick={this.beforeSave}>Add promotion</button>
                                        <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                                    </div>
                                }
                            </div>

                        </div>
                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}

export default observer(PromotionForm)
