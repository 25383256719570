import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import AsyncSelect from 'react-select/async';

// constants
import { SELECT_STYLES_ERROR, SELECT_STYLES, SELECT_THEME_PRIMARY, VALUE_SEPARATOR, TAXONOMIES } from '../../../constant';
import { FILTERS_OPERATORS, FILTERS_STORES, FILTERS_UI_TYPES } from '../../../utility/constants/dataExport/filters';

// helpers
import store from '../../../store';

// apis
import taxonomyApi from '../../../apis/taxonomyApi';
import academicDegreeApi from '../../../apis/academicDegreeApi';
import searchApi from '../../../apis/searchApi';

class FiltersBuilderModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterField: '',
            filterOperator: '',
            filterValueFrom: '',
            filterValueTo: '',

            valueOptionFrom: '',
            valueOptions: [],

            filterFieldEmpty: false,
            filterOperatorEmpty: false,
            filterValueFromEmpty: false,
            filterValueToEmpty: false
        }
    }

    /**
     * Method to select the filter field
     * @param {Object} option 
     */
    handleFilterFieldChange = async (option = {}) => {
        const storeType = option && option.store;

        // get the field options
        if (storeType) {
            await this.getOptionsData(storeType);
        }

        this.setState({
            filterField: option,
            filterOperator: '',
            filterValueFrom: '',
            filterValueTo: '',
            valueOptionFrom: '',
            filterFieldEmpty: false,
            filterOperatorEmpty: false,
            filterValueFromEmpty: false,
            filterValueToEmpty: false,
            currentFilterType: storeType
        });
    }

    /**
     * Method to get the filter field options
     * @param {String} storeType         Options store type
     */
    getOptionsData = async (storeType = '') => {
        let data = [];

        switch (storeType) {
        case FILTERS_STORES.COUNTRY:
            data = await taxonomyApi.getCountries();
            break;
        case FILTERS_STORES.REGION:
            data = await taxonomyApi.getRegions();
            break;
        case FILTERS_STORES.FUNDING_STAGE:
            data = await taxonomyApi.getTaxonomyByType(TAXONOMIES.fundingStage);
            break;
        case FILTERS_STORES.INDUSTRY:
            data = await taxonomyApi.getIndustries();
            break;
        case FILTERS_STORES.PRIMARY_INDUSTRY:
            data = await taxonomyApi.getIndustries();
            break;
        case FILTERS_STORES.SUB_INDUSTRY:
            data = await taxonomyApi.getSubIndustries();
            break;
        case FILTERS_STORES.SECONDARY_INDUSTRY:
            data = await taxonomyApi.getIndustries();
            break;
        case FILTERS_STORES.INVESTOR_TYPE:
            data = await taxonomyApi.getTaxonomyByType(TAXONOMIES.investorType);
            break;
        case FILTERS_STORES.DEVELOPMENT_STAGE:
            data = await taxonomyApi.getTaxonomyByType(TAXONOMIES.devStage);
            break;
        case FILTERS_STORES.BUSINESS_TYPE:
            data = await taxonomyApi.getTaxonomyByType(TAXONOMIES.businessType);
            break;
        case FILTERS_STORES.INVESTMENT_VEHICLE:
            data = await taxonomyApi.getTaxonomyByType(TAXONOMIES.investmentVehicles);
            break;
        case FILTERS_STORES.GENDER:
            data = [
                { id: 'MALE', name: 'Male' },
                { id: 'FEMALE', name: 'female' },
            ];
            break;
        default:
            break;
        }

        const options = data.map(item => {
            return {
                value: item.id,
                label: item.name
            }
        });

        this.setState({
            filterValueFrom: [],
            filterValueTo: [],
            valueOptionFrom: '',
            valueOptions: options,
            filterValueFromEmpty: false,
            filterValueToEmpty: false
        });
    }

    /**
     * Method to handle dropdown select
     * @param {Object} option 
     * @param {String} fieldName 
     */
    handleSelectChange = (option = {}, fieldName = '') => {
        this.setState({
            [fieldName]: option,
            filterValueFromEmpty: false,
            filterValueToEmpty: false
        });
    }

    /**
     * Method to handle multi dropdown select
     * @param {Object} option 
     */
    handleMultiSelectChange = (option = {}) => {
        let { filterValueFrom } = this.state;

        if (!filterValueFrom) {
            filterValueFrom = [];
        }

        if (filterValueFrom.length > 0) {
            let index = filterValueFrom.findIndex(item => {
                return item.value === option.value
            });

            if (index === -1) {
                filterValueFrom.push(option)
            }
        } else {
            filterValueFrom.push(option);
        }

        this.setState({
            valueOptionFrom: option,
            filterValueFrom
        });
    }

    /**
     * Method to handle multi dropdown remove
     * @param {Number} index 
     */
    handleMultiRemove = (index = 0) => {
        const tempFilterValue = [...this.state.filterValueFrom];

        tempFilterValue.splice(index, 1);

        this.setState({
            filterValueFrom: tempFilterValue
        });
    }

    handleInputChange = (event) => {
        const value = event.target.value;
        const fieldName = event.target.name;

        this.setState({
            [fieldName]: value,
            [`${fieldName}Empty`]: false
        });
    }

    /**
     * Method to handle date change
     * @param {Date} date 
     */
    handleDateChange = (date = new Date(), valueIndex = '') => {
        if (valueIndex == 'from') {
            this.setState({
                filterValueFrom: date,
                filterValueFromEmpty: false
            });
        } else if (valueIndex == 'to') {
            this.setState({
                filterValueTo: date,
                filterValueToEmpty: false
            });
        }
    }

    /**
     * Method to handle checkbox change
     * @param {Number} option 
     */
    handleCheckBoxChange = (option) => {
        this.setState({
            filterValueFrom: option,
            filterValueFromEmpty: false
        });
    }

    handleCloseModal = () => {
        this.handleClearFields();
        $('#filtersBuilderModal').modal('hide');
    }

    handleClearFields = () => {
        this.setState({
            filterField: '',
            filterOperator: '',
            filterValueFrom: '',
            filterValueTo: '',

            valueOptionFrom: '',
            valueOptions: [],

            filterFieldEmpty: false,
            filterOperatorEmpty: false,
            filterValueFromEmpty: false,
            filterValueToEmpty: false
        });
    }

    /**
     * Method to handle check validations
     */
    handleValidation = () => {
        const { filterField, filterOperator, filterValueFrom, filterValueTo } = this.state;
        let filterFieldEmpty = false;
        let filterOperatorEmpty = false;
        let filterValueFromEmpty = false;
        let filterValueToEmpty = false;

        if (!filterField) {
            filterFieldEmpty = true;
        }

        if (!filterOperator) {
            filterOperatorEmpty = true;
        }

        if (!filterValueFrom) {
            filterValueFromEmpty = true;
        }

        if (!filterValueTo) {
            filterValueToEmpty = true;
        }

        this.setState({
            filterFieldEmpty,
            filterOperatorEmpty,
            filterValueFromEmpty,
            filterValueToEmpty
        });

        if (this.checkBtwFilter()) { 
            return filterFieldEmpty || filterOperatorEmpty || filterValueFromEmpty || filterValueToEmpty;
        } else {
            return filterFieldEmpty || filterOperatorEmpty || filterValueFromEmpty;
        }
    }

    checkBtwFilter = () => {
        if ((this.state.filterField.ui_type == FILTERS_UI_TYPES.NUMBER || this.state.filterField.ui_type == FILTERS_UI_TYPES.DATE) && this.state.filterOperator.label == 'Between') {
            return true
        } else {
            return false
        }
    }

    /**
     * Method to add a new filter
     */
    handleAddFilter = () => {
        const { filterField, filterOperator, filterValueFrom, filterValueTo} = this.state;

        if (this.handleValidation()) {
            return;
        }

        // prepare the filter value and label
        let strFilterValue = '';
        let strFilterValueLabel = '';
        let strFilterType = '';
        let preparedFilters = [];

        if (filterField.ui_type == FILTERS_UI_TYPES.TEXT) {
            strFilterValue = filterValueFrom;
            strFilterValueLabel = filterValueFrom;
        } else if (filterField.ui_type == FILTERS_UI_TYPES.NUMBER) {
            if (this.checkBtwFilter()) {
                strFilterValue = `${filterValueFrom}${VALUE_SEPARATOR}${filterValueTo}`;
                strFilterValueLabel = `${filterValueFrom} & ${filterValueTo}`;
            } else {
                strFilterValue = filterValueFrom;
                strFilterValueLabel = filterValueFrom;
            }
        } else if (filterField.ui_type == FILTERS_UI_TYPES.DATE) {
            const fromDate = new Date(filterValueFrom);
            if (this.checkBtwFilter()) {
                const toDate = new Date(filterValueTo);
                strFilterValue = `${fromDate.getFullYear()}-${fromDate.getMonth() + 1}-${fromDate.getDate()}${VALUE_SEPARATOR}${toDate.getFullYear()}-${toDate.getMonth() + 1}-${toDate.getDate()}`;
                strFilterValueLabel = `${fromDate.getFullYear()}-${fromDate.getMonth() + 1}-${fromDate.getDate()} & ${toDate.getFullYear()}-${toDate.getMonth() + 1}-${toDate.getDate()}`
            } else {
                strFilterValue = `${fromDate.getFullYear()}-${fromDate.getMonth() + 1}-${fromDate.getDate()}`;
                strFilterValueLabel = `${fromDate.getFullYear()}-${fromDate.getMonth() + 1}-${fromDate.getDate()}`
            }
        } else if (filterField.ui_type == FILTERS_UI_TYPES.CHECKBOX) {
            strFilterValue = filterValueFrom;
            strFilterValueLabel = filterValueFrom ? 'Yes' : 'No';
        } else if ((filterField.ui_type == FILTERS_UI_TYPES.AUTO_COMPLETE) || (filterField.ui_type == FILTERS_UI_TYPES.DROP_DOWN)) {
            strFilterValue = filterValueFrom.map(item => item.value).join(VALUE_SEPARATOR);
            strFilterValueLabel = filterValueFrom.map(item => item.label).join(', ');
            strFilterType = filterValueFrom.map(item => item.type).join(VALUE_SEPARATOR);
        }

        // prepare the filter which requires additional filter for validation
        if (Array.isArray(filterField.value) && filterField.value.length === 2) {
            preparedFilters = [
                {
                    label: <>{filterField.label} <strong>{filterOperator.label}</strong> {strFilterValueLabel}</>,
                    value: `${filterField.value[0]}${filterOperator.value}${strFilterValue}`,
                    isPrimaryFilterValue: true,
                    secondaryFilterValue: `${filterField.value[1]}${filterOperator.value}${strFilterType}`
                },
                {
                    label: <>Employer Type <strong>{filterOperator.label}</strong> {strFilterType}</>,
                    value: `${filterField.value[1]}${filterOperator.value}${strFilterType}`,
                }
            ]
        } else {
            const strLabel = <>{filterField.label} <strong>{filterOperator.label}</strong> {strFilterValueLabel}</>;
            const strValue = `${filterField.value}${filterOperator.value}${strFilterValue}`;

            preparedFilters = [
                {
                    label: strLabel,
                    value: strValue,
                    isPrimaryFilterValue: true,
                }
            ]
        }

        // send the info back to the form
        this.props.handleAddFilter(preparedFilters);
        this.handleClearFields();
    }

    getAutoCompleteOptionsData = async (onFocus, option = "", callback) => {
        const storeType = this.state.currentFilterType;

        let data = []
        let options = []

        if (option && (option.length < 2 && !onFocus)) {
            return;
        }

        switch (storeType) {
        case FILTERS_STORES.ENTITY:
            data = await searchApi.getEntityNamesSuggestions(option)
            break;
        case FILTERS_STORES.DEGREE:
            data = await academicDegreeApi.getDegreeSuggestions(option);
            break;
        default:
            break;
        }

        options = data.map((item) => {
            return {
                value: item.id,
                label: `${item.name} [${item.id}]`,
                type: item.type || null
            }
        })

        this.setState({
            valueOptions: options,
        });

        if (callback) {
            callback(options);
        }
    }

    render() {
        return (
            <div className="modal fade" id="filtersBuilderModal">
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">Add Filter</h4>
                            <button type="button" className="close" onClick={this.handleCloseModal}>&times;</button>
                        </div>

                        <div className="modal-body">
                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label>Filter Field <span className='required'>*</span></label>
                                    <Select
                                        placeholder='Select filter field'
                                        styles={this.state.filterFieldEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        value={this.state.filterField}
                                        onChange={this.handleFilterFieldChange}
                                        options={store.filterFieldsOptions}
                                    />
                                    {this.state.filterFieldEmpty && <div className='error'>Filter field cannot be empty</div>}
                                </div>
                            </div>

                            {
                                this.state.filterField &&
                                <div className='row'>
                                    <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                        <label>Operator <span className='required'>*</span></label>
                                        <Select
                                            placeholder='Select filter operator'
                                            styles={this.state.filterOperatorEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            value={this.state.filterOperator}
                                            onChange={(option) => this.handleSelectChange(option, 'filterOperator')}
                                            options={FILTERS_OPERATORS}
                                        />
                                        {this.state.filterOperatorEmpty && <div className='error'>Operator cannot be empty</div>}
                                    </div>
                                </div>
                            }

                            {
                                this.state.filterOperator &&
                                <div className='row'>
                                    <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                        {
                                            !this.checkBtwFilter() &&
                                            <label>Value <span className='required'>*</span></label>
                                        }
                                        {
                                            this.checkBtwFilter() &&
                                            <label>From <span className='required'>*</span></label>
                                        }
                                        {
                                            this.state.filterField.ui_type == FILTERS_UI_TYPES.TEXT &&
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="filterValueFrom"
                                                placeholder="Input value"
                                                value={this.state.filterValueFrom}
                                                onChange={this.handleInputChange} />
                                        }
                                        {
                                            this.state.filterField.ui_type == FILTERS_UI_TYPES.NUMBER &&
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="filterValueFrom"
                                                placeholder="Input value"
                                                value={this.state.filterValueFrom}
                                                onChange={this.handleInputChange} />
                                        }
                                        {
                                            this.state.filterField.ui_type == FILTERS_UI_TYPES.DROP_DOWN &&
                                            <>
                                                <Select
                                                    placeholder='Select value'
                                                    styles={this.state.filterFieldEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: SELECT_THEME_PRIMARY
                                                        }
                                                    })}
                                                    value={this.state.valueOptionFrom}
                                                    onChange={(option) => this.handleMultiSelectChange(option, 'from')}
                                                    options={this.state.valueOptions}
                                                />
                                                {this.state.filterValueFrom ?
                                                    this.state.filterValueFrom.length > 0 &&
                                                    <div className='permission-policy-list-wrapper'>
                                                        {this.state.filterValueFrom.map((item, i) => {
                                                            return <li key={i}>{item.label} <i className='fa fa-times-circle' onClick={() => this.handleMultiRemove(i, 'from')}></i></li>
                                                        })}
                                                    </div> : null
                                                }
                                            </>
                                        }
                                        {
								            this.state.filterField.ui_type == FILTERS_UI_TYPES.AUTO_COMPLETE &&
											<>
											    <AsyncSelect
											        name='auto_complete'
											        placeholder='Please type and select from the list'
											        styles={this.state.filterFieldEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
											        theme={theme => ({
											            ...theme,
											            colors: {
											                ...theme.colors,
											                primary: SELECT_THEME_PRIMARY
											            }
											        })}
											        value={this.state.valueOptionFrom}
											        defaultOptions={this.state.valueOptions}
											        isDisabled={false}
											        loadOptions={(value, callback) => this.getAutoCompleteOptionsData(false, value, callback)}
											        onChange={(option) => this.handleMultiSelectChange(option, 'from')}
											        onFocus={() => { this.getAutoCompleteOptionsData(true) }}
											    />
											</>
								        }
								        {
                                            this.state.filterField.ui_type == FILTERS_UI_TYPES.DATE &&
                                            <DatePicker
                                                placeholderText="Click to select a date"
                                                className={this.state.filterValueFromEmpty ? 'datepicker-form-control input-error' : 'datepicker-form-control'}
                                                dateFormat="yyyy/MM/dd"
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                selected={this.state.filterValueFrom}
                                                onChange={(date) => this.handleDateChange(date, 'from')} />
                                        }
                                        {
                                            this.state.filterField.ui_type == FILTERS_UI_TYPES.CHECKBOX &&
                                            <>
                                                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                                    <input className="form-control"
                                                        style={{ width: '4%' }}
                                                        type="checkbox"
                                                        name="filterValueFrom"
                                                        value={this.state.filterValueFrom}
                                                        checked={this.state.filterValueFrom}
                                                        onChange={() => this.handleCheckBoxChange(1)}
                                                    />
                                                    <label style={{ paddingLeft: 10 }}>Yes</label>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                                    <input className="form-control"
                                                        style={{ width: '4%' }}
                                                        type="checkbox"
                                                        name="filterValueFrom"
                                                        value={this.state.filterValueFrom}
                                                        checked={!this.state.filterValueFrom}
                                                        onChange={() => this.handleCheckBoxChange(0)}
                                                    />
                                                    <label style={{ paddingLeft: 10 }}>No</label>
                                                </div>
                                            </>
                                        }
                                        {this.state.filterValueFromEmpty && <div className='error'>Value cannot be empty</div>}
                                    </div>
                                </div>
                            }

                            {
                                this.state.filterOperator && this.checkBtwFilter() &&
                                <div className='row'>
                                    <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                        <label>To <span className='required'>*</span></label>
                                        {
                                            this.state.filterField.ui_type == FILTERS_UI_TYPES.NUMBER &&
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="filterValueTo"
                                                placeholder="Input value"
                                                value={this.state.filterValueTo}
                                                onChange={this.handleInputChange} />
                                        }
                                        {
                                            this.state.filterField.ui_type == FILTERS_UI_TYPES.DATE &&
                                            <DatePicker
                                                placeholderText="Click to select a date"
                                                className={this.state.filterValueToEmpty ? 'datepicker-form-control input-error' : 'datepicker-form-control'}
                                                dateFormat="yyyy/MM/dd"
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                selected={this.state.filterValueTo}
                                                onChange={(date) => this.handleDateChange(date, 'to')} />
                                        }
                                        {this.state.filterValueToEmpty && <div className='error'>Value cannot be empty</div>}
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleCloseModal}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={this.handleAddFilter}>Add Filter</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default observer(FiltersBuilderModal);
