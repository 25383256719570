import React from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';

// constants
import { SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY, TAXONOMIES } from '../../constant';
import { API_PATH } from '../../utility/constants/apiPath';

// helpers
import store from '../../store';
import { isObjectEmpty } from '../../utility/helpers';

// apis
import newsApi from '../../apis/newsApi';
import taxonomyApi from '../../apis/taxonomyApi';

// components
import FormParent from '../FormParent';
import Home from '../Home';
import SideNav from '../SideNav';
import Modal from '../reusable/Modal';

class NewsUTMLinkForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                description: '',
                category_id: '',
                utm_link: ''
            },
            validationFields: ['category_id', 'utm_link'],
            editParams: [],
            categories: []
        }

        this.parameters = {
            description: '',
            category_id: '',
            utm_link: ''
        }
    }

    componentDidMount() {
        if (store.isEdit && store.currentId) {
            newsApi.getNewsUTMDetails(store.currentId).then(
                response => {
                    let tempObj = {};

                    Object.keys(this.state.formFields).map(key => {
                        if (key === 'category_id') {
                            tempObj[key] = !isObjectEmpty(response.category_data) ? { value: response.category_data.id, label: response.category_data.name } : '';
                        } else {
                            tempObj[key] = response[key] !== null ? response[key] : '';
                        }
                    });

                    this.setState({ formFields: tempObj });
                }
            );
        }

        taxonomyApi.getTaxonomyByType(TAXONOMIES.newsCategory).then(
            response => {
                if (response.length > 0) {
                    let arrTemp = [];
                    response.map(category => arrTemp.push({ value: category.id, label: category.name }));

                    this.setState({
                        categories: arrTemp
                    });
                }
            }).catch(error => {
            // handle error
        });
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className="content">
                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className="content-right">
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT NEWS UTM LINK</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD NEWS UTM LINK</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Description</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="description"
                                                value={this.state.formFields.description}
                                                onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Category <span className='required'>*</span></label>
                                            <Select
                                                name='category_id'
                                                placeholder='Please Select'
                                                styles={this.state.category_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                options={this.state.categories}
                                                value={this.state.formFields.category_id}
                                                onChange={(option) => this.handleSelectChange(option, 'category_id')}
                                            />
                                            {this.state.category_idEmpty && <div className='error'>Category cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >UTM Link <span className='required'>*</span></label>
                                            <input
                                                className={this.state.utm_linkEmpty ? "form-control input-error" : "form-control"}
                                                type="text"
                                                name="utm_link"
                                                value={this.state.formFields.utm_link}
                                                onChange={this.handleInputChange} />
                                            {this.state.utm_linkEmpty && <div className='error'>UTM Link cannot be empty</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ? <button className="btn-primary bottom-btn" disabled={this.state.editParams.length > 0 ? false : true} onClick={() => this.handleUpdate(API_PATH.newsUTM)}>Save</button> :
                                    <div>
                                        <button className="btn-primary bottom-btn" onClick={() => this.handleAdd(API_PATH.newsUTM)}>Add UTM</button>
                                        <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                                    </div>
                                }
                            </div>

                        </div>
                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}

export default observer(NewsUTMLinkForm)
