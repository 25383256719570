import React from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react'
import { SUB_POLICY_COLUMN_HEADERS, POLICY_PERIODS, SELECT_STYLES, SELECT_THEME_PRIMARY, SELECT_STYLES_ERROR } from '../../constant'
import Select from 'react-select'
import store from '../../store'
import FormParent from '../FormParent'
import {isObjectEmpty} from '../../utility/helpers'


function SubActionRenderes(props) {
    return (
        <a href="#" onClick={() => props.context.componentParent.handleRemovePolicy(props.data)}>Remove</a>
    )
}

class PolicyTable extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: SUB_POLICY_COLUMN_HEADERS,
            context: { componentParent: this },
            frameworkComponents: { actionRenderer: SubActionRenderes },
            defaultColDef: { suppressMovable: true },
            isReqFieldError: false,
            policies: [],
            modalFields: {
                limit: '',
                period: '',
                weight: '',
                category: ''
            },
        }
    }

    onGridReady = (params) => {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit();
    }

    changeInput = (event, fieldName = null) => {
        let value, name;

        if (event.target) {
            value   = event.target.value;
            name    = event.target.name;
        } else if (event.label) {
            value   = event;        // Which is an option object in case of select
            name    = fieldName;
        }

        const modalFields       = { ...this.state.modalFields };
        modalFields[name]       = value;
        this.setState({modalFields});
    }

    handleRemovePolicy = (data) => {
        const index = this.props.data.findIndex(item => item.uniqueId == data.uniqueId);

        if (index > -1) {
            const clonedData = [...this.props.data];
            clonedData.splice(index, 1);
            this.props.onPolicyRemove(clonedData);
        }
    }

    handleAddPolicy = () => {
        const { modalFields} = this.state;
        const tempStateObj  = {};
        const tempObj       = {};

        if (isObjectEmpty(modalFields.category) || isObjectEmpty(modalFields.period) || !modalFields.limit) { 
            return this.setState({ isReqFieldError: true });
        }

        // Labels for category and periods has been added here
        // It is for the view purpose in the table
        tempStateObj.category   = '';
        tempObj.category_id     = modalFields.category.value;
        tempObj.categoryLabel   = modalFields.category.label;

        tempStateObj.limit  = '';
        tempObj.limit       = modalFields.limit;

        tempStateObj.period = '';
        tempObj.period      = modalFields.period.value;
        tempObj.periodLabel = modalFields.period.label;

        
        if (modalFields.weight) {
            tempStateObj.weight = '';
            tempObj.weight = modalFields.weight;
        }
        
        tempObj.uniqueId    = new Date().getTime();
        const rowData       = [tempObj, ...this.props.data];

        this.gridApi.setRowData(rowData);
        this.props.onSuccess(tempObj);
        this.setState({ modalFields: tempStateObj, isReqFieldError: false });
        $('#policyModal').modal('hide');
    }

    showModal = () => {
        $('#policyModal').modal({
            backdrop: 'static',
            keyboard: true,
            show: true
        });
    }

    renderModal = () => {
        const { isReqFieldError, modalFields } = this.state;
        return (
            <div className="modal fade" id="policyModal" >
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            {/**TODO: When edit route is enabled, flag should be passed from the parent component to toggle the title */}
                            <h4 className="modal-title">{'ADD POLICY'}</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Category <span className="required">*</span></label>
                                    <Select
                                        placeholder='Please Select'
                                        styles={(isReqFieldError && !modalFields.category) ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        value={this.state.modalFields.category}
                                        onChange={option => this.changeInput(option, 'category')}
                                        options={this.props.researchCategories}
                                    />
                                </div>

                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Period <span className="required">*</span></label>
                                    <Select
                                        placeholder='Please Select'
                                        styles={(isReqFieldError && !modalFields.period) ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        value={modalFields.period}
                                        onChange={option => this.changeInput(option, 'period')}
                                        options={POLICY_PERIODS}
                                    />
                                </div>

                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Limit <span className="required">*</span></label>
                                    <input
                                        type="number"
                                        className={(isReqFieldError && !modalFields.limit) ? "form-control input-error" : "form-control"}
                                        name='limit'
                                        value={modalFields.limit}
                                        onChange={this.changeInput} />
                                </div>

                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Weight</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        name='weight'
                                        value={this.state.modalFields.weight}
                                        onChange={this.changeInput} />
                                </div>

                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={this.handleAddPolicy}> {store.isFundingEdit ? 'Update' : 'Add'}</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div>
                <div className='form-container' style={{ marginTop: 10 }}>
                    <div className='form-wrapper'>
                        <h5>Policy Details</h5>
                        <div className='funding-addinvestor-btn'>
                            <button className="btn-primary bottom-btn fas fa-plus-circle"
                                data-backdrop="static"
                                data-keyboard="false"
                                onClick={this.showModal}>Add Policy</button>
                        </div>

                        <div id='grid' className='ag-theme-balham' style={{ width: '100%', color: '#5d656d' }}>
                            <AgGridReact
                                reactNext={true}
                                defaultColDef={this.state.defaultColDef}
                                columnDefs={this.state.columnDefs}
                                rowData={this.props.data}
                                domLayout='autoHeight'
                                context={this.state.context}
                                frameworkComponents={this.state.frameworkComponents}
                                onGridReady={this.onGridReady}
                                enableCellTextSelection={true}
                            />
                        </div>

                    </div>
                </div>

                {this.renderModal()}
            </div>
        )
    }
}

export default observer(PolicyTable)