import React, { Component } from 'react'
import { observer } from 'mobx-react'

import store from '../../store'
import { isObjectEmpty } from '../../utility/helpers';
import { REQUIRED_FIELD_ERROR_MSG } from '../../constant';

class CommentModal extends Component {
    constructor() {
        super();
        this.state = {
            comment: ''
        };
    }

    handleCloseModal = () => {
        $('#commentModal').modal('hide')
    }

    saveComment = () => {
        if (store.commentModalData.comments == '') {
            this.setState({error: true})
            return
        }

        let payload = { comment: store.commentModalData.comments}
        let requestId = store.commentModalData.id

        this.props.handleSaveComment(requestId, payload)
    }

    render() {
        return (
            <div className="modal fade" id="commentModal">
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">Export Request</h4>
                            <button type="button" className="close" onClick={this.handleCloseModal}>&times;</button>
                        </div>

                        <div className="modal-body">
                            {this.state.error && <div className="alert alert-danger">{REQUIRED_FIELD_ERROR_MSG}</div>}
                            <div className="form-group">
                                <label style={{ textAlign: "left", display: "block" }}>Comment<span className="required">*</span></label>
                                <input type="text" name="comment" className="form-control" value={!isObjectEmpty(store.commentModalData) ? store.commentModalData.comments : ''} onChange={(event) => store.commentModalData.comments = event.target.value} />
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleCloseModal}>Close</button>
                            <button type="button" className="btn btn-danger" onClick={this.saveComment}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default observer(CommentModal)
