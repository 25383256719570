
import React, { PureComponent } from 'react'
import store from '../../store'

class CommentModalActionRenderer extends PureComponent {
    showModal = () => {
        store.commentModalData = this.props.data

        $('#commentModal').modal({
            backdrop: 'static',
            keyboard: true,
            show: true
        })
    }

    render() {
        return (
            <a tabIndex="1" className="btn-edit" onClick={this.showModal}>Edit Comment</a>
        )
    }
}

export default CommentModalActionRenderer
