import axios from 'axios';

import store from '../store';
import { ALLOWED_QUERY_OPERATOR, API } from '../constant';
import { prepareFilterParam, prepareSelectParam } from '../utility/queryParams';

const technologyApi = {
    getTechnologyList: () => {
        const fields = prepareSelectParam(['id', 'name']);
        const url = `${API}/technology?fields=${fields}`;
        const headers = {
            Authorization: store.token
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(response => {
                    resolve(response.data.data);
                }).catch(err =>
                    reject(err)
                );
        });
    },

    getTechnologyDetails: (id = 0, fields = []) => {
        const url = `${API}/technology/${id}?fields=${fields}`;
        const headers = {
            Authorization: store.token
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(response => {
                    if (response.data.status === 200) {
                        resolve(response.data.data);
                    } else {
                        reject({});
                    }
                }).catch(err => reject(err))
        })
    },
}

export default technologyApi;
