import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

// constants
import { DATA_EXPORT_LOG_COLUMN_HEADERS, ALLOWED_QUERY_OPERATOR, SELECT_STYLES, SELECT_THEME_PRIMARY } from '../../constant';
import { API_PATH } from '../../utility/constants/apiPath';

// helpers
import store from '../../store';
import { prepareSelectParam } from '../../utility/queryParams';

// apis
import { getUsers } from '../../utility/commonApis';
import dataExportApi from '../../apis/dataExportApi';

// components
import Parent from '../Parent';
import SideNav from '../SideNav';
import Home from '../Home';
import RequestLogModal from './RequestLogModal';
import LogModalActionRenderer from './LogModalActionRenderer';
import ActionRenderer from '../reusable/ActionRenderer';
import Modal from '../reusable/Modal';
import Sort from '../reusable/Sort';
import Pagination from '../reusable/Pagination';

class DataExportLogs extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: DATA_EXPORT_LOG_COLUMN_HEADERS,
            context: { componentParent: this },
            frameworkComponents: { 
                actionRenderer: ActionRenderer,
                requestLogModalRenderer: LogModalActionRenderer
            },
            defaultColDef: { suppressMovable: true },

            // filters
            data_set_type: '',
            user_id: [],
            filters: {
                user_id: [],
                data_set_type: []
            },
            filterFields: [
                { 'key': 'user_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'data_set_type', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
            ],
            //stores
            dataSetTypes: [],
            users: [],
        }
        store.sortType = this.props.location.pathname.split('/')[1];
    }

    onGridReady(params) {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    async componentDidMount() {

        const paramFields = ['id', 'user_data', 'order_id', 'order_data', 'data_set_type', 'fields', 'filters', 'sort', 'number_of_records', 'is_custom', 'request_date', 'product_data'];
        store.fields = prepareSelectParam(paramFields);

        this.fetchListData(API_PATH.dataExportLog, store.fields);

        await this.getDataExportProducts();
        await this.getUsersList();
    }

    getDataExportProducts = async () => {
        dataExportApi.getProducts().then(
            response => {
                const dataSetTypes = response.map(type => {
                    return { value: type.data_set_type, label: type.data_set_type };
                })

                this.setState({ dataSetTypes });
            }
        );
    }

    getUsersList = async (option = '') => {
        const users = await getUsers(option);

        this.setState({ users });

        return users;
    }

    handleClearFilters = () => {
        this.setState({
            data_set_type: '',
            user_id: ''
        }, () => {
            this.handleClearAllFilters(API_PATH.dataExportLog);
        });
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>Data Export Logs</h3>
                            <div className='filter-container'>


                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing'  >

                                        <div className="col-md-6 col-sm-6 col-xs-12 filter-spacing" style={{ padding: 0 }}>
                                            <AsyncSelect
                                                placeholder='Filter by user'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.user_id}
                                                defaultOptions={this.state.users}
                                                loadOptions={(option) => this.getUsersList(option)}
                                                onChange={(option) => this.handleSingleSelectFilter(option, 'user_id')}
                                            />
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                            <Select
                                                placeholder='Filter by data set type'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.data_set_type}
                                                onChange={(option) => this.handleSingleSelectFilter(option, 'data_set_type')}
                                                options={this.state.dataSetTypes}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-6 filter-searchbtn" onClick={this.handleClearFilters}>Clear All</a>
                                    </div>
                                </div>

                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    defaultColDef={this.state.defaultColDef}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    frameworkComponents={this.state.frameworkComponents}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                />
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />
                        <RequestLogModal />
                    </div>
                }
            </div>
        )
    }
}


export default observer(DataExportLogs)
