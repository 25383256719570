import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import { SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY, OPTIONS, API, LOCATIONS, PUBLISHED, TAXONOMIES, ENTITY_TYPES, CURRENCIES, JOB_PERIODS } from '../../constant'
import { getTaxonomyData, getLocations, getDepartments } from '../../utility/commonApis'
import { prepareSelectParam } from '../../utility/queryParams'
import store from '../../store'
import SideNav from '../SideNav'
import Modal from '../reusable/Modal'
import FormParent from '../FormParent'
import Home from '../Home'
import jobApi from '../../apis/jobApi'

class JobForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                entity_type: '',
                entity_id: '',
                title: '',
                description: '',
                requirement: '',
                primary_role_id: '',
                type_id: '',
                location_id: '',
                department_id: '',
                remote: '',
                status: '',
                is_featured: '',
                comp_min: '',
                comp_max: '',
                comp_currency: '',
                comp_other: '',
                comp_period: '',
                email_notification: '',
                start_date: '',
                deadline: '',
            },
            types: [],
            locations: [],
            departments: [],
            companies: [],
            roles: [],
            editParams: [],
            isCompOther: false,
            validationFields: ['entity_type', 'entity_id', 'title', 'description', 'requirement', 'primary_role_id', 'type_id', 'department_id']
        }
        this.parameters = {
            entity_type: '',
            entity_id: '',
            title: '',
            description: '',
            requirement: '',
            primary_role_id: '',
            type_id: '',
            location_id: '',
            department_id: '',
            remote: '',
            status: '',
            // compensation: '',
            comp_min: '',
            comp_max: '',
            comp_currency: '',
            comp_period: '',
            comp_other: '',
            email_notification: '',
            start_dte: '',
            deadline: ''
        }
    }

    componentDidMount() {
        if (store.isEdit && store.currentId) {
            let paramFields = ['entity_id', 'entity_type', 'entity_data', 'title', 'description', 'requirement', 'primary_role_data', 'location_data', 'department_data', 'type_id', 'type_data', 'remote', 'comp_max', 'comp_min', 'comp_currency', 'comp_other', 'comp_period', 'email_notification', 'start_date', 'deadline', 'status', 'is_featured']
            let fields = prepareSelectParam(paramFields)

            fetch(`${API}/job/${store.currentId}/?fields=${fields}`, {
                method: 'GET',
                headers: {
                    'Authorization': store.token,
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    let obj = {}
                    Object.keys(this.state.formFields).map(key => {
                        if (key === 'entity_type') {
                            let option = data.data.entity_type !== null ? { 'value': data.data.entity_type, 'label': data.data.entity_type } : ''
                            obj[key] = data.data.entity_type !== null ? { 'value': data.data.entity_type, 'label': data.data.entity_type } : ''
                            this.handleSelectEntityType(option, 'entity_type')
                        }
                        else if (key === 'entity_id') {
                            obj[key] = data.data.entity_data !== null ? { 'value': data.data.entity_data.id, 'label': data.data.entity_data.name } : ''
                        }
                        else if (key === 'primary_role_id') {
                            obj[key] = data.data.primary_role_data !== null ? { 'value': data.data.primary_role_id, 'label': data.data.primary_role_data.name } : ''
                        }
                        else if (key === 'type_id') {
                            obj[key] = data.data.type_data !== null ? { 'value': data.data.type_id, 'label': data.data.type_data.name } : ''
                        }
                        else if (key === 'location_id') {
                            obj[key] = data.data.location_data !== null ? { 'value': data.data.location_id, 'label': data.data.location_data.name } : ''
                        }
                        else if (key === 'department_id') {
                            obj[key] = data.data.department_data !== null ? { 'value': data.data.department_id, 'label': data.data.department_data.name } : ''
                        }
                        else if (key === 'remote') {
                            obj[key] = data.data[key] === 1 ? { value: 1, label: 'Yes' } : data.data[key] === 0 ? { value: 0, label: 'No' } : ''
                        }
                        else if (key === 'status') {
                            obj[key] = data.data[key] === 0 ? { value: 0, label: 'Draft' } : data.data[key] === 1 ? { value: 1, label: 'Published' } : data.data[key] === 2 ? { value: 2, label: 'UnPublished' } : ''
                        }
                        else if (key === 'start_date') {
                            obj[key] = data.data.start_date !== null ? new Date(data.data.start_date) : ''
                        }
                        else if (key === 'deadline') {
                            obj[key] = data.data.deadline !== null ? new Date(data.data.deadline) : ''
                        }
                        else if (key === 'comp_currency' && data.data.comp_currency) {
                            obj[key] = { value: data.data.comp_currency, label: data.data.comp_currency }
                        } else if (key === 'is_featured') {
                            obj[key] = data.data[key] === 1 ? { value: 1, label: 'Yes' } : data.data[key] === 0 ? { value: 0, label: 'No' } : ''
                        }
                        else if (key === 'comp_period' && data.data.comp_period) {

                            const tempCompPeriod = JOB_PERIODS.filter(v => v.value == data.data.comp_period)
                            if (tempCompPeriod.length > 0) {
                                obj[key] = tempCompPeriod[0]
                            }
                        }
                        else {
                            obj[key] = data.data[key] !== null ? data.data[key] : ''
                        }
                    })

                    this.shouldCompOtherShow(data.data);
                    this.setState({ formFields: obj })

                })
        }

        getTaxonomyData(TAXONOMIES.jobRole).then(res => {
            this.setState({ roles: res })
        })

        getLocations().then(res => {
            this.setState({ locations: res })
        })

        getDepartments().then(res => {
            this.setState({ departments: res })
        })

        jobApi.getJobType().then(response => {
            let typesList = [];

            if (response && response.length > 0) {
                response.map(type => typesList.push({ value: type.id, label: type.name }))
            }

            this.setState({ types: typesList })
        })
    }

    shouldCompOtherShow = (data) => {
        if (!data.comp_other) return

        if (!data.comp_min && !data.comp_max) {
            this.setState({
                isCompOther: true
            })
        }
    }

    handleCompOther = () => {
        let { formFields, isCompOther, editParams } = this.state
        const compFields                = ['comp_min', 'comp_max', 'comp_currency', 'comp_period']
        const tempObj                   = {}

        // Reset compensation fields except comp_other
        // And on edit, set the them as null so it can be reset from DB as well
        // If their values exist
        compFields.map(item => {
            if (store.isEdit && formFields[item]) {
                editParams.push(item);
                formFields[item]        = null
                this.parameters[item]   = null
            } else {
                formFields[item]        = ''
                this.parameters[item]   = ''
            }
        })

        tempObj['isCompOther']      = !isCompOther
        tempObj['formFields']       = formFields
        tempObj['editParams']       = editParams

        this.setState(tempObj)
    }

    handleChangeEmail = (e) => {
        const emailArray = e.target.value.split(",");

        // Remove duplicates
        const uniqueEmailArray = [...new Set(emailArray)];
        const uniqueEmails = uniqueEmailArray.join(",");
        e.target.value = uniqueEmails;

        this.handleInputChange(e)
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT JOB</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD JOB</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Type <span className='required'>*</span></label>
                                            <Select
                                                name='entity_type'
                                                placeholder='Please select'
                                                styles={this.state.entity_typeEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.entity_type}
                                                onChange={(option) => this.handleSelectEntityType(option, 'entity_type', 'startup')}
                                                options={ENTITY_TYPES}
                                            />
                                            {this.state.entity_typeEmpty && <div className='error'>Type cannot be empty</div>}
                                        </div>
                                    </div>

                                    {this.state.formFields.entity_type && <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Company Name <span className='required'>*</span></label>
                                            <AsyncSelect
                                                name='entity_id'
                                                placeholder='Please Select or type to search'
                                                styles={this.state.entity_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.entity_id}
                                                defaultOptions={this.state.companies}
                                                loadOptions={(value, callback) => this.handleLoadOptions(value, callback, this.state.formFields.entity_type.value)}
                                                onChange={(option) => this.handleSelectChange(option, 'entity_id')}
                                            />
                                            {this.state.entity_idEmpty && <div className='error'>Company name cannot be empty</div>}
                                        </div>
                                    </div>}

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Job Title <span className='required'>*</span></label>
                                            <input type="text"
                                                name='title'
                                                className={this.state.titleEmpty ? "form-control input-error" : "form-control"}
                                                value={this.state.formFields.title}
                                                onChange={this.handleInputChange} />
                                            {this.state.titleEmpty && <div className='error'>Job title cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Job Description <span className='required'>*</span></label>
                                            <textarea rows='8'
                                                className={this.state.descriptionEmpty ? "form-control input-error" : "form-control"}
                                                name='description'
                                                value={this.state.formFields.description}
                                                onChange={this.handleInputChange} />
                                            {this.state.descriptionEmpty && <div className='error'>Description cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Job Requirements <span className='required'>*</span></label>
                                            <textarea rows='8'
                                                name='requirement'
                                                className={this.state.requirementEmpty ? "form-control input-error" : "form-control"}
                                                value={this.state.formFields.requirement}
                                                onChange={this.handleInputChange} />
                                            {this.state.requirementEmpty && <div className='error'>Job requirements cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Primary Role <span className='required'>*</span></label>
                                            <Select
                                                name='primary_role_id'
                                                placeholder='Please Select'
                                                styles={this.state.primary_role_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        //primary25: 'lightgray',
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.primary_role_id}
                                                onChange={(option) => this.handleSelectChange(option, 'primary_role_id')}
                                                options={this.state.roles}
                                            />
                                            {this.state.primary_role_idEmpty && <div className='error'>Primary role cannot be empty</div>}
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Job Type <span className='required'>*</span></label>
                                            <Select
                                                name='type_id'
                                                placeholder='Please Select'
                                                styles={this.state.type_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        //primary25: 'lightgray',
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.type_id}
                                                onChange={(option) => this.handleSelectChange(option, 'type_id')}
                                                options={this.state.types}
                                            />
                                            {this.state.type_idEmpty && <div className='error'>Job type cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Job Department <span className='required'>*</span></label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={this.state.department_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        //primary25: 'lightgray',
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.department_id}
                                                onChange={(option) => this.handleSelectChange(option, 'department_id')}
                                                options={this.state.departments}
                                            />
                                            {this.state.department_idEmpty && <div className='error'>Job department cannot be empty</div>}

                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Job Location</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={this.state.fundraisingEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        //primary25: 'lightgray',
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.location_id}
                                                onChange={(option) => this.handleSelectChange(option, 'location_id')}
                                                options={this.state.locations}
                                            />
                                        </div>


                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Status</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        //primary25: 'lightgray',
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.status}
                                                onChange={(option) => this.handleSelectChange(option, 'status')}
                                                options={PUBLISHED}
                                            />
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Remote</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        //primary25: 'lightgray',
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.remote}
                                                onChange={(option) => this.handleSelectChange(option, 'remote')}
                                                options={OPTIONS}
                                            />
                                        </div>
                                    </div>

                                    {!this.state.isCompOther && <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Compensation From (Min)</label>
                                            <input title='number'
                                                type='number'
                                                name='comp_min'
                                                className="form-control"
                                                value={this.state.formFields.comp_min}
                                                onChange={this.handleInputChange} />
                                        </div>

                                        <div className="col-md-5 col-sm-5 col-xs-12 form-group">
                                            <label >Compensation To (Max)</label>
                                            <input title='number'
                                                type='number'
                                                name='comp_max'
                                                className="form-control"
                                                value={this.state.formFields.comp_max}
                                                onChange={this.handleInputChange} />
                                        </div>

                                        <div className="col-md-1 col-sm-1 col-xs-12" style={{ marginTop: 35, padding: 0 }}>

                                            <span style={{ marginRight: 10 }}><strong>OR</strong></span>

                                            <input title='checkbox'
                                                name='comp_min'
                                                type="checkbox"
                                                checked={this.state.isCompOther}
                                                onChange={this.handleCompOther} />
                                            <span><strong>Other</strong></span>
                                        </div>
                                    </div>}

                                    {!this.state.isCompOther && <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Select Compensation Currency </label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.comp_currency}
                                                onChange={(option) => this.handleSelectChange(option, 'comp_currency')}
                                                options={CURRENCIES}
                                            />
                                        </div>

                                        <div className="col-md-5 col-sm-5 col-xs-12 form-group">
                                            <label >Select Compensation Period</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.comp_period}
                                                onChange={(option) => this.handleSelectChange(option, 'comp_period')}
                                                options={JOB_PERIODS}
                                            />
                                        </div>
                                    </div>}

                                    {this.state.isCompOther && <div className="row">
                                        <div className="col-md-11 col-sm-11 col-xs-12 form-group">
                                            <label >Compensation Text</label>
                                            <input value={this.state.formFields.comp_other}
                                                className="form-control"
                                                name='comp_other'
                                                onChange={this.handleInputChange} />
                                        </div>


                                        <div className="col-md-1 col-sm-2 col-xs-12" style={{ marginTop: 35, padding: 0 }}>

                                            <span style={{ marginRight: 10 }}><strong>OR</strong></span>

                                            <input title='checkbox'
                                                name='comp_min'
                                                type="checkbox"
                                                checked={this.state.isCompOther}
                                                onChange={() => this.setState({ isCompOther: !this.state.isCompOther })} />
                                            <span><strong>Other</strong></span>
                                        </div>
                                    </div>}

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Start date</label>
                                            <DatePicker
                                                placeholderText="Click to select a date"
                                                className='datepicker-form-control'
                                                dateFormat="yyyy/MM/dd"
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                selected={this.state.formFields.start_date}
                                                onChange={(date) => this.handleDateChange(date, 'start_date')} />
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Deadline</label>
                                            <DatePicker
                                                placeholderText="Click to select a date"
                                                className='datepicker-form-control'
                                                dateFormat="yyyy/MM/dd"
                                                minDate={this.state.formFields.start_date}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                selected={this.state.formFields.deadline}
                                                onChange={(date) => this.handleDateChange(date, 'deadline')} />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Featured</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.is_featured}
                                                onChange={(option) => this.handleSelectChange(option, 'is_featured')}
                                                options={OPTIONS}
                                            />
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Email Notification</label>
                                            <input value={this.state.formFields.email_notification}
                                                className={this.state.email_notificationInvalid ? "form-control input-error" : "form-control"}
                                                name='email_notification'
                                                onChange={this.handleChangeEmail} />
                                            {this.state.email_notificationInvalid && <div className='error'>Invalid Email Address or Incorrectly Entered Email</div>}
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ? <button className="btn-primary bottom-btn" disabled={this.state.editParams.length > 0 ? false : true} onClick={() => this.handleUpdate('job')}>Save</button> :
                                    <button className="btn-primary bottom-btn" onClick={() => this.handleAdd('job')}>Add Job</button>}
                                <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                            </div>

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}
export default observer(JobForm)
