import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react'
import Select from 'react-select'
import store from '../../store'
import { NEWS_COLUMN_HEADERS, API, TAXONOMIES, ALLOWED_QUERY_OPERATOR, PUBLISHED, SELECT_STYLES, SELECT_THEME_PRIMARY, FEATURED_OPTIONS } from '../../constant'
import { prepareSelectParam } from '../../utility/queryParams'
import { getTaxonomyData } from '../../utility/commonApis'
import SideNav from '../SideNav'
import ActionRenderer from '../reusable/ActionRenderer'
import Parent from '../Parent'
import Modal from '../reusable/Modal'
import Sort from '../reusable/Sort'
import Pagination from '../reusable/Pagination'
import Home from '../Home'

class Articles extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: NEWS_COLUMN_HEADERS,
            context: { componentParent: this },
            frameworkComponents: { actionRenderer: ActionRenderer },
            defaultColDef: { suppressMovable: true },

            keyword: '',
            showkeywords: false,
            keyword: [],
            category: '',
            is_published: '',
            is_featured: '',
            categorys: [],
            categoryStore: [],
            country: '',
            countrys: [],
            countryStore: [],
            industry: '',
            industrys: [],
            industryStore: [],
            showcategory: false,
            showCountries: false,
            showIndustries: false,
            filters: {
                keyword: [],
                category: [],
                tag: [],
                is_published: [],
                is_featured: []
            },
            filterFields: [
                { 'key': 'keyword', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'category', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'tag', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'is_published', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'is_featured', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
            ]
        }
        store.sortType = this.props.location.pathname.split('/')[1]
    }

    onGridReady(params) {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    componentDidMount() {
        document.addEventListener('mousedown', (e) => {
            if (this.category) { if (!this.category.contains(e.target)) this.setState({ showcategory: false }) }
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showKeywords: false }) }
            if (this.country) { if (!this.country.contains(e.target)) this.setState({ showCountries: false }) }
            if (this.industry) { if (!this.industry.contains(e.target)) this.setState({ showIndustries: false }) }
        })
        let paramFields = ['id', 'title', 'news_category_data', 'author', 'published_at', 'is_published', 'is_featured']
        store.fields = prepareSelectParam(paramFields)

        //TODO: perform token validator
        this.fetchListData('news', store.fields)

        getTaxonomyData(TAXONOMIES.newsCategory).then(res => {
            this.setState({ categorys: res, categoryStore: res })
        })
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => {
            if (this.category) { if (!this.category.contains(e.target)) this.setState({ showcategory: false }) }
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showKeywords: false }) }
            if (this.country) { if (!this.country.contains(e.target)) this.setState({ showCountries: false }) }
            if (this.industry) { if (!this.industry.contains(e.target)) this.setState({ showIndustries: false }) }
        })
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>NEWS</h3>
                            <div className='filter-container'>

                                <div className='filter-row'>
                                    <div ref={node => this.word = node} className="col-md-12 col-sm-12 col-xs-12 filter-firstline filter-spacing" >
                                        <div style={{ width: '100%' }}>
                                            <input ref={node => this.input = node}
                                                className='filter-input input-border'
                                                placeholder="Type and Enter to Search"
                                                value={this.state.keyword}
                                                onFocus={() => this.setState({ showKeywords: true })}
                                                onKeyUp={this.handleKeywordEnter}
                                                onChange={e => this.setState({ keyword: e.target.value })} />
                                            {this.state.showKeywords && this.state.filters.keyword.length > 0 ?
                                                <div className='filter-dropdown' style={{ width: '100%' }}>
                                                    <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                        {this.state.filters.keyword.map((word, i) => {
                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'keyword')}>
                                                                {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div> : null}
                                        </div>
                                        {this.state.keyword.length > 0 &&
                                            <i className="fa fa-times "
                                                style={{ paddingRight: 10 }}
                                                onClick={() => {
                                                    this.input.focus()
                                                    this.setState({ keyword: '' })
                                                }}>
                                            </i>
                                        }
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing'  >

                                        <div className="col-md-12 col-sm-12 col-xs-12" style={{ padding: 0 }}>
                                            <div ref={node => this.category = node} className='filter-firstline' >
                                                <div style={{ width: '100%' }}>
                                                    <input className='filter-input'
                                                        style={{ paddingLeft: 15 }}
                                                        placeholder="By category "
                                                        value={this.state.category}
                                                        onChange={(e) => this.handleInputChange(e.target.value, 'category')}
                                                        onFocus={() => this.setState({ showcategory: true })} />
                                                    {this.state.showcategory && this.state.categorys ?
                                                        <div className='filter-dropdown' >
                                                            <div >
                                                                {this.state.filters.category.length > 0 ?
                                                                    <div className='filter-dropdown-wrapper' >
                                                                        {this.state.filters.category.map((loc, i) => {
                                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'category')}>
                                                                                {loc}&nbsp;<i className="fa fa-times-circle"></i>
                                                                            </div>
                                                                        })}
                                                                    </div> : null}
                                                            </div>
                                                            <ul className='filter-list-container'>
                                                                <div className='filter-list-wrapper' >
                                                                    <div>
                                                                        {this.state.categorys.map((item, j) =>
                                                                            <div key={j}>
                                                                                <li style={{ fontSize: 12, }} onClick={() => this.handleSelectFilter(item, 'category')}>
                                                                                    {item.label}
                                                                                </li><br />
                                                                            </div>)}
                                                                    </div>
                                                                    <div className='filter-stickybtn ' >
                                                                        <div style={{ width: 140 }}>
                                                                            <a tabIndex='0' className='filter-canclebtn' onClick={() => this.handleClearFilter('category')}>Clear</a> &nbsp; | &nbsp;
                                                                            <a tabIndex='0' className='filter-applybtn' onClick={() => { this.setState({ showcategory: false }); this.handleSearchFilters('news') }}>Apply</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ul>
                                                        </div> : null}
                                                </div>
                                                {this.state.showcategory ? <i className="fa fa-chevron-up " style={{ paddingRight: 10 }} onClick={() => this.setState({ showcategory: false })} ></i> :
                                                    <i className="fa fa-chevron-down " style={{ paddingRight: 10 }} onClick={() => this.setState({ showcategory: true })} ></i>}
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="filter-row" style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing'  >

                                        <div className="col-md-6 col-sm-12 col-xs-12" style={{ paddingLeft: 0 }}>
                                            <div style={{ width: '100%' }}>
                                                <Select
                                                    placeholder='Filter by published status'
                                                    styles={SELECT_STYLES}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: SELECT_THEME_PRIMARY
                                                        }
                                                    })}
                                                    value={this.state.is_published}
                                                    onChange={(option) => this.handleSingleSelectFilter(option, 'is_published')}
                                                    options={PUBLISHED}
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ padding: 0 }}>
                                            <Select
                                                placeholder='Filter by featured'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.is_featured}
                                                onChange={(option) => this.handleSingleSelectFilter(option, 'is_featured')}
                                                options={FEATURED_OPTIONS}
                                            />
                                        </div>

                                    </div>
                                </div>

                                
                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing'  >

                                  
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-6 filter-searchbtn" onClick={() => this.handleClearAllFilters('news')}>Clear All</a>
                                        {/*<a tabIndex='2' className="col-md-2 col-sm-2 col-xs-6 filter-searchbtn" onClick={() => this.handleSearchFilters('news')}>Apply</a>*/}
                                    </div>
                                </div>

                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    defaultColDef={this.state.defaultColDef}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    frameworkComponents={this.state.frameworkComponents}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                >
                                </AgGridReact>
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}


export default observer(Articles)
