import React, { Component } from 'react'

import store from '../../store'
import { observer } from 'mobx-react'

import Home from '../Home'
import SideNav from '../SideNav'
import ErrorPopup from '../reusable/ErrorModal'
import userApi from '../../apis/userApi'
import Modal from '../reusable/Modal';

class DeactivateUsers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            usersList: '',
            errorMessage: '',
            errorEmptyList: false,
            processing: false,

        }
        store.sortType = this.props.location.pathname.split('/')[1]
    }

    componentDidMount() {
        store.currentDeleteName = '';
        store.currentAddedName = '';
        store.currentEditName = '';
        store.isAdd = '';
        store.isEdit = '';
    }

    handleDeactivate = async () => {
        this.setState({ processing: true })

        // covert usersList to an array
        const usersListToArray = this.state.usersList.split(',')

        // convert the array of strings to array of numbers
        for (const idIndex in usersListToArray) {
            const userId = Number(usersListToArray[idIndex].replace(/\s/g, ''))
            if (userId) {
                usersListToArray[idIndex] = userId
            } else {
                usersListToArray.splice(idIndex, 1)
            }
        }

        if (usersListToArray.length < 1) {
            this.setState({ errorEmptyList: true,processing: false })
            return
        }

        userApi.deactivateUsers(usersListToArray).then(response => {
            store.numDeactivatedUsers = Number(response.number_of_newly_deactivated_users)
            $('#dailogModal').modal({
                backdrop: 'static',
                keyboard: true,
                show: true
            })
            this.setState({ processing: false })
        }).catch(err => {
            this.setState({ errorMessage: err.response.data.errors[0].message })
            $('#errorModal').modal({
                backdrop: 'static',
                keyboard: true,
                show: true
            })
            this.setState({ processing: false })
        })
    }

    handleChange = (e) => {
        const inputValue = e.target.value;
        // check if input value has a letter or a white space
        if (/[a-z]/i.test(inputValue) || /\s/g.test(inputValue)) {
            return
        }

        this.setState({ usersList: inputValue, errorEmptyList: false })
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>

                            <h3 style={{ textAlign: 'center' }}>Deactivate Users</h3>
                            <div className='w-70 ' style={{ width: '75%', margin: '0 auto', marginTop: '4%' }}>
                                <textarea className="form-control  input-border"
                                    placeholder="Enter I.ds of users separated by commas"
                                    value={this.state.usersList}
                                    onChange={this.handleChange}
                                    rows={5}
                                />
                                {this.state.errorEmptyList && <span className='text-danger' style={{ margin: '0 auto' }}>Please, enter the ids of users you want to deactivate.</span>}
                                <div className='filter-row justify-content-end' style={{ alignItems: 'right', paddingTop: 10 }}>
                                    <a tabIndex='5' className="col-md-2 col-sm-2 col-xs-6 filter-searchbtn mr-0" onClick={this.handleDeactivate}>{this.state.processing ? <span className="spinner-border text-light" style={{ width: '20px', height: '20px' }} role="status" /> : 'Deactivate'}</a>
                                </div>


                                <ErrorPopup errorMessage={this.state.errorMessage} />
                                <Modal />
                            </div>
                        </div>

                    </div>
                }

            </div>
        )
    }
}

export default observer(DeactivateUsers)