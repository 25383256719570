import React from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

// helpers
import store from '../../../store';
import { isObjectEmpty } from '../../../utility/helpers';

// constants
import { SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY, LOCATION_TYPES } from '../../../constant';

// apis
import locationApi from '../../../apis/locationApi';

// components
import FormParent from '../../FormParent';
import Home from '../../Home';
import SideNav from '../../SideNav';
import Modal from '../../reusable/Modal';

class LocationForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            type: '',
            // form fields
            formFields: {
                name: '',
                region: '',
                country: '',
                iso_code: '',
                is_region: 0
            },
            validationFields: [],
            editParams: [],

            // error flags
            isTypeEmpty: false,
            nameEmpty: false,
            regionEmpty: false,
            countryEmpty: false,
            cityEmpty: false,

            // config
            isSubmitting: false,

            // lists
            regionsList: [],
            countriesList: [],
        }

        this.parameters = {
            name: '',
            region: '',
            country: '',
            iso_code: '',
            is_region: 0
        }
    }

    componentDidMount() {
        if (store.isEdit && store.currentId) {
            const fields = ['id', 'name', 'region', 'country', 'iso_code', 'is_region', 'region_data', 'country_data'];

            locationApi.getLocation(store.currentId, fields).then(
                response => {
                    let obj = {};
                    let type = {};

                    Object.keys(this.state.formFields).map(key => {
                        if (key === 'region') {
                            obj[key] = response && !isObjectEmpty(response.region_data) ? { value: response.region_data.id, label: response.region_data.name } : '';
                        } else if (key === 'country') {
                            obj[key] = response && !isObjectEmpty(response.country_data) ? { value: response.country_data.id, label: response.country_data.name } : '';
                        } else {
                            obj[key] = response[key] !== null ? response[key] : '';
                        }
                    })

                    // region
                    if (response && response.is_region === 1) {
                        type = LOCATION_TYPES.find(type => type.value === 0);
                    }

                    // country
                    else if (response && response.country === 0) {
                        type = LOCATION_TYPES.find(type => type.value === 1);
                        this.getRegions();
                    }

                    // city
                    else if (response && response.country != 0) {
                        type = LOCATION_TYPES.find(type => type.value === 2);
                        this.getCountries();
                    }

                    this.setState({ type, formFields: obj });
                }
            );
        }
    }

    getRegions = async () => {
        locationApi.getRegions().then(
            response => {
                let arrRegions = [];
                response.map(region => arrRegions.push({ value: region.id, label: region.name }));

                this.setState({
                    regionsList: arrRegions
                });
            }
        )
    }

    getCountries = async () => {
        locationApi.getCountries().then(
            response => {
                let arrCountries = [];
                response.map(region => arrCountries.push({ value: region.id, label: region.name }));

                this.setState({
                    countriesList: arrCountries
                });
            }
        )
    }

    handleSelectType = async (option) => {
        let arrValidationFields = [];

        // region fields validation
        if (option.value === 0) {
            arrValidationFields = ['name', 'is_region'];
        }

        // country fields validation
        else if (option.value === 1) {
            arrValidationFields = ['name', 'iso_code', 'region'];
            await this.getRegions();
        }

        // city fields validation
        else if (option.value === 2) {
            arrValidationFields = ['name', 'country'];
            await this.getCountries();
        }

        this.setState({ 
            type: option,
            isTypeEmpty: false,
            validationFields: arrValidationFields
        });
    }

    handleRegionChange = (event) => {
        this.handleInputChange(event);

        this.setState(prevState => ({ 
            formFields: {
                ...prevState.formFields,
                is_region: 1
            }
        }));

        this.parameters.is_region = 1;
    }

    handleSubmit = () => {
        this.setState({ isSubmitting: true });

        if (isObjectEmpty(this.state.type)) {
            this.setState({ isSubmitting: false, isTypeEmpty: true });
            return;
        }

        store.route = 'taxonomy/location';

        if (store.isEdit) {
            this.handleUpdate('location');
        } else {
            this.handleAdd('location');
        }
    }
    
    render() {
        const { name, region, country, iso_code } = this.state.formFields;
        const { type, isTypeEmpty, 
            nameEmpty, regionEmpty, iso_codeEmpty, countryEmpty,
            regionsList, countriesList, 
            isSubmitting } = this.state;

        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className="content">
                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className="content-right">
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT LOCATION</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD LOCATION</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Select location type <span className='required'>*</span></label>
                                            <Select
                                                placeholder='Please select a location type'
                                                styles={isTypeEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={type}
                                                onChange={(option) => this.handleSelectType(option)}
                                                options={LOCATION_TYPES}
                                            />
                                            {isTypeEmpty && <div className='error'>Type cannot be empty</div>}
                                        </div>
                                    </div>

                                    {/* Region form  */}
                                    { type && type.value === 0 &&
                                        <div>
                                            <div className='row'>
                                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                                    <label >Region Name <span className='required'>*</span></label>
                                                    <input 
                                                        className={nameEmpty ? "form-control input-error" : "form-control"} 
                                                        type="text" 
                                                        name='name' 
                                                        value={name} 
                                                        onChange={this.handleRegionChange} 
                                                    />
                                                    {nameEmpty && <div className='error'>Region name cannot be empty</div>}
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {/* Country form  */}
                                    { type && type.value === 1 &&
                                        <>
                                            <div>
                                                <div className='row'>
                                                    <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                                        <label >Country Name <span className='required'>*</span></label>
                                                        <input 
                                                            className={nameEmpty ? "form-control input-error" : "form-control"} 
                                                            type="text" 
                                                            name='name' 
                                                            value={name} 
                                                            onChange={this.handleInputChange} 
                                                        />
                                                        {nameEmpty && <div className='error'>Country name cannot be empty</div>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div className='row'>
                                                    <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                                        <label >ISO Code <span className='required'>*</span></label>
                                                        <input 
                                                            className={iso_codeEmpty ? "form-control input-error" : "form-control"} 
                                                            type="text" 
                                                            name='iso_code' 
                                                            value={iso_code} 
                                                            onChange={this.handleInputChange} 
                                                        />
                                                        {iso_codeEmpty && <div className='error'>ISO code cannot be empty</div>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                                    <label>Select Region <span className='required'>*</span></label>
                                                    <Select
                                                        placeholder='Please select a region'
                                                        styles={regionEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                        theme={theme => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary: SELECT_THEME_PRIMARY
                                                            }
                                                        })}
                                                        value={region}
                                                        onChange={(option) => this.handleSelectChange(option, 'region')}
                                                        options={regionsList}
                                                    />
                                                    {regionEmpty && <div className='error'>Region cannot be empty</div>}
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {/* City form  */}
                                    { type && type.value === 2 &&
                                        <>
                                            <div>
                                                <div className='row'>
                                                    <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                                        <label >City Name <span className='required'>*</span></label>
                                                        <input 
                                                            className={nameEmpty ? "form-control input-error" : "form-control"} 
                                                            type="text" 
                                                            name='name' 
                                                            value={name} 
                                                            onChange={this.handleInputChange} 
                                                        />
                                                        {nameEmpty && <div className='error'>City name cannot be empty</div>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                                    <label>Select Country <span className='required'>*</span></label>
                                                    <AsyncSelect
                                                        placeholder='Please Select or Type to search'
                                                        cacheOptions
                                                        styles={countryEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                        theme={theme => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary: SELECT_THEME_PRIMARY
                                                            }
                                                        })}
                                                        defaultOptions={countriesList}
                                                        loadOptions={(value, callback) => this.handleLoadOptions(value, callback, 'location')}
                                                        value={country}
                                                        onChange={option => this.handleSelectChange(option, 'country')}
                                                    />
                                                    {countryEmpty && <div className='error'>Country cannot be empty</div>}
                                                </div>
                                            </div>
                                        </>
                                    }

                                </div>
                            </div>

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ? 
                                    <button className="btn-primary bottom-btn" disabled={isSubmitting || this.state.editParams.length > 0 ? false : true} onClick={this.handleSubmit}>
                                        {isSubmitting && <i className="fas fa-circle-notch fa-spin mr-2"></i>}
                                        Save
                                    </button> :
                                    <div>
                                        <button className="btn-primary bottom-btn" disabled={isSubmitting} onClick={this.handleSubmit}>
                                            {isSubmitting && <i className="fas fa-circle-notch fa-spin mr-2"></i>}
                                            Add Location
                                        </button>
                                        <button className="btn-secondary bottom-btn" disabled={isSubmitting} onClick={this.handleClear}>Clear</button>
                                    </div>
                                }
                            </div>

                        </div>
                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}

export default observer(LocationForm)
