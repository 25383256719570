import React from 'react'
import { observer } from 'mobx-react'
import store from '../../store'
import Select from 'react-select'
import { SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY, OPTIONS, API } from '../../constant'
import { prepareSelectParam } from '../../utility/queryParams'
import SideNav from '../SideNav'
import Modal from '../reusable/Modal'
import FormParent from '../FormParent'
import Home from '../Home'

class SubscriptionsTypeForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                name: '',
                description: '',
                active: ''
            },
            editParams: [],
            validationFields: ['name', 'description'],
            error: false
        }
        this.parameters = {
            name: '',
            description: '',
            active: ''
        }
    }


    async componentDidMount() {

        if (store.isEdit && store.currentId) {
            let paramFields = ['name', 'description', 'active']
            let fields = prepareSelectParam(paramFields)

            fetch(`${API}/subscription-type/${store.currentId}?fields=${fields}`, {
                method: 'GET',
                headers: {
                    'Authorization': store.token,
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    let obj = {}
                    Object.keys(this.state.formFields).map(key => {
                        if (key === 'active') {
                            obj[key] = data.data.active == 0 ? { value: 0, label: 'No' } : data.data.active == 1 ? { value: 1, label: 'Yes' } : ''
                        }
                        else {
                            obj[key] = data.data[key] !== null ? data.data[key] : ''
                        }
                    })
                    this.setState({ formFields: obj })
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    handleAddSubscription = () => {
        this.handleAdd('subscription-type');
    }

    handleUpdateSubscription = () => {
        this.handleUpdate('subscription-type');
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT SUBSCRIPTION TYPES</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD SUBSCRIPTIONS TYPES</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Name <span className='required'>*</span></label>
                                            <input type="text"
                                                className={this.state.nameEmpty ? "form-control input-error" : "form-control"}
                                                value={this.state.formFields.name}
                                                name='name'
                                                onChange={this.handleInputChange} />
                                            {this.state.nameEmpty && <div className='error'>Name cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Description <span className='required'>*</span></label>
                                            <input type="text"
                                                className={this.state.descriptionEmpty ? "form-control input-error" : "form-control"}
                                                value={this.state.formFields.description}
                                                name='description'
                                                onChange={this.handleInputChange} />
                                            {this.state.descriptionEmpty && <div className='error'>Description cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Active</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={this.state.activeEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.active}
                                                onChange={(option) => this.handleSelectChange(option, 'active')}
                                                options={OPTIONS}
                                            />
                                            {this.state.activeEmpty && <div className='error'>Active cannot be empty</div>}
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ? <button className="btn-primary bottom-btn" disabled={this.state.editParams.length > 0 || store.apis.length > 0 ? false : true} onClick={this.handleUpdateSubscription}>Save</button> :
                                    <div>
                                        <button className="btn-primary bottom-btn" onClick={this.handleAddSubscription}>Add Subscription Type</button>
                                        <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                                    </div>
                                }
                            </div>

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}

                        <Modal />

                    </div>
                }
            </div>
        )
    }
}
export default observer(SubscriptionsTypeForm)
