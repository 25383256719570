import axios from 'axios';

import store from '../store';
import { ALLOWED_QUERY_OPERATOR,RESEARCH_TYPES, API, ORDER_TYPES_VALUE } from '../constant';
import { prepareFilterParam, prepareSelectParam } from '../utility/queryParams';
import researchApi from './researchApi'

const subscriptionApi = {

    getSubscriptionTypes: () => {
        const fields = prepareSelectParam(['id', 'name']);

        const url = `${API}/subscription-type?fields=${fields}`;

        return new Promise((resolve, reject) => {

            axios.get(url).then((response) => {
                if (response.data.status === 200) {
                    resolve({
                        types: response.data.data,
                    });
                } else {
                    resolve({});
                }
            }).catch((err) => reject(err));
        });
    },

    getSubscriptionTaxonomy: async (type = 1) => {
        try {
            const subsFields = prepareSelectParam(['id', 'name', 'duration', 'price']);
            if (type == ORDER_TYPES_VALUE.subscription) {
                const subscriptions = await axios.get(`${API}/subscription/?fields=${subsFields}&limit=300`)
                return subscriptions.data.data
            }

            if (type == ORDER_TYPES_VALUE.report) {
                const filters = [{ 'key': 'type_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [RESEARCH_TYPES.paid] }]
                const fields = ['id', 'name', 'price'];
                const research = await researchApi.getResearchData(fields,filters,300)
                return research.records
            }
        } catch (err) {
            console.log(err);
        }
    },

    getRoles: (subscriptionId) => {
        const fields = prepareSelectParam(['id', 'role_data']);
        const filters = prepareFilterParam([{ key: 'subscription_id', value: [subscriptionId], op: ALLOWED_QUERY_OPERATOR.equal }]);

        const url = `${API}/subscription-type-role?fields=${fields}&filters=${filters}`;

        return new Promise((resolve, reject) => {

            axios.get(url).then((response) => {
                if (response.data.status === 200) {
                    resolve(response.data.data);
                } else {
                    resolve({});
                }
            }).catch((err) => reject(err));
        });
    },

    deleteRole: (subscriptionRoleId) => {
        const url = `${API}/subscription-type-role/${subscriptionRoleId}`;
        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        return new Promise((resolve, reject) => {

            axios.delete(url, { headers }).then((response) => {
                if (response.data.status === 200) {
                    resolve(response.data.data);
                } else {
                    reject();
                }
            }).catch((err) => reject(err));
        });
    },

}

export default subscriptionApi;