import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react';
import Select from 'react-select';

// helpers
import store from '../../store';
import { prepareSelectParam } from '../../utility/queryParams';

// constants
import { UNDISCLOSED_PROXY_COLUMN_HEADERS, ALLOWED_QUERY_OPERATOR, TAXONOMIES, SELECT_STYLES, SELECT_THEME_PRIMARY, OPTIONS } from '../../constant';

// apis
import taxonomyApi from '../../apis/taxonomyApi';

// components
import Parent from '../Parent';
import Home from '../Home';
import SideNav from '../SideNav';
import ActionRenderer from '../reusable/ActionRenderer';
import Sort from '../reusable/Sort';
import Pagination from '../reusable/Pagination';
import Modal from '../reusable/Modal';

class UndisclosedProxies extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            // directory table setup
            columnDefs: UNDISCLOSED_PROXY_COLUMN_HEADERS,
            context: { componentParent: this },
            frameworkComponents: { actionRenderer: ActionRenderer },
            defaultColDef: { suppressMovable: true },

            // data
            funding_stages: [],

            // filters
            funding_stage_id: '',
            year: '',
            active: '',
            filters: {
                year: [],
                funding_stage_id: [],
                active: []
            },
            filterFields: [
                { 'key': 'year', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'funding_stage_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'active', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] }
            ]
        }

        store.sortType = this.props.location.pathname.split('/')[1];
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
    }

    componentDidMount() {

        // set request required fields
        const paramFields = ['id', 'year', 'value', 'funding_stage_data', 'created_by_data', 'created_at', 'updated_at'];
        store.fields = prepareSelectParam(paramFields);

        // set default sort by
        store.currentSort = "year^:desc^,id^:desc";

        // get the undisclosed-proxies list
        this.fetchListData('undisclosed-proxy', store.fields);

        // get funding stages list
        taxonomyApi.getTaxonomyByType(TAXONOMIES.fundingStage).then(
            response => {
                if (response.length > 0) {
                    let arrTemp = [];
                    response.map(stage => arrTemp.push({ value: stage.id, label: stage.name }));

                    this.setState({
                        funding_stages: arrTemp
                    });
                }
            }
        );
    }

    clearFilters = () => {
        // reset filters in state
        this.setState({
            funding_stage_id: '',
            active: '',
            filters: {
                funding_stage_id: [],
                active: []
            },
            filterFields: [
                { 'key': 'funding_stage_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'active', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] }
            ]
        });

        // reset filters in store
        store.currentFilters = '';

        // get the undisclosed-proxies list
        this.fetchListData('undisclosed-proxy', store.fields);
    }

    render() {
        if (!store.isLoggedin) {
            return <Home />;
        }

        return (
            <div>
                <div className='content'>

                    <div className='content-left mobile-hide'>
                        <SideNav />
                    </div>

                    <div className='mobile-show'>
                        {store.isSideNav ?
                            <div className='content-left'>
                                <SideNav />
                            </div> :
                            <div className='content-left-bar '>
                                <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                            </div>
                        }
                    </div>

                    <div className='content-right'>
                        <h3 style={{ textAlign: 'center' }}>UNDISCLOSED PROXIES</h3>

                        <div className='filter-container'>
                            <div className='row'>

                                {/* Funding stage filter*/}
                                <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                    <label>Funding Stage</label>
                                    <Select
                                        placeholder='Filter by Funding Stage'
                                        styles={SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        value={this.state.funding_stage_id}
                                        onChange={(option) => this.handleSingleSelectFilter(option, 'funding_stage_id')}
                                        options={this.state.funding_stages}
                                    />
                                </div>

                                {/* Status filter*/}
                                <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                    <label>Active Status</label>
                                    <Select
                                        placeholder='Filter by Active Status'
                                        styles={SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        value={this.state.active}
                                        onChange={(option) => this.handleSingleSelectFilter(option, 'active')}
                                        options={OPTIONS}
                                    />
                                </div>
                            </div>

                            {/* Clear filters button*/}
                            <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                    <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-6 filter-searchbtn" style={{ color: 'white' }} onClick={this.clearFilters}>Clear All</a>
                                </div>
                            </div>

                        </div>

                        <Sort />

                        <div id='grid' className='ag-theme-balham' style={{ width: '100%', color: '#5d656d' }}>
                            <AgGridReact
                                reactNext={true}
                                defaultColDef={this.state.defaultColDef}
                                columnDefs={this.state.columnDefs}
                                rowData={store.rowData}
                                domLayout='autoHeight'
                                context={this.state.context}
                                frameworkComponents={this.state.frameworkComponents}
                                onGridReady={this.onGridReady}
                                enableCellTextSelection={true}
                            >
                            </AgGridReact>
                        </div>

                        <Pagination />

                    </div >

                    {store.isSideNav && <div className='overlay'> </div>}
                    <Modal />
                </div>

            </div>
        )
    }
}


export default observer(UndisclosedProxies)
