import React from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import DatePicker from 'react-datepicker';

// constants
import { FIELD_SEPARATOR, SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY } from '../../constant';
import { DATA_EXPORT_SORT } from '../../utility/constants/dataExport/sort';

// helpers
import store from '../../store';

// apis
import { getUsers } from '../../utility/commonApis';
import resourceApi from '../../apis/resourceApi';
import dataExportApi from '../../apis/dataExportApi';

// components
import Home from '../Home';
import FormParent from '../FormParent';
import SideNav from '../SideNav';
import FiltersBuilderModal from '../reusable/dataExport/FiltersBuilderModal';
import Modal from '../reusable/Modal';
import ErrorPopup from '../reusable/ErrorModal';
import { DATA_EXPORT_FILTERS } from '../../utility/constants/dataExport/filters';

class DataExportLogForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                user: '',
                data_set_type: '',
                number_of_records: '',
                fields: '',
                filters: [],
                sort: '',
                request_date: ''
            },
            validationFields: ['user', 'data_set_type', 'fields'],
            // stores
            dataSetTypes: [],
            users: [],
            fields: [],
            sortList: [],
            filtersList: [],

            selectedField: '',
            errorMessage: ''
        }

        this.parameters = {
            data_set_type: '',
            number_of_records: '',
            fields: [],
            filters: '',
            sort: '',
            user: '',
            request_date: ''
        }
    }

    async componentDidMount() {
        await this.getDataExportProducts();
        await this.getUsersList();

    }

    getDataExportProducts = async () => {
        dataExportApi.getProducts().then(
            response => {
                const dataSetTypes = response.map(type => {
                    return { value: type.data_set_type, label: type.data_set_type, resource: type.resource };
                })

                this.setState({ dataSetTypes });
            }
        );
    }

    getUsersList = async (option = '') => {
        const users = await getUsers(option);

        this.setState({ users });

        return users;
    }

    handleDataSetChange = (option) => {
        const value = option.value;
        const resource = option.resource;

        // handle change
        this.handleSelectChange(option, 'data_set_type');

        // reset fields
        const tempFormFields = { ...this.state.formFields };
        tempFormFields.fields   = [];
        tempFormFields.filters  = [];
        tempFormFields.sort     = '';

        this.parameters.fields  = [];
        this.parameters.filters = '';
        this.parameters.sort    = '';

        this.setState({ formFields: tempFormFields, selectedField: '' });

        // get the data set fields
        this.getAttributes(resource);

        // get the data set sort
        this.getSortOptions(value);
    }

    getAttributes = async (resource = '') => {
        const resourceCode = resource.toLowerCase();
        const resourceName = `${resourceCode}-get`;

        await resourceApi.getResourceIdByCode(resourceCode, resourceName).then(
            response => {
                const attributes = response.attributes[resourceCode].map(item => {
                    return { value: item.key, label: item.label };
                });

                this.setState({ fields: attributes });
            }
        );
    }

    getSortOptions = async (dataSetType = '') => {
        const sortList = DATA_EXPORT_SORT[dataSetType];

        this.setState({ sortList });
    }

    handleAddFieldOption = (option) => {
        const tempFormFields = {...this.state.formFields};

        if (tempFormFields.fields == "") {
            tempFormFields.fields = [];
        }

        tempFormFields.fields.push(option);

        this.setState({
            formFields: tempFormFields,
            selectedField: option
        });

        this.parameters.fields.push(option.value);
    }

    handleRemoveField = (index) => {
        let tempFormFields = { ...this.state.formFields };
        let fields = [...tempFormFields.fields];

        fields.splice(index, 1);
        tempFormFields.fields = fields;

        this.parameters.fields.splice(index, 1);

        this.setState({
            formFields: tempFormFields
        });
    }

    handleAddFilter = (filter) => {
        let tempFiltersList = { ...this.state.formFields };

        if (tempFiltersList.filters == '') {
            tempFiltersList.filters = [];
        }

        tempFiltersList.filters.push(...filter);

        this.setState({
            formFields: tempFiltersList
        });
    }

    handleRemoveFilter = (index, secondaryFilterValue = null) => {

        let tempFormFields = { ...this.state.formFields };
        let filters = [...tempFormFields.filters];

        filters.splice(index, 1);

        // remove the secondary filter if the filter has secondary filter
        // If a primary filter (e.g., 'entity_id') is removed, the corresponding secondary filter (e.g., 'entity_type') is removed.
        // The secondary filter provides additional validation when filtering by entity.
        if (secondaryFilterValue) {
            const secondaryFilterIndex = filters.findIndex(filter => filter.value === secondaryFilterValue);

            if (secondaryFilterIndex > -1) {
                filters.splice(secondaryFilterIndex, 1);
            }
        }

        tempFormFields.filters = filters;

        this.setState({
            formFields: tempFormFields
        });
    }

    handleAddLog = () => {
        const formFields = this.state.formFields;

        // set the filters
        const tempFilters = formFields.filters.map(filter => filter.value);
        this.parameters.filters = tempFilters.join(FIELD_SEPARATOR);

        // set the user
        const userId = formFields.user.value;
        delete this.parameters.user;

        const path = `user/${userId}/admin-data-export-request`;
        store.successLabel = 'Data export request log';

        this.handleAdd(path);
    }

    showFilterModal = () => {
        const { data_set_type } = this.state.formFields;

        store.filterFieldsOptions = DATA_EXPORT_FILTERS[data_set_type.value];

        $('#filtersBuilderModal').modal({
            backdrop: 'static',
            keyboard: true,
            show: true
        })
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className="content">
                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className="content-right">
                            <h3 style={{ textAlign: 'center' }}>ADD DATA EXPORT LOG</h3>

                            <div className='form-container'>
                                <div className='form-wrapper'>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >User <span className='required'>*</span></label>
                                            <AsyncSelect
                                                placeholder='Please select a user'
                                                name='user'
                                                styles={this.state.userEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.user}
                                                defaultOptions={this.state.users}
                                                loadOptions={(option) => this.getUsersList(option)}
                                                onChange={(option) => this.handleSelectChange(option, 'user')}
                                            />
                                            {this.state.userEmpty && <div className='error'>User cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Data Set Type <span className='required'>*</span></label>
                                            <Select
                                                name='data_set_type'
                                                placeholder='Please Select'
                                                styles={this.state.data_set_typeEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                options={this.state.dataSetTypes}
                                                value={this.state.formFields.data_set_type}
                                                onChange={this.handleDataSetChange}
                                            />
                                            {this.state.data_set_typeEmpty && <div className='error'>Data set type cannot be empty</div>}
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Number of Records </label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name='number_of_records'
                                                value={this.state.formFields.number_of_records}
                                                onChange={this.handleInputChange} />
                                            {this.state.number_of_recordsEmpty && <div className='error'>Number of records cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Fields <span className='required'>*</span></label>
                                            <Select
                                                name='fields'
                                                placeholder='Select Fields'
                                                styles={this.state.fieldsEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                isDisabled={!this.state.formFields.data_set_type}
                                                value={this.state.selectedField}
                                                onChange={this.handleAddFieldOption}
                                                options={this.state.fields}
                                            />
                                            {this.state.formFields.fields ?
                                                this.state.formFields.fields.length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state.formFields.fields.map((item, i) => {
                                                        return <li key={i}>{item.label} <i className='fa fa-times-circle' onClick={() => this.handleRemoveField(i)}></i></li>
                                                    })}
                                                </div> : null
                                            }
                                            {this.state.fieldsEmpty && <div className='error'>Fields cannot be empty</div>}
                                        </div>
                                    </div>

                                    <hr />

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <div className="d-flex justify-content-between pb-2">
                                                <label >Filters</label>
                                                <button
                                                    className="btn-primary bottom-btn"
                                                    disabled={!this.state.formFields.data_set_type}
                                                    onClick={this.showFilterModal}
                                                >
                                                    Add Filter
                                                </button>
                                            </div>
                                            <ul className="list-group list-group-flush">
                                                {
                                                    this.state.formFields.filters.length > 0 ?
                                                        this.state.formFields.filters.map((item, i) => {
                                                            if (item.isPrimaryFilterValue) {
                                                                return <li className='list-group-item' key={i} style={{ fontSize: 15 }}>
                                                                    {item.label}{' '}
                                                                    <i className='fa fa-times-circle' onClick={() => this.handleRemoveFilter(i, item.secondaryFilterValue)}></i>
                                                                </li>
                                                            }
                                                            return null;
                                                        })
                                                        :   <div className='d-flex justify-content-center bd-highlight' style={{ fontSize: '1rem', padding: 10, backgroundColor: "#cbcccd" }}>
                                                            <label>Added filters will be shown here.</label>
                                                        </div>
                                                }
                                            </ul>
                                        </div>
                                    </div>

                                    <hr/>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Sort</label>
                                            <Select
                                                placeholder='Select Sort'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                isDisabled={!this.state.formFields.data_set_type}
                                                options={this.state.sortList}
                                                value={this.state.formFields.sort}
                                                onChange={(option) => this.handleSelectChange(option, 'sort')}
                                            />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Request Date</label>
                                            <DatePicker
                                                placeholderText="Select request date"
                                                className='datepicker-form-control'
                                                dateFormat="yyyy/MM/dd"
                                                maxDate={new Date()}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                selected={this.state.formFields.request_date}
                                                onChange={(date) => this.handleDateChange(date, 'request_date')} />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className='bottom-btn-wrapper'>
                                <div>
                                    <button className="btn-primary bottom-btn" onClick={this.handleAddLog}>Add Request Log</button>
                                    <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                                </div>
                            </div>

                        </div>
                        {store.isSideNav && <div className='overlay'> </div>}
                        <ErrorPopup errorMessage={this.state.errorMessage}/>
                        <Modal/>
                        <FiltersBuilderModal handleAddFilter={this.handleAddFilter}/>

                    </div>
                }
            </div>
        )
    }
}

export default observer(DataExportLogForm)
