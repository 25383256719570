import React, { Component } from 'react';

class AddBrandThemeModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            font_color: '',
            line_color: '',
            primary_color: '',
            secondary_color: '',
        }
    }

    componentDidMount() {
        const { brandTheme, isEdit } = this.props;
        if (brandTheme && isEdit) {
            this.setState({
                font_color: `#${brandTheme.font_color}`,
                line_color: `#${brandTheme.line_color}`,
                primary_color: `#${brandTheme.primary_color}`,
                secondary_color: `#${brandTheme.secondary_color}`,
            });
        }
    }

    handleCloseModal = () => {
        this.handleClearFields();

        this.props.setParentState({
            showBrandThemeModel: false
        });
    }

    handleClearFields = () => {

        this.setState({
            font_color: '',
            line_color: '',
            primary_color: '',
            secondary_color: '',
        });

	 $('#brandThemeModal').modal('hide');
    }

    handleAddBrandTheme = () => {
        const { font_color, line_color, primary_color, secondary_color } = this.state;

        const obj = {
            font_color: font_color.replace('#', ''),
            line_color: line_color.replace('#', ''),
            primary_color: primary_color.replace('#', ''),
            secondary_color: secondary_color.replace('#', '')
        };

        const option = { value: obj };

        this.props.handleAddBrandTheme(option, 'brand_theme');
        this.handleClearFields();
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render() {
        return (
            <div className="modal fade" id="brandThemeModal">
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">Add Brand Theme</h4>
                            <button type="button" className="close" onClick={this.handleCloseModal}>&times;</button>
                        </div>

                        <div className="modal-body">
                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Font Color</label>
                                    <input
                                        className="form-control"
                                        type="color"
                                        name='font_color'
                                        placeholder="Add Font Color"
                                        value={this.state.font_color}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Line Color</label>
                                    <input
                                        className="form-control"
                                        type="color"
                                        name='line_color'
                                        placeholder="Add Line Color"
                                        value={this.state.line_color}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Primary Color</label>
                                    <input
                                        className="form-control"
                                        type="color"
                                        name='primary_color'
                                        placeholder="Add Primary Color"
                                        value={this.state.primary_color}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Secondary Color</label>
                                    <input
                                        className="form-control"
                                        type="color"
                                        name='secondary_color'
                                        placeholder="Add Secondary Color"
                                        value={this.state.secondary_color}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleCloseModal}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={this.handleAddBrandTheme}>Add Brand Theme</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddBrandThemeModel
