import axios from 'axios';

import { API } from '../constant';

import store from '../store';

const searchApi = {

    // get entities list
    getEntities: (filterValue) => {

        const url = `${API}/search/entity-investors?keyword=${filterValue}`;
        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(
                    response => resolve(response.data.data)
                )
                .catch(
                    error => reject(error)
                );
        });
    },

    getEntityNamesSuggestions: (entityName = '') => {

        return new Promise((resolve, reject) => {
            let url = `${API}/search/entity-names?keyword=${entityName}&limit=10`;
            const headers = {
                Authorization: store.token
            };
            axios.get(url, { headers })
                .then(response => {
                    if (response.data.status === 200) {
                        resolve(response.data.data)
                    } else {
                        reject([])
                    }
                }).catch(err => reject(err))
        });
    }
}

export default searchApi;
