import React from 'react';
import { SUBSCRIPTIONS_ADDONS } from '../../../utility/constants/subscription_addons';

function SubscriptionAddonsSection(props) {
    return (
        <div className='form-wrapper'>
            <h5>Subscription Addons</h5>
            <div className='row'>
                <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                    <label>Please select the subscription addons<span className='required'>*</span></label>
                    <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                        {props.subscriptionAddons.map(addon => {

                            let isDisabled = false;
                            if (addon.id === SUBSCRIPTIONS_ADDONS.directory_addon) {
                                isDisabled = true;
                                addon.is_selected = true;
                            }

                            return (
                                <div key={addon.id} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <input
                                        className="form-control"
                                        style={{ width: '4%' }}
                                        type="checkbox"
                                        id={addon.id}
                                        name={addon.name}
                                        checked={addon.is_selected}
                                        disabled={isDisabled}
                                        onChange={props.handleSelectAddon}
                                    />
                                    <label style={{ paddingLeft: 10 }}>{addon.name}</label>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <hr />
        </div>
    )
}

export default SubscriptionAddonsSection;