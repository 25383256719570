import React from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';

// constants
import { SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY, ACTIVE_STATUS_OBJ, API, GENDERS } from '../../constant';

// APIs
import { getLocations, getPeopleData } from '../../utility/commonApis'

// helpers
import store from '../../store';
import { prepareSelectParam } from '../../utility/queryParams';
import { isObjectEmpty } from '../../utility/helpers';

// components
import Home from '../Home';
import FormParent from '../FormParent';
import SideNav from '../SideNav';
import Modal from '../reusable/Modal';
import ErrorPopup from '../reusable/ErrorModal';

class PeopleForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                first_name: '',
                last_name: '',
                email: '',
                type: '',
                status: '',
                gender: '',
                profile_pic: '',
                country_id: '',
                nationality_id: '',
                linkedin: '',
                facebook: '',
                twitter: '',
                skype: '',
                phone: '',
                current_job_title: '',
                occupation_description: '',
                experience: ''
            },
            locations: [],
            profile_picMsg: '',
            profile_pic: '',
            profile_picFile: '',
            editprofile_pic: '',
            editParams: [],
            fileFields: ['profile_pic'],
            socialFields: ['linkedin', 'facebook', 'twitter'],
            validationFields: ['first_name', 'last_name',],
        }

        this.parameters = {
            first_name: '',
            last_name: '',
            email: '',
            type: '',
            status: '',
            gender: '',
            profile_pic: '',
            country_id: '',
            nationality_id: '',
            linkedin: '',
            facebook: '',
            twitter: '',
            skype: '',
            phone: '',
            current_job_title: '',
            occupation_description: '',
            experience: ''
        }
    }

    componentDidMount() {
        if (store.isEdit && store.currentId) {
            getPeopleData(`people/${store.currentId}`).then(response => {
                return response.json()
            }).then(data => {
                let obj = {}
                Object.keys(this.state.formFields).map(key => {
                    if (key === 'profile_pic') {
                        obj[key] = data.data.profile_pic_data !== null ? data.data.profile_pic_data.name : ''
                    } else if (key === 'status') {
                        obj[key] = data.data.status === 0 ? { 'value': data.data.status, 'label': 'Inactive' } :
                            data.data.status === 1 ? { 'value': data.data.status, 'label': 'Active' } : ''
                    } else if (key === 'gender' && data.data.gender) {
                        obj[key] = { 'value': data.data.gender, 'label': data.data.gender }
                    } else if (key === 'country_id') {
                        obj[key] = !isObjectEmpty(data.data.country_data) ? { value: data.data.country_data.id, label: data.data.country_data.name } : '';
                    } else if (key === 'nationality_id') {
                        obj[key] = !isObjectEmpty(data.data.nationality_data) ? { value: data.data.nationality_data.id, label: data.data.nationality_data.name } : '';
                    } else {
                        obj[key] = data.data[key] !== null ? data.data[key] : ''
                    }
                })

                this.setState({ formFields: obj })
            }).catch(err => {
                console.log(err)
            })
        }

        getLocations().then(res => {
            this.setState({ locations: res })
        })
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT PEOPLE</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD PEOPLE</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>
                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>First Name <span className='required'>*</span></label>
                                            <input type="text"
                                                className={this.state.first_nameEmpty ? "form-control input-error" : "form-control"}
                                                value={this.state.formFields.first_name}
                                                name='first_name'
                                                onChange={this.handleInputChange}
                                            />
                                            {this.state.first_nameEmpty && <div className='error'>First name cannot be empty</div>}
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Last Name <span className='required'>*</span></label>
                                            <input type="text"
                                                className={this.state.last_nameEmpty ? "form-control input-error" : "form-control"}
                                                value={this.state.formFields.last_name}
                                                name='last_name'
                                                onChange={this.handleInputChange}
                                            />
                                            {this.state.last_nameEmpty && <div className='error'>Last name cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Email</label>
                                            <input type="text"
                                                className={"form-control"}
                                                value={this.state.formFields.email}
                                                name='email'
                                                onChange={this.handleInputChange}
                                            />
                                            {this.state.emailInvalid && <div className='error'>Email is invalid</div>}
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Profile Picture</label>
                                            <div className="input-group mb-3">
                                                <div className="custom-file">
                                                    <div className="form-filename-holder">{this.state.formFields.profile_pic === '' ? 'Choose a File' : this.state.formFields.profile_pic}</div>
                                                    <input type='file' className="custom-file-input" name='profile_pic' onChange={this.handleImageUpload} />
                                                </div>
                                                <div className="input-group-append">
                                                    <span className="input-group-text" onClick={() => this.addFileUpload('profile-pic', 'profile_pic', this.state.profile_picFile)} >Upload</span>
                                                </div>
                                            </div>
                                            {this.state.profile_picMsg.length > 0 && <div className='error'>{this.state.profile_picMsg}</div>}
                                            {this.state.profile_picError && <div className='error'>Upload failed!.</div>}
                                            {this.state.profile_picSuccess && <div className='success'>Upload successful!</div>}
                                            {this.state.profile_picUpload && <div className='error'>File is chosen but not uploaded</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Gender</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.gender}
                                                onChange={(option) => this.handleSelectChange(option, 'gender')}
                                                options={GENDERS}
                                            />
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Status</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.status}
                                                onChange={(option) => this.handleSelectChange(option, 'status')}
                                                options={ACTIVE_STATUS_OBJ}
                                            />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Location</label>
                                            <Select
                                                name="country_id"
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.country_id}
                                                onChange={(option) => this.handleSelectChange(option, 'country_id')}
                                                options={this.state.locations}
                                            />
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Nationality</label>
                                            <Select
                                                name="nationality_id"
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.nationality_id}
                                                onChange={(option) => this.handleSelectChange(option, 'nationality_id')}
                                                options={this.state.locations}
                                            />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Linkedin URL</label>
                                            <input className={this.state.linkedinSocial ? "form-control input-error " : "form-control"}
                                                name='linkedin'
                                                value={this.state.formFields.linkedin}
                                                onChange={this.handleInputChange}
                                            />
                                            {this.state.linkedinSocial && <div className='error'>Invalid url</div>}
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Facebook URL</label>
                                            <input className={this.state.facebookSocial ? "form-control input-error " : "form-control"}
                                                name='facebook'
                                                value={this.state.formFields.facebook}
                                                onChange={this.handleInputChange}
                                            />
                                            {this.state.facebookSocial && <div className='error'>Invalid url</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Twitter URL</label>
                                            <input className={this.state.twitterSocial ? "form-control input-error " : "form-control"}
                                                name='twitter'
                                                value={this.state.formFields.twitter}
                                                onChange={this.handleInputChange}
                                            />
                                            {this.state.twitterSocial && <div className='error'>Invalid url</div>}
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Skype</label>
                                            <input className="form-control"
                                                name='skype'
                                                value={this.state.formFields.skype}
                                                onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Phone</label>
                                            <input title='number'
                                                className={this.state.phoneNumber ? "form-control input-error" : "form-control"}
                                                value={this.state.formFields.phone}
                                                name='phone'
                                                onChange={this.handleInputChange}
                                            />
                                            {this.state.phoneNumber && <div className='error'>Phone number should be number</div>}
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Current Title</label>
                                            <input className="form-control"
                                                name='current_job_title'
                                                value={this.state.formFields.current_job_title}
                                                onChange={this.handleInputChange}
                                            />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Occupation Description</label>
                                            <textarea rows='8'
                                                className="form-control"
                                                name='occupation_description'
                                                value={this.state.formFields.occupation_description}
                                                onChange={this.handleInputChange}
                                            />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Experience</label>
                                            <textarea rows='8'
                                                className="form-control"
                                                name='experience'
                                                value={this.state.formFields.experience}
                                                onChange={this.handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ? <button className="btn-primary bottom-btn" disabled={this.state.editParams.length > 0 || store.apis.length > 0 ? false : true} onClick={() => this.handleUpdate('people')}>Save</button> :
                                    <div>
                                        <button className="btn-primary bottom-btn" onClick={() => this.handleAdd('people')}>Add People</button>
                                        <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                                    </div>
                                }
                            </div>

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <ErrorPopup errorMessage={this.state.errorMessage} />
                        <Modal />
                    </div>
                }
            </div>
        )
    }
}
export default observer(PeopleForm)
