import axios from 'axios';
import store from '../store';
import { ALLOWED_QUERY_OPERATOR, API } from '../constant';
import { prepareSelectParam, prepareFilterParam } from '../utility/queryParams';


const emailTemplateApi = {
    getEmailTemplate: (strName = '') => {
        const arrFields = ['id', 'name'];
        const queryFields = prepareSelectParam(arrFields);
        let queryFilters = "";
        if (strName !== '') {
            queryFilters = prepareFilterParam([
                { key: 'name', value: [strName], op: ALLOWED_QUERY_OPERATOR.substring }
            ]);
        }
        const url = `${API}/email-template?fields=${queryFields}&filters=${queryFilters}`;
        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };
        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(err => reject(err));
        })
    },
}
export default emailTemplateApi;
