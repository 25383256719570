import React from 'react'
import { observer } from 'mobx-react'
import Select from 'react-select'
import store from '../../store'
import { SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY, OPTIONS, METHODS } from '../../constant'
import SideNav from '../SideNav'
import Modal from '../reusable/Modal'
import FormParent from '../FormParent'
import Home from '../Home'
import resourceApi from '../../apis/resourceApi'

class ResourceForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                code: '',
                method: '',
                operation: '',
                path: '',
                authenticate: '',
                authorize: '',
                service_name: '',
                active: '',
            },
            validationFields: ['code', 'method', 'authenticate', 'authorize', 'service_name', 'active'],
            editParams: [],
            isPathRequired: false
        }

        this.parameters = {
            code: '',
            method: '',
            operation: '',
            path: '',
            authenticate: '',
            authorize: '',
            service_name: '',
            active: '',
        }
    }

    componentDidMount() {
        if (store.isEdit && store.currentId) {
            resourceApi.getResourceDetails(store.currentId).then(
                response => {
                    const resourceObj = response.resource;
                    const tempObj = {};

                    Object.keys(this.state.formFields).map(key => {
                        if (key === 'method') {
                            tempObj[key] = resourceObj[key] !== null ? { value: resourceObj[key], label: resourceObj[key].toUpperCase() } : '';
                        } else if (['authenticate', 'authorize', 'active'].includes(key)) {
                            tempObj[key] = resourceObj[key] !== null ? { value: resourceObj[key], label: resourceObj[key] ? 'Yes' : 'No' } : '';
                        } else {
                            tempObj[key] = resourceObj[key] !== null ? resourceObj[key] : '';
                        }
                    });

                    this.setState({ formFields: tempObj });
                }
            );
        }
    }

    handleOperationChange = (event) => {
        const value = event.target.value

        if (store.isEdit) {
            if (this.state.editParams.indexOf('operation') === -1) {
                this.state.editParams.push('operation')
            }
        }

        const tempArr = [...this.state.validationFields];
        const tempObj = { ...this.state.formFields };

        tempObj.operation = value;
        this.parameters.operation = value;

        let isPathRequired;
        if (value === "") {
            isPathRequired = false;
        } else {
            isPathRequired = true;
            tempArr.push('path');
        }

        this.setState({
            operationEmpty: false,
            validationFields: tempArr,
            formFields: tempObj,
            isPathRequired,
        });
    }

    handleAddResource = () => {
        if  (this.parameters.operation === "" && this.parameters.path === "") {
            // set the path default value
            this.parameters.path === this.parameters.code;
        }

        this.handleAdd('resource', true);
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className="content">
                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className="content-right">
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT RESOURCE</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD RESOURCE</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Resource Code (Model Name) <span className='required'>*</span></label>
                                            <input 
                                                className={this.state.codeEmpty ? "form-control input-error" : "form-control"} 
                                                type="text" 
                                                name='code' 
                                                placeholder='e.g. startup, investor' 
                                                disabled={store.isEdit}
                                                value={this.state.formFields.code} 
                                                onChange={this.handleInputChange} />
                                            {this.state.codeEmpty && <div className='error'>Resource code cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Method <span className='required'>*</span></label>
                                            <Select
                                                name='method'
                                                placeholder='Please Select'
                                                styles={this.state.methodEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                isDisabled={store.isEdit}
                                                options={METHODS}
                                                value={this.state.formFields.method}
                                                onChange={(option) => this.handleSelectChange(option, 'method')}
                                            />
                                            {this.state.methodEmpty && <div className='error'>Method cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Operation</label>
                                            <input 
                                                className={this.state.operationEmpty ? "form-control input-error" : "form-control"} 
                                                type="text" 
                                                name='operation' 
                                                placeholder="e.g. :param/funding" 
                                                disabled={store.isEdit}
                                                value={this.state.formFields.operation} 
                                                onChange={this.handleOperationChange} />
                                            {this.state.operationEmpty && <div className='error'>Operation cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >API Path (from the API route) {this.state.isPathRequired && <span className='required'>*</span>}</label>
                                            <input 
                                                className={(this.state.isPathRequired && this.state.pathEmpty) ? "form-control input-error" : "form-control"} 
                                                type="text" 
                                                name='path' 
                                                placeholder="e.g. startup/:id/funding"
                                                disabled={store.isEdit || !this.state.isPathRequired}
                                                value={this.state.formFields.path} 
                                                onChange={this.handleInputChange} />
                                            {(this.state.isPathRequired && this.state.pathEmpty) && <div className='error'>API path cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Authenticate <span className='required'>*</span></label>
                                            <Select
                                                name='authenticate'
                                                placeholder='Please Select'
                                                styles={this.state.authenticateEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.authenticate}
                                                onChange={(option) => this.handleSelectChange(option, 'authenticate')}
                                                options={OPTIONS}
                                            />
                                            {this.state.authenticateEmpty && <div className='error'>Authenticate flag cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Authorize <span className='required'>*</span></label>
                                            <Select
                                                name='authorize'
                                                placeholder='Please Select'
                                                styles={this.state.authorizeEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.authorize}
                                                onChange={(option) => this.handleSelectChange(option, 'authorize')}
                                                options={OPTIONS}
                                            />
                                            {this.state.authorizeEmpty && <div className='error'>Authorize flag cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Service Name <span className='required'>*</span></label>
                                            <input 
                                                className={this.state.service_nameEmpty ? "form-control input-error" : "form-control"} 
                                                type="text" 
                                                name='service_name' 
                                                placeholder="e.g. core-api, inv-app-api"
                                                value={this.state.formFields.service_name} 
                                                onChange={this.handleInputChange} />
                                            {this.state.service_nameEmpty && <div className='error'>Service name cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Is Active <span className='required'>*</span></label>
                                            <Select
                                                name='active'
                                                placeholder='Please Select'
                                                styles={this.state.activeEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.active}
                                                onChange={(option) => this.handleSelectChange(option, 'active')}
                                                options={OPTIONS}
                                            />
                                            {this.state.activeEmpty && <div className='error'>Active flag cannot be empty</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ? <button className="btn-primary bottom-btn" disabled={this.state.editParams.length > 0 ? false : true} onClick={() => this.handleUpdate('resource')}>Save</button> :
                                    <div>
                                        <button className="btn-primary bottom-btn" onClick={this.handleAddResource}>Add resource</button>
                                        <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                                    </div>
                                }
                            </div>

                        </div>
                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}

export default observer(ResourceForm)
