import React, { Component } from 'react'
import { NavLink, Link, withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import store from '../store'
import { API, PUBLIC_IMG_URL } from '../constant'
import Auth from '../utility/auth'
import Fetchintercept from 'fetch-intercept'
import Localstorage from '../utility/localstorage'

let isModalLogin = false

class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoggedin: false,
            loginEmail: '',
            loginPwd: '',
            errorLogin: false,
            errMessage: ''
        }
    }

    handleKeyPress = (event) => {
        if (event.keyCode === 13 && this.state.loginEmail !== '' && this.state.loginPwd !== '') {
            this.handleLogin()
        }
    }

    async componentDidMount() {
        await this.isValidAuth()
        this.unregister()
        let expireIn = Auth.getTokenExpiryIn(store.token);
        // Generate access token
        if (store.isLoggedin && store.refreshToken && expireIn > 0) {
            this.periodicAccessToken(expireIn)
        }
        else if (store.isLoggedin && store.refreshToken) {
            store.token = await Auth.getAccessToken(store.refreshToken)
            // If refresh token expiernced or access token not generated ask user to login.
            if (!store.token) {
                isModalLogin = true
                $('#loginModal').modal('show')
            }

            await Auth.setToken(store.token) // Update locastorage manually.
            expireIn = Auth.getTokenExpiryIn(store.token);
            this.periodicAccessToken(expireIn)
        }
    }

    /**
     * Method to validate auth on page load
     */
    async isValidAuth() {
        if (store.isLoggedin && Auth.isTokenExpired(store.token)) {
            store.token = await Auth.getAccessToken(store.refreshToken)

            if (store.token) {
                await Auth.setToken(store.token)
            } else {
                store.token = ''
                store.isLoggedin = false
                store.userId = ''
                store.refreshToken = ''
                store.permissions = {}

                window.location = `/`
            }
        }
    }

    /**
     * @note temparory solution. Needs to be written better way.
     */
    async periodicAccessToken(expireIn) {
        if (!expireIn) { 
            return;
        }

        // To avoid multiple tab api calls
        setTimeout(() => {
            Localstorage.set('timer-js', 1)
        }, 2 * 1000)
        
        setTimeout(async () => {
            if (Localstorage.get('timer-js') == 1) {
                Localstorage.set('timer-js', 0)
                store.token = await Auth.getAccessToken(store.refreshToken)
                // If refresh token expiernced or access token not generated ask user to login.
                if (!store.token) {
                    isModalLogin = true
                    $('#loginModal').modal('show')
                }

                await Auth.setToken(store.token) // Update locastorage manually.
                expireIn = Auth.getTokenExpiryIn(store.token)
            }
            await this.periodicAccessToken(expireIn)
        }, ((expireIn - 2) * 1000))
    }

    /**
     * @todo handle it in better and avoid duplicate api calls.
     */
    unregister() {
        return Fetchintercept.register({
            request: async (url, config) => {
                if (config && config.headers['Authorization'] && Auth.isTokenExpired(config.headers['Authorization'])) {
                    store.token = await Auth.getAccessToken(store.refreshToken)
                    // If refresh token expiernced or access token not generated ask user to login.
                    if (!store.token) {
                        isModalLogin = true
                        $('#loginModal').modal('show')
                    }

                    await Auth.setToken(store.token) // Update locastorage manually.

                    config.headers['Authorization'] = store.token;
                }

                return [url, config];
            }
        })
    }

    handleLogin = async () => {

        if (this.state.loginEmail === '') {
            this.setState({ errorEmail: true })
        }
        
        if (this.state.loginPwd === '') {
            this.setState({ errorPwd: true })
        }

        if (this.state.loginEmail !== '' && this.state.loginPwd !== '') {
            let params = {
                email: this.state.loginEmail,
                password: this.state.loginPwd
            }

            let loginData = await this.doLogin(params);

            if (loginData.status == 200) {
                const permissionsData = await this.fetchPermissions(loginData.data.user.id, loginData.data.token)
                
                if (loginData.data.user.roles.indexOf(3) === -1) {
                    this.setState({
                        errMessage: 'You have no access!'
                    })
                } else {
                    store.token         = loginData.data.token
                    store.userId        = loginData.data.user.id
                    store.refreshToken  = loginData.data.refresh_token
                    store.isLoggedin    = true
                    store.permissions   = permissionsData
                    // await Auth.setToken(loginData.data.token) // Update locastorage manually.

                    this.setState({
                        loginEmail: '',
                        loginPwd: ''
                    })
                    $('#loginModal').modal('hide')

                    if (!isModalLogin) {
                        window.location = '/'
                    }
                }
            }
            else {
                this.setState({
                    errMessage: loginData.errors[0].message
                })
            }
        }
    }

    async doLogin(params) {
        const loginResponse = await fetch(`${API}/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        });

        return await loginResponse.json();
    }

    async fetchPermissions(userId, token) {
        let response = await fetch(`${API}/user/${userId}/permission`, {
            method: 'GET',
            headers: {
                'Authorization': token
            }
        })
        const data = await response.json()
        return data.data;
    }

    // handleForgotPassword = () => {
    // }

    doLogoutStuff = () => {
        store.isLoggedin    = false
        store.token         = ''
        store.refreshToken  = ''
        store.userId        = ''
        store.permissions   = {}
        window.location     = '/'
    }

    // User will be loged out regardless of the API failure or success
    handleLogout = () => {
        fetch(`${API}/user/${store.userId}/logout`, {
            method: 'POST',
            headers: {
                'x-refresh-token': store.refreshToken
            }
        }).then(response => { return response.json() })
            .then(data => {
                this.doLogoutStuff();
            }).catch(err => {
                console.log(err);
                this.doLogoutStuff();
            })
    }

    render() {
        let errorEmail = ''
        let errorPwd = ''
        if (this.state.errorEmail) {
            errorEmail = 'input-error'
        }
        if (this.state.errorPwd) {
            errorPwd = 'input-error'
        }
        return (
            <div>
                <header className='header'>
                    <div className='header-left'>
                        <Link to={`/`}><img src={`${PUBLIC_IMG_URL}/favicon.ico`} alt='Magnitt Logo' /></Link>
                    </div>
                    <div className='header-right'>
                        {this.state.isLoggedin ?
                            null :
                            <div className="login">
                                {store.isLoggedin ?
                                    <div onClick={this.handleLogout} >LOG OUT</div> :
                                    <div onClick={() => $('#loginModal').modal('show')} >LOGIN</div>
                                }
                            </div>
                        }
                    </div>
                </header>

                <div className="modal fade" id="loginModal">
                    <div className="modal-dialog">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h4 className="modal-title">ENTER DETAILS</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>

                            <div className="modal-body">
                                <div className="" >
                                    <div className="login-form-container">
                                        <input type="text"
                                            placeholder="Email"
                                            className={`${errorEmail} login-input`}
                                            value={this.state.loginEmail}
                                            onChange={(e) => this.setState({ loginEmail: e.target.value, errorEmail: false, errMessage: '' })}
                                            onKeyDown={this.handleKeyPress} />
                                        {this.state.loginEmail.length > 0 && <span className='login-input-clearbtn' onClick={() => this.setState({ loginEmail: '' })} >clear</span>}
                                        {this.state.errorEmail && <div className='error'>Email cannot be empty</div>}
                                    </div>
                                    <div className="login-form-container">
                                        <input type="password"
                                            placeholder="Password"
                                            className={`${errorPwd} login-input`}
                                            value={this.state.loginPwd}
                                            onChange={(e) => this.setState({ loginPwd: e.target.value, errorPwd: false, errMessage: '' })}
                                            onKeyDown={this.handleKeyPress} />
                                        {this.state.loginPwd.length > 0 && <span className='login-input-clearbtn' onClick={() => this.setState({ loginPwd: '' })}>clear</span>}
                                        {this.state.errorPwd && <div className='error'>Password cannot be empty</div>}
                                    </div>
                                </div>
                            </div>

                            {this.state.errMessage.length > 0 && !this.state.errorEmail && !this.state.errorPwd &&
                                <div className='error pad'>{this.state.errMessage}</div>}

                            <div className="modal-footer">
                                {/* <p className='login-forgotpwd' onClick={this.handleForgotPassword} >FORGOT PASSWORD?</p> */}
                                <button type="button" className="btn btn-primary btn-block" onClick={this.handleLogin}>Login</button>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default observer(Header)