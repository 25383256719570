
import React, { PureComponent } from 'react'
import store from '../../store'

class PolicyModalActionRenderere extends PureComponent {
    showModal = () => {
        $('#policyViewModal').modal({
            backdrop: 'static',
            keyboard: true,
            show: true
        })
        store.policyViewModalData = this.props.data
    }

    render() {
        return (
            <a tabIndex="1" className="btn-edit" onClick={this.showModal}>View policy</a>
        )
    }
}

export default PolicyModalActionRenderere
