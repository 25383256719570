import React from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

// constants
import { SELECT_STYLES, SELECT_THEME_PRIMARY, POLICY_PERIODS, OPTIONS, SELECT_STYLES_ERROR } from '../../constant';
import { API_PATH } from '../../utility/constants/apiPath';

// helpers 
import store from '../../store'
import { isObjectEmpty } from '../../utility/helpers';

// apis
import taxonomyApi from '../../apis/taxonomyApi';
import dataExportApi from '../../apis/dataExportApi';

// components
import SideNav from '../SideNav';
import Modal from '../reusable/Modal';
import FormParent from '../FormParent';
import Home from '../Home';
import PoliciesTable from './policy/PoliciesTable';
import ErrorPopup from '../reusable/ErrorModal';

class DataExportPolicyForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                role_id: '',
                period: '',
                active: '',
                records_limit: '',
                policy: []
            },

            validationFields: ['role_id', 'period', 'policy'],

            editParams: [],
            
            roles: [],
        }
        this.parameters = {
            role_id: '',
            period: '',
            active: '',
            policy: []
        }
    }

    async componentDidMount() {

        taxonomyApi.getMagnittRoles().then(
            response => {
                const roles = response.map(role => {
                    return { value: role.id, label: role.name };
                });

                this.setState({ roles });
            });

        if ((store.isEdit || store.isCopy) && store.currentId) {
            dataExportApi.getPolicyDetails(store.currentId).then(
                response => {
                    let objTemp = {};

                    Object.keys(this.state.formFields).map(key => {
                        if (key === 'period') {
                            const filteredPeriods = POLICY_PERIODS.filter(item => item.value == response.period);

                            if (filteredPeriods.length > 0) {
                                objTemp[key] = filteredPeriods[0];
                            }

                            this.parameters.period = response.period;
                        }
                        else if (key === 'role_id') {
                            objTemp[key] = !isObjectEmpty(response.role_data) ? { 'value': response.role_data.id, 'label': response.role_data.name } : '';

                            this.parameters.role_id = response.role_data.id;
                        }
                        else if (key === 'active') {
                            objTemp[key] = response.active == 0 ? { 'value': response.active, 'label': 'No' } : { 'value': response.active, 'label': 'Yes' };

                            this.parameters.active = response.active;
                        }
                        else {
                            objTemp[key] = response[key] !== null ? response[key] : '';

                            this.parameters[key] = response[key];
                        }
                    })
                    
                    this.setState({ formFields: objTemp });
                }
            );
        }
    }

    /**
     * This method will get called from PolicyModal
     * when fields for policy has been added successfully
     */
    onPolicySuccess = (data, dataSetType = '') => {
        if (store.isEdit) {
            if (this.state.editParams.indexOf('policy') === -1) {
                this.state.editParams.push('policy');
            }
        }

        if (isObjectEmpty(data)) {
            return;
        }

        const tempFormFields    = { ...this.state.formFields };
        let tempPolicy          = [...tempFormFields.policy];

        // edit case
        if (dataSetType) {
            tempPolicy = tempPolicy.map(policy => {
                if (policy.data_set_type == dataSetType) {
                    return data;
                } else {
                    return policy;
                }
            });
        } 
        // add case
        else {
            tempPolicy.push(data);
        }

        tempFormFields.policy = tempPolicy;
        
        this.setState({ formFields: tempFormFields });
        this.parameters.policy = tempPolicy;
    }
    
    /**
     * This method will get called from PolicyTable Component
     * when policy gets removed
     */
    onPolicyRemove = (data) => {
        if (store.isEdit) {
            if (this.state.editParams.indexOf('policy') === -1) {
                this.state.editParams.push('policy');
            }
        }

        const tempFormFields = { ...this.state.formFields };
        tempFormFields.policy = data;
        
        this.setState({ formFields: tempFormFields });
        this.parameters.policy  = data;
    }

    handleAddPolicy = () => {
        const tempPolicy = [...this.parameters.policy];

        tempPolicy.map(item => {
            delete item.uniqueId;
        });
        
        this.parameters.policy = tempPolicy;

        this.handleAdd(API_PATH.dataExportPolicy);
    }

    handleUpdatePolicy = () => {
        const tempPolicy = [...this.parameters.policy];

        tempPolicy.map(item => {
            delete item.uniqueId;
        });
        
        this.parameters.policy = tempPolicy;
        this.handleUpdate(API_PATH.dataExportPolicy);
    }

    renderErrors = () => {
        const { periodEmpty, policyEmpty, role_idEmpty } = this.state

        let errors = [];

        if (periodEmpty) {
            errors.push(<div className='error'>Period cannot be empty.</div>);
        }

        if (policyEmpty) {
            errors.push(<div className='error'>Policy cannot be empty.</div>);
        }

        if (role_idEmpty) {
            errors.push(<div className='error'>Role cannot be empty.</div>);
        }

        return errors;
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT DATA EXPORT POLICY</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD DATA EXPORT POLICY</h3>
                            }   

                            <div className='form-container'>
                                <div className='form-wrapper'>
                                    <div className="row">
                                        
                                        <div className="col-md-6 col-12 form-group">
                                            <label >Role <span className="required">*</span></label>
                                            <AsyncSelect
                                                name='role_id'
                                                placeholder='Please select a role'
                                                styles={this.state.role_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                cacheOptions
                                                value={this.state.formFields.role_id}
                                                defaultOptions={this.state.roles}
                                                loadOptions={(value, callback) => this.handleLoadOptions(value, callback, 'role')}
                                                onChange={(option) => this.handleSelectChange(option, 'role_id')}
                                            />
                                        </div>

                                        <div className="col-md-6 col-12 form-group">
                                            <label >Period <span className="required">*</span></label>
                                            <Select
                                                name='period'
                                                placeholder='Please select a period'
                                                styles={this.state.periodEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.period}
                                                onChange={(option) => this.handleSelectChange(option, 'period')}
                                                options={POLICY_PERIODS}
                                            />
                                        </div>

                                        <div className="col-md-6 col-12 form-group">
                                            <label >Records Limit</label>
                                            <input 
                                                className="form-control"
                                                type="number"
                                                name="records_limit"
                                                value={this.state.formFields.records_limit}
                                                onChange={this.handleInputChange} 
                                            />
                                        </div>

                                        <div className="col-md-6 col-12 form-group">
                                            <label >Policy Status</label>
                                            <Select
                                                placeholder='Please select a status'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.active}
                                                onChange={(option) => this.handleSelectChange(option, 'active')}
                                                options={OPTIONS}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <PoliciesTable
                                data={this.state.formFields.policy}
                                isGeneralPolicy={this.state.formFields.records_limit !== ''}
                                onSuccess={this.onPolicySuccess}
                                onPolicyRemove={this.onPolicyRemove}
                            />

                            {this.renderErrors()}

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ? <button className="btn-primary bottom-btn" disabled={this.state.editParams.length > 0 ? false : true} onClick={this.handleUpdatePolicy}>Save</button> :
                                    <button className="btn-primary bottom-btn" onClick={this.handleAddPolicy}>Add</button>}
                                <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                            </div>

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <ErrorPopup errorMessage={this.state.errorMessage}/>
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}

export default observer(DataExportPolicyForm);
