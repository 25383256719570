import axios from 'axios';

import { ALLOWED_QUERY_OPERATOR, API } from '../constant';
import store from '../store';
import { prepareFilterParam, prepareSelectParam } from '../utility/queryParams';

const ecosystemApi = {

    verifyEntity: (entityId, entityType, payload) => {
        const url = `${API}/${entityType}/${entityId}/verify`

        const headers = {
            Authorization: store.token
        }

        return new Promise((resolve, reject) => {
            axios.patch(url, payload, { headers })
                .then(response => {
                    if (response.data.status === 200) {
                        resolve(response.data.data)
                    } else {
                        reject({})
                    }
                }).catch(err => reject(err))
        })
    },

    getEntityDetails: (entityId, entityType) => {

        const arrFields = ['id', 'name'];
        const queryFields = prepareSelectParam(arrFields);

        const url = `${API}/${entityType}/${entityId}?fields=${queryFields}`

        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(err => reject(err));
        })
    },

    getEcosystems: (strName) => {
        const arrFields = ['id', 'name', 'ecosystem_type'];
        const queryFields = prepareSelectParam(arrFields);

        let queryFilters = "";
        if (strName !== '') {
            queryFilters = prepareFilterParam([
                { key: 'name', value: [strName], op: ALLOWED_QUERY_OPERATOR.substring }
            ]);
        }

        const url = `${API}/ecosystem?fields=${queryFields}&filters=${queryFilters}`;

        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(err => reject(err));
        })
    }
}

export default ecosystemApi;
