import React, { Component } from 'react';
import store from '../../../store';

class OrganizationUserActionRenderer extends Component {
    handleDelete = () => {
        const rowIndex = this.props.rowIndex;
        const rowData = this.props.data;

        if (store.organizationUsersData[rowIndex].id == 'NEW') {
            store.organizationUsersData.splice(rowIndex, 1)
            store.organizationUsers = store.organizationUsers.filter(orgUser => orgUser.user_id != rowData.userId);
        } else {

            if (store.isEdit) {
                store.apis.push({ path: `${store.type}/${store.currentId}/user/${rowData.id}`, method: 'DELETE' });
            }

            store.organizationUsersData.splice(rowIndex, 1);
            store.organizationUsers = store.organizationUsers.filter(orgUser => orgUser.user_id != rowData.userId);
        }

        this.props.api.setRowData(store.organizationUsersData);
    }

    render() {
        return (
            <div>
                <a tabIndex='1' className="btn-delete" onClick={this.handleDelete} ><i className='fas fa-trash-alt'></i></a>
            </div>
        )
    }
}

export default OrganizationUserActionRenderer;

