import React from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

// constants
import { SELECT_STYLES, SELECT_THEME_PRIMARY, SELECT_STYLES_ERROR, ACTIVE_STATUS, API_PRODUCT_PERIOD } from '../../constant';
import { API_PRODUCT_DURATION_OPTIONS, API_PRODUCT_PERIOD_OPTIONS, API_PRODUCT_RESOURCE_OPTIONS } from '../../utility/constants/selectOptions';
import { API_PATH } from '../../utility/constants/apiPath';

// helpers
import store from '../../store'
import { isObjectEmpty } from '../../utility/helpers';

// apis
import taxonomyApi from '../../apis/taxonomyApi';
import apiProductApi from '../../apis/apiProductApi';

// components
import SideNav from '../SideNav';
import Modal from '../reusable/Modal';
import FormParent from '../FormParent';
import Home from '../Home';
import ErrorPopup from '../reusable/ErrorModal';
import ManageQuotaModal from './ManageQuotaModal';

class ApiProductForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                name: '',
                type: '',
                active: '',
                price: '',
                currency: '',
                duration: '',
                country_id: '',
                policy: {
                    quota: [],
                    roles: [],
                    is_general_policy: false
                }
            },
            validationFields: ['name', 'duration', 'price', 'country_id'],
            editParams: [],

            // roles
            isRoleEmpty: false,
            role: '',
            selectedRoles: [],

            // general quota flag
            is_general_policy: false,

            // quotas
            quotas: [],

            // options
            countries: [],
            roles: []
        }

        this.parameters = {
            name: '',
            type: '',
            active: '',
            price: '',
            currency: '',
            duration: '',
            country_id: '',
            policy: {
                quota: [],
                roles: [],
                is_general_policy: false
            }
        }
    }

    async componentDidMount() {

        taxonomyApi.getCountries().then(
            response => {
                const countries = response.map(role => {
                    return { value: role.id, label: role.name };
                });

                this.setState({ countries });
            });

        taxonomyApi.getMagnittRoles().then(
            response => {
                const roles = response.map(role => {
                    return { value: role.id, label: role.name };
                });

                this.setState({ roles }, () => {
                    this.prepareRoles()
                });
            });

        if ((store.isEdit || store.isCopy) && store.currentId) {
            apiProductApi.getApiProductDetails(store.currentId).then(
                response => {
                    let objTemp = {};

                    Object.keys(this.state.formFields).map(key => {
                        if (key === 'duration') {
                            const filteredPeriods = API_PRODUCT_DURATION_OPTIONS.filter(item => item.value == response.duration);

                            if (filteredPeriods.length > 0) {
                                objTemp[key] = filteredPeriods[0];
                            } else {
                                objTemp[key] = '';
                            }
                        }
                        else if (key === 'country_id') {
                            objTemp[key] = !isObjectEmpty(response.country_data) ? { value: response.country_data.id, label: response.country_data.name } : '';

                            this.parameters.country_id = response.country_data.id;
                        }
                        else if (key === 'active') {
                            objTemp[key] = response.active == 0 ? { value: response.active, label: 'In Active' } : { value: response.active, label: 'Active' };
                        }
                        else if (key === 'type') {
                            objTemp[key] = response.type !== null ? { value: response.type, label: response.type } : '';
                        }
                        else if (key === 'policy') {
                            if (isObjectEmpty(response.policy)) {
                                objTemp[key] = {
                                    quota: [],
                                    roles: [],
                                    is_general_policy: false
                                }
                            } else {
                                objTemp[key] = response.policy;
                            }
                        }
                        else {
                            objTemp[key] = response[key] !== null ? response[key] : '';
                        }
                    })

                    this.setState({ formFields: objTemp }, () => {
                        this.prepareRoles();
                        this.prepareQuota();
                        this.preparePolicyType();
                    });
                }
            );
        }
    }

    prepareRoles = () => {
        const { formFields, roles } = this.state;
        const tempFormFields = { ...formFields };
        const tempPolicy = { ...tempFormFields.policy };

        if (isObjectEmpty(tempPolicy)) {
            return;
        }

        const tempRoles = [...tempPolicy.roles];

        let selectedRoles = [];
        roles.forEach(role => {
            if (tempRoles.includes(role.value)) {
                selectedRoles.push(role);
            }
        });

        this.setState({
            selectedRoles
        });
    }

    prepareQuota = () => {
        const { formFields } = this.state;
        const tempFormFields = { ...formFields };
        const tempPolicy = { ...tempFormFields.policy };

        if (isObjectEmpty(tempPolicy)) {
            return;
        }

        const tempQuota = [...tempPolicy.quota];

        let displayData = [];
        tempQuota.forEach(quota => {
            const objTemp = {};

            const filteredPeriods = API_PRODUCT_PERIOD_OPTIONS.filter(item => item.value == quota.period);
            objTemp.period = filteredPeriods[0].label;

            const filteredResources = API_PRODUCT_RESOURCE_OPTIONS.filter(item => item.value == quota.resource);

            if (filteredResources.length > 0) {
                objTemp.resource = filteredResources[0].label;
            } else {
                objTemp.resource = '';
            }

            objTemp.limit = quota.limit;

            displayData.push(objTemp)
        });

        this.setState({
            quotas: displayData
        });
    }

    preparePolicyType = () => {
        const { formFields, roles } = this.state;
        const tempFormFields = { ...formFields };
        const tempPolicy = { ...tempFormFields.policy };

        if (isObjectEmpty(tempPolicy)) {
            return;
        }

        const isGeneralPolicy = tempPolicy.is_general_policy ? tempPolicy.is_general_policy : false;

        this.setState({
            is_general_policy: isGeneralPolicy
        });
    }

    handleSelectRole = (option = {}) => {
        const tempEditParams = [...this.state.editParams];
        if (store.isEdit) {
            if (tempEditParams.indexOf('policy') === -1) {
                tempEditParams.push('policy')
            }
        }

        const tempSelectedRoles = [...this.state.selectedRoles, option];

        this.setState({
            role: option,
            isRoleEmpty: false,
            selectedRoles: tempSelectedRoles,
            editParams: tempEditParams
        });
    }

    handleRemoveRole = (index) => {
        const tempEditParams = [...this.state.editParams];
        if (store.isEdit) {
            if (tempEditParams.indexOf('policy') === -1) {
                tempEditParams.push('policy')
            }
        }

        const tempSelectedRoles = [...this.state.selectedRoles];

        tempSelectedRoles.splice(index, 1);

        this.setState({
            selectedRoles: tempSelectedRoles,
            editParams: tempEditParams
        });
    }

    handleShowResourceModal = () => {
        $('#manageApiResourceModal').modal({
            backdrop: 'static',
            keyboard: true,
            show: true
        })
    }

    handleAddQuota = (obj = {}) => {
        const tempEditParams = [...this.state.editParams];
        if (store.isEdit) {
            if (tempEditParams.indexOf('policy') === -1) {
                tempEditParams.push('policy')
            }
        }

        const tempQuotas = [...this.state.quotas];
        const tempFormFields = { ...this.state.formFields };
        const tempPolicy = { ...tempFormFields.policy };

        const objDisplay = {
            resource: obj.resource.label,
            period: obj.period.label,
            limit: obj.limit
        };

        if (isObjectEmpty(tempPolicy)) {
            tempPolicy.quota = [];
            tempPolicy.roles = [];
        }

        tempQuotas.push(objDisplay);

        const objValue = {
            resource: obj.resource.value,
            period: obj.period.value,
            limit: obj.limit
        };

        tempPolicy.quota.push(objValue);

        tempFormFields.policy = tempPolicy;

        this.setState({
            formFields: tempFormFields,
            isQuotaEmpty: false,
            quotas: tempQuotas,
            editParams: tempEditParams
        });
    }

    handleRemoveQuota = (index) => {
        const tempEditParams = [...this.state.editParams];
        if (store.isEdit) {
            if (tempEditParams.indexOf('policy') === -1) {
                tempEditParams.push('policy')
            }
        }

        const tempDisplayQuotas = [...this.state.quotas];
        const tempFormFields = { ...this.state.formFields };
        const tempPolicy = { ...tempFormFields.policy };
        const tempQuotas = [...tempPolicy.quota];

        tempDisplayQuotas.splice(index, 1);
        tempQuotas.splice(index, 1);

        tempPolicy.quota = tempQuotas;
        tempFormFields.policy = tempPolicy;

        this.setState({
            formFields: tempFormFields,
            quotas: tempDisplayQuotas,
            editParams: tempEditParams
        });
    }

    handlePolicyTypeChange = () => {
        const arrTempEditParams = [...this.state.editParams];

        if (store.isEdit) {
            if (arrTempEditParams.indexOf('policy') === -1) {
                arrTempEditParams.push('policy')
            }
        }

        this.setState({
            is_general_policy: !this.state.is_general_policy,
            editParams: arrTempEditParams
        });
    }

    handleAddApiProduct = () => {
        const objErrors = {};
        if (this.state.selectedRoles.length === 0) {
            objErrors.isRoleEmpty = true;
        }

        if (this.state.quotas.length === 0) {
            objErrors.isQuotaEmpty = true;
        }

        if (!isObjectEmpty(objErrors)) {
            this.setState({
                ...objErrors
            }, () => {
                this.handleEmptyFieldValidation();
            });
        } else {
            const arrRoles = this.state.selectedRoles.map(role => role.value);

            this.parameters.policy.roles = arrRoles;
            this.parameters.policy.quota = this.state.formFields.policy.quota;
            this.parameters.policy.is_general_policy = this.state.is_general_policy;

            this.handleAdd(API_PATH.apiProduct);
        }
    }

    handleUpdateApiProduct = () => {
        const objErrors = {};
        if (this.state.selectedRoles.length === 0) {
            objErrors.isRoleEmpty = true;
        }

        if (this.state.quotas.length === 0) {
            objErrors.isQuotaEmpty = true;
        }

        if (!isObjectEmpty(objErrors)) {
            this.setState({
                ...objErrors
            }, () => {
                this.handleEmptyFieldValidation();
            });
        } else {
            const arrRoles = this.state.selectedRoles.map(role => role.value);

            this.parameters.policy.roles = arrRoles;
            this.parameters.policy.quota = this.state.formFields.policy.quota;
            this.parameters.policy.is_general_policy = this.state.is_general_policy;

            this.handleUpdate(API_PATH.apiProduct);
        }
    }

    renderErrors = () => {
        const { periodEmpty, policyEmpty, role_idEmpty, formFields } = this.state

        let errors = [];

        if (periodEmpty) {
            errors.push(<div className='error'>Period cannot be empty.</div>);
        }

        if (policyEmpty) {
            errors.push(<div className='error'>Policy cannot be empty.</div>);
        }

        if (role_idEmpty) {
            errors.push(<div className='error'>Role cannot be empty.</div>);
        }

        return errors;
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>Edit API Product</h3> :
                                <h3 style={{ textAlign: 'center' }}>Add API Product</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Name <span className='required'>*</span></label>
                                            <input
                                                className={this.state.nameEmpty ? "form-control input-error" : "form-control"}
                                                type="text"
                                                name='name'
                                                value={this.state.formFields.name}
                                                onChange={this.handleInputChange} />
                                            {this.state.nameEmpty && <div className='error'>Name cannot be empty</div>}
                                        </div>
                                    </div>

                                    <hr />

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Price <span className='required'>*</span></label>
                                            <input
                                                className={this.state.priceEmpty ? "form-control input-error" : "form-control"}
                                                type="number"
                                                name='price'
                                                value={this.state.formFields.price}
                                                onChange={this.handleInputChange} />
                                            {this.state.priceEmpty && <div className='error'>Price cannot be empty</div>}
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Currency </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name='currency'
                                                value={this.state.formFields.currency}
                                                onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Duration <span className='required'>*</span></label>
                                            <Select
                                                name='duration'
                                                placeholder='Select duration'
                                                styles={this.state.durationEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.duration}
                                                onChange={(option) => this.handleSelectChange(option, 'duration')}
                                                options={API_PRODUCT_DURATION_OPTIONS}
                                            />
                                            {this.state.durationEmpty && <div className='error'>Duration cannot be empty</div>}
                                        </div>
                                    </div>

                                    <hr />

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Country <span className='required'>*</span></label>
                                            <Select
                                                placeholder='Select a country'
                                                name='country_id'
                                                styles={this.state.country_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.country_id}
                                                options={this.state.countries}
                                                onChange={(option) => this.handleSelectChange(option, 'country_id')}
                                            />
                                            {this.state.country_idEmpty && <div className='error'>Country cannot be empty</div>}
                                        </div>
                                    </div>

                                    <hr />

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Type</label>
                                            <Select
                                                name='type'
                                                placeholder='Select type'
                                                styles={this.state.typeEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                isDisabled={true}
                                                value={this.state.formFields.type}
                                                onChange={(option) => this.handleSelectChange(option, 'type')}
                                                options={[]}
                                            />
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Status</label>
                                            <Select
                                                name='active'
                                                placeholder='Select status'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.active}
                                                onChange={(option) => this.handleSelectChange(option, 'active')}
                                                options={ACTIVE_STATUS}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className='form-container'>
                                <div className='form-wrapper'>
                                    <h5>Policy Details</h5>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Roles <span className='required'>*</span></label>
                                            <AsyncSelect
                                                name='role'
                                                placeholder='Please select a role'
                                                styles={this.state.isRoleEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                cacheOptions
                                                value={this.state.role}
                                                defaultOptions={this.state.roles}
                                                loadOptions={(value, callback) => this.handleLoadOptions(value, callback, 'role')}
                                                onChange={this.handleSelectRole}
                                            />
                                            {this.state.selectedRoles.length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state.selectedRoles.map((item, index) => {
                                                        return <li key={index}>{item.label} <i className='fa fa-times-circle' onClick={() => this.handleRemoveRole(index)}></i></li>
                                                    })}
                                                </div>
                                            }
                                            {this.state.isRoleEmpty && <div className='error'>Roles cannot be empty</div>}
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Policy Type</label>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    id="is_general_policy"
                                                    name="is_general_policy"
                                                    type="checkbox"
                                                    onChange={this.handlePolicyTypeChange}
                                                    checked={this.state.is_general_policy} />
                                                <label className="form-check-label" htmlFor="is_general_policy">Flag as a general policy</label>
                                            </div>
                                        </div>
                                    </div>

                                    <hr />

                                    <div className='d-flex justify-content-between'>
                                        <h6>Resources</h6>

                                        <button
                                            className="btn-primary bottom-btn"
                                            onClick={this.handleShowResourceModal}>
                                            Add Resource
                                        </button>
                                    </div>

                                    <div className='mt-4'>
                                        {
                                            this.state.quotas.length > 0 ?

                                                <table className="table table-striped">
                                                    <tbody>
                                                        <tr>
                                                            <th scope="col">Resource</th>
                                                            <th scope="col">Period</th>
                                                            <th scope="col">Limit</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                        {
                                                            this.state.quotas.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td>
                                                                            <ul>
                                                                                {item.resource}
                                                                            </ul>
                                                                        </td>
                                                                        <td>
                                                                            <ul>
                                                                                {item.period}
                                                                            </ul>
                                                                        </td>
                                                                        <td>
                                                                            <ul>
                                                                                {item.limit}
                                                                            </ul>
                                                                        </td>
                                                                        <td>
                                                                            <ul>
                                                                                <button className='btn btn-danger' onClick={() => this.handleRemoveQuota(index)}>Delete</button>
                                                                            </ul>
                                                                        </td>
                                                                    </tr>)
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                                :
                                                <div className='d-flex justify-content-center bd-highlight' style={{ fontSize: '1rem', padding: 10, backgroundColor: "#cbcccd" }}>
                                                    <label>Added Resources will be shown here.</label>
                                                </div>
                                        }
                                    </div>

                                </div>
                            </div>

                            {this.renderErrors()}

                            <div className='bottom-btn-wrapper'>
                                {
                                    store.isEdit ?
                                        <button
                                            className="btn-primary bottom-btn"
                                            disabled={this.state.editParams.length > 0 ? false : true}
                                            onClick={this.handleUpdateApiProduct}>
                                            Save
                                        </button>
                                        :
                                        <button className="btn-primary bottom-btn" onClick={this.handleAddApiProduct}>Add</button>
                                }
                                <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                            </div>

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <ErrorPopup errorMessage={this.state.errorMessage} />
                        <Modal />
                        <ManageQuotaModal handleAddQuota={this.handleAddQuota} />

                    </div>
                }
            </div>
        )
    }
}

export default observer(ApiProductForm);
