import axios from 'axios';
import store from '../store';
import { ALLOWED_QUERY_OPERATOR, API } from '../constant';
import { prepareSelectParam, prepareFilterParam } from '../utility/queryParams';

const customDashboardApi = {

    getCustomDashboardDetails: (customDashboardId = 0) => {
        const paramFields = [
            'id',
            'name',
            'organization_data',
            'organization_id',
            'description',
            'tableau_workbook_name',
            'tableau_view_name',
            'tableau_site_id',
            'iframe_width',
            'iframe_height',
            'organization_id',
            'status'
        ];

        const fields = prepareSelectParam(paramFields);

        const url = `${API}/custom-dashboard/${customDashboardId}?fields=${fields}`;
        const headers = {
            Authorization: store.token
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(response => {
                    if (response.data.status === 200) {
                        resolve(response.data.data);
                    } else {
                        reject({});
                    }
                }).catch(err => reject(err))
        })
    },

    getCustomDashboards: (strName = '') => {
        const arrFields = ['id', 'name'];
        const queryFields = prepareSelectParam(arrFields);

        let queryFilters = "";
        if (strName !== '') {
            queryFilters = prepareFilterParam([
                { key: 'name', value: [strName], op: ALLOWED_QUERY_OPERATOR.substring }
            ]);
        }

        const url = `${API}/custom-dashboard?fields=${queryFields}&filters=${queryFilters}`;

        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(err => reject(err));
        })
    },
}

export default customDashboardApi;

