import { toast } from 'react-toastify';

toast.configure();

export default {
    success: (msg) => {
        return toast.success(msg, {
            className: 'toastSuccessStyle',
            progressClassName: 'toastSuccessProgressBar'
        })
    },
    
    error: (msg) => {
        return toast.error(msg, {
            className: 'toastErrorStyle',
            progressClassName: 'toastErrorProgressBar'
        })
    }
}