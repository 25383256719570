import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react'
import store from '../../store'
import { SUBSCRIPTIONS_TYPES_COLUMN_HEADERS, API, ALLOWED_QUERY_OPERATOR } from '../../constant'
import { prepareSelectParam } from '../../utility/queryParams'
import SideNav from '../SideNav'
import ActionRenderer from '../reusable/ActionRenderer'
import Parent from '../Parent'
import Modal from '../reusable/Modal'
import Sort from '../reusable/Sort'
import Pagination from '../reusable/Pagination'
import Home from '../Home'

class SubscriptionTypes extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: SUBSCRIPTIONS_TYPES_COLUMN_HEADERS,
            context: { componentParent: this },
            frameworkComponents: { actionRenderer: ActionRenderer },

            name: '',
            showName: false,
            type: '',
            filters: {
                name: []
            },
            filterFields: [
                { 'key': 'name', 'op': ALLOWED_QUERY_OPERATOR.substring, 'value': [] },
            ]
        }
        store.sortType = this.props.location.pathname.split('/')[1]
    }

    onGridReady(params) {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    componentDidMount() {
        document.addEventListener('mousedown', (e) => {
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showName: false }) }
        })

        const paramFields = ['id', 'name', 'active', 'description'];
        store.fields = prepareSelectParam(paramFields);
        
        this.fetchListData('subscription-type', store.fields);

    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => {
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showName: false }) }
        })
    }


    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>SUBSCRIPTIONS TYPES</h3>
                            <div className='filter-container'>

                                <div className="col-md-12 col-sm-12 col-xs-12 filter-firstline filter-spacing" >
                                    <div ref={node => this.word = node} style={{ paddingLeft: 0 }} class="col-md-12 col-sm-12 col-xs-12">
                                        <div>
                                            <div>
                                                <input ref={node => this.input = node}
                                                    className='filter-input input-border'
                                                    style={{width: '100%'}}
                                                    placeholder="Search by name"
                                                    value={this.state.name}
                                                    onFocus={() => this.setState({ showName: true })}
                                                    onKeyUp={(event) => this.handleKeywordEnter(event, 'name')}
                                                    onChange={e => this.setState({ name: e.target.value })} />
                                                {this.state.showName && this.state.filters.name.length > 0 ?
                                                    <div className='filter-dropdown' style={{ width: '100%' }}>
                                                        <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                            {this.state.filters.name.map((word, i) => {
                                                                return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'name')}>
                                                                    {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                                </div>
                                                            })}
                                                        </div>
                                                    </div> : null}
                                            </div>
                                        </div>
                                        {this.state.name.length > 0 &&
                                            <i className="fa fa-times "
                                                style={{ paddingRight: 10 }}
                                                onClick={() => {
                                                    this.input.focus()
                                                    this.setState({ name: '' })
                                                }}>
                                            </i>
                                        }
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-12 filter-searchbtn" onClick={() => this.handleClearAllFilters('subscription-type')}>Clear All</a>
                                    </div>
                                </div>

                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    frameworkComponents={this.state.frameworkComponents}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                >
                                </AgGridReact>
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}


export default observer(SubscriptionTypes)
