import axios from 'axios';

import { API } from '../constant';

const jobApi = {

    getJobType: () => {
        const url = `${API}/taxonomy-type/46/taxonomy?fields=id^,name&limit=100`
        
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then(response => {
                    if (response.data.status === 200) {
                        resolve(response.data.data)
                    } else {
                        reject({})
                    }
                }).catch(err => reject(err))
        })
    },

}

export default jobApi;