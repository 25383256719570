import axios from 'axios';

import store from '../store';
import { PRODUCT_API } from '../constant';
import { prepareSelectParam } from '../utility/queryParams';

const apiClientApi = {

    getApiClientDetails: (intApiClientId = '') => {
        const paramFields = ['id', 'name', 'status', 'user_id', 'user_data', 'organization_id', 'organization_data', 'client_id', 'client_ip'];
        const fields = prepareSelectParam(paramFields);

        const url = `${PRODUCT_API}/api-clients/${intApiClientId}/?fields=${fields}`;
        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(
                    response => {
                        resolve(response.data.data);
                    }
                )
                .catch((err) => reject(err));
        });
    }

}

export default apiClientApi;