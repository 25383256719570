import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { POLICY_PERIODS, TAXONOMIES } from '../../constant'
// import {toJS} from 'mobx';
import store from '../../store'
import { getTaxonomyData } from '../../utility/commonApis'

class PolicyViewModal extends Component {
    constructor() {
        super();
        this.state = {
            researchCategories: []
        };
    }

    handleCloseModal = () => {
        $('#policyViewModal').modal('hide');
    }

    componentDidMount() {
        getTaxonomyData(TAXONOMIES.researchCategory).then(res => {
            this.setState({ researchCategories: res });
        });
    }

    renderData = () => {
        let tableRows = [];
        let content = <div style={{textAlign: 'center'}}>No policy to show</div>
        
        if (store.policyViewModalData && Array.isArray(store.policyViewModalData.policy) && store.policyViewModalData.policy.length > 0) {
            store.policyViewModalData.policy.map((item, index) => {

                let period      = 'N/A';
                let category    = 'N/A';

                if (item.period) {
                    const filteredPeriods = POLICY_PERIODS.filter(periodItem => periodItem.value === item.period);
                    if (filteredPeriods.length > 0) {
                        period = filteredPeriods[0].label;
                    }                        
                }

                if (item.category_id) {
                    const filteredCategories = this.state.researchCategories.filter(categoryItem => categoryItem.value === item.category_id);
                    if (filteredCategories.length > 0) {
                        category = filteredCategories[0].label;
                    }
                }

                tableRows.push(
                    <tr key={index}>
                        <td>{item.limit}</td>
                        <td>{period}</td>
                        <td>{item.weight}</td>
                        <td>{category}</td>
                    </tr>
                );
            });
    
        }

        if (tableRows.length > 0) {
            content = <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Limit</th>
                        <th scope="col">Period</th>
                        <th scope="col">Weight</th>
                        <th scope="col">Category</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </table>
        }

        return content;
    }

    render() {
        return (
            <div className="modal fade" id="policyViewModal">
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">Policy</h4>
                            <button type="button" className="close" onClick={this.handleCloseModal}>&times;</button>
                        </div>

                        <div className="modal-body">
                            {this.renderData()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default observer(PolicyViewModal)
