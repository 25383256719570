import axios from 'axios';
import { prepareFilterParam, prepareSelectParam } from '../utility/queryParams';
import { ALLOWED_QUERY_OPERATOR, API } from '../constant';
import store from '../store';

const academicDegreeApi = {
    getDegreeSuggestions: (name = '') => {
        const fields = prepareSelectParam(['id', 'name']);

        const filters = prepareFilterParam([
            {
                key: 'name',
                value: [name],
                op: ALLOWED_QUERY_OPERATOR.substring
            },
            {
                key: 'status',
                value: [1],
                op: ALLOWED_QUERY_OPERATOR.equal
            },
            {
                key: 'is_deleted',
                value: [0],
                op: ALLOWED_QUERY_OPERATOR.equal
            }
        ]);

        return new Promise((resolve, reject) => {
            let url = `${API}/academic-degree/?fields=${fields}&filters=${filters}&limit=10`

            const headers = {
                Authorization: store.token
            };
            axios.get(url, { headers })
                .then(response => {
                    if (response.data.status === 200) {
                        resolve(response.data.data)
                    } else {
                        reject([])
                    }
                }).catch(err => reject(err))
        });
    }
}

export default academicDegreeApi;
