import React from 'react'
import { observer } from 'mobx-react'
import Select from 'react-select'
import CKEditor from "ckeditor4-react"
import Modal from '../reusable/Modal'
import store from '../../store'
import SideNav from '../SideNav'
import FormParent from '../FormParent'
import Home from '../Home'
import { prepareSelectParam } from '../../utility/queryParams'
// import Modal from '../reusable/Modal';
import {
    SELECT_STYLES,
    SELECT_STYLES_ERROR,
    SELECT_THEME_PRIMARY,
    TOOLBAR,
    BROWSE_SERVER,
    UPLOAD_SERVER,
    API
} from "../../constant";

const arActive = [
    {value: 1, label: 'Yes'},
    {value: 0, label: 'No'}
]

class EmailTemplateForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                name: '',
                active: '',
                subject: '',
                description: '',
                html_part: '',
                text_part: ''
            },
            validationFields: ['name', 'active', 'subject', 'html_part'],
            editParams: [],
            active: [
                { value: 1, label: 'Yes' },
                { value: 0, label: 'No' }
            ]
        }

        this.parameters = {
            name: '',
            active: '',
            subject: '',
            description: '',
            html_part: '',
            text_part: ''
        }
    }

    componentDidMount() {
        if (store.isEdit && store.currentId) {
            let paramFields = ['id', 'name', 'active', 'subject', 'code', 'description', 'html_part']
            let fields = prepareSelectParam(paramFields)

            fetch(`${API}/email-template/${store.currentId}/?fields=${fields}`, {
                method: 'GET',
                headers: {
                    'Authorization': store.token,
                    'Content-Type': 'application/json'
                },
            }).then(response => {
                return response.json()
            }).then(data => {
                if (!data.data.description) {
                    data.data.description = '';
                }

                if (data.data.active == 1) {
                    data.data.active = { value: 1, label: 'Yes' }
                }
                else {
                    data.data.active = { value: 0, label: 'No' }
                }

                this.setState({ formFields: data.data })
            }).catch(err => {
                console.log(err)
            })
        }
    }

    renderErrors = () => {
        let {
            activeEmpty,
            subjectEmpty,
            html_partEmpty,
            nameEmpty
        } = this.state

        let errors = []

        if (nameEmpty)
            errors.push(<div className='error'>Name cannot be empty</div>)
        if (activeEmpty)
            errors.push(<div className='error'>Active type cannot be empty</div>)
        if (subjectEmpty)
            errors.push(<div className='error'>Subject cannot be empty</div>)
        if (html_partEmpty)
            errors.push(<div className='error'>Email template html body cannot be empty</div>)

        return errors
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>
                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT EMAIL TEMPLATE</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD EMAIL TEMPLATE</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>
                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-6 form-group">
                                            <label >Name<span className='required'>*</span></label>
                                            <input type="text"
                                                className={this.state.nameEmpty ? "form-control input-error" : "form-control"}
                                                value={this.state.formFields.name}
                                                onChange={this.handleInputChange}
                                                name='name' />
                                            {this.state.nameEmpty && <div className='error'>Name cannot be empty</div>}
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-6 form-group">
                                            <label >Active<span className='required'>*</span></label>
                                            <Select
                                                name='active'
                                                placeholder='Select Active Option'
                                                styles={this.state.activeEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.active}
                                                onChange={(option) => this.handleSelectChange(option, 'active')}
                                                options={this.state.active}
                                            />
                                            {this.state.activeEmpty && <div className='error'>Active type cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Subject<span className='required'>*</span></label>
                                            <input type="text"
                                                className={this.state.subjectEmpty ? "form-control input-error" : "form-control"}
                                                value={this.state.formFields.subject}
                                                onChange={this.handleInputChange}
                                                name='subject' />
                                            {this.state.subjectEmpty && <div className='error'>Subject cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Description</label>
                                            <input type="text"
                                                className="form-control"
                                                value={this.state.formFields.description}
                                                onChange={this.handleInputChange}
                                                name='description' />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >HTML Body <span className='required'>*</span></label>
                                            <CKEditor
                                                name='html_partEmpty'
                                                onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
                                                onInit={(editor) => console.log(editor)}
                                                config={{
                                                    height: 400,
                                                    allowedContent: true,
                                                    toolbar: TOOLBAR,
                                                    filebrowserImageUploadUrl: `${UPLOAD_SERVER}?token=${store.token}`
                                                }}
                                                data={this.state.formFields.html_part}
                                                onChange={(ev) => this.handleRichEditorInputChange(ev, 'html_part')}
                                            />
                                            {this.state.html_partEmpty && <div className='error'>Email template html body cannot be empty</div>}
                                        </div>
                                    </div>

                                    {this.renderErrors()}

                                    <div className='row'>
                                        <div className='bottom-btn-wrapper'>
                                            {store.isEdit ? <button className="btn-primary bottom-btn" disabled={this.state.editParams.length > 0 || store.apis.length > 0 ? false : true} onClick={() => this.handleUpdate('email-template')}>Save</button> :
                                                <div>
                                                    <button className="btn-primary bottom-btn" onClick={() => this.handleAdd('email-template')}>Add Email Template</button>
                                                    <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    {store.isSideNav && <div className='overlay'> </div>}

                                    <Modal />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default observer(EmailTemplateForm)