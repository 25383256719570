import React, { Component } from 'react'

class ErrorPopup extends Component {
    render() {
        return (
            <div>
                <div className="modal fade" id="errorModal">
                    <div className="modal-dialog">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h4 className="modal-title">ERROR</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>

                            <div className="modal-body" style={{ background: '#e5e6e8' }}>
                                <div  style={{ textAlign: 'center' }}>
                                    <i className='fas fa-exclamation-triangle mb-2' style={{ fontSize: 40, color: '#d11348' }} />
                                    <h3>FAILED!</h3>
                                    {
                                        this.props.errorMessage ? 
                                            <p>{this.props.errorMessage}</p>
                                            :
                                            <p>Oops! something went wrong. Give another try.</p>
                                    }
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">Close</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ErrorPopup