import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react';
import Select from 'react-select';

// constants
import { DATA_EXPORT_PRODUCT_COLUMN_HEADERS, ALLOWED_QUERY_OPERATOR, SELECT_STYLES, SELECT_THEME_PRIMARY } from '../../constant'
import { API_PATH } from '../../utility/constants/apiPath';

// helpers
import store from '../../store';
import { prepareSelectParam } from '../../utility/queryParams';

// apis
import dataExportApi from '../../apis/dataExportApi';

// components
import Parent from '../Parent';
import Home from '../Home';
import SideNav from '../SideNav';
import ActionRenderer from '../reusable/ActionRenderer';
import Sort from '../reusable/Sort';
import Pagination from '../reusable/Pagination';
import Modal from '../reusable/Modal';

class DataExportProducts extends Parent {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: DATA_EXPORT_PRODUCT_COLUMN_HEADERS,
            context: { componentParent: this },
            frameworkComponents: { actionRenderer: ActionRenderer },

            dataSetTypes: [],
            data_set_type: '',
            filters: {
                data_set_type: []
            },
            filterFields: [
                { key: 'data_set_type', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },
            ]
        };

        store.sortType = this.props.location.pathname.split('/')[1];
    }

    onGridReady(params) {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    async componentDidMount() {
        await this.getDataExportProducts();

        const paramFields = ['id', 'data_set_type', 'active', 'description'];
        store.fields = prepareSelectParam(paramFields);

        this.fetchListData(API_PATH.dataExportProduct, store.fields);
    }

    getDataExportProducts = async () => {
        dataExportApi.getProducts().then(
            response => {
                const dataSetTypes = response.map(type => {
                    return { value: type.data_set_type, label: type.data_set_type };
                })

                this.setState({ dataSetTypes });
            }
        );
    }

    clearFilters = () => {
        this.setState({
            data_set_type: ''
        }, () => this.handleClearAllFilters(API_PATH.dataExportProduct));
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>DATA EXPORT PRODUCTS</h3>
                            <div className='filter-container'>

                                <div className="filter-row" >
                                    <div className="col-md-12 col-sm-12 col-xs-12" style={{ paddingLeft: 0 }}>
                                        <Select
                                            placeholder='Filter by data set type'
                                            styles={SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            value={this.state.data_set_type}
                                            onChange={(option) => this.handleSingleSelectFilter(option, 'data_set_type')}
                                            options={this.state.dataSetTypes}
                                        />
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-12 filter-searchbtn" onClick={this.clearFilters}>Clear All</a>
                                    </div>
                                </div>

                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    frameworkComponents={this.state.frameworkComponents}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                />
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}


export default observer(DataExportProducts)
