import { API, PRODUCT_API } from "../constant"
import store from '../store'

export default class APIReq {
    /**
     * Method to make API request
     * @note Method can be written better but due to migration complexity its limited for now.
     * @param {URL}     url         Base url and query param,should start with no slash.
     * @param {String}  method      HTTP method
     * @param {Object}  headers     Object of headers, it can contain auth token, content-type and re-fresh token.
     * @param {Object}  options     Object of options, it can contain data or any other params.
     * @param {Object}              Object of API response.
     */
    static async request(url = null, method = 'GET', headers = {}, options = {}) {
        if (!url) {
            return;
        }

        headers['Authorization'] = store.token;

        let fetchOptions = {
            method: method,
            headers: headers
        }

        if (options && options.data) {
            fetchOptions.body = options.data
        }

        let apiUrl = API
        if (/^api-clients/g.test(url)) {
            apiUrl = PRODUCT_API;
        }

        try {
            // We can handle global error here
            let response        = await fetch(`${apiUrl}/${url}`, fetchOptions)
            return await response.json()
        } catch (error) {
            return;
        }
    }
}