import React, { Component } from 'react';
import { observer } from 'mobx-react';

// constants
import { API_PRODUCT_PERIOD } from '../../../constant';

// helpers
import store from '../../../store';

// apis
import roleApi from '../../../apis/roleApi';

class ViewPolicyModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roles: {}
        }
    }

    async componentDidMount() {
        await this.getRoles();
    }

    getRoles = async () => {
        roleApi.getRoles().then(
            response => {
                const objTemp = {};
                response.map(role => {
                    objTemp[role.id] = role.name;
                });

                this.setState({
                    roles: objTemp
                })
            });
    }

    prepareQuotaInfo = (arrQuota = []) => {
        if (arrQuota.length === 0) {
            return 'No data';
        }

        const quotaListItems = arrQuota.map(item => {
            return (
                <li>
                    <b>{item.resource.charAt(0).toUpperCase() + item.resource.slice(1)}</b>
                    <ul>
                        <li className="pl-4">Limit: <b>{item.limit}</b> records</li>
                        <li className="pl-4">Period: <b>{API_PRODUCT_PERIOD[item.period]}</b></li>
                    </ul>
                </li>
            );
        });

        return quotaListItems;
    }

    prepareRolesInfo = (arrRoles = []) => {
        if (arrRoles.length === 0) {
            return 'No data';
        }

        const objRoles = { ...this.state.roles };

        const arrTemp = arrRoles.map(role => {
            return objRoles[role];
        });

        if (arrTemp.length === 0) {
            return 'No data';
        }

        const rolesListItems = arrTemp.map(role => {
            return (
                <li>
                    - {role}
                </li>
            );
        })

        return rolesListItems;
    }

    handleCloseModal = () => {
        $('#apiProductPolicyModal').modal('hide');
    }

    renderData = () => {
        let content = <div style={{ textAlign: 'center' }}>No policy to show</div>

        if (store.apiProductPolicyModalData && store.apiProductPolicyModalData.policy) {
            const policy = { ...store.apiProductPolicyModalData.policy };
            const quotaListItems = this.prepareQuotaInfo(policy.quota);
            const rolesListItems = this.prepareRolesInfo(policy.roles);

            let strPolicyType = "";
            if (policy.is_general_policy) {
                strPolicyType = "General policy";
            } else {
                strPolicyType = "Resource based policy";
            }

            content = (
                <table className="table table-striped">
                    <tbody>
                        <tr>
                            <th scope="col">Type</th>
                            <td>
                                {strPolicyType}
                            </td>
                        </tr>
                        <tr>
                            <th scope="col">Quota</th>
                            <td>
                                <ul>
                                    {quotaListItems}
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th scope="col">Roles</th>
                            <td>
                                <ul>
                                    {rolesListItems}
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            );
        }

        return content;
    }

    render() {
        return (
            <div className="modal fade" id="apiProductPolicyModal">
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">API Product Policy</h4>
                            <button type="button" className="close" onClick={this.handleCloseModal}>&times;</button>
                        </div>

                        <div className="modal-body">
                            {this.renderData()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default observer(ViewPolicyModal);
