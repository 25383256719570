import store from "../store";

export default class RBAC {
    /**
     * Method to check user access
     * 
     * @note this method will not check policy and weight.
     * @param   {String} type       Type resource
     * @param   {String} method     API method
     * @param   {String} operation  API Operation
     * @return  {Boolean}           Returns boolean value.
     */
    static hasAccess(type, method, operation = ':param') {
        const arPermissions = store.permissions[`${type}-${method}`] ? [...store.permissions[`${type}-${method}`]] : null;
        let hasAccess       = false

        if (!arPermissions) {
            hasAccess = false
        } else if (arPermissions.length > 0) {
            for (let i = 0; i < arPermissions.length; i++) {
                if (arPermissions[i].operation === operation) {
                    hasAccess = true
                    break;
                } else {
                    hasAccess = false
                }
            }
        } else {
            hasAccess = false
        }

        return hasAccess
    }
}