import React from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';

// constants
import { SELECT_STYLES, SELECT_THEME_PRIMARY, USER_STATUS, API, GENDERS, GENERAL_SHORT_ERROR_MSG } from '../../constant';

// helpers
import store from '../../store';
import { prepareSelectParam } from '../../utility/queryParams';
import { isObjectEmpty } from '../../utility/helpers';

// components
import Home from '../Home';
import FormParent from '../FormParent';
import SideNav from '../SideNav';
import Modal from '../reusable/Modal';
import ErrorPopup from '../reusable/ErrorModal';
import Toast from '../reusable/Toast';

class UserForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                first_name: '',
                last_name: '',
                username: '',
                password: '',
                re_password: '',
                profile_pic: '',
                email: '',
                status: '',
                gender: '',
                linkedin: '',
                facebook: '',
                twitter: '',
                skype: '',
                phone: '',
                occupation_description: '',
                startup_experience: '',
                current_job_title: '',
            },
            email: '',
            emails: [],
            profile_picMsg: '',
            profile_pic: '',
            profile_picFile: '',
            editprofile_pic: '',
            editParams: [],
            fileFields: ['profile_pic'],
            socialFields: ['linkedin', 'facebook', 'twitter'],
            validationFields: ['username', 'email', 'first_name', 'last_name', 'password', 're_password'],
        }
        this.parameters = {
            first_name: '',
            last_name: '',
            username: '',
            password: '',
            re_password: '',
            profile_pic: '',
            email: '',
            status: '',
            gender: '',
            linkedin: '',
            facebook: '',
            twitter: '',
            skype: '',
            phone: '',
            occupation_description: '',
            startup_experience: '',
            current_job_title: '',
        }
        /*this.email = {
            email: ''
        }*/
    }

    componentDidMount() {
        if (store.isEdit && store.currentId) {
            this.setState({ validationFields: ['first_name', 'last_name', 'username'] })
            let paramFields = ['id', 'first_name', 'last_name', 'username', 'password', 're_password', 'email_data', 'profile_pic_data', 'phone', 'linkedin', 'facebook', 'twitter', 'skype', 'occupation_description', 'startup_experience', 'current_job_title', 'status', 'gender']
            let fields = prepareSelectParam(paramFields)

            fetch(`${API}/user/${store.currentId}/?fields=${fields}`, {
                method: 'GET',
                headers: {
                    'Authorization': store.token,
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    if (data.data.email_data !== null) {
                        this.setState({ emails: data.data.email_data })
                    }
                    let pic
                    let obj = {}
                    Object.keys(this.state.formFields).map(key => {
                        if (key === 'profile_pic') {
                            obj[key] = data.data.profile_pic_data !== null ? data.data.profile_pic_data.name : ''
                        }
                        else if (key === 'status') {
                            obj[key] = data.data.status === 0 ? { 'value': data.data.status, 'label': 'Invited' } :
                                data.data.status === 1 ? { 'value': data.data.status, 'label': 'Active' } :
                                    data.data.status === 2 ? { 'value': data.data.status, 'label': 'Suspended' } : ''
                        } else if ((key === "re_password") && data.data.password) {
                            obj['re_password'] = data.data.password;
                        } else if (key === 'gender' && data.data.gender) {
                            obj[key] = { 'value': data.data.gender, 'label': data.data.gender }
                        }
                        else {
                            obj[key] = data.data[key] !== null ? data.data[key] : ''
                        }
                    })

                    this.setState({ formFields: obj })
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    handleEmailEnter = () => {
        if (event.keyCode === 13 && this.state.email !== '') {
            this.handleAddEmail()
        }
    }

    handleAddEmail = async () => {
        if (this.state.email) {
            if (this.handleEmailValidation('email')) {
                const body = {
                    email: this.state.email,
                    is_primary: this.state.isPrimaryEmail ? 1 : 0
                };

                const result = await this.saveEmail(`user/${store.currentId}/email`, body);

                if (result.status === 201) {
                    let arr                 = this.state.emails;
                    const isPrimaryEmail    = result.data.is_primary == 1 ? true : false;
                    
                    arr.unshift({ id: result.data.id, email: result.data.email, is_primary: isPrimaryEmail });
                    this.setState({ emails: arr, email: '', emailSuccess: true, isPrimaryEmail: false });
                }
                else {
                    this.setState({ emailSuccess: false, isPrimaryEmail: false }, () => {
                        let errorMessage = GENERAL_SHORT_ERROR_MSG;

                        if (result && !isObjectEmpty(result.errors) && result.errors[0].message) {
                            errorMessage = result.errors[0].message + ", please try again.";
                        }

                        Toast.error(errorMessage);
                    });
                }
            }
            else {
                this.setState({ emailInvalid: true })
            }
        }
    }

    async handleRemoveEmail(obj, index) {
        let result = await this.deleteEmail(`user/${store.currentId}/email/${obj.id}`)

        if (result.status === 200) {
            let arr = this.state.emails
            arr.splice(index, 1)
            this.setState({ emails: arr })
        }
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT USER</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD USER</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >User Name <span className='required'>*</span></label>
                                            <input type="text"
                                                className={this.state.usernameEmpty ? "form-control input-error" : "form-control"}
                                                value={this.state.formFields.username}
                                                name='username'
                                                onChange={this.handleInputChange} />
                                            {this.state.usernameEmpty && <div className='error'>User name cannot be empty</div>}
                                        </div>
                                    </div>

                                    {store.isEdit ? <div className='row'>
                                        <div className="col-md-10 col-sm-10 col-xs-10 form-group">
                                            <label >Email<span className='required'>*</span></label>
                                            <div className="input-group mb-3">
                                                <input type="text"
                                                    name='email'
                                                    className={this.state.emailEmpty ? "form-control input-error" : "form-control"}
                                                    value={this.state.email}
                                                    onKeyUp={this.handleEmailEnter}
                                                    onChange={(ev) => this.setState({ email: ev.target.value, emailSuccess: false })} />
                                                <div className="input-group-append">
                                                    <span className="input-group-text" id="" onClick={this.handleAddEmail}>Upload more</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 col-sm-2 col-xs-2 form-group" style={{ paddingTop: 35 }}>
                                            <div>
                                                <input title='checkbox'
                                                    name='comp_min'
                                                    type="checkbox"
                                                    checked={this.state.isPrimaryEmail}
                                                    onChange={() => this.setState({ isPrimaryEmail: !this.state.isPrimaryEmail })} />
                                                <span><strong>Is Primary?</strong></span>
                                            </div>
                                        </div>

                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            {this.state.emails.length > 0 &&
                                                <div>
                                                    {this.state.emails.map((item, i) => {
                                                        return (
                                                            <div key={item.id} className="email-holder">
                                                                <div>{item.email}</div>
                                                                {item.is_primary ? <div>Primary email</div> : <div>Non-primary</div>}
                                                                <i className='fas fa-times' style={{ cursor: 'pointer' }} onClick={() => this.handleRemoveEmail(item, i)}></i>
                                                            </div>
                                                        )
                                                    })}

                                                </div>}
                                            {this.state.emailEmpty && <div className='error'>Email cannot be empty</div>}
                                            {this.state.emailInvalid && <div className='error'>Email is invalid</div>}
                                            {this.state.emailSuccess && <div className='success'>Email is uploaded</div>}
                                        </div>
                                    </div> :
                                        <div className='row'>
                                            <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                                <label >Email <span className='required'>*</span></label>
                                                <input type="text"
                                                    className={this.state.emailEmpty ? "form-control input-error" : "form-control"}
                                                    value={this.state.formFields.email}
                                                    name='email'
                                                    onChange={this.handleInputChange} />
                                                {this.state.emailEmpty && <div className='error'>Email cannot be empty</div>}
                                                {this.state.emailInvalid && <div className='error'>Email is invalid</div>}
                                            </div>
                                        </div>
                                    }

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >First Name <span className='required'>*</span></label>
                                            <input type="text"
                                                className={this.state.first_nameEmpty ? "form-control input-error" : "form-control"}
                                                value={this.state.formFields.first_name}
                                                name='first_name'
                                                onChange={this.handleInputChange} />
                                            {this.state.first_nameEmpty && <div className='error'>First name cannot be empty</div>}
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Last Name <span className='required'>*</span></label>
                                            <input type="text"
                                                className={this.state.last_nameEmpty ? "form-control input-error" : "form-control"}
                                                value={this.state.formFields.last_name}
                                                name='last_name'
                                                onChange={this.handleInputChange} />
                                            {this.state.last_nameEmpty && <div className='error'>Last name cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Password <span className='required'>{!store.isEdit && '*'}</span></label>
                                            <input type="password"
                                                disabled={(store.isEdit && !this.state.formFields.password && !this.state.editParams.includes('password')) ? true : false}
                                                className={this.state.passwordEmpty ? "form-control input-error" : "form-control"}
                                                value={this.state.formFields.password}
                                                name='password'
                                                onChange={this.handleInputChange} />
                                            {this.state.passwordEmpty && <div className='error'>Password cannot be empty</div>}
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Confirm Password <span className='required'>{!store.isEdit && '*'}</span> </label>
                                            <input type="password"
                                                disabled={(store.isEdit && !this.state.formFields.re_password && !this.state.editParams.includes('password')) ? true : false}
                                                className={this.state.re_passwordEmpty || this.state.passwordError ? "form-control input-error" : "form-control"}
                                                value={this.state.formFields.re_password}
                                                name='re_password'
                                                onChange={(e) => {
                                                    this.handleInputChange(e);
                                                    this.handlePasswordValidation();
                                                }} />
                                            {this.state.re_passwordEmpty && <div className='error'>Confirm password cannot be empty</div>}
                                            {this.state.passwordError && <div className='error'>Confirm password should be same as password</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Linkedin URL</label>
                                            <input className={this.state.linkedinSocial ? "form-control input-error " : "form-control"}
                                                name='linkedin'
                                                value={this.state.formFields.linkedin}
                                                onChange={this.handleInputChange} />
                                            {this.state.linkedinSocial && <div className='error'>Invalid url</div>}
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Facebook URL</label>
                                            <input className={this.state.facebookSocial ? "form-control input-error " : "form-control"}
                                                name='facebook'
                                                value={this.state.formFields.facebook}
                                                onChange={this.handleInputChange} />
                                            {this.state.facebookSocial && <div className='error'>Invalid url</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Twitter URL</label>
                                            <input className={this.state.twitterSocial ? "form-control input-error " : "form-control"}
                                                name='twitter'
                                                value={this.state.formFields.twitter}
                                                onChange={this.handleInputChange} />
                                            {this.state.twitterSocial && <div className='error'>Invalid url</div>}
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Skype</label>
                                            <input className="form-control" value={this.state.formFields.skype} name='skype' onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Phone</label>
                                            <input title='number'
                                                className={this.state.phoneNumber ? "form-control input-error" : "form-control"}
                                                value={this.state.formFields.phone}
                                                name='phone'
                                                onChange={this.handleInputChange} />
                                            {this.state.phoneNumber && <div className='error'>Phone number should be number</div>}
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Status</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.status}
                                                onChange={(option) => this.handleSelectChange(option, 'status')}
                                                options={USER_STATUS}
                                            />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Profile Picture</label>
                                            <div className="input-group mb-3">
                                                <div className="custom-file">
                                                    <div className="form-filename-holder">{this.state.formFields.profile_pic === '' ? 'Choose a File' : this.state.formFields.profile_pic}</div>
                                                    <input type='file' className="custom-file-input" name='profile_pic' onChange={this.handleImageUpload} />
                                                </div>
                                                <div className="input-group-append">
                                                    <span className="input-group-text" onClick={() => this.addFileUpload('profile-pic', 'profile_pic', this.state.profile_picFile)} >Upload</span>
                                                </div>
                                            </div>
                                            {this.state.profile_picMsg.length > 0 && <div className='error'>{this.state.profile_picMsg}</div>}
                                            {this.state.profile_picError && <div className='error'>Upload failed!.</div>}
                                            {this.state.profile_picSuccess && <div className='success'>Upload successful!</div>}
                                            {this.state.profile_picUpload && <div className='error'>File is chosen but not uploaded</div>}
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Gender</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.gender}
                                                onChange={(option) => this.handleSelectChange(option, 'gender')}
                                                options={GENDERS}
                                            />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Current Job</label>
                                            <input className="form-control" value={this.state.formFields.current_job_title} name='current_job_title' onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Occupation</label>
                                            <textarea rows='8' className="form-control" value={this.state.formFields.occupation_description} name='occupation_description' onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Startup Experience</label>
                                            <textarea rows='8' className="form-control" value={this.state.formFields.startup_experience} name='startup_experience' onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ? <button className="btn-primary bottom-btn" disabled={this.state.editParams.length > 0 || store.apis.length > 0 ? false : true} onClick={() => this.handleUpdate('user')}>Save</button> :
                                    <div>
                                        <button className="btn-primary bottom-btn" onClick={() => this.handleAdd('user')}>Add User</button>
                                        <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                                    </div>
                                }
                            </div>

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <ErrorPopup errorMessage={this.state.errorMessage}/>
                        <Modal />
                    </div>
                }
            </div>
        )
    }
}
export default observer(UserForm)
