import React, { Component } from 'react'

class NoMatch extends Component {
    render() {
        return (
            <div>
                <h1>Four Oh Four</h1>
            </div>
        )
    }

}
export default NoMatch