import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

// constants
import { ALLOWED_QUERY_OPERATOR, SELECT_STYLES, SELECT_THEME_PRIMARY, OPTIONS, TAXONOMIES } from '../../constant';
import { API_PATH } from '../../utility/constants/apiPath';
import { SALES_AUTOMATION_EMAIL_HEADERS } from '../../utility/constants/tableHeaders';

// helpers
import store from '../../store';
import { prepareSelectParam } from '../../utility/queryParams';

// apis
import emailTemplateApi from '../../apis/emailTemplateApi';
import taxonomyApi from '../../apis/taxonomyApi';

// components
import Parent from '../Parent';
import Home from '../Home';
import SideNav from '../SideNav';
import ActionRenderer from '../reusable/ActionRenderer';
import Sort from '../reusable/Sort';
import Pagination from '../reusable/Pagination';
import Modal from '../reusable/Modal';

class salesAutomationEmail extends Parent {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: SALES_AUTOMATION_EMAIL_HEADERS,
            context: { componentParent: this },
            frameworkComponents: { actionRenderer: ActionRenderer },
            defaultColDef: { suppressMovable: true },

            //filter values
            taxonomy_id: '',
            email_template_id: '',
            taxonomy_type_filter: '',
            active: '',

            // filter options
            taxonomy: [],
            emailTemplates: [],
            taxonomyTypes: [
                { label: `Contact Sales - Sales Options ${TAXONOMIES.salesContactOptions}`, value: TAXONOMIES.salesContactOptions },
                { label: `Contact Sales - Demo Options ${TAXONOMIES.demoContactOptions}`, value: TAXONOMIES.demoContactOptions },
                { label: `Demo Widget  ${TAXONOMIES.demoWidgetType}`, value: TAXONOMIES.demoWidgetType }
            ],

            filters: {
                taxonomy_id: [],
                email_template_id: [],
                taxonomy_type_filter: [],
                active: [],

            },
            filterFields: [
                { key: 'taxonomy_id', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },
                { key: 'taxonomy_type_filter', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },
                { key: 'email_template_id', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },
                { key: 'active', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },
            ]
        };

        store.sortType = this.props.location.pathname.split('/')[1];
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
    }

    componentDidMount() {

        // sales automation fields
        const paramFields = [
            'id',
            'active',
            'taxonomy_id',
            'taxonomy_name',
            'taxonomy_type',
            'taxonomy_type_name',
            'email_template_id',
            'email_template_name',
            'email_template_subject'
        ];

        store.fields = prepareSelectParam(paramFields);

        this.fetchListData(API_PATH.salesAutomationEmail, store.fields);
    }

    clearFilters = () => {
        this.setState({
            taxonomy_id: '',
            email_template_id: '',
            taxonomy_type_filter: '',
            active: '',
        }, () => this.handleClearAllFilters(API_PATH.salesAutomationEmail));
    };

    getOptionsData = async (filterOptionName, onFocus, option = "", callback) => {

        if (option && (option.length < 2 && !onFocus)) {
            return;
        }

        let data = []
        let mappedData = []

        if (filterOptionName === "taxonomy") {

            for (const taxonomy of this.state.taxonomyTypes) {

                const responseData = await taxonomyApi.getTaxonomyByType(taxonomy.value, option)
                data.push(...responseData);
            }

        } else {
            data = await emailTemplateApi.getEmailTemplate(option);
        }

        mappedData = data.map((item) => {
            return {
                value: item.id,
                label: `${item.name} [${item.id}]`,
            }
        })

        this.setState({ [filterOptionName]: mappedData })

        if (callback) {
            callback(mappedData);
        }
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>SALES AUTOMATION EMAIL</h3>
                            <div className='filter-container'>

                                <div className="filter-row" style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className="col-md-6 col-sm-6 col-xs-12 " style={{ paddingLeft: 0 }}>
                                        <div className='filter-firstline'>
                                            <div style={{ width: '100%' }}>
                                                <AsyncSelect
                                                    name='id'
                                                    placeholder='Filter by Taxonomy Name'
                                                    styles={SELECT_STYLES}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: SELECT_THEME_PRIMARY
                                                        }
                                                    })}
                                                    value={this.state.taxonomy_id}
                                                    defaultOptions={this.state.taxonomy}
                                                    loadOptions={(value, callback) => this.getOptionsData("taxonomy", false, value, callback)}
                                                    onChange={(option) => this.handleSingleSelectFilter(option, 'taxonomy_id')}
                                                    onFocus={() => { this.getOptionsData("taxonomy", true) }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12 " style={{ padding: 0 }}>
                                        <div className='filter-firstline'>
                                            <div style={{ width: '100%' }}>
                                                <AsyncSelect
                                                    name='organization_id'
                                                    placeholder='Filter by Taxonomy Type'
                                                    styles={SELECT_STYLES}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: SELECT_THEME_PRIMARY
                                                        }
                                                    })}
                                                    value={this.state.taxonomy_type_filter}
                                                    defaultOptions={this.state.taxonomyTypes}
                                                    onChange={(option) => this.handleSingleSelectFilter(option, 'taxonomy_type_filter')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="filter-row" style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className="col-md-6 col-sm-6 col-xs-12 " style={{ paddingLeft: 0 }}>
                                        <div className='filter-firstline'>
                                            <div style={{ width: '100%' }}>
                                                <AsyncSelect
                                                    name='id'
                                                    placeholder='Filter by Email Template Name'
                                                    styles={SELECT_STYLES}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: SELECT_THEME_PRIMARY
                                                        }
                                                    })}
                                                    value={this.state.email_template_id}
                                                    defaultOptions={this.state.emailTemplates}
                                                    loadOptions={(value, callback) => this.getOptionsData("emailTemplates", false, value, callback)}
                                                    onChange={(option) => this.handleSingleSelectFilter(option, 'email_template_id')}
                                                    onFocus={() => { this.getOptionsData("emailTemplates", true) }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12 " style={{ padding: 0 }}>
                                        <div className='filter-firstline'>
                                            <div style={{ width: '100%' }}>
                                                <Select
                                                    placeholder='Filter by active active'
                                                    styles={SELECT_STYLES}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: SELECT_THEME_PRIMARY
                                                        }
                                                    })}
                                                    value={this.state.active}
                                                    onChange={(option) => this.handleSingleSelectFilter(option, 'active')}
                                                    options={OPTIONS}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-12 filter-searchbtn mr-0" onClick={this.clearFilters}>Clear All</a>
                                    </div>
                                </div>
                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    defaultColDef={this.state.defaultColDef}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    frameworkComponents={this.state.frameworkComponents}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                />
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }
            </div>
        );
    }
}

export default observer(salesAutomationEmail);
