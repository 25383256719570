import React, { Component } from 'react'
import { observer } from 'mobx-react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import store from '../../store'
import { API, SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY } from '../../constant'
import Api from '../../utility/api'
import { getTaxonomyData } from '../../utility/commonApis'
import FormParent from '../FormParent'

class MentionsModal extends FormParent {
    constructor() {
        super()
        this.state = {
            companies: [],
            news: [],
            formFields: {
                entity_id: '',
                entity_type: ''
            },
            investorEntityTypes: [
                { 'value': 'STARTUP', 'label': 'Startup' },
                { 'value': 'CORPORATE', 'label': 'Corporate' },
                { 'value': 'ENABLER', 'label': 'Enabler' },
                { 'value': 'INVESTOR', 'label': 'Investor' },
                { 'value': 'USER', 'label': 'User' }
            ],
            editParams: [],
            validationFields: ['entity_id', 'entity_type'],
            entity_exists: false
        }
        this.parameters = {
            entity_id: '',
            entity_type: '',
        }
        this.parametersData = {};
    }

    componentDidMount() {
        store.mentionData = []
        store.mentions = []
        fetch(`${API}/news?fields=id^,title`, {
            method: 'GET',
            headers: {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            },
        })
            .then(response => { return response.json() })
            .then(data => {
                if (data.status === 200) {
                    let arr = []
                    data.data.map(item => {
                        arr.push({ value: item.id, label: item.title })
                    })
                    this.setState({ news: arr })
                }
            })
    }

    handleAddMention = () => {
        if (!this.state.formFields.entity_type) {
            this.setState({ entity_typeEmpty: true })
        } else if (!this.state.formFields.entity_id) {
            this.setState({ entity_idEmpty: true })
        } else {
            if (this.handleEmptyFieldValidation()) {
                this.parametersData.from = 'modal';
                this.addMentions(this.parameters, this.parametersData, this.state.formFields['entity_id'].label);
            }
        }
    }

    async handleEntityTypeChange(option, str) {
        if (store.isEdit) {
            if (this.state.editParams.indexOf(str) === -1) {
                this.state.editParams.push(str)
            }
        }

        this.state[`${str}Empty`] = false
        this.parameters[str] = option.value
        this.parametersData[str] = option.label
        this.state.formFields[str] = option
        this.setState(this.state);
        this.setState({ entity_exists: false });

        let type = option.value;
        
        if (str === 'entity_type' && type !== '') {
            try {
                const data = await Api.request(`${type}?fields=id^,name`, 'GET', {
                    'Authorization': store.token,
                    'Content-Type': 'application/json'
                })
                let arr     = []

                data.data.map(item => {
                    arr.push({
                        value: item.id,
                        label: item.name
                    })
                })

                this.setState({
                    companies: arr
                })
            } catch (error) {
                console.error(error)
                return;
            }
        }
    }

    handleMentionsSelectChange(option, str, isMulti) {
        this.state[`${str}Empty`] = false;
        this.parameters[str] = option.value;
        this.parametersData[str] = option.label;
        this.state.formFields[str] = option;
        this.setState(this.state);
        this.setState({ entity_exists: false });
    }

    render() {
        this.state.formFields = store.mentionDetails;
        let obj = {}, objData = {};
        Object.keys(store.mentionDetails).map(key => {
            obj[key] = store.mentionDetails[key].value;
            objData[key] = store.mentionDetails[key].label;
        })
        this.parameters = obj;
        this.parametersData = objData;

        return (
            <div className="modal fade" id="addMentionModal" >
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">ADD MENTIONS</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div className="modal-body">

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Company type <span className='required'>*</span></label>
                                    <Select
                                        placeholder='Please select'
                                        styles={this.state.entity_typeEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        value={this.state.formFields.entity_type}
                                        onChange={(option) => this.handleEntityTypeChange(option, 'entity_type')}
                                        options={this.state.investorEntityTypes}
                                    />
                                    {this.state.entity_typeEmpty && <div className='error'>Type cannot be empty</div>}
                                </div>
                            </div>

                            {this.state.formFields.entity_type && <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Company Name <span className='required'>*</span></label>
                                    <AsyncSelect
                                        placeholder='Please Select or type to search'
                                        styles={this.state.entity_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        value={this.state.formFields.entity_id}
                                        defaultOptions={this.state.companies}
                                        loadOptions={(value, callback) => this.handleLoadOptions(value, callback, this.state.formFields.entity_type.value)}
                                        onChange={(option) => this.handleMentionsSelectChange(option, 'entity_id', false)}
                                    />
                                    {this.state.entity_idEmpty && <div className='error'>Company name cannot be empty</div>}
                                </div>
                            </div>}

                        </div>
                        {this.state.entity_exists && <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                            <div className='error'>Entity selected already exists</div>
                        </div>}

                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={this.handleAddMention}>Add</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default observer(MentionsModal)