import React, { Component } from 'react'
import { observer } from 'mobx-react'
import store from '../store'
import Welcome from './Welcome'

class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoggedin: false,
            loginEmail: '',
            loginPwd: '',
            errorLogin: false,
            errMessage: ''
        }
    }

    render() {
        return (
            <div>
                {store.isLoggedin ?
                    <Welcome /> :
                    <div className='login-container'>
                        <h1 style={{ padding: 20 }}>
                            Welcome to MAGNiTT's Admin panel.
                        </h1>

                        <div style={{ width: '50vw' }}>
                            <button className="login-header" onClick={() => $('#loginModal').modal('show')} >LOGIN</button>
                        </div>

                    </div>
                }
            </div>
        )
    }
}
export default observer(Home)