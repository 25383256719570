import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import store from '../../store';

// constants
import { API, DATE_FORMAT, FUNDING_STATUS_LABELS, POLICY_PERIODS } from '../../constant';
import { API_PATH } from '../../utility/constants/apiPath';

// helpers
import { isObjectEmpty, displayAmount } from '../../utility/helpers'

// components
import Parent from '../Parent';

class Pagination extends Parent {
    allocateValue = (detail, data) => {
        let arr = []
        switch (detail) {
        case 'view-research-downloads':
            data.map(item => {
                arr.push({
                    email: item.email !== null ? item.email : '',
                    uid: !isObjectEmpty(item.user_data) ? item.user_data.id : '',
                    username: !isObjectEmpty(item.user_data) ? `${item.user_data.first_name} ${item.user_data.last_name}` : '',
                    useremail: !isObjectEmpty(item.user_data) ? item.user_data.name : '',
                    download_date: item.download_date !== null ? moment(item.download_date).format(DATE_FORMAT) : item.download_date
                })
            })
            break;
        case 'taxonomy':
            data.map(item => {
                arr.push({
                    id: item.id,
                    name: item.name,
                    value: item.value
                })
            })
            break;
        case 'funding-verification':
            data.map(item => {
                let created_by_type = "-";
                if (item.created_by_admin) {
                    created_by_type = "ADMIN";
                } else if (!isObjectEmpty(item.investing_entity_data)) {
                    created_by_type = item.investing_entity_data.entity_type;
                } else {
                    created_by_type = item.entity_type ? item.entity_type : "-";
                }

                arr.push({
                    "id": item.id,
                    "funding_id": item.funding_id,
                    "entity_id": !isObjectEmpty(item.investedin_data) ? item.investedin_data.id : '-',
                    "entity_name": !isObjectEmpty(item.investedin_data) ? item.investedin_data.name : '-',
                    "stage": !isObjectEmpty(item.funding_stage_data) ? item.funding_stage_data.name : '-',
                    "investment_vehicle": !isObjectEmpty(item.investment_vehicle_data) ? item.investment_vehicle_data.name : '-',
                    "date": item.date_raised ? moment(item.date_raised).format(DATE_FORMAT) : '-',
                    "funding_amount": item.amount !== null ? displayAmount(item.amount) : '-',
                    "verified_at": item.verified_at ? moment(item.verified_at).format(DATE_FORMAT) : '-',
                    "verified_by": !isObjectEmpty(item.verified_by_data) ? `${item.verified_by_data.first_name} ${item.verified_by_data.last_name}` : '-',
                    "verification_status": item.verification_status !== null ? FUNDING_STATUS_LABELS[item.verification_status] : '-',
                    "funding_status": item.verification_status,
                    "source_url": item.source_url,
                    "comment": item.comment,
                    "created_by": !isObjectEmpty(item.created_by_data) ? `${item.created_by_data.first_name} ${item.created_by_data.last_name}` : '-',
                    "created_by_type": created_by_type,
                    "investors": item.investors_data,
                })
            })
            break;
        case API_PATH.fundingRequests:
        case 'funding-round-requests':
            data.map(item => {

                arr.push({
                    "id": item.id,
                    "startup_id": !isObjectEmpty(item.investedin_data) ? item.investedin_data.id : '-',
                    "funding_id": item.funding_id ? item.funding_id : '-',
                    "funding_breakdown_id": item.funding_breakdown_id ? item.funding_breakdown_id : '-',
                    "entity_id": item.entity_id ? item.entity_id : '-',
                    "entity_type": item.entity_type ? item.entity_type : '-',
                    "startup_name": !isObjectEmpty(item.investedin_data && item.investedin_data.name) ? item.investedin_data.name : '-',
                    "stage": !isObjectEmpty(item.funding_stage_data && item.funding_stage_data.name) ? item.funding_stage_data.name : '-',
                    "requestor": !isObjectEmpty(item.entity_data) ? item.entity_data.name : '-',
                    'amount_invested': item.invested_amount ? displayAmount(item.invested_amount) : '-'
                })
            })

            break
        case 'undisclosed-proxy':
            data.map(item => {

                arr.push({
                    "year": item.year,
                    "value": item.value,
                    "funding_stage": !isObjectEmpty(item.funding_stage_data) && item.funding_stage_data.name ? item.funding_stage_data.name : '-',
                    "created_by": !isObjectEmpty(item.created_by_data) && item.created_by_data.name ? item.created_by_data.name : '-',
                    "created_at": item.created_at,
                    "updated_at": item.updated_at,
                })
            })
            break
        case 'data-export-policy':
            data.map(item => {

                let period = '-';
                if (item.period) {
                    const filteredPeriods = POLICY_PERIODS.filter(periodItem => periodItem.value === item.period);

                    if (filteredPeriods.length > 0) {
                        period = filteredPeriods[0].label;
                    }
                }

                arr.push({
                    "id": item.id,
                    "role": !isObjectEmpty(item.role_data) ? item.role_data.name : '-',
                    "period": period,
                    "policy": (item.policy && item.policy.length > 0) ? item.policy : '-',
                    "active": item.active ? 'Yes' : 'No',
                });
            });
            break;
        case 'data-export-log':
            data.map(item => {

                const details = {
                    fields: item.fields ? item.fields.join(', ') : '-',
                    filters: item.filters ? item.filters : '-',
                    sort: item.sort ? item.sort : '-'
                }

                arr.push({
                    "id": item.id,
                    "user_id": !isObjectEmpty(item.user_data) ? item.user_data.id : '-',
                    "username": !isObjectEmpty(item.user_data) ? item.user_data.name : '-',
                    "order_id": item.order_id ? item.order_id : '-',
                    "subscription": !isObjectEmpty(item.order_data) ? item.order_data.subscription_name : '-',
                    "number_of_records": item.number_of_records ? item.number_of_records : '-',
                    "data_set_type": item.data_set_type ? item.data_set_type : '-',
                    "is_custom": item.is_custom ? 'Yes' : '-',
                    "details": details,
                    "created_at": item.created_at ? moment(item.created_at).format('DD/MM/YYYY') : '-',
                })
            })
            break
        case 'data-export-product':
            data.map(item => {
                arr.push({
                    "id": item.id,
                    "data_set_type": item.data_set_type ? item.data_set_type : '-',
                    "description": item.description ? item.description : '-',
                    "active": item.active ? 'Yes' : 'No',
                })
            })
            break
        case 'location':
            data.map(item => {
                arr.push({
                    id: item.id,
                    name: item.name
                })
            })
            break;
        case API_PATH.organization:
            data.map(item => {
                arr.push({
                    "id": item.id,
                    "name": item.name !== null ? item.name : '-',
                    "status": item.active === 1 ? 'Active' : 'In-active',
                    "location": !isObjectEmpty(item.country_data) ? item.country_data.name : '-'
                });
            })
            break;
        default:
            //console.log('default allocate value')
            break;
        }

        store.data = data;
        store.rowData = arr
    }

    fetchData = (fields) => {
        store.rowData = []

        let query = store.currentFilters !== '' && store.currentSort ?
            `fields=${fields}&filters=${store.currentFilters}&sort=${store.currentSort}` :
            store.currentFilters !== '' && store.currentSort === '' ?
                `fields=${fields}&filters=${store.currentFilters}` :
                store.currentFilters === '' && store.currentSort !== '' ?
                    `fields=${fields}&sort=${store.currentSort}` :
                    `fields=${fields}`         

        const url = `${API}/${this.props.path}?${query}`
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.status === 200) {
                    this.allocateValue(this.props.details, data.data)
                    this.handleResult(data)
                }
            })

    }

    handleFetchNextPage = () => {
        if (store.page !== store.lastPage) {
            let fields = `${store.fields}&page=${store.page + 1}`
            // this.props.path ? this.fetchData(fields) : this.fetchListData(store.type, fields)

            if (this.props.fetchData) {
                this.props.fetchData(fields);
            } else if (this.props.path) {
                this.fetchData(fields)
            } else {
                this.fetchListData(store.type, fields)
            }
        }
    }

    handleFetchPrevPage = () => {
        if (store.page > 1) {
            let fields = `${store.fields}&page=${store.page - 1}`
            // this.props.path ? this.fetchData(fields) : this.fetchListData(store.type, fields)

            if (this.props.fetchData) {
                this.props.fetchData(fields);
            } else if (this.props.path) {
                this.fetchData(fields)
            } else {
                this.fetchListData(store.type, fields)
            }
        }
    }

    handleFetchFirstPage = () => {
        let fields = `${store.fields}&page=1`
        // this.props.path ? this.fetchData(fields) : this.fetchListData(store.type, fields)

        if (this.props.fetchData) {
            this.props.fetchData(fields);
        } else if (this.props.path) {
            this.fetchData(fields)
        } else {
            this.fetchListData(store.type, fields)
        }
    }

    handleFetchLastPage = () => {
        let fields = `${store.fields}&page=${store.lastPage}`
        // this.props.path ? this.fetchData(fields) : this.fetchListData(store.type, fields)
        if (this.props.fetchData) {
            this.props.fetchData(fields);
        } else if (this.props.path) {
            this.fetchData(fields)
        } else {
            this.fetchListData(store.type, fields)
        }
    }

    render() {
        return (
            <div className='taxonomy-pagination-container'>
                <span className='taxonomy-pagination-span'>
                    <span> {store.fromCount} </span> to
                    <span> {store.toCount} </span> of
                    <span> {store.totalCount} </span>
                </span>
                <span className='taxonomy-pagination-span' >
                    I<i className='fas fa-chevron-left taxonomy-pagination-i' onClick={this.handleFetchFirstPage}></i> &nbsp;&nbsp;
                    <i className='fas fa-chevron-left taxonomy-pagination-i' onClick={this.handleFetchPrevPage}></i> &nbsp;Page
                    <span> {store.page} </span> of
                    <span> {store.lastPage} </span>&nbsp;
                    <i className='fas fa-chevron-right taxonomy-pagination-i' onClick={this.handleFetchNextPage}></i> &nbsp;&nbsp;
                    <i className='fas fa-chevron-right taxonomy-pagination-i' onClick={this.handleFetchLastPage}></i>I
                </span>
            </div>
        )
    }
}

export default observer(Pagination)