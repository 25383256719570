import axios from 'axios';

import { ALLOWED_QUERY_OPERATOR, API, TAXONOMIES } from '../constant';
import store from '../store';
import { prepareFilterParam, prepareSelectParam } from '../utility/queryParams';

const taxonomyApi = {
    getCountries: () => {
        const fields = prepareSelectParam(['id', 'name']);
        const filters = prepareFilterParam([
            {key: 'country', value: [0], op: ALLOWED_QUERY_OPERATOR.equal},
            {key: 'is_region', value: [0], op: ALLOWED_QUERY_OPERATOR.equal}
        ]);

        const url = `${API}/location?fields=${fields}&filters=${filters}&limit=200`
        const headers = {
            Authorization: store.token
        }

        return new Promise((resolve, reject) => {
            axios.get(url, {headers})
                .then(response => {
                    if (response.data.status === 200) {
                        resolve(response.data.data)
                    } else {
                        reject([])
                    }
                }).catch(err => reject(err))
        })
    },

    getRegions: () => {
        const fields = prepareSelectParam(['id', 'name']);
        const filters = prepareFilterParam([
            {key: 'is_region', value: [1], op: ALLOWED_QUERY_OPERATOR.equal}
        ]);

        const url = `${API}/location?fields=${fields}&filters=${filters}&limit=200`
        const headers = {
            Authorization: store.token
        }

        return new Promise((resolve, reject) => {
            axios.get(url, {headers})
                .then(response => {
                    if (response.data.status === 200) {
                        resolve(response.data.data)
                    } else {
                        reject([])
                    }
                }).catch(err => reject(err))
        })
    },

    getTaxonomyByType: (typId, strName = '') => {
        const fields = prepareSelectParam(['id', 'name']);

        let filtersQuery = '';
        let filters = []

        if (strName !== '') {
            filters.push({ key: 'name', value: [strName], op: ALLOWED_QUERY_OPERATOR.substring })
        }

        if (filters && filters.length > 0) {
            filtersQuery = `filters=${prepareFilterParam(filters)}&`;
        }

        const url = `${API}/taxonomy-type/${typId}/taxonomy/?fields=${fields}&${filtersQuery}limit=200`;

        const headers = {
            Authorization: store.token
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(response => {
                    if (response.data.status === 200) {
                        resolve(response.data.data)
                    } else {
                        reject([])
                    }
                }).catch(err => reject(err))
        })
    },

    getTaxonomyDetails: (path, paramFields) => {
        const fields = prepareSelectParam(paramFields)
        const url = `${API}/${path}?fields=${fields}`

        const headers = {
            Authorization: store.token,
            'Content-Type': 'application/json'
        }

        return new Promise((resolve, reject) => {
            axios.get(url, {headers})
                .then(response => {
                    if (response.data.status === 200) {
                        resolve(response.data.data)
                    } else {
                        reject({})
                    }
                }).catch(err => reject(err))
        })
    },

    getMagnittRoles: (nameFilter = '') => {
        const fields = prepareSelectParam(['id', 'name']);

        let filters = '';
        if (nameFilter) {
            filters = prepareFilterParam([{key: 'name', value: [nameFilter], op: ALLOWED_QUERY_OPERATOR.substring}]);
        }

        const url = `${API}/role?fields=${fields}&filters=${filters}&limit=100`
        const headers = {
            Authorization: store.token
        }

        return new Promise((resolve, reject) => {
            axios.get(url, {headers})
                .then(response => {
                    if (response.data.status === 200) {
                        resolve(response.data.data)
                    } else {
                        reject([])
                    }
                }).catch(err => reject(err))
        })
    },

    getIndustries: () => {
        const fields = prepareSelectParam(['id', 'name']);
        const filters = prepareFilterParam([
            { key: 'is_deleted', value: [0], op: ALLOWED_QUERY_OPERATOR.equal },
            { key: 'parent_id', value: [0], op: ALLOWED_QUERY_OPERATOR.equal },
        ]);

        const url = `${API}/industry?fields=${fields}&filters=${filters}&limit=200`;

        const headers = {
            Authorization: store.token
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(response => {
                    if (response.data.status === 200) {
                        resolve(response.data.data)
                    } else {
                        reject([])
                    }
                }).catch(err => reject(err))
        })
    },

    getSubIndustries: () => {
        const fields = prepareSelectParam(['id', 'name']);
        const filters = prepareFilterParam([
            { key: 'is_deleted', value: [0], op: ALLOWED_QUERY_OPERATOR.equal },
            { key: 'parent_id', value: [0], op: ALLOWED_QUERY_OPERATOR.notEq },
        ]);

        const url = `${API}/industry?fields=${fields}&filters=${filters}&limit=200`;

        const headers = {
            Authorization: store.token
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(response => {
                    if (response.data.status === 200) {
                        resolve(response.data.data)
                    } else {
                        reject([])
                    }
                }).catch(err => reject(err))
        })
    }
}

export default taxonomyApi;
