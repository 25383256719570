import axios from 'axios';

import store from '../store';
import { API } from '../constant';
import { prepareSelectParam } from '../utility/queryParams';

const valuationCalculationApi = {

    // get the valuation calculation 
    getValuationCalculations: () => {
        const paramFields = ['id', 'description'];
        const fields = prepareSelectParam(paramFields);

        const url = `${API}/valuation-calculation?fields=${fields}`;
        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(
                    response => {
                        if (response.data.status === 200) {
                            resolve(response.data.data);
                        } else {
                            resolve([]);
                        }
                    }
                )
                .catch((err) => reject(err));
        });
    },

    getValuationCalculationDetails: (ValCalId = 0, fields = []) => {
        const url = `${API}/valuation-calculation/${ValCalId}?fields=${fields}`;
        const headers = {
            Authorization: store.token
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(response => {
                    if (response.data.status === 200) {
                        resolve(response.data.data);
                    } else {
                        reject({});
                    }
                }).catch(err => reject(err))
        })
    },

}

export default valuationCalculationApi;