import React from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async'
// constants
import { SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY, ACTIVE_STATUS, DIRECTORY_TYPE } from '../../constant';
import { API_PATH } from '../../utility/constants/apiPath';

// helpers
import store from '../../store';
import { prepareSelectParam } from '../../utility/queryParams';
import { isObjectEmpty } from '../../utility/helpers';

// apis
import clientDirectoryApi from '../../apis/clientDirectoryApi';
import organizationApi from '../../apis/organizationApi';
import taxonomyApi from '../../apis/taxonomyApi';
import ecosystemApi from '../../apis/ecosystemApi';
// components
import FormParent from '../FormParent';
import Home from '../Home';
import SideNav from '../SideNav';
import Modal from '../reusable/Modal';
import AddDefaultFilterModel from './DefaultFilter/AddDefaultFilterModel';
import AddBrandThemeModel from './BrandTheme/AddBrandThemeModel';
import LogoDimensionModel from './LogoDimension/AddLogoDimensionModel';
import ClientDirTaxonomy from './ClientDirectoryTaxonomy/ClientDirTaxonomy';


class ClientDirectoryForm extends FormParent {
    constructor(props) {
        super(props);
        this.state = {

            formFields: {
                title: '',
                status: '',
                type: '',
                description: '',
                logo: '',
                default_filter: '',
                organization_id: '',
                brand_theme: '',
                logo_dimensions: ''
            },
            validationFields: [
                'title', 'logo', 'type', 'organization_id', 'default_filter'
            ],
            editParams: [],

            //options
            organizations: [],
            locations: [],
            primaryIndustries: [],
            clientDirectoryTaxoData: [],

            error: false,

            titleEmpty: false,
            statusEmpty: false,
            typeEmpty: false,
            descriptionEmpty: false,
            logoEmpty: false,
            logoEmpty: false,
            default_filterEmpty: false,
            organization_idEmpty: false,
            isOrganizationEmpty: false,

            logoMsg: '',
            logoError: false,
            logoSuccess: false,
            logoUpload: false,
            showBrandThemeModel: false,
            showDefaultFilterModal: false,
            showLogoDimensionModel: false,
            postCreation: { client_directory_taxonomy: true },

        };
        this.parameters = {
            title: '',
            status: '',
            type: '',
            description: '',
            logo: '',
            default_filter: '',
            brand_theme: '',
            logo_dimensions: '',
            organization_id: '',
        };
    }


    async componentDidMount() {
        const [locations, primaryIndustries] = await Promise.all([
            this.fetchLocations(),
            this.fetchPrimaryIndustries(),
        ]);

        store.clientDirectoryPostCreateTaxo = [];

        this.setState({ locations, primaryIndustries });

        if (store.isEdit && store.currentId) {
            const paramFields = [
                'id',
                'title',
                'type',
                'organization_id',
                'status',
                'brand_theme',
                'description',
                'default_filter',
                'logo',
                'organization_logo_data',
                'logo_dimensions'
            ];
            const fields = prepareSelectParam(paramFields);

            clientDirectoryApi.getClientDirectoryDetails(store.currentId, fields).then(
                async response => {
                    const formFields = await this.transformResponseData(response);
                    this.setState({ formFields });
                }
            );

            clientDirectoryApi.getClientDirTaxonomy(store.currentId).then(
                async response => {
                    this.setState({ clientDirectoryTaxoData: response });
                }
            );
        }
    }

    fetchLocations = async () => {
        const response = await taxonomyApi.getCountries();
        return response.map(item => ({ value: item.id, label: item.name }));
    }

    fetchPrimaryIndustries = async () => {
        const response = await taxonomyApi.getIndustries();
        return response.map(item => ({ value: item.id, label: item.name }));
    }

    transformResponseData = async (response) => {
        let objTemp = {};

        for (const key of Object.keys(this.state.formFields)) {
            let resProperty = response[key];

            if (key === 'logo' && response.organization_logo_data) {
                resProperty = response.organization_logo_data
            }

            objTemp[key] = await this.transformFieldData(key, resProperty);
        }
        return objTemp;
    }

    transformFieldData = async (key, value) => {
        if (key === 'status') {
            return value !== null ? { value: value, label: ACTIVE_STATUS[value].label } : '';
        } else if (key === 'type') {
            return value !== null ? { value: value, label: value } : '';
        } else if (key === 'logo') {
            return value !== null ? `${value?.name} (Initial Image Name)` : '';
        } else if (key === 'default_filter') {

            let defaultFilterData = {}

            if (value && value?.entity_relation?.id && value?.entity_relation?.type) {
                let entityRelation = value.entity_relation
                const entityRelationData = await ecosystemApi.getEntityDetails(entityRelation?.id, entityRelation?.type?.toLowerCase());
                entityRelation = { value: entityRelation.id, label: entityRelationData?.name || '', type: entityRelation.type };
                defaultFilterData.entity_relation = entityRelation;
            }

            if (value && value?.filters?.hq_id?.length > 0) {
                const hqData = this.state.locations.filter(item => value.filters.hq_id.includes(item.value));
                defaultFilterData.hq_id = hqData;
            }
            if (value && value?.filters?.primary_industry?.length > 0) {
                const primaryIndustryData = this.state.primaryIndustries.filter(item => value.filters.primary_industry.includes(item.value));
                defaultFilterData.primary_industry = primaryIndustryData;
            }

            defaultFilterData.has_directory_tags = { label: value?.has_directory_tags ? 'Yes' : 'No', value: value?.has_directory_tags };

            return !isObjectEmpty(defaultFilterData) ? defaultFilterData : '';
        } else if (key === 'organization_id') {
            const organizationData = value !== null ? await organizationApi.getOrganizationDetails(value, ['id', 'name']) : false;
            return organizationData ? { value: organizationData.id, label: organizationData.name } : '';
        } else {
            return value !== null ? value : '';
        }
    }

    handleAddData = () => {
        this.handleAdd(API_PATH.clientDirectory);
    };

    handleUpdateData = () => {
        this.handleUpdate(API_PATH.clientDirectory);
    };

    handleShowDefaultFilterModal = () => {
        this.setState({ showDefaultFilterModal: true },
            () =>
                $('#defaultFilterModal').modal({
                    backdrop: 'static',
                    keyboard: true,
                    show: true
                })
        );
    }

    handleShowBrandThemeModal = () => {
        this.setState({ showBrandThemeModel: true },
            () =>
                $('#brandThemeModal').modal({
                    backdrop: 'static',
                    keyboard: true,
                    show: true
                })
        );
    }

    handleShowLogoDimensionModel = () => {
        this.setState({ showLogoDimensionModel: true },
            () =>
                $('#logoDimensionsModal').modal({
                    backdrop: 'static',
                    keyboard: true,
                    show: true
                })
        );
    }

    getOrganizations = async (onFocus = false, option = '', callback) => {

        if (option && option.length < 2 && !onFocus) {
            return;
        }

        let arrOrganizations = []

        await organizationApi.getOrganizations(option).then(
            response => {
                arrOrganizations = response.map(item => {
                    return { value: item.id, label: item.name, };
                });

                this.setState({ organizations: arrOrganizations });
            }
        );

        if (callback) {
            callback(arrOrganizations);
        }
    }

    handleUploadLogo = async (e) => {
        await this.addFileUpload('logo', 'logo', this.state.logoFile)

        if (this.state.logoSuccess) {
            const img = new Image();
            img.onload = () => {
                const dimensions = {
                    height: img.height,
                    width: img.width
                };
                const logoDimensions = {
                    original_dimensions: dimensions,
                }

                this.handleSelectChange({ value: logoDimensions }, 'logo_dimensions');
                this.parameters.logo_id = this.parameters.logo;
                if (store.isEdit) this.handleUpdateData();
            };
            img.src = URL.createObjectURL(this.state.logoFile);
        }
    }

    handleAddTaxonomy = async (client_directory_id) => {
        const clientDirectoryPostCreateTaxo = store.clientDirectoryPostCreateTaxo

        const objKeys = Object.keys(clientDirectoryPostCreateTaxo).sort((a, b) => a.length - b.length);

        if (!client_directory_id || objKeys.length == 0) return;

        let handledKeys = {};
        const processChildTaxo = async (parentData, resParentTaxoData) => {
            for (const childKey of objKeys) {
                const childData = clientDirectoryPostCreateTaxo[childKey];
                if (childData.payload.parent_id === parentData?.payload?.taxoId &&
					childData.payload.parent_id !== parentData?.payload?.parent_id &&
					(parentData.payload.level + 1) === childData.payload.level) {

                    clientDirectoryPostCreateTaxo[childKey].payload.parent_id = resParentTaxoData.data.id;
                    clientDirectoryPostCreateTaxo[childKey].payload.client_directory_id = client_directory_id;
                    handledKeys[childKey] = true;
                    delete clientDirectoryPostCreateTaxo[childKey].payload.id;

                    const resChildTaxoData = await this.saveAssociatedData(childData.path, childData.payload, childData.method);

                    childData.payload.level < 2 && processChildTaxo(childData, resChildTaxoData);
                }
            }
        }

        for (const key of objKeys) {
            let item = clientDirectoryPostCreateTaxo[key];
            let resParentTaxoData = null;

            if ((item?.payload?.parent_id === null || !String(item?.payload?.parent_id).includes('parent_id')) && !handledKeys[key]) {
                item.payload.client_directory_id = client_directory_id;
                handledKeys[key] = true;
                delete item.payload.id;

                resParentTaxoData = await this.saveAssociatedData(item.path, item.payload, item.method);
            }

            processChildTaxo(item, resParentTaxoData);
        }
        store.clientDirectoryPostCreateTaxo = {};
    }

    render() {
        const { showBrandThemeModel, showDefaultFilterModal, showLogoDimensionModel } = this.state;
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT CLIENT DIRECTORY</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD CLIENT DIRECTORY</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >TITLE <span className='required'>*</span></label>
                                            <input
                                                className={this.state.titleEmpty ? "form-control input-error" : "form-control"}
                                                type="text"
                                                name='title'
                                                placeholder="Add Title"
                                                value={this.state.formFields.title}
                                                onChange={this.handleInputChange} />
                                            {this.state.titleEmpty && <div className='error'>Title cannot be empty</div>}
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >ORGANIZATION <span className='required'>*</span></label>
                                            <AsyncSelect
                                                name='organization_id'
                                                placeholder='Select organization name'
                                                styles={this.state.organization_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.organization_id}
                                                defaultOptions={this.state.organizations}
                                                loadOptions={(value, callback) => this.getOrganizations(false, value, callback)}
                                                onChange={(option) => this.handleSelectChange(option, 'organization_id')}
                                                onFocus={() => this.getOrganizations(true)}
                                            />
                                            {this.state.organization_idEmpty && <div className='error'>Organization cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >TYPE <span className='required'>*</span></label>
                                            <Select
                                                name='type'
                                                placeholder='Please select type'
                                                styles={this.state.typeEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.type}
                                                onChange={(option) => this.handleSelectChange(option, 'type')}
                                                options={DIRECTORY_TYPE}
                                            />
                                            {this.state.typeEmpty && <div className='error'>Type cannot be empty</div>}
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>LOGO <span className='required'>*</span></label>
                                            <div className="input-group mb-3">
                                                <div className="custom-file">
                                                    <div className="form-filename-holder">{this.state.formFields.logo === '' ? 'Choose a File' : this.state.formFields.logo}</div>
                                                    <input type='file'
                                                        className={this.state.logoEmpty ? "custom-file-input input-error" : "custom-file-input"}
                                                        name='logo'
                                                        onChange={this.handleImageUpload}
                                                        styles={{
                                                            border: this.state.logoEmpty ? '1px solid red' : '1px solid #ced4da',
                                                        }}
                                                    />
                                                </div>
                                                <div className="input-group-append">
                                                    <button className="input-group-text" onClick={this.handleUploadLogo} >Upload</button>
                                                </div>
                                            </div>
                                            {this.state.logoMsg.length > 0 && <div className='error'>{this.state.logoMsg}</div>}
                                            {this.state.logoError && <div className='error'>Upload failed!.</div>}
                                            {this.state.logoSuccess && <div className='success'>Upload successful!</div>}
                                            {this.state.logoUpload && <div className='error'>File is chosen but not uploaded</div>}
                                            {this.state.logoEmpty && <div className='error'>Logo cannot be empty</div>}
                                            {(this.state.formFields.logo && !this.state.formFields.logo.includes('(Initial Image Name)')) &&
												!this.parameters.logo &&
												<div className='error'>Please click upload button to upload the file</div>
                                            }

                                            {this.state.formFields.logo_dimensions !== '' && <div className='row'>
                                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                                    <label >LOGO DIMENSION </label>
                                                    <div >
                                                        <button
                                                            className='btn-primary bottom-btn w-100'
                                                            onClick={this.handleShowLogoDimensionModel}
                                                        >{this.state.formFields.logo_dimensions === '' ? 'Add' : 'Update'} Logo Dimension</button>
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >DESCRIPTION</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name='description'
                                                placeholder="Add Description"
                                                value={this.state.formFields.description}
                                                onChange={this.handleInputChange}
                                            />
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >STATUS</label>
                                            <Select
                                                name='status'
                                                placeholder='Please select status'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.status}
                                                onChange={(option) => this.handleSelectChange(option, 'status')}
                                                options={ACTIVE_STATUS}
                                            />
                                        </div>

                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >DEFAULT FILTER <span className='required'>*</span></label>
                                            <div >
                                                <button
                                                    className='btn-primary bottom-btn w-100'
                                                    onClick={this.handleShowDefaultFilterModal}
                                                >{this.state.formFields.default_filter === '' ? 'Add' : 'Update'} Default Filter</button>
                                            </div>
                                            {this.state.default_filterEmpty && <div className='error'>Default Filter cannot be empty</div>}
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >BRAND THEME </label>
                                            <div >
                                                <button
                                                    className='btn-primary bottom-btn w-100'
                                                    onClick={this.handleShowBrandThemeModal}
                                                >{this.state.formFields.brand_theme === '' ? 'Add' : 'Update'} Brand Theme</button>
                                            </div>
                                            {this.state.brand_themeEmpty && <div className='error'>Brand Theme cannot be empty</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ClientDirTaxonomy isEdit={store.isEdit}
                                clientDirectoryTaxoData={this?.state.clientDirectoryTaxoData}
                                setParentState={(a, c) => { this.setState(a, c) }}
                            />

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ? <button className="btn-primary bottom-btn" disabled={this.state.editParams.length > 0 || store.apis.length > 0 ? false : true} onClick={this.handleUpdateData}>SAVE</button> :
                                    <div>
                                        <button className="btn-primary bottom-btn" onClick={this.handleAddData}>ADD</button>
                                        <button className="btn-secondary bottom-btn" onClick={this.handleClear}>CLEAR</button>
                                    </div>
                                }
                            </div>

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}

                        <Modal />

                        {showDefaultFilterModal && <AddDefaultFilterModel
                            locations={this.state.locations}
                            primaryIndustries={this.state.primaryIndustries}
                            handleAddDefaultFilter={this.handleSelectChange}
                            defaultFilter={this.state.formFields.default_filter}
                            isEdit={store.isEdit}
                            setParentState={(a, c) => { this.setState(a, c) }}
                        />}

                        {showBrandThemeModel && <AddBrandThemeModel
                            handleAddBrandTheme={this.handleSelectChange}
                            brandTheme={this.state.formFields.brand_theme}
                            isEdit={store.isEdit}
                            setParentState={(a, c) => { this.setState(a, c) }}
                        />}

                        {showLogoDimensionModel && <LogoDimensionModel
                            handleAddLogoDimension={this.handleSelectChange}
                            logoDimensions={this.state.formFields.logo_dimensions}
                            isEdit={store.isEdit}
                            setParentState={(a, c) => { this.setState(a, c) }}
                        />}
                    </div>
                }
            </div>
        );
    }
}
export default observer(ClientDirectoryForm);
