import axios from 'axios';

import store from '../store';
import { API } from '../constant';
import { prepareSelectParam } from '../utility/queryParams';

const roleApi = {

    getRoles: () => {
        const fields = prepareSelectParam(['id', 'name']);

        const url = `${API}/role?fields=${fields}&limit=100`;
        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        return new Promise((resolve, reject) => {

            axios.get(url, { headers })
                .then(
                    response => {
                        if (response.data.status === 200) {
                            resolve(response.data.data);
                        } else {
                            resolve({});
                        }
                    }
                )
                .catch((err) => reject(err));
        });
    }

}

export default roleApi;