
import React, { PureComponent } from 'react';

// helpers
import store from '../../../store';

class InvestorModalActionRenderer extends PureComponent {
    showModal = () => {
        $('#investorsViewModal').modal({
            backdrop: 'static',
            keyboard: true,
            show: true
        })
        
        store.currentFundingBreakdownId = this.props.data.id;

        if (this.props.data.investors_data) {
            store.investorFundingData = this.props.data.investors_data;
        } else if (this.props.data.investors) {
            store.investorFundingData = this.props.data.investors;
        } else {
            store.investorFundingData = [];
        }
    }

    render() {
        return (
            <a tabIndex="1" className="btn-edit" onClick={this.showModal}>View investors</a>
        )
    }
}

export default InvestorModalActionRenderer
