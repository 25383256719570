import React from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';
import NumberFormat from "react-number-format";

// helpers
import store from '../../store';
import { isObjectEmpty } from '../../utility/helpers';
import { prepareSelectParam, prepareFilterParam } from '../../utility/queryParams';

// constants
import { SELECT_STYLES, SELECT_THEME_PRIMARY, SELECT_STYLES_ERROR, OPTIONS, TAXONOMIES, ALLOWED_QUERY_OPERATOR } from '../../constant';

// apis
import taxonomyApi from '../../apis/taxonomyApi';
import UndisclosedProxyApi from '../../apis/undisclosedProxyApi';

// components
import Home from '../Home';
import FormParent from '../FormParent';
import SideNav from '../SideNav';
import Modal from '../reusable/Modal';
import ErrorPopup from '../reusable/ErrorModal';

class UndisclosedProxyForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            // form fields data
            formFields: {
                year: '',
                value: '',
                funding_stage_id: '',
                active: ''
            },

            // validation - required fields
            validationFields: ['year', 'funding_stage_id'],

            // edited fields
            editParams: [],

            // data stores
            funding_stages: []
        }

        this.parameters = {
            year: '',
            value: '',
            funding_stage_id: '',
            active: ''
        }
    }

    componentDidMount() {
        if (store.isEdit && store.currentId) {
            const paramFields = ['id', 'year', 'value', 'funding_stage_data', 'active'];
            const fields = prepareSelectParam(paramFields);

            UndisclosedProxyApi.getUndisclosedProxyDetails(store.currentId, fields).then(
                response => {
                    const objTemp = {};

                    Object.keys(this.state.formFields).map(key => {
                        if (key === 'funding_stage_id') {
                            objTemp[key] = !isObjectEmpty(response.funding_stage_data) ? { 'value': response.funding_stage_data.id, 'label': response.funding_stage_data.name } : '';
                        } else if (key === 'active') {
                            objTemp[key] = response.active !== null ? { 'value': response.active, 'label': response.active ? 'Yes' : 'No' } : '';
                        } else {
                            objTemp[key] = response[key] !== null ? response[key] : '';
                        }
                    });

                    this.setState({ formFields: objTemp });
                }
            );
        }

        // get funding stages list
        taxonomyApi.getTaxonomyByType(TAXONOMIES.fundingStage).then(
            response => {
                if (response.length > 0) {
                    let arrTemp = [];
                    response.map(stage => arrTemp.push({ value: stage.id, label: stage.name }));

                    this.setState({
                        funding_stages: arrTemp
                    });
                }
            }
        );
    }

    async generateValue() {
        let { formFields, editParams } = this.state;

        let filters = prepareFilterParam([
            { key: 'year', value: [formFields.year], op: ALLOWED_QUERY_OPERATOR.equal },
            { key: 'funding_stage_id', value: [formFields.funding_stage_id.value], op: ALLOWED_QUERY_OPERATOR.equal }
        ]);

        await UndisclosedProxyApi.getUndisclosedProxyCalculation(filters).then(
            response => {
                this.parameters.value = response[0].value;
                formFields.value = response[0].value;
                if (store.isEdit && store.currentId) {
                    editParams.push('value')
                }
                this.setState({ formFields, editParams });
            }
        );
    }

    async handleAddUndisclosedProxy() {
        let { formFields } = this.state;

        if (formFields.value == "") {
            await this.generateValue();
        }

        this.handleAdd('undisclosed-proxy');
    }

    async handleUpdateUndisclosedProxy() {
        let { formFields } = this.state;

        if (formFields.value == "") {
            await this.generateValue();
        }

        this.handleUpdate('undisclosed-proxy')
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className="content">
                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className="content-right">
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT UNDISCLOSED PROXY</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD UNDISCLOSED PROXY</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Year <span className='required'>*</span></label>
                                            <input
                                                className={this.state.yearEmpty ? "form-control input-error" : "form-control"}
                                                type="number"
                                                name='year'
                                                placeholder='Year, e.g. 2021'
                                                value={this.state.formFields.year}
                                                onChange={this.handleInputChange}
                                            />
                                            {this.state.yearEmpty && <div className='error'>Year cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Funding Stage <span className='required'>*</span></label>
                                            <Select
                                                name='funding_stage_id'
                                                placeholder='Please select a funding stage'
                                                styles={this.state.funding_stage_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.funding_stage_id}
                                                onChange={(option) => this.handleSelectChange(option, 'funding_stage_id')}
                                                options={this.state.funding_stages}
                                            />
                                            {this.state.funding_stage_idEmpty && <div className='error'>Funding stage cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Value</label>
                                            <div className="input-group mb-3">
                                                <NumberFormat
                                                    title="number"
                                                    name='value'
                                                    className="form-control"
                                                    placeholder='Value, e.g. 100000'
                                                    thousandSeparator={true}
                                                    value={this.state.formFields.value}
                                                    onChange={this.handleInputChange}
                                                />
                                                <div className="input-group-append">
                                                    <button className="input-group-text btn-primary" disabled={!this.state.formFields.year || !this.state.formFields.funding_stage_id} onClick={() => this.generateValue()}>Generate</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Active</label>
                                            <Select
                                                placeholder='Please select a status'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.active}
                                                onChange={(option) => this.handleSelectChange(option, 'active')}
                                                options={OPTIONS}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ? <button className="btn-primary bottom-btn" disabled={this.state.editParams.length > 0 ? false : true} onClick={() => this.handleUpdateUndisclosedProxy()}>Save</button> :
                                    <div>
                                        <button className="btn-primary bottom-btn" onClick={() => this.handleAddUndisclosedProxy()}>Add undisclosed proxy</button>
                                        <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                                    </div>
                                }
                            </div>

                        </div>
                        {store.isSideNav && <div className='overlay'> </div>}
                        <ErrorPopup errorMessage={this.state.errorMessage} />
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}

export default observer(UndisclosedProxyForm)
