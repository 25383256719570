import React from 'react';
import { observer } from 'mobx-react';
import AsyncSelect from 'react-select/async';
import store from '../../store';
import { API, SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY,OPTIONS } from '../../constant';
import FormParent from '../FormParent';

class PeopleTeamModal extends FormParent {
    constructor() {
        super()
        this.state = {
            people: [],
            roles: [],
            teamRoles: [],
            formFields: {
                people_id: '',
                team_role_id: '',
                is_primary_contact: 0,
                is_founder: 0,
            },
            validationFields: [],

        }

        this.parameters = {
            people_id: '',
            team_role_id: '',
            is_primary_contact: 0,
            is_founder: 0,
        }

    }

    componentDidMount() {
        store.peopleTeamData = [];
        store.peopleTeams = [];

        fetch(`${API}/people?fields=id^,first_name^,last_name^,email&filters=is_deleted^:0`, {
            method: 'GET',
            headers: {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            },
        })
            .then(response => { return response.json() })
            .then(data => {
                if (data.status === 200) {
                    let arr = [];

                    data.data.map(item => {
                        const email = item.email;
                        arr.push({ 
                            value: item.id, 
                            label: `${item.first_name} ${item.last_name} | ${email ? email : 'No email found'}`, 
                            email: item.email 
                        });
                    });

                    this.setState({ people: arr });
                }
            });

        fetch(`${API}/team-role?fields=id^,name`, {
            method: 'GET',
            headers: {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            },
        })
            .then(response => { return response.json() })
            .then(data => {
                if (data.status === 200) {
                    let arr = []
                    data.data.map(item => {
                        arr.push({ value: item.id, label: item.name })
                    })
                    this.setState({ teamRoles: arr })
                }
            })
    }

    handleAddPeopleTeam = () => {
        if (this.handleEmptyFieldValidation()) {
            if (store.isPeopleTeamEdit) {
                store.peopleTeamData[store.peopleTeamRowIndex].pid = this.state.formFields['people_id'].value;
                store.peopleTeamData[store.peopleTeamRowIndex].email = this.state.formFields['people_id'].email;
                store.peopleTeamData[store.peopleTeamRowIndex].is_primary_contact = this.state.formFields['is_primary_contact'].value;
                store.peopleTeamData[store.peopleTeamRowIndex].is_founder = this.state.formFields['is_founder'].value;

                if (store.isEdit) {
                    if (store.currentPeopleTeamId !== '') {
                        store.apis.push({ path: `${store.type}/${store.currentId}/people-team/${store.currentPeopleTeamId}`, method: 'PATCH', multiType: 'team', team: this.parameters })
                    }
                    else {
                        store.apis.push({ path: `${store.type}/${store.currentId}/people-team`, method: 'POST', multiType: 'team', team: [this.parameters] })
                    }
                } else {
                    store.peopleTeams[store.peopleTeamRowIndex] = this.parameters
                }
            } else {
                store.peopleTeams.push(this.parameters)
                store.peopleTeamData.push(
                    {
                        pid: this.state.formFields['people_id'].value,
                        people_id: this.state.formFields['people_id'].label,
                        email: this.state.formFields['people_id'].email,
                        team_role_id: this.state.formFields['team_role_id'].label,
                        is_primary_contact: this.state.formFields['is_primary_contact'].value,
                        is_founder: this.state.formFields['is_founder'].value
                    }
                )
                if (store.isEdit) {
                    store.apis.push({ path: `${store.type}/${store.currentId}/people-team`, method: 'POST', multiType: 'team', team: [this.parameters] })
                }
            }
            this.props.gridApi.setRowData(store.peopleTeamData)
            $('#addPeopleTeamModal').modal('hide')
        }
    }

    handlePeopleTeamSelectChange(option, str) {
        this.state[`${str}Empty`] = false
        this.parameters[str] = option.value
        this.state.formFields[str] = option
        this.setState(this.state)
    }

    render() {

        this.state.formFields = store.peopleTeamDetails
        let obj = {};

        Object.keys(store.peopleTeamDetails).map(key => {
            obj[key] = store.peopleTeamDetails[key] ? store.peopleTeamDetails[key].value : null;
        })

        this.parameters = obj

        const isPrimaryValue = () => {
            if (this.state.formFields.is_primary_contact) {
                if (this.state.formFields.is_primary_contact.value === 1) {
                    return true
                }
            }
            return false
        }
        return (
            <div className="modal fade" id="addPeopleTeamModal" >
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">{store.isPeopleTeamEdit ? 'EDIT PEOPLE' : 'ADD PEOPLE'}</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div className="modal-body">

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >People name{store.isPeopleTeamEdit && store.isEdit && <span className='required'>*</span>}</label>
                                    <AsyncSelect
                                        placeholder='Please Select'
                                        isDisabled={store.isPeopleTeamEdit && store.isEdit ? true : false}
                                        styles={this.state.people_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        cacheOptions
                                        value={this.state.formFields.people_id}
                                        defaultOptions={this.state.people}
                                        loadOptions={(value, callback) => this.handleLoadOptions(value, callback, 'people')}
                                        onChange={(option) => this.handlePeopleTeamSelectChange(option, 'people_id')}
                                    />
                                    {this.state.people_idEmpty && <div className='error'>People name cannot be empty</div>}
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label>People email</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name='email'
                                        value={(this.state.formFields.people_id && this.state.formFields.people_id.email) || "-"}
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Team member's role <span className='required'>*</span></label>
                                    <AsyncSelect
                                        placeholder='Please Select'
                                        styles={this.state.team_role_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        cacheOptions
                                        value={this.state.formFields.team_role_id}
                                        defaultOptions={this.state.teamRoles}
                                        loadOptions={(value, callback) => this.handleLoadOptions(value, callback, 'team_role_id')}
                                        onChange={(option) => this.handlePeopleTeamSelectChange(option, 'team_role_id', false)}
                                    />
                                    {this.state.team_role_idEmpty && <div className='error'>Team role cannot be empty</div>}
                                </div>
                            </div>

                            {store.type === 'startup' &&  <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label>Founder<span className='required'>*</span></label>
                                    <AsyncSelect
                                        placeholder='Please Select'
                                        styles={SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        cacheOptions
                                        value={this.state.formFields.is_founder}
                                        defaultOptions={OPTIONS}
                                        onChange={(option) => this.handlePeopleTeamSelectChange(option, 'is_founder', false)}
                                    />
                                    {this.state.is_founderEmpty && <div className='error'>Founder cannot be empty</div>}
                                </div>
                            </div>}

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">

                                    <div className="form-check">
                                        <input type="checkbox"
                                            className="form-check-input"
                                            id="is_primary_contact"
                                            onChange={(e) => this.handlePeopleTeamSelectChange({ value: e.target.checked ? 1 : 0, label: "is_primary_contact" }, 'is_primary_contact', false)}
                                            name="is_primary_contact"
                                            checked={isPrimaryValue()} />
                                        <label className="form-check-label" htmlFor="is_primary_contact">Primary Contact</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={this.handleAddPeopleTeam}> {store.isPeopleTeamEdit ? 'Update' : 'Add'}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default observer(PeopleTeamModal)
