import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

// helpers
import store from '../../../store';
import { prepareSelectParam } from '../../../utility/queryParams';
import { isObjectEmpty } from '../../../utility/helpers';

// constants
import { FUNDING_COLUMN_HEADERS, TAXONOMIES, ALLOWED_QUERY_OPERATOR, SELECT_STYLES, SELECT_THEME_PRIMARY, ENTITY_TYPES, } from '../../../constant';
import { API_PATH } from '../../../utility/constants/apiPath';

// apis
import { getTaxonomyData, getEntities } from '../../../utility/commonApis';

// components
import Parent from '../../Parent';
import Home from '../../Home';
import SideNav from '../../SideNav';
import Sort from '../../reusable/Sort';
import Pagination from '../../reusable/Pagination';
import Modal from '../../reusable/Modal';
import InvestorsViewModal from '../fundingPopups/InvestorsViewModal';
import InvestorModalActionRenderer from '../fundingActions/InvestorModalActionRenderer';
import ActionRenderer from '../../reusable/ActionRenderer';

class FundingRounds extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: FUNDING_COLUMN_HEADERS,
            context: { componentParent: this },
            frameworkComponents: {
                actionRenderer: ActionRenderer,
                InvestorsModalRenderer: InvestorModalActionRenderer
            },
            defaultColDef: { suppressMovable: true },

            keyword_filter: '',
            showkeywords: false,
            id: '',
            showIds: false,
            investedin_id: '',
            investedin_type: '',
            managerEntityType: '',
            showEntityIds: false,
            funding_stage_id: '',
            funding_stages: [],
            funding_stageStore: [],
            showfunding_stage: false,
            createdAtFrom: '',
            createdAtTo: '',
            fundingDateFrom: '',
            fundingDateTo: '',
            managerEntityType: '',
            managerEntityId: '',
            investorId: '',

            //store
            entities: [],
            investors: [],

            filters: {
                keyword_filter: [],
                id: [],
                investedin_id: [],
                investedin_type: [],
                funding_stage_id: [],
                createdAtFrom: [],
                fundingDateFrom: [],
                fundingDateTo: [],
                createdAtTo: [],
                fund_manager_type_filter: [],
                fund_manager_id_filter: [],
                investor_type_id: [],
            },
            filterFields: [
                { 'key': 'keyword_filter', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'investedin_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'investedin_type', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'funding_stage_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'created_at', 'op': ALLOWED_QUERY_OPERATOR.gte, 'value': [] },
                { 'key': 'created_at', 'op': ALLOWED_QUERY_OPERATOR.lte, 'value': [] },
                { 'key': 'fund_manager_type_filter', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },
                { 'key': 'fund_manager_id_filter', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },
                { 'key': 'investor_type_id', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },
            ]
        }

        store.sortType = this.props.location.pathname.split('/')[1];
    }

    onGridReady(params) {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    componentDidMount() {
        store.isEdit = false;
        store.isAdd = false;

        document.addEventListener('mousedown', (e) => {
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showKeywords: false }) }
            if (this.id) { if (!this.id.contains(e.target)) this.setState({ showIds: false }) }
            if (this.investedin_id) { if (!this.investedin_id.contains(e.target)) this.setState({ showEntityIds: false }) }
            if (this.stage) { if (!this.stage.contains(e.target)) this.setState({ showfunding_stage: false }) }
        })

        // funding rounds request fields
        const paramFields = ['id', 'investedin_data', 'amount_raised', 'funding_stage_data', 'post_money_valuation', 'investors_data', 'created_by_data', 'created_at', 'funding_breakdowns_count'];
        store.fields = prepareSelectParam(paramFields);

        this.fetchListData(API_PATH.funding, store.fields);

        getTaxonomyData(TAXONOMIES.fundingStage).then(res => {
            this.setState({ funding_stages: res, funding_stageStore: res })
        })
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => {
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showKeywords: false }) }
            if (this.stage) { if (!this.stage.contains(e.target)) this.setState({ showfunding_stage: false }) }
            if (this.id) { if (!this.id.contains(e.target)) this.setState({ showIds: false }) }
            if (this.investedin_id) { if (!this.investedin_id.contains(e.target)) this.setState({ showEntityIds: false }) }
        })
    }

    beforeClearAll = () => {
        this.setState({
            createdAtFrom: '',
            createdAtTo: '',
            fundingDateFrom: '',
            fundingDateTo: '',
            managerEntityId: '',
            managerEntityType: '',
            investorId: '',
            investedin_id: '',
            investedin_type: ''
        }, () => {
            this.handleClearAllFilters(API_PATH.funding);
        });
    }

    setEntities = async (option, callback, filterEntityType) => {
        const { investedin_type, managerEntityType } = this.state;
        let value = "";
        let entityType = { value: 'investor' }

        if (filterEntityType === 'investorType') {

            entityType = investedin_type
        } else if (filterEntityType === 'managerEntityType') {

            entityType = managerEntityType
        }

        if (!entityType || isObjectEmpty(entityType)) {
            return;
        }

        if (option && option.length > 2) {
            value = option;
        }

        const entityStoreName = filterEntityType === 'investor' ? 'investors' : 'entities';

        const entityData = await getEntities(entityType.value.toLowerCase(), value);

        this.setState({ [entityStoreName]: entityData });

        if (callback) {
            callback(entityData);
        }
    }

    handleEntityTypeChange = (option, entityTypeFilter) => {
        const entityType = entityTypeFilter === 'investorType' ? 'investedin_type' : 'managerEntityType'

        this.setState({
            [entityType]: option,
        }, () => {
            this.setEntities(false, false, entityTypeFilter);
            this.handleSingleSelectFilter(option, entityType === 'investedin_type' ? 'investedin_type' : 'fund_manager_type_filter');
        });
    }

    render() {

        const handleInstructionMessage = ({ inputValue }) => {
            const { managerEntityType } = this.state;
            if (managerEntityType && !isObjectEmpty(managerEntityType)) {
                return `No option found for ${inputValue} in ${managerEntityType.label}`;
            }
            return "Please first select Fund Manager Type"
        }

        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>FUNDING</h3>
                            <div className='filter-container'>

                                <div className="filter-row" style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                        <Select
                                            placeholder='Search by company type'
                                            styles={SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            options={ENTITY_TYPES}
                                            value={this.state.investedin_type}
                                            onChange={(option) => this.handleEntityTypeChange(option, 'investorType')}
                                        />
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ padding: 0 }}>
                                        <AsyncSelect
                                            placeholder='Search by company name'
                                            styles={SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            cacheOptions
                                            value={this.state.investedin_id}
                                            defaultOptions={this.state.entities}
                                            loadOptions={(option, callback) => this.setEntities(option, callback, 'investorType')}
                                            onChange={(option) => this.handleSingleSelectFilter(option, 'investedin_id')}
                                        />
                                    </div>
                                </div>

                                <div className="filter-row" style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className="col-md-6 col-sm-6 col-12" style={{ paddingLeft: 0 }}>
                                        <div className='filter-firstline' style={{ width: '100%' }}>
                                            <input ref={node => this.input = node}
                                                className='filter-input input-border'
                                                placeholder="Search by Funding ID"
                                                value={this.state.id}
                                                onFocus={() => this.setState({ showKeywords: false, showEntityIds: false, showIds: true })}
                                                onKeyUp={event => this.handleKeywordEnter(event, 'id')}
                                                onChange={e => this.setState({ id: e.target.value })} />
                                            {this.state.showIds && this.state.filters.id.length > 0 ?
                                                <div className='filter-dropdown' style={{ width: '100%' }}>
                                                    <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                        {this.state.filters.id.map((item, i) => {
                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'id')}>
                                                                {item}&nbsp;<i className="fa fa-times-circle"></i>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div> : null}
                                        </div>
                                        {this.state.id.length > 0 &&
											<i className="fa fa-times "
											    style={{ paddingRight: 10 }}
											    onClick={() => {
											        this.input.focus()
											        this.setState({ id: '' })
											    }}>
											</i>
                                        }
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-12 filter-spacing" style={{ padding: 0 }}>
                                        <AsyncSelect
                                            placeholder='Search by Investor Name'
                                            styles={SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            value={this.state.investorId}
                                            defaultOptions={this.state.investors}
                                            loadOptions={(option, callback) => this.setEntities(option, callback, 'investor')}
                                            onFocus={(option, callback) => this.setEntities(option, callback, 'investor')}
                                            onChange={(option) => this.handleSingleSelectFilter(option, 'investor_type_id')}
                                        />
                                    </div>

                                </div>

                                <div className="filter-row" style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className="col-md-6 col-sm-6 col-12" style={{ paddingLeft: 0 }}>
                                        <div className='filter-firstline' style={{ width: '100%' }}>
                                            <input ref={node => this.input = node}
                                                className='filter-input input-border'
                                                placeholder="Search by Keyword"
                                                value={this.state.keyword_filter}
                                                onFocus={() => this.setState({ showKeywords: true, showIds: false, showEntityIds: false })}
                                                onKeyUp={(event) => this.handleKeywordEnter(event, 'keyword_filter')}
                                                onChange={e => this.setState({ keyword_filter: e.target.value })} />
                                            {this.state.showKeywords && this.state.filters.keyword_filter.length > 0 ?
                                                <div className='filter-dropdown' style={{ width: '100%' }}>
                                                    <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                        {this.state.filters.keyword_filter.map((word, i) => {
                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'keyword_filter')}>
                                                                {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div> : null}
                                        </div>
                                        {this.state.keyword_filter.length > 0 &&
											<i className="fa fa-times "
											    style={{ paddingRight: 10 }}
											    onClick={() => {
											        this.input.focus()
											        this.setState({ keyword_filter: '' })
											    }}>
											</i>
                                        }
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-12" style={{ padding: 0 }}>
                                        <div ref={node => this.stage = node} className='filter-firstline' >
                                            <div style={{ width: '100%' }}>
                                                <input className='filter-input'
                                                    style={{ paddingLeft: 15 }}
                                                    placeholder="By Stage of Funding"
                                                    value={this.state.funding_stage_id}
                                                    onChange={(e) => this.handleInputChange(e.target.value, 'industry')}
                                                    onFocus={() => { this.setState({ showfunding_stage: true }) }} />
                                                {this.state.showfunding_stage && this.state.funding_stages ?
                                                    <div className='filter-dropdown' >
                                                        <div >
                                                            {this.state.filters.funding_stage_id.length > 0 ?
                                                                <div className='filter-dropdown-wrapper' >
                                                                    {this.state.filters.funding_stage_id.map((item, i) => {
                                                                        return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'funding_stage_id')}>
                                                                            {item}&nbsp;<i className="fa fa-times-circle"></i>
                                                                        </div>
                                                                    })}
                                                                </div> : null}
                                                        </div>
                                                        <ul className='filter-list-container'>
                                                            <div className='filter-list-wrapper' >
                                                                <div>
                                                                    {this.state.funding_stages.map((item, j) =>
                                                                        <div key={j}>
                                                                            <li style={{ fontSize: 12, }} onClick={() => this.handleSelectFilter(item, 'funding_stage_id')}>
                                                                                {item.label}
                                                                            </li><br />
                                                                        </div>)}
                                                                </div>
                                                                <div className='filter-stickybtn ' >
                                                                    <div style={{ width: 140 }}>
                                                                        <a tabIndex='0' className='filter-canclebtn' onClick={() => this.handleClearFilter('funding_stage_id')}>Clear</a> &nbsp; | &nbsp;
                                                                        <a tabIndex='0' className='filter-applybtn' onClick={() => { this.setState({ showfunding_stage: false }); this.handleSearchFilters(API_PATH.funding) }}>Apply</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div> : null}
                                            </div>
                                            {this.state.showfunding_stage ? <i className="fa fa-chevron-up " style={{ paddingRight: 10 }} onClick={() => this.setState({ showfunding_stage: false })} ></i> :
                                                <i className="fa fa-chevron-down " style={{ paddingRight: 10 }} onClick={() => this.setState({ showfunding_stage: true })} ></i>}
                                        </div>
                                    </div>
                                </div>

                                <div className="filter-row" style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                        <Select
                                            placeholder='Search by Fund Manager Type'
                                            styles={SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            value={this.state.managerEntityType}
                                            onChange={this.handleEntityTypeChange}
                                            options={ENTITY_TYPES}
                                        />
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12 filter-spacing" style={{ padding: 0 }}>
                                        <AsyncSelect
                                            placeholder='Search by Fund Manager Name'
                                            styles={SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            value={this.state.managerEntityId}
                                            defaultOptions={this.state.entities}
                                            loadOptions={(option, callback) => this.setEntities(option, callback, 'managerEntityType')}
                                            onFocus={(option, callback) => this.setEntities(option, callback, 'managerEntityType')}
                                            noOptionsMessage={handleInstructionMessage}
                                            onChange={(option) => this.handleSingleSelectFilter(option, 'fund_manager_id_filter')}
                                        />
                                    </div>
                                </div>

                                <div className="filter-row" style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                        <div ref={node => this.createdAtFrom = node} className='filter-firstline' >
                                            <div style={{ width: '100%' }}>
                                                <DatePicker
                                                    placeholderText="Created at - From"
                                                    className='filter-datepicker-form-control'
                                                    dateFormat="yyyy/MM/dd"
                                                    isClearable={this.state.createdAtFrom}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    selected={this.state.createdAtFrom}
                                                    onChange={(date) => this.handleDateChange(date, 'createdAtFrom', 'created_at', ALLOWED_QUERY_OPERATOR.gte)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-12 filter-spacing" style={{ padding: 0 }}>
                                        <div ref={node => this.createdAtTo = node} className='filter-firstline' >
                                            <div style={{ width: '100%' }}>
                                                <DatePicker
                                                    placeholderText="Created at - To"
                                                    className='filter-datepicker-form-control'
                                                    dateFormat="yyyy/MM/dd"
                                                    minDate={this.state.createdAtFrom}
                                                    maxDate={new Date()}
                                                    isClearable={this.state.createdAtTo}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    selected={this.state.createdAtTo}
                                                    onChange={(date) => this.handleDateChange(date, 'createdAtTo', 'created_at', ALLOWED_QUERY_OPERATOR.lte)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-6 filter-searchbtn" onClick={this.beforeClearAll}>Clear All</a>
                                    </div>
                                </div>

                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    defaultColDef={this.state.defaultColDef}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    frameworkComponents={this.state.frameworkComponents}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                >
                                </AgGridReact>
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />
                        <InvestorsViewModal />
                    </div>
                }
            </div>
        )
    }
}


export default observer(FundingRounds);
