import React from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import NumberFormat from "react-number-format";
import moment from 'moment';

// helpers
import store from '../../../store';
import { displayAmount, isObjectEmpty } from '../../../utility/helpers';

// constants
import { SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY, TAXONOMIES, ENTITY_TYPES, FUNDING_STATUS_LABELS, DATE_FORMAT } from '../../../constant';

// apis
import { getTaxonomyData } from '../../../utility/commonApis';
import fundingApi from '../../../apis/fundingApi';

// components
import Home from '../../Home';
import FormParent from '../../FormParent';
import SideNav from '../../SideNav';
import Modal from '../../reusable/Modal';
import FundingBreakdowns from './FundingBreakdowns';

class FundingForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                entity_type: '',
                entity_id: '',
                funding_stage_id: '',
                amount_raised: '',
                pre_money_valuation: '',
                post_money_valuation: '',
                breakdown: []
            },
            companies: [],
            fundingStages: [],
            fundingSources: [],
            editParams: [],
            validationFields: ['entity_type', 'entity_id', 'funding_stage_id'],
            isFundingBreakdownMissing: false
        }
        this.parameters = {
            entity_type: '',
            entity_id: '',
            funding_stage_id: '',
            amount_raised: '',
            pre_money_valuation: '',
            post_money_valuation: '',
            breakdown: []
        }
    }

    componentDidMount() {
        store.fundingBreakdowns = [];
        store.fundingBreakdownsData = [];

        if (store.isEdit && store.currentId) {
            fundingApi.getFundingDetails(store.currentId).then(
                response => {
                    let objData = {};

                    Object.keys(this.state.formFields).map(key => {
                        if (key === 'entity_type') {
                            objData[key] = response.entity_type ? { 'value': response.entity_type, 'label': response.entity_type } : '';
                        }
                        else if (key === 'entity_id') {
                            objData[key] = !isObjectEmpty(response.investedin_data) ? { 'value': response.investedin_data.id, 'label': response.investedin_data.name } : '';
                        }
                        else if (key === 'funding_stage_id') {
                            objData[key] = !isObjectEmpty(response.funding_stage_data) ? { 'value': response.funding_stage_data.id, 'label': response.funding_stage_data.name } : ''
                        }
                        else if (key === 'breakdown' && response.funding_breakdowns_data) {
                            let arrDisplayData = [];
                            let arrStoreData = [];

                            response.funding_breakdowns_data.map(item => {
                                arrDisplayData.push({
                                    id: item.funding_breakdown_id,
                                    investment_vehicle_name: item.investment_vehicle_data.name,
                                    investment_vehicle_id: item.investment_vehicle_data,
                                    date_raised: moment(item.date_raised).format(DATE_FORMAT),
                                    amount: displayAmount(item.amount),
                                    verification_status: FUNDING_STATUS_LABELS[item.verification_status],
                                    investors: item.investors_data
                                });

                                arrStoreData.push({
                                    id: item.funding_breakdown_id,
                                    investment_vehicle_id: item.investment_vehicle_data.id,
                                    date_raised: item.date_raised,
                                    amount: item.amount,
                                    verification_status: item.verification_status,
                                    investors: item.investors_data
                                });
                            });

                            store.fundingBreakdowns = arrStoreData;
                            store.fundingBreakdownsData = arrDisplayData;
                        }
                        else if (key === 'amount_raised') {
                            objData[key] = response.amount_raised ? response.amount_raised : '';
                        }
                        else if (key === 'post_money_valuation') {
                            objData[key] = response.post_money_valuation ? response.post_money_valuation : '';
                        }
                        else if (key === 'pre_money_valuation') {
                            objData[key] = response.pre_money_valuation ? response.pre_money_valuation : '';
                        }
                        else {
                            objData[key] = response[key] !== null ? response[key] : ''
                        }
                    });

                    this.setState({ formFields: objData });
                }
            );
        }

        getTaxonomyData(TAXONOMIES.fundingStage).then(res => {
            this.setState({ fundingStages: res })
        })

        getTaxonomyData(TAXONOMIES.fundingSourceType).then(res => {
            this.setState({ fundingSources: res })
        })
    }

    handleFundingAdd = () => { 
        this.handleEmptyFieldValidation();

        if (store.fundingBreakdowns.length === 0) {
            this.setState({ isFundingBreakdownMissing: true });
        } else {
            this.parameters.breakdown = store.fundingBreakdowns; 
            this.handleAdd('funding');
        }
    };

    renderErrors = () => {
        let {
            entity_typeEmpty,
            entity_idEmpty,
            funding_stage_idEmpty,
            amount_raisedValid
        } = this.state;

        let errors = [];

        if (entity_typeEmpty) {
            errors.push(<div className='error'>Type cannot be empty</div>)
        }
        if (entity_idEmpty) {
            errors.push(<div className='error'>Company name cannot be empty</div>)
        }
        if (funding_stage_idEmpty) {
            errors.push(<div className='error'>Funding stage cannot be empty</div>)
        }
        if (amount_raisedValid) {
            errors.push(<div className='error'>Amount raised should be number</div>)
        }

        return errors;
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT FUNDING</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD FUNDING</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Entity Type <span className='required'>*</span></label>
                                            <Select
                                                name='entity_type'
                                                placeholder='Please select a type'
                                                styles={this.state.entity_typeEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.entity_type}
                                                onChange={(option) => this.handleSelectEntityType(option, 'entity_type')}
                                                options={ENTITY_TYPES}
                                            />
                                            {this.state.entity_typeEmpty && <div className='error'>Type cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Company Name <span className='required'>*</span></label>
                                            <AsyncSelect
                                                name='entity_id'
                                                placeholder='Please select or type to search'
                                                styles={this.state.entity_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                isDisabled={!this.state.formFields.entity_type}
                                                value={this.state.formFields.entity_id}
                                                defaultOptions={this.state.companies}
                                                loadOptions={(value, callback) => this.handleLoadOptions(value, callback, this.state.formFields.entity_type.value)}
                                                onChange={(option) => this.handleSelectChange(option, 'entity_id')}
                                                onFocus={() => { this.handleLoadCompanies(this.state.formFields.entity_type.value) }}
                                            />
                                            {this.state.entity_idEmpty && <div className='error'>Company name cannot be empty</div>}
                                        </div>
                                    </div>
                                    <hr />
                                

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Funding Stage <span className='required'>*</span></label>
                                            <Select
                                                name='funding_stage'
                                                placeholder='Please select a stage'
                                                styles={this.state.funding_stage_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.funding_stage_id}
                                                onChange={(option) => this.handleSelectChange(option, 'funding_stage_id')}
                                                options={this.state.fundingStages}
                                            />
                                            {this.state.funding_stage_idEmpty && <div className='error'>Funding stage cannot be empty</div>}
                                        </div>
                                    </div>
                                    <hr />

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Amount Raised</label>
                                            <NumberFormat
                                                title='number'
                                                className={this.state.amount_raisedValid ? "form-control input-error" : "form-control"}
                                                name='amount_raised'
                                                placeholder='0'
                                                value={this.state.formFields.amount_raised}
                                                onChange={this.handleInputChange}
                                                thousandSeparator={true}
                                            />
                                            {this.state.amount_raisedValid && <div className='error'>Amount raised should be number</div>}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Pre Money Valuation</label>
                                            <NumberFormat
                                                title='number'
                                                className={"form-control"}
                                                name='pre_money_valuation'
                                                placeholder='0'
                                                value={this.state.formFields.pre_money_valuation}
                                                onChange={this.handleInputChange}
                                                thousandSeparator={true}
                                            />
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Post Money Valuation</label>
                                            <NumberFormat
                                                title='number'
                                                className={"form-control"}
                                                name='post_money_valuation'
                                                placeholder='0'
                                                value={this.state.formFields.post_money_valuation}
                                                onChange={this.handleInputChange}
                                                thousandSeparator={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <FundingBreakdowns 
                                entity_type={this.state.formFields.entity_type.value}
                                entity_id={this.state.formFields.entity_id.value}
                            />

                            {this.renderErrors()}
                            {this.state.isFundingBreakdownMissing &&
                                <div className='error'>Funding breakdowns cannot be empty</div>}

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ? <button className="btn-primary bottom-btn" disabled={this.state.editParams.length > 0 || store.apis.length > 0 ? false : true} onClick={() => this.handleUpdate('funding')}>Save</button> :
                                    <button className="btn-primary bottom-btn" onClick={this.handleFundingAdd}>Add Funding</button>}
                                <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                            </div>

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}

export default observer(FundingForm)
