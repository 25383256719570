import React from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';

// helpers
import store from '../../store';
import {  prepareSelectParam } from '../../utility/queryParams'


// constants
import { SELECT_STYLES,SELECT_THEME_PRIMARY, OPTIONS } from '../../constant';
import { API_PATH } from '../../utility/constants/apiPath';

// apis
import technologyApi from '../../apis/technologyApi';

// components
import FormParent from '../FormParent';
import Home from '../Home';
import SideNav from '../SideNav';
import Modal from '../reusable/Modal';


class TechnologyForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                name: '',
                description: '',
                status: '',
            },
            editParams: [],
            validationFields: [ 'name'],

        }
        this.parameters = {
            name: '',
            description: '',
            status: 1
        }
    }

    componentDidMount() {
        if (store.isEdit && store.currentId) {
            const paramFields = ['name', 'description', 'status'];
            const fields = prepareSelectParam(paramFields);
        
            technologyApi.getTechnologyDetails(store.currentId, fields).then(
                response => {
                    console.log('response', response);
                    let objData = {};
        
                    Object.keys(this.state.formFields).map(key => {
                        if (key === 'status') {
                            objData[key] = response.status === 1 ? { value: 1, label: 'Yes' } : { value: 0, label: 'No' };
                        } else {
                            objData[key] = response[key] !== null ? response[key] : ''
                        }
                    });
        
                    this.setState({
                        formFields: objData,
                    });
                }
            );
        }


    }

    handleTechnologyAdd = () => {
        this.handleAdd(API_PATH.technology);
    };

    renderErrors = () => {
        let {
            nameEmpty,
        } = this.state;

        let errors = [];

        if (nameEmpty) {
            errors.push(<div className='error'>Technology name cannot be empty</div>)
        }
        return errors;
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>Edit Technology</h3> :
                                <h3 style={{ textAlign: 'center' }}>Add Technology</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Technology Name <span className='required'>*</span></label>
                                            <input
                                                className={this.state.nameEmpty ? "form-control input-error" : "form-control"}
                                                type="text"
                                                name="name"
                                                value={this.state.formFields.name}
                                                onChange={this.handleInputChange} />
                                            {this.state.nameEmpty && <div className='error'>Technology name cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Technology Description </label>
                                            <input
                                                className={"form-control"}
                                                type="text"
                                                name="description"
                                                value={this.state.formFields.description}
                                                onChange={this.handleInputChange} />
                                        </div>

                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Status</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.status}
                                                onChange={(option) => this.handleSelectChange(option, 'status')}
                                                options={OPTIONS}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {this.renderErrors()}

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ? <button className="btn-primary bottom-btn" disabled={this.state.editParams.length > 0 || store.apis.length > 0 ? false : true} onClick={() => this.handleUpdate(API_PATH.technology)}>Save</button> :
                                    <button className="btn-primary bottom-btn" onClick={this.handleTechnologyAdd}>Add Technology</button>}
                                <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                            </div>

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />
                    </div>
                }
            </div>
        )
    }
}

export default observer(TechnologyForm)
