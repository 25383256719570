import axios from 'axios';
import store from '../store';
import { ALLOWED_QUERY_OPERATOR, API } from '../constant';
import { prepareSelectParam, prepareFilterParam } from '../utility/queryParams';


const salesAutomationEmailApi = {
    getSalesAutomationEmailDetails: (salesAutomationEmailId = 0) => {
        const paramFields = [
            'id',
            'active',
            'taxonomy_id',
            'taxonomy_name',
            'email_template_id',
            'email_template_name',
        ];
        const fields = prepareSelectParam(paramFields);
        const url = `${API}/sales-automation-email/${salesAutomationEmailId}?fields=${fields}`;
        const headers = {
            Authorization: store.token
        };
        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(response => {
                    if (response.data.status === 200) {
                        resolve(response.data.data);
                    } else {
                        reject({});
                    }
                }).catch(err => reject(err))
        })
    }
}
export default salesAutomationEmailApi;
