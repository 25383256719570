import axios from 'axios';

import { API } from '../constant';
import store from '../store';

const orderApi = {

    addUserCustomSubscription: (orderId = null, objData = {}) => {
        const url = `${API}/order/${orderId}/user-custom-subscription`;

        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        return new Promise((resolve, reject) => {
            axios.post(url, objData, { headers }).then((response) => {
                resolve(response.data.data);
            }).catch((err) => reject(err));
        });
    }
}

export default orderApi;