import React from 'react'
import { observer } from 'mobx-react'
import AsyncSelect from 'react-select/async'
import CreatableSelect from 'react-select/creatable';
// contants
import { prepareFilterParam, prepareSelectParam } from '../../utility/queryParams'

// helpers
import store from '../../store'
import { isObjectEmpty } from '../../utility/helpers';

// apis
import taxonomyApi from '../../apis/taxonomyApi';

// components
import { ALLOWED_QUERY_OPERATOR, SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY, API } from '../../constant'
import SideNav from '../SideNav'
import Modal from '../reusable/Modal'
import FormParent from '../FormParent'
import Home from '../Home'


class PermissionForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                role_id: '',
                resource_id: '',
                policy: {},
                weight: '',
            },
            code: '',
            roles: [],
            users: [],
            resources: [],
            attributes: [],
            all_attribute: '',
            allowed: '',
            not_allowed: '',
            allow_anyone: '',
            allow: '',
            not_allow: '',
            allowed_list: [],
            not_allowed_list: [],
            allow_anyone_list: [],
            allow_list: [],
            not_allow_list: [],
            limit: '',
            policy: {
                attributes: {
                    allow: [],
                    not_allowed: []
                },
                filters: {
                    allow: [],
                    not_allowed: [],
                    allow_anyone: []
                },
                limit: ''
            },
            editParams: [],
            validationFields: ['role_id', 'resource_id', 'weight'],
            role_idSelected: '',
            resource_idSelected: '',
            weightSelected: '',
            showPermissionsTable: false,
            exsistingPermission: false,
            permissionsData: []
        }

        this.parameters = {
            policy: {
                attributes: {
                    allow: [],
                    not_allowed: []
                },
                filters: {
                    allow: [],
                    not_allowed: [],
                    allow_anyone: []
                },
                limit: ''
            },
            role_id: '',
            resource_id: '',
            weight: '',
        }
    }

    componentDidMount() {
        if ((store.isEdit || store.isCopy) && store.currentId) {
            let paramFields = ['role_id', 'user_id', 'resource_id', 'policy', 'weight', 'resource_data', 'role_data']
            let fields = prepareSelectParam(paramFields)

            fetch(`${API}/permission/${store.currentId}/?fields=${fields}`, {
                method: 'GET',
                headers: {
                    'Authorization': store.token,
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(async (data) => {
                    if (data.status === 200) {
                        if (data.data.policy) {
                            if (data.data.policy.attributes) {
                                if (data.data.policy.attributes && data.data.policy.attributes.allow) {
                                    this.parameters.policy.attributes['allow'] = data.data.policy.attributes.allow
                                }
                            
                                if (data.data.policy.attributes.not_allowed) {
                                    this.parameters.policy.attributes['not_allowed'] = data.data.policy.attributes.not_allowed
                                }
                            }
                        
                            if (data.data.policy.attributes && data.data.policy.filters) {
                                if (data.data.policy.filters.allow) {
                                    this.parameters.policy.filters['allow'] = data.data.policy.filters.allow
                                }
                            
                                if (data.data.policy.filters.not_allowed) {
                                    this.parameters.policy.filters['not_allowed'] = data.data.policy.filters.not_allowed
                                }
                            
                                if (data.data.policy.filters.allow_anyone) {
                                    this.parameters.policy.filters['allow_anyone'] = data.data.policy.filters.allow_anyone
                                }
                            }

                            if (data.data.policy && data.data.policy.limit) {
                                this.parameters.policy.limit = data.data.policy.limit
                            }
                        }

                        Object.keys(this.state.formFields).map(key => {
                            if (key === 'role_id') {
                                this.parameters[key] = data.data.role_data !== undefined ? { 'value': data.data.role_id, 'label': data.data.role_data.name } : ''
                            }
                            // todo: This code needs to be refactored
                            else if (key === 'resource_id' && !isObjectEmpty(data.data.resource_data)) {
                                this.parameters[key] = data.data.resource_data.name ? { 'value': data.data.resource_id, 'label': data.data.resource_data.name } : ''
                                this.state.code = data.data.resource_data.code ? data.data.resource_data.code : ""
                            }
                            else if (key === 'weight') {
                                this.parameters[key] = data.data.weight
                            }
                        })
                    
                        if (this.parameters.policy) {
                            this.setState({
                                policy: {
                                    ...this.parameters.policy
                                }
                            })
                        }
                    
                        this.setState({ formFields: {
                            role_id: this.parameters.role_id,
                            resource_id: this.parameters.resource_id,
                            weight: this.parameters.weight
                        },
                        role_idSelected: this.parameters.role_id.value,
                        resource_idSelected: this.parameters.resource_id.value,
                        weightSelected: parseInt(this.parameters.weight) });

                        // Prepare attributes
                        let attributesData = await fetch(`${API}/resource/${data.data.resource_id}/attribute`, {
                            method: 'GET',
                            headers: {
                                'Authorization': store.token,
                                'Content-Type': 'application/json'
                            },
                        });

                        let arAttributes = []
                        attributesData = await attributesData.json()
                        attributesData = attributesData.data
                        if (attributesData.attribute && attributesData.attribute.length > 0) {
                            for (let i = 0; i < attributesData.attribute.length; i++) {
                            // arAttributes
                                arAttributes.push({
                                    label: attributesData.attribute[i].label,
                                    value: attributesData.attribute[i].key
                                });
                            }

                            this.setState({
                                attributes: arAttributes
                            })
                        }
                    }
                })
                .catch(err => {
                    console.error(err)
                })

        }

        taxonomyApi.getMagnittRoles().then(
            response => {
                const roles = response.map(role => {
                    return { value: role.id, label: role.name };
                });
                this.setState({ roles });
            });

        fetch(`${API}/resource?limit=100`, {
            method: 'GET',
            headers: {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.status === 200) {
                    let arr = []
                    data.data.resources.map(item => {
                        arr.push({ value: item.id, label: item.name })
                    })
                    this.setState({ resources: arr })
                }
            })
            .catch(err => {
                console.error(err)
            })
    }

    async handleResourceSelectChange(option, str) {
        if (store.isEdit) {
            this.state.editParams.push(str)
        }

        this.state.attributes = [];

        if (option.value) {
            let data = await fetch(`${API}/resource/${option.value}/attribute`, {
                method: 'GET',
                headers: {
                    'Authorization': store.token,
                    'Content-Type': 'application/json'
                },
            });

            data = await data.json();
            data = data.data;
            if (data.attribute && data.attribute.length > 0) {
                for (let i = 0; i < data.attribute.length; i++) {
                    this.state.attributes.push({ label: data.attribute[i].label, value: data.attribute[i].key });
                }
            }
        }

        this.state[`${str}Empty`]   = false
        this.state.formFields[str]  = option
        this.parameters[str]        = option.value
        this.setState(this.state)
    }

    onSubmit() {
        this.parameters.policy = this.state.policy

        // Handle role_id and resource_id value if it copy mode
        if (store.isCopy && this.parameters.role_id && this.parameters.role_id.value) {
            this.parameters.role_id = this.parameters.role_id.value
        }

        if (store.isCopy && this.parameters.resource_id && this.parameters.resource_id.value) {
            this.parameters.resource_id = this.parameters.resource_id.value
        }

        this.handleAdd('permission')
    }

    handlePermissionSelectChange(option, str, field, isCreate = false) {
        if (store.isEdit) {
            let arEditParams = this.state.editParams
            arEditParams.push('policy')
            this.setState({
                editParams: arEditParams
            });
        }

        if (isCreate) {
            let arAttributes = this.state.attributes;
            
            option = {
                label: option,
                value: option
            };

            arAttributes.push(option);
            
            this.setState({
                attributes: arAttributes
            });
        }
        
        if (this.state.policy[field][str].indexOf(option.value) === -1) {
            let objPolicy = this.state.policy

            objPolicy[field][str].push(option.value)
            this.setState({policy: {...objPolicy}})
        }
    }

    handlePermissionInputChange = (event) => {
        if (store.isEdit) {
            this.state.editParams.push('policy')
        }

        this.state[`${event.target.name}Number`] = /^\d*$/.test(event.target.value) || event.target.value === '' ? false : true
        this.state[event.target.name] = event.target.value
        this.state.policy[event.target.name] = event.target.value
        Object.keys(this.state.policy).map(key => {
            if (key === 'limit') {
                this.parameters.policy[key] = this.state.policy[key]
            }
        })
        this.setState(this.state)
    }

    handleRemoveAttributes(index, value, field, key) {
        if (store.isEdit) {
            this.state.editParams.push('policy')
        }

        if (this.state.policy[field][key]) {
            this.state.policy[field][key].splice(index, 1);
            this.setState(this.state.policy[field][key]);
        }
    }

    handleRoleChange = (option) => {
        this.setState({ role_idSelected: option.value }, () => {
            this.handlePermissionValidation();
            this.handleSelectChange(option, 'role_id');
        });
    }

    handleResourceChange = (option) => {
        this.setState({ resource_idSelected: option.value }, () => {
            this.handlePermissionValidation();
            this.handleResourceSelectChange(option, 'resource_id');
        });
    }

    handleWeightChange = (option) => {
        this.handleInputChange(option);
        this.setState({ weightSelected: parseInt(option.target.value) }, () => {
            this.handlePermissionValidation();
        });
    }

    async handlePermissionValidation() {
        if (this.state.resource_idSelected) {
            let rbacCheck = await fetch(`${API}/permission/${this.state.resource_idSelected}/validate`, {
                method: 'GET',
                headers: {
                    'Authorization': store.token,
                    'Content-Type': 'application/json'
                },
            });

            rbacCheck = await rbacCheck.json();
            if (rbacCheck.data.length > 0) {
                this.setState({ showPermissionsTable: true, exsistingPermission: false })
                let tableRows = [];
                let content = '';

                rbacCheck.data.map((item, index) => {
                    if (this.state.role_idSelected && this.state.resource_idSelected && (this.state.weightSelected > 0 || this.state.weightSelected === 0)) {
                        if (this.state.resource_idSelected == item.id && this.state.weightSelected == item.weight) {
                            this.setState({ exsistingPermission: true })
                        }
                    }
                    
                    tableRows.push(
                        <tr key={index} className={(this.state.resource_idSelected === item.id && this.state.weightSelected === item.weight) ? "permissionTableAlert" : ""}>
                            <td>{item.id}</td>
                            <td>{item.name}</td>
                            <td>{item.code}</td>
                            <td>{item.method}</td>
                            <td>{item.role_id}</td>
                            <td>{item.role_name}</td>
                            <td>{item.weight}</td>
                        </tr>
                    );
                });

                content = <table className="table table-bordered" style={{ width: '100%', color: '#5d656d' }}>
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">Resource ID</th>
                            <th scope="col">Resource Name</th>
                            <th scope="col">Resource Code</th>
                            <th scope="col">Resource Method</th>
                            <th scope="col">Role ID</th>
                            <th scope="col">Role Name</th>
                            <th scope="col">Weight</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </table>

                this.setState({ permissionsData: content })
            }
        }
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className="content">
                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className="content-right">
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT PERMISSION</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD PERMISSION</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Role<span className='required'>*</span></label>
                                            <AsyncSelect
                                                name='role_id'
                                                placeholder='Please Select'
                                                styles={this.state.role_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                cacheOptions
                                                value={this.state.formFields.role_id}
                                                defaultOptions={this.state.roles}
                                                loadOptions={(value, callback) => this.handleLoadOptions(value, callback, 'role')}
                                                onChange={this.handleRoleChange}
                                            />
                                            {this.state.role_idEmpty && <div className='error'>Role cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Resource <span className='required'>*</span></label>
                                            <AsyncSelect
                                                name='resource_id'
                                                placeholder='Please Select or Type to search'
                                                cacheOptions
                                                styles={this.state.resource_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                defaultOptions={this.state.resources}
                                                loadOptions={(value, callback) => this.handleLoadOptions(value, callback, 'resource')}
                                                value={this.state.formFields.resource_id}
                                                onChange={this.handleResourceChange}
                                            />
                                            {this.state.resource_idEmpty && <div className='error'>Resource cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Weight <span className='required'>*</span></label>
                                            <input title='number'
                                                name='weight'
                                                type="number"
                                                className={(this.state.weightNumber || this.state.weightEmpty) ? "form-control input-error" : "form-control"}
                                                value={this.state.formFields.weight}
                                                onChange={this.handleWeightChange} />
                                            {this.state.weightNumber && <div className='error'>Weight should be number</div>}
                                            {this.state.weightEmpty && <div className='error'>Weight cannot be empty</div>}
                                        </div>
                                    </div>
                                    {this.state.exsistingPermission && <div className='error'>Permission already exists (Selected resource and weight combination should be unique).</div>}
                                </div>
                            </div>


                            {this.state.showPermissionsTable &&
                                <div className='form-container' style={{ marginTop: 10 }}>
                                    <h5>EXISTING PERMISSION</h5>
                                    <div className='permissionTableScroll'>
                                        {this.state.permissionsData}
                                    </div>
                                </div>
                            }

                            <div className='form-container' style={{ marginTop: 10 }}>
                                <div className='form-wrapper'>

                                    <h5>POLICY</h5>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Allowed Attributes</label>
                                            <CreatableSelect
                                                placeholder='Select allowed Attributes'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.policy.attributes.allow}
                                                onChange={option => this.handlePermissionSelectChange(option, 'allow', 'attributes')}
                                                onCreateOption={option => this.handlePermissionSelectChange(option, 'allow', 'attributes', true)}
                                                options={this.state.attributes}
                                            />
                                            {this.state.policy.attributes['allow'] ?
                                                this.state.policy.attributes.allow.length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state.policy.attributes.allow.map((item, i) => {
                                                        return <li key={i}>{item} <i className='fa fa-times-circle' onClick={() => this.handleRemoveAttributes(i, item, 'attributes', 'allow')}></i></li>
                                                    })}
                                                </div> : null
                                            }
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Not Allowed Attributes</label>
                                            <CreatableSelect
                                                placeholder='Select Not allowed Attributes'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.policy.attributes.not_allowed}
                                                onChange={option => this.handlePermissionSelectChange(option, 'not_allowed', 'attributes')}
                                                onCreateOption={option => this.handlePermissionSelectChange(option, 'not_allowed', 'attributes', true)}
                                                options={this.state.attributes}
                                            />
                                            {this.state.policy.attributes['not_allowed'] ?
                                                this.state.policy.attributes.not_allowed.length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state.policy.attributes.not_allowed.map((item, i) => {
                                                        return <li key={i}>{item} <i className='fa fa-times-circle' onClick={() => this.handleRemoveAttributes(i, item, 'attributes', 'not_allowed')}></i></li>
                                                    })}
                                                </div> : null
                                            }
                                        </div>

                                    </div>

                                    <div className='row'>
                                        <div className="col-md-4 col-sm-4 col-xs-12 form-group">
                                            <label >Allow Anyone Filter</label>
                                            <CreatableSelect
                                                placeholder='Select allow anyone filter'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.policy.filters.allow_anyone}
                                                onChange={option => this.handlePermissionSelectChange(option, 'allow_anyone', 'filters')}
                                                onCreateOption={option => this.handlePermissionSelectChange(option, 'allow_anyone', 'filters', true)} 
                                                options={this.state.attributes}
                                            />
                                            {this.state.policy.filters['allow_anyone'] ?
                                                this.state.policy.filters.allow_anyone.length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state.policy.filters.allow_anyone.map((item, i) => {
                                                        return <li key={i}>{item} <i className='fa fa-times-circle' onClick={() => this.handleRemoveAttributes(i, item, 'filters', 'allow_anyone')}></i></li>
                                                    })}
                                                </div> : null
                                            }
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Allowed Filters</label>
                                            <CreatableSelect
                                                placeholder='Select allowed filters'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.policy.filters.allow}
                                                onChange={option => this.handlePermissionSelectChange(option, 'allow', 'filters')}
                                                onCreateOption={option => this.handlePermissionSelectChange(option, 'allow', 'filters', true)}
                                                options={this.state.attributes}
                                            />
                                            {this.state.policy.filters['allow'] ?
                                                this.state.policy.filters.allow.length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state.policy.filters.allow.map((item, i) => {
                                                        return <li key={i}>{item} <i className='fa fa-times-circle' onClick={() => this.handleRemoveAttributes(i, item, 'filters', 'allow')}></i></li>
                                                    })}
                                                </div> : null
                                            }
                                        </div>

                                        <div className="col-md-4 col-sm-4 col-xs-12 form-group">
                                            <label >Not Allowed Filters</label>
                                            <CreatableSelect
                                                placeholder='Select not allowed filters'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.policy.filters.not_allowed}
                                                onChange={option => this.handlePermissionSelectChange(option, 'not_allowed', 'filters')}
                                                onCreateOption={option => this.handlePermissionSelectChange(option, 'not_allowed', 'filters', true)}
                                                options={this.state.attributes}
                                            />
                                            {this.state.policy.filters['not_allowed'] ?
                                                this.state.policy.filters.not_allowed.length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state.policy.filters.not_allowed.map((item, i) => {
                                                        return <li key={i}>{item} <i className='fa fa-times-circle' onClick={() => this.handleRemoveAttributes(i, item, 'filters', 'not_allowed')}></i></li>
                                                    })}
                                                </div> : null
                                            }
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Limit</label>
                                            <input title='number' name='limit' className="form-control" value={this.state.policy.limit} onChange={this.handlePermissionInputChange} />
                                            {this.state.limitNumber && <div className='error'>Limit should be number</div>}
                                        </div>
                                    </div>

                                </div>

                            </div>

                            {this.state.exsistingPermission && <div className='error'>Permission already exists (Selected resource and weight combination should be unique).</div>}

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ? <button className="btn-primary bottom-btn" disabled={this.state.exsistingPermission || (this.state.editParams.length > 0 ? false : true)} onClick={() => this.handleUpdate('permission')}>Save</button> :
                                    <div>
                                        <button className="btn-primary bottom-btn" disabled={this.state.exsistingPermission} onClick={() => this.onSubmit()}>Add permission</button>
                                        <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                                    </div>
                                }
                            </div>

                        </div>

                    </div>
                }
                {store.isSideNav && <div className='overlay'> </div>}
                <Modal />

            </div>
        )
    }
}

export default observer(PermissionForm)
