import React, { Component } from 'react';

// constants
import { MAX_FILE_SIZE, ALLOWED_QUERY_OPERATOR, LINKEDIN, FACEBOOK, TWITTER, USER_STATUS_OBJ, ACTIVE_STATUS_OBJ } from '../constant';
import { API_PATH } from '../utility/constants/apiPath';

// helpers
import store from '../store';
import { prepareFilterParam } from '../utility/queryParams';
import { isValidEmail, isValidImageFile, isValidFile, extPrimaryEmail, isObjectEmpty } from '../utility/helpers';

// apis
import Api from '../utility/api';
import { getCities } from '../utility/commonApis';

//reusable component
import toast from './reusable/Toast';
import moment from 'moment';

export default class FormParent extends Component {
    handleImageUpload = (event, fieldName = undefined) => {
        let file = event.target.files[0];


        if (file !== undefined && file !== null) {
            let fileName = file.name.toLowerCase()

            if (file.size > MAX_FILE_SIZE) {
                this.state[`${event.target.name}Msg`] = "Maximum file size allowed is 8MB"
            }
            else if (!isValidImageFile(fileName)) {
                this.state[`${event.target.name}Msg`] = "Support only '.jpg', '.gif', '.png', '.jpeg' formats"
            }
            else if (file.name.includes('(') || file.name.includes(')')) {
                this.state[`${event.target.name}Msg`] = "Filename cannot contain '(' or ')'"
            }
            else {
                this.state[`${event.target.name}Msg`] = ''
                this.state[event.target.name] = fileName
                this.state.formFields[`${event.target.name}`] = fileName
                this.state[`${event.target.name}File`] = file
                if (fieldName && !this.state.editParams.includes(fieldName) && this.props.location.pathname.includes('startup/edit')) {
                    this.state.editParams.push(fieldName)
                }
            }
            this.setState(this.state)
        }
    }

    handleFileUpload = (event) => {
        let file = event.target.files[0]

        if (file !== undefined && file !== null) {
            let fileName = file.name.toLowerCase()

            if (file.size > MAX_FILE_SIZE) {
                store[`${event.target.name}Msg`] = 'Maximum size 8MB'
            }
            else if (!isValidFile(fileName)) {
                this.state[`${event.target.name}Msg`] = "Support only '.pdf', '.xls', '.xlsx', formats"
            }
            else {
                this.state[`${event.target.name}Msg`] = ''
                this.state[event.target.name] = fileName
                this.state.formFields[`${event.target.name}`] = fileName
                this.state[`${event.target.name}File`] = file
            }
            this.setState(this.state)
        }
    }

    async addFileUpload(path, fieldName, file) {
        if (this.state.formFields[fieldName] !== '') {
            let imageType, query, method

            if (store.isEdit) {
                imageType = fieldName
                query = store.type === 'advert' ? `banner/${store.currentId}/${path}` : `${store.type}/${store.currentId}/${path}`
                method = 'PATCH'
            }
            else {
                //imageType = path === 'logo' || path === 'cover-image' ? path : 'logo'
                imageType = 'file'
                query = `file`
                method = 'POST'
            }

            const formData = new FormData()
            formData.append(imageType, file)

            try {
                const data = await Api.request(query, method, {
                    'Authorization': store.token
                }, {
                    data: formData
                })

                if (data.status === 200 || data.status === 201) {
                    if (store.type === 'application') {
                        this.parameters[`${fieldName}`] = data.data.name

                        if (!this.props.location.pathname.includes('startup/edit')) {
                            this.state.editParams.push(fieldName)
                        }
                    } else {
                        this.parameters[`${fieldName}`] = data.data.id
                        if (!this.props.location.pathname.includes('startup/edit')) {
                            this.parameters[`${fieldName}`] = data.data.id
                        }
                    }

                    let obj = {}
                    obj[`${fieldName}Success`] = true
                    obj[`${fieldName}Upload`] = false
                    obj[`${fieldName}Error`] = false
                    obj[`${fieldName}Empty`] = false
                    obj[`${fieldName}Failed`] = false

                    this.setState(obj)
                } else {
                    let obj = {}
                    if (store.type === 'research') {
                        obj[`image_idError`] = true
                        obj[`image_idSuccess`] = false
                        obj[`image_idUpload`] = false
                        obj[`image_idEmpty`] = false
                        obj[`${fieldName}Failed`] = false
                    } else {
                        obj[`${fieldName}Error`] = true
                        obj[`${fieldName}Success`] = false
                        obj[`${fieldName}Upload`] = false
                        obj[`${fieldName}Empty`] = false
                        obj[`${fieldName}Failed`] = false
                    }
                    this.setState(obj)
                }
            } catch (error) {
                console.error(error)
                return;
            }
        }
    }


    async deleteImage(path, fieldName) {
        if (this.state.formFields[fieldName] !== '') {
            let query, method
            if (!store.isEdit) {
                return
            }

            this.setState({ [`${fieldName}Deleting`]: true })

            query = `${store.type}/${store.currentId}/${path}`
            method = 'DELETE'
            try {

                const data = await Api.request(query, method, {
                    'Authorization': store.token
                })

                if (data.status === 200 || data.status === 201) {

                    const formFields = { ...this.state.formFields, [fieldName]: '' }
                    this.setState({ formFields })

                    let obj = {}
                    obj[`${fieldName}Success`] = false
                    obj[`${fieldName}Upload`] = false
                    obj[`${fieldName}Error`] = false
                    obj[`${fieldName}Failed`] = false
                    obj[`${fieldName}Empty`] = false
                    obj[`${fieldName}Delete`] = true
                    obj[`${fieldName}Deleting`] = false

                    this.setState(obj)
                } else {
                    let obj = {}
                    obj[`${fieldName}Error`] = false
                    obj[`${fieldName}Failed`] = true
                    obj[`${fieldName}Success`] = false
                    obj[`${fieldName}Upload`] = false
                    obj[`${fieldName}Empty`] = false
                    obj[`${fieldName}Delete`] = false
                    obj[`${fieldName}Deleting`] = false

                    this.setState(obj)
                }
            } catch (error) {
                let obj = {}
                obj[`${fieldName}Error`] = false
                obj[`${fieldName}Failed`] = true
                obj[`${fieldName}Success`] = false
                obj[`${fieldName}Upload`] = false
                obj[`${fieldName}Empty`] = false
                obj[`${fieldName}Delete`] = false
                obj[`${fieldName}Deleting`] = false

                this.setState(obj)
                console.error(error)
                return;
            }
        }
    }

    //  handleImageUpdate removes image property from the param obj  and calls the method to upload the image
    handleImageUpdate = async (param) => {
        let uploadStatus = 'no uploads'
        if ('cover_image' in param) {
            delete param.cover_image
            await this.addFileUpload('cover-image', 'cover_image', this.state.cover_imageFile)
            uploadStatus = this.state.cover_imageError || !uploadStatus ? false : true
        }
        if ('logo' in param) {
            delete param.logo
            await this.addFileUpload('logo', 'logo', this.state.logoFile)
            uploadStatus = this.state.logoError || !uploadStatus ? false : true
        }

        return uploadStatus ? 'success' : uploadStatus === 'no uploads' ? false : 'Image upload failed!.'
    }

    //TODO: to be removed when url to file server is available
    tempImageUpload = async (path, fieldName, file) => {
        const formData = new FormData()
        formData.append(path, file)

        try {
            const data = await Api.request(`file/logo`, 'POST', {
                'Authorization': store.token
            }, {
                data: formData
            })

            if (data.status === 200 || data.status === 201) {
                this.state[`${fieldName}Path`] = data.data.path
                let obj = {}
                obj[`${fieldName}Error`] = false
                this.setState(obj)
            } else {
                let obj = {}
                obj[`${fieldName}Error`] = true
                this.setState(obj)
            }
        } catch (error) {
            console.error(error)
            return;
        }
    }

    async addSampleUpload(file) {
        if (file !== '') {
            const formData = new FormData()
            formData.append('file', file)

            try {
                const data = await Api.request(`file`, 'POST', {
                    'Authorization': store.token
                }, {
                    data: formData
                })

                if (data.status === 200 || data.status === 201) {
                    this.parameters['sample_id'] = data.data.id
                    let obj = {}
                    obj['sample_idSuccess'] = true
                    obj[`sample_idUpload`] = false
                    obj[`sample_idError`] = false
                    this.setState(obj)
                } else {
                    let obj = {}
                    obj[`sample_idError`] = true
                    obj['sample_idSuccess'] = false
                    obj[`sample_idUpload`] = false
                    this.setState(obj)
                }
            } catch (error) {
                console.error(error)
                return error;
            }
        }
    }

    handleInputChange = (event) => {
        let targetVal = event.target.value

        if (store.isEdit) {
            if (this.state.editParams.indexOf(event.target.name) === -1) {
                this.state.editParams.push(event.target.name)
            }

            if (event.target.title !== 'number' && targetVal === '') {
                targetVal = null
            }
        }

        if (event.target.title === 'number') {
            if (targetVal.includes(',')) {
                targetVal = targetVal.replaceAll(',', '')
            }
            this.state[`${event.target.name}Number`] = /^\d*$/.test(targetVal) || targetVal === '' ? false : true;
            this.state.formFields[event.target.name] = targetVal
            this.parameters[event.target.name] = /^\d*$/.test(targetVal) && targetVal !== '' ? parseInt(targetVal) : targetVal

            let str = event.target.name.substr(4)
            if (event.target.name.match(/max_/g) && this.parameters[`min_${str}`] !== '')
                this.state[`${event.target.name}Max`] = this.parameters[`max_${str}`] < this.parameters[`min_${str}`] ? true : false
        }
        else {
            this.state.formFields[event.target.name] = targetVal
            this.parameters[event.target.name] = targetVal
        }

        this.state[`${event.target.name}Empty`] = false
        this.state[`${event.target.name}Social`] = false
        this.state[`${event.target.name}Invalid`] = false
        this.setState(this.state)
    }

    handleDateChange = (date, str) => {
        if (store.isEdit) {
            if (this.state.editParams.indexOf(str) === -1) {
                this.state.editParams.push(str)
            }
        }
        this.state[`${str}Empty`] = false
        this.state.formFields[str] = date
        this.parameters[str] = moment(date).format('yy-MM-DD')

        this.setState(this.state)
    }

    handleSelectChange = (option, str) => {
        if (store.isEdit) {
            if (this.state.editParams.indexOf(str) === -1) {
                this.state.editParams.push(str)
            }
        }

        this.state[`${str}Empty`] = false
        this.parameters[str] = option.value
        this.state.formFields[str] = option
        this.setState(this.state)
    }

    handleRichEditorInputChange(ev, str) {

        const data = ev.editor.getData();

        if (store.isEdit && this.state.formFields[str] !== data) {
            if (this.state.editParams.indexOf(str) === -1) {
                this.state.editParams.push(str);
            }
        }

        this.state.formFields[str] = data;
        this.parameters[str] = data;
        this.state[`${str}Empty`] = false;

        this.setState(this.state);
    }

    handleMultiFileUpload(event, str) {
        let file = event.target.files[0]

        if (file !== undefined && file !== null) {
            let fileName = file.name.toLowerCase()

            if (file.size > MAX_FILE_SIZE) {
                this.state[`${event.target.name}Msg`] = 'Maximum file size allowed is 8MB'
            }
            else if (!isValidFile(fileName)) {
                this.state[`${event.target.name}Msg`] = "Support only '.pdf', '.xls', '.xlsx', formats"
            }
            else {
                this.state[`${event.target.name}Msg`] = ''
                if (store.isEdit) {
                    let fileObj = {}, fileData = [], key;
                    key = (str === 'product-file') ? 'product_files' : 'research_file';
                    fileObj[key] = file;
                    fileData.push(fileObj);
                    store.apis.push({ path: `/${store.type}/${store.currentId}/${str}`, method: 'POST', multiType: str, fieldName: event.target.name, fileData: fileData });
                }

                if (this.state[str].indexOf(fileName) === -1) {
                    this.state[`${str}Value`] = fileName
                    let obj = {}
                    obj[event.target.name] = file
                    this.state.multiFields[str].push(obj)
                    this.state[str].push({ name: fileName })
                }
            }
            this.setState(this.state)
        }
    }

    handleMultiSelectChange(option, field, str) {

        this.state[`${str}Value`] = option

        if (this.state[str].length > 0) {
            let index = this.state[str].findIndex(item => {
                return item['name'] === option.label
            })

            if (index === -1) {
                let obj = {};
                obj[field] = option.value;

                if (str != 'user-role' && str !== 'organization-role') {
                    this.state.multiFields[str].push(obj)
                }

                this.state[str].push({ id: option.value, name: option.label });

                if (store.isEdit) {
                    store.apis.push({ path: `${store.type}/${store.currentId}/${str}`, method: 'POST', multiType: str, data: [obj] });
                }
            } else {
                return
            }
        }
        else {
            let obj = {};
            obj[field] = option.value;

            if (str != 'user-role' && str !== 'organization-role') {
                this.state.multiFields[str].push(obj)
            }

            this.state[str].push({ id: option.value, name: option.label });

            if (store.isEdit) {
                store.apis.push({ path: `${store.type}/${store.currentId}/${str}`, method: 'POST', multiType: str, data: [obj] })
            }
        }
        this.setState(this.state)

    }

    handleMultiRemove(index, id, str) {
        if (store.isEdit) {
            //this.state.isMultiChange = true
            store.apis.push({ path: `${store.type}/${store.currentId}/${str}/${id}`, method: 'DELETE', multiType: str })
        }

        this.state[`${str}Value`] = '';

        if (str != 'user-role' && str !== 'organization-role') {
            this.state.multiFields[str].splice(index, 1);
        }

        this.state[str].splice(index, 1)
        this.setState(this.state)
    }

    handleEmptyFieldValidation = () => {
        let errorCount = 0
        let errorShownOnce = false;

        this.state.validationFields.map(item => {
            if (this.state.formFields[item] === '' || this.state.formFields[item] === null) {
                this.state[`${item}Empty`] = true
                errorCount++
            } else if (item == "logo" && (this.state.logoMsg.length > 0 || this.state.logoEmpty || this.state.logoError || this.state.logoUpload)) {
                errorCount++
            } else {
                this.state[`${item}Empty`] = false
            }
            //Scroll to view based on the error. Restricted for the first occurrence of error. New Toast module added
            if (errorCount == 1 && !errorShownOnce) {
                let element = document.getElementsByName(item)[0];
                if (element)
                    element.parentElement.scrollIntoView({ behavior: 'smooth' });
                toast.error("Please fill in the required field marked with an asterisk (*)");
                errorShownOnce = true;
            }
        })

        if (this.state.fileFields && store.isAdd) {
            this.state.fileFields.map(item => {
                if (this.state.formFields[item] !== '') {

                    if (this.state[`${item}Error`] || (!this.state[`${item}Error`] && !this.state[`${item}Success`])) {
                        this.state[`${item}Upload`] = true
                        errorCount++
                    }
                    else {
                        this.state[`${item}Upload`] = false
                    }
                }
            })
        }

        if (store.form === 'userform') {
            if (store.isAdd && this.state.formFields.email === '') {
                this.state[`emailEmpty`] = true
                errorCount++
            }
            else if (store.isEdit && this.state.emails.length === 0) {
                this.state[`emailEmpty`] = true
                errorCount++
            }
            else {
                this.state[`emailEmpty`] = false
            }
        }
        this.setState(this.state)
        return errorCount > 0 ? false : true
    }

    handleEmailValidation = (field) => {
        let temp = store.isEdit && store.form === 'userform' ? this.state.email : this.state.formFields[field]
        let errorCount = 0
        if (temp !== '') {
            if (isValidEmail(temp)) {
                this.state[`${field}Invalid`] = false
            }
            else {
                this.state[`${field}Invalid`] = true
                errorCount++
            }
            this.setState(this.state)
            return errorCount > 0 ? false : true
        }
        return errorCount > 0 ? false : true
    }

    handleMultiEmailValidation = (field) => {
        let emails = this.state.formFields[field]
        let errorCount = 0
        if (emails !== '') {
            const emailArray = emails.split(",");

            if (emailArray.length > 0) {
                for (const email of emailArray) {
                    const trimmedEmail = email.trim()

                    if (isValidEmail(trimmedEmail)) {

                        this.state[`${field}Invalid`] = errorCount === 0 ? false : this.state[`${field}Invalid`]
                    } else {
                        this.state[`${field}Invalid`] = true
                        errorCount++
                    }
                }
            }

            this.setState(this.state)
            return errorCount > 0 ? false : true
        }

        return errorCount > 0 ? false : true
    }

    handlePasswordValidation = () => {
        let errorCount = 0
        if (this.state.formFields.password && this.state.formFields.password !== this.state.formFields.re_password) {
            this.setState({ passwordError: true })
            errorCount++
        }
        else {
            this.setState({ passwordError: false })
            if (errorCount > 0) errorCount--
        }
        return errorCount > 0 ? false : true
    }

    handleSocialValidation = () => {
        let errorCount = 0
        let pattern
        this.state.socialFields.map(item => {
            if (this.state[`${item}`] !== '') {
                pattern = item === 'linkedin' ? LINKEDIN : item === 'facebook' ? FACEBOOK : TWITTER
                if (this.state.formFields[item].match(pattern)) {
                    this.state[`${item}Social`] = false
                }
                else {
                    this.state[`${item}Social`] = true
                    errorCount++
                }
            }
        })

        this.setState(this.state)
        return errorCount > 0 ? false : true
    }

    handleLoadOptions = async (value, callback, path = "") => {

        if (value.length > 2) {
            let filterParams;

            if (path.toLowerCase() === 'user') {
                filterParams = [
                    { 'key': 'keyword', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [value] },
                    { 'key': 'status', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [USER_STATUS_OBJ.invited, USER_STATUS_OBJ.active, USER_STATUS_OBJ.unVerified] },
                ];
            } else if (path.toLowerCase() === 'people') {
                filterParams = [
                    { 'key': 'keyword', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [value] },
                    { 'key': 'status', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [ACTIVE_STATUS_OBJ.inactive, ACTIVE_STATUS_OBJ.active] },
                ];
            } else if (path.toLowerCase() === 'location') {
                filterParams = [
                    { 'key': 'name', 'op': ALLOWED_QUERY_OPERATOR.substring, 'value': [value] },
                    { 'key': 'country', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [0] }
                ];
            } else if (path.toLowerCase() === 'resource') {
                filterParams = [
                    { 'key': 'name', 'op': ALLOWED_QUERY_OPERATOR.substring, 'value': [value] },
                    { 'key': 'active', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [ACTIVE_STATUS_OBJ.active] },
                ];
            } else {
                filterParams = [{ 'key': 'name', 'op': ALLOWED_QUERY_OPERATOR.substring, 'value': [value] }];
            }

            let fields = 'id^,name';
            if (path === 'application') {
                fields = 'id^,name^,code^,logo_data';
            } else if (path === 'user') {
                fields = 'id^,first_name^,last_name^,email_data';
            } else if (path === 'people') {
                fields = 'id^,first_name^,last_name^,email';
            } else if (path === 'news') {
                fields = 'id^,title';
            } else if (path === 'resource') {
                fields = 'id^,name^,code';
            }

            let filters = prepareFilterParam(filterParams);
            let limit = path === 'resource' ? 100 : 50;
            const url = `${path}/?fields=${fields}&filters=${filters}&limit=${limit}`


            try {
                const data = await Api.request(url, 'GET', {
                    'Authorization': store.token,
                    'Content-Type': 'application/json'
                })
                let arr = []

                if (store.form === 'permissionform' || store.form === 'dataExportProductForm') {
                    if (path === 'resource') {
                        data.data.resources.map(item => {
                            arr.push({
                                value: item.id,
                                label: item.name,
                                code: item.code
                            })
                        })
                        this.setState({
                            resources: arr
                        })
                    } else {
                        data.data.map(item => {
                            arr.push({
                                value: item.id,
                                label: item.name
                            })
                        })
                    }
                } else if (path === 'user' || path === 'people') {
                    data.data.map(item => {
                        let userEmail;
                        if (path === 'user') {
                            userEmail = extPrimaryEmail(item.email_data);
                        } else if (path === 'people') {
                            userEmail = item.email;
                        }

                        arr.push({
                            value: item.id,
                            label: `${item.first_name} ${item.last_name} | ${userEmail ? userEmail : 'No email found'}`,
                            email: userEmail
                        })
                    })
                } else if (path === 'news') {
                    data.data.map(item => {
                        arr.push({
                            value: item.id,
                            label: item.title
                        })
                    })
                } else {
                    data.data.map(item => {
                        arr.push({
                            value: item.id,
                            label: item.name
                        })
                    })
                }

                callback(arr)
            } catch (error) {
                console.error(error)
                return;
            }
        }
    }

    async handleSelectEntityType(option, field) {
        if (store.isEdit) {
            if (this.state.editParams.indexOf(field) === -1) {
                this.state.editParams.push(field)
            }
        }

        this.parameters[field] = option.value;

        this.setState(prevState => ({
            formFields: {
                ...prevState.formFields,
                entity_type: option,
            },
            entity_typeEmpty: false
        }))

        let type = option.value;

        if (field === 'entity_type' && type !== '') {
            try {
                const data = await Api.request(`${type}?fields=id^,name`, 'GET', {
                    'Authorization': store.token,
                    'Content-Type': 'application/json'
                })
                let arr = []

                data.data.map(item => {
                    arr.push({
                        value: item.id,
                        label: item.name
                    })
                })

                this.setState({
                    companies: arr
                })
            } catch (error) {
                console.error(error)
                return;
            }
        }
    }

    async handleSelectMultiObj(option, obj, field) {
        if (store.isEdit) {
            if (this.state.editParams.indexOf(obj) === -1) {
                this.state.editParams.push(obj)
            }
        }

        this.state[`${field}Value`] = option
        this.state.formFields[obj][field].push(option)
        this.parameters[obj][field].push(option.label)

        this.setState(this.state)

    }

    async handleRemoveMultiObj(index, obj, field) {
        if (store.isEdit) {
            if (this.state.editParams.indexOf(obj) === -1) {
                this.state.editParams.push(obj)
            }
        }

        this.state[`${field}Value`] = ''
        this.state.formFields[obj][field].splice(index, 1)
        this.parameters[obj][field].splice(index, 1)

        this.setState(this.state)

    }

    async handleLoadCompanies(type) {
        if (type !== '') {
            try {
                const data = await Api.request(`${type}?fields=id^,name`, 'GET', {
                    'Authorization': store.token,
                    'Content-Type': 'application/json'
                })

                let arr = []
                if (data.data && data.data.length > 0) {
                    data.data.map(item => {
                        arr.push({
                            value: item.id,
                            label: `${item.name} [${item.id}]`
                        })
                    })
                }

                this.setState({
                    companies: arr
                })
            } catch (error) {
                console.error(error)
                return;
            }
        }
    }

    prepParameters = (params, str) => {
        let obj = {}

        Object.keys(params).map(key => {
            if (params[key] !== "") {
                obj[key] = params[key]
            }
        });

        if (store.isAdd) return this[str] = obj
        if (store.isEdit) this.editParams = obj
    }

    async handleUpdate(path) {
        let arr = []
        let bool

        switch (path) {
        case 'job':
            bool = this.handleEmptyFieldValidation() && this.handleMultiEmailValidation('email_notification')
            break;
        case 'user':
            bool = this.handleEmptyFieldValidation() && this.handlePasswordValidation() && this.handleEmailValidation('email') // TODO : to be added && this.handleSocialValidation()
            break;
        case 'application':
            bool = this.handleEmptyFieldValidation() && this.handleEmailValidation('mng_email')
            break;
        case API_PATH.people:
            bool = this.handleEmptyFieldValidation() && this.handleEmailValidation('email')
            break;
        default:
            bool = this.handleEmptyFieldValidation();
            break;
        }

        if (bool) {
            if (this.state.editParams.length > 0) {

                let param = {}
                this.state.editParams.map(item => {
                    if (this.parameters[item] === '') {
                        param[item] = null;
                    } else {
                        param[item] = this.parameters[item]
                    }
                })

                let imageUploadStatus = false
                if (this.props.location.pathname.includes('startup/edit')) {
                    imageUploadStatus = await this.handleImageUpdate(param)
                }

                if (Object.keys(param).length > 0) {
                    this.prepParameters(param)
                    this.updateData(path, param)
                        .then(res => {
                            if (res.status === 200 && imageUploadStatus !== 'Image upload failed!.') {
                                $('#dailogModal').modal({
                                    backdrop: 'static',
                                    keyboard: true,
                                    show: true
                                })
                            } else {
                                //Display error from backend
                                let errMsg = (res.errors && res.errors[0].message) ? res.errors[0].message : imageUploadStatus ? imageUploadStatus : ''

                                if (path == 'undisclosed-proxy' && res.errors[0].name == "SequelizeUniqueConstraintError") {
                                    errMsg = "The year and funding stage pair already exists. Please try editing the entry with latest value.";
                                }

                                this.setState({
                                    errorMessage: errMsg
                                }, () => {
                                    $('#errorModal').modal({
                                        backdrop: 'static',
                                        keyboard: true,
                                        show: true
                                    })
                                });
                            }
                        })
                } else if (imageUploadStatus) {
                    if (imageUploadStatus === 'success') {
                        $('#dailogModal').modal({
                            backdrop: 'static',
                            keyboard: true,
                            show: true
                        })

                    } else {
                        this.setState({
                            errorMessage: imageUploadStatus
                        }, () => {
                            $('#errorModal').modal({
                                backdrop: 'static',
                                keyboard: true,
                                show: true
                            })
                        });
                    }

                }
            }

            if (store.apis.length > 0) {
                store.apis.map(item => {
                    if (item.method === 'DELETE') {
                        arr.push(this.deleteAssociatedData(item.path))
                    }
                    else if (item.method === 'POST' && (item.multiType === 'product-file' || item.multiType === 'file')) {
                        arr.push(this.saveFile(item.path, item.fieldName, item.fileData))
                    }
                    else if (item.method === 'PATCH' && item.multiType === 'team') {
                        arr.push(this.updateAssociatedData(item.path, item.team))
                    }
                    else if (item.method === 'POST' && item.multiType === 'team') {
                        arr.push(this.saveAssociatedData(item.path, item.team))
                    }
                    else if (item.method === 'PATCH' && item.multiType === 'news-mention') {
                        arr.push(this.updateAssociatedData(item.path, item.mentions))
                    }
                    else if (item.method === 'POST' && item.multiType === 'news-mention') {
                        arr.push(this.saveAssociatedData(item.path, item.mentions))
                    }
                    else if (item.method === 'PATCH' && item.multiType === 'invested') {
                        arr.push(this.updateAssociatedData(item.path, item.invested))
                    }
                    else if (item.method === 'POST' && item.multiType === 'invested') {
                        arr.push(this.saveAssociatedData(item.path, item.invested))
                    }
                    else if (item.method === 'POST' && item.multiType === 'question-option') {
                        arr.push(this.saveAssociatedData(item.path, item.options))
                    }
                    else if (item.method === 'POST' && item.fieldName === 'cover_img') {
                        arr.push(this.saveAssociatedFile(item.path, 'cover_image', item.fileData))
                    }
                    else {
                        // arr.push(this.saveAssociatedData(item.path, this.state.multiFields[item.multiType]))
                        arr.push(this.saveAssociatedData(item.path, item.data, item.method))
                    }
                })

                Promise.all(arr)
                    .then(res => {

                        // handle the successful resolution of all promises
                        store.apis = [];

                        let index = res.findIndex(item => {
                            item.status === 400
                        });

                        if (index === -1) {
                            $('#dailogModal').modal({
                                backdrop: 'static',
                                keyboard: true,
                                show: true
                            })
                        } else {
                            // display error from backend
                            this.handleErrorModal(res);
                        }

                    })
                    .catch(error => {
                        // handle any error that occurred in any of the promises
                        this.handleErrorModal(error);
                    });
            }
        }
    }

    handleErrorModal = (res) => {
        const errMsg = (res.errors && res.errors[0].message) ? res.errors[0].message : '';
        this.setState({
            errorMessage: errMsg
        }, () => {
            $('#errorModal').modal({
                backdrop: 'static',
                keyboard: true,
                show: true
            })
        });
    }

    updateData = async (path, body) => {
        try {
            const data = await Api.request(`${path}/${store.currentId}`, 'PATCH', {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            }, {
                data: JSON.stringify(body)
            })

            return data
        } catch (error) {
            console.error(error)
            return
        }
    }

    deleteAssociatedData = async (path) => {
        try {
            const data = await Api.request(path, 'DELETE', {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            })

            return data
        } catch (error) {
            console.error(error)
            return
        }
    }

    updateAssociatedData = async (path, body) => {
        try {
            const data = await Api.request(path, 'PATCH', {
                'Authorization': store.token,
                'Content-Type': 'application/json',
            }, {
                data: JSON.stringify(body)
            })

            return data
        } catch (error) {
            console.error(error)
            return
        }
    }

    saveAssociatedData = async (path, body, method = 'POST') => {
        try {
            const data = await Api.request(path, method, {
                'Authorization': store.token,
                'Content-Type': 'application/json',
            }, {
                data: JSON.stringify(body)
            })
            return data;
        } catch (error) {
            console.error(error)
            return error
        }
    }

    saveAssociatedFile = async (path, name, file) => {
        try {
            const formData = new FormData()
            formData.append(name, file)

            const data = await Api.request(path, 'POST', {
                'Authorization': store.token
            }, {
                data: formData
            })

            return data
        } catch (error) {
            return error
        }
    }

    async handleAdd(path) {
        let check
        switch (path) {
        case 'job':
            store.currentAddName = this.state.formFields.title
            check = this.handleEmptyFieldValidation() && this.handleMultiEmailValidation('email_notification')
            break;
        case 'news':
            store.currentAddName = this.state.formFields.title
            check = this.handleEmptyFieldValidation()
            break;
        case 'user':
            store.currentAddName = this.state.formFields.username
            check = this.handleEmptyFieldValidation() && this.handlePasswordValidation() && this.handleEmailValidation('email') // TODO : to be added && this.handleSocialValidation()
            break;
        case 'funding':
            store.currentAddName = 'Funding'
            check = this.handleEmptyFieldValidation()
            break;
        case 'fund':
            store.currentAddName = 'Fund'
            check = this.handleEmptyFieldValidation()
            break;
        case 'technology':
            store.currentAddName = 'Technology'
            check = this.handleEmptyFieldValidation()
            break;
        case 'permission':
            store.currentAddName = 'Permission'
            check = this.handleEmptyFieldValidation()
            break;
        case 'application':
            store.currentAddName = this.state.formFields.name
            check = this.handleEmptyFieldValidation() && this.handleEmailValidation('mng_email')
            break;
        case 'startup':
            store.currentAddName = this.state.formFields.name
            check = this.handleEmptyFieldValidation() && this.handleEmailValidation('email_relations')
            if (check && !isObjectEmpty(this.state.formFields.primary_industry)) {
                const multiFields = { ...this.state.multiFields };
                // Primary industry add
                const primaryIndustry = {
                    industry_id: this.state.formFields.primary_industry.value,
                    is_sub_industry: 0,
                    is_primary: 1
                }
                multiFields.industry.push(primaryIndustry)

                // Sub industry check to avoid empty data
                if (!isObjectEmpty(this.state.formFields.sub_industry)) {
                    const subIndustry = {
                        industry_id: this.state.formFields.sub_industry.value,
                        is_primary: 0,
                        is_sub_industry: 1
                    }
                    multiFields.industry.push(subIndustry)
                }

                multiFields.industry = multiFields.industry.map(industry => {
                    if (industry.is_primary == undefined) {
                        return { ...industry, is_primary: 0, is_sub_industry: 0 }
                    } else {
                        return industry
                    }
                })
                this.setState({ multiFields })
                delete this.parameters.primary_industry;
            }
            break;
        case 'investor':
        case 'enabler':
        case 'corporate':
            store.currentAddName = this.state.formFields.name
            check = this.handleEmptyFieldValidation() && this.handleEmailValidation('email_relations')
            break;
        case 'rd-policy':
            // this page doesn't have a name field for the saved item
            store.currentAddName = "The report policy";
            check = this.handleEmptyFieldValidation()
            break
        case 'undisclosed-proxy':
            store.currentAddName = "The undisclosed proxy record";
            check = this.handleEmptyFieldValidation();
            break
        case 'resource':
            store.currentAddName = 'Resource';
            check = this.handleEmptyFieldValidation();
            break;
        case API_PATH.dataExportProduct:
            store.currentAddName = 'Data export product';
            check = this.handleEmptyFieldValidation();
            break;
        case API_PATH.dataExportPolicy:
            store.currentAddName = 'Data export policy';
            check = this.handleEmptyFieldValidation();
            break;
        case API_PATH.people:
            store.currentAddName = 'People'
            check = this.handleEmptyFieldValidation() && this.handleEmailValidation('email')
            break;
        case API_PATH.customDashboard:
            store.currentAddName = 'Custom Dashboard'
            check = this.handleEmptyFieldValidation()
            break;
        case API_PATH.salesAutomationEmail:
            store.currentAddName = 'Sales automation email'
            check = this.handleEmptyFieldValidation()
            break;
        default:
            store.currentAddName = this.state.formFields.name || store.successLabel;
            check = this.handleEmptyFieldValidation()
            break
        }

        if (check) {
            this.prepParameters(this.parameters, 'parameters')
            const bulkAddRoutes = ['valuation-calculation', 'resource', 'permission'];

            let requestData;

            if (bulkAddRoutes.includes(store.type)  || bulkAddRoutes.includes(path)) {
                requestData = JSON.stringify([this.parameters])
            } else {
                if (path == 'user-role' || path === 'organization-role') {
                    let newArrayDataOfObject = Object.values(this.parameters);
                    requestData = JSON.stringify(newArrayDataOfObject);
                } else {
                    requestData = JSON.stringify(this.parameters)
                }
            }

            try {
                const data = await Api.request(path, 'POST', {
                    'Authorization': store.token,
                    'Content-Type': 'application/json',
                }, {
                    data: requestData
                })

                if (data.status === 201 || data.status === 200) {
                    // show the api client id and secret on the success popup
                    if (path === API_PATH.apiClient) {
                        store.apiClientID = data.data.client_id;
                        store.apiClientSecret = data.data.secret_key;
                    }

                    let arr = []
                    if (this.state.multiFields) {
                        Object.keys(this.state.multiFields).map(key => {
                            if (this.state.multiFields[key].length > 0) {
                                if (key === 'product-file' || key === 'file') {
                                    let field = key === 'product-file' ? 'product_files' : 'research_file'
                                    arr.push(this.saveFile(`${path}/${data.data.id}/${key}`, field, this.state.multiFields[key]))
                                } else {
                                    arr.push(this.saveAssociatedData(`${path}/${data.data.id}/${key}`, this.state.multiFields[key]))
                                }
                            }
                            if (key === 'team' && store.teams.length > 0) {
                                arr.push(this.saveAssociatedData(`${path}/${data.data.id}/${key}`, store.teams))
                            }
                            if (key === 'news-mention' && store.mentions.length > 0) {
                                arr.push(this.saveAssociatedData(`${path}/${data.data.id}/${key}`, store.mentions))
                            }
                            if (key === 'userInvite' && store.userInvitations.length > 0) {
                                store.userInvitations.map(item => {
                                    item.entity_id = data.data.id
                                    arr.push(this.saveAssociatedData(`user/invite`, item))
                                })
                            }
                        })
                    }

                    if (this.state.postCreation) {
                        Object.keys(this.state.postCreation).map(key => {
                            if (key === 'cover_img' && !isObjectEmpty(this.state.postCreation[key])) {
                                arr.push(this.saveAssociatedFile(`${path}/${data.data._id}/cover-image`, 'cover_image', this.state.postCreation[key]))
                            }
                            else if (key === 'roles' && this.state.postCreation.roles.length > 0) {
                                const subscriptionId = data.data.id;

                                let payload = [];
                                this.state.postCreation.roles.map(roleId => {
                                    payload.push({ subscription_id: subscriptionId, role_id: roleId });
                                });

                                arr.push(this.saveAssociatedData(`subscription-type-role`, payload));
                            }
                            else if (key === 'addon_roles' && this.state.postCreation.addon_roles.length > 0) {
                                const payload = [];
                                const subscriptionAddonId = data.data.id;

                                this.state.postCreation.addon_roles.map(roleId => {
                                    payload.push({ subscription_addon_id: subscriptionAddonId, role_id: roleId });
                                });

                                arr.push(this.saveAssociatedData(`subscription-addon-role`, payload));
                            }
                            else if (key === 'data_export_request' && !isObjectEmpty(this.state.postCreation[key])) {
                                const orderId = data.data.order.id;
                                const requestData = this.state.postCreation.data_export_request;

                                const payload = {
                                    ...requestData,
                                    data_set_type: requestData.data_set_type.value,
                                    order_id: orderId
                                };

                                const userId = requestData.user_id.value;

                                delete payload.user_id;

                                arr.push(this.saveAssociatedData(`user/${userId}/admin-data-export-request`, payload));
                            }
                        })
                    }

                    if (arr.length > 0) {
                        Promise.all(arr).then(res => {
                            this.ResetFields();

                            if (res[0].status == 200 || res[0].status == 201) {
                                $('#dailogModal').modal({
                                    backdrop: 'static',
                                    keyboard: true,
                                    show: true
                                })
                            } else {
                                const error = res && res[0].errors && res[0].errors.length > 0 && res[0].errors.message || res.message;
                                this.setState({
                                    errorMessage: error
                                }, () => {
                                    $('#errorModal').modal({
                                        backdrop: 'static',
                                        keyboard: true,
                                        show: true
                                    })
                                });
                            }
                        });
                    } else {
                        this.ResetFields()
                        $('#dailogModal').modal({
                            backdrop: 'static',
                            keyboard: true,
                            show: true
                        })
                    }
                } else {
                    let error = data.errors && data.errors[0].message || '';

                    if (path == 'undisclosed-proxy' && data.errors[0].name == "SequelizeUniqueConstraintError") {
                        error = "The year and funding stage pair already exists. Please try editing the entry with latest value.";
                    }

                    this.setState({
                        errorMessage: error
                    }, () => {
                        $('#errorModal').modal({
                            backdrop: 'static',
                            keyboard: true,
                            show: true
                        })
                    })
                }
            } catch (error) {
                return
            }
        }
    }

    saveFile = async (path, fieldName, file) => {
        const formData = new FormData()

        file.map(item => {
            formData.append(`${fieldName}`, item[`${fieldName}`])
        })

        try {
            const data = await Api.request(path, 'POST', {
                'Authorization': store.token,
            }, {
                data: formData
            })

            return data
        } catch (err) {
            console.log(err)
        }
    }

    saveEmail = async (path, body) => {
        try {
            const data = await Api.request(path, 'POST', {
                'Authorization': store.token,
                'Content-Type': 'application/json',
            }, {
                data: JSON.stringify(body)
            })

            return data
        } catch (err) {
            console.log(err)
        }
    }

    deleteEmail = async (path) => {
        try {
            const data = await Api.request(path, 'DELETE', {
                'Authorization': store.token,
                'Content-Type': 'application/json',
            })

            return data
        } catch (err) {
            console.log(err)
        }
    }

    handleClear = () => {
        this.ResetFields()
    }

    ResetFields = () => {
        //TODO: all the cases yet to be included
        switch (store.form) {
        case 'startup' || 'corporate' || 'enabler' || 'investor':
            this.state.logoError = false
            this.state.logoSuccess = false
            this.state.logoUpload = false
            this.state.logoMsg = ''
            this.state.logoEmpty = false
            this.state.cover_imageMsg = ''
            this.state.cover_imageEmpty = false
            this.state.cover_imageError = false
            this.state.cover_imageSuccess = false
            this.state.cover_imageUpload = false
            break;
        case 'permissionform':
            this.setState({
                allowed: '',
                not_allowed: '',
                allow_anyone: '',
                allow: '',
                not_allow: '',
                limit: '',
                policy: { attributes: {}, filters: {}, limit: '' }
            })
            break;
        case 'fundingform':
            store.fundingData = []
            //this.gridApi.setRowData(this.state.rowData)
            break;
        case 'application':
            store.questions = []
            store.questionsData = []
            store.questionsGroups = []
            store.questionsGroupsData = []
            store.questionsNew = []
            store.isQuestionEdit = false
            store.questionRowIndex = ''
            store.questionInfo = ''
            break;
        default:
            break;
        }

        let obj = {}
        Object.keys(this.state.formFields).map(key => {
            // TODO: review
            if (key === 'requirement') {
                obj[key] = { industries: [], locations: [], dev_stages: [] }
            } else {
                obj[key] = ''
            }
        })
        this.state.formFields = obj
        this.parameters = { ...this.state.formFields }
        this.setState(this.state)
    }

    handleShowAddTeamModal = () => {
        store.isTeamEdit = false
        this.setState({ teamError: false })

        store.teamDetails = {
            user_id: '',
            role_id: '',
            team_role_id: '',
            is_primary: 0
        }

        $('#addTeamModal').modal({
            backdrop: 'static',
            keyboard: true,
            show: true
        })
    }

    handleShowAddPeopleTeamModal = () => {
        store.isPeopleTeamEdit = false
        this.setState({ peopleTeamError: false })

        store.peopleTeamDetails = {
            people_id: '',
            team_role_id: '',
            is_primary_contact: 0,
            is_founder: 0,
        }

        $('#addPeopleTeamModal').modal({
            backdrop: 'static',
            keyboard: true,
            show: true
        })
    }

    handleShowInviteUserModal = () => {
        store.isTeamEdit = false
        this.setState({ teamError: false })
        store.teamDetails = {
            user_id: '',
            role_id: '',
            team_role_id: '',
            is_primary: 0
        }

        $('#inviteUserModal').modal({
            backdrop: 'static',
            keyboard: true,
            show: true
        })
    }

    handleDeleteTeam = (index) => {
        if (store.isEdit && store.currentTeamId) {
            store.apis.push({ path: `${store.type}/${store.currentId}/team/${store.currentTeamId}`, method: 'DELETE', multiType: 'team' })
        }
        store.teams.splice(index, 1)
        store.teamData.splice(index, 1)
        this.gridApi.setRowData(store.teamData)
    }

    handleDeletePeopleTeam = (index) => {
        if (store.isEdit && store.currentPeopleTeamId) {
            store.apis.push({ path: `${store.type}/${store.currentId}/people-team/${store.currentPeopleTeamId}`, method: 'DELETE', multiType: 'people-team' })
        }
        store.peopleTeams.splice(index, 1)
        store.peopleTeamData.splice(index, 1)
        this.gridApi.setRowData(store.peopleTeamData)
    }

    handleDeleteMentions = (index) => {
        if (store.isEdit && store.currentMentionId) {
            store.apis.push({ path: `/${store.type}/${store.currentId}/news-mention/${store.currentMentionId}`, method: 'DELETE', multiType: 'news-mention' })
        }
        store.mentions.splice(index, 1)
        store.mentionData.splice(index, 1)
        this.gridApi.setRowData(store.mentionData)
    }

    handleDeleteFunding = (index) => {
        if (store.isEdit && store.currentFundingId) {
            store.apis.push({ path: `${store.type}/${store.currentFundingId}/invested`, method: 'DELETE', multiType: 'funding' })
        }
        store.funding.splice(index, 1)
        store.fundingData.splice(index, 1)
        this.gridApi.setRowData(store.fundingData)
    }

    handleDeleteFundingBreakdown = (index) => {
        if (store.isEdit && store.currentFundingBreakdownId) {
            store.apis.push({ path: `${API_PATH.fundingBreakdown}/${store.currentFundingBreakdownId}`, method: 'DELETE' })
        }
        store.fundingBreakdowns.splice(index, 1);
        store.fundingBreakdownsData.splice(index, 1);
        this.gridApi.setRowData(store.fundingBreakdownsData);
    }

    handleHQChange = async (option) => {
        const { formFields, editParams } = this.state;

        // Remove the city if the headquarter gets changed
        if (store.isEdit) {
            formFields['city_id'] = null;
            this.parameters.city_id = null;
            editParams.push('city_id');

        } else {
            formFields['city_id'] = "";
            this.parameters.city_id = "";
        }

        this.handleSelectChange(option, 'hq_id');
        const cities = await getCities(option.value);
        this.setState({ cities, formFields, editParams });
    }

    loadCities = async (value, callback) => {
        const filters = [{ 'key': 'name', 'op': ALLOWED_QUERY_OPERATOR.substring, 'value': [value] }]
        const filterParams = prepareFilterParam(filters);

        const cities = await getCities(this.state.formFields.hq_id.value, filterParams);
        return callback(cities);
    }

    addMentions = (parameters, parametersData, entityName) => {
        const mentionExists = store.mentionData.filter((mention) => mention.entity_id === entityName);
        if (mentionExists.length > 0) {
            this.setState({ entity_exists: true });
        } else {
            this.setState({ entity_exists: false });
            store.mentions.push(parameters)
            store.mentionData.push(parametersData);

            if (store.isEdit) {
                store.apis.push({ path: `/${store.type}/${store.currentId}/news-mention`, method: 'POST', multiType: 'news-mention', mentions: [parameters] })
            }

            if (parametersData.from === 'modal') {
                this.props.gridApi.setRowData(store.mentionData)
            } else if (parametersData.from === 'form') {
                this.gridApi.setRowData(store.mentionData)
            }

            $('#addMentionModal').modal('hide');
        }
    }

    // Handle Geo Locations
    handleAddGeoFocus = (option, field, str) => {
        let { geoLocations } = this.state;

        geoLocations.forEach((location) => {
            if (location.label == option.label) {
                location.isDisabled = true;
            }
        })

        this.setState({ geoLocations });
        this.handleMultiSelectChange(option, field, str);
    }

    handleMultiRemoveGeoFocus = (index, id, str) => {
        let { geoLocations } = this.state;

        geoLocations.forEach((location) => {
            if (location.label == this.state['geo-focus'][index].name) {
                location.isDisabled = false;
            }
        })

        this.setState({ geoLocations });
        this.handleMultiRemove(index, id, str);
    }
}
