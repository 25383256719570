import { AgGridReact } from 'ag-grid-react'
import { observer } from 'mobx-react'
import React from 'react'
import DatePicker from 'react-datepicker'

import { ALLOWED_QUERY_OPERATOR, USER_COLUMN_HEADERS } from '../../constant'
import store from '../../store'
import { prepareSelectParam } from '../../utility/queryParams'
import Home from '../Home'
import Parent from '../Parent'
import ActionRenderer from '../reusable/ActionRenderer'
import Modal from '../reusable/Modal'
import Pagination from '../reusable/Pagination'
import Sort from '../reusable/Sort'
import SideNav from '../SideNav'


class Users extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: USER_COLUMN_HEADERS,
            context: { componentParent: this },
            frameworkComponents: { actionRenderer: ActionRenderer },

            keyword: '',
            showKeywords: false,
            showstatus: false,
            showemail: false,
            showlast_login: false,
            status: '',
            email: '',
            lastLoginTo: '',
            lastLoginFrom: '',
            statuss: [
                { value: 0, label: 'Invited' },
                { value: 1, label: 'Active' },
                { value: 2, label: 'Suspended' }
            ],
            statusStore: [
                { value: 0, label: 'Invited' },
                { value: 1, label: 'Active' },
                { value: 2, label: 'Suspended' }
            ],
            filters: {
                keyword: [],
                status: [],
                email: [],
                last_login: []
            },
            filterFields: [
                { 'key': 'keyword', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'status', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'email', 'op': ALLOWED_QUERY_OPERATOR.substring, 'value': [] },
                { 'key': 'last_login', 'op': ALLOWED_QUERY_OPERATOR.gte, 'value': [] },
                { 'key': 'last_login', 'op': ALLOWED_QUERY_OPERATOR.lte, 'value': [] },
            ]
        }
        store.sortType = this.props.location.pathname.split('/')[1]
    }

    onGridReady(params) {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    componentDidMount() {
        document.addEventListener('mousedown', (e) => {
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showKeywords: false }) }
            if (this.status) { if (!this.status.contains(e.target)) this.setState({ showstatus: false }) }
            if (this.email) { if (!this.email.contains(e.target)) this.setState({ showemail: false }) }
            if (this.lastLogin) { if (!this.lastLogin.contains(e.target)) this.setState({ showlast_login: false }) }
        })
        let paramFields = ['id', 'first_name', 'last_name', 'username', 'status', 'email_data', 'last_login', 'created_at']
        store.fields = prepareSelectParam(paramFields)

        //TODO: perform token validator
        this.fetchListData('user', store.fields)

    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => {
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showKeywords: false }) }
            if (this.status) { if (!this.status.contains(e.target)) this.setState({ showstatus: false }) }
            if (this.email) { if (!this.email.contains(e.target)) this.setState({ showstatus: false }) }
        })
    }
    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>USERS</h3>
                            <div className='filter-container'>

                                <div className='filter-row'>
                                    <div className="col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing" >
                                        <div ref={node => this.word = node} style={{ paddingLeft: 0 }} class="col-md-6 col-sm-6 col-xs-12">
                                            <div className="filter-firstline">
                                                <div>
                                                    <input ref={node => this.input = node}
                                                        className='filter-input input-border'
                                                        placeholder="Search by name"
                                                        value={this.state.keyword}
                                                        onFocus={() => this.setState({ showKeywords: true, showemail: false, showstatus: false })}
                                                        onKeyUp={this.handleKeywordEnter}
                                                        onChange={e => this.setState({ keyword: e.target.value })} />
                                                    {this.state.showKeywords && this.state.filters.keyword.length > 0 ?
                                                        <div className='filter-dropdown' style={{ width: '100%' }}>
                                                            <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                                {this.state.filters.keyword.map((word, i) => {
                                                                    return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'keyword')}>
                                                                        {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                                    </div>
                                                                })}
                                                            </div>
                                                        </div> : null}
                                                </div>
                                            </div>
                                            {this.state.keyword.length > 0 &&
                                            <i className="fa fa-times "
                                                style={{ paddingRight: 10 }}
                                                onClick={() => {
                                                    this.input.focus()
                                                    this.setState({ keyword: '' })
                                                }}>
                                            </i>
                                            }
                                        </div>
        
                                        <div ref={node => this.input = node} class="col-md-6 col-sm-6 col-xs-12" style={{ padding: 0 }}>
                                            <div className="filter-firstline">
                                                <div>
                                                    <input className='filter-input input-border'
                                                        placeholder="Search by email"
                                                        value={this.state.email}
                                                        onFocus={() => this.setState({ showemail: true })}
                                                        onKeyUp={(e) => this.handleInputEnter(e, 'email')}
                                                        onChange={e => this.setState({ email: e.target.value })} />
                                                    {this.state.showemail && this.state.filters.email.length > 0 ?
                                                        <div className='filter-dropdown' style={{ width: '100%' }}>
                                                            <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                                {this.state.filters.email.map((word, i) => {
                                                                    return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'email')}>
                                                                        {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                                    </div>
                                                                })}
                                                            </div>
                                                        </div> : null}
                                                </div>
                                                {this.state.email.length > 0 &&
                                            <i className="fa fa-times "
                                                style={{ paddingRight: 10 }}
                                                onClick={() => {
                                                    this.input.focus()
                                                    this.setState({ email: '' })
                                                }}>
                                            </i>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing'  >

                                        <div className="col-md-12 col-sm-12 col-xs-12" style={{ padding: 0 }}>
                                            <div ref={node => this.status = node} className='filter-firstline' >
                                                <div style={{ width: '100%' }}>
                                                    <input className='filter-input'
                                                        style={{ paddingLeft: 15 }}
                                                        placeholder="By Status"
                                                        value={this.state.status}
                                                        onChange={(e) => this.handleInputChange(e.target.value, 'status')}
                                                        onFocus={() => { this.setState({ showDateFounded: false, showstatus: true, showIndustries: false }) }} />
                                                    {this.state.showstatus && this.state.statuss ?
                                                        <div className='filter-dropdown' >
                                                            <div >
                                                                {this.state.filters.status.length > 0 ?
                                                                    <div className='filter-dropdown-wrapper' >
                                                                        {this.state.filters.status.map((loc, i) => {
                                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'status')}>
                                                                                {loc}&nbsp;<i className="fa fa-times-circle"></i>
                                                                            </div>
                                                                        })}
                                                                    </div> : null}
                                                            </div>
                                                            <ul className='filter-list-container' style={{ maxHeight: 120 }}>
                                                                <div className='filter-list-wrapper' >
                                                                    <div>
                                                                        {this.state.statuss.map((item, j) =>
                                                                            <div key={j}>
                                                                                <li style={{ fontSize: 12, }} onClick={() => this.handleSelectFilter(item, 'status')}>
                                                                                    {item.label}
                                                                                </li><br />
                                                                            </div>)}
                                                                    </div>
                                                                    <div className='filter-stickybtn' style={{ maxHeight: 120 }} >
                                                                        <div style={{ width: 140 }}>
                                                                            <a tabIndex='0' className='filter-canclebtn' onClick={() => this.handleClearFilter('status')}>Clear</a> | <a tabIndex='0' className='filter-applybtn' onClick={() => { this.setState({ showstatus: false }); this.handleSearchFilters('user') }}>Apply</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ul>
                                                        </div> : null}
                                                </div>
                                                {this.state.showstatus ? <i className="fa fa-chevron-up " style={{ paddingRight: 10 }} onClick={() => this.setState({ showstatus: false })} ></i> :
                                                    <i className="fa fa-chevron-down " style={{ paddingRight: 10 }} onClick={() => this.setState({ showstatus: true })} ></i>}
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>

                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing'  >

                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                            <div ref={node => this.lastLogin = node} className='filter-firstline' >
                                                <div style={{ width: '100%' }}>
                                                    <DatePicker
                                                        placeholderText="Last Login - Select From date"
                                                        className='filter-datepicker-form-control'
                                                        dateFormat="yyyy/MM/dd"
                                                        maxDate={new Date()}
                                                        isClearable={this.state.lastLoginFrom}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        selected={this.state.lastLoginFrom}
                                                        onChange={(date) => this.handleDateChange(date, 'lastLoginFrom', 'last_login', ALLOWED_QUERY_OPERATOR.gte)} />
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ padding: 0 }}>
                                            <div ref={node => this.lastLogin = node} className='filter-firstline' >
                                                <div style={{ width: '100%' }}>
                                                    <DatePicker
                                                        placeholderText="Last Login - Select To date"
                                                        className='filter-datepicker-form-control'
                                                        dateFormat="yyyy/MM/dd"
                                                        minDate={this.state.lastLoginFrom}
                                                        maxDate={new Date()}
                                                        isClearable={this.state.lastLoginTo}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        selected={this.state.lastLoginTo}
                                                        onChange={(date) => this.handleDateChange(date, 'lastLoginTo', 'last_login', ALLOWED_QUERY_OPERATOR.lte)} />
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2'
                                            className="col-md-2 col-sm-2 col-xs-12 filter-searchbtn"
                                            onClick={() => {
                                                this.state.lastLoginFrom = ''
                                                this.state.lastLoginTo = ''
                                                this.handleClearAllFilters('user')
                                            }}>Clear All</a>
                                    </div>
                                </div>
                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    frameworkComponents={this.state.frameworkComponents}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                >
                                </AgGridReact>
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}


export default observer(Users)
