import React, { Component } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import routes from '../routes'
import Header from './Header'
import NoMatch from './NoMatch'
import store from '../store'
import ErrorModal from './reusable/ErrorModal'

class App extends Component {
    constructor(props) {
        super(props)
    }

    async componentDidMount() {
        window.addEventListener("beforeunload", this.handleBeforeUnload)
        window.addEventListener("load", this.handleOnLoad)

        this.setRouteState();
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.handleBeforeUnload)
        window.removeEventListener("load", this.handleOnLoad)
    }

    handleBeforeUnload = () => {
        // let str = this.props.location.pathname.replace(/\//g, "") === '' ? 'startup' : this.props.location.pathname.replace(/\//g, "")
        let str = this.props.location.pathname.replace(/\//g, "")
        store.isAdd = false
        store.isCopy = false
        store.isEdit = false
        store.isDeleted = false
        store.isAddSubTaxonomy = false
        store.isSideNav = false
        store.showTaxonomies = false
        store.isTaxonomyList = true
        store.showPublish = false
        store.showDelete = false
        store.showDeactivate = false
        store.type = ''
        store.form = ''
        store.fields = ''
        store.taxonomyTitle = ''
        store.currentId = ''
        store.currentIndex = ''
        store.currentDeleteName = ''
        store.currentAddedName = ''
        store.currentEditName = ''
        store.currentFilters = ''
        store.currentSort = ''
        store.locations = []
        store.rowData = []
        store.page = 0
        store.lastPage = 0
        store.totalCount = 0
        store.fromCount = 0
        store.toCount = 0
        store.FormFieldsTaxonomy = ''
        store.teamData = []
        store.teams = []
        store.teamRowIndex = ''
        store.peopleTeamData = []
        store.peopleTeams = []
        store.peopleTeamRowIndex = ''
        store.mentionData = []
        store.mentions = []
        store.mentionRowIndex = ''
        store.fundingData = []
        store.funding = []
        store.fundingRowIndex = ''
        store.apis = []
        store.currentTeamId = ''
        store.currentPeopleTeamId = ''
        store.currentMentionId = ''
        store.currentFundingId = ''
        store.researchTitle = ''
        store.investorModalData = {},
        // store.dataVerificationData = []
        store.data = []
        localStorage.state = JSON.stringify({
            userId: store.userId,
            isLoggedin: store.isLoggedin,
            token: store.token,
            refreshToken: store.refreshToken,
            permissions: store.permissions
        })
    }

    setRouteState = () => {
        const actionType = this.props.location.pathname.split('/')[2];

        if (actionType === "add") {
            store.isAdd     = true;
            store.isEdit    = false;
        } else if (actionType === "edit") {
            store.isEdit    = true;
            store.isAdd     = false;
        }
    }

    handleOnLoad = () => {
        // console.log("this.props.location.pathname.replace(/\//,) === ", this.props.location.pathname.replace(/\//, ""))
        // store.type = this.props.location.pathname.replace(/\//, "")
        store.type  = this.props.location.pathname.split('/')[1]
        store.route = this.props.location.pathname.replace(/\//, "")
    }

    render() {
        return (
            <div >
                <Header />
                <div className='content-container'>
                    <div className='content-wrapper'>
                        <Switch>
                            {routes.map(({ path, exact, component: Component, ...props }) => (
                                <Route
                                    key={path}
                                    path={path}
                                    exact={exact}
                                    component={Component}
                                />
                            ))}
                            <Route render={(props) => <NoMatch {...props} />} />
                        </Switch>
                    </div>
                </div>
                <ErrorModal />
            </div >
        )
    }
}

export default withRouter(observer(props => <App {...props} />))