
import React, { PureComponent } from 'react';

// helpers
import store from '../../../store';

// constants
import { FUNDING_STATUS_LABELS } from '../../../constant';

class FundingStatusActionRenderer extends PureComponent {
    showModal = () => {
        $('#fundingStatusPopup').modal({
            backdrop: 'static',
            keyboard: true,
            show: true
        })

        const fundingStatus = this.props.data.funding_status;
        store.fundingStatus         = { value: fundingStatus, label: FUNDING_STATUS_LABELS[fundingStatus] };
        store.fundingBreakdownId    = this.props.data.id;
        store.fundingAnnouncement   = this.props.data.source_url;
        store.fundingComment        = this.props.data.comment;
    }

    render() {
        return (
            <a tabIndex="1" className="btn-edit" onClick={this.showModal}>Edit Status</a>
        )
    }
}

export default FundingStatusActionRenderer