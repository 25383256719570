import React, { Component } from 'react'
import { observer } from 'mobx-react';
import DatePicker from 'react-datepicker';
import store from '../../store';
import userApi from '../../apis/userApi';

class EndDateModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            endDate: '',
            isEmpty: false,
        }
    }

    handleCloseModal = () => {
        this.setState({ endDate: '' });
        store.date = '';

        $('#endDateModal').modal('hide');
    }

    handleDateChange = (date) => {
        this.setState({ endDate: date });
    }

    handleUpdate = async () => {
        if (this.state.endDate == '') {
            this.setState({ isEmpty: true });
            return;
        }

        const payload = {
            end_date: this.state.endDate
        };

        const subscriptionId = store.data.id;

        userApi.updateSubscription(subscriptionId, payload)
            .then(response => {
                if (!response) {
                    $('#errorModal').modal({
                        backdrop: 'static',
                        keyboard: true,
                        show: true
                    });
                } else {
                    this.props.fetchData();
                    this.handleCloseModal();

                    store.isEdit = true;
                    store.currentEditName = 'Subscription End Date';
                    
                    $('#dailogModal').modal({
                        backdrop: 'static',
                        keyboard: true,
                        show: true
                    });
                }
            });
    }

    render() {
        return (
            <div>
                <div className="modal fade" id="endDateModal">
                    <div className="modal-dialog">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h4 className="modal-title">Update Subscription End Date</h4>
                                <button type="button" className="close" onClick={this.handleCloseModal}>&times;</button>
                            </div>

                            <div style={{paddingTop: '10px', margin: '0px auto', textAlign: 'center', fontSize: '10px', }}>
                                <div className="alert alert-warning" role="alert">
                                    There are no business validations, so you will be responsible for updating the user subscription!
                                </div>
                            </div>

                            <div className="modal-body">
                                
                                <div className='row'>
                                    <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                        <label>User Name</label>
                                        <p>{store.data ? store.data.name : ''}</p>
                                    </div>
                                </div>

                                
                                <div className='row'>
                                    <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                        <label>Current Subscription End Date</label>
                                        <p>{store.data ? store.data.end_date : ''}</p>
                                    </div>
                                </div>

                                
                                <div className='row'>
                                    <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                        <label>Subscription End Date</label>
                                        <DatePicker
                                            placeholderText="Select End Date"
                                            className="datepicker-form-control"
                                            dateFormat="dd/MM/yyyy"
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            selected={this.state.endDate}
                                            onChange={this.handleDateChange} />
                                        {this.state.isEmpty && <div className='error'>Subscription End Date cannot be empty.</div>}
                                    </div>
                                </div>

                                <div className='bottom-btn-wrapper'>
                                    <div style={{margin: "0px auto"}}>
                                        <div style={{float: "left"}}>
                                            <button className="btn-primary bottom-btn" onClick={this.handleUpdate}>Update</button>
                                        </div>
                                        <div style={{float: "right"}}>
                                            <button className="btn-secondary bottom-btn" onClick={this.handleCloseModal}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default observer(EndDateModal)