import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react';

// helpers
import store from '../../store';
import { prepareSelectParam, prepareFilterParam } from '../../utility/queryParams';
import { isObjectEmpty } from '../../utility/helpers';

// constants
import { FUNDING_REQUESTS_COLUMN_HEADERS, SELECT_STYLES, SELECT_THEME_PRIMARY, TAXONOMIES, ALLOWED_QUERY_OPERATOR, ENTITY_TYPES, FUNDING_STATUS_FILTER_OPTIONS  } from '../../constant';
import { API_PATH } from '../../utility/constants/apiPath';

// apis
import { getTaxonomyData, getEntities } from '../../utility/commonApis';

// components
import Parent from '../Parent';
import Home from '../Home';
import SideNav from '../SideNav';
import Sort from '../reusable/Sort';
import Pagination from '../reusable/Pagination';
import InvestorsViewModal from './fundingPopups/InvestorsViewModal';
import Modal from '../reusable/Modal';
import InvestorModalActionRenderer from './fundingActions/InvestorModalActionRenderer';
import ActionRenderer from '../reusable/ActionRenderer';

class FundingRoundRequests extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: FUNDING_REQUESTS_COLUMN_HEADERS,
            context: { componentParent: this },
            frameworkComponents: {
                actionRenderer: ActionRenderer,
                InvestorsModalRenderer: InvestorModalActionRenderer
            },
            defaultColDef: { suppressMovable: true },

            keyword: '',
            entity_id: '',
            verification_status: '',
            showverification_status: false,
            showstartup_id: false,
            keyword: [],
            entities: [],
            startup_ids: [],
            funding_stage: '',
            funding_stages: [],
            funding_stageStore: [],
            startup_idStore: [],
            showfunding_stage: false,
            investorEntityType: '',
            investorEntityId: '',
            filters: {
                funding_stage: [],
                investor_id: [],
                startup_id: [],
                verification_status: []
            },
            filterFields: [
                { 'name': 'funding_stage', 'key': 'funding_stage_filter', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'name': 'startup_id', 'key': 'investedin_id_filter', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'name': 'verification_status', 'key': 'verification_status_filter', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'name': 'investor_id', 'key': 'entity_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
            ],
            
            fundingRequests: []
        }

        this.fundingRequestUrl = API_PATH.fundingRequests;

        store.sortType = this.props.location.pathname.split('/')[1]
    }

    onGridReady(params) {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    componentDidMount() {
        document.addEventListener('mousedown', (e) => {
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showverification_status: false }) }
            if (this.stage) { if (!this.stage.contains(e.target)) this.setState({ showfunding_stage: false }) }
            if (this.startup_id) { if (!this.startup_id.contains(e.target)) this.setState({ showstartup_id: false }) }
            if (this.investor_id) { if (!this.investor_id.contains(e.target)) this.setState({ showinvestor_id: false }) }
        })

        store.currentSort = 'created_at^:DESC';

        this.getInitialData()

        getTaxonomyData(TAXONOMIES.fundingStage).then(res => {
            this.setState({ funding_stages: res, funding_stageStore: res })
        })

        this.setStartupIds();
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => {
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showverification_status: false }) }
            if (this.stage) { if (!this.stage.contains(e.target)) this.setState({ showfunding_stage: false }) }
            if (this.startup_id) { if (!this.startup_id.contains(e.target)) this.setState({ showstartup_id: false }) }
            if (this.investor_id) { if (!this.investor_id.contains(e.target)) this.setState({ showinvestor_id: false }) }
        })
    }

    getInitialData = () => {
        const paramFields = ['id', 'funding_id', 'funding_breakdown_id', 'investedin_data', 'funding_stage_data', 'entity_id', 'entity_type', 'entity_data', 'invested_amount', 'investors_data', 'date_raised', 'created_at'];

        store.fields = prepareSelectParam(paramFields);
        store.currentFilters = prepareFilterParam(this.state.filterFields);

        this.fetchListData(this.fundingRequestUrl, store.fields, store.type);
    }

    setStartupIds = async (e) => {
        let value = ""

        if (e && e.target.value.length > 2) {
            value = e.target.value;
        }

        const startups = await getEntities('startup', value)

        this.setState({
            startup_ids: startups,
            startup_idStore: startups
        })
    }

    setEntities = async (option, callback) => {
        const { investorEntityType } = this.state;
        let value = ""

        if (!investorEntityType || isObjectEmpty(investorEntityType)) {
            return
        }

        if (option && option.length > 2) {
            value = option;
        }

        const entities = await getEntities(investorEntityType.value.toLowerCase(), value)

        this.setState({ entities })
    
        if (callback) {
            callback(entities)
        }
    }

    handleEntityTypeChange = (option) => {
        this.setState({
            investorEntityType: option
        }, () => this.setEntities())
    }

    handleInvestorChange = (option) => {
        this.setState({
            investorEntityId: option
        }, () => {
            this.handleSelectFilter(option, 'investor_id')
            this.handleSearchFilters(this.fundingRequestUrl, null, store.type) }
        )
    }

    clearAllFilters = () => {
        this.handleClearAllFilters(this.fundingRequestUrl)
        this.setState({
            keyword: '',
            investorEntityType: '',
            investorEntityId: '',
        })
    }


    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>Funding Round Requests</h3>
                            <div className='filter-container'>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className="col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing" style={{ paddingLeft: 0 }}>
                                        <div ref={node => this.startup_id = node} className='filter-firstline' style={{ width: '100%' }}>
                                            <div>
                                                <input className='filter-input'
                                                    style={{ paddingLeft: 15 }}
                                                    placeholder="Search by startup name"
                                                    onChange={this.setStartupIds}
                                                    onFocus={() => { this.setState({ showstartup_id: true }) }} />
                                                {this.state.showstartup_id && this.state.startup_ids ?
                                                    <div className='filter-dropdown' >
                                                        <div >
                                                            {this.state.filters.startup_id.length > 0 ?
                                                                <div className='filter-dropdown-wrapper' >
                                                                    {this.state.filters.startup_id.map((owner, i) => {
                                                                        return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'startup_id')}>
                                                                            {owner}&nbsp;<i className="fa fa-times-circle"></i>
                                                                        </div>
                                                                    })}
                                                                </div> : null}
                                                        </div>
                                                        <ul className='filter-list-container'>
                                                            <div className='filter-list-wrapper' >
                                                                <div>
                                                                    {this.state.startup_ids.map((item, j) =>
                                                                        <div key={j}>
                                                                            <li style={{ fontSize: 12, }} onClick={() => this.handleSelectFilter(item, 'startup_id')}>
                                                                                {item.label}
                                                                            </li><br />
                                                                        </div>)}
                                                                </div>
                                                                <div className='filter-stickybtn ' >
                                                                    <div style={{ width: 140 }}>
                                                                        <a tabIndex='0' className='filter-canclebtn' onClick={() => this.handleClearFilter('startup_id', 'funding')}>Clear</a> &nbsp; | &nbsp;
                                                                        <a tabIndex='0' className='filter-applybtn' onClick={() => { this.setState({ showstartup_id: false }); this.handleSearchFilters(this.fundingRequestUrl, null, store.type) }}>Apply</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div> : null}
                                            </div>

                                            {this.state.showstartup_id ? <i className="fa fa-chevron-up " style={{ paddingRight: 10 }} onClick={() => this.setState({ showstartup_id: false })} ></i> :
                                                <i className="fa fa-chevron-down " style={{ paddingRight: 10 }} onClick={() => this.setState({ showstartup_id: true })} ></i>}
                                        </div>
                                    </div>
                                </div>

                                {/* Investor search filter */}
                                <div className="filter-row" style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                        <Select
                                            placeholder='Please select investor type'
                                            styles={SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            value={this.state.investorEntityType}
                                            onChange={this.handleEntityTypeChange}
                                            options={ENTITY_TYPES}
                                        />
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ padding: 0 }}>
                                        <AsyncSelect
                                            placeholder='Please Select or type investor name to search'
                                            styles={SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            cacheOptions
                                            value={this.state.investorEntityId}
                                            defaultOptions={this.state.entities}
                                            loadOptions={(option, callback) => this.setEntities(option, callback)}
                                            onChange={this.handleInvestorChange}
                                        />
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                        <div ref={node => this.stage = node} className='filter-firstline' >
                                            <div style={{ width: '100%' }}>
                                                <input className='filter-input'
                                                    style={{ paddingLeft: 15 }}
                                                    placeholder="By stage of funding"
                                                    value={this.state.funding_stage}
                                                    onChange={(e) => this.handleInputChange(e.target.value, 'industry')}
                                                    onFocus={() => { this.setState({ showfunding_stage: true }) }} />
                                                {this.state.showfunding_stage && this.state.funding_stages ?
                                                    <div className='filter-dropdown' >
                                                        <div >
                                                            {this.state.filters.funding_stage.length > 0 ?
                                                                <div className='filter-dropdown-wrapper' >
                                                                    {this.state.filters.funding_stage.map((item, i) => {
                                                                        return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'funding_stage')}>
                                                                            {item}&nbsp;<i className="fa fa-times-circle"></i>
                                                                        </div>
                                                                    })}
                                                                </div> : null}
                                                        </div>
                                                        <ul className='filter-list-container'>
                                                            <div className='filter-list-wrapper' >
                                                                <div>
                                                                    {this.state.funding_stages.map((item, j) =>
                                                                        <div key={j}>
                                                                            <li style={{ fontSize: 12, }} onClick={() => this.handleSelectFilter(item, 'funding_stage')}>
                                                                                {item.label}
                                                                            </li><br />
                                                                        </div>)}
                                                                </div>
                                                                <div className='filter-stickybtn ' >
                                                                    <div style={{ width: 140 }}>
                                                                        <a tabIndex='0' className='filter-canclebtn' onClick={() => this.handleClearFilter('funding_stage', 'funding')}>Clear</a> &nbsp; | &nbsp;
                                                                        <a tabIndex='0' className='filter-applybtn' onClick={() => { this.setState({ showfunding_stage: false }); this.handleSearchFilters(this.fundingRequestUrl, null, store.type) }}>Apply</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div> : null}
                                            </div>
                                            {this.state.showfunding_stage ? <i className="fa fa-chevron-up " style={{ paddingRight: 10 }} onClick={() => this.setState({ showfunding_stage: false })} ></i> :
                                                <i className="fa fa-chevron-down " style={{ paddingRight: 10 }} onClick={() => this.setState({ showfunding_stage: true })} ></i>}
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ padding: 0 }}>
                                        <Select
                                            placeholder='Filter by is verified'
                                            styles={SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            value={this.state.verification_status}
                                            onChange={(option) => this.handleSingleSelectFilter(option, 'verification_status', this.fundingRequestUrl)}
                                            options={FUNDING_STATUS_FILTER_OPTIONS}
                                        />
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-6 filter-searchbtn" onClick={() => this.clearAllFilters()}>Clear All</a>
                                    </div>
                                </div>

                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    defaultColDef={this.state.defaultColDef}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    frameworkComponents={this.state.frameworkComponents}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                >
                                </AgGridReact>
                            </div>

                            <Pagination
                                path={API_PATH.fundingRequests}
                                details="funding-round-requests"
                                fetchData={this.getInitialData}
                            />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal getInitialData={this.getInitialData}/>
                        <InvestorsViewModal />

                    </div>
                }
            </div>
        )
    }
}


export default observer(FundingRoundRequests)
