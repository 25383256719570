import React, { Component } from 'react';
import { observer } from 'mobx-react';

import store from '../../../store';

class ViewPolicyModal extends Component {
    handleCloseModal = () => {
        $('#dataExportPolicyModal').modal('hide');
    }

    renderData = () => {
        let tableRows = [];
        let content = <div style={{textAlign: 'center'}}>No policy to show</div>
        
        if (store.dataExportPolicyModalData && Array.isArray(store.dataExportPolicyModalData.policy) && store.dataExportPolicyModalData.policy.length > 0) {
            store.dataExportPolicyModalData.policy.map((item, index) => {
                tableRows.push(
                    <tr key={index}>
                        <td>{item.data_set_type}</td>
                        <td>{item.limit ? item.limit : '-'}</td>
                        <td>{item.fields.join(', ')}</td>
                    </tr>
                );
            });
    
        }

        if (tableRows.length > 0) {
            content = <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Data Set Type</th>
                        <th scope="col">Limit</th>
                        <th scope="col">Fields</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </table>
        }

        return content;
    }

    render() {
        return (
            <div className="modal fade" id="dataExportPolicyModal">
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">Policy</h4>
                            <button type="button" className="close" onClick={this.handleCloseModal}>&times;</button>
                        </div>

                        <div className="modal-body">
                            {this.renderData()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default observer(ViewPolicyModal);
