import axios from 'axios';

import { API } from '../constant';
import store from '../store';

const demoRequestApi = {

    patchDemoRequestData: (demoRequestId, payload) => {
        let url = `${API}/demo-request/${demoRequestId}`
        let headers = {
            Authorization: store.token
        }
        
        return new Promise((resolve, reject) => {
            axios.patch(url, payload, {headers})
                .then(response => {
                    if (response.data.status === 200) {
                        resolve(response.data.data)
                    } else {
                        reject({})
                    }
                }).catch(err => reject(err))
        })
    },

}

export default demoRequestApi;