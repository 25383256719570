import axios from 'axios';

import store from '../store';
import { API } from '../constant';
import { prepareSelectParam } from '../utility/queryParams';

const apiProductApi = {

    getApiProducts: () => {
        const fields = ['id', 'name', 'price', 'duration'];
        const paramFields = prepareSelectParam(fields);

        const url = `${API}/api-product?fields=${paramFields}`;
        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(
                    response => {
                        resolve(response.data.data);
                    }
                )
                .catch((err) => reject(err));
        });
    },

    getApiProductDetails: (intApiProductId = '') => {
        const paramFields = ['id', 'name', 'active', 'type', 'duration', 'price', 'currency', 'country_data', 'policy'];
        const fields = prepareSelectParam(paramFields);

        const url = `${API}/api-product/${intApiProductId}/?fields=${fields}`;
        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(
                    response => {
                        resolve(response.data.data);
                    }
                )
                .catch((err) => reject(err));
        });
    }

}

export default apiProductApi;