import React from 'react'
import { observer } from 'mobx-react'
import store from '../../store'
import Parent from '../Parent';
import { API_PATH } from '../../utility/constants/apiPath';

class Sort extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            showSortList: false,
            sort: 'None',
            sortlist: []
        }
    }
    componentDidMount() {
        document.addEventListener('mousedown', (e) => {
            if (this.sort) { if (!this.sort.contains(e.target)) this.setState({ showSortList: false }) }
        })

        switch (store.sortType) {
        case 'startup':
            this.setState({
                sortlist: [
                    { name: 'Startup Id : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'Startup Id : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                    { name: 'Startup Name : Descending', order: 'DESC', field: 'name', icon: 'fas fa-arrow-down' },
                    { name: 'Startup Name : Ascending', order: 'ASC', field: 'name', icon: 'fas fa-arrow-up' },
                    //{ name: 'Industry : Descending', order: 'DESC', field: 'industry_data', icon: 'fas fa-arrow-down' },
                    //{ name: 'Industry : Ascending', order: 'ASC', field: 'industry_data', icon: 'fas fa-arrow-up' },
                    { name: 'Country : Descending', order: 'DESC', field: 'hq_id', icon: 'fas fa-arrow-down' },
                    { name: 'Country : Ascending', order: 'ASC', field: 'hq_id', icon: 'fas fa-arrow-up' },
                    { name: 'Owner : Descending', order: 'DESC', field: 'owned_by', icon: 'fas fa-arrow-down' },
                    { name: 'Owner : Ascending', order: 'ASC', field: 'owned_by', icon: 'fas fa-arrow-up' },
                    { name: 'Total Funding : Descending', order: 'DESC', field: 'total_fund', icon: 'fas fa-arrow-down' },
                    { name: 'Total Funding : Ascending', order: 'ASC', field: 'total_fund', icon: 'fas fa-arrow-up' },
                    { name: 'Date Founded : Descending', order: 'DESC', field: 'date_founded', icon: 'fas fa-arrow-down' },
                    { name: 'Date Founded : Ascending', order: 'ASC', field: 'date_founded', icon: 'fas fa-arrow-up' },
                    { name: 'Created On : Descending', order: 'DESC', field: 'created_at', icon: 'fas fa-arrow-down' },
                    { name: 'Created On : Ascending', order: 'ASC', field: 'created_at', icon: 'fas fa-arrow-up' },
                    { name: 'Updated On : Descending', order: 'DESC', field: 'updated_at', icon: 'fas fa-arrow-down' },
                    { name: 'Updated On : Ascending', order: 'ASC', field: 'updated_at', icon: 'fas fa-arrow-up' },
                ]
            })
            break;
        case 'investor':
            this.setState({
                sortlist: [
                    { name: 'Investor Id : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'Investor Id : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                    { name: 'Investor Name : Descending', order: 'DESC', field: 'name', icon: 'fas fa-arrow-down' },
                    { name: 'Investor Name : Ascending', order: 'ASC', field: 'name', icon: 'fas fa-arrow-up' },
                    { name: 'Investor Type : Descending', order: 'DESC', field: 'type_id', icon: 'fas fa-arrow-down' },
                    { name: 'Investor Type : Ascending', order: 'ASC', field: 'type_id', icon: 'fas fa-arrow-up' },
                    // { name: 'Stage Focus : Descending', order: 'DESC', field: 'name', icon: 'fas fa-arrow-down' },
                    // { name: 'Stage Focus : Ascending', order: 'ASC', field: 'name', icon: 'fas fa-arrow-up' },
                    // { name: 'Industry Focus : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    // { name: 'Industry Focus : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                    { name: 'Owner : Descending', order: 'DESC', field: 'owned_by', icon: 'fas fa-arrow-down' },
                    { name: 'Owner : Ascending', order: 'ASC', field: 'owned_by', icon: 'fas fa-arrow-up' },
                    { name: 'Created On : Descending', order: 'DESC', field: 'created_at', icon: 'fas fa-arrow-down' },
                    { name: 'Created On : Ascending', order: 'ASC', field: 'created_at', icon: 'fas fa-arrow-up' },
                    { name: 'Updated On : Descending', order: 'DESC', field: 'updated_at', icon: 'fas fa-arrow-down' },
                    { name: 'Updated On : Ascending', order: 'ASC', field: 'updated_at', icon: 'fas fa-arrow-up' },
                ]
            })
            break;
        case 'enabler':
            this.setState({
                sortlist: [
                    { name: 'Enabler Id : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'Enabler Id : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                    { name: 'Enabler Name : Descending', order: 'DESC', field: 'name', icon: 'fas fa-arrow-down' },
                    { name: 'Enabler Name : Ascending', order: 'ASC', field: 'name', icon: 'fas fa-arrow-up' },
                    { name: 'Enabler Type : Descending', order: 'DESC', field: 'type_id', icon: 'fas fa-arrow-down' },
                    { name: 'Enabler Type : Ascending', order: 'ASC', field: 'type_id', icon: 'fas fa-arrow-up' },
                    // { name: 'Stage Focus : Descending', order: 'DESC', field: 'name', icon: 'fas fa-arrow-down' },
                    // { name: 'Stage Focus : Ascending', order: 'ASC', field: 'name', icon: 'fas fa-arrow-up' },
                    // { name: 'Industry Focus : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    // { name: 'Industry Focus : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                    { name: 'Owner : Descending', order: 'DESC', field: 'owned_by', icon: 'fas fa-arrow-down' },
                    { name: 'Owner : Ascending', order: 'ASC', field: 'owned_by', icon: 'fas fa-arrow-up' },
                    { name: 'Created On : Descending', order: 'DESC', field: 'created_at', icon: 'fas fa-arrow-down' },
                    { name: 'Created On : Ascending', order: 'ASC', field: 'created_at', icon: 'fas fa-arrow-up' },
                    { name: 'Updated On : Descending', order: 'DESC', field: 'updated_at', icon: 'fas fa-arrow-down' },
                    { name: 'Updated On : Ascending', order: 'ASC', field: 'updated_at', icon: 'fas fa-arrow-up' },
                ]
            })
            break;
        case 'corporate':
            this.setState({
                sortlist: [
                    { name: 'Corporate Id : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'Corporate Id : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                    { name: 'Corporate Name : Descending', order: 'DESC', field: 'name', icon: 'fas fa-arrow-down' },
                    { name: 'Corporate Name : Ascending', order: 'ASC', field: 'name', icon: 'fas fa-arrow-up' },
                    { name: 'Corporate Type : Descending', order: 'DESC', field: 'type_id', icon: 'fas fa-arrow-down' },
                    { name: 'Corporate Type : Ascending', order: 'ASC', field: 'type_id', icon: 'fas fa-arrow-up' },
                    /*{ name: 'Stage Focus : Descending', order: 'DESC', field: 'name', icon: 'fas fa-arrow-down' },
                        { name: 'Stage Focus : Ascending', order: 'ASC', field: 'name', icon: 'fas fa-arrow-up' },
                        { name: 'Industry Focus : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                        { name: 'Industry Focus : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },*/
                    { name: 'Owner : Descending', order: 'DESC', field: 'owned_by', icon: 'fas fa-arrow-down' },
                    { name: 'Owner : Ascending', order: 'ASC', field: 'owned_by', icon: 'fas fa-arrow-up' },
                    { name: 'Created On : Descending', order: 'DESC', field: 'created_at', icon: 'fas fa-arrow-down' },
                    { name: 'Created On : Ascending', order: 'ASC', field: 'created_at', icon: 'fas fa-arrow-up' },
                    { name: 'Updated On : Descending', order: 'DESC', field: 'updated_at', icon: 'fas fa-arrow-down' },
                    { name: 'Updated On : Ascending', order: 'ASC', field: 'updated_at', icon: 'fas fa-arrow-up' },
                ]
            })
            break;
        case 'user':
            this.setState({
                sortlist: [
                    { name: 'User Id : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'User Id : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                    { name: 'User Name : Descending', order: 'DESC', field: 'username', icon: 'fas fa-arrow-down' },
                    { name: 'User Name : Ascending', order: 'ASC', field: 'username', icon: 'fas fa-arrow-up' },
                    { name: 'First Name : Descending', order: 'DESC', field: 'first_name', icon: 'fas fa-arrow-down' },
                    { name: 'First Name : Ascending', order: 'ASC', field: 'first_name', icon: 'fas fa-arrow-up' },
                    { name: 'Last Name : Descending', order: 'DESC', field: 'last_name', icon: 'fas fa-arrow-down' },
                    { name: 'Last Name : Ascending', order: 'ASC', field: 'last_name', icon: 'fas fa-arrow-up' },
                ]
            })
            break;
        case 'people':
            this.setState({
                sortlist: [
                    { name: 'People Id : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'People Id : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                    { name: 'First Name : Descending', order: 'DESC', field: 'first_name', icon: 'fas fa-arrow-down' },
                    { name: 'First Name : Ascending', order: 'ASC', field: 'first_name', icon: 'fas fa-arrow-up' },
                    { name: 'Last Name : Descending', order: 'DESC', field: 'last_name', icon: 'fas fa-arrow-down' },
                    { name: 'Last Name : Ascending', order: 'ASC', field: 'last_name', icon: 'fas fa-arrow-up' },
                ]
            })
            break;
        case 'news':
            this.setState({
                sortlist: [
                    { name: 'Total Views : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'Total Views : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                    { name: 'Post Date : Descending', order: 'DESC', field: 'published_at', icon: 'fas fa-arrow-down' },
                    { name: 'Post Date : Ascending', order: 'ASC', field: 'published_at', icon: 'fas fa-arrow-up' },
                    { name: 'Featured : Descending', order: 'DESC', field: 'is_featured', icon: 'fas fa-arrow-down' },
                    { name: 'Featured : Ascending', order: 'ASC', field: 'is_featured', icon: 'fas fa-arrow-up' },
                ]
            })
            break;
        case 'job':
            this.setState({
                sortlist: [
                    { name: 'JOB Name : Descending', order: 'DESC', field: 'title', icon: 'fas fa-arrow-down' },
                    { name: 'JOB Name : Ascending', order: 'ASC', field: 'title', icon: 'fas fa-arrow-up' },
                    { name: 'Deadline : Descending', order: 'DESC', field: 'deadline', icon: 'fas fa-arrow-down' },
                    { name: 'Deadline : Ascending', order: 'ASC', field: 'deadline', icon: 'fas fa-arrow-up' },
                    { name: 'Featured : Descending', order: 'DESC', field: 'is_featured', icon: 'fas fa-arrow-down' },
                    { name: 'Featured : Ascending', order: 'ASC', field: 'is_featured', icon: 'fas fa-arrow-up' },
                ]
            })
            break;
        case 'research':
            this.setState({
                sortlist: [
                    /*{ name: 'Published : Published', order: 'DESC', field: 'published', icon: 'fas fa-arrow-down' },
                        { name: 'Published : Unpublished', order: 'ASC', field: 'published', icon: 'fas fa-arrow-up' },
                        { name: 'Published : Draft', order: 'DESC', field: 'published', icon: 'fas fa-arrow-down' },*/
                    { name: 'Price : Descending', order: 'DESC', field: 'price', icon: 'fas fa-arrow-down' },
                    { name: 'Price : Ascending', order: 'ASC', field: 'price', icon: 'fas fa-arrow-up' },
                    { name: 'Published Date : Descending', order: 'DESC', field: 'published_at', icon: 'fas fa-arrow-down' },
                    { name: 'Published Date : Ascending', order: 'ASC', field: 'published_at', icon: 'fas fa-arrow-up' },
                    { name: 'Featured : Descending', order: 'DESC', field: 'is_featured', icon: 'fas fa-arrow-down' },
                    { name: 'Featured : Ascending', order: 'ASC', field: 'is_featured', icon: 'fas fa-arrow-up' },
                ]
            })
            break;
        case 'banner':
            this.setState({
                sortlist: [
                    { name: 'Ad Id : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'Ad Id : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                    { name: 'Ad Name : Descending', order: 'DESC', field: 'name', icon: 'fas fa-arrow-down' },
                    { name: 'Ad Name : Ascending', order: 'ASC', field: 'name', icon: 'fas fa-arrow-up' },
                    { name: 'Start Date : Descending', order: 'DESC', field: 'start_date', icon: 'fas fa-arrow-down' },
                    { name: 'Start Date : Ascending', order: 'ASC', field: 'start_date', icon: 'fas fa-arrow-up' },
                    { name: 'End Date: Descending', order: 'DESC', field: 'end_date', icon: 'fas fa-arrow-down' },
                    { name: 'End Date : Ascending', order: 'ASC', field: 'end_date', icon: 'fas fa-arrow-up' },
                ]
            })
            break;
        case 'role':
            this.setState({
                sortlist: [
                    { name: 'Role Id : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'Role Id : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                    { name: 'Role Name : Descending', order: 'DESC', field: 'name', icon: 'fas fa-arrow-down' },
                    { name: 'Role Name : Ascending', order: 'ASC', field: 'name', icon: 'fas fa-arrow-up' },
                    { name: 'Created On : Descending', order: 'DESC', field: 'created_at', icon: 'fas fa-arrow-down' },
                    { name: 'Created On : Ascending', order: 'ASC', field: 'created_at', icon: 'fas fa-arrow-up' },
                    { name: 'Updated On : Descending', order: 'DESC', field: 'updated_at', icon: 'fas fa-arrow-down' },
                    { name: 'Updated On : Ascending', order: 'ASC', field: 'updated_at', icon: 'fas fa-arrow-up' },
                ]
            })
            break;
        case 'resource':
            this.setState({
                sortlist: [
                    { name: 'Resource Id : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'Resource Id : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                    { name: 'Resource Name : Descending', order: 'DESC', field: 'name', icon: 'fas fa-arrow-down' },
                    { name: 'Resource Name : Ascending', order: 'ASC', field: 'name', icon: 'fas fa-arrow-up' },
                ]
            })
            break;
        case 'permission':
            this.setState({
                sortlist: [
                    { name: 'Permission Id : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'Permission Id : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                    { name: 'Created On : Descending', order: 'DESC', field: 'created_at', icon: 'fas fa-arrow-down' },
                    { name: 'Created On : Ascending', order: 'ASC', field: 'created_at', icon: 'fas fa-arrow-up' },
                    { name: 'Updated On : Descending', order: 'DESC', field: 'updated_at', icon: 'fas fa-arrow-down' },
                    { name: 'Updated On : Ascending', order: 'ASC', field: 'updated_at', icon: 'fas fa-arrow-up' },
                ]
            })
            break;
        case 'application':
            this.setState({
                sortlist: [
                    { name: 'Application Id : Descending', order: 'DESC', field: '_id', icon: 'fas fa-arrow-down' },
                    { name: 'Application Id : Ascending', order: 'ASC', field: '_id', icon: 'fas fa-arrow-up' },
                    { name: 'Deadline : Descending', order: 'DESC', field: 'end_date', icon: 'fas fa-arrow-down' },
                    { name: 'Deadline : Ascending', order: 'ASC', field: 'end_date', icon: 'fas fa-arrow-up' },
                    { name: 'Response Rate : Descending', order: 'DESC', field: 'response_rate', icon: 'fas fa-arrow-down' },
                    { name: 'Response Rate : Ascending', order: 'ASC', field: 'response_rate', icon: 'fas fa-arrow-up' },
                ]
            })
            break;
        case 'subscription':
            this.setState({
                sortlist: [
                    { name: 'Subscription Id : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'Subscription Id : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                    { name: 'Price : Descending', order: 'DESC', field: 'price', icon: 'fas fa-arrow-down' },
                    { name: 'Price : Ascending', order: 'ASC', field: 'price', icon: 'fas fa-arrow-up' },
                ]
            })
            break;
        case 'subscription-types':
            this.setState({
                sortlist: [
                    { name: 'Subscription Type Id : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'Subscription Type Id : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' }
                ]
            })
            break
        case 'order':
            this.setState({
                sortlist: [
                    { name: 'Start date : Descending', order: 'DESC', field: 'created_at', icon: 'fas fa-arrow-down' },
                    { name: 'Start date : Ascending', order: 'ASC', field: 'created_at', icon: 'fas fa-arrow-up' },
                    { name: 'Expiry date : Descending', order: 'DESC', field: 'end_date', icon: 'fas fa-arrow-down' },
                    { name: 'Expiry date : Ascending', order: 'ASC', field: 'end_date', icon: 'fas fa-arrow-up' }
                ]
            })
            break
        case 'view-research-downloads':
            this.setState({
                sortlist: [
                    { name: 'Download Date : Descending', order: 'DESC', field: 'download_date', icon: 'fas fa-arrow-down' },
                    { name: 'Download Date : Ascending', order: 'ASC', field: 'download_date', icon: 'fas fa-arrow-up' },
                ]
            })
            break;
        case 'email-template':
            this.setState({
                sortlist: [
                    { name: 'Name : Descending', order: 'DESC', field: 'name', icon: 'fas fa-arrow-down' },
                    { name: 'Name : Ascending', order: 'ASC', field: 'name', icon: 'fas fa-arrow-up' },
                ]
            })
            break;
        case 'funding-round-requests':
            this.setState({
                sortlist: [
                    { name: 'id : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'id : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                    { name: 'Startup : Descending', order: 'DESC', field: 'startup_name', icon: 'fas fa-arrow-down' },
                    { name: 'Startup : Ascending', order: 'ASC', field: 'startup_name', icon: 'fas fa-arrow-up' },
                    { name: 'Date raised : Descending', order: 'DESC', field: 'date_raised', icon: 'fas fa-arrow-down' },
                    { name: 'Date raised : Ascending', order: 'ASC', field: 'date_raised', icon: 'fas fa-arrow-up' },
                    { name: 'Created at : Descending', order: 'DESC', field: 'created_at', icon: 'fas fa-arrow-down' },
                    { name: 'Created at : Ascending', order: 'ASC', field: 'created_at', icon: 'fas fa-arrow-up' },
                ]
            })
            break;
        case 'rd-policy':
            this.setState({
                sortlist: [
                    { name: 'ID : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'ID : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                ]
            })
            break;
        case 'export-request':
            this.setState({
                sortlist: [
                    { name: 'ID : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'ID : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                ]
            })
            break;
        case 'demo-request':
            this.setState({
                sortlist: [
                    { name: 'ID : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'ID : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                ]
            })
            break;
        case 'ecosystem':
            this.setState({
                sortlist: [
                    { name: 'ID : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'ID : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                ]
            })
            break;
        case 'undisclosed-proxy':
            this.setState({
                sortlist: [
                    { name: 'ID : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'ID : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                    { name: 'YEAR : Descending', order: 'DESC', field: 'year', icon: 'fas fa-arrow-down' },
                    { name: 'YEAR : Ascending', order: 'ASC', field: 'year', icon: 'fas fa-arrow-up' },
                    { name: 'VALUE : Descending', order: 'DESC', field: 'value', icon: 'fas fa-arrow-down' },
                    { name: 'VALUE : Ascending', order: 'ASC', field: 'value', icon: 'fas fa-arrow-up' },
                ]
            })
            break;
        case API_PATH.undisclosedProxyLog:
            this.setState({
                sortlist: [
                    { name: 'ID : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'ID : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                    { name: 'Start time : Descending', order: 'DESC', field: 'date_raised', icon: 'fas fa-arrow-down' },
                    { name: 'Start time : Ascending', order: 'ASC', field: 'date_raised', icon: 'fas fa-arrow-up' },
                    { name: 'End time : Descending', order: 'DESC', field: 'created_at', icon: 'fas fa-arrow-down' },
                    { name: 'End time : Ascending', order: 'ASC', field: 'created_at', icon: 'fas fa-arrow-up' },
                ]
            });
            break;
        case 'data-export-policy':
            this.setState({
                sortlist: [
                    { name: 'ID : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'ID : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' }
                ]
            })
            break;
        case 'data-export-log':
            this.setState({
                sortlist: [
                    { name: 'ID : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'ID : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                    { name: 'NUMBER OF RECORDS : Descending', order: 'DESC', field: 'number_of_records', icon: 'fas fa-arrow-down' },
                    { name: 'NUMBER OF RECORDS : Ascending', order: 'ASC', field: 'number_of_records', icon: 'fas fa-arrow-up' }
                ]
            })
            break;
        case 'data-export-product':
            this.setState({
                sortlist: [
                    { name: 'ID : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'ID : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' }
                ]
            })
            break;
        case API_PATH.funding:
            this.setState({
                sortlist: [
                    { name: 'ID : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'ID : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                    { name: 'Created At : Descending', order: 'DESC', field: 'created_at', icon: 'fas fa-arrow-down' },
                    { name: 'Created At : Ascending', order: 'ASC', field: 'created_at', icon: 'fas fa-arrow-up' },
                    { name: 'Updated At : Descending', order: 'DESC', field: 'updated_at', icon: 'fas fa-arrow-down' },
                    { name: 'Updated At : Ascending', order: 'ASC', field: 'updated_at', icon: 'fas fa-arrow-up' },
                    { name: 'Funding Amount : Descending', order: 'DESC', field: 'amount_raised', icon: 'fas fa-arrow-down' },
                    { name: 'Funding Amount : Ascending', order: 'ASC', field: 'amount_raised', icon: 'fas fa-arrow-up' }
                ]
            })
            break;
        case 'funding-verification':
            this.setState({
                sortlist: [
                    { name: 'FB ID : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'FB ID : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                    { name: 'Date Raised : Descending', order: 'DESC', field: 'date_raised', icon: 'fas fa-arrow-down' },
                    { name: 'Date Raised : Ascending', order: 'ASC', field: 'date_raised', icon: 'fas fa-arrow-up' },
                    { name: 'Created At : Descending', order: 'DESC', field: 'created_at', icon: 'fas fa-arrow-down' },
                    { name: 'Created At : Ascending', order: 'ASC', field: 'created_at', icon: 'fas fa-arrow-up' },
                ]
            });
            break;
        case 'valuation-calculation':
            this.setState({
                sortlist: [
                    { name: 'Val Calc ID: Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'Val Calc ID: Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                    { name: 'Funding Stage Id : Descending', order: 'DESC', field: 'funding_stage_id', icon: 'fas fa-arrow-down' },
                    { name: 'Funding Stage Id : Ascending', order: 'ASC', field: 'funding_stage_id', icon: 'fas fa-arrow-up' },
                    { name: 'Lower Share Percentage : Descending', order: 'DESC', field: 'lower_share_percentage', icon: 'fas fa-arrow-down' },
                    { name: 'Lower Share Percentage : Ascending', order: 'ASC', field: 'lower_share_percentage', icon: 'fas fa-arrow-up' },
                    { name: 'Upper Share Percentage : Descending', order: 'DESC', field: 'upper_share_percentage', icon: 'fas fa-arrow-down' },
                    { name: 'Upper Share Percentage : Ascending', order: 'ASC', field: 'upper_share_percentage', icon: 'fas fa-arrow-up' },
                    { name: 'Equity Percentage : Descending', order: 'DESC', field: 'equity_percentage', icon: 'fas fa-arrow-down' },
                    { name: 'Equity Percentage : Ascending', order: 'ASC', field: 'equity_percentage', icon: 'fas fa-arrow-up' },
                    { name: 'Created At : Descending', order: 'DESC', field: 'created_at', icon: 'fas fa-arrow-down' },
                    { name: 'Created At : Ascending', order: 'ASC', field: 'created_at', icon: 'fas fa-arrow-up' },
                ]
            });
            break;
        case 'fund':
            this.setState({
                sortlist: [
                    { name: 'Name : Descending', order: 'DESC', field: 'name', icon: 'fas fa-arrow-down' },
                    { name: 'Name : Ascending', order: 'ASC', field: 'name', icon: 'fas fa-arrow-up' },
                    { name: 'Entity ID: Descending', order: 'DESC', field: 'entity_id', icon: 'fas fa-arrow-down' },
                    { name: 'Entity ID: Ascending', order: 'ASC', field: 'entity_id', icon: 'fas fa-arrow-up' },
                    { name: 'Entity Type : Descending', order: 'DESC', field: 'entity_type', icon: 'fas fa-arrow-down' },
                    { name: 'Entity Type : Ascending', order: 'ASC', field: 'entity_type', icon: 'fas fa-arrow-up' },
                    { name: 'Created ON : Descending', order: 'DESC', field: 'created_at', icon: 'fas fa-arrow-down' },
                    { name: 'Created ON : Ascending', order: 'ASC', field: 'created_at', icon: 'fas fa-arrow-up' },
                    { name: 'Updated ON: Descending', order: 'DESC', field: 'updated_at', icon: 'fas fa-arrow-down' },
                    { name: 'Updated ON: Ascending', order: 'ASC', field: 'updated_at', icon: 'fas fa-arrow-up' },
                ]
            });
            break;
        case API_PATH.valuationCalculationLog:
            this.setState({
                sortlist: [
                    { name: 'ID : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'ID : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                    { name: 'Start time : Descending', order: 'DESC', field: 'date_raised', icon: 'fas fa-arrow-down' },
                    { name: 'Start time : Ascending', order: 'ASC', field: 'date_raised', icon: 'fas fa-arrow-up' },
                    { name: 'End time : Descending', order: 'DESC', field: 'created_at', icon: 'fas fa-arrow-down' },
                    { name: 'End time : Ascending', order: 'ASC', field: 'created_at', icon: 'fas fa-arrow-up' },
                ]
            });
            break;
        case API_PATH.customDashboard:
            this.setState({
                sortlist: [
                    { name: 'ID: Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'ID: Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                    { name: 'Name: Descending', order: 'DESC', field: 'name', icon: 'fas fa-arrow-down' },
                    { name: 'Name: Ascending', order: 'ASC', field: 'name', icon: 'fas fa-arrow-up' },
                    { name: 'Created ON : Descending', order: 'DESC', field: 'created_at', icon: 'fas fa-arrow-down' },
                    { name: 'Created ON : Ascending', order: 'ASC', field: 'created_at', icon: 'fas fa-arrow-up' },
                    { name: 'Updated ON: Descending', order: 'DESC', field: 'updated_at', icon: 'fas fa-arrow-down' },
                    { name: 'Updated ON: Ascending', order: 'ASC', field: 'updated_at', icon: 'fas fa-arrow-up' },
                ]
            })
            break
        case API_PATH.organization:
        case API_PATH.organizationRole:
        case API_PATH.apiClient:
        case API_PATH.subscriptionAddon:
        case 'news-utm':
            this.setState({
                sortlist: [
                    { name: 'ID : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'ID : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' }
                ]
            })
            break;
        case 'technology':
            this.setState({
                sortlist: [
                    { name: 'Tech ID : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'Tech ID : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                    { name: 'Tech Name : Descending', order: 'DESC', field: 'name', icon: 'fas fa-arrow-down' },
                    { name: 'Tech Name : Ascending', order: 'ASC', field: 'name', icon: 'fas fa-arrow-up' },
                    { name: 'Created On : Descending', order: 'DESC', field: 'created_at', icon: 'fas fa-arrow-down' },
                    { name: 'Created On : Ascending', order: 'ASC', field: 'created_at', icon: 'fas fa-arrow-up' },
                    { name: 'Updated On : Descending', order: 'DESC', field: 'updated_at', icon: 'fas fa-arrow-down' },
                    { name: 'Updated On : Ascending', order: 'ASC', field: 'updated_at', icon: 'fas fa-arrow-up' }
                ]
            })
            break;
        case API_PATH.salesAutomationEmail:
            this.setState({
                sortlist: [
                    { name: 'ID : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'ID : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                    { name: 'Taxonomy ID : Descending', order: 'DESC', field: 'taxonomy_id', icon: 'fas fa-arrow-down' },
                    { name: 'Taxonomy ID : Ascending', order: 'ASC', field: 'taxonomy_id', icon: 'fas fa-arrow-up' },
                    { name: 'Taxonomy Type : Descending', order: 'DESC', field: 'taxonomy_type', icon: 'fas fa-arrow-down' },
                    { name: 'Taxonomy Type : Ascending', order: 'ASC', field: 'taxonomy_type', icon: 'fas fa-arrow-up' },
                    { name: 'Email Template ID : Descending', order: 'DESC', field: 'email_template_id', icon: 'fas fa-arrow-down' },
                    { name: 'Email Template ID : Ascending', order: 'ASC', field: 'email_template_id', icon: 'fas fa-arrow-up' },
                    { name: 'Created On : Descending', order: 'DESC', field: 'created_at', icon: 'fas fa-arrow-down' },
                    { name: 'Created On : Ascending', order: 'ASC', field: 'created_at', icon: 'fas fa-arrow-up' },
                    { name: 'Updated On : Descending', order: 'DESC', field: 'updated_at', icon: 'fas fa-arrow-down' },
                    { name: 'Updated On : Ascending', order: 'ASC', field: 'updated_at', icon: 'fas fa-arrow-up' },
                ]
            });
            break;
        case 'client-directory':
            this.setState({
                sortlist: [
                    { name: 'Client Directory ID: Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'Client Directory ID: Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                    { name: 'Title : Descending', order: 'DESC', field: 'title', icon: 'fas fa-arrow-down' },
                    { name: 'Title : Ascending', order: 'ASC', field: 'title', icon: 'fas fa-arrow-up' },
                    { name: 'Status : Descending', order: 'DESC', field: 'status', icon: 'fas fa-arrow-down' },
                    { name: 'Status : Ascending', order: 'ASC', field: 'status', icon: 'fas fa-arrow-up' },
                    { name: 'Type : Descending', order: 'DESC', field: 'type', icon: 'fas fa-arrow-down' },
                    { name: 'Type : Ascending', order: 'ASC', field: 'type', icon: 'fas fa-arrow-up' },
                    { name: 'Created At : Descending', order: 'DESC', field: 'created_at', icon: 'fas fa-arrow-down' },
                    { name: 'Created At : Ascending', order: 'ASC', field: 'created_at', icon: 'fas fa-arrow-up' },
                ]
            });
            break;
        default:
            this.setState({
                sortlist: [
                    { name: 'Startup Id : Descending', order: 'DESC', field: 'id', icon: 'fas fa-arrow-down' },
                    { name: 'Startup Id : Ascending', order: 'ASC', field: 'id', icon: 'fas fa-arrow-up' },
                    { name: 'Startup Name : Descending', order: 'DESC', field: 'name', icon: 'fas fa-arrow-down' },
                    { name: 'Startup Name : Ascending', order: 'ASC', field: 'name', icon: 'fas fa-arrow-up' },
                    //{ name: 'Industry : Descending', order: 'DESC', field: 'industry_data', icon: 'fas fa-arrow-down' },
                    //{ name: 'Industry : Ascending', order: 'ASC', field: 'industry_data', icon: 'fas fa-arrow-up' },
                    { name: 'Country : Descending', order: 'DESC', field: 'hq_id', icon: 'fas fa-arrow-down' },
                    { name: 'Country : Ascending', order: 'ASC', field: 'hq_id', icon: 'fas fa-arrow-up' },
                    { name: 'Owner : Descending', order: 'DESC', field: 'owned_by', icon: 'fas fa-arrow-down' },
                    { name: 'Owner : Ascending', order: 'ASC', field: 'owned_by', icon: 'fas fa-arrow-up' },
                    { name: 'Total Funding : Descending', order: 'DESC', field: 'total_fund', icon: 'fas fa-arrow-down' },
                    { name: 'Total Funding : Ascending', order: 'ASC', field: 'total_fund', icon: 'fas fa-arrow-up' },
                    { name: 'Date Founded : Descending', order: 'DESC', field: 'date_founded', icon: 'fas fa-arrow-down' },
                    { name: 'Date Founded : Ascending', order: 'ASC', field: 'date_founded', icon: 'fas fa-arrow-up' },
                    { name: 'Created On : Descending', order: 'DESC', field: 'created_at', icon: 'fas fa-arrow-down' },
                    { name: 'Created On : Ascending', order: 'ASC', field: 'created_at', icon: 'fas fa-arrow-up' },
                    { name: 'Updated On : Descending', order: 'DESC', field: 'updated_at', icon: 'fas fa-arrow-down' },
                    { name: 'Updated On : Ascending', order: 'ASC', field: 'updated_at', icon: 'fas fa-arrow-up' },
                ]
            })
            break;
        }
    }

    componentWillUnmount() {
        document.addEventListener('mousedown', (e) => {
            if (this.sort) { if (!this.sort.contains(e.target)) this.setState({ showSortList: false }) }
        })
    }

    render() {
        return (
            <div ref={node => this.sort = node} className='sort-container'>
                <div >
                    <span> Sort by </span>&nbsp;
                    <a onClick={() => this.setState({ showSortList: !this.state.showSortList })} >
                        <span className='sort-color'>{this.state.sort} </span> &nbsp;&nbsp;
                        <i className={this.state.showSortList ? 'fa fa-chevron-up sort-color' : 'fa fa-chevron-down sort-color'} ></i>
                    </a>
                </div>

                {this.state.showSortList ?
                    <div className='sort-dropdown'>
                        {this.state.sortlist.map((item, i) => {
                            return <li key={i}
                                className='sort-item'
                                style={{ color: this.state.sort === item.name ? '#216ba5' : '' }}
                                onClick={() => this.handleSortChange(item)} >
                                {item.name}
                                <i className={this.state.sort === item.name ? 'fa fa-check sort-selected' : 'sort-selected'}></i>
                            </li>
                        })}
                    </div> : null}
            </div>
        )
    }
}

export default observer(Sort)
