import React, { Component } from 'react';
import { observer } from 'mobx-react';

import store from '../../../store';

class ViewUndisclosedProxyLogModal extends Component {
    handleCloseModal = () => {
        $('#undisclosedProxyLogModal').modal('hide');
    }

    renderData = () => {
        let tableRows = [];
        let content = <div style={{ textAlign: 'center' }}>No data</div>

        if (store.undisclosedProxyLogModalData && store.undisclosedProxyLogModalData.calculation_data) {
            store.undisclosedProxyLogModalData.calculation_data.map((item, index) => {
                tableRows.push(
                    <tr key={index}>
                        <td>{item.year}</td>
                        <td>{item.funding_stage_id}</td>
                    </tr>
                );
            });

        }

        if (tableRows.length > 0) {
            content = <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Year</th>
                        <th scope="col">Funding Stage</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </table>
        }

        return content;
    }

    render() {
        return (
            <div className="modal fade" id="undisclosedProxyLogModal">
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">Calculation Details</h4>
                            <button type="button" className="close" onClick={this.handleCloseModal}>&times;</button>
                        </div>

                        <div className="modal-body">
                            {this.renderData()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default observer(ViewUndisclosedProxyLogModal);
