import React from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react'
import Select from 'react-select'
import ReactDatePicker from 'react-datepicker'

import store from '../../store'
import { DEMO_REQUEST_COLUMN_HEADERS, ALLOWED_QUERY_OPERATOR, SELECT_STYLES, SELECT_THEME_PRIMARY, DEMO_REQUEST_STATUS } from '../../constant'
import { prepareSelectParam } from '../../utility/queryParams'
import { isObjectEmpty } from '../../utility/helpers'

import Home from '../Home'
import Parent from '../Parent'
import SideNav from '../SideNav'
import ActionRenderer from '../reusable/ActionRenderer'
import Modal from '../reusable/Modal'
import Sort from '../reusable/Sort'
import Pagination from '../reusable/Pagination'
import CommentModalActionRenderer from '../reusable/CommentModalActionRenderer'
import CommentModal from '../reusable/CommentModal'

import demoRequestApi from '../../apis/demoRequestApi'

class DemoRequest extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: DEMO_REQUEST_COLUMN_HEADERS,
            context: { componentParent: this },
            frameworkComponents: { 
                actionRenderer: ActionRenderer,
                CommentModalRenderer: CommentModalActionRenderer 
            },
            defaultColDef: { suppressMovable: true },

            email: '',
            showemail: '',
            status: '',
            showstatus: '',
            request_date_from: '',
            request_date_to: '',
            filters: {
                email: [],
                status: [],
                request_date: [],
                request_date_from: [],
                request_date_to: [],
            },
            filterFields: [
                { 'key': 'email', 'op': ALLOWED_QUERY_OPERATOR.substring, 'value': [] },
                { 'key': 'status', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'request_date', 'op': ALLOWED_QUERY_OPERATOR.gte, 'value': [] },   
                { 'key': 'request_date', 'op': ALLOWED_QUERY_OPERATOR.lte, 'value': [] }, 
            ]
        }
        store.sortType = this.props.location.pathname.split('/')[1]
    }

    onGridReady(params) {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    componentDidMount() {
        document.addEventListener('mousedown', (e) => {
            if (this.email) { if (!this.email.contains(e.target)) this.setState({ showemail: false }) }
            if (this.status) { if (!this.status.contains(e.target)) this.setState({ showstatus: false }) }
        })        

        this.getInitialData()
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => {
            if (this.email) { if (!this.email.contains(e.target)) this.setState({ showemail: false }) }
            if (this.status) { if (!this.status.contains(e.target)) this.setState({ showstatus: false }) }
        })
    }

    getInitialData = () => {
        let paramFields = ['id', 'request_date', 'email', 'status', 'comments', 'is_subscribed']
        store.fields = prepareSelectParam(paramFields)

        this.fetchListData('demo-request', store.fields)
    }

    saveComment = (requestId, payload) => {
        let requestBody = {
            comments: payload.comment
        }

        demoRequestApi.patchDemoRequestData(requestId, requestBody).then(
            response => {
                if (!isObjectEmpty(response)) {
                    this.getInitialData()
                    $('#commentModal').modal('hide')
                }
            }
        )
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>Demo Requests</h3>
                            <div className='filter-container'>
                                <div className="filter-row" style={{ alignItems: 'center', paddingTop: 10 }}>

                                    <div ref={node => this.email = node} className="col-md-6 col-sm-6 col-xs-12" style={{padding: 0, marginRight: 15}}>
                                        <div className="filter-firstline">
                                            <div>
                                                <input className='filter-input input-border'
                                                    placeholder="Search by Email"
                                                    value={this.state.email}
                                                    onFocus={() => this.setState({ showemail: true })}
                                                    onKeyUp={(e) => this.handleInputEnter(e, 'email')}
                                                    onChange={e => this.setState({ email: e.target.value })} />
                                                {this.state.showemail && this.state.filters.email.length > 0 ?
                                                    <div className='filter-dropdown' style={{ width: '100%' }}>
                                                        <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                            {this.state.filters.email.map((word, i) => {
                                                                return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'email')}>
                                                                    {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                                </div>
                                                            })}
                                                        </div>
                                                    </div> : null}
                                            </div>
                                            {this.state.email.length > 0 &&
                                            <i className="fa fa-times "
                                                style={{ paddingRight: 10 }}
                                                onClick={() => {
                                                    this.input.focus()
                                                    this.setState({ email: '' })
                                                }}>
                                            </i>
                                            }
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                        <Select
                                            placeholder='Please select status'
                                            styles={SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,

                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            value={this.state.status}
                                            onChange={(option) => this.handleSingleSelectFilter(option, 'status')}
                                            options={DEMO_REQUEST_STATUS}
                                        />
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>

                                    <div className="col-md-6 col-sm-6 col-xs-12 filter-spacing" style={{ paddingLeft: 0 }}>
                                        <div ref={node => this.request_date_from = node} className='filter-firstline' >
                                            <div style={{ width: '100%' }}>
                                                <ReactDatePicker
                                                    placeholderText="Request Date - From"
                                                    className='filter-datepicker-form-control'
                                                    dateFormat="yyyy/MM/dd"
                                                    isClearable={this.state.request_date_from}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    selected={this.state.request_date_from}
                                                    onChange={(date) => this.handleDateChange(date, 'request_date_from', 'request_date', ALLOWED_QUERY_OPERATOR.gte)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12 filter-spacing" style={{ padding: 0 }}>
                                        <div ref={node => this.request_date_to = node} className='filter-firstline' >
                                            <div style={{ width: '100%' }}>
                                                <ReactDatePicker
                                                    placeholderText="Request Date - To"
                                                    className='filter-datepicker-form-control'
                                                    dateFormat="yyyy/MM/dd"
                                                    minDate={this.state.request_date_from}
                                                    maxDate={new Date()}
                                                    isClearable={this.state.request_date_to}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    selected={this.state.request_date_to}
                                                    onChange={(date) => this.handleDateChange(date, 'request_date_to', 'request_date', ALLOWED_QUERY_OPERATOR.lte)} />
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-6 filter-searchbtn" onClick={() => this.handleClearAllFilters('demo-request')}>Clear All</a>
                                    </div>
                                </div>

                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    defaultColDef={this.state.defaultColDef}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    frameworkComponents={this.state.frameworkComponents}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                >
                                </AgGridReact>
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal getInitialData={this.getInitialData}/>
                        <CommentModal handleSaveComment={this.saveComment}/>

                    </div>
                }
            </div>
        )
    }
}

export default observer(DemoRequest)