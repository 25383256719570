import React from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

// helpers
import store from '../../store';

// constants
import { SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY, ACTIVE_STATUS } from '../../constant';
import { API_PATH } from '../../utility/constants/apiPath';

// apis
import customDashboardApi from '../../apis/customDashboardApi';
import organizationApi from '../../apis/organizationApi';

// components
import FormParent from '../FormParent';
import Home from '../Home';
import SideNav from '../SideNav';
import Modal from '../reusable/Modal';
import { isObjectEmpty } from '../../utility/helpers';


class CustomDashboardForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                name: '',
                organization_id: '',
                description: '',
                tableau_site_id: '',
                tableau_workbook_name: '',
                tableau_view_name: '',
                iframe_width: '',
                iframe_height: '',
                status: '',
            },

            // validation fields
            validationFields: ['tableau_workbook_name', 'tableau_view_name', 'name', 'organization_id'],

            tableau_workbook_nameEmpty: false,
            tableau_view_nameEmpty: false,
            organization_idEmpty: false,
            nameEmpty: false,

            editParams: [],
            organizations: [],
        }
        this.parameters = {
            name: '',
            organization_id: '',
            description: '',
            tableau_site_id: '',
            tableau_workbook_name: '',
            tableau_view_name: '',
            iframe_width: '',
            iframe_height: '',
            status: 1,
        }
    }

    componentDidMount() {

        if (store.isEdit && store.currentId) {

            customDashboardApi.getCustomDashboardDetails(store.currentId).then(
                response => {
                    let objData = {};

                    Object.keys(this.state.formFields).map(key => {
                        if (key === 'organization_id' && response.organization_data) {
                            objData[key] = !isObjectEmpty(response.organization_data) ? { 'value': response.organization_data.id, 'label': `${response.organization_data.name} [${response.organization_data.id}]` } : '';
                        } else if (key === 'status') {
                            objData[key] = response.status === 1 ? { value: 1, label: 'Active' } : { value: 0, label: 'In Active' };
                        }
                        else {
                            objData[key] = response[key] !== null ? response[key] : ''
                        }
                    });

                    this.setState({
                        formFields: objData,
                    });
                }
            );
        }


    }

    handleCustomDashboardAdd = () => {
        this.handleAdd(API_PATH.customDashboard);
    };

    getOrganizations = async (onFocus, option = "", callback) => {

        if (option && (option.length < 2 && !onFocus)) {
            return;
        }

        const responseData = await organizationApi.getOrganizations(option);

        const organizations = responseData.map((item) => {
            return {
                value: item.id,
                label: `${item.name} [${item.id}]`,
            }
        })

        this.setState({ organizations });
        if (callback) {
            callback(organizations);
        }
    }

    handleStatusChange = (selectedOption) => {
        const customizedEvent = {
            target: {
                value: selectedOption,
                name: "status"
            }
        }
        this.handleInputChange(customizedEvent)
        this.parameters.status = selectedOption.value
    }

    renderErrors = () => {
        let {
            tableau_workbook_nameEmpty,
            tableau_view_nameEmpty,
            organization_idEmpty,
            nameEmpty,
        } = this.state;

        let errors = [];

        if (nameEmpty) {
            errors.push(<div className='error'>Custom Dashboard Name cannot be empty</div>)
        }
        if (organization_idEmpty) {
            errors.push(<div className='error'>Organization Name cannot be empty</div>)
        }
        if (tableau_view_nameEmpty) {
            errors.push(<div className='error'>Tableau View Name cannot be empty</div>)
        }

        if (tableau_workbook_nameEmpty) {
            errors.push(<div className='error'>Tableau Workbook Name cannot be empty</div>)
        }

        return errors;
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT CUSTOM DASHBOARD</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD CUSTOM DASHBOARD</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Custom Dashboard Name<span className='required'>*</span></label>
                                            <input
                                                className={this.state.nameEmpty ? "form-control input-error" : "form-control"}
                                                type="text"
                                                name='name'
                                                value={this.state.formFields.name}
                                                onChange={this.handleInputChange} />
                                            {this.state.nameEmpty && <div className='error'>Custom Dashboard Name cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Organization Name <span className='required'>*</span></label>
                                            <AsyncSelect
                                                name='organization_id'
                                                placeholder='Please select or type to search'
                                                styles={this.state.organization_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.organization_id}
                                                defaultOptions={this.state.organizations}
                                                isDisabled={false}
                                                loadOptions={(value, callback) => this.getOrganizations(false, value, callback)}
                                                onChange={(option) => this.handleSelectChange(option, 'organization_id')}
                                                onFocus={() => { this.getOrganizations(true) }}
                                            />
                                            {this.state.organization_idEmpty && <div className='error'>Organization Name cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Description</label>
                                            <input
                                                className={"form-control"}
                                                type="text"
                                                name='description'
                                                value={this.state.formFields.description}
                                                onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Tableau Workbook Name<span className='required'>*</span></label>
                                            <input
                                                className={this.state.tableau_workbook_nameEmpty ? "form-control input-error" : "form-control"}
                                                type="text"
                                                name='tableau_workbook_name'
                                                value={this.state.formFields.tableau_workbook_name}
                                                onChange={this.handleInputChange} />
                                            {this.state.tableau_workbook_nameEmpty && <div className='error'>Tableau Workbook Name cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Tableau View Name<span className='required'>*</span></label>
                                            <input
                                                className={this.state.tableau_view_nameEmpty ? "form-control input-error" : "form-control"}
                                                type="text"
                                                name='tableau_view_name'
                                                value={this.state.formFields.tableau_view_name}
                                                onChange={this.handleInputChange} />
                                            {this.state.tableau_view_nameEmpty && <div className='error'>Tableau View Name cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Tableau Site Id</label>
                                            <input
                                                className={"form-control"}
                                                type="text"
                                                name='tableau_site_id'
                                                value={this.state.formFields.tableau_site_id}
                                                onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Iframe Width</label>
                                            <input
                                                className={"form-control"}
                                                type="number"
                                                name='iframe_width'
                                                value={this.state.formFields.iframe_width}
                                                onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Iframe Height</label>
                                            <input
                                                className={"form-control"}
                                                type="number"
                                                name='iframe_height'
                                                value={this.state.formFields.iframe_height}
                                                onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Status</label>
                                            <Select
                                                name='status'
                                                placeholder='Select a Status'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                isDisabled={this.state.secondResource === ''}
                                                value={this.state.formFields.status}
                                                onChange={(option, e) => this.handleStatusChange(option)}

                                                options={ACTIVE_STATUS}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {this.renderErrors()}

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ? <button className="btn-primary bottom-btn" disabled={this.state.editParams.length > 0 || store.apis.length > 0 ? false : true} onClick={() => this.handleUpdate(API_PATH.customDashboard)}>Save</button> :
                                    <button className="btn-primary bottom-btn" onClick={this.handleCustomDashboardAdd}>Add Custom Dashboard</button>}
                                <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                            </div>

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />
                    </div>
                }
            </div>
        )
    }
}

export default observer(CustomDashboardForm)
