import React, { Component } from 'react'
import { observer } from 'mobx-react'
import store from '../store'
import SideNav from './SideNav'

class Welcome extends Component {
    render() {
        return (
            <div className='content'>

                <div className='content-left mobile-hide'>
                    <SideNav />
                </div>

                <div className='mobile-show'>
                    {store.isSideNav ?
                        <div className='content-left'>
                            <SideNav />
                        </div> :
                        <div className='content-left-bar '>
                            <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                        </div>
                    }
                </div>

                <div className='content-right'>
                    <h3 style={{ textAlign: 'center' }}>Welcome to MAGNiTT's admin panel!</h3>
                </div>
            </div>
        )
    }
}

export default observer(Welcome)