import React from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

// helpers
import store from '../../store';

// constants
import { SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY, ACTIVE_STATUS, TAXONOMIES } from '../../constant';
import { API_PATH } from '../../utility/constants/apiPath';

// apis
import emailTemplateApi from '../../apis/emailTemplateApi';
import salesAutomationEmailApi from '../../apis/salesAutomationEmailApi';
import taxonomyApi from '../../apis/taxonomyApi';

// components
import FormParent from '../FormParent';
import Home from '../Home';
import SideNav from '../SideNav';
import Modal from '../reusable/Modal';


class salesAutomationEmailForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                taxonomy_id: '',
                email_template_id: '',
                active: { label: 'Active', value: 1 },
            },

            // validation fields
            validationFields: ['email_template_id', 'taxonomy_id'],

            //error fields
            email_template_idEmpty: false,
            taxonomy_idEmpty: false,

            // edit params
            editParams: [],

            // options
            taxonomies: [],
            emailTemplates: [],

            taxonomyTypes: [
                TAXONOMIES.salesContactOptions ,
                TAXONOMIES.demoContactOptions ,
                TAXONOMIES.demoWidgetType
            ],
        }
        this.parameters = {
            taxonomy_id: '',
            email_template_id: '',
            active: 1,
        }
    }

    componentDidMount() {

        if (store.isEdit && store.currentId) {

            salesAutomationEmailApi.getSalesAutomationEmailDetails(store.currentId).then(
                response => {
                    let objData = {};

                    Object.keys(this.state.formFields).map(key => {
                        if (key === 'taxonomy_id') {
                            objData[key] = response.taxonomy_id !== null ? { 'value': response.taxonomy_id, 'label': `${response.taxonomy_name || ""} [${response.taxonomy_id}]` } : '';
                        } else if (key === 'email_template_id') {
                            objData[key] = response.email_template_id !== null ? { 'value': response.email_template_id, 'label': `${response.email_template_name || ""} [${response.email_template_id}]` } : '';
                        } else if (key === 'active') {
                            objData[key] = response.active === 1 ? { value: 1, label: 'Active' } : { value: 0, label: 'In Active' };
                        }
                        else {
                            objData[key] = response[key] !== null ? response[key] : ''
                        }
                    });

                    this.setState({
                        formFields: objData,
                    });
                }
            );
        }


    }

    handleSalesAutomationEmailAdd = () => {
        this.handleAdd(API_PATH.salesAutomationEmail);
    };

    getOptionsData = async (filterOptionName, onFocus, option = "", callback) => {

        if (option && (option.length < 2 && !onFocus)) {
            return;
        }

        let data = []
        let mappedData = []

        if (filterOptionName === "taxonomies") {
            for (const taxonomyType of this.state.taxonomyTypes) {

                const responseData = await taxonomyApi.getTaxonomyByType(taxonomyType, option)
                data.push(...responseData);
            }

        } else {
            data = await emailTemplateApi.getEmailTemplate(option);
        }

        mappedData = data.map((item) => {
            return {
                value: item.id,
                label: `${item.name} [${item.id}]`,
            }
        })

        this.setState({ [filterOptionName]: mappedData })

        if (callback) {
            callback(mappedData);
        }
    }

    handleStatusChange = (selectedOption) => {
        const customizedEvent = {
            target: {
                value: selectedOption,
                name: "active"
            }
        }
        this.handleInputChange(customizedEvent)
        this.parameters.active = selectedOption.value
    }

    renderErrors = () => {
        let {
            email_template_idEmpty,
            taxonomy_idEmpty,
        } = this.state;

        let errors = [];

        if (taxonomy_idEmpty) {
            errors.push(<div className='error'>Taxonomy cannot be empty</div>)
        }

        if (email_template_idEmpty) {
            errors.push(<div className='error'>Email Template cannot be empty</div>)
        }

        return errors;
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT SALES AUTOMATION EMAIL</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD SALES AUTOMATION EMAIL</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Taxonomy<span className='required'>*</span></label>
                                            <AsyncSelect
                                                name='taxonomy_id'
                                                placeholder='Please select or type to search'
                                                styles={this.state.taxonomy_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.taxonomy_id}
                                                defaultOptions={this.state.taxonomies}
                                                isDisabled={false}
                                                loadOptions={(value, callback) => this.getOptionsData('taxonomies', false, value, callback)}
                                                onChange={(option) => this.handleSelectChange(option, 'taxonomy_id')}
                                                onFocus={() => { this.getOptionsData('taxonomies', true) }}
                                            />
                                            {this.state.taxonomy_idEmpty && <div className='error'>Taxonomy cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Email Template<span className='required'>*</span></label>
                                            <AsyncSelect
                                                name='email_template_id'
                                                placeholder='Please select or type to search'
                                                styles={this.state.email_template_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.email_template_id}
                                                defaultOptions={this.state.emailTemplates}
                                                isDisabled={false}
                                                loadOptions={(value, callback) => this.getOptionsData('emailTemplates', false, value, callback)}
                                                onChange={(option) => this.handleSelectChange(option, 'email_template_id')}
                                                onFocus={() => { this.getOptionsData('emailTemplates', true) }}
                                            />
                                            {this.state.email_template_idEmpty && <div className='error'>Email Template cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Active</label>
                                            <Select
                                                name='active'
                                                placeholder='Select a Status'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.active}
                                                onChange={(option, e) => this.handleStatusChange(option)}

                                                options={ACTIVE_STATUS}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {this.renderErrors()}

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ? <button
                                    className="btn-primary bottom-btn"
                                    disabled={this.state.editParams.length > 0 || store.apis.length > 0 ? false : true}
                                    onClick={() => this.handleUpdate(API_PATH.salesAutomationEmail)}>Save</button> :
                                    <button className="btn-primary bottom-btn" onClick={this.handleSalesAutomationEmailAdd}>Add Automation Email</button>}
                                <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                            </div>

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />
                    </div>
                }
            </div>
        )
    }
}

export default observer(salesAutomationEmailForm)
