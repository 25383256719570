export const DATA_EXPORT_SORT = {
    STARTUP: [
        { 
            label: 'Recently Funded', 
            value: 'last_date_raised^:desc'
        },
        {
            label: 'Total Funding: High to Low',
            value: 'total_fund^:desc' 
        },
        {
            label: 'Total Funding: Low to High',
            value: 'total_fund^:asc' 
        },
    ],
    ECOSYSTEM: [
        { 
            label: 'Latest', 
            value: 'created_at^:desc' 
        },
        { 
            label: '# of Investments: High to Low', 
            value: 'number_of_investments^:desc' 
        },
        { 
            label: '# of Investments: Low to High', 
            value: 'number_of_investments^:asc' 
        }
    ],
    FUNDING: [
        { 
            label: 'Date Funded: New to Old', 
            value: 'date_raised^:desc' 
        },
        { 
            label: 'Date Funded: Old to New', 
            value: 'date_raised^:asc' 
        },
        { 
            label: 'Amount Raised: High to Low', 
            value: 'amount_raised^:desc' 
        },
        { 
            label: 'Amount Raised: Low to High', 
            value: 'amount_raised^:asc' 
        }
    ],
    PEOPLE: [
        {
            label: 'Total Amount Raised',
            value: 'total_amount_raised^:desc'
        },
        {
            label: 'Total Investment',
            value: 'total_investment^:desc'
        },
        {
            label: 'Name: A to Z',
            value: 'full_name^:asc'
        },
        {
            label: 'Name: Z to A',
            value: 'full_name^:desc'
        },
    ]
}
