import axios from 'axios';
import store from '../store';
import { API } from '../constant';
import { prepareFilterParam, prepareSelectParam } from '../utility/queryParams';

const userApi = {

    updateSubscription: (subscriptionId, payload) => {

        const url = `${API}/user-sub/${subscriptionId}`
        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }

        return new Promise((resolve, reject) => {
            axios.patch(url, payload, { headers })
                .then(response => {
                    if (response.data.status === 200) {
                        resolve(response.data.data)
                    } else {
                        reject({})
                    }
                }).catch(err => reject(err))
        })
    },

    getUserSubscription: (userId = '', fields = [], filters = []) => {
        const queryFields = prepareSelectParam(fields);
        const queryFilters = prepareFilterParam(filters);

        const url = `${API}/user/${userId}/subscription?fields=${queryFields}&filters=${queryFilters}&sort=id^:desc&limit=1`;

        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(err => reject(err));
        })
    },

    deactivateUsers: (user_id) => {
        const url = `${API}/user/deactivate`;
        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }

        return new Promise((resolve, reject) => {
            axios.post(url, { user_id }, { headers })
                .then(response => {
                    if (response.data.status === 200) {
                        return  resolve(response.data.data)
                    } else {
                        return   reject({})
                    }
                }).catch(err => reject(err))
        })
    }

}

export default userApi;