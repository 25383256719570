export const API_PRODUCT_DURATION_OPTIONS = [
    { value: 1, label: 'Monthly' },
    { value: 3, label: 'Quarterly' },
    { value: 6, label: 'Biannually' },
    { value: 12, label: 'Annually' }
];

export const API_PRODUCT_PERIOD_OPTIONS = [
    { value: 2, label: 'Monthly' },
    { value: 3, label: 'Annually' }
];

export const API_PRODUCT_RESOURCE_OPTIONS = [
    { value: 'startups', label: 'Startups' },
    { value: 'ecosystems', label: 'Ecosystems' },
    { value: 'funding-rounds', label: 'Funding Rounds' },
    { value: 'general', label: 'General' }
];

export const RESEARCH_DOWNLOAD_FORM_TYPE_OPTIONS = [
    { value: 1, label: 'Long Form' },
    { value: 2, label: 'Short Form' }
];

export const VALUATION_CALCULATION_STATUS_OPTIONS = [
    { value: 0, label: 'Pending' },
    { value: 1, label: 'Running' },
    { value: 2, label: 'Completed' },
    { value: 3, label: 'Failed' }
];

export const UNDISCLOSED_PROXY_LOGS_STATUS_OPTIONS = [
    { value: 0, label: 'Pending' },
    { value: 1, label: 'Running' },
    { value: 2, label: 'Completed' },
    { value: 3, label: 'Failed' }
];