import React from 'react'
import { observer } from 'mobx-react'
import Select from 'react-select'

import store from '../../store';
import { SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY, OPTIONS, API } from '../../constant'
import { prepareSelectParam } from '../../utility/queryParams'
import { getLocations } from '../../utility/commonApis'
import { isObjectEmpty } from '../../utility/helpers'

import SideNav from '../SideNav'
import Modal from '../reusable/Modal'
import FormParent from '../FormParent'
import Home from '../Home'
import SubscriptionRoles from './subscriptionRoles/SubscriptionRoles';

// apis
import subscriptionApi from '../../apis/subscriptionApi';
import ErrorPopup from '../reusable/ErrorModal';

class SubscriptionsForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                name: '',
                type: '',
                duration: '',
                price: '',
                currency: '',
                country_id: [{ 'value': 5847, 'label': 'United Arab Emirates (UAE)' }],
                active: ''
            },
            postCreation: {
                roles: []
            },
            locations: [],
            types: [],
            currencies: [{ 'value': 'USD', 'label': 'USD' }],
            editParams: [],
            validationFields: ['name', 'type', 'duration', 'price', 'currency', 'country_id', 'active'],
            error: false
        }
        this.parameters = {
            name: '',
            type: '',
            duration: '',
            price: '',
            currency: '',
            country_id: 5847,
            active: ''
        }
    }

    async componentDidMount() {
        store.rolesData = [];
        store.subscriptionRoles = [];

        if (store.isEdit && store.currentId) {
            let paramFields = ['id', 'name', 'type', 'type_data', 'duration', 'price', 'currency', 'country_id', 'country_data', 'active']
            let fields = prepareSelectParam(paramFields)

            fetch(`${API}/subscription/${store.currentId}/?fields=${fields}`, {
                method: 'GET',
                headers: {
                    'Authorization': store.token,
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    let obj = {}
                    Object.keys(this.state.formFields).map(key => {
                        if (key === 'type') {
                            obj[key] = !isObjectEmpty(data.data.type_data) ? { 'value': data.data.type_data.id, 'label': data.data.type_data.name } : ''
                        }
                        else if (key === 'country_id') {
                            obj[key] = !isObjectEmpty(data.data.country_data) ? { 'value': data.data.country_id, 'label': data.data.country_data.name } : ''
                        }
                        else if (key === 'active') {
                            obj[key] = data.data.active == 0 ? { value: 0, label: 'No' } : data.data.active == 1 ? { value: 1, label: 'Yes' } : ''
                        }
                        else if (key === 'currency') {
                            obj[key] = data.data.currency !== null ? { 'value': data.data.currency, 'label': data.data.currency } : ''
                        }
                        else {
                            obj[key] = data.data[key] !== null ? data.data[key] : ''
                        }
                    })
                    this.setState({ formFields: obj })
                })
                .catch(err => {
                    console.log(err)
                })
        }

        getLocations().then(res => {
            this.setState({ locations: res })
        });

        subscriptionApi.getSubscriptionTypes().then(
            response => {
                if (response && response.types) {
                    const types = response.types.map(type => {
                        return { value: type.id, label: type.name };
                    });
                    
                    this.setState({types});
                }
            }
        );
    }

    handleAddSubscription = () => {
        const tempObj = { ...this.state.postCreation };

        tempObj.roles = store.subscriptionRoles;

        this.setState({
            postCreation: tempObj
        }, () => this.handleAdd('subscription'));
    }

    handleUpdateSubscription = () => {
        if (store.subscriptionRoles.length > 0) {
            const subscriptionId = store.currentId;

            let payload = [];
            store.subscriptionRoles.map(role => {
                payload.push({ subscription_id: subscriptionId, role_id: role });
            });

            store.apis.push({ path: 'subscription-type-role', method: 'POST', data: payload });
        }

        this.handleUpdate('subscription');
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT SUBSCRIPTIONS</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD SUBSCRIPTIONS</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Name <span className='required'>*</span></label>
                                            <input type="text"
                                                className={this.state.nameEmpty ? "form-control input-error" : "form-control"}
                                                value={this.state.formFields.name}
                                                name='name'
                                                onChange={this.handleInputChange} />
                                            {this.state.nameEmpty && <div className='error'>Name cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Type <span className='required'>*</span></label>
                                            <Select
                                                name='type'
                                                placeholder='Please Select'
                                                styles={this.state.typeEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.type}
                                                onChange={(option) => this.handleSelectChange(option, 'type')}
                                                options={this.state.types}
                                            />
                                            {this.state.typeEmpty && <div className='error'>Type cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Duration <span className='required'>*</span></label>
                                            <input type="text"
                                                className={this.state.durationEmpty ? "form-control input-error" : "form-control"}
                                                value={this.state.formFields.duration}
                                                name='duration'
                                                onChange={this.handleInputChange} />
                                            {this.state.durationEmpty && <div className='error'>Duration cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Price <span className='required'>*</span></label>
                                            <input type="text"
                                                className={this.state.priceEmpty ? "form-control input-error" : "form-control"}
                                                value={this.state.formFields.price}
                                                name='price'
                                                onChange={this.handleInputChange} />
                                            {this.state.priceEmpty && <div className='error'>Price cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Select Currency <span className='required'>*</span></label>
                                            <Select
                                                name='currency'
                                                placeholder='Please Select'
                                                styles={this.state.currencyEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.currency}
                                                onChange={(option) => this.handleSelectChange(option, 'currency')}
                                                options={this.state.currencies}
                                            />
                                            {this.state.currencyEmpty && <div className='error'>Currency cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Country <span className='required'>*</span></label>
                                            <Select
                                                name='country_id'
                                                placeholder='Please Select'
                                                styles={this.state.hq_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.country_id}
                                                onChange={(option) => this.handleSelectChange(option, 'country_id')}
                                                options={this.state.locations}
                                            />
                                            {this.state.country_idEmpty && <div className='error'>Country cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Active<span className='required'>*</span></label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={this.state.activeEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.active}
                                                onChange={(option) => this.handleSelectChange(option, 'active')}
                                                options={OPTIONS}
                                            />
                                            {this.state.activeEmpty && <div className='error'>Active cannot be empty</div>}
                                        </div>
                                    </div>

                                    <SubscriptionRoles />

                                </div>
                            </div>

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ? <button className="btn-primary bottom-btn" disabled={this.state.editParams.length > 0 || store.apis.length > 0 || store.subscriptionRoles.length > 0 ? false : true} onClick={this.handleUpdateSubscription}>Save</button> :
                                    <div>
                                        <button className="btn-primary bottom-btn" onClick={this.handleAddSubscription}>Add Subscription</button>
                                        <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                                    </div>
                                }
                            </div>

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <ErrorPopup errorMessage={this.state.errorMessage}/>
                        <Modal />
                        
                    </div>
                }
            </div>
        )
    }
}
export default observer(SubscriptionsForm)
