import React from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

// helpers
import store from '../../store';
import { prepareFilterParam, prepareSelectParam } from '../../utility/queryParams'


// constants
import { SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY, OPTIONS, ICE_ENTITY_TYPES, ALLOWED_QUERY_OPERATOR } from '../../constant';
import { API_PATH } from '../../utility/constants/apiPath';

// apis
import { getIndustries, getLocations, getMultiGeoFocus, getMultiIndustry } from '../../utility/commonApis';
import fundsApi from '../../apis/fundsApi';

// components
import FormParent from '../FormParent';
import Home from '../Home';
import SideNav from '../SideNav';
import Modal from '../reusable/Modal';


class FundsForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                entity_type: '',
                entity_id: '',
                name: '',
                status: '',
            },
            industryValue: [],
            industries: [],
            industry: [],

            'geo-focusValue': [],
            locations: [],
            'geo-focus': [],

            companies: [],

            editParams: [],
            validationFields: ['entity_type', 'entity_id', 'name'],

            multiFields: {
                industry: [],
                'geo-focus': [],
            },
        }
        this.parameters = {
            entity_type: '',
            entity_id: '',
            name: '',
            status: 1
        }
    }

    componentDidMount() {

        if (store.isEdit && store.currentId) {
            const paramFields = ['name', 'entity_id', 'entity_type', 'geo_focus_data', 'industry_data', 'status'];
            const fields = prepareSelectParam(paramFields);

            fundsApi.getFundsDetails(store.currentId, fields).then(
                response => {
                    let objData = {};

                    Object.keys(this.state.formFields).map(key => {
                        if (key === 'entity_type') {
                            objData[key] = response.entity_type ? { 'value': response.entity_type, 'label': response.entity_type } : '';
                        }
                        else if (key === 'entity_id' && response.name) {
                            objData[key] = response.entity_id && response.name ? { 'value': response.entity_id, 'label': `${response.name} [${response.entity_id}]` } : '';
                            objData['name'] = response.entity_id && response.name ? { 'value': response.name, 'label': `${response.name} [${response.entity_id}]` } : '';
                        } else if (key === 'status') {
                            objData[key] = response.status === 1 ? { value: 1, label: 'Yes' } : { value: 0, label: 'No' };
                        }
                        else {
                            objData[key] = response[key] !== null ? response[key] : ''
                        }
                    });

                    // get industries for current entity
                    getMultiIndustry(`fund/${store.currentId}`).then(res => {
                        if (res.length > 0) {
                            this.setState({ industry: res })
                        }
                    })

                    // get geo-focus  for current entity
                    getMultiGeoFocus(`fund/${store.currentId}`).then(res => {
                        if (res.length > 0) {
                            this.setState({ 'geo-focus': res })
                        }
                    })

                    this.setState({
                        formFields: objData,
                    });
                }
            );
        }

        // get locations
        getLocations().then(res => {
            this.setState({ locations: res })
        })

        // get  Industries
        const filters = prepareFilterParam([
            { key: 'is_deleted', value: [0], op: ALLOWED_QUERY_OPERATOR.equal },
            { key: 'parent_id', value: [0], op: ALLOWED_QUERY_OPERATOR.equal },
        ]);
        getIndustries(filters).then(res => {
            this.setState({ industries: res })
        })

    }

    handleFundingAdd = () => {
        this.handleAdd(API_PATH.fund);
    };

    renderErrors = () => {
        let {
            entity_typeEmpty,
            entity_idEmpty,
            nameEmpty,
        } = this.state;

        let errors = [];

        if (nameEmpty) {
            errors.push(<div className='error'>Fund name cannot be empty</div>)
        }

        if (entity_typeEmpty) {
            errors.push(<div className='error'>Fund manager type cannot be empty</div>)
        }

        if (entity_idEmpty) {
            errors.push(<div className='error'>Fund manager name cannot be empty</div>)
        }

        return errors;
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>Edit Fund</h3> :
                                <h3 style={{ textAlign: 'center' }}>Add Fund</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Fund Name <span className='required'>*</span></label>
                                            <input
                                                className={this.state.nameEmpty ? "form-control input-error" : "form-control"}
                                                type="text"
                                                name="name"
                                                value={this.state.formFields.name}
                                                onChange={this.handleInputChange} />
                                            {this.state.nameEmpty && <div className='error'>Fund name cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Fund Manager Entity Type <span className='required'>*</span></label>
                                            <Select
                                                name='entity_type'
                                                placeholder='Please select a type'
                                                styles={this.state.entity_typeEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.entity_type}
                                                onChange={(option) => this.handleSelectEntityType(option, 'entity_type')}
                                                options={ICE_ENTITY_TYPES}
                                            />
                                            {this.state.entity_typeEmpty && <div className='error'>Type cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Fund Manager Name <span className='required'>*</span></label>
                                            <AsyncSelect
                                                name='entity_id'
                                                placeholder='Please select or type to search'
                                                styles={this.state.entity_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                isDisabled={!this.state.formFields.entity_type}
                                                value={this.state.formFields.entity_id}
                                                defaultOptions={this.state.companies}
                                                loadOptions={(value, callback) => this.handleLoadOptions(value, callback, this.state.formFields.entity_type.value)}
                                                onChange={(option) => this.handleSelectChange(option, 'entity_id')}
                                                onFocus={() => { this.handleLoadCompanies(this.state.formFields.entity_type.value) }}
                                            />
                                            {this.state.entity_idEmpty && <div className='error'>Company name cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-12 form-group">
                                            <label>Fund Industry Focus</label>
                                            <Select
                                                placeholder='Select industry'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.industryValue}
                                                onChange={option => this.handleMultiSelectChange(option, 'industry_id', 'industry')}
                                                options={this.state.industries}
                                            />
                                            {this.state.industry.length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state.industry.map((item, i) => {
                                                        return <li key={i}>{item.name} <i className='fa fa-times-circle' onClick={() => this.handleMultiRemove(i, item.id, 'industry')}></i></li>
                                                    })}
                                                </div>
                                            }
                                        </div>

                                        <div className="col-md-12 col-12 form-group">
                                            <label>Fund Geo Focus</label>
                                            <Select
                                                placeholder='Select geo-focus'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state['geo-focusValue']}
                                                onChange={option => this.handleMultiSelectChange(option, 'location_id', 'geo-focus')}
                                                options={this.state.locations}
                                            />
                                            {this.state['geo-focus'].length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state['geo-focus'].map((item, i) => {
                                                        return <li key={i}>{item.name} <i className='fa fa-times-circle' onClick={() => this.handleMultiRemove(i, item.id, 'geo-focus')}></i></li>
                                                    })}
                                                </div>
                                            }
                                        </div>

                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Status</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.status}
                                                onChange={(option) => this.handleSelectChange(option, 'status')}
                                                options={OPTIONS}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {this.renderErrors()}

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ? <button className="btn-primary bottom-btn" disabled={this.state.editParams.length > 0 || store.apis.length > 0 ? false : true} onClick={() => this.handleUpdate(API_PATH.fund)}>Save</button> :
                                    <button className="btn-primary bottom-btn" onClick={this.handleFundingAdd}>Add Fund</button>}
                                <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                            </div>

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />
                    </div>
                }
            </div>
        )
    }
}

export default observer(FundsForm)
