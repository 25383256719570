import { AgGridReact } from 'ag-grid-react';
import { observer } from 'mobx-react';
import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import store from '../../store'
import { ALLOWED_QUERY_OPERATOR, PEOPLE_COLUMN_HEADERS, SELECT_STYLES, SELECT_THEME_PRIMARY, PEOPLE_ENTITY_TYPES } from '../../constant';
import { prepareSelectParam, prepareFilterParam } from '../../utility/queryParams';

import Home from '../Home';
import Parent from '../Parent';
import SideNav from '../SideNav';

import ActionRenderer from '../reusable/ActionRenderer';
import Modal from '../reusable/Modal';
import Pagination from '../reusable/Pagination';
import Sort from '../reusable/Sort';

import { getLocations, getUsers, getEntityNamesSuggestions } from '../../utility/commonApis';

class People extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: PEOPLE_COLUMN_HEADERS,
            context: { componentParent: this },
            frameworkComponents: { actionRenderer: ActionRenderer },

            email: '',
            first_name: '',
            last_name: '',
            country_id: '',
            countries: [],
            user_id: '',
            users: [],
            current_entity_type: '',
            current_entity_name: '',
            current_entity_names: [],
            showemail: false,
            showfirst_name: false,
            showlast_name: false,
            filters: {
                email: [],
                first_name: [],
                last_name: [],
                country_id: [],
                user_id: [],
                current_entity_type: [],
                current_entity_name: []
            },
            filterFields: [
                { 'key': 'email', 'op': ALLOWED_QUERY_OPERATOR.substring, 'value': [] },
                { 'key': 'first_name', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'last_name', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'country_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'user_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'current_entity_type', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'current_entity_name', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] }
            ]
        }
        store.sortType = this.props.location.pathname.split('/')[1]
    }

    onGridReady(params) {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    componentDidMount() {
        document.addEventListener('mousedown', (e) => {
            if (this.email) { if (!this.email.contains(e.target)) this.setState({ showemail: false }) }
        })
        let paramFields = ['id', 'user_id', 'first_name', 'last_name', 'email', 'current_job_title', 'current_entity_name', 'current_entity_type', 'gender', 'nationality_id', 'nationality_data', 'country_id', 'country_data']
        store.fields = prepareSelectParam(paramFields)

        let paramFilters = [{ key: 'is_deleted', value: [0], op: ALLOWED_QUERY_OPERATOR.equal }];
        store.currentFilters = prepareFilterParam(paramFilters);

        //TODO: perform token validator
        this.fetchListData('people', store.fields);

        getLocations().then(response => {
            this.setState({
                countries: response
            });
        });

        this.getUsersList();
        this.getEntityNames('m');
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => {
            if (this.email) { if (!this.email.contains(e.target)) this.setState({ showemail: false }) }
        })
    }

    getUsersList = async (option = '') => {
        const users = await getUsers(option)

        this.setState({ users })

        return users
    }

    getEntityNames = async (option = '') => {
        const current_entity_names = await getEntityNamesSuggestions(option)

        this.setState({ current_entity_names })

        return current_entity_names
    }

    clearFilters = () => {
        this.setState({
            country_id: '',
            user_id: '',
            current_entity_type: '',
            current_entity_name: ''
        }, () => {
            this.handleClearAllFilters('people');
        });
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>PEOPLE</h3>

                            <div className='filter-container'>
                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className="col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing" >
                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }} >
                                            <div ref={node => this.input = node} className="filter-firstline">
                                                <div style={{ width: '100%' }}>
                                                    <input className='filter-input input-border'
                                                        placeholder="Search by first name"
                                                        value={this.state.first_name}
                                                        onFocus={() => this.setState({ showfirst_name: true, showlast_name: false, showemail: false })}
                                                        onKeyUp={(e) => this.handleInputEnter(e, 'first_name')}
                                                        onChange={e => this.setState({ first_name: e.target.value })} />
                                                    {this.state.showfirst_name && this.state.filters.first_name.length > 0 ?
                                                        <div className='filter-dropdown' style={{ width: '100%' }}>
                                                            <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                                {this.state.filters.first_name.map((word, i) => {
                                                                    return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'first_name')}>
                                                                        {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                                    </div>
                                                                })}
                                                            </div>
                                                        </div> : null
                                                    }
                                                </div>
                                                {this.state.email.length > 0 &&
                                                    <i className="fa fa-times "
                                                        style={{ paddingRight: 10 }}
                                                        onClick={() => {
                                                            this.input.focus()
                                                            this.setState({ first_name: '' })
                                                        }}>
                                                    </i>
                                                }
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ padding: 0 }}>
                                            <div ref={node => this.input = node} className="filter-firstline">
                                                <div style={{ width: '100%' }}>
                                                    <input className='filter-input input-border'
                                                        placeholder="Search by last name"
                                                        value={this.state.last_name}
                                                        onFocus={() => this.setState({ showfirst_name: false, showlast_name: true, showemail: false })}
                                                        onKeyUp={(e) => this.handleInputEnter(e, 'last_name')}
                                                        onChange={e => this.setState({ last_name: e.target.value })} />
                                                    {this.state.showlast_name && this.state.filters.last_name.length > 0 ?
                                                        <div className='filter-dropdown' style={{ width: '100%' }}>
                                                            <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                                {this.state.filters.last_name.map((word, i) => {
                                                                    return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'last_name')}>
                                                                        {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                                    </div>
                                                                })}
                                                            </div>
                                                        </div> : null
                                                    }
                                                </div>
                                                {this.state.email.length > 0 &&
                                                    <i className="fa fa-times "
                                                        style={{ paddingRight: 10 }}
                                                        onClick={() => {
                                                            this.input.focus()
                                                            this.setState({ last_name: '' })
                                                        }}>
                                                    </i>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing'>
                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                            <div ref={node => this.input = node} className="filter-firstline">
                                                <div style={{ width: '100%' }}>
                                                    <input className='filter-input input-border'
                                                        placeholder="Search by email"
                                                        value={this.state.email}
                                                        onFocus={() => this.setState({ showfirst_name: false, showlast_name: false, showemail: true })}
                                                        onKeyUp={(e) => this.handleInputEnter(e, 'email')}
                                                        onChange={e => this.setState({ email: e.target.value })} />
                                                    {this.state.showemail && this.state.filters.email.length > 0 ?
                                                        <div className='filter-dropdown' style={{ width: '100%' }}>
                                                            <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                                {this.state.filters.email.map((word, i) => {
                                                                    return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'email')}>
                                                                        {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                                    </div>
                                                                })}
                                                            </div>
                                                        </div> : null
                                                    }
                                                </div>
                                                {this.state.email.length > 0 &&
                                                    <i className="fa fa-times "
                                                        style={{ paddingRight: 10 }}
                                                        onClick={() => {
                                                            this.input.focus()
                                                            this.setState({ email: '' })
                                                        }}>
                                                    </i>
                                                }
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                            <div style={{ width: '100%' }}>
                                                <Select
                                                    placeholder='Filter by country'
                                                    styles={SELECT_STYLES}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: SELECT_THEME_PRIMARY
                                                        }
                                                    })}
                                                    value={this.state.country_id}
                                                    onFocus={() => this.setState({ showfirst_name: false, showlast_name: false, showemail: false })}
                                                    onChange={(option) => this.handleSingleSelectFilter(option, 'country_id')}
                                                    options={this.state.countries}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing'>
                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                            <div style={{ width: '100%' }}>
                                                <Select
                                                    placeholder='Filter by entity type'
                                                    styles={SELECT_STYLES}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: SELECT_THEME_PRIMARY
                                                        }
                                                    })}
                                                    value={this.state.current_entity_type}
                                                    onFocus={() => this.setState({ showfirst_name: false, showlast_name: false, showemail: false })}
                                                    onChange={(option) => this.handleSingleSelectFilter(option, 'current_entity_type')}
                                                    options={PEOPLE_ENTITY_TYPES}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                            <AsyncSelect
                                                placeholder='Search by entity name'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.current_entity_name}
                                                onFocus={() => this.setState({ showfirst_name: false, showlast_name: false, showemail: false })}
                                                defaultOptions={this.state.current_entity_names}
                                                loadOptions={(option) => this.getEntityNames(option)}
                                                onChange={(option) => this.handleSingleSelectFilter(option, 'current_entity_name')}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline'  >
                                        <div className="col-md-6 col-sm-6 col-xs-12 filter-spacing" style={{ paddingRight: 15 }} >
                                            <AsyncSelect
                                                placeholder='Search by user'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.user_id}
                                                onFocus={() => this.setState({ showfirst_name: false, showlast_name: false, showemail: false })}
                                                defaultOptions={this.state.users}
                                                loadOptions={(option) => this.getUsersList(option)}
                                                onChange={(option) => this.handleSingleSelectFilter(option, 'user_id')}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2'
                                            className="col-md-2 col-sm-2 col-xs-12 filter-searchbtn"
                                            onClick={() => {
                                                this.clearFilters()
                                            }}>Clear All</a>
                                    </div>
                                </div>
                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    frameworkComponents={this.state.frameworkComponents}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                >
                                </AgGridReact>
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}


export default observer(People)
