import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import store from '../../store'
import { API, EXPORT_REQUEST_STATUS_VALUES, DEMO_REQUEST_STATUS_VALUES, PRODUCT_API } from '../../constant'
import Parent from '../Parent'
import { isObjectEmpty } from '../../utility/helpers'

// apis
import Api from '../../utility/api'
import investorApi from '../../apis/investorApi'
import demoRequestApi from '../../apis/demoRequestApi'
import exportRequestApi from '../../apis/exportRequestApi'
import ecosystemApi from '../../apis/ecosystemApi';
import startupApi from '../../apis/startupApi'
import { API_PATH } from '../../utility/constants/apiPath'

class Modal extends Parent {
    constructor() {
        super();
        this.state = {
            announcement: '',
            validationError: ''
        };
    }

    handleDelete = () => {
        $('#dailogModal').modal('hide')
        let path
        let URL = API
        if (store.type === API_PATH.apiClient) {
            path = `${store.type}/${store.currentId}`;
            URL = PRODUCT_API;
        }
        else if (store.type === 'order') {
            if (store.route === 'order/user-sub') {
                path = `order/${store.orderId}/user-subscription/${store.currentId}`
            } else {
                return this.handleOrderDelete();
            }
        }
        else if (store.type === 'taxonomy-type' && !store.isTaxonomyList) {
            path = `${store.type}/${store.taxonomyId}/taxonomy/${store.currentId}`
        }
        else {
            path = `${store.type}/${store.currentId}`
        }

        fetch(`${URL}/${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.status === 200) {
                    store.showDelete = false
                    store.isDeleted = true
                    this.prepareResult()
                    $('#dailogModal').modal({
                        backdrop: 'static',
                        keyboard: true,
                        show: true
                    })
                }
                else {
                    $('#errorModal').modal({
                        backdrop: 'static',
                        keyboard: true,
                        show: true
                    })
                }
            })
    }

    handleOrderDelete = async () => {
        try {
            if (store.route === 'order/offline') {
                const orderPath = `order/${store.currentId}`;
                await Api.request(orderPath, 'DELETE');
            } else if (store.route === 'order/online') {
                const orderPath = `order/${store.currentId}/cancel-user-subscription`;
                await Api.request(orderPath, 'POST');
            }

            store.showDelete = false;
            store.isDeleted = true;
            this.fetchListData('order');

            $('#dailogModal').modal({
                backdrop: 'static',
                keyboard: true,
                show: true
            })
        } catch (error) {
            $('#errorModal').modal({
                backdrop: 'static',
                keyboard: true,
                show: true
            })
        }
    }

    handleDeactivate = () => {
        let path = '';
        
        if (store.type === 'user') {
            path = `${store.type}/${store.currentId}/status-change`
        } else if (store.type === 'resource') {
            if (store.resourceStatus) {
                path = `${store.type}/${store.currentId}/deactivate`
            } else {
                path = `${store.type}/${store.currentId}/activate`
            }
        }
        else {
            path = `${store.type}/${store.currentId}/activate`
        }

        fetch(`${API}/${path}`, {
            method: 'PATCH',
            headers: {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            },
            body: store.type === 'user' ? JSON.stringify({ status: store.deactivate }) : ''
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.status === 200) {
                    store.showDeactivate = false
                    $('#dailogModal').modal('hide')
                    this.prepareResult()
                }
                else {
                    $('#errorModal').modal({
                        backdrop: 'static',
                        keyboard: true,
                        show: true
                    })
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    handlePublish = () => {
        let obj = {}
        obj.is_published = store.publish === 0 || store.publish === 2 ? 1 : 2
        fetch(`${API}/${store.type}/${store.currentId}`, {
            method: 'PATCH',
            headers: {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(obj)
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.status === 200) {
                    store.showPublish = false
                    $('#dailogModal').modal('hide')
                    this.prepareResult()
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    handleClose = (ev) => {
        if (store.isAdd || store.isEdit) {
            this.props.history.push(`/${store.route}`)
        }

        store.showAcceptFundingRequest = false;
        store.showRejectFundingRequest = false;

        this.setState({
            validationError: '',
            announcement: ''
        })
    }

    handleFundingRoundRequest = () => {
        const startupId = store.startup_id;
        const fundingBreakdownId = store.funding_breakdown_id;
        const InvFundingId = store.investor_funding_id;

        if (store.showAcceptFundingRequest) {
            startupApi.acceptJoinFundRequest(startupId, fundingBreakdownId, InvFundingId).then(
                response => {
                    store.showAcceptFundingRequest = false;
                    store.showRejectFundingRequest = false;
                    this.handleClose();
                    $('#dailogModal').modal('hide');
                    this.props.getInitialData();
                }
            )
        } else if (store.showRejectFundingRequest) {
            startupApi.rejectJoinFundRequest(startupId, fundingBreakdownId, InvFundingId).then(
                response => {
                    store.showAcceptFundingRequest = false;
                    store.showRejectFundingRequest = false;
                    this.handleClose();
                    $('#dailogModal').modal('hide');
                    this.props.getInitialData();
                }
            )
        } else {
            $('#dailogModal').modal('hide');
            $('#errorModal').modal({
                backdrop: 'static',
                keyboard: true,
                show: true
            })
        }
    
    }

    handleExportRequestUpdate = () => {
        let requestId = store.export_request_id;
        let status;

        if (store.showExportContacted) {
            status = EXPORT_REQUEST_STATUS_VALUES.contacted;
        } else if (store.showExportConverted) {
            status = EXPORT_REQUEST_STATUS_VALUES.converted;
        } else {
            return;
        }

        let payload = {status}

        exportRequestApi.patchRequestData(requestId, payload)
            .then(response => {
                if (!isObjectEmpty(response)) {
                    store.showExportContacted = false
                    store.showExportConverted = false
                    this.handleClose()
                    $('#dailogModal').modal('hide')
                    this.props.getInitialData()
                }
            })
    }

    handleVerifyICE = () => {
        const data = store.verifyData;
        const entityId = data.id;
        const entityType = data.entity_type.toLowerCase();
        
        let verifiedStatus;
        if (data.verify_status == 1) {
            verifiedStatus = 0;
        } else {
            verifiedStatus = 1;
        }

        const payload = { is_verified: verifiedStatus };

        ecosystemApi.verifyEntity(entityId, entityType, payload)
            .then(response => {
                if (!isObjectEmpty(response)) {
                    this.handleClose()
                    $('#dailogModal').modal('hide')
                    this.props.getInitialData()
                }
            })
    }

    handleInputChange = (ev) => {
        this.setState({ [ev.target.name]: ev.target.value });
    }

    renderAnnouncementForm = () => {
        return (
            <div>
                <div className="form-group">
                    <label style={{ textAlign: "left", display: "block" }}>Announcement URL<span className="required">*</span></label>
                    <input type="text" name="announcement" className="form-control" value={this.state.announcement} onChange={this.handleInputChange} />
                </div>
            </div>
        );
    }

    handleDemoRequestUpdate = () => {
        let demoRequestId = store.demo_request_id;
        let status;

        if (store.showDemoContacted) {
            status = DEMO_REQUEST_STATUS_VALUES.contacted;
        } else if (store.showDemoConverted) {
            status = DEMO_REQUEST_STATUS_VALUES.converted;
        } else {
            return;
        }

        let payload = {status}

        demoRequestApi.patchDemoRequestData(demoRequestId, payload)
            .then(response => {
                if (!isObjectEmpty(response)) {
                    store.showDemoContacted = false
                    store.showDemoConverted = false
                    this.handleClose()
                    $('#dailogModal').modal('hide')
                    this.props.getInitialData()
                }
            })
    }

    render() {
        let apiClientDetails = '';
        if (store.apiClientID || store.apiClientSecret) {
            apiClientDetails = (
                <div className="d-flex flex-column">
                    <span><b>API Client ID</b>: {store.apiClientID}</span>
                    <span><b>API Client Secret</b>: {store.apiClientSecret}</span>
                </div>
            );
        }

        return (
            <div className="modal fade" id="dailogModal">
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            {store.showPublish ? <h4 className="modal-title">{store.publish === 0 || store.publish === 2 ? 'PUBLISH' : store.publish === 1 ? 'UNPUBLISH' : ''}</h4> :
                                store.showDeactivate && store.deactivate ? <h4 className="modal-title">{store.deactivate === 2 ? 'DEACTIVATE' : 'ACTIVATE'}</h4> :
                                    store.showDeactivate && store.subscriptionStatus != null ? <h4 className="modal-title">{store.subscriptionStatus === 0 ? 'ACTIVATE' : 'DEACTIVATE'}</h4> :
                                        store.showDelete ? <h4 className="modal-title"> DELETE</h4> : 
                                            store.showAcceptFundingRequest ? <h4 className="modal-title"> Accept Funding Round Request</h4> :
                                                store.showRejectFundingRequest ? <h4 className="modal-title"> Reject Funding Round Request</h4> :
                                                    store.showDemoContacted ? <h4 className="modal-title"> Demo Request</h4> :
                                                        store.showDemoConverted ? <h4 className="modal-title"> Demo Request</h4> :
                                                            store.showExportContacted ? <h4 className="modal-title"> Export Request</h4> :
                                                                store.showExportConverted ? <h4 className="modal-title"> Export Request</h4> :
                                                                    store.showVerifyICE ? <h4 className="modal-title">ICE Verification</h4> :
                                                                        store.showDeactivate && store.resourceStatus != null ? <h4 className="modal-title">{store.resourceStatus ? 'DEACTIVATE' : 'ACTIVATE'}</h4>
                                                                            : <h4 className="modal-title">SUCCESS</h4>}
                            <button type="button" className="close" data-dismiss="modal" onClick={this.handleClose}>&times;</button>
                        </div>

                        <div className="modal-body">
                            {this.state.validationError && <div className="alert alert-danger">{this.state.validationError}</div>}
                            <div style={{ textAlign: 'center' }}>
                                {store.showDelete && `Are you sure you want to delete ${store.currentDeleteName ? store.currentDeleteName : 'this record'}?`}
                                {store.showDeactivate && store.deactivate && `Are you sure you want to ${store.deactivate === 2 ? 'deactivate' : 'activate'}  ${store.currentDeleteName}?`}
                                {store.showDeactivate && store.subscriptionStatus != null && `Are you sure you want to ${store.subscriptionStatus === 0 ? 'activate' : 'deactivate'}  ${store.currentDeleteName}?`}
                                {store.showPublish && `Are you sure you want to ${store.publish === 1 ? 'unpublish' : 'publish'} ${store.currentDeleteName}?`}
                                {store.showAcceptFundingRequest && `Are you sure you want to add ${store.requestor} to ${store.startup_name}'s ${store.stage} funding round?`}
                                {store.showRejectFundingRequest && `Are you sure you want to ignore ${store.requestor}'s request to join ${store.startup_name}'s ${store.stage} funding round?`}
                                {store.isAdd && `${store.currentAddName ? store.currentAddName : "Record"} successfully added!`}
                                {(store.isAdd && (store.type === API_PATH.apiClient)) && <>{apiClientDetails}</>}
                                {store.isEdit && `${store.currentEditName ? store.currentEditName : "Record"} successfully updated!`}
                                {store.isDeleted && `${store.currentDeleteName ? store.currentDeleteName : "Record"} successfully deleted!`}
                                {store.showDemoContacted && `Has the demo request user been contacted?`}
                                {store.showDemoConverted && `Has the demo request user been converted?`}
                                {store.showExportContacted && `Has the export request user been contacted?`}
                                {store.showExportConverted && `Has the export request user been converted?`}
                                {store.showVerifyICE && `Are you sure you want to ${store.verifyData.verify_status == 0 ? 'verify' : 'un-verify'} ${store.verifyData.name}?`}
                                {store.showDeactivate && store.resourceStatus != null && `Are you sure you want to ${store.resourceStatus ? 'deactivate' : 'activate'}  ${store.currentDeleteName}?`}
                                {store.type === 'deactivate-users' && (store.numDeactivatedUsers && store.numDeactivatedUsers === 1 ? 'User has successfully been deactivated' : `${store.numDeactivatedUsers} users have successfully been deactivated`)}
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleClose}>Close</button>

                            {
                                store.showDelete ? <button type="button" className="btn btn-danger" onClick={this.handleDelete}>{store.route === 'order/online' ? "Cancel" : "Delete"}</button> :
                                    store.showDeactivate && store.deactivate ? <button type="button" className="btn btn-danger" onClick={this.handleDeactivate}>{store.deactivate === 2 ? 'DEACTIVATE' : 'ACTIVATE'}</button> :
                                        store.showDeactivate && store.subscriptionStatus != null ? <button type="button" className="btn btn-danger" onClick={this.handleDeactivate}>{store.subscriptionStatus === 0 ? 'ACTIVATE' : 'DEACTIVATE'}</button> :
                                            store.showPublish ? <button type="button" className="btn btn-danger" onClick={this.handlePublish}>{store.publish === 0 || store.publish === 2 ? 'PUBLISH' : 'UNPUBLISH'}</button> :
                                                store.showAcceptFundingRequest ? <button type="button" className="btn btn-danger" onClick={this.handleFundingRoundRequest}>Accept</button> :
                                                    store.showRejectFundingRequest ? <button type="button" className="btn btn-danger" onClick={this.handleFundingRoundRequest}>Ignore</button> : 
                                                        store.showDemoContacted ? <button type="button" className="btn btn-danger" onClick={this.handleDemoRequestUpdate}>Confirm</button> :
                                                            store.showDemoConverted ? <button type="button" className="btn btn-danger" onClick={this.handleDemoRequestUpdate}>Confirm</button> :
                                                                store.showExportContacted ? <button type="button" className="btn btn-danger" onClick={this.handleExportRequestUpdate}>Confirm</button> :
                                                                    store.showExportConverted ? <button type="button" className="btn btn-danger" onClick={this.handleExportRequestUpdate}>Confirm</button> :
                                                                        store.showVerifyICE ? <button type="button" className="btn btn-danger" onClick={this.handleVerifyICE}>{store.verifyData.verify_status == 0 ? 'VERIFY' : 'UN-VERIFY'}</button> :
                                                                            store.showDeactivate && store.resourceStatus != null ? <button type="button" className="btn btn-danger" onClick={this.handleDeactivate}>{store.resourceStatus ? 'DEACTIVATE' : 'ACTIVATE'}</button> : ''
                            }
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(observer(Modal))
