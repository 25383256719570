import React from 'react';
import { observer } from 'mobx-react';
import AsyncSelect from 'react-select/async';

// constants
import { API, SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY } from '../../../constant';

// helpers
import store from '../../../store';

// apis
import { getUsers } from '../../../utility/commonApis';

// components
import FormParent from '../../FormParent';

class OrganizationUserModal extends FormParent {
    constructor() {
        super()
        this.state = {
            users: [],
            user: '',
            isUserEmpty: false
        }

        this.parameters = {
            user_id: '',
            organization_id: ''
        }

    }

    async componentDidMount() {
        store.organizationUsers = [];
        store.organizationUsersData = [];

        const users = await getUsers();
        this.setState({ users });
    }

    handleAddUser = () => {
        const { user } = this.state;

        if (user === '') {
            this.setState({
                isUserEmpty: true
            });
        } else {
            store.organizationUsers.push(this.parameters);
            store.organizationUsersData.push({
                id: 'NEW',
                userId: this.state.user.value,
                name: this.state.user.label
            });

            this.props.gridApi.setRowData(store.organizationUsersData);
            $('#addOrganizationUserModal').modal('hide');

            this.setState({
                user: ''
            });
        }
    }

    handleSelectChange = (option) => {
        this.parameters.user_id = option.value; 

        this.setState({ 
            user: option,
            isUserEmpty: false
        });
    }

    render() {

        return (
            <div className="modal fade" id="addOrganizationUserModal" >
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">Add User</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div className="modal-body">

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label>User <span className='required'>*</span></label>
                                    <AsyncSelect
                                        placeholder='Select user'
                                        styles={this.state.isUserEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        cacheOptions
                                        value={this.state.user}
                                        defaultOptions={this.state.users}
                                        loadOptions={(value, callback) => this.handleLoadOptions(value, callback, 'user')}
                                        onChange={this.handleSelectChange}
                                    />
                                    {this.state.isUserEmpty && <div className='error'>User cannot be empty</div>}
                                </div>
                            </div>



                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={this.handleAddUser}>Add</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default observer(OrganizationUserModal);