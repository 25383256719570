import Home from './components/Home'
import Articles from './components/news/Articles'
import ArticleForm from './components/news/ArticleForm'
import Startups from './components/startup/Startups'
import StartupForm from './components/startup/StartupForm'
import Investors from './components/investor/Investors'
import InvestorForm from './components/investor/InvestorForm'
import Corporates from './components/corporate/Corporates'
import CorporateForm from './components/corporate/CorporateForm'
import Enablers from './components/enabler/Enablers'
import EnablerForm from './components/enabler/EnablerForm'
import Users from './components/user/Users'
import UserForm from './components/user/UserForm'
import Jobs from './components/job/Jobs'
import JobForm from './components/job/JobForm'
import Researches from './components/research/Researches'
import ResearchForm from './components/research/ResearchForm'
import FundingRounds from './components/funding/fundingRounds/FundingRounds'
import FundingVerification from './components/funding/fundingVerification/FundingVerification'
import FundingRoundRequests from './components/funding/FundingRoundRequests'
import FundingForm from './components/funding/fundingRounds/FundingForm'
import Advertisements from './components/advertisement/Advertisements'
import AdvertisementForm from './components/advertisement/AdvertisementForm'
import Taxonomy from './components/taxonomy/Taxonomy'
import Location from './components/taxonomy/Location'
import Industry from './components/taxonomy/Industry'
import ResearchTags from './components/taxonomy/ResearchTags'
import TeamRoles from './components/taxonomy/TeamRoles'
import Roles from './components/role/Roles'
import RoleForm from './components/role/RoleForm'
import Resources from './components/resource/Resources'
import ResourceForm from './components/resource/ResourceForm'
import Promotions from './components/promotion/Promotions'
import PromotionForm from './components/promotion/PromotionForm'
import PermissionForm from './components/permission/PermissionForm'
import Permissions from './components/permission/Permissions'
import UserRoles from './components/userroles/UserRoles'
import UserRolesForm from './components/userroles/UserRolesForm'
import Subscriptions from './components/subscription/Subscriptions'
import SubscriptionsForm from './components/subscription/SubscriptionsForm'
import SubscriptionTypes from './components/subscriptionTypes/SubscriptionTypes'
import SubscriptionTypesForm from './components/subscriptionTypes/SubsccriptionTypeForm'
import UserSubscriptions from './components/usersubscription/UserSubscriptions'
import UserSubscriptionForm from './components/usersubscription/UserSubscriptionForm'
import SubscriptionList from './components/usersubscription/SubscriptionList'
import SubTaxonomy from './components/taxonomy/SubTaxonomy'
import SubIndustries from './components/taxonomy/SubIndustries'
import EmailTemplateForm from './components/emailtemplate/EmailTemplateForm'
import EmailTemplateList from './components/emailtemplate/EmailTemplates'
import RDPolicyForm from './components/RDPolicy/RDPolicyForm'
import RDPolicyList from './components/RDPolicy/RDPolicies'
import ExportRequests from './components/exportRequest/ExportRequests'
import DemoRequests from './components/demoRequest/DemoRequests'
import EcosystemVerification from './components/ecosystemVerification/EcosystemVerification'
import UndisclosedProxies from './components/undisclosedProxy/UndisclosedProxies';
import UndisclosedProxyForm from './components/undisclosedProxy/UndisclosedProxyForm'
import UndisclosedProxyLog from './components/undisclosedProxyLog/UndisclosedProxyLog';
import LocationForm from './components/taxonomy/location/LocationForm'
import DataExportPolicies from './components/dataExportPolicy/DataExportPolicies';
import DataExportPolicyForm from './components/dataExportPolicy/DataExportPolicyForm';
import DataExportLogs from './components/dataExportLog/DataExportLogs';
import DataExportLogForm from './components/dataExportLog/DataExportLogForm'
import DataExportProducts from './components/dataExportProducts/DataExportProducts'
import DataExportProductForm from './components/dataExportProducts/DataExportProductForm'
import NewsUTMLinks from './components/newsUTMLink/NewsUTMLinks';
import NewsUTMLinksForm from './components/newsUTMLink/NewsUTMLinksForm';
import MagnittDepartments from './components/magnittDepartment/MagnittDepartments';
import MagnittDepartmentForm from './components/magnittDepartment/MagnittDepartmentForm';
import Organizations from './components/organization/Organizations';
import OrganizationForm from './components/organization/OrganizationForm';
import OrganizationRoles from './components/organizationRoles/OrganizationRoles';
import OrganizationRoleForm from './components/organizationRoles/OrganizationRoleForm';
import ApiProducts from './components/apiProduct/ApiProducts';
import ApiProductForm from './components/apiProduct/ApiProductForm';
import ApiClients from './components/apiClients/ApiClients';
import ApiClientForm from './components/apiClients/ApiClientForm';
import DeactivateUsers from './components/deactivateUsers/DeactivateUsers';
import SubscriptionAddons from './components/subscriptionAddons/SubscriptionAddons';
import SubscriptionAddonForm from './components/subscriptionAddons/SubscriptionAddonForm';
import ValuationCalculation from './components/valuationCalculation/ValuationCalculation'
import ValuationCalculationForm from './components/valuationCalculation/ValuationCalculationForm'
import Funds from './components/funds/Funds';
import FundsForm from './components/funds/FundsForm';
import Technology from './components/technology/Technology';
import TechnologyForm from './components/technology/TechnologyForm';
import People from './components/people/People';
import PeopleForm from './components/people/PeopleForm';
import ValuationCalculationLog from './components/valuationCalculationLog/ValuationCalculationLog';
import CustomDashboard from './components/customDashboard/CustomDashboard'
import CustomDashboardForm from './components/customDashboard/CustomDashboardForm'
import SalesAutomationEmail from './components/salesAutomationEmail/SalesAutomationEmail';
import SalesAutomationEmailForm from './components/salesAutomationEmail/SalesAutomationEmailForm';
import ClientDirectory from './components/clientDirectory/ClientDirectory';
import ClientDirectoryForm from './components/clientDirectory/ClientDirectoryForm';

const routes = [
    {
        path: '/',
        exact: true,
        component: Home,
    },
    {
        path: '/startup/add',
        component: StartupForm,
    },
    {
        path: '/startup/edit',
        component: StartupForm,
    },
    {
        path: '/startup',
        component: Startups,
    },
    {
        path: '/investor/add',
        component: InvestorForm,
    },
    {
        path: '/investor/edit',
        component: InvestorForm,
    },
    {
        path: '/investor',
        component: Investors,
    },
    {
        path: '/corporate/add',
        component: CorporateForm,
    },
    {
        path: '/corporate/edit',
        component: CorporateForm,
    },
    {
        path: '/corporate',
        component: Corporates,
    },
    {
        path: '/enabler/add',
        component: EnablerForm,
    },
    {
        path: '/enabler/edit',
        component: EnablerForm,
    },
    {
        path: '/enabler',
        component: Enablers,
    },
    {
        path: '/user/add',
        component: UserForm,
    },
    {
        path: '/user/edit',
        component: UserForm,
    },
    {
        path: '/user',
        component: Users,
    },
    {
        path: '/job/add',
        component: JobForm,
    },
    {
        path: '/job/edit',
        component: JobForm,
    },
    {
        path: '/job',
        component: Jobs,
    },
    {
        path: '/research/add',
        component: ResearchForm,
    },
    {
        path: '/research/edit',
        component: ResearchForm,
    },
    {
        path: '/research',
        component: Researches,
    },
    {
        path: '/news/add',
        component: ArticleForm,
    },
    {
        path: '/news/edit',
        component: ArticleForm,
    },
    {
        path: '/news',
        component: Articles,
    },
    {
        path: '/funding/add',
        component: FundingForm,
    },
    {
        path: '/funding/edit',
        component: FundingForm,
    },
    {
        path: '/funding',
        component: FundingRounds,
    },
    {
        path: '/funding-verification',
        component: FundingVerification,
    },
    {
        path: '/funding-round-requests',
        component: FundingRoundRequests,
    },
    {
        path: '/banner/add',
        component: AdvertisementForm,
    },
    {
        path: '/banner/edit',
        component: AdvertisementForm,
    },
    {
        path: '/banner',
        component: Advertisements,
    },
    {
        path: '/user-role',
        exact: true,
        component: UserRoles,
    },
    {
        path: '/user-role/add',
        component: UserRolesForm,
    },
    {
        path: '/subscription-types',
        exact: true,
        component: SubscriptionTypes,
    },
    {
        path: '/subscription-types/add',
        component: SubscriptionTypesForm,
    },
    {
        path: '/subscription-types/edit',
        component: SubscriptionTypesForm,
    },
    {
        path: '/subscription',
        exact: true,
        component: Subscriptions,
    },
    {
        path: '/subscription/add',
        component: SubscriptionsForm,
    },
    {
        path: '/subscription/edit',
        component: SubscriptionsForm,
    },
    {
        path: '/order/offline',
        exact: true,
        component: UserSubscriptions,
    },
    {
        path: '/order/offline/add',
        component: UserSubscriptionForm,
    },
    {
        path: '/order/offline/edit',
        component: UserSubscriptionForm,
    },
    {
        path: '/order/online',
        exact: true,
        component: UserSubscriptions,
    },
    {
        path: '/order/user-sub',
        component: SubscriptionList,
    },
    {
        path: '/taxonomy/taxonomy-type',
        exact: true,
        component: Taxonomy,
    },
    {
        path: '/taxonomy/taxonomy-type/sub-taxonomy',
        component: SubTaxonomy,
    },
    {
        path: '/taxonomy/industry/sub-industries',
        component: SubIndustries,
    },
    {
        path: '/taxonomy/location/add',
        component: LocationForm,
    },
    {
        path: '/taxonomy/location/edit',
        component: LocationForm,
    },
    {
        path: '/taxonomy/location',
        component: Location,
    },
    {
        path: '/taxonomy/industry',
        component: Industry,
    },
    {
        path: '/taxonomy/research-taxonomy',
        component: ResearchTags,
    },
    {
        path: '/taxonomy/team-role',
        component: TeamRoles,
    },
    {
        path: '/role/add',
        component: RoleForm,
    },
    {
        path: '/role/edit',
        component: RoleForm,
    },
    {
        path: '/role',
        component: Roles,
    },
    {
        path: '/resource/add',
        component: ResourceForm,
    },
    {
        path: '/resource/edit',
        component: ResourceForm,
    },
    {
        path: '/resource',
        component: Resources,
    },
    {
        path: '/promotion/add',
        component: PromotionForm,
    },
    {
        path: '/promotion/edit',
        component: PromotionForm,
    },
    {
        path: '/promotion',
        component: Promotions,
    },
    {
        path: '/permission/add',
        component: PermissionForm,
    },
    {
        path: '/permission/edit',
        component: PermissionForm,
    },
    {
        path: '/permission',
        component: Permissions,
    },
    {
        path: '/email-template/add',
        exact: true,
        component: EmailTemplateForm,
    },
    {
        path: '/email-template',
        exact: true,
        component: EmailTemplateList,
    },
    {
        path: '/email-template/edit',
        component: EmailTemplateForm,
    },
    {
        path: '/rd-policy',
        exact: true,
        component: RDPolicyList,
    },
    {
        path: '/rd-policy/add',
        exact: true,
        component: RDPolicyForm,
    },
    {
        path: '/rd-policy/edit',
        component: RDPolicyForm,
    },
    {
        path: '/export-request',
        exact: true,
        component: ExportRequests,
    },
    {
        path: '/demo-request',
        exact: true,
        component: DemoRequests,
    },
    {
        path: '/ecosystem',
        exact: true,
        component: EcosystemVerification,
    },
    {
        path: '/undisclosed-proxy',
        exact: true,
        component: UndisclosedProxies,
    },
    {
        path: '/undisclosed-proxy/add',
        exact: true,
        component: UndisclosedProxyForm,
    },
    {
        path: '/undisclosed-proxy/edit',
        exact: true,
        component: UndisclosedProxyForm,
    },
    {
        path: '/undisclosed-proxy-log',
        exact: true,
        component: UndisclosedProxyLog,
    },
    {
        path: '/data-export-policy',
        exact: true,
        component: DataExportPolicies,
    },
    {
        path: '/data-export-policy/add',
        exact: true,
        component: DataExportPolicyForm,
    },
    {
        path: '/data-export-policy/edit',
        exact: true,
        component: DataExportPolicyForm,
    },
    {
        path: '/data-export-log',
        exact: true,
        component: DataExportLogs,
    },
    {
        path: '/data-export-log/add',
        exact: true,
        component: DataExportLogForm,
    },
    {
        path: '/data-export-product',
        exact: true,
        component: DataExportProducts,
    },
    {
        path: '/data-export-product/add',
        exact: true,
        component: DataExportProductForm,
    },
    {
        path: '/data-export-product/edit',
        exact: true,
        component: DataExportProductForm,
    },
    {
        path: '/news-utm',
        exact: true,
        component: NewsUTMLinks,
    },
    {
        path: '/news-utm/add',
        exact: true,
        component: NewsUTMLinksForm,
    },
    {
        path: '/news-utm/edit',
        exact: true,
        component: NewsUTMLinksForm,
    },
    {
        path: '/magnitt-department',
        exact: true,
        component: MagnittDepartments,
    },
    {
        path: '/magnitt-department/add',
        exact: true,
        component: MagnittDepartmentForm,
    },
    {
        path: '/magnitt-department/edit',
        exact: true,
        component: MagnittDepartmentForm,
    },
    {
        path: '/organization',
        exact: true,
        component: Organizations,
    },
    {
        path: '/organization/add',
        exact: true,
        component: OrganizationForm,
    },
    {
        path: '/organization/edit',
        exact: true,
        component: OrganizationForm,
    },
    {
        path: '/organization-role',
        exact: true,
        component: OrganizationRoles,
    },
    {
        path: '/organization-role/add',
        exact: true,
        component: OrganizationRoleForm,
    },
    {
        path: '/organization-role/edit',
        exact: true,
        component: OrganizationRoleForm,
    },
    {
        path: '/api-product',
        exact: true,
        component: ApiProducts,
    },
    {
        path: '/api-product/add',
        exact: true,
        component: ApiProductForm,
    },
    {
        path: '/api-product/edit',
        exact: true,
        component: ApiProductForm,
    },
    {
        path: '/api-clients',
        exact: true,
        component: ApiClients,
    },
    {
        path: '/api-clients/add',
        exact: true,
        component: ApiClientForm,
    },
    {
        path: '/api-clients/edit',
        exact: true,
        component: ApiClientForm,
    },
    {
        path: '/deactivate-users',
        exact: true,
        component: DeactivateUsers,
    },
    {
        path: '/subscription-addon',
        exact: true,
        component: SubscriptionAddons,
    },
    {
        path: '/subscription-addon/add',
        exact: true,
        component: SubscriptionAddonForm,
    },
    {
        path: '/subscription-addon/edit',
        exact: true,
        component: SubscriptionAddonForm,
    },
    {
        path: '/valuation-calculation/edit',
        exact: true,
        component: ValuationCalculationForm,
    },
    {
        path: '/valuation-calculation/add',
        exact: true,
        component: ValuationCalculationForm,
    },
    {
        path: '/valuation-calculation',
        exact: true,
        component: ValuationCalculation,
    },
    {
        path: '/fund/edit',
        exact: true,
        component: FundsForm,
    },
    {
        path: '/fund/add',
        exact: true,
        component: FundsForm,
    },
    {
        path: '/fund',
        exact: true,
        component: Funds,
    },
    {
        path: '/technology/edit',
        exact: true,
        component: TechnologyForm,
    },
    {
        path: '/technology/add',
        exact: true,
        component: TechnologyForm,
    },
    {
        path: '/technology',
        exact: true,
        component: Technology,
    },
    {
        path: '/people/add',
        component: PeopleForm,
    },
    {
        path: '/people/edit',
        component: PeopleForm,
    },
    {
        path: '/people',
        component: People,
    },
    {
        path: '/valuation-calculation-log',
        exact: true,
        component: ValuationCalculationLog,
    },
    {
        path: '/custom-dashboard/edit',
        exact: true,
        component: CustomDashboardForm,
    },
    {
        path: '/custom-dashboard/add',
        exact: true,
        component: CustomDashboardForm,
    },
    {
        path: '/custom-dashboard',
        exact: true,
        component: CustomDashboard,
    },
    {
        path: '/sales-automation-email/add',
        exact: true,
        component: SalesAutomationEmailForm,
    },
    {
        path: '/sales-automation-email/edit',
        exact: true,
        component: SalesAutomationEmailForm
    },
    {
        path: '/sales-automation-email',
        exact: true,
        component: SalesAutomationEmail
    },
    {
        path: '/client-directory/add',
        exact: true,
        component: ClientDirectoryForm,
    },
    {
        path: '/client-directory/edit',
        exact: true,
        component: ClientDirectoryForm
    },
    {
        path: '/client-directory',
        exact: true,
        component: ClientDirectory
    },
]

export default routes
