import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react';
import Select from 'react-select';

import store from '../../store'
import { SELECT_STYLES, SELECT_THEME_PRIMARY, RESOURCE_COLUMN_HEADERS, ALLOWED_QUERY_OPERATOR, OPTIONS } from '../../constant'
import { prepareSelectParam } from '../../utility/queryParams'
import SideNav from '../SideNav'
import ActionRenderer from '../reusable/ActionRenderer'
import Parent from '../Parent'
import Modal from '../reusable/Modal'
import Sort from '../reusable/Sort'
import Pagination from '../reusable/Pagination'
import Home from '../Home'

class Resources extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: RESOURCE_COLUMN_HEADERS,
            context: { componentParent: this },
            frameworkComponents: { actionRenderer: ActionRenderer },
            defaultColDef: { suppressMovable: true },
            fields: '',
            keyword: '',
            data: [],
            showKeywords: false,
            filters: {
                keyword: [],
                authenticate: [],
                authorize: [],
                active: [],
            },
            filterFields: [
                { 'key': 'keyword', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'authenticate', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'authorize', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'active', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
            ]
        }
        store.sortType = this.props.location.pathname.split('/')[1]
    }

    onGridReady = (params) => {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    componentDidMount() {
        document.addEventListener('mousedown', (e) => {
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showKeywords: false }) }
        });

        const paramFields = ['id', 'name', 'method', 'operation', 'authenticate', 'authorize', 'active'];
        store.fields = prepareSelectParam(paramFields);

        //TODO: perform token validator
        this.fetchListData('resource', store.fields);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', e => {
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showKeywords: false }) }
        });
    }

    clearFilters = () => {
        this.setState({
            keyword: '',
            authenticate: '',
            authorize: '',
            active: ''
        })

        this.handleClearAllFilters('resource')
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>RESOURCES</h3>
                            <div className='filter-container'>

                                <div className='filter-row'>
                                    <div ref={node => this.word = node} className="col-md-12 col-sm-12 col-xs-12 filter-firstline filter-spacing" >
                                        <div style={{ width: '100%' }}>
                                            <input ref={node => this.input = node}
                                                className='filter-input input-border'
                                                placeholder="Type and Enter to Search"
                                                value={this.state.keyword}
                                                onFocus={() => this.setState({ showKeywords: true })}
                                                onKeyUp={this.handleKeywordEnter}
                                                onChange={e => this.setState({ keyword: e.target.value })} />
                                            {this.state.showKeywords && this.state.filters.keyword.length > 0 ?
                                                <div className='filter-dropdown' style={{ width: '100%' }}>
                                                    <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                        {this.state.filters.keyword.map((word, i) => {
                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'keyword')}>
                                                                {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div> : null}
                                        </div>
                                        {this.state.keyword.length > 0 &&
                                            <i className="fa fa-times "
                                                style={{ paddingRight: 10 }}
                                                onClick={() => {
                                                    this.input.focus()
                                                    this.setState({ keyword: '' })
                                                    //this.filterValues(this.state.tempFilter)
                                                }}>
                                            </i>
                                        }
                                    </div>
                                </div>

                                <div className="filter-row" style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                        <Select
                                            placeholder='Filter by authenticate flag'
                                            styles={SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            value={this.state.authenticate}
                                            onChange={(option) => this.handleSingleSelectFilter(option, 'authenticate')}
                                            options={OPTIONS}
                                        />
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ padding: 0 }}>
                                        <Select
                                            placeholder='Filter by authorize flag'
                                            styles={SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            value={this.state.authorize}
                                            onChange={(option) => this.handleSingleSelectFilter(option, 'authorize')}
                                            options={OPTIONS}
                                        />
                                    </div>
                                </div>

                                <div className="filter-row" style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                        <div style={{ width: '100%' }}>
                                            <Select
                                                placeholder='Filter by active flag'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.active}
                                                onChange={(option) => this.handleSingleSelectFilter(option, 'active')}
                                                options={OPTIONS}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-6 filter-searchbtn" onClick={this.clearFilters}>Clear All</a>
                                    </div>
                                </div>

                            </div>

                            <Sort />

                            <div id='grid' className='ag-theme-balham' style={{ width: '100%', color: '#5d656d' }}>
                                <AgGridReact
                                    reactNext={true}
                                    defaultColDef={this.state.defaultColDef}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    frameworkComponents={this.state.frameworkComponents}
                                    onGridReady={this.onGridReady}
                                    enableCellTextSelection={true}
                                >
                                </AgGridReact>
                            </div>

                            <Pagination />

                        </div >

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />
                    </div>
                }
            </div>
        )
    }
}


export default observer(Resources)
