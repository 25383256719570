import axios from 'axios';
import store from '../store';
import { ALLOWED_QUERY_OPERATOR, API } from '../constant';
import { prepareFilterParam, prepareSelectParam } from '../utility/queryParams';

const resourceApi = {

    getResourceDetails: (resourceId) => {
        const paramFields = ['id', 'name', 'code', 'method', 'operation', 'path', 'authenticate', 'authorize', 'service_name', 'active'];
        const fields = prepareSelectParam(paramFields);
        const url = `${API}/resource/${resourceId}?fields=${fields}`;

        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(response => {
                    if (response.data.status === 200) {
                        resolve({
                            resource: response.data.data.resources,
                            attributes: response.data.data.attributes
                        })
                    } else {
                        reject({})
                    }
                }).catch(err => reject(err))
        })
    },

    getResourceIdByCode: (resourceCode = '', resourceName = '') => {
        const fields = prepareSelectParam(['id']);

        const filters = prepareFilterParam([
            { key: 'name', value: [resourceName], op: ALLOWED_QUERY_OPERATOR.equal },
            { key: 'code', value: [resourceCode], op: ALLOWED_QUERY_OPERATOR.equal },
            { key: 'active', value: [1], op: ALLOWED_QUERY_OPERATOR.equal }
        ]);

        const url = `${API}/resource?fields=${fields}&filters=${filters}`;

        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(response => {
                    if (response.data.status === 200) {
                        resolve({
                            resource: response.data.data.resources,
                            attributes: response.data.data.attributes
                        })
                    } else {
                        reject({})
                    }
                }).catch(err => reject(err))
        })
    }

}

export default resourceApi;