import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react';
import Select from 'react-select';

// constants
import { SELECT_STYLES, SELECT_THEME_PRIMARY, ALLOWED_QUERY_OPERATOR, TAXONOMIES } from '../../constant';
import { NEWS_UTM_LINKS_COLUMN_HEADERS } from '../../utility/constants/tableHeaders';
import { API_PATH } from '../../utility/constants/apiPath';

// helpers
import store from '../../store';
import { prepareSelectParam } from '../../utility/queryParams';

// apis
import taxonomyApi from '../../apis/taxonomyApi';

// components
import Parent from '../Parent';
import Home from '../Home';
import SideNav from '../SideNav';
import ActionRenderer from '../reusable/ActionRenderer';
import Modal from '../reusable/Modal';
import Sort from '../reusable/Sort';
import Pagination from '../reusable/Pagination';

class NewsUTMLinks extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            // config
            columnDefs: NEWS_UTM_LINKS_COLUMN_HEADERS,
            context: { componentParent: this },
            frameworkComponents: { actionRenderer: ActionRenderer },
            defaultColDef: { suppressMovable: true },

            // data
            data: [],

            // filters
            category: '',
            categories: [],
            filters: {
                category: []
            },
            filterFields: [
                { 'name': 'category', 'key': 'category_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] }
            ]
        }

        store.sortType = this.props.location.pathname.split('/')[1];
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
    }

    componentDidMount() {
        const paramFields = ['id', 'description', 'utm_link', 'category_data'];
        store.fields = prepareSelectParam(paramFields);

        this.fetchListData(API_PATH.newsUTM, store.fields);

        taxonomyApi.getTaxonomyByType(TAXONOMIES.newsCategory).then(
            response => {
                if (response.length > 0) {
                    let arrTemp = [];
                    response.map(category => arrTemp.push({ value: category.id, label: category.name }));

                    this.setState({
                        categories: arrTemp
                    });
                }
            }).catch(error => {
            // handle error
        });
    }

    clearFilters = () => {
        this.setState({
            category: ''
        }, () => {
            this.handleClearAllFilters(API_PATH.newsUTM);
        });
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>NEWS UTM LINKS</h3>
                            <div className='filter-container'>

                                <div className="filter-row" style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className="col-md-12 col-sm-12 col-xs-12" style={{ paddingLeft: 0 }}>
                                        <Select
                                            placeholder='Filter by category'
                                            styles={SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            value={this.state.category}
                                            onChange={(option) => this.handleSingleSelectFilter(option, 'category')}
                                            options={this.state.categories}
                                        />
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-6 filter-searchbtn" onClick={this.clearFilters}>Clear All</a>
                                    </div>
                                </div>

                            </div>

                            <Sort />

                            <div id='grid' className='ag-theme-balham' style={{ width: '100%', color: '#5d656d' }}>
                                <AgGridReact
                                    reactNext={true}
                                    defaultColDef={this.state.defaultColDef}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    frameworkComponents={this.state.frameworkComponents}
                                    onGridReady={this.onGridReady}
                                    enableCellTextSelection={true}
                                >
                                </AgGridReact>
                            </div>

                            <Pagination />

                        </div >

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />
                    </div>
                }
            </div>
        )
    }
}

export default observer(NewsUTMLinks);
