import React, { Component } from 'react';
import { observer } from 'mobx-react';

import store from '../../store';
import { isObjectEmpty } from '../../utility/helpers';
import { ALLOWED_QUERY_OPERATOR, FIELD_SEPARATOR, VALUE_SEPARATOR } from '../../constant';

class RequestLogModal extends Component {

    handleCloseModal = () => {
        $('#requestLogModal').modal('hide');
    }

    /**
     * Method to decode the request filters/sort
     * @param {String} filters 
     * @param {Object[]} attributes 
     */
    decodeFilters = (filters = '', attributes = []) => {
        if (!filters || filters == '-') {
            return '-';
        }

        const decodedFilters = decodeURIComponent(filters);

        let arrTemp = decodedFilters.split(FIELD_SEPARATOR);

        //Remove empty or undefined entries from arrTemp
        arrTemp = arrTemp.filter(function (element) {
            return !!element;
        });

        const arrFilters = arrTemp.map(item => {
            
            const equalSymbol       = ALLOWED_QUERY_OPERATOR.equal;
            const notEqualSymbol    = ALLOWED_QUERY_OPERATOR.notEq;
            const substringSymbol   = ALLOWED_QUERY_OPERATOR.substring;
            const gtSymbol          = ALLOWED_QUERY_OPERATOR.gt;
            const gteSymbol         = ALLOWED_QUERY_OPERATOR.gte;
            const ltSymbol          = ALLOWED_QUERY_OPERATOR.lt;
            const lteSymbol         = ALLOWED_QUERY_OPERATOR.lte;
            const btwSymbol         = ALLOWED_QUERY_OPERATOR.btw;

            let value       = ''; 
            let obj         = ''; 
            let key         = ''; 
            let gtSplit     = ''; 
            let gtEqSplit   = ''; 
            let ltSplit     = '';
            let ltEqSplit   = '';
            let btwSplit    = '';
            let operator    = 'equal to';
            
            if (item.includes(equalSymbol)) {
                obj = item.split(equalSymbol);
            } else if (item.includes(notEqualSymbol)) {
                obj         = item.split(notEqualSymbol);
                operator    = 'not equal to';
            } else if (item.includes(substringSymbol)) {
                obj         = item.split(substringSymbol);
                operator    = 'substring of';
            } else {
                obj = [item];
            }
            
            key = (obj && obj.length > 0) ? obj[0] : 'default';
    
            if (key.includes(gtSymbol)) {
                gtSplit = key.split(gtSymbol);
    
            } else if (key.includes(gteSymbol)) {
                gtEqSplit = key.split(gteSymbol);
    
            } else if (key.includes(ltSymbol)) {
                ltSplit = key.split(ltSymbol);
    
            } else if (key.includes(lteSymbol)) {
                ltEqSplit = key.split(lteSymbol);

            } else if (key.includes(btwSymbol)) {
                btwSplit = key.split(btwSymbol);
            }
    
            if (gtSplit.length > 1) {
                key         = gtSplit[0];
                value       = gtSplit[1];
                operator    = 'greater than';
            } else if (gtEqSplit.length > 1) {
                key         = gtEqSplit[0];
                value       = gtEqSplit[1];
                operator    = 'greater than or equal';
            } else if (ltSplit.length > 1) {
                key         = ltSplit[0];
                value       = ltSplit[1];
                operator    = 'less than';
            } else if (ltEqSplit.length > 1) {
                key         = ltEqSplit[0];
                value       = ltEqSplit[1];
                operator    = 'less than or equal';
            } else if (btwSplit.length > 1) {
                key         = btwSplit[0];
                value       = btwSplit[1].split(VALUE_SEPARATOR).join(' & ');
                operator    = 'between';
            } else {
                value = obj[1].split(VALUE_SEPARATOR).join(', ');
            }

            const objField = attributes.find(field => field.key == key);
            const label = objField && objField.label ? objField.label : key;
    
            return <>{label} <strong>{operator}</strong> {value}</>;
        })

        return arrFilters;
    }

    /**
     * Method to get the fields labels
     */
    prepareFields = (strFields = '', attributes = []) => {
        let arrFields = strFields.split(', ');

        let arrLabels = [];

        //Remove empty or undefined entries from arrFields
        arrFields = arrFields.filter(function (element) {
            return !!element;
        });

        for (let index = 0; index < arrFields.length; index++) {
            const strKeyField = arrFields[index];
            
            const objField = attributes.find(({key}) => key === strKeyField);

            const label = objField && objField.label ? objField.label : strKeyField;

            arrLabels.push(<li>- {label}</li>);
        }

        return arrLabels;
    }

    /**
     * Method to render the request details
     */
    renderData = () => {
        let content = <div style={{textAlign: 'center'}}>No details to show</div>;

        const data = store.requestLogModalData;
        const attributes = store.attributes;
        
        if (data && !isObjectEmpty(data) && attributes && !isObjectEmpty(attributes)) {

            const fields    = this.prepareFields(data.details.fields, attributes);
            const filters   = this.decodeFilters(data.details.filters, attributes);
            const sort      = this.decodeFilters(data.details.sort, attributes);

            let arrFilters = '';
            if (filters == '-') {
                arrFilters = '-';
            } else {
                arrFilters = filters.map(filter => <li>- {filter}</li>);
            }
            
            content =   <table className="table table-striped">
                <tbody>
                    <tr>
                        <th scope="col">Fields</th>
                        <td>
                            <ul>
                                {fields}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th scope="col">Filters</th>
                        <td>
                            <ul>
                                {arrFilters}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th scope="col">Sort</th>
                        <td>{sort}</td>
                    </tr>
                </tbody>
            </table>;
        }

        return content;
    }

    render() {
        return (
            <div className="modal fade" id="requestLogModal">
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">Request Details</h4>
                            <button type="button" className="close" onClick={this.handleCloseModal}>&times;</button>
                        </div>

                        <div className="modal-body">
                            {this.renderData()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default observer(RequestLogModal);
