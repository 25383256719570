const subscriptions = {
    development: {
        directory_addon: 4,
        api_product_addon: 7
    },
    production: {
        directory_addon: 4, // TODO: CS update value
        api_product_addon: 7 // TODO: CS update value
    }
}

export const SUBSCRIPTIONS_ADDONS = subscriptions[process.env.NODE_ENV];