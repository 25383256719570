import React from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select'

// Utils
import store from '../../store';
import { API, SELECT_STYLES, SELECT_THEME_PRIMARY } from '../../constant';
import Parent from '../Parent';

class TaxonomyModal extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            parentIndustries: [],
            taxonomySuccess: false,
            subTaxonomySuccess: false,
            industrySuccess: false,
            locationSuccess: false,
            researchTagSuccess: false,
            teamRoleSuccess: false,
            editParams: [],
            validationFields: []
        }
        this.parameters = ''
    }

    componentDidMount() {
        this.prepareTaxonomyDetails();
    }

    prepareTaxonomyDetails = () => {
        switch (store.type) {
        case 'taxonomy-type':
            if (store.isTaxonomyList) {
                this.state.validationFields = ['name']

                store.formFieldsTaxonomy = {
                    name: '',
                    description: '',
                }

                this.parameters = {
                    name: '',
                    description: '',
                }
            }
            else {
                this.state.validationFields = ['name', 'value']

                store.formFieldsTaxonomy = {
                    name: '',
                    value: '',
                    description: '',
                }

                this.parameters = {
                    name: '',
                    value: '',
                    description: '',
                }
            }
            break;
        case 'location':
            this.state.validationFields = ['name', 'iso_code']

            store.formFieldsTaxonomy = {
                name: '',
                iso_code: '',
                region: '',
                country: ''
            }

            this.parameters = {
                name: '',
                iso_code: '',
                region: '',
                country: ''
            }
            break;
        case 'industry':
            this.state.validationFields = ['name', 'description']

            store.formFieldsTaxonomy = {
                name: '',
                description: '',
                parent_id: 0,
                depth_level: ''
            }

            this.parameters = {
                name: '',
                description: '',
                parent_id: 0,
                depth_level: ''
            }
            break;
        case 'research-taxonomy':
            this.state.validationFields = ['name', 'value']

            store.formFieldsTaxonomy = {
                name: '',
                value: '',
                description: '',
            }

            this.parameters = {
                name: '',
                value: '',
                description: '',
            }
            break;

        case 'team-role':
            this.state.validationFields = ['name', 'value']

            store.formFieldsTaxonomy = {
                name: '',
                value: '',
            }

            this.parameters = {
                name: '',
                value: '',
            }
            break;
        default:
            break;
        }
    }

    handleCloseModal = () => {
        $('#taxonomyModal').modal('hide')
        store.taxonomyTitle = '';
        this.ResetFieldsTaxonomy();
        this.handleResetState();
    }

    handleResetState = () => {
        if (store.type === 'industry') {
            store.parentIndustry = null
            store.sub_industries = []
        }
        this.setState({
            taxonomySuccess: false,
            subTaxonomySuccess: false,
            industrySuccess: false,
            locationSuccess: false,
            researchTagSuccess: false,
            teamRoleSuccess: false,
            editParams: [],
            validationFields: []
        });
    }

    handleInputChangeTaxonomy = (event) => {
        if (store.isEdit) {
            this.state.editParams.push(event.target.name)
        }

        store.formFieldsTaxonomy[event.target.name] = event.target.value
        this.parameters[event.target.name] = event.target.value
        this.state[`${event.target.name}Empty`] = false
        this.setState(this.state)
    }

    handleEmptyFieldValidationTaxonomy = () => {
        let errorCount = 0
        this.state.validationFields.map(item => {
            if (store.formFieldsTaxonomy[item] === '') {
                this.state[`${item}Empty`] = true
                errorCount++
            }
            else {
                this.state[`${item}Empty`] = false
                if (errorCount > 0) errorCount--
            }
        })
        this.setState(this.state)
        return errorCount > 0 ? false : true
    }

    ResetFieldsTaxonomy = () => {
        Object.keys(store.formFieldsTaxonomy).map(key => {
            store.formFieldsTaxonomy[key] = ''
        })
        this.parameters = store.formFieldsTaxonomy
    }

    prepParametersTaxonomy = (params) => {
        let obj = {}
        Object.keys(params).map(key => {
            if (params[key] !== "" && key !== 'logo' && key !== 'cover' && key !== 'cover_image') {
                obj[key] = params[key]
            }
        })
        if (store.isAdd) this.parameters = obj
        if (store.isEdit) this.editParams = obj
    }

    handleSaveTaxonomy = () => {
        store.currentAddName = store.formFieldsTaxonomy.name
        if (this.handleEmptyFieldValidationTaxonomy()) {
            if (store.isAdd) {
                this.addTaxonomy()
            }
            else {
                this.updateTaxonomy()
            }
        }
    }

    addTaxonomy = () => {
        this.prepParametersTaxonomy(this.parameters)
        let query = store.type === 'taxonomy-type' && !store.isTaxonomyList ?
            `${store.type}/${store.currentId}` : `${store.type}`

        fetch(`${API}/${query}/`, {
            method: 'POST',
            headers: {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.parameters)
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.status === 201) {
                    if (store.taxonomyTitle === 'TAXONOMY') {
                        this.setState({ taxonomySuccess: true })
                    }
                    if (store.taxonomyTitle === 'SUBTAXONOMY') {
                        this.setState({ subTaxonomySuccess: true })
                    }
                    if (store.taxonomyTitle === 'INDUSTRY') {
                        this.setState({ industrySuccess: true })
                    }
                    if (store.taxonomyTitle === 'LOCATION') {
                        this.setState({ locationSuccess: true })
                    }
                    if (store.taxonomyTitle === 'RESEARCH TAGS') {
                        this.setState({ researchTagSuccess: true })
                    }
                    if (store.taxonomyTitle === 'TEAM ROLES') {
                        this.setState({ teamRoleSuccess: true })
                    }

                    this.ResetFieldsTaxonomy();
                    this.prepareResult();
                    this.props.gridApi.setRowData(store.rowData)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    updateTaxonomy = () => {
        let params = {}
        this.state.editParams.map(item => {
            if (this.parameters[item] === '' || this.parameters[item] === null) {
                params[item] = null;
            } else {
                params[item] = this.parameters[item];
            }
        })
        let query = store.type === 'taxonomy-type' && !store.isTaxonomyList ?
            `${store.type}/${store.taxonomyId}/taxonomy/${store.currentId}` : `${store.type}/${store.currentId}`

        fetch(`${API}/${query}`, {
            method: 'PATCH',
            headers: {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.status === 200) {
                    if (store.taxonomyTitle === 'TAXONOMY') {
                        this.setState({ taxonomySuccess: true })
                    }
                    if (store.taxonomyTitle === 'SUBTAXONOMY') {
                        this.setState({ subTaxonomySuccess: true })
                    }
                    if (store.taxonomyTitle === 'INDUSTRY') {
                        this.setState({ industrySuccess: true })
                    }
                    if (store.taxonomyTitle === 'LOCATION') {
                        this.setState({ locationSuccess: true })
                    }
                    if (store.taxonomyTitle === 'RESEARCH TAGS') {
                        this.setState({ researchTagSuccess: true })
                    }
                    if (store.taxonomyTitle === 'TEAM ROLES') {
                        this.setState({ teamRoleSuccess: true })
                    }

                    this.prepareResult();
                    this.props.gridApi.setRowData(store.rowData);
                    this.ResetFieldsTaxonomy();
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    handlePrimaryFocusChange = async (option) => {
        const parentId = option.value;
        const selectedParentIndustry = store.parent_industries.find(industry => industry.value === parentId)

        // Update store with new industires
        store.formFieldsTaxonomy.parent_id = parentId;
        store.parentIndustry = selectedParentIndustry;

        if (store.isEdit) {
            this.setState({editParams: [...this.state.editParams,'parent_id']})
        }
        this.parameters['parent_id'] = parentId
    }

    render() {
        return (
            <div>
                <div className="modal fade" id="taxonomyModal">
                    <div className="modal-dialog">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h4 className="modal-title">{store.isEdit ? 'EDIT' : 'ADD'} {store.taxonomyTitle}</h4>
                                <button type="button" className="close" onClick={this.handleCloseModal}>&times;</button>
                            </div>

                            <div className="modal-body">

                                {store.taxonomyTitle === 'SUBTAXONOMY' && <div>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Taxonomy Name <span className='required'>*</span></label>
                                            <input type="text" className={this.state.nameEmpty ? "form-control input-error" : "form-control"} name='name' value={store.formFieldsTaxonomy.name} onChange={this.handleInputChangeTaxonomy} />
                                            {this.state.nameEmpty && <div className='error'>Name cannot be empty</div>}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Taxonomy Value <span className='required'>*</span></label>
                                            <input type="text" className={this.state.valueEmpty ? "form-control input-error" : "form-control"} name='value' value={store.formFieldsTaxonomy.value} onChange={this.handleInputChangeTaxonomy} />
                                            {this.state.valueEmpty && <div className='error'>Value cannot be empty</div>}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Description</label>
                                            <input type="text" className="form-control" name='description' value={store.formFieldsTaxonomy.description} onChange={this.handleInputChangeTaxonomy} />
                                        </div>
                                    </div>

                                    {this.state.subTaxonomySuccess && <div className="alert alert-success">
                                        <strong>Success!</strong> {store.currentAddName} Successfully {store.isAdd ? 'Added!' : 'Updated!'}
                                    </div>}
                                </div>}

                                {store.taxonomyTitle === 'TAXONOMY' && <div>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Taxonomy Name <span className='required'>*</span></label>
                                            <input type="text" className={this.state.nameEmpty ? "form-control input-error" : "form-control"} name='name' value={store.formFieldsTaxonomy.name} onChange={this.handleInputChangeTaxonomy} />
                                            {this.state.nameEmpty && <div className='error'>Name cannot be empty</div>}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Description</label>
                                            <input type="text" className="form-control" name='description' value={store.formFieldsTaxonomy.description} onChange={this.handleInputChangeTaxonomy} />
                                        </div>
                                    </div>

                                    {this.state.taxonomySuccess && <div className="alert alert-success">
                                        <strong>Success!</strong> {store.currentAddName} Successfully {store.isAdd ? 'Added!' : 'Updated!'}
                                    </div>}
                                </div>}

                                {store.taxonomyTitle === 'LOCATION' && <div>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Location Name <span className='required'>*</span></label>
                                            <input type="text" className={this.state.nameEmpty ? "form-control input-error" : "form-control"} name='name' value={store.formFieldsTaxonomy.name} onChange={this.handleInputChangeTaxonomy} />
                                            {this.state.nameEmpty && <div className='error'>Name cannot be empty</div>}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >ISO Code <span className='required'>*</span></label>
                                            <input type="text" className={this.state.iso_codeEmpty ? "form-control input-error" : "form-control"} name='iso_code' value={store.formFieldsTaxonomy.iso_code} onChange={this.handleInputChangeTaxonomy} />
                                            {this.state.iso_codeEmpty && <div className='error'>ISO Code cannot be empty</div>}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Region</label>
                                            <input type="text" className="form-control" name='region' value={store.formFieldsTaxonomy.region} onChange={this.handleInputChangeTaxonomy} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Country</label>
                                            <input type="text" className="form-control" name='country' value={store.formFieldsTaxonomy.country} onChange={this.handleInputChangeTaxonomy} />
                                        </div>
                                    </div>
                                    {this.state.locationSuccess && <div className="alert alert-success">
                                        <strong>Success!</strong> {store.currentAddName} Successfully {store.isAdd ? 'Added!' : 'Updated!'}
                                    </div>}
                                </div>}

                                {store.taxonomyTitle === 'INDUSTRY' && <div>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Industry Name <span className='required'>*</span></label>
                                            <input type="text" className={this.state.nameEmpty ? "form-control input-error" : "form-control"} name='name' value={store.formFieldsTaxonomy.name} onChange={this.handleInputChangeTaxonomy} />
                                            {this.state.nameEmpty && <div className='error'>Name cannot be empty</div>}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Description <span className='required'>*</span></label>
                                            <input type="text" className={this.state.descriptionEmpty ? "form-control input-error" : "form-control"} name='description' value={store.formFieldsTaxonomy.description} onChange={this.handleInputChangeTaxonomy} />
                                            {this.state.nameEmpty && <div className='error'>Description cannot be empty</div>}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Parent industry</label>
                                            <Select
                                                placeholder='Select parent industry'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={store.parentIndustry}
                                                onChange={(option) => this.handlePrimaryFocusChange(option)}
                                                options={store.parent_industries}
                                            />
                                        </div>
                                    </div>
                                    {this.state.industrySuccess && <div className="alert alert-success">
                                        <strong>Success!</strong> {store.currentAddName} Successfully {store.isAdd ? 'Added!' : 'Updated!'}
                                    </div>}
                                </div>}

                                {store.taxonomyTitle === 'RESEARCH TAGS' && <div>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Name <span className='required'>*</span></label>
                                            <input type="text" className={this.state.nameEmpty ? "form-control input-error" : "form-control"} name='name' value={store.formFieldsTaxonomy.name} onChange={this.handleInputChangeTaxonomy} />
                                            {this.state.nameEmpty && <div className='error'>Name cannot be empty</div>}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Value <span className='required'>*</span></label>
                                            <input type="text" className={this.state.valueEmpty ? "form-control input-error" : "form-control"} name='value' value={store.formFieldsTaxonomy.value} onChange={this.handleInputChangeTaxonomy} />
                                            {this.state.valueEmpty && <div className='error'>Name cannot be empty</div>}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Description </label>
                                            <input type="text" className="form-control" name='description' value={store.formFieldsTaxonomy.description} onChange={this.handleInputChangeTaxonomy} />
                                        </div>
                                    </div>
                                    {this.state.researchTagSuccess && <div className="alert alert-success">
                                        <strong>Success!</strong> {store.currentAddName} Successfully {store.isAdd ? 'Added!' : 'Updated!'}
                                    </div>}
                                </div>}

                                {store.taxonomyTitle === 'TEAM ROLES' && <div>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Team Role Name <span className='required'>*</span></label>
                                            <input type="text" className={this.state.nameEmpty ? "form-control input-error" : "form-control"} name='name' value={store.formFieldsTaxonomy.name} onChange={this.handleInputChangeTaxonomy} />
                                            {this.state.nameEmpty && <div className='error'>Name cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Value <span className='required'>*</span></label>
                                            <input type="text" className={this.state.valueEmpty ? "form-control input-error" : "form-control"} name='value' value={store.formFieldsTaxonomy.value} onChange={this.handleInputChangeTaxonomy} />
                                            {this.state.valueEmpty && <div className='error'>Name cannot be empty</div>}
                                        </div>
                                    </div>
                                    {this.state.teamRoleSuccess && <div className="alert alert-success">
                                        <strong>Success!</strong> {store.currentAddName} Successfully {store.isAdd ? 'Added!' : 'Updated!'}
                                    </div>}
                                </div>}

                            </div>

                            <div className="modal-footer">
                                <div></div>
                                <button type="button" className="btn btn-primary" onClick={this.handleSaveTaxonomy}>{store.isEdit ? 'SAVE' : 'ADD'}</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default observer(TaxonomyModal)