import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import store from '../../store'
import { SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY, OPTIONS, API, LOCATIONS, TAXONOMIES, BANNER_TYPES } from '../../constant'
import { getTaxonomyData, getPageList } from '../../utility/commonApis'
import { prepareSelectParam } from '../../utility/queryParams'
import { isObjectEmpty } from '../../utility/helpers'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import SideNav from '../SideNav'
import Modal from '../reusable/Modal'
import FormParent from '../FormParent'
import Home from '../Home'
import ErrorPopup from '../reusable/ErrorModal';

class AdvertisementForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                name: '',
                url: '',
                start_date: '',
                end_date: '',
                responsive_img_id: '',
                desktop_img_id: '',
                type: 0
            },
            responsive_img_idError: false,
            responsive_img_idSuccess: false,
            responsive_img_idUpload: false,
            responsive_img_idMsg: '',
            desktop_img_idError: false,
            desktop_img_idSuccess: false,
            desktop_img_idUpload: false,
            desktop_img_idMsg: '',
            responsive_img_idFile: '',
            desktop_img_idFile: '',
            editParams: [],
            multiFields:
                { page: [] },
            pageValue: '',
            page: [],

            // If the page is a directory page then "isDir" should be true
            pageList: [
                { value: 'All Directory', label: 'All Directory', isDir: true },
                { value: 'Dashboard', label: 'Dashboard', isDir: false },
                { value: 'News', label: 'News', isDir: true },
                { value: 'Jobs', label: 'Jobs', isDir: true },
                { value: 'Applications', label: 'Applications', isDir: true },
                { value: 'Research', label: 'Research', isDir: true }
            ],
            fileFields: ['responsive_img_id', 'desktop_img_id'],
            validationFields: ['name', 'start_date', 'end_date'],
        }
        this.parameters = {
            name: '',
            url: '',
            start_date: '',
            end_date: '',
            responsive_img_id: '',
            desktop_img_id: '',
            type: 0
        }

    }

    componentDidMount() {
        if (store.isEdit && store.currentId) {
            let paramFields = ['id', 'name', 'url', 'start_date', 'end_date', 'type', 'responsive_img_id', 'responsive_img_data', 'desktop_img_id', 'desktop_img_data', 'page_data']
            let fields = prepareSelectParam(paramFields)

            fetch(`${API}/banner/${store.currentId}/?fields=${fields}`, {
                method: 'GET',
                headers: {
                    'Authorization': store.token,
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    /*if(data.data.page_data.length > 0){
                        this.state.page = data.data.page_data
                        data.data.page_data.map(item=>{
                            this.state.multiFields.page.push({page: item.id})
                        })
                    }
                    else{
                        this.state.page = []
                        this.state.multiFields.page = []
                    }*/

                    let obj = {}
                    Object.keys(this.state.formFields).map(key => {
                        if (key === 'responsive_img_id') {
                            obj[key] = !isObjectEmpty(data.data.responsive_img_data) ? data.data.responsive_img_data.name : ''
                        }
                        else if (key === 'desktop_img_id') {
                            obj[key] = !isObjectEmpty(data.data.desktop_img_data) ? data.data.desktop_img_data.name : ''
                        }
                        else if (key === 'start_date') {
                            obj[key] = data.data.start_date !== null ? new Date(data.data.start_date) : ''
                        }
                        else if (key === 'end_date') {
                            obj[key] = data.data.end_date !== null ? new Date(data.data.end_date) : ''
                        }
                        else if (key === 'type') {
                            obj[key] = data.data[key] === 0 ? { value: 0, label: 'Top' } : data.data[key] === 1 ? { value: 1, label: 'Side' } : ''
                        }
                        else {
                            obj[key] = data.data[key] !== null ? data.data[key] : ''
                        }
                    })
                    this.state.formFields = obj
                    this.setState(this.state)
                })
                .catch(err => {
                    console.log(err)
                })

            getPageList(`banner/${store.currentId}`).then(res => {
                if (res.length > 0) {
                    this.setState({ page: res })
                }
            })
        }

    }

    handlePageSelect = option => {
        const { pageList } = this.state;

        // If all directory is selected
        // Make selection for all the directory pages
        if (option.value === "All Directory") {
            pageList.map(v => {

                if (v.isDir) {
                    this.handleMultiSelectChange(v, 'page', 'page');
                }
            });
        } else {
            this.handleMultiSelectChange(option, 'page', 'page');
        }
    }

    renderErrors = () => {
        let { nameEmpty,
            desktop_img_idMsg,
            desktop_img_idError,
            desktop_img_idUpload,
            responsive_img_idMsg,
            responsive_img_idError,
            responsive_img_idUpload,
            start_dateEmpty,
            end_dateEmpty } = this.state

        let errors = []

        if (nameEmpty)
            errors.push(<div className='error'>Ad name cannot be empty</div>)
        if (desktop_img_idMsg)
            errors.push(<div className='error'>{this.state.desktop_img_idMsg}</div>)
        if (desktop_img_idError)
            errors.push(<div className='error'>Upload failed!.</div>)
        if (desktop_img_idUpload)
            errors.push(<div className='error'>File is chosen but not uploaded.</div>)
        if (responsive_img_idMsg)
            errors.push(<div className='error'>{this.state.responsive_img_idMsg}</div>)
        if (responsive_img_idError)
            errors.push(<div className='error'>Upload failed!.</div>)
        if (responsive_img_idUpload)
            errors.push(<div className='error'>File is chosen but not uploaded.</div>)
        if (start_dateEmpty)
            errors.push(<div className='error'>Start date cannot be empty</div>)
        if (end_dateEmpty)
            errors.push(<div className='error'>End date cannot be empty</div>)

        return errors
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT ADVERTISEMENT</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD ADVERTISEMENT</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Ad Name <span className='required'>*</span></label>
                                            <input type="text" className={this.state.nameEmpty ? "form-control input-error" : "form-control"} name='name' value={this.state.formFields.name} onChange={this.handleInputChange} />
                                            {this.state.nameEmpty && <div className='error'>Ad name cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >URL</label>
                                            <input className="form-control" name='url' value={this.state.formFields.url} onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Desktop Image </label>
                                            <div className="input-group mb-3">
                                                <div className="custom-file">
                                                    <div className="form-filename-holder">{this.state.formFields.desktop_img_id === '' ? 'Choose a File' : this.state.formFields.desktop_img_id}</div>
                                                    <input type='file' className="custom-file-input" name="desktop_img_id" onChange={this.handleImageUpload} />
                                                </div>
                                                <div className="input-group-append">
                                                    <span className="input-group-text" onClick={() => this.addFileUpload('desktop-img', 'desktop_img_id', this.state.desktop_img_idFile)}>Upload</span>
                                                </div>
                                            </div>
                                            {this.state.desktop_img_idMsg && <div className='error'>{this.state.desktop_img_idMsg}</div>}
                                            {this.state.desktop_img_idError && <div className='error'>Upload failed!.</div>}
                                            {this.state.desktop_img_idSuccess && <div className='success'>Upload successful!</div>}
                                            {this.state.desktop_img_idUpload && <div className='error'>File is chosen but not uploaded</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Mobile Image </label>
                                            <div className="input-group mb-3">
                                                <div className="custom-file">
                                                    <div className="form-filename-holder">{this.state.formFields.responsive_img_id === '' ? 'Choose a File' : this.state.formFields.responsive_img_id}</div>
                                                    <input type='file' className="custom-file-input" name="responsive_img_id" onChange={this.handleImageUpload} />
                                                </div>
                                                <div className="input-group-append">
                                                    <span className="input-group-text" onClick={() => this.addFileUpload('responsive-img', 'responsive_img_id', this.state.responsive_img_idFile)}>Upload</span>
                                                </div>
                                            </div>
                                            {this.state.responsive_img_idMsg && <div className='error'>{this.state.responsive_img_idMsg}</div>}
                                            {this.state.responsive_img_idError && <div className='error'>Upload failed!.</div>}
                                            {this.state.responsive_img_idSuccess && <div className='success'>Upload successful!</div>}
                                            {this.state.responsive_img_idUpload && <div className='error'>File is chosen but not uploaded</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Start Date <span className='required'>*</span> </label>
                                            <DatePicker
                                                name='start_date'
                                                placeholderText="Click to select a date"
                                                className={this.state.start_dateEmpty ? 'input-error datepicker-form-control' : 'datepicker-form-control'}
                                                dateFormat="yyyy/MM/dd"
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                selected={this.state.formFields.start_date}
                                                onChange={(date) => this.handleDateChange(date, 'start_date')} />
                                            {this.state.start_dateEmpty && <div className='error'>Start date cannot be empty</div>}
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >End Date<span className='required'>*</span></label>
                                            <DatePicker
                                                name='end_date'
                                                placeholderText="Click to select a date"
                                                className={this.state.end_dateEmpty ? 'datepicker-form-control input-error' : 'datepicker-form-control'}
                                                dateFormat="yyyy/MM/dd"
                                                minDate={this.state.formFields.start_date}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                selected={this.state.formFields.end_date}
                                                onChange={(date) => this.handleDateChange(date, 'end_date')} />
                                            {this.state.end_dateEmpty && <div className='error'> End date cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Type</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.type}
                                                onChange={(option) => this.handleSelectChange(option, 'type')}
                                                options={BANNER_TYPES}
                                            />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Pages</label>
                                            <Select
                                                placeholder='Select pages'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.pageValue}
                                                onChange={option => this.handlePageSelect(option)}
                                                options={this.state.pageList}
                                            />
                                            {this.state.page.length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state.page.map((item, i) => {
                                                        return <li key={i}>{item.name} <i className='fa fa-times-circle' onClick={() => this.handleMultiRemove(i, item.id, 'page')}></i></li>
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {this.renderErrors()}

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ?
                                    <button className="btn-primary bottom-btn" disabled={this.state.editParams.length > 0 || store.apis.length > 0 ? false : true} onClick={() => this.handleUpdate('banner')}>Save</button> :
                                    <div>
                                        <button className="btn-primary bottom-btn" onClick={() => this.handleAdd('banner')}>Add Advertisement</button>
                                        <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                                    </div>
                                }
                            </div>

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <ErrorPopup errorMessage={this.state.errorMessage}/>
                        <Modal />
                    </div>
                }
            </div>
        )
    }
}
export default observer(AdvertisementForm)
