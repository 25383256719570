import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react';
import Select from 'react-select';

// constants
import { TECHNOLOGY_COLUMN_HEADERS, ALLOWED_QUERY_OPERATOR, SELECT_STYLES, SELECT_THEME_PRIMARY, DELETE_OPTIONS, ACTIVE_STATUS } from '../../constant';
import { API_PATH } from '../../utility/constants/apiPath';

// helpers
import store from '../../store';
import { prepareSelectParam } from '../../utility/queryParams';

// components
import Parent from '../Parent';
import Home from '../Home';
import SideNav from '../SideNav';
import ActionRenderer from '../reusable/ActionRenderer';
import Sort from '../reusable/Sort';
import Pagination from '../reusable/Pagination';
import Modal from '../reusable/Modal';

class Technology extends Parent {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: TECHNOLOGY_COLUMN_HEADERS,
            context: { componentParent: this },
            frameworkComponents: { actionRenderer: ActionRenderer },
            defaultColDef: { suppressMovable: true },
            keyword: [],
            name: '',
            showkeyword: false,
            filters: {
                name: [],
                status: [],
                is_deleted: [],
            },
            filterFields: [
                { key: 'name', op: ALLOWED_QUERY_OPERATOR.substring, value: [] },
                { key: 'is_deleted', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },
                { key: 'status', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },
            ]
        };

        store.sortType = this.props.location.pathname.split('/')[1];
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
    }

    componentDidMount() {
        document.addEventListener('mousedown', (e) => {
            if (this.name) { if (!this.name.contains(e.target)) this.setState({ showkeyword: false }); }
        });

        // technology request fields
        const paramFields = [
            'id',
            'name',
            'description',
            'status',
            'created_at',
            'updated_at',
        ];

        store.fields = prepareSelectParam(paramFields);

        this.fetchListData(API_PATH.technology, store.fields);

    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => {
            if (this.name) { if (!this.name.contains(e.target)) this.setState({ showkeyword: false }); }
        });
    }

    clearFilters = () => {
        this.setState({ 
            is_deleted: [],
            status: [],
            name: []
        }, () => this.handleClearAllFilters(API_PATH.technology));    
    };

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>TECHNOLOGY</h3>
                            <div className='filter-container'>

                                <div className='filter-row' >
                                    <div ref={node => this.name = node} className="col-md-12 col-sm-12 col-xs-12 filter-firstline filter-spacing" >
                                        <div style={{ width: '100%' }}>
                                            <input ref={node => this.input = node}
                                                className='filter-input input-border'
                                                placeholder="Type and Enter to Search"
                                                value={this.state.name}
                                                onFocus={() => this.setState({ showkeyword: true })}
                                                onKeyUp={this.handleNameEnter}
                                                onChange={e => this.setState({ name: e.target.value })} />
                                            {this.state.showkeyword && this.state.filters.name.length > 0 ?
                                                <div className='filter-dropdown' style={{ width: '100%' }}>
                                                    <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                        {this.state.filters.name.map((word, i) => {
                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'name')}>
                                                                {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div> : null}
                                        </div>
                                        {this.state.name.length > 0 &&
                                            <i className="fa fa-times "
                                                style={{ paddingRight: 10 }}
                                                onClick={() => {
                                                    this.input.focus()
                                                    this.setState({ name: '' })
                                                }}>
                                            </i>
                                        }
                                    </div>
                                </div>

                                <div className="filter-row" style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className="col-md-6 col-sm-6 col-xs-12 " style={{ paddingLeft: 0 }}>
                                        <div className='filter-firstline'>
                                            <div style={{ width: '100%' }}>
                                                <Select
                                                    placeholder='Filter by deleted status'
                                                    styles={SELECT_STYLES}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: SELECT_THEME_PRIMARY
                                                        }
                                                    })}
                                                    value={this.state.is_deleted}
                                                    onChange={(option) => this.handleSingleSelectFilter(option, 'is_deleted')}
                                                    options={DELETE_OPTIONS}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12 " style={{ padding: 0 }}>
                                        <div className='filter-firstline'>
                                            <div style={{ width: '100%' }}>
                                                <Select
                                                    placeholder='Filter by active status'
                                                    styles={SELECT_STYLES}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: SELECT_THEME_PRIMARY
                                                        }
                                                    })}
                                                    value={this.state.status}
                                                    onChange={(option) => this.handleSingleSelectFilter(option, 'status')}
                                                    options={ACTIVE_STATUS}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-12 filter-searchbtn mr-0" onClick={this.clearFilters}>Clear All</a>
                                    </div>
                                </div>
                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    defaultColDef={this.state.defaultColDef}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    frameworkComponents={this.state.frameworkComponents}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                />
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }
            </div>
        );
    }
}

export default observer(Technology);
