import React from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select'
import AsyncSelect from 'react-select/async'

// constants
import { SELECT_STYLES, SELECT_THEME_PRIMARY, SELECT_STYLES_ERROR, ACTIVE_STATUS } from '../../constant';
import { API_PATH } from '../../utility/constants/apiPath';

// helpers
import store from '../../store';
import { isObjectEmpty } from '../../utility/helpers';

// apis
import organizationApi from '../../apis/organizationApi';
import { getUsers } from '../../utility/commonApis';
import apiClientApi from '../../apis/apiClientApi';

// components
import Home from '../Home';
import FormParent from '../FormParent';
import SideNav from '../SideNav';
import Modal from '../reusable/Modal';
import ErrorPopup from '../reusable/ErrorModal';

class ApiClientForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                name: '',
                user_id: '',
                client_id: '',
                organization_id: '',
                status: '',
                client_ip: [
                    { ip_label: '', ip: '' }
                ]
            },
            editParams: [],

            // options
            organizations: [],
            users: [],

            // required fields
            validationFields: ['name', 'user_id', 'organization_id'],
        }

        this.parameters = {
            name: '',
            user_id: '',
            organization_id: '',
            status: '',
            client_ip: []
        };
    }

    async componentDidMount() {
        store.apiClientID = '';
        store.apiClientSecret = '';

        await this.getOrganizations();
        await this.getUsers();

        if (store.isEdit && store.currentId) {
            apiClientApi.getApiClientDetails(store.currentId).then(
                response => {
                    let objTemp = {};

                    Object.keys(this.state.formFields).map(key => {
                        if (key === 'user_id') {
                            objTemp[key] = !isObjectEmpty(response.user_data) ? { value: response.user_data.id, label: response.user_data.name } : '';
                        }
                        else if (key === 'organization_id') {
                            objTemp[key] = !isObjectEmpty(response.organization_data) ? { value: response.organization_data.id, label: response.organization_data.name } : '';
                        }
                        else if (key === 'status') {
                            objTemp[key] = response.active == 0 ? { value: response.active, label: 'In Active' } : { value: response.active, label: 'Active' };
                        }
                        else {
                            objTemp[key] = response[key] !== null ? response[key] : '';
                        }
                    })

                    this.setState({ formFields: objTemp });
                }
            );
        }
    }

    getOrganizations = async (option) => {
        organizationApi.getOrganizations(option).then(
            response => {
                const arrTemp = response.map(org => {
                    return { value: org.id, label: org.name };
                });

                this.setState({
                    organizations: arrTemp
                });
            }
        );
    }

    getUsers = async (option) => {
        const arrUsers = await getUsers(option);

        this.setState({
            users: arrUsers
        });
    }

    handleAddIP = () => {
        const tempFormFields = { ...this.state.formFields };
        const tempIps = [...tempFormFields.client_ip];

        tempIps.push({ ip_label: '', ip: '' });

        tempFormFields.client_ip = tempIps;

        this.setState({
            formFields: tempFormFields
        });
    }

    handleIPChange = (event) => {
        const tempEditParams = [...this.state.editParams];
        if (store.isEdit) {
            if (tempEditParams.indexOf('client_ip') === -1) {
                tempEditParams.push('client_ip')
            }
        }

        const value = event.target.value;
        const name = event.target.name;

        const arrTemp = name.split('-');
        const index = arrTemp[0];
        const label = arrTemp[1];

        const tempFormFields = { ...this.state.formFields };
        let tempIps = [...tempFormFields.client_ip];

        tempIps[index][label] = value;

        tempFormFields.client_ip = tempIps;

        this.setState({
            formFields: tempFormFields,
            editParams: tempEditParams
        });
    }

    handleDeleteIP = (index) => {
        const tempEditParams = [...this.state.editParams];
        if (store.isEdit) {
            if (tempEditParams.indexOf('client_ip') === -1) {
                tempEditParams.push('client_ip')
            }
        }

        const tempFormFields = { ...this.state.formFields };
        const tempIps = [...tempFormFields.client_ip];

        tempIps.splice(index, 1);

        tempFormFields.client_ip = tempIps;

        this.setState({
            formFields: tempFormFields,
            editParams: tempEditParams
        });
    }

    handleAddClient = () => {
        this.parameters.client_ip = this.state.formFields.client_ip;

        this.handleAdd(API_PATH.apiClient);
    }

    handleEditClient = () => {
        this.parameters.client_ip = this.state.formFields.client_ip;

        this.handleUpdate(API_PATH.apiClient);
    }

    renderIpSection = () => {
        const tempFormFields = { ...this.state.formFields };
        const tempIps = [...tempFormFields.client_ip];

        const section = tempIps.map((obj, index) => {
            return (
                <div className='row'>
                    <div className="col-md-5 col-sm-5 col-xs-12 form-group">
                        <label>IP Label {index === 0 && <span className='required'>*</span>}</label>
                        <input
                            key={`${index}-ip-label`}
                            className="form-control"
                            type="text"
                            name={`${index}-ip_label`}
                            value={obj.ip_label}
                            onChange={this.handleIPChange}
                        />
                    </div>
                    <div className="col-md-5 col-sm-5 col-xs-12 form-group">
                        <label>IP {index === 0 && <span className='required'>*</span>}</label>
                        <input
                            key={`${index}-ip`}
                            className="form-control"
                            type="text"
                            name={`${index}-ip`}
                            value={obj.ip}
                            onChange={this.handleIPChange}
                        />
                    </div>

                    {
                        index !== 0 &&
                        <div className="col-md-2 col-sm-2 col-xs-12 d-flex align-items-center justify-content-center">
                            <button className="btn btn-danger" onClick={() => this.handleDeleteIP(index)}>Delete</button>
                        </div>
                    }
                </div>
            );
        });

        return section;
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className="content">
                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className="content-right">
                            {
                                store.isEdit ?
                                    <h3 style={{ textAlign: 'center' }}>Edit API Client</h3>
                                    :
                                    <h3 style={{ textAlign: 'center' }}>Add API Client</h3>
                            }
                            <div className='form-container'>
                                <div className='form-wrapper'>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Client Name <span className='required'>*</span></label>
                                            <input
                                                className={this.state.nameEmpty ? "form-control input-error" : "form-control"}
                                                type="text"
                                                name='name'
                                                value={this.state.formFields.name}
                                                onChange={this.handleInputChange}
                                            />
                                            {this.state.nameEmpty && <div className='error'>Client name cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Client ID</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name='name'
                                                value={this.state.formFields.client_id}
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >User <span className='required'>*</span></label>
                                            <AsyncSelect
                                                name='user_id'
                                                placeholder='Select an organization'
                                                styles={this.state.user_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                cacheOptions
                                                value={this.state.formFields.user_id}
                                                defaultOptions={this.state.users}
                                                loadOptions={(value, callback) => this.handleLoadOptions(value, callback, 'user')}
                                                onChange={(option) => this.handleSelectChange(option, 'user_id')}
                                            />
                                            {this.state.user_idEmpty && <div className='error'>User cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Organization<span className='required'>*</span></label>
                                            <AsyncSelect
                                                name='organization_id'
                                                placeholder='Select an organization'
                                                styles={this.state.organization_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                cacheOptions
                                                value={this.state.formFields.organization_id}
                                                defaultOptions={this.state.organizations}
                                                loadOptions={(value, callback) => this.handleLoadOptions(value, callback, 'organization')}
                                                onChange={(option) => this.handleSelectChange(option, 'organization_id')}
                                            />
                                            {this.state.organization_idEmpty && <div className='error'>Organization cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Status</label>
                                            <Select
                                                name='status'
                                                placeholder='Select status'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                cacheOptions
                                                value={this.state.formFields.status}
                                                options={ACTIVE_STATUS}
                                                onChange={(option) => this.handleSelectChange(option, 'status')}
                                            />
                                        </div>
                                    </div>

                                    <hr />

                                    <div className='d-flex justify-content-between'>
                                        <h5>Client IPs</h5>

                                        <button
                                            className="btn-primary bottom-btn"
                                            onClick={this.handleAddIP}>
                                            Add IP
                                        </button>
                                    </div>

                                    {this.renderIpSection()}

                                </div>
                            </div>

                            <div className='bottom-btn-wrapper'>
                                <div>
                                    {
                                        store.isEdit ?
                                            <button
                                                className="btn-primary bottom-btn"
                                                onClick={this.handleEditClient}
                                                disabled={this.state.editParams.length === 0}>
                                                Save
                                            </button>
                                            :
                                            <button
                                                className="btn-primary bottom-btn"
                                                onClick={this.handleAddClient}>
                                                Add Client
                                            </button>
                                    }
                                    <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                                </div>
                            </div>

                        </div>
                        {store.isSideNav && <div className='overlay'> </div>}
                        <ErrorPopup />
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}

export default observer(ApiClientForm);
