import React from 'react';
import { observer } from 'mobx-react';
import AsyncSelect from 'react-select/async'

// constants
import { SELECT_STYLES, SELECT_THEME_PRIMARY, SELECT_STYLES_ERROR } from '../../constant';
import { API_PATH } from '../../utility/constants/apiPath';

// helpers
import store from '../../store';

// apis
import { getRolesTaxonomy } from '../../utility/commonApis';

// components
import Home from '../Home';
import FormParent from '../FormParent';
import SideNav from '../SideNav';
import Modal from '../reusable/Modal';
import ErrorPopup from '../reusable/ErrorModal';
import organizationApi from '../../apis/organizationApi';

class OrganizationRoleForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                organization_id: '',
                role_id: '',
                subscription_id: ''
            },

            'organization-role': [],
            'organization-roleValue': '',
            subscriptionId: '',
            subscriptionIdEmpty: false,
            role_idEmpty: false,

            // options
            organizations: [],
            roles: [],

            // required fields
            validationFields: ['organization_id', 'subscription_id'],
        }

        this.parameters = [];
    }

    async componentDidMount() {
        await this.getOrganizations();
        await this.getRoles();
    }

    getOrganizations = async (option) => {
        organizationApi.getOrganizations(option).then(
            response => {
                const arrTemp = response.map(org => {
                    return { value: org.id, label: org.name };
                });

                this.setState({
                    organizations: arrTemp
                });
            }
        );
    }

    getRoles = async (option) => {
        const arrRoles = await getRolesTaxonomy(option);

        this.setState({
            roles: arrRoles
        });
    }

    handleOrgSelect = (option) => {
        const tempFormFields = { ...this.state.formFields };
        const fields = ['id'];
        let subId = '';
        let subIdEmpty = false;

        //get organization subscription data
        organizationApi.getOrganizationSubscription(option.value, fields).then(
            response => {
                if (response && response.length > 0) {
                    subId = response[0].id || '';

                    if (subId === '') {
                        subIdEmpty = true;
                    }

                    tempFormFields.subscription_id = subId;

                    this.setState({
                        formFields: tempFormFields,
                        subscriptionId: subId,
                        subscriptionIdEmpty: subIdEmpty
                    }, () => {
                        this.handleSelectChange(option, 'organization_id');
                    });
                }
            }
        );
    }

    handleAddOrgRoles = () => {
        const { formFields } = this.state;
        const arrOrgRoles = [...this.state['organization-role']];

        if (arrOrgRoles.length > 0) {
            this.parameters = [];

            arrOrgRoles.map((item) => {
                this.parameters.push({
                    organization_id: formFields.organization_id.value,
                    role_id: item.id,
                    subscription_id: formFields.subscription_id
                });
            });

            this.handleAdd(API_PATH.organizationRole);
        } else {
            this.setState({ role_idEmpty: true });
        }
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className="content">
                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className="content-right">
                            <h3 style={{ textAlign: 'center' }}>Add Organization Role</h3>

                            <div className='form-container'>
                                <div className='form-wrapper'>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Organization<span className='required'>*</span></label>
                                            <AsyncSelect
                                                name='organization_id'
                                                placeholder='Select an organization'
                                                styles={this.state.organization_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                cacheOptions
                                                value={this.state.formFields.organization_id}
                                                defaultOptions={this.state.organizations}
                                                loadOptions={(value, callback) => this.handleLoadOptions(value, callback, 'organization')}
                                                onChange={this.handleOrgSelect}
                                            />
                                            {this.state.organization_idEmpty && <div className='error'>Organization cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Subscription ID<span className='required'>*</span></label>
                                            <input
                                                className={this.state.subscriptionIdEmpty ? "form-control input-error" : "form-control"}
                                                type="number"
                                                name='subscriptionId'
                                                value={this.state.subscriptionId}
                                                disabled
                                            />
                                            {this.state.subscriptionIdEmpty && <div className='error'>Organization does not have an active subscription</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Role<span className='required'>*</span></label>
                                            <AsyncSelect
                                                name='role_id'
                                                placeholder='Select a role'
                                                styles={this.state.role_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                cacheOptions
                                                value={this.state['organization-roleValue']}
                                                defaultOptions={this.state.roles}
                                                loadOptions={(value, callback) => this.handleLoadOptions(value, callback, 'role')}
                                                onChange={(option) => this.handleMultiSelectChange(option, 'role_id', 'organization-role')}
                                            />
                                            {this.state['organization-role'].length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state['organization-role'].map((item, i) => {
                                                        return <li key={i}>{item.name} <i className='fa fa-times-circle' onClick={() => this.handleMultiRemove(i, item.id, 'organization-role')}></i></li>
                                                    })}
                                                </div>
                                            }
                                            {this.state.role_idEmpty && <div className='error'>Role cannot be empty</div>}
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className='bottom-btn-wrapper'>
                                <div>
                                    <button
                                        className="btn-primary bottom-btn"
                                        onClick={this.handleAddOrgRoles}>
                                        Add Role
                                    </button>
                                    <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                                </div>
                            </div>

                        </div>
                        {store.isSideNav && <div className='overlay'> </div>}
                        <ErrorPopup />
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}

export default observer(OrganizationRoleForm);
