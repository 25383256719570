import axios from 'axios';

import store from '../store';
import { ALLOWED_QUERY_OPERATOR, API } from '../constant';
import { prepareFilterParam, prepareSelectParam } from '../utility/queryParams';

const subscriptionAddonApi = {

    getSubscriptionAddons: () => {
        const fields = prepareSelectParam(['id', 'name']);
        const filters = prepareFilterParam([
            {key: 'active', value: [1], op: ALLOWED_QUERY_OPERATOR.equal },
            {key: 'is_deleted', value: [0], op: ALLOWED_QUERY_OPERATOR.equal }
        ]);

        const url = `${API}/subscription-addon?fields=${fields}&filters=${filters}`;

        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        return new Promise((resolve, reject) => {

            axios.get(url, { headers }).then((response) => {
                resolve(response.data.data);
            }).catch((err) => reject(err));
        });
    },

    getSubscriptionAddonDetails: (subscriptionAddonId) => {
        const fields = prepareSelectParam(['id', 'name', 'price', 'active']);

        const url = `${API}/subscription-addon/${subscriptionAddonId}?fields=${fields}`;

        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        return new Promise((resolve, reject) => {

            axios.get(url, { headers }).then((response) => {
                resolve(response.data.data);
            }).catch((err) => reject(err));
        });
    },

    getSubscriptionAddonRoles: (subscriptionAddonId) => {
        const fields = prepareSelectParam(['id', 'role_data']);
        const filters = prepareFilterParam([{ key: 'subscription_addon_id', value: [subscriptionAddonId], op: ALLOWED_QUERY_OPERATOR.equal }]);

        const url = `${API}/subscription-addon-role?fields=${fields}&filters=${filters}`;

        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        return new Promise((resolve, reject) => {

            axios.get(url, { headers }).then((response) => {
                resolve(response.data.data);
            }).catch((err) => reject(err));
        });
    },

    deleteSubscriptionAddonRole: (subscriptionAddonRoleId) => {
        const url = `${API}/subscription-addon-role/${subscriptionAddonRoleId}`;

        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        return new Promise((resolve, reject) => {

            axios.delete(url, { headers }).then((response) => {
                resolve(response.data.data);
            }).catch((err) => reject(err));
        });
    },

}

export default subscriptionAddonApi;