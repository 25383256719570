import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react'
import { MENTIONS_COLUMN_HEADERS } from '../../constant'
import store from '../../store'
import MentionsModal from './MentionsModal'
import FormParent from '../FormParent'
import MentonsActionRenderer from './MentionsActionRenderer'

class NewsMentions extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: MENTIONS_COLUMN_HEADERS,
            context: { componentParent: this },
            frameworkComponents: { actionRenderer: MentonsActionRenderer },
            defaultColDef: { suppressMovable: true }
        }
    }

    onGridReady = (params) => {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    handleShowAddMentionsModal = () => {
        this.setState({ mentionError: false })

        store.mentionDetails = {
            entity_id: '',
            entity_type: '',
        }

        $('#addMentionModal').modal({
            backdrop: 'static',
            keyboard: true,
            show: true
        })
    }

    handleUpdateMentionsTable = () => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(this.props.body, 'text/html');
        const tagNameSelector = /^widget-startup-hover/;
        const hoverableElements = [], els = doc.body.getElementsByTagName('*');
        for (let i = 0; i < els.length; i++) {
            if (tagNameSelector.test(els[i].id)) {
                hoverableElements.push(els[i]);
            }
        }

        let elementsToAdd = [];
        if (store.isEdit && store.mentionData.length > 0) {
            let tempObj = hoverableElements;
            store.mentionData.map((mention, index) => {
                for (let i = 0; i < hoverableElements.length; i++) {
                    if (mention.entity_id == hoverableElements[i].getAttribute("data-name")) {
                        tempObj.splice(i, 1);
                    }
                }
            })
            elementsToAdd = tempObj;
        } else {
            elementsToAdd = hoverableElements;
        }
        
        elementsToAdd.map((element) => {
            const entityId = parseInt(element.getAttribute("data-id"));
            const entityType = element.getAttribute("data-type");
            const entityName = element.getAttribute("data-name");

            const parameters = {
                entity_id: entityId,
                entity_type: entityType,
            }

            const parametersData = {
                entity_id: entityName,
                entity_type: entityType.charAt(0).toUpperCase() + (entityType.slice(1).toLowerCase()),
                from: 'form'
            }
            this.addMentions(parameters, parametersData, entityName);
        });
    }

    render() {
        return (
            <div>
                <div className='form-container' style={{ marginTop: 10 }}>
                    <div className='form-wrapper'>
                        <h5>NEWS MENTIONS</h5>
                        <div className='funding-addinvestor-btn'>
                            <button className="btn-primary bottom-btn fas fa-plus-circle"
                                data-backdrop="static"
                                data-keyboard="false"
                                onClick={this.handleShowAddMentionsModal}> Add Mentions
                            </button>
                            <button className="btn-primary bottom-btn fas fa-spinner"
                                data-backdrop="static"
                                data-keyboard="false"
                                onClick={this.handleUpdateMentionsTable}> Update Mentions
                            </button>
                        </div>

                        <div id='grid' className='ag-theme-balham' style={{ width: '100%', color: '#5d656d' }}>
                            <AgGridReact
                                reactNext={true}
                                defaultColDef={this.state.defaultColDef}
                                columnDefs={this.state.columnDefs}
                                rowData={store.mentionData}
                                domLayout='autoHeight'
                                context={this.state.context}
                                frameworkComponents={this.state.frameworkComponents}
                                onGridReady={this.onGridReady}
                                enableCellTextSelection={true}
                            />
                        </div>

                    </div>
                </div>

                <MentionsModal gridApi={this.gridApi} />

            </div>
        )
    }
}

export default observer(NewsMentions)