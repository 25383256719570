import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

// constants
import { ALLOWED_QUERY_OPERATOR, SELECT_STYLES, SELECT_THEME_PRIMARY } from '../../constant';
import { API_PATH } from '../../utility/constants/apiPath';
import { VALUATION_CALCULATION_COLUMN_HEADERS } from '../../utility/constants/tableHeaders';
import { VALUATION_CALCULATION_STATUS_OPTIONS } from '../../utility/constants/selectOptions';

// helpers
import store from '../../store';
import { prepareSelectParam } from '../../utility/queryParams';

// apis
import { getUsers } from '../../utility/commonApis';

// components
import Parent from '../Parent';
import Home from '../Home';
import SideNav from '../SideNav';
import ActionRenderer from '../reusable/ActionRenderer';
import Modal from '../reusable/Modal';
import Sort from '../reusable/Sort';
import Pagination from '../reusable/Pagination';
import ValuationCalculationLogModalActionRenderer from './calculationDetails/calculationDetails';
import ViewValuationCalculationLogModal from './calculationDetails/ViewValuationCalculationLogModal';

class ValuationCalculationLogs extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: VALUATION_CALCULATION_COLUMN_HEADERS,
            context: { componentParent: this },
            frameworkComponents: {
                actionRenderer: ActionRenderer,
                valuationCalculationLogModalRenderer: ValuationCalculationLogModalActionRenderer
            },
            defaultColDef: { suppressMovable: true },

            // filters
            status: '',
            created_by: [],
            users: [],
            filters: {
                created_by: [],
                status: []
            },
            filterFields: [
                { key: 'created_by', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },
                { key: 'status', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },
            ]
        }
        store.sortType = this.props.location.pathname.split('/')[1];
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
    }

    async componentDidMount() {
        await this.getUsersList();

        const paramFields = ['id', 'start_time', 'end_time', 'calculation_data', 'error', 'status', 'created_by_data'];
        store.fields = prepareSelectParam(paramFields);

        this.fetchListData(API_PATH.valuationCalculationLog, store.fields);

    }

    /**
     * Get list of users
     */
    getUsersList = async (option = '') => {
        const users = await getUsers(option);

        this.setState({ users });

        return users;
    }

    /**
     * Clear all filters
     */
    handleClearFilters = () => {
        this.setState({
            created_by: '',
            status: '',
        }, () => {
            this.handleClearAllFilters(API_PATH.valuationCalculationLog);
        });
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>Valuation Calculation Logs</h3>
                            <div className='filter-container'>


                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing'  >

                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                            <AsyncSelect
                                                placeholder='Filter by user'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.created_by}
                                                defaultOptions={this.state.users}
                                                loadOptions={(option) => this.getUsersList(option)}
                                                onChange={(option) => this.handleSingleSelectFilter(option, 'created_by')}
                                            />
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                            <Select
                                                placeholder='Filter by status'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.status}
                                                onChange={(option) => this.handleSingleSelectFilter(option, 'status')}
                                                options={VALUATION_CALCULATION_STATUS_OPTIONS}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-6 filter-searchbtn" onClick={this.handleClearFilters}>Clear All</a>
                                    </div>
                                </div>

                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    defaultColDef={this.state.defaultColDef}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    frameworkComponents={this.state.frameworkComponents}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                />
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />
                        <ViewValuationCalculationLogModal />
                    </div>
                }
            </div>
        )
    }
}


export default observer(ValuationCalculationLogs);
