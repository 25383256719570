import axios from 'axios';
import store from '../store';
import { ALLOWED_QUERY_OPERATOR, API } from '../constant';
import { prepareSelectParam, prepareFilterParam } from '../utility/queryParams';

const organizationApi = {

    getOrganizations: (strName = '') => {
        const arrFields = ['id', 'name'];
        const queryFields = prepareSelectParam(arrFields);

        let queryFilters = "";
        if (strName !== '') {
            queryFilters = prepareFilterParam([
                { key: 'name', value: [strName], op: ALLOWED_QUERY_OPERATOR.substring }
            ]);
        }

        const url = `${API}/organization?fields=${queryFields}&filters=${queryFilters}`;

        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(err => reject(err));
        })
    },

    getOrganizationDetails: (intOrganizationId = '', arrFields = []) => {
        const queryFields = prepareSelectParam(arrFields);

        const url = `${API}/organization/${intOrganizationId}?fields=${queryFields}`;

        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(err => reject(err));
        })
    },

    getOrganizationUsers: (intOrganizationId = '') => {
        const queryFields = prepareSelectParam(["id", "user_data"]);

        const url = `${API}/organization/${intOrganizationId}/user?fields=${queryFields}`;

        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(err => reject(err));
        })
    },

    getOrganizationSubscription: (intOrganizationId = '', arrFields = [], arrFilters = []) => {
        const queryFields = prepareSelectParam(arrFields);
        const queryFilters = prepareFilterParam(arrFilters);

        const url = `${API}/organization/${intOrganizationId}/subscription?fields=${queryFields}&filters=${queryFilters}&sort=id^:desc&limit=1`;

        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        }

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(err => reject(err));
        })
    }
}

export default organizationApi;
