import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react'
import { PEOPLE_TEAM_COLUMN_HEADERS } from '../../constant'
import store from '../../store'
import PeopleTeamModal from './PeopleTeamModal'
import FormParent from '../FormParent'
import PeopleTeamActionRenderer from './PeopleTeamActionRenderer'

class PeopleTeam extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: PEOPLE_TEAM_COLUMN_HEADERS,
            context: { componentParent: this },
            frameworkComponents: { actionRenderer: PeopleTeamActionRenderer },
            defaultColDef: { suppressMovable: true },
            isInvitePopupOpened: false
        }
    }

    onGridReady = (params) => {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    render() {
        return (
            <div>
                <div className='form-container' style={{ marginTop: 10 }}>
                    <div className='form-wrapper'>
                        <h5>PEOPLE</h5>
                        <div className='funding-addinvestor-btn'>
                            <button className="btn-primary bottom-btn fas fa-plus-circle"
                                data-backdrop="static"
                                data-keyboard="false"
                                onClick={this.handleShowAddPeopleTeamModal}
                            >
                                Add people
                            </button>
                        </div>

                        <div id='grid' className='ag-theme-balham' style={{ width: '100%', color: '#5d656d' }}>
                            <AgGridReact
                                reactNext={true}
                                defaultColDef={this.state.defaultColDef}
                                columnDefs={this.state.columnDefs}
                                rowData={store.peopleTeamData}
                                domLayout='autoHeight'
                                context={this.state.context}
                                frameworkComponents={this.state.frameworkComponents}
                                onGridReady={this.onGridReady}
                                enableCellTextSelection={true}
                            />
                        </div>

                    </div>
                </div>

                <PeopleTeamModal gridApi={this.gridApi} />
            </div>
        )
    }
}

export default observer(PeopleTeam)