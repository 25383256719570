import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';

// constants
import { SELECT_STYLES_ERROR, SELECT_STYLES, SELECT_THEME_PRIMARY } from '../../constant';
import { API_PRODUCT_PERIOD_OPTIONS, API_PRODUCT_RESOURCE_OPTIONS } from '../../utility/constants/selectOptions';

class ManageQuotaModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resource: '',
            limit: '',
            period: '',

            resourceEmpty: false,
            limitEmpty: false,
            periodEmpty: false,
        }
    }

    handleOptionChange = (option = {}, strLabel = '') => {
        this.setState({
            [strLabel]: option,
            [`${strLabel}Empty`]: false,
        });
    }

    handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value,
            [`${name}Empty`]: false,
        })
    }

    handleCloseModal = () => {
        this.handleClearFields();
        $('#manageApiResourceModal').modal('hide');
    }

    handleClearFields = () => {
        this.setState({
            resource: '',
            limit: '',
            period: '',
            resourceEmpty: false,
            limitEmpty: false,
            periodEmpty: false
        });
    }

    /**
     * Method to handle check validations
     */
    handleValidation = () => {
        const { resource, limit, period } = this.state;
        let resourceEmpty = false;
        let limitEmpty = false;
        let periodEmpty = false;

        if (!resource) {
            resourceEmpty = true;
        }

        if (!limit) {
            limitEmpty = true;
        }

        if (!period) {
            periodEmpty = true;
        }

        this.setState({
            resourceEmpty,
            limitEmpty,
            periodEmpty
        });

        return resourceEmpty || limitEmpty || periodEmpty;
    }

    /**
     * Method to add a new resource
     */
    handleAddQuota = () => {
        const { resource, limit, period } = this.state;

        if (this.handleValidation()) {
            return;
        }

        const obj = {
            resource,
            limit,
            period
        };

        // send the info back to the form
        this.props.handleAddQuota(obj);
        this.handleClearFields();
    }

    render() {
        return (
            <div className="modal fade" id="manageApiResourceModal">
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">Add Resource</h4>
                            <button type="button" className="close" onClick={this.handleCloseModal}>&times;</button>
                        </div>

                        <div className="modal-body">
                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label>Resource <span className='required'>*</span></label>
                                    <Select
                                        placeholder='Select a resource'
                                        styles={this.state.resourceEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        value={this.state.resource}
                                        onChange={(option) => this.handleOptionChange(option, 'resource')}
                                        options={API_PRODUCT_RESOURCE_OPTIONS}
                                    />
                                    {this.state.resourceEmpty && <div className='error'>Resource cannot be empty</div>}
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label>Number of Records <span className='required'>*</span></label>
                                    <input
                                        className={this.state.limitEmpty ? "form-control input-error" : "form-control"}
                                        type="number"
                                        name='limit'
                                        value={this.state.limit}
                                        onChange={this.handleInputChange} />
                                    {this.state.limitEmpty && <div className='error'>Number of records cannot be empty</div>}
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label>Period <span className='required'>*</span></label>
                                    <Select
                                        name='period'
                                        placeholder='Select a period'
                                        styles={this.state.periodEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        isDisabled={this.state.secondResource === ''}
                                        value={this.state.period}
                                        onChange={(option) => this.handleOptionChange(option, 'period')}
                                        options={API_PRODUCT_PERIOD_OPTIONS}
                                    />
                                    {this.state.periodEmpty && <div className='error'>Period cannot be empty</div>}
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleCloseModal}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={this.handleAddQuota}>Add Resource</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default observer(ManageQuotaModal);
