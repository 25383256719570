import React from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';

// constants
import { SELECT_STYLES, SELECT_THEME_PRIMARY, ACTIVE_STATUS } from '../../constant';
import { API_PATH } from '../../utility/constants/apiPath';

// helpers
import store from '../../store';
import { isObjectEmpty } from '../../utility/helpers';

// apis
import { getLocations } from '../../utility/commonApis';

// components
import Home from '../Home';
import FormParent from '../FormParent';
import SideNav from '../SideNav';
import Modal from '../reusable/Modal';
import ErrorPopup from '../reusable/ErrorModal';
import organizationApi from '../../apis/organizationApi';
import OrganizationUsers from './organizationUsers/OrganizationUsers';

class OrganizationForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                name: '',
                description: '',
                logo: '',
                active: '',
                phone_number: '',
                tax_code: '',
                country_id: ''
            },
            multiFields: {
                user: []
            },
            editParams: [],

            // options
            locations: [],

            // logo field
            logo: '',
            logoFile: '',
            editlogo: '',
            fileFields: ['logo'],

            // required fields
            validationFields: ['name'],

            // errors
            logoMsg: '',
            logoEmpty: false,
            logoError: false,
            logoSuccess: false,
            logoUpload: false
        }

        this.parameters = {
            name: '',
            description: '',
            logo: '',
            active: '',
            phone_number: '',
            tax_code: '',
            country_id: ''
        }
    }

    async componentDidMount() {

        await this.getLocations();

        if (store.isEdit && store.currentId) {
            const paramFields = ['id', 'name', 'logo_data', 'description', 'country_data', 'phone_number', 'tax_code', 'active'];

            organizationApi.getOrganizationDetails(store.currentId, paramFields).then(
                response => {
                    let objTemp = {};

                    Object.keys(this.state.formFields).map(key => {
                        if (key === 'logo') {
                            objTemp[key] = !isObjectEmpty(response.logo_data) ? response.logo_data.name : '';
                        }
                        else if (key === 'country_id') {
                            objTemp[key] = !isObjectEmpty(response.country_data) ? { value: response.country_data.id, label: response.country_data.name } : '';
                        }
                        else if (key === 'active') {
                            objTemp[key] = response.active === 0 ? { value: response.active, label: 'In Active' } : { value: response.active, label: 'Active' };
                        }
                        else {
                            objTemp[key] = response[key] !== null ? response[key] : '';
                        }
                    })

                    this.setState({ formFields: objTemp });
                }
            );


            organizationApi.getOrganizationUsers(store.currentId).then(
                response => {
                    const arrTemp = response.map(obj => {
                        return { id: obj.id, userId: obj.user_data.id, name: obj.user_data.name };
                    });

                    store.organizationUsersData = arrTemp;
                }
            );
        }
    }

    getLocations = async (option) => {
        const arrLocations = await getLocations();

        this.setState({ locations: arrLocations });
    }

    handleAddOrg = () => {
        if (store.organizationUsers.length > 0) {
            const tempMultiFields = { ...this.state.multiFields };

            tempMultiFields.user = [...store.organizationUsers];

            this.setState({ multiFields: tempMultiFields }, () => {
                this.handleAdd(API_PATH.organization);
            })
        } else {
            this.handleAdd(API_PATH.organization);
        }

    };

    handleEditOrg = () => {                
        if (store.organizationUsers.length > 0) {
            store.apis.push({ path: `${store.type}/${store.currentId}/user`, method: 'POST', data: [...store.organizationUsers] });
        }

        this.handleUpdate(API_PATH.organization);
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className="content">
                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className="content-right">
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>Edit Organization</h3> :
                                <h3 style={{ textAlign: 'center' }}>Add Organization</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-6 col-xs-6 form-group">
                                            <label>Name <span className='required'>*</span></label>
                                            <input
                                                className={this.state.nameEmpty ? "form-control input-error" : "form-control"}
                                                type='text'
                                                placeholder='MAGNiTT'
                                                name='name'
                                                value={this.state.formFields.name}
                                                onChange={this.handleInputChange} />
                                            {this.state.nameEmpty && <div className='error'>Name cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-6 col-xs-6 form-group">
                                            <label>Logo</label>
                                            <div className="input-group mb-3">
                                                <div className={this.state.logoEmpty ? "custom-file input-error" : "custom-file"}>
                                                    <div className="form-filename-holder">{this.state.formFields.logo === '' ? 'Choose a File' : this.state.formFields.logo}</div>
                                                    <input type='file' className="custom-file-input" name="logo" onChange={this.handleImageUpload} />
                                                </div>
                                                <div className="input-group-append">
                                                    <span className="input-group-text" onClick={() => this.addFileUpload('logo', 'logo', this.state.logoFile)}>Upload</span>
                                                </div>
                                            </div>
                                            {this.state.logoMsg.length > 0 && <div className='error'>{this.state.logoMsg}</div>}
                                            {this.state.logoEmpty && <div className='error'>Logo cannot be empty</div>}
                                            {this.state.logoError && <div className='error'>Upload failed!.</div>}
                                            {this.state.logoSuccess && <div className='success'>Upload successful!</div>}
                                            {this.state.logoUpload && <div className='error'>File is chosen but not uploaded</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Description</label>
                                            <textarea
                                                className="form-control"
                                                type='text'
                                                placeholder='An EVM data platform'
                                                rows='5'
                                                name='description'
                                                value={this.state.formFields.description}
                                                onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-6 col-xs-6 form-group">
                                            <label>Phone Number</label>
                                            <input
                                                className="form-control"
                                                type='text'
                                                placeholder='+971-XX-XXX-XXXX'
                                                name='phone_number'
                                                value={this.state.formFields.phone_number}
                                                onChange={this.handleInputChange} />
                                        </div>

                                        <div className="col-md-12 col-sm-6 col-xs-6 form-group">
                                            <label>Tax Code</label>
                                            <input
                                                className="form-control"
                                                type='text'
                                                placeholder='XXXX-XXXX-XXXX'
                                                name='tax_code'
                                                value={this.state.formFields.tax_code}
                                                onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Country</label>
                                            <Select
                                                placeholder='Select a country'
                                                name='country'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.country_id}
                                                options={this.state.locations}
                                                onChange={(option) => this.handleSelectChange(option, 'country_id')}
                                            />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Status</label>
                                            <Select
                                                placeholder='Select status'
                                                name='active'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.active}
                                                options={ACTIVE_STATUS}
                                                onChange={(option) => this.handleSelectChange(option, 'active')}
                                            />
                                        </div>
                                    </div>

                                    <OrganizationUsers />

                                </div>
                            </div>

                            <div className='bottom-btn-wrapper'>
                                <div>
                                    {store.isEdit ?
                                        <button
                                            className="btn-primary bottom-btn"
                                            disabled={this.state.editParams.length > 0 || store.apis.length > 0 ||  store.organizationUsers.length > 0 ? false : true}
                                            onClick={this.handleEditOrg}>
                                            Save
                                        </button> :
                                        <button
                                            className="btn-primary bottom-btn"
                                            onClick={this.handleAddOrg}>
                                            Add Organization
                                        </button>
                                    }
                                    <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                                </div>
                            </div>

                        </div>
                        {store.isSideNav && <div className='overlay'> </div>}
                        <ErrorPopup />
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}

export default observer(OrganizationForm);
