import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

// constants
import {
    SELECT_STYLES,
    SELECT_STYLES_ERROR,
    SELECT_THEME_PRIMARY
} from '../../../constant';

function DataExportSection(props) {
    return (
        <div className='form-wrapper'>
            <h5>Data Export Request Details</h5>
            <div className='row'>
                <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                    <label>Data Set Type <span className='required'>*</span></label>
                    <Select
                        name='data_set_type'
                        placeholder='Please Select'
                        styles={props.isDataSetTypeEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                        theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: SELECT_THEME_PRIMARY
                            }
                        })}
                        value={props.formFields.data_set_type}
                        onChange={props.handleDataSetChange}
                        options={props.dataSetTypes}
                    />
                    {props.isDataSetTypeEmpty && <div className='error'>Data set type cannot be empty</div>}
                </div>

                <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                    <label>Number of records <span className='required'>*</span></label>
                    <input
                        className={props.isNumberOfRecordsEmpty ? "form-control input-error" : "form-control"}
                        type="number"
                        value={props.formFields.number_of_records}
                        name='number_of_records'
                        onChange={props.handleInputChange} />
                    {props.isNumberOfRecordsEmpty && <div className='error'>Number of records cannot be empty</div>}
                </div>
            </div>

            <div className='row'>
                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                    <label>Fields <span className="required">*</span></label>
                    <CreatableSelect
                        name='fields'
                        placeholder='Select Fields'
                        styles={props.isFieldsEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                        theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: SELECT_THEME_PRIMARY
                            }
                        })}
                        isDisabled={!props.formFields.data_set_type}
                        value={props.selectedField}
                        onChange={(option) => props.handleAddFieldOption(option)}
                        onCreateOption={(option) => props.handleAddFieldOption(option, true)}
                        options={props.fields}
                    />
                    {props.formFields.fields ?
                        props.formFields.fields.length > 0 &&
                        <div className='permission-policy-list-wrapper'>
                            {props.formFields.fields.map((item, i) => {
                                return <li key={i}>{item.label} <i className='fa fa-times-circle' onClick={() => props.handleRemoveField(i)}></i></li>
                            })}
                        </div> : null
                    }
                    {props.isFieldsEmpty && <div className='error'>Fields cannot be empty</div>}
                </div>
            </div>

            <div className='row'>
                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                    <div className="d-flex justify-content-between pb-2">
                        <label >Filters</label>
                        <button
                            className="btn-primary bottom-btn"
                            disabled={!props.formFields.data_set_type}
                            onClick={props.showFilterModal}
                        >
                            Add Filter
                        </button>
                    </div>
                    <ul className="list-group list-group-flush">
                        {
                            props.formFields.filters.length > 0 ?
                                props.formFields.filters.map((item, i) => {
                                    if (item.isPrimaryFilterValue) {
                                        return <li className='list-group-item' key={i} style={{ fontSize: 15 }}>
                                            {item.label}{' '}
                                            <i className='fa fa-times-circle' onClick={() => props.handleRemoveFilter(i, item.secondaryFilterValue)}></i>
                                        </li>
                                    }
                                    return null;
                                })
                                : <div className='d-flex justify-content-center bd-highlight' style={{ fontSize: '1rem', padding: 10, backgroundColor: "#cbcccd" }}>
                                    <label>Added filters will be shown here.</label>
                                </div>
                        }
                    </ul>
                </div>
            </div>

            <div className='row'>
                <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                    <label>Sort</label>
                    <Select
                        placeholder='Select Sort'
                        styles={SELECT_STYLES}
                        theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: SELECT_THEME_PRIMARY
                            }
                        })}
                        isDisabled={!props.formFields.data_set_type}
                        options={props.sortList}
                        value={props.formFields.sort}
                        onChange={(option) => props.handleSelectChange(option, 'sort')}
                    />
                </div>
            </div>
            <hr />
        </div>
    )
}

export default DataExportSection;