
import React, { PureComponent } from 'react';
import store from '../../../store';

class PolicyModalActionRenderer extends PureComponent {
    showModal = () => {
        $('#apiProductPolicyModal').modal({
            backdrop: 'static',
            keyboard: true,
            show: true
        })

        // pass the modal data
        store.apiProductPolicyModalData = this.props.data;
    }

    render() {
        return (
            <a tabIndex="1" className="btn-edit" onClick={this.showModal}>View policy</a>
        )
    }
}

export default PolicyModalActionRenderer;
