import React from 'react'
import { observer } from 'mobx-react'
import Select from 'react-select'
import store from '../../store'
import { SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY, API } from '../../constant'
import SideNav from '../SideNav'
import Modal from '../reusable/Modal'
import FormParent from '../FormParent'
import Home from '../Home'
import { prepareSelectParam } from '../../utility/queryParams'

class RoleForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                name: '',
                type: '',
                description: ''
            },
            /**@todo: Should be fetched from API, once it's ready */
            types: [
                {
                    value: 'admin',
                    label: 'admin',
                },
                {
                    value: 'auth-role',
                    label: 'auth-role',
                },
                {
                    value: 'public-role',
                    label: 'public-role',
                },
                {
                    value: 'subscription',
                    label: 'subscription',
                },
                {
                    value: 'user',
                    label: 'user',
                }
            ],
            validationFields: ['name', 'type'],
            editParams: []
        }

        this.parameters = {
            name: '',
            type: '',
            description: ''
        }
    }

    componentDidMount() {
        if (store.isEdit && store.currentId) {
            let paramFields = ['id', 'name', 'type', 'description',]
            let fields = prepareSelectParam(paramFields)

            fetch(`${API}/role/${store.currentId}/?fields=${fields}`, {
                method: 'GET',
                headers: {
                    'Authorization': store.token,
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    let obj = {}
                    Object.keys(this.state.formFields).map(key => {

                        if (key === 'type') {
                            obj[key] = data.data[key] !== null ? { value: data.data[key], label: data.data[key] } : { value: '', label: '' };
                        } else {
                            obj[key] = data.data[key] !== null ? data.data[key] : '';
                        }

                    })
                    this.setState({ formFields: obj });
                })
                .catch(err => {
                    console.log(err)
                });
        }
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className="content">
                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className="content-right">
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT ROLE</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD ROLE</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Role Name <span className='required'>*</span></label>
                                            <input type="text" className={this.state.nameEmpty ? "form-control input-error" : "form-control"} name='name' value={this.state.formFields.name} onChange={this.handleInputChange} />
                                            {this.state.nameEmpty && <div className='error'>Role name cannot be empty</div>}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Description</label>
                                            <textarea
                                                className="form-control"
                                                rows='8'
                                                name='description'
                                                value={this.state.formFields.description}
                                                onChange={this.handleInputChange}
                                                disabled={store.isEdit ? true : false}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >Role Type <span className='required'>*</span></label>
                                            <Select
                                                name='type'
                                                placeholder='Please Select'
                                                styles={this.state.development_stageEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                isDisabled={store.isEdit ? true : false}
                                                value={this.state.formFields.type}
                                                onChange={(option) => this.handleSelectChange(option, 'type')}
                                                options={this.state.types}
                                            />
                                            {this.state.typeEmpty && <div className='error'>Role type cannot be empty</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ? <button className="btn-primary bottom-btn" disabled={this.state.editParams.length > 0 ? false : true} onClick={() => this.handleUpdate('role')}>Save</button> :
                                    <div>
                                        <button className="btn-primary bottom-btn" onClick={() => this.handleAdd('role')}>Add role</button>
                                        <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                                    </div>
                                }
                            </div>

                        </div>
                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}

export default observer(RoleForm)
