export const STARTUP_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', suppressSizeToFit: true, width: 80 },
    { headerName: 'NAME', field: 'name', resizable: true },
    {
        headerName: 'INDUSTRY',
        field: 'industry',
        resizable: true,
        autoHeight: true,
        cellRenderer: function (params) {
            return (params.value !== undefined ? `<p>${params.value.replace(/,/g, ',<br/>')}</p>` : '')
        }
    },
    { headerName: 'COUNTRY', field: 'country', resizable: true },
    { headerName: 'OWNER', field: 'owner', resizable: true },
    { headerName: 'DATE FOUNDED', field: 'date_founded', resizable: true },
    { headerName: 'TOTAL FUNDING', field: 'total_funding', resizable: true },
    { headerName: 'CREATED ON', field: 'created_on', resizable: true },
    { headerName: 'UPDATED ON', field: 'updated_on', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 100 },
]

export const INVESTOR_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', suppressSizeToFit: true, width: 80 },
    { headerName: 'NAME', field: 'name', resizable: true },
    { headerName: 'TYPE', field: 'investor_type', resizable: true, filter: "agTextColumnFilter" },
    { headerName: 'COUNTRY', field: 'country', resizable: true, filter: "agTextColumnFilter" },
    {
        headerName: 'INDUSTRY',
        field: 'industry',
        resizable: true,
        autoHeight: true,
        cellRenderer: function (params) {
            return (params.value !== undefined ? `<p>${params.value.replace(/,/g, ',<br/>')}</p>` : '')
        }
    },
    { headerName: 'OWNER', field: 'owner', resizable: true },
    { headerName: 'CREATED ON', field: 'created_on', resizable: true },
    { headerName: 'UPDATED ON', field: 'updated_on', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
]

export const CORPORATE_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', suppressSizeToFit: true, width: 80 },
    { headerName: 'NAME', field: 'name', resizable: true },
    { headerName: 'TYPE', field: 'corporate_type', resizable: true },
    { headerName: 'COUNTRY', field: 'country', resizable: true },
    {
        headerName: 'INDUSTRY',
        field: 'industry',
        resizable: true,
        autoHeight: true,
        cellRenderer: function (params) {
            return (params.value !== undefined ? `<p>${params.value.replace(/,/g, ',<br/>')}</p>` : '')
        }
    },
    { headerName: 'OWNER', field: 'owner', resizable: true },
    { headerName: 'CREATED ON', field: 'created_on', resizable: true },
    { headerName: 'UPDATED ON', field: 'updated_on', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
]

export const ENABLER_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', suppressSizeToFit: true, width: 80 },
    { headerName: 'NAME', field: 'name', resizable: true },
    { headerName: 'TYPE', field: 'enabler_type', resizable: true, filter: "agTextColumnFilter" },
    { headerName: 'COUNTRY', field: 'country', resizable: true, filter: "agTextColumnFilter" },
    {
        headerName: 'INDUSTRY',
        field: 'industry',
        resizable: true,
        autoHeight: true,
        cellRenderer: function (params) {
            return (params.value !== undefined ? `<p>${params.value.replace(/,/g, ',<br/>')}</p>` : '')
        }
    },
    { headerName: 'OWNER', field: 'owner', resizable: true },
    { headerName: 'CREATED ON', field: 'created_on', resizable: true },
    { headerName: 'UPDATED ON', field: 'updated_on', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
]

export const USER_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', suppressSizeToFit: true, width: 80 },
    { headerName: 'FIRSTNAME', field: 'first_name', resizable: true },
    { headerName: 'LASTNAME', field: 'last_name', resizable: true },
    { headerName: 'USERNAME', field: 'username', resizable: true },
    {
        headerName: 'EMAIL',
        field: 'email',
        resizable: true,
        autoHeight: true,
        cellRenderer: function (params) {
            return (params.value !== undefined ? `<p>${params.value.replace(/,/g, ',<br/>')}</p>` : '')
        }
    },
    { headerName: 'STATUS', field: 'status', resizable: true },
    { headerName: 'LAST LOGIN', field: 'last_login', resizable: true },
    { headerName: 'Registered On', field: 'created_on', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
]

export const NEWS_COLUMN_HEADERS = [
    { headerName: 'TITLE', field: 'title', resizable: true },
    { headerName: 'CATEGORY', field: 'category', resizable: true },
    { headerName: 'AUTHOR', field: 'author', resizable: true },
    { headerName: 'STATUS', field: 'status', resizable: true },
    { headerName: 'FEATURED', field: 'featured', resizable: true },
    //{ headerName: 'TOTAL VIEWS', field: 'total_views', resizable: true },
    //{ headerName: 'COUNTRY', field: 'country', field: 'startup_id', resizable: true, filter: "agTextColumnFilter" },
    //{ headerName: 'INDUSTRY', field: 'industry', resizable: true, filter: "agTextColumnFilter" },
    { headerName: 'POST DATE', field: 'post_date', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
]

export const RESEARCH_COLUMN_HEADERS = [
    { headerName: 'TITLE', field: 'title', resizable: true },
    { headerName: 'RESEARCH CATEGORY', field: 'category', resizable: true, },
    //{ headerName: 'AUTHOR', field: 'author', resizable: true },
    {
        headerName: 'IMAGE',
        field: 'image',
        resizable: true,
        autoHeight: true,
        cellRenderer: function (params) {
            return (params.value !== '' ? `<img src=${encodeURI(params.value)} alt='profilepic' width='50px' height='50px' />` : '')
        }
    },
    { headerName: 'PRICE', field: 'price', resizable: true },
    {
        headerName: "INDUSTRY",
        field: "industry",
        resizable: true,
        autoHeight: true,
        cellRenderer: function (params) {
            return params.value !== undefined ? `<p>${params.value.replace(/,/g, ",<br/>")}</p>` : "";
        },
    },
    { headerName: 'STATUS', field: 'status', resizable: true },
    { headerName: 'PUBLISHED DATE', field: 'published_date', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 230 },
]

export const RD_PPOLICY_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', resizable: true },
    { headerName: 'ROLE', field: 'role', resizable: true },
    { headerName: 'DOWNLOAD LIMIT', field: 'download_limit', resizable: true, },
    { headerName: 'PERIOD', field: 'period', resizable: true },
    { headerName: 'WEIGHT', field: 'weight', resizable: true },
    { headerName: 'ACTIVE', field: 'status', resizable: true },
    { headerName: 'POLICY', field: 'policy', resizable: true, cellRenderer: "policyModalRenderer" },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 230 },
]

export const RESEARCH_DOWNLOADS_COLUMN_HEADERS = [
    { headerName: 'EMAIL', field: 'email', resizable: true },
    { headerName: 'USER ID', field: 'uid', resizable: true },
    { headerName: 'FULL NAME', field: 'username', resizable: true },
    { headerName: 'ROLE', field: 'role', resizable: true },
    { headerName: 'LOCATION', field: 'location', resizable: true },
    { headerName: 'DOWNLOAD DATE', field: 'download_date', resizable: true },
]

export const JOBS_COLUMN_HEADERS = [
    { headerName: 'JOB NAME', field: 'title', resizable: true },
    { headerName: 'COMPANY NAME', field: 'entity_id', resizable: true },
    { headerName: 'JOB TYPE', field: 'type_id', resizable: true },
    { headerName: 'PRIMARY ROLE', field: 'primary_role_id', resizable: true },
    { headerName: 'DEADLINE', field: 'deadline', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
]

export const FUNDING_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', resizable: true },
    { headerName: 'COMPANY ID', field: 'startup_id', resizable: true, },
    { headerName: 'COMPANY NAME', field: 'startup', resizable: true, },
    { headerName: 'AMOUNT', field: 'amount', resizable: true, },
    { headerName: 'POST-MONEY VALUATION', field: 'post_money_valuation', resizable: true, },
    { headerName: 'FUNDING STAGE', field: 'stage', resizable: true, },
    { headerName: 'FUNDING BREAKDOWNS COUNT', field: 'funding_breakdowns_count', resizable: true, },
    { headerName: 'INVESTORS', field: 'investors', resizable: true, cellRenderer: "InvestorsModalRenderer" },
    { headerName: 'CREATED BY', field: 'created_by', resizable: true, },
    { headerName: 'CREATED AT', field: 'created_at', resizable: true, },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
]

export const FUNDING_VERIFICATION_COLUMN_HEADERS = [
    { headerName: 'FB ID', field: 'id' },
    { headerName: 'FR ID', field: 'funding_id' },
    { headerName: 'ENTITY ID', field: 'entity_id', resizable: true },
    { headerName: 'ENTITY NAME', field: 'entity_name', resizable: true, },
    { headerName: 'STAGE', field: 'stage', resizable: true, },
    { headerName: 'INVESTMENT VEHICLE', field: 'investment_vehicle', resizable: true, },
    { headerName: 'DATE', field: 'date', resizable: true, },
    { headerName: 'AMOUNT', field: 'funding_amount', resizable: true, },
    { headerName: 'INVESTORS', field: 'investors', resizable: true, cellRenderer: "InvestorsModalRenderer" },
    { headerName: 'STATUS', field: 'verification_status', resizable: true, },
    { headerName: 'CREATED BY', field: 'created_by', resizable: true, },
    { headerName: 'TYPE', field: 'created_by_type', resizable: true, },
    { headerName: 'VERIFIED BY', field: 'verified_by', resizable: true, },
    { headerName: 'VERIFIED AT', field: 'verified_at', resizable: true, },
    { headerName: 'VERIFICATION STATUS', field: 'verification_status', hide: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true },
]

export const FUNDING_REQUESTS_COLUMN_HEADERS = [
    { headerName: 'FB ID', field: 'funding_breakdown_id' },
    { headerName: 'IF ID', field: 'id' },
    { headerName: 'STARTUP NAME', field: 'startup_name', resizable: true, },
    { headerName: 'FUNDING STAGE', field: 'stage', resizable: true, },
    { headerName: 'REQUESTER NAME', field: 'requestor', resizable: true, },
    { headerName: 'AMOUNT INVESTED', field: 'amount_invested', resizable: true, },
    { headerName: 'INVESTORS', field: 'investors', resizable: true, cellRenderer: "InvestorsModalRenderer" },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true },
]

export const FUNDING_INVESTOR_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'eid', resizable: true, hide: true, suppressToolPanel: true },
    { headerName: 'TYPE ID', field: 'tid', resizable: true, hide: true, suppressToolPanel: true },
    { headerName: 'CURRENCY ID', field: 'cid', resizable: true, hide: true, suppressToolPanel: true },
    { headerName: 'NAME', field: 'entity_id', resizable: true, },
    { headerName: 'TYPE', field: 'entity_type', resizable: true, },
    { headerName: 'AMOUNT', field: 'invested_amount', resizable: true, },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
]

export const AD_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', suppressSizeToFit: true, width: 80 },
    { headerName: 'NAME', field: 'name', resizable: true, },
    {
        headerName: 'DESKTOP IMAGE',
        field: 'desk_image',
        resizable: true,
        autoHeight: true,
        cellRenderer: function (params) {
            return (params.value !== '' ? `<img src=${encodeURI(params.value)} alt='desktopimage' width='50px' height='50px' />` : '')
        }
    },
    {
        headerName: 'RESPONSIVE IMAGE',
        field: 'mobile_image',
        resizable: true,
        autoHeight: true,
        cellRenderer: function (params) {
            return (params.value !== '' ? `<img src=${encodeURI(params.value)} alt='mobileimage' width='50px' height='50px' />` : '')
        }
    },
    { headerName: 'START DATE', field: 'start_date', resizable: true, },
    { headerName: 'END DATE', field: 'end_date', resizable: true, },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
];

export const USER_ROLES_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', suppressSizeToFit: true, width: 80 },
    { headerName: 'USERNAME', field: 'full_name', resizable: true },
    {
        headerName: 'EMAIL',
        field: 'email',
        resizable: true,
        autoHeight: true,
        cellRenderer: function (params) {
            return (params.value !== undefined ? `<p>${params.value.replace(/,/g, ',<br/>')}</p>` : '')
        }
    },
    { headerName: 'ROLE ID', field: 'role_id', resizable: true },
    { headerName: 'ROLE NAME', field: 'role_name', resizable: true },
    { headerName: 'ACCESS TYPE', field: 'access_type', resizable: true }
]

export const SUBSCRIPTIONS_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', suppressSizeToFit: true, width: 80 },
    { headerName: 'NAME', field: 'name', resizable: true },
    { headerName: 'TYPE', field: 'type', resizable: true },
    { headerName: 'DURATION', field: 'duration', resizable: true },
    { headerName: 'PRICE', field: 'price', resizable: true },
    { headerName: 'STATUS', field: 'status', hide: true, },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
]

export const SUBSCRIPTIONS_TYPES_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', suppressSizeToFit: true, width: 80 },
    { headerName: 'NAME', field: 'name', resizable: true },
    { headerName: 'ACTIVE', field: 'active', resizable: true },
    { headerName: 'DESCRIPTION', field: 'description', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
]

export const USER_SUBSCRIPTION_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', suppressSizeToFit: true, width: 80 },
    { headerName: 'User', field: 'user', resizable: true },
    { headerName: 'ORDER', field: 'type', resizable: true },
    { headerName: 'AMOUNT PAID', field: 'amount_paid', resizable: true },
    { headerName: 'AMOUNT PAID (First payment)', field: 'first_amount_paid', resizable: true },
    { headerName: 'AMOUNT PAID (Total amount)', field: 'total_amount_paid', resizable: true },
    { headerName: 'PROMO AMOUNT', field: 'promo_amount', resizable: true },
    { headerName: 'PROMO CODE', field: 'promo_code', resizable: true },
    { headerName: 'ORDER DATE', field: 'start_date', resizable: true },
    { headerName: 'EXPIRY DATE', field: 'end_date', resizable: true },
    { headerName: '# USERS', field: 'total_users', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 150 },
]

export const TAXONOMY_COLUMN_HEADERS = [
    { headerName: 'TAXONOMY TYPE ID', field: 'id', resizable: true },
    { headerName: 'TAXONOMY TYPE NAME', field: 'name', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 300 },
]

export const SUBTAXONOMY_COLUMN_HEADERS = [
    { headerName: 'TAXONOMY ID', field: 'id', hide: true },
    { headerName: 'TAXONOMY NAME', field: 'name', resizable: true },
    { headerName: 'TAXONOMY VALUE', field: 'value', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 150 },
]

export const INDUSTRY_COLUMN_HEADERS = [
    { headerName: 'INDUSTRY ID', field: 'id', resizable: true, width: 150 },
    { headerName: 'NAME', field: 'name', resizable: true, width: 250 },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 250 },
]
export const SUB_INDUSTRY_COLUMN_HEADERS = [
    { headerName: 'INDUSTRY ID', field: 'id', resizable: true, width: 50 },
    { headerName: 'NAME', field: 'name', resizable: true, width: 350 },
]

export const LOCATION_COLUMN_HEADERS = [
    { headerName: 'LOCATION ID', field: 'id', resizable: true, },
    { headerName: 'NAME', field: 'name', resizable: true, },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
]

export const RESEARCH_TAGS_COLUMN_HEADERS = [
    { headerName: 'RESEARCH TAGS ID', field: 'id', resizable: true, },
    { headerName: 'NAME', field: 'name', resizable: true, },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
]

export const TEAM_ROLES_COLUMN_HEADERS = [
    { headerName: 'Team Roles ID', field: 'id', resizable: true, },
    { headerName: 'NAME', field: 'name', resizable: true, },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
]

export const ROLE_COLUMN_HEADERS = [
    { headerName: 'ROLE NAME', field: 'name', resizable: true },
    { headerName: 'ROLE TYPE', field: 'type', resizable: true, },
    { headerName: 'CREATED ON', field: 'created_on', resizable: true },
    { headerName: 'UPDATED ON', field: 'updated_on', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer",suppressSizeToFit: true, width: 120 },
]

export const RESOURCE_COLUMN_HEADERS = [
    { headerName: 'RESOURCE CODE', field: 'code', resizable: true },
    { headerName: 'RESOURCE NAME', field: 'name', resizable: true },
    { headerName: 'RESOURCE METHOD', field: 'method', resizable: true, },
    { headerName: 'RESOURCE OPERATION', field: 'operation', resizable: true, },
    { headerName: 'AUTHENTICATE', field: 'authenticate', resizable: true, },
    { headerName: 'AUTHORIZE', field: 'authorize', resizable: true, },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
]

export const PROMOTION_COLUMN_HEADERS = [
    { headerName: 'PROMO CODE', field: 'code', resizable: true },
    { headerName: 'RESOURCE DESCRIPTION', field: 'description', resizable: true },
    { headerName: 'RESOURCE START DATE', field: 'start_date', resizable: true, },
    { headerName: 'RESOURCE END DATE', field: 'end_date', resizable: true, },
    { headerName: 'CREATED ON', field: 'created_on', resizable: true },
    { headerName: 'UPDATED ON', field: 'updated_on', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
]

export const PERMISSION_COLUMN_HEADERS = [
    { headerName: 'ROLE NAME', field: 'role', resizable: true, },
    { headerName: 'RESOURCE CODE', field: 'code', resizable: true },
    { headerName: 'RESOURCE NAME', field: 'resource', resizable: true },
    { headerName: 'CREATED BY', field: 'created_by', resizable: true, },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
]

export const TEAM_COLUMN_HEADERS = [
    { headerName: 'USER ID', field: 'uid' },
    { headerName: 'ROLE ID', field: 'rid', hide: true, },
    { headerName: 'TEAM ROLE ID', field: 'trid', hide: true, },
    { headerName: 'MEMBER NAME', field: 'user_id', suppressSizeToFit: true, width: 200 },
    { headerName: 'EMAIL', field: 'email', resizable: true, },
    { headerName: 'ROLE', field: 'role_id', resizable: true, },
    { headerName: 'TEAM ROLE', field: 'team_role_id', resizable: true, },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
]

export const MENTIONS_COLUMN_HEADERS = [
    { headerName: 'NAME ID', field: 'cid', hide: true, },
    { headerName: 'TYPE ID', field: 'tid', hide: true, },
    { headerName: 'COMPANY NAME', field: 'entity_id', suppressSizeToFit: true, width: 200 },
    { headerName: 'COMPANY TYPE', field: 'entity_type', resizable: true, },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
]

export const USER_SUBSCRIPTION_HEADERS = [
    { headerName: 'SUBSCRIPTION ID', field: 'id', resizable: true, },
    { headerName: 'NAME', field: 'name', resizable: true, },
    { headerName: 'STATUS', field: 'status', resizable: true, },
    { headerName: 'START DATE', field: 'start_date', resizable: true, },
    { headerName: 'END DATE', field: 'end_date', resizable: true, },
    { headerName: 'NOTE', field: 'note', resizable: true, },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
]

export const EMAIL_TEMPLATE_HEADERS = [
    { headerName: 'ID', field: 'id', suppressSizeToFit: true, width: 80 },
    { headerName: 'NAME', field: 'name', resizable: true },
    { headerName: 'SUBJECT', field: 'subject', resizable: true },
    { headerName: 'CODE', field: 'code', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
]

export const SUB_POLICY_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', resizable: true, hide: true, suppressToolPanel: true },
    { headerName: 'LIMIT', field: 'limit', resizable: true },
    { headerName: 'PERIOD', field: 'periodLabel', resizable: true },
    { headerName: 'WEIGHT', field: 'weight', resizable: true, },
    { headerName: 'CATEGORY ID', field: 'categoryLabel', resizable: true, },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
]

export const DEMO_REQUEST_COLUMN_HEADERS = [
    { headerName: 'REQUEST ID', field: 'id', resizable: true },
    { headerName: 'EMAIL', field: 'email', resizable: true },
    { headerName: 'REQUEST DATE', field: 'requestDate', resizable: true, },
    { headerName: 'SUBSCRIBED', field: 'subscribed', resizable: true },
    { headerName: 'STATUS', field: 'status', resizable: true, },
    { headerName: 'COMMENTS', field: 'comments', sortable: false, resizable: true },
    { headerName: 'EDIT COMMENTS', sortable: false, cellRenderer: "CommentModalRenderer", suppressSizeToFit: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true },
]

export const EXPORT_REQUEST_COLUMN_HEADERS = [
    { headerName: 'REQUEST ID', field: 'id', resizable: true },
    { headerName: 'NAME', field: 'name', resizable: true },
    { headerName: 'EMAIL', field: 'email', resizable: true },
    { headerName: 'CURRENTLY SUBSCRIBED', field: 'subscribed', resizable: true },
    { headerName: 'SUBSCRIPTION', field: 'subscriptionType', resizable: true, },
    { headerName: 'STATUS', field: 'status', resizable: true, },
    { headerName: 'COMMENTS', field: 'comments', sortable: false, resizable: true },
    { headerName: 'EDIT COMMENTS', sortable: false, cellRenderer: "CommentModalRenderer", suppressSizeToFit: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true },
]

export const QUESTIONS_GROUPS_HEADERS = [
    { headerName: 'NAME', field: 'title', resizable: true },
    { headerName: 'ORDER', field: 'order', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true },
]

export const ECOSYSTEM_VERIFICATION_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id' },
    { headerName: 'Entity Type', field: 'entity_type', resizable: true },
    { headerName: 'NAME', field: 'name', resizable: true },
    { headerName: 'TYPE', field: 'type', resizable: true },
    { headerName: 'OWNER', field: 'owner', resizable: true },
    { headerName: 'CREATED AT', field: 'created_at', resizable: true },
    { headerName: 'VERIFIED', field: 'is_verified', resizable: true },
    { headerName: 'VERIFIED AT', field: 'verified_at', resizable: true },
    { headerName: 'VERIFIED BY', field: 'verified_by', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true },
];

export const SUBSCRIPTION_ROLES_HEADERS = [
    { headerName: 'NAME', field: 'name', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true },
];

export const UNDISCLOSED_PROXY_COLUMN_HEADERS = [
    { headerName: 'YEAR', field: 'year', resizable: true },
    { headerName: 'FUNDING STAGE', field: 'funding_stage', resizable: true },
    { headerName: 'VALUE', field: 'value', resizable: true, },
    { headerName: 'CREATED BY', field: 'created_by', resizable: true, },
    { headerName: 'CREATED AT', field: 'created_at', resizable: true },
    { headerName: 'UPDATED AT', field: 'updated_at', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
]

export const DATA_EXPORT_POLICY_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', resizable: true },
    { headerName: 'ROLE', field: 'role', resizable: true },
    { headerName: 'PERIOD', field: 'period', resizable: true },
    { headerName: 'RECORDS LIMIT', field: 'records_limit', resizable: true },
    { headerName: 'POLICY', field: 'policy', resizable: true, cellRenderer: "policyModalRenderer" },
    { headerName: 'ACTIVE', field: 'active', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 230 },
];

export const DATA_EXPORT_POLICY_TABLE_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', resizable: true, hide: true, suppressToolPanel: true },
    { headerName: 'DATA SET TYPE', field: 'data_set_type', resizable: true, },
    { headerName: 'LIMIT', field: 'limit', resizable: true },
    { headerName: 'FIELDS', field: 'fields', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
];

export const DATA_EXPORT_LOG_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', resizable: true },
    { headerName: 'USER ID', field: 'user_id', resizable: true },
    { headerName: 'USERNAME', field: 'username', resizable: true },
    { headerName: 'ORDER ID', field: 'order_id', resizable: true },
    { headerName: 'SUBSCRIPTION', field: 'subscription', resizable: true },
    { headerName: 'DATA SET TYPE', field: 'data_set_type', resizable: true },
    { headerName: 'NUMBER OF RECORDS', field: 'number_of_records', resizable: true },
    { headerName: 'CUSTOM REQUEST', field: 'is_custom', resizable: true },
    { headerName: 'REQUEST DETAILS', field: 'details', resizable: true, cellRenderer: "requestLogModalRenderer" },
    { headerName: 'REQUEST DATE', field: 'request_date', resizable: true },
];

export const DATA_EXPORT_PRODUCT_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', suppressSizeToFit: true, width: 80 },
    { headerName: 'DATA SET TYPE', field: 'data_set_type', resizable: true },
    { headerName: 'DESCRIPTION', field: 'description', resizable: true },
    { headerName: 'ACTIVE', field: 'active', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
];

export const FUNDING_BREAKDOWNS_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', resizable: true },
    { headerName: 'INVESTMENT VEHICLE', field: 'investment_vehicle_name', resizable: true },
    { headerName: 'DATE', field: 'date_raised', resizable: true },
    { headerName: 'AMOUNT', field: 'amount', resizable: true },
    { headerName: 'VERIFICATION STATUS', field: 'verification_status', resizable: true },
    { headerName: 'INVESTORS', field: 'investors', resizable: true, cellRenderer: "InvestorsModalRenderer" },
    { headerName: 'ACTION', sortable: false, resizable: true, cellRenderer: "actionRenderer" },
];

export const MAGNITT_DEPARTMENT_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', resizable: true },
    { headerName: 'NAME', field: 'name', resizable: true },
    { headerName: 'DESCRIPTION', field: 'description', resizable: true },
    { headerName: 'GOAL', field: 'goal', resizable: true, },
    {
        headerName: 'BACKGROUND IMAGE',
        field: 'background_image',
        resizable: true,
        autoHeight: true,
        cellRenderer: function (params) {
            return (params.value !== '' ? `<img src=${encodeURI(params.value)} alt='backgroundimage' width='50px' height='50px' />` : '')
        }
    },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", resizable: true },
];

export const VALUATION_CALCULATION_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', suppressSizeToFit: true, width: 80 },
    { headerName: 'FUNDING STAGE', field: 'stage', resizable: true },
    { headerName: 'LOWER SHARE PERCENTAGE', field: 'lower_share_percentage', resizable: true },
    { headerName: 'UPPER SHARE PERCENTAGE', field: 'upper_share_percentage', resizable: true },
    { headerName: 'EQUITY PERCENTAGE', field: 'equity_percentage', resizable: true },
    { headerName: 'DESCRIPTION', field: 'description', resizable: true },
    { headerName: 'ACTIVE', field: 'active', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
];

export const FUNDS_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'entity_id', suppressSizeToFit: true, width: 80 },
    { headerName: 'NAME', field: 'name', resizable: true },
    { headerName: 'FUND MANAGER', field: 'fund_manager', resizable: true },
    { headerName: 'MANAGER ID', field: 'entity_id', resizable: true },
    { headerName: 'MANAGER TYPE', field: 'entity_type', resizable: true },
    { headerName: 'ACTIVE', field: 'active', resizable: true },
    { headerName: 'CREATED ON', field: 'created_at', resizable: true },
    { headerName: 'UPDATED ON', field: 'updated_at', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
];

export const TECHNOLOGY_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', suppressSizeToFit: true, width: 80 },
    { headerName: 'NAME', field: 'name', resizable: true },
    { headerName: 'DESCRIPTION', field: 'description', resizable: true },
    { headerName: 'STATUS', field: 'status', resizable: true },
    { headerName: 'CREATED ON', field: 'created_at', resizable: true },
    { headerName: 'UPDATED ON', field: 'updated_at', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
];

export const PEOPLE_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', suppressSizeToFit: true, width: 80 },
    { headerName: 'FIRSTNAME', field: 'first_name', resizable: true },
    { headerName: 'LASTNAME', field: 'last_name', resizable: true },
    { headerName: 'CURRENT TITLE', field: 'current_job_title', resizable: true },
    { headerName: 'CURRENT ENTITY NAME', field: 'current_entity_name', resizable: true },
    { headerName: 'CURRENT ENTITY TYPE', field: 'current_entity_type', resizable: true },
    { headerName: 'LOCATION', field: 'country_data.name', resizable: true },
    { headerName: 'NATIONALITY', field: 'nationality_data.name', resizable: true },
    { headerName: 'GENDER', field: 'gender', resizable: true },
    { headerName: 'EMAIL', field: 'email', resizable: true },
    { headerName: 'USER ID', field: 'user_id', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
]

export const PEOPLE_TEAM_COLUMN_HEADERS = [
    { headerName: 'PEOPLE ID', field: 'pid' },
    { headerName: 'TEAM ROLE ID', field: 'trid', hide: true, },
    { headerName: 'MEMBER NAME', field: 'people_id', suppressSizeToFit: true, width: 200 },
    { headerName: 'EMAIL', field: 'email', resizable: true, },
    { headerName: 'TEAM ROLE', field: 'team_role_id', resizable: true, },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
]

export const FIELD_SEPARATOR = "^,"
export const EQUAL_SYMBOL = "^:"
export const VALUE_SEPARATOR = "^;"
export const DATE_FORMAT = 'DD/MM/YYYY'
export const GENERAL_SHORT_ERROR_MSG = "Oops! something went wrong";
export const REQUIRED_FIELD_ERROR_MSG = "Please fill all the required fields";
export const WRONG_URL_ERROR_MSG = "URL is not valid";

export const ALLOWED_QUERY_OPERATOR = {
    "equal": "^:",
    "notEq": "^!:",
    "not": "^!^",
    "gte": "^>:",
    "gt": "^>^",
    "lte": "^<:",
    "lt": "^<^",
    "btw": "^btw:",
    "like": "^l:",
    "iLike": "^il:",
    "substring": "^sub:",
    "notIn": "^not:"
}

export const MONTH = [
    { label: 'Jan', },
    { label: 'Feb', },
    { label: 'Mar', },
    { label: 'Apr', },
    { label: 'May', },
    { label: 'Jun', },
    { label: 'Jul', },
    { label: 'Aug', },
    { label: 'Sep', },
    { label: 'Oct', },
    { label: 'Nov', },
    { label: 'Dec', },
]

export const OPTIONS = [
    { value: 0, label: 'No' },
    { value: 1, label: 'Yes' },
];

// todo: Add more currencies
export const CURRENCIES = [
    { value: 'USD', label: 'USD' },
    { value: 'AED', label: 'AED' },
    { value: 'LE', label: 'LE' },
    { value: 'SAR', label: 'SAR' },
    { value: 'BD', label: 'BD' },
    { value: 'DZD', label: 'DZD' },
    { value: 'IQD', label: 'IQD' },
    { value: 'JOD', label: 'JOD' },
    { value: 'KD', label: 'KD' },
    { value: 'LL', label: 'LL' },
    { value: 'LYD', label: 'LYD' },
    { value: 'MAD', label: 'MAD' },
    { value: 'OMR', label: 'OMR' },
    { value: 'QR', label: 'QR' },
    { value: 'SYP', label: 'SYP' },
    { value: 'TND', label: 'TND' },
    { value: 'YER', label: 'YER' },
]

export const JOB_PERIODS = [
    { value: 'YEAR', label: 'Per Year' },
    { value: 'MONTH', label: 'Per Month' },
    { value: 'WEEK', label: 'Per Week' },
    { value: 'DAY', label: 'Per Day' },
    { value: 'HOUR', label: 'Per Hour' },
]

/**
 * @Note for the third element
 * Value has a "," as a seperator, which will be used to split the string into an array
 * "isMulti" is a flag to indicate that this filter has multiple values
 */
export const DELETE_OPTIONS = [
    { value: 1, label: 'Deleted' },
    { value: 0, label: 'Live' },
    { value: '0,1', label: 'All', isMulti: true },
]

export const FUNDING_STATUS_FILTER_OPTIONS = [
    { value: 0, label: 'Pending' },
    { value: 1, label: 'Verified' },
    { value: 2, label: 'Un-Verified' },
    { value: 3, label: 'Draft' },
    { value: '0,1,2,3', label: 'All', isMulti: true },
]

export const FEATURED_OPTIONS = [
    { value: 1, label: 'Featured' },
    { value: 0, label: 'Not Featured' },
    { value: "", label: 'All' }
]

export const PUBLISHED = [
    { value: 0, label: 'Draft' },
    { value: 1, label: 'Published' },
    { value: 2, label: 'UnPublished' },
];

export const USER_STATUS = [
    { value: 0, label: 'Invited' },
    { value: 1, label: 'Active' },
    { value: 2, label: 'Suspended' },
    { value: 3, label: 'Un-verified' },
]

export const ACTIVE_STATUS = [
    { value: 0, label: 'In Active' },
    { value: 1, label: 'Active' },
]

export const GENDERS = [
    { value: 'MALE', label: 'MALE' },
    { value: 'FEMALE', label: 'FEMALE' },
]

export const ENTITY_TYPES = [
    { value: 'USER', label: 'USER' },
    { value: 'STARTUP', label: 'STARTUP' },
    { value: 'INVESTOR', label: 'INVESTOR' },
    { value: 'CORPORATE', label: 'CORPORATE' },
    { value: 'ENABLER', label: 'ENABLER' },
    { value: 'FUND', label: 'FUND' }
]

export const ICE_ENTITY_TYPES = [
    { value: 'INVESTOR', label: 'Investor' },
    { value: 'CORPORATE', label: 'Corporate' },
    { value: 'ENABLER', label: 'Enabler' }
];

export const PEOPLE_ENTITY_TYPES = [
    { value: 'INVESTOR', label: 'INVESTOR' },
    { value: 'CORPORATE', label: 'CORPORATE' },
    { value: 'ENABLER', label: 'ENABLER' },
    { value: 'STARTUP', label: 'STARTUP' },
    { value: 'COMPANY', label: 'COMPANY' }
];

export const METHODS = [
    { value: 'get', label: 'GET' },
    { value: 'post', label: 'POST' },
    { value: 'patch', label: 'PATCH' },
    { value: 'delete', label: 'DELETE' }
]

export const TOOLBAR = [
    { name: 'document', items: ['Source', '-', 'Save', 'NewPage', 'Preview', '-', 'Templates'] },
    { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
    { name: 'editing', items: ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt'] },
    { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat'] },
    '/',
    { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language'] },
    { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
    { name: 'insert', items: ['Image', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'] },
    '/',
    { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize', 'FontFamily', 'FontColor'] },
    { name: 'colors', items: ['TextColor', 'BGColor'] },
    { name: 'tools', items: ['Maximize', 'ShowBlocks'] },
    //{name: 'align',items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']},

]

export const TOOLBAR_ENTITY = [
    { name: 'document', items: ['Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates'] },
    { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
    { name: 'editing', items: ['Find', 'Replace', '-', 'SelectAll'] },
    { name: 'forms', items: ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'] },
    '/',
    { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat'] },
    { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language'] },
    { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
    { name: 'insert', items: ['Image', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe', 'Startupentity'] },
    '/',
    { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize', 'FontFamily', 'FontColor'] },
    { name: 'colors', items: ['TextColor', 'BGColor'] },
    { name: 'tools', items: ['Maximize', 'ShowBlocks'] }
    // { name: 'about', items: ['About'] }
]

export const LANGUAGES = [
    { value: 'en', label: 'English' },
    { value: 'ar', label: 'Arabic' }
]

export const ORDER_TYPES_VALUE = {
    subscription: 1,
    report: 2,
    dataExport: 4,
    apiProduct: 5
};

export const ORDER_TYPES = [
    { value: ORDER_TYPES_VALUE.subscription, label: 'Subscriptions' },
    { value: ORDER_TYPES_VALUE.report, label: 'Reports' },
    { value: ORDER_TYPES_VALUE.dataExport, label: 'Data Export' },
    { value: ORDER_TYPES_VALUE.apiProduct, label: 'API Product' },
];


export const RESEARCH_TYPES = {
    free: 0,
    paid: 1,
    sponsored: 2
}

export const BANNER_TYPES = [
    { value: 0, label: 'Top' },
    { value: 1, label: 'Side' },
]

export const POLICY_PERIODS = [
    { value: 1, label: 'Day(s)' },
    { value: 2, label: 'Week(s)' },
    { value: 3, label: 'Month(s)' },
    { value: 4, label: 'Year(s)' }
]

export const UPLOAD_SERVER = 'https://testapi.magnitt.com/file/ck-editor'
export const BROWSE_SERVER = '/ckfinder/ckfinder.html'

export const SELECT_STYLES = {
    control: (provided, state) => ({
        ...provided,
        boxShadow: 'none'
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: '#5d656d',
    })
}

export const SELECT_STYLES_ERROR = {
    control: (provided, state) => ({
        ...provided,
        boxShadow: 'none',
        borderColor: 'red'
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: '#5d656d',
    })
}

export const SELECT_THEME_PRIMARY = '#216ba5'

export const LOCATIONS = []

export const MAX_FILE_SIZE = 8192000

export const USER_STATUS_OBJ = {
    invited: 0,
    active: 1,
    suspended: 2,
    unVerified: 3
}

export const ACTIVE_STATUS_OBJ = {
    inactive: 0,
    active: 1
}

export const TWITTER = /http(s)?:\/\/(.*\.)?twitter\.com\/[A-z0-9_]+\/?/
export const LINKEDIN = /http(s)?:\/\/([\w]+\.)?linkedin\.com\/[A-z0-9_-]+\/?/
export const LINKED_PUB = /http(s)?:\/\/([\w]+\.)?linkedin\.com\/[A-z0-9_-]+(\/[A-z0-9]+){3}\/?/
export const FACEBOOK = /http(s)?:\/\/(www\.)?(facebook|fb)\.com\/[A-z0-9_\-\.]+\/?/

export const MIN_SEARCH_LENGTH = 2

export const FUNDING_STATUS = [
    'pending',
    'verified',
    'unverified'
]

export const FUNDING_STATUS_VALUES = {
    pending: 0,
    verified: 1,
    unverified: 2,
    draft: 3
};

export const FUNDING_STATUS_LABELS = {
    0: 'Pending',
    1: 'Verified',
    2: 'Un-verified',
    3: 'Draft'
};

export const FUNDING_STATUS_OPTIONS = [
    { value: 0, label: 'Pending' },
    { value: 1, label: 'Verified' },
    { value: 2, label: 'Un-Verified' },
    { value: 3, label: 'Draft' }
]

export const FUNDING_VERIFICATION_DATA = {
    pending: '0',
    verified: '1',
    unverified: '2'
}

export const EXPORT_REQUEST_STATUS_VALUES = {
    pending: 0,
    contacted: 1,
    converted: 2
}

export const EXPORT_REQUEST_STATUS_LABELS = [
    'Pending',
    'Contacted',
    'Converted'
]

export const EXPORT_REQUEST_STATUS = [
    { value: 0, label: 'Pending' },
    { value: 1, label: 'Contacted' },
    { value: 2, label: 'Converted' }
]

export const DEMO_REQUEST_STATUS_VALUES = {
    pending: 0,
    contacted: 1,
    converted: 2
}

export const DEMO_REQUEST_STATUS_LABELS = [
    'Pending',
    'Contacted',
    'Converted'
]

export const DEMO_REQUEST_STATUS = [
    { value: 0, label: 'Pending' },
    { value: 1, label: 'Contacted' },
    { value: 2, label: 'Converted' }
]

export const USER_ROLES_TYPES = [
    { value: 'user', label: 'User' },
    { value: 'subscription', label: 'Subscription' },
    { value: 'admin', label: 'Admin' }
]

export const PURCHASING_AS_TYPES = [
    { value: 'home', label: 'Individual' },
    { value: 'office', label: 'Company' }
]

export const ICE_VERIFICATION_STATUS = {
    verified: 1,
    unVerified: 0
};

export const ICE_VERIFICATION_STATUS_LABEL = [
    { value: 1, label: 'Verified' },
    { value: 0, label: 'Un-verified' }
];

export const LOCATION_TYPES = [
    { value: 0, label: 'Region' },
    { value: 1, label: 'Country' },
    { value: 2, label: 'City' }
]

export const DIRECTORY_TYPE = [
    { value: 'startup', label: 'Startup' },
    { value: 'ecosystem', label: 'Ecosystem' }
]

export const PARENT_INDUSTRY_ID = 0;

// api product period
export const API_PRODUCT_PERIOD = {
    2: 'Month',
    3: 'Year'
}

// research download form types
export const RESEARCH_DOWNLOAD_FORM_TYPES = {
    1: 'Long Form',
    2: 'Short Form'
}

// taxonomies
const objTaxo = {
    development: {
        jobRole: 22,
        researchCategory: 27,
        devStage: 10,
        fundingStage: 42,
        fundingSourceType: 48,
        investorType: 36,
        corporateType: 38,
        enablerType: 37,
        newsCategory: 14,
        businessType: 29,
        institutions: 2,
        investmentVehicles: 60,
        dealTerm: 65,
        demoContactOptions: 62,
        salesContactOptions: 63,
        demoWidgetType: 68,

    },
    production: {
        jobRole: 22,
        researchCategory: 27,
        devStage: 10,
        fundingStage: 42,
        fundingSourceType: 48,
        investorType: 36,
        corporateType: 38,
        enablerType: 37,
        newsCategory: 14,
        businessType: 29,
        institutions: 2,
        investmentVehicles: 50,
        dealTerm: 49,
        demoContactOptions: 52,
        salesContactOptions: 53,
        demoWidgetType: 55,
    }
}

export const TAXONOMIES = objTaxo[process.env.NODE_ENV];

// endpoints
const coreApi = {
    development: 'https://testgate.magnitt.com/core-api',
    production: 'https://gateway.magnitt.com/core-api',
}

const productApi = {
    development: 'https://testgate.magnitt.com/product-api',
    production: 'https://gateway.magnitt.com/product-api',
}

const ckeditorApiPath = {
    development: 'https://magnitt-live.s3.eu-west-2.amazonaws.com/public/ckeditor/dev/',
    production: 'https://magnitt-live.s3.eu-west-2.amazonaws.com/public/ckeditor/prod/'
}

const PublicImgUrlPath = {
    development: 'https://magnitt-public-dev.s3.eu-west-2.amazonaws.com/public/images',
    production: 'https://d2p9i44hnkrmkx.cloudfront.net/public/images'
}

export const API = coreApi[process.env.NODE_ENV];
// export const API = "http://localhost:3212/core-api"; //for local testing
export const PRODUCT_API = productApi[process.env.NODE_ENV];
export const CKEDITOR_API = ckeditorApiPath[process.env.NODE_ENV] + 'ckeditor.js';
export const CKEDITOR_API_PATH = ckeditorApiPath[process.env.NODE_ENV];
export const PUBLIC_IMG_URL = PublicImgUrlPath[process.env.NODE_ENV];

