import React from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react'

import store from '../../store'
import { MAGNITT_DEPARTMENT_COLUMN_HEADERS, ALLOWED_QUERY_OPERATOR } from '../../constant'
import { API_PATH } from '../../utility/constants/apiPath';
import { prepareSelectParam } from '../../utility/queryParams'
import SideNav from '../SideNav'
import ActionRenderer from '../reusable/ActionRenderer'
import Parent from '../Parent'
import Modal from '../reusable/Modal'
import Sort from '../reusable/Sort'
import Pagination from '../reusable/Pagination'
import Home from '../Home'

class MagnittDepartments extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: MAGNITT_DEPARTMENT_COLUMN_HEADERS,
            context: { componentParent: this },
            frameworkComponents: { actionRenderer: ActionRenderer },

            name: '',
            showkeywords: false,
            keyword: [],
            filters: {
                name: []
            },
            filterFields: [
                { 'key': 'name', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] }
            ]
        }
        store.sortType = this.props.location.pathname.split('/')[1];
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
    }

    componentDidMount() {
        document.addEventListener('mousedown', (e) => {
            if (this.category) { if (!this.category.contains(e.target)) this.setState({ showCategories: false }) }
        })

        let paramFields = ['id', 'name', 'description', 'goal', 'background_image_data'];
        store.fields = prepareSelectParam(paramFields);
        this.fetchListData(API_PATH.magnittDepartment, store.fields);
    }

    componentWillUnmount() {
        document.addEventListener('mousedown', (e) => {
            if (this.category) { if (!this.category.contains(e.target)) this.setState({ showCategories: false }) }
        })
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>
                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>MAGNITT DEPARTMENTS</h3>
                            <div className='filter-container'>
                                <div className='filter-row'>
                                    <div ref={node => this.word = node} className="col-md-12 col-sm-12 col-xs-12 filter-firstline filter-spacing" >
                                        <div style={{ width: '100%' }}>
                                            <input ref={node => this.input = node}
                                                className='filter-input input-border'
                                                placeholder="Type and Enter to Search"
                                                value={this.state.name}
                                                onFocus={() => this.setState({ showKeywords: true })}
                                                onKeyUp={this.handleNameEnter}
                                                onChange={e => this.setState({ name: e.target.value })} />
                                            {this.state.showKeywords && this.state.filters.name.length > 0 ?
                                                <div className='filter-dropdown' style={{ width: '100%' }}>
                                                    <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                        {this.state.filters.name.map((word, i) => {
                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'name')}>
                                                                {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div> : null}
                                        </div>
                                        {this.state.name.length > 0 &&
                                            <i className="fa fa-times "
                                                style={{ paddingRight: 10 }}
                                                onClick={() => {
                                                    this.input.focus()
                                                    this.setState({ name: '' })
                                                }}>
                                            </i>
                                        }
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2'
                                            className="col-md-2 col-sm-2 col-xs-6 filter-searchbtn"
                                            onClick={() => {
                                                this.handleClearAllFilters(API_PATH.magnittDepartment)
                                            }}>Clear All</a>
                                    </div>
                                </div>
                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    frameworkComponents={this.state.frameworkComponents}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                />
                            </div>

                            <Pagination />
                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />
                    </div>
                }</div>
        )
    }
}

export default observer(MagnittDepartments)